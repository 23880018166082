import {
    NOTIFICATION_LIFETIME as SourceNOTIFICATION_LIFETIME,
    ERROR_NOTIFICATION_LIFETIME as SourceERROR_NOTIFICATION_LIFETIME,
    ERROR_TYPE as SourceERROR_TYPE,
} from 'SourceComponent/Notification/Notification.config';

//TODO: implement ANIMATION_DURATION
export const ANIMATION_DURATION = 700;

//TODO: implement NOTIFICATION_LIFETIME
export const NOTIFICATION_LIFETIME = 5000;

//TODO: implement ERROR_NOTIFICATION_LIFETIME
export const ERROR_NOTIFICATION_LIFETIME = 5000;

//TODO: implement ERROR_TYPE
export const ERROR_TYPE = SourceERROR_TYPE;
