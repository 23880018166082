import {
    CategoryPage as SourceCategoryPage,
} from 'SourceRoute/CategoryPage/CategoryPage.component';

import './CategoryPage.style.override'

import PropTypes from 'prop-types';
import Image from 'Component/Image';
import { CategoryTreeType } from 'Type/Category';
import { customerType } from 'Type/Account';
import { DeviceType } from 'Type/Device';
import { FilterInputType, FilterType } from 'Type/ProductList';
import CategoryDetails from 'Component/CategoryDetails';
import ContentWrapper from 'Component/ContentWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faThLarge, faSortAlt, faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import CategorySort from 'Component/CategorySort';
import shop from 'Route/StorePage/Images/shop.png';
import truck from 'Route/StorePage/Images/truck.png';
import  { Redirect } from 'react-router-dom'
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { showNotification } from 'Store/Notification/Notification.action';
import NoMatch from 'Route/NoMatch';

export class CategoryPage extends SourceCategoryPage {

    static propTypes = {
        category: CategoryTreeType.isRequired,
        customer: customerType.isRequired,
        filters: PropTypes.objectOf(PropTypes.shape).isRequired,
        sortFields: PropTypes.shape({
            options: PropTypes.array
        }).isRequired,
        selectedSort: PropTypes.shape({
            sortDirection: PropTypes.oneOf([
                'ASC',
                'DESC'
            ]),
            sortKey: PropTypes.string
        }).isRequired,
        onSortChange: PropTypes.func.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired,
        selectedFilters: FilterType.isRequired,
        filter: FilterInputType.isRequired,
        search: PropTypes.string,
        isContentFiltered: PropTypes.bool,
        isMatchingListFilter: PropTypes.bool,
        isMatchingInfoFilter: PropTypes.bool,
        totalPages: PropTypes.number,
        device: DeviceType.isRequired,
        totalItems: PropTypes.number,
        isGrid: PropTypes.bool
    };

    state = {
        isGrid: false
    };

    renderIcons() {

        const { onClickIcon, totalItems } = this.props;
        return (<></>);

        /*if(!totalItems) {
            return (<></>);
        }*/

        /*return(
            <div block="CategoryPage" elem="Icons">
                <button onClick={ this.onIconClick }>
                    <FontAwesomeIcon className="CategoryPage-ListMode" icon={faSquare} />
                </button>
                <button onClick={ this.onIconClick }>
                    <FontAwesomeIcon className="CategoryPage-GridMode" icon={faThLarge} />
                </button>
            </div>
        )*/
    }

    renderItemsCounter() {
        const { search, totalItems } = this.props;

        return (<>
                <div block="SearchPage" elem="TotalItems">
                    <div block="SearchPage" elem="TotalLabel">
                    <span block="SearchPage" elem="TotalItemsCount">
                        {totalItems}
                    </span>
                        <span block="SearchPage" elem="TotalItemsLabel">
                        { __('Search results for: ') }
                    </span>
                    </div>
                    <div block="SearchPage" elem="TotalSearch">
                    <span block="SearchPage" elem="TotalItemsLabel">
                        "{ search && search != 'undefined' ? search : '' }"
                    </span>
                    </div>
                </div>
            </>
        );
    }

    renderMiscellaneous() {
        if (!this.displayProducts()) {
            return null;
        }

        const { totalItems, device } = this.props;

        if(device.isMobile){
            return (
                <div block="CategoryPage" elem="MiscellaneousMobile">
                    <aside block="CategoryPage" elem="MiscellaneousMobileSort">
                        { this.renderFilterButton() }
                        { this.renderCategorySort() }
                    </aside>
                    <div block="CategoryPage" elem="MiscellaneousLineProductCount">
                        <div block="CategoryPage" elem="MiscellaneousProductCount">
                            { this.renderItemsCounter() }
                            { /*this.renderIcons()*/ }
                        </div>
                    </div>
                </div>
            );
        }else {
            return (
                <aside block="CategoryPage" elem="Miscellaneous">
                    { this.renderItemsCounter() }
                    { this.renderFilterButton() }
                    { this.renderCategorySort() }
                </aside>
            );
        }
    }

    renderFilterButton() {
        const { isContentFiltered, totalPages } = this.props;

        if (!isContentFiltered && totalPages === 0) {
            return null;
        }

        return (
            <div block="CategoryPage" elem="FilterButton">
                <FontAwesomeIcon className="CategoryDetails-FilterIcon" icon={faSlidersH} />
                <div block="CategoryPage" elem="SortLabel">
                    <button
                        block="CategoryPage"
                        elem="Filter"
                        onClick={ this.onFilterButtonClick }
                    >
                        { __('Filter') }
                    </button>
                </div>
            </div>
        );
    }

    renderCategorySort() {
        const {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter,
            totalItems
        } = this.props;

        const { options = {} } = sortFields;
        const updatedSortFields = Object.values(options).map(({ value: id, label }) => ({ id, label }));
        const { sortDirection, sortKey } = selectedSort;

        /*if (!totalItems) {
            return <></>;
        }*/

        return (
            <div block="CategoryPage" elem="SortButton">
                { totalItems ? <>
                    <FontAwesomeIcon className="CategoryDetails-SortIcon" icon={faSortAlt} />
                    <div block="CategoryPage" elem="SortLabel">
                        <CategorySort
                            isMatchingInfoFilter={ isMatchingInfoFilter }
                            onSortChange={ onSortChange }
                            sortFields={ updatedSortFields }
                            sortKey={ sortKey }
                            sortDirection={ sortDirection }
                        />
                    </div>
                </> : '' }
            </div>
        );
    }

    renderSort() {
        const { totalItems } = this.props;

        return (
            <aside block="CategoryPage" elem="MiscellaneousMobileSortFilter">
                { this.renderFilterButton() }
                { (!!totalItems) && this.renderCategorySort() }
                {/* <div block="CategoryPage" elem="MiscellaneousMobileLine"></div> */}
            </aside>
        )
    }

    renderCategoryDetails() {
        const { category, sortFields, selectedSort , onSortChange , isMatchingInfoFilter, totalItems, isLoadingCount } = this.props;

        return (
            <CategoryDetails
                category={ category }
                isMatchingInfoFilter={ isMatchingInfoFilter }
                sortFields={ sortFields}
                onSortChange={ onSortChange }
                selectedSort={ selectedSort }
                totalItems={ totalItems }
                isLoadingCount={ isLoadingCount }
                onClickIcon={ this.onIconClick }
            />
        );
    }

    renderDeliveryServices() {
        return (
            <div block="CategoryPage" elem="Delivery" >
                <h3>{__("After choosing your products you can choose the best way to receive them")}</h3>
                <label>{__("Your pharmacy offers all of these options")}</label>
                <div block="CategoryPage" elem="Delivery-Services" >
                    <div block="CategoryPage" elem="Delivery-Image">
                        <Image
                            src={ truck }
                            height="75px"
                        />
                        <h3>{__('Home delivery')}</h3>
                    </div>
                    <div block="CategoryPage" elem="Delivery-Image">
                        <Image
                            src={ shop }
                            height="75px"
                        />
                        <h3>{__('Store pickup')}</h3>
                    </div>
                </div>
                <label>{__("Any questions, in addition to our help channel, you can contact your pharmacy directly")}</label>
            </div>
        );
    }

    renderContent() {
        const { search, totalItems, isGrid, customer } = this.props;

        let favourite_pharmacy_code = null;
        let user = customer;

        if (!customer.favourite_pharmacy_code) {
            if (JSON.parse(localStorage.getItem('customer'))) {
                user = JSON.parse(localStorage.getItem('customer'));
                if (user.data.favourite_pharmacy_code) {
                    favourite_pharmacy_code = user.data.favourite_pharmacy_code;
                }
            }

            if (favourite_pharmacy_code == null) {
                if (localStorage.getItem('guest_pharmacy_code')) {
                    favourite_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
                }
            }
        } else {
            favourite_pharmacy_code = customer.favourite_pharmacy_code;
        }

        //if (favourite_pharmacy_code != null) {
            return (
                <>
                    { this.renderFilterOverlay() }
                    { !search && this.renderSort() }
                    { !search && this.renderCategoryDetails() }
                    {/* { !search && this.renderCategoryName() } */}
                    { this.renderCmsBlock() }
                    { search && this.renderMiscellaneous() }
                    { this.renderCategoryProductList() }
                    { this.renderDeliveryServices() }
                </>
            );
        //}
    }

    onIconClick = () => {
        this.setState( {
            isGrid: !this.state.isGrid
        })
        const { isGrid } = this.state;
    }

    render() {
        const { customer, showPharacySelectorPopup, activeOverlay,requestStores, updateNoMatch, category, isInfoLoading, isLoadingCount } = this.props;

        const { isGrid } = this.state;
        const hideProducts = !this.displayProducts();

        let favourite_pharmacy_code = null;
        let user = customer;

        if (!customer.favourite_pharmacy_code) {
            if (JSON.parse(localStorage.getItem('customer'))) {
                user = JSON.parse(localStorage.getItem('customer'));
                if (user.data.favourite_pharmacy_code) {
                    favourite_pharmacy_code = user.data.favourite_pharmacy_code;
                }
            }

            if (favourite_pharmacy_code == null) {
                if (localStorage.getItem('guest_pharmacy_code')) {
                    favourite_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
                }
            }
        } else {
            favourite_pharmacy_code = customer.favourite_pharmacy_code;
        }


        if(favourite_pharmacy_code == null){
            //window.location.href = "/localizador-de-farmacias";
            //history.push(appendWithStoreCode('/selecionador-de-farmacias'));
            if (activeOverlay !== 'PharmacySelector') {
                //requestStores();
                //showPharacySelectorPopup();
            }
        }

        if(isInfoLoading === false && isLoadingCount === false && category.is_active === false){
            return <NoMatch />;
        }

        return (
            <main block="CategoryPage">
                <ContentWrapper
                    wrapperMix={ {
                        block: 'CategoryPage',
                        elem: 'Wrapper',
                        mods: { hideProducts, IsGridActive: isGrid}
                    } }
                    label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
};

export default CategoryPage;
