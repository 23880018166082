import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';
import Loader from 'Component/Loader';

import { customerType } from 'Type/Account';
import { showPopup } from 'Store/Popup/Popup.action';
import history from 'Util/History';
import { HistoryType } from 'Type/Common';
import { goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { getQueryParam, removeQueryParamWithoutHistory } from 'Util/Url';
import { CheckoutSaudaDispatcher } from 'Store/CheckoutSauda';
import { updateMeta } from 'Store/Meta/Meta.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';
import HealthCardWithoutLogin from './HealthCardWithoutLogin.component';
import MyAccountQuery from 'Query/MyAccount.query';
import { fetchMutation, fetchQuery } from 'Util/Request';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);


/** @namespace Component/HealthCardContainer/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    saudaCardMatrix: state.CheckoutSaudaReducer.getSaudaCardMatrix,
    isLoadedSaudaCardMatrix: state.CheckoutSaudaReducer.isLoadedSaudaCardMatrix,
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints,
    isLoadedSaudaCardPoint: state.CheckoutSaudaReducer.isLoadedSaudaCardPoint,
    device: state.ConfigReducer.device,
    favStoreObj: state.StorePageReducer.favStoreObj,
    customerRequestDone: state.MyAccountReducer.customerRequestDone
});

/** @namespace Component/HealthCardContainer/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCustomer: (customer) => dispatch(updateCustomerDetails(customer)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    showErrorNotification: (error) => dispatch(showNotification('error', error)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    showInfoPopup: (payload) => dispatch(showPopup('HealthCardConditions', payload)),
    showInfoPopupPhone: (payload) => dispatch(showPopup('HealthCardPhone', payload)),
    showInfoPopupVoucher: (payload) => dispatch(showPopup('GenerateVoucher', payload)),
    showInfoPopupCardInfo: (id, payload) => dispatch(showPopup(id, payload)),
    requestSaudaMatrix: () => {
        CheckoutSaudaDispatcher.getSaudaCardMatrix(dispatch);
    },
    requestSaudaPoints: () => {
        CheckoutSaudaDispatcher.getSaudaCardPoints(dispatch);
    },
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showSaudaCardBlockedPopup: (payload) => dispatch(showPopup('SaudaCardBlocked', payload)),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
});

/** @namespace Component/HealthCardContainer/Container */
class HealthCardWithoutLoginContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        updateCustomer: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        getSaudaCardDetails: PropTypes.func.isRequired,
        requestSaudaMatrix: PropTypes.func.isRequired,
        requestSaudaPoints: PropTypes.func.isRequired,
        history: HistoryType.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        requestCustomerData: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired
    };

    containerFunctions = {
        showEditForm: this.showEditForm.bind(this),
        setLoading: this.setLoading.bind(this),
        changeCreateCardSuccessState: this.changeCreateCardSuccessState.bind(this),
        submitPointsToVoucher: this.submitPointsToVoucher.bind(this),
        checkJoinCard: this.checkJoinCard.bind(this),
        updatePage: this.updatePage.bind(this),
        setAssociationFinish: this.setAssociationFinish.bind(this)
    };

    state = {
        showHideForm: false,
        isLoading: false,
        showSuccessCreate: false,
        associationFinish: false,
        campaignAttrId: '',
        alertShow: false,
        healthcard: false
    };

    setAssociationFinish(value) {
        this.setState({associationFinish: value});
    }

    onError = () => {
        const { showErrorNotification } = this.props;

        this.setState({ isLoading: false }, () => {
            showErrorNotification(__('We are experiencing issues, please try again later'));
        });
    };

    componentWillUnmount(){
        this.setState({alertShow: false});
    }


    componentDidUpdate(prevProps){
        const {
            customer: { sauda_card },
            showSaudaCardBlockedPopup,
            setHealthCard
        } = this.props;

        const {
            customer: { sauda_card: prevCard }
        } = prevProps;

        const { associationFinish, alertShow, healthcard } = this.state;

        if(prevCard != sauda_card){
            if(sauda_card && sauda_card.card_number){
                if(
                    !sauda_card.is_active &&
                    (sauda_card.status == 'PreCanceledAuto' ||
                    sauda_card.status == 'NotDefined' ||
                    sauda_card.status == 'PreCanceledBackOffice' ||
                    sauda_card.status == 'Blocked')
                ){
                    if(!alertShow){
                        showSaudaCardBlockedPopup();
                        this.setState({alertShow: true});
                    }
                }
            }
        }

        if(associationFinish) {
            const query = MyAccountQuery.updateSaudaCard();
            fetchQuery(query).then(
                ({ updateSaudaCard }) => {
                    if(
                        updateSaudaCard && 
                        (updateSaudaCard.message == 'PreCanceledAuto' ||
                        updateSaudaCard.message == 'NotDefined' ||
                        updateSaudaCard.message == 'PreCanceledBackOffice' ||
                        updateSaudaCard.message == 'Blocked')
                    ){
                        if(!alertShow){
                            showSaudaCardBlockedPopup();
                            this.setState({alertShow: true});
                        }
                    }

                    if(updateSaudaCard.message == 'Canceled'){
                        this.updatePage();
                    }
                },
                (error) => {
                    console.log('error', error);
                }
            );
        }

        if(healthcard){
            localStorage.setItem("healthcard_number",healthcard)
        }
    }
    
    componentDidMount() {
        const {
            requestSaudaMatrix,
            requestSaudaPoints,
            customer: { sauda_card },
            showSaudaCardBlockedPopup,
            updateMeta
        } = this.props;

        const { alertShow } = this.state;

        updateMeta({ title: __('Cartão Saúda') });

        requestSaudaMatrix();
        requestSaudaPoints();
        if(sauda_card && sauda_card.card_number){
            if(
                !sauda_card.is_active &&
                (sauda_card.status == 'PreCanceledAuto' ||
                sauda_card.status == 'NotDefined' ||
                sauda_card.status == 'PreCanceledBackOffice' ||
                sauda_card.status == 'Blocked')
            ){
                if(!alertShow){
                    showSaudaCardBlockedPopup();
                    this.setState({alertShow: true});
                }
            }
        }

        const query = MyAccountQuery.getCampaignAttrId();
        return fetchQuery(query).then(
            ({ getCampaignAttrId }) => {
                this.setState({
                    campaignAttrId: getCampaignAttrId.id
                });
            },
            (error) => {
                console.log('error', error);
            }
        );
    }

    updatePage() {
        const { requestSaudaMatrix, requestSaudaPoints } = this.props;
        requestSaudaMatrix();
        requestSaudaPoints();
    }

    setLoading(value) {
        this.setState({ isLoading: value });
    }

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    showEditForm(status,healthcard){
        window.scrollTo(0, 0);
        this.setState({showHideForm: status});
        this.setState({healthcard: healthcard});
        //this.setState({showHideForm: !this.state.showHideForm});
     }

    changeCreateCardSuccessState(){
        this.setState({showSuccessCreate: !this.state.showSuccessCreate});
    }

    goBack(url){
        const { history } = this.props;

        history.push({
            pathname: appendWithStoreCode(url)
        });
    }

    submitPointsToVoucher(cardNumber, voucher) {
        const { showSuccessNotification, showErrorNotification, requestSaudaPoints } = this.props;

        this.setLoading(true);
        const query = MyAccountQuery.changePointsToVoucher(cardNumber, voucher);
            return fetchQuery(query).then(
                (response) => {
                    this.setLoading(false);
                    if (response.changePointsToVoucher.status == '200') {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        showSuccessNotification(__('Successfully acquired voucher.'));
                        requestSaudaPoints();
                    } else {
                        showErrorNotification(__('It is currently not possible to purchase the voucher, please try later.'));
                    }
                },
                (error) => {
                    this.setLoading(false);
                    console.log('error', error);
                    showErrorNotification(__('It is currently not possible to purchase the voucher, please try later.'));
                }
            );
    }

    checkJoinCard() {
        const action = this.getActionFromUrl();
        if (action === 'adesao') {
            // remove query param
            removeQueryParamWithoutHistory('action', history, location);
            return true;
        }

        return false;
    }

    getActionFromUrl() {
        return getQueryParam('action', location) || '';
    }

    render() {
        const { isLoading } = this.state;

        return (
            <>
                <Loader isLoading={ isLoading } />
                <HealthCardWithoutLogin
                    { ...this.props }
                    { ...this.state }
                    { ...this.containerFunctions }
                    { ...this.containerProps() }
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthCardWithoutLoginContainer);
