import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    CartOverlayContainer as SourceCartOverlayContainer
} from 'SourceComponent/CartOverlay/CartOverlay.container';

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { hasOutOfStockProductsInCartItems } from 'Util/Cart';
import { appendWithStoreCode } from 'Util/Url';
import { isSignedIn } from 'Util/Auth';
import { customerType } from 'Type/Account';
import { TotalsType } from 'Type/MiniCart';
import history from 'Util/History';
import BrowserDatabase from 'Util/BrowserDatabase';

import { showNotification } from 'Store/Notification/Notification.action';

import { StorePageDispatcher } from 'Store/StorePage';
import { CheckoutSaudaDispatcher } from 'Store/CheckoutSauda';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints,
    favStoreObj: state.StorePageReducer.favStoreObj
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showOutStockNotification: () => dispatch(showNotification('info', __('Remove out of stock products from cart'))),
    /*requestStore: (id) => {
        StorePageDispatcher.requestFavStore(dispatch, id);
    },*/
    /*requestSaudaPoints: () => {
        CheckoutSaudaDispatcher.getSaudaCardPoints(dispatch);
    }*/
});

export class CartOverlayContainer extends SourceCartOverlayContainer {

    static propTypes = {
        totals: TotalsType.isRequired,
        guest_checkout: PropTypes.bool,
        changeHeaderState: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        setNavigationState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        customer: customerType.isRequired
    };

    state = {
        isEditing: false,
        customerAvailablePoints: 0
    };

    containerFunctions = {
        changeHeaderState: this.changeHeaderState.bind(this),
        handleCheckoutClick: this.handleCheckoutClick.bind(this),
        handleItemQtyChange: this.handleItemQtyChange.bind(this)
    };

    componentDidMount() {
        const {
            saudaCardPoints
        } = this.props;

        if (saudaCardPoints.cardNumber) {
            this.handleItemQtyChange();
        }

        /*if(favourite_pharmacy_code) {
            requestStore(favourite_pharmacy_code);
        }*/
        // requestSaudaPoints();
    }

    componentDidUpdate(prevProps) {
        const { saudaCardPoints,  totals: { items_qty } } = this.props;
        const {
            saudaCardPoints: prevSaudaCardPoints,
            totals: { items_qty: prevItemsQty }
        } = prevProps;

        if (prevSaudaCardPoints.cardNumber !== saudaCardPoints.cardNumber || items_qty !== prevItemsQty) {
            this.handleItemQtyChange();
        }
    }

    handleItemQtyChange() {
        const { totals: { items }, saudaCardPoints } = this.props;

        if (items) {
            let customerAvailablePoints = items.reduce((a, { qty, buy_with_points, product }) => {
                if (buy_with_points && product.attributes && product.attributes.points) {
                    a -= parseInt(product.attributes.points.attribute_value * qty);
                }
                
                return a;
            }, parseInt(saudaCardPoints.points || 0));
    
            customerAvailablePoints = customerAvailablePoints < 0 ? 0 : customerAvailablePoints;
    
            this.setState({ customerAvailablePoints });
        } else {
            this.setState({ customerAvailablePoints: parseInt(saudaCardPoints.points || 0) });
        }
    }

    handleCheckoutClick(e) {
        const {
            guest_checkout,
            showOverlay,
            showNotification,
            setNavigationState,
            hideActiveOverlay,
            totals,
            customer,
            favStoreObj: { sauda },
            customer: { favourite_pharmacy_code }
        } = this.props;

        const {
            location: {
                pathname
            }
        } = history;

        // to prevent outside-click handler trigger
        e.nativeEvent.stopImmediatePropagation();

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            const hasOutOfStockProductsInCart = hasOutOfStockProductsInCartItems(totals.items);
            let redirectUrl = CHECKOUT_URL;

            if (hasOutOfStockProductsInCart) {
                showNotification('error', 'Cannot proceed to checkout. Remove out of stock products first.');
                return;
            }

            // Guest checkout enabled or user is signed in => proceed to the checkout
            if (guest_checkout || isSignedIn()) {
                if(sauda && customer && customer.sauda_card && customer.sauda_card.card_number && customer.sauda_card.is_active && !pathname.includes('/checkout-sauda')) {
                    redirectUrl = '/checkout-sauda';
                } else {
                    if (!isSignedIn() && localStorage.getItem('guest_pharmacy_code')) {
                        redirectUrl = '/checkout-signin';
                    }
                }
                hideActiveOverlay();
                history.push({ pathname: appendWithStoreCode(redirectUrl) });
                return;
            }

            // there is no mobile, as cart overlay is not visible here
            showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
            showNotification('info', __('Please sign-in to complete checkout!'));
            setNavigationState({ name: CUSTOMER_ACCOUNT_OVERLAY_KEY, title: 'Sign in' });
        } else {
            BrowserDatabase.setItem('/cart', 'redirect_to');
            //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
            hideActiveOverlay();
            history.push({ pathname: appendWithStoreCode('/selecionador-de-farmacias') });
            return;
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CartOverlayContainer);
