/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';

export const regionType = PropTypes.oneOfType([
    PropTypes.shape({
        region_code: PropTypes.string,
        region: PropTypes.string,
        region_id: PropTypes.number
    })
]);

export const addressType = PropTypes.shape({
    city: PropTypes.string,
    country_id: PropTypes.string,
    customer_id: PropTypes.number,
    default_billing: PropTypes.bool,
    default_shipping: PropTypes.bool,
    firstname: PropTypes.string,
    id: PropTypes.number,
    lastname: PropTypes.string,
    middlename: PropTypes.string,
    postcode: PropTypes.string,
    prefix: PropTypes.string,
    regionType,
    street: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    suffix: PropTypes.string,
    telephone: PropTypes.string
});

export const addressesType = PropTypes.arrayOf(addressType);

export const coPaymentList = PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string
});

export const coPaymentListExist = PropTypes.shape({
    entity_id: PropTypes.number,
    card_name: PropTypes.string,
    co_payment_system_code: PropTypes.string,
    co_payment_system_number: PropTypes.string,
    photo_card_front: PropTypes.string,
    photo_card_back: PropTypes.string
});


export const coPaymentType = PropTypes.arrayOf(coPaymentList);

export const coPaymentExistType = PropTypes.arrayOf(coPaymentListExist);

export const customerType = PropTypes.shape({
    addressesType,
    created_at: PropTypes.string,
    default_billing: PropTypes.string,
    default_shipping: PropTypes.string,
    dob: PropTypes.date,
    email: PropTypes.string,
    firstname: PropTypes.string,
    group_id: PropTypes.number,
    id: PropTypes.number,
    is_subscribed: PropTypes.bool,
    lastname: PropTypes.string,
    middlename: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    taxvat: PropTypes.string,
    photo: PropTypes.string,
    phone: PropTypes.string,
    date_of_birth: PropTypes.string,
    gender: PropTypes.number,
    doc_type: PropTypes.number,
    doc_number: PropTypes.string,
    extension_attributes: PropTypes.arrayOf(PropTypes.string),
    coPaymentType,
    coPaymentExistType
});

export const baseOrderInfoType = PropTypes.shape({
    id: PropTypes.number,
    increment_id: PropTypes.string,
    created_at: PropTypes.string,
    status_label: PropTypes.string,
    grand_total: PropTypes.number,
    subtotal: PropTypes.string
});

// TODO: remove objects
export const orderType = PropTypes.shape({
    base_order_info: baseOrderInfoType,
    order_products: PropTypes.array,
    payment_info: PropTypes.object,
    shipping_info: PropTypes.object
});

export const ordersType = PropTypes.arrayOf(orderType);

export const DASHBOARD = 'dashboard';
export const MY_ORDERS = 'my-orders';
export const MY_WISHLIST = 'my-wishlist';
export const ADDRESS_BOOK = 'address-book';
export const NEWSLETTER_SUBSCRIPTION = 'newsletter-subscription';
export const HEALTH_CARD = 'healthcard';
export const NOTIFICATION = 'notifications';
export const PHARMACY = 'pharmacy';
export const PRESCRIPTION = 'prescription';
export const CONTACTS = 'help-and-contacts';

export const activeTabType = PropTypes.string;

export const tabType = PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string
});

export const tabMapType = PropTypes.objectOf(tabType);

export const signInStateType = PropTypes.string;
