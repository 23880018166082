import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm';
import { addressType } from 'Type/Account';
import { countriesType } from 'Type/Config';
import { setAddressesInFormObject } from 'Util/Address';
import { DeviceType } from 'Type/Device';

/** @namespace Component/MyAccountAddressForm/Component */
export class MyAccountAddressForm extends FieldForm {
    static propTypes = {
        address: addressType.isRequired,
        countries: countriesType.isRequired,
        default_country: PropTypes.string,
        onSave: PropTypes.func,
        addressLinesQty: PropTypes.number.isRequired,
        showVatNumber: PropTypes.bool.isRequired,
        checkoutEditMode: PropTypes.bool.isRequired,
        activateEditMode: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        zipCodeValid: PropTypes.bool.isRequired
    };

    static defaultProps = {
        default_country: 'PT',
        onSave: () => {}
    };

    componentDidUpdate(){
        const { zipCodeValid, saveEnabled } = this.props;
        if(!zipCodeValid){
            this.setState({saveEnabled: true});
        }
    }

    __construct(props) {
        super.__construct(props);

        const {
            countries,
            default_country,
            address: { country_id, region: { region_id } = {} }
        } = props;

        const countryId = country_id || default_country;
        const country = countries.find(({ id }) => id === countryId);
        const { available_regions: availableRegions } = country || {};
        const regions = availableRegions || [{}];
        const regionId = region_id || regions[0].id;
        const saveEnabled = true;

        this.state = {
            countryId,
            availableRegions,
            regionId,
            saveEnabled
        };
    }

    onFormSuccess = (fields) => {
        const { onSave, addressLinesQty, checkoutEditMode, setIsEditing, isCheckout, setIsCreatingAddress, zipCodeValid } = this.props;
        const { save_in_address_book, region_id, region_string: region, ...newAddress } = addressLinesQty > 1
            ? setAddressesInFormObject(fields, addressLinesQty)
            : fields;
        this.setState({ saveEnabled: false });

        newAddress.region = { region_id: 0, region };

        if(save_in_address_book) {
            setIsCreatingAddress(true);
        }

        const trimmedName = newAddress.firstname.trim();
        let nameArray = trimmedName.split(' ');
        let count = nameArray.length;
        newAddress.lastname  = nameArray[(count - 1)];
        let lastNameLength = newAddress.lastname.length + 1;
        //newAddress.firstname = newAddress.firstname.replace(newAddress.lastname, '');
        newAddress.firstname = newAddress.firstname.slice(0, -lastNameLength);
        newAddress.save_in_address_book = save_in_address_book;
        onSave(newAddress);
        if(checkoutEditMode){
            setIsEditing(false);
            if(zipCodeValid){
                this.setState({ saveEnabled: true });
            }
        }
    };

    getRegionFields() {
        const { address: { region: { region } = {} } } = this.props;
        const { availableRegions, regionId } = this.state;

        // if (!availableRegions || !availableRegions.length) {
            return {
                region_string: {
                    label: __('State/Province'),
                    value: region,
                    validation: ['notEmpty'],
                    maxlength: 100
                }
            };
        // }

        // return {
        //     region_id: {
        //         label: __('State/Province'),
        //         type: 'select',
        //         selectOptions: availableRegions.map(({ id, name }) => ({ id, label: name, value: id })),
        //         onChange: (regionId) => this.setState({ regionId }),
        //         value: regionId
        //     }
        // };
    }

    onCountryChange = (countryId) => {
        const { countries } = this.props;
        const country = countries.find(({ id }) => id === countryId);
        const { available_regions } = country;

        this.setState({
            countryId,
            availableRegions: available_regions || []
        });
    };

    getFirstNameFields(label, index) {
        const { address: { firstname = '', lastname = '' }, isMyAccount } = this.props;

        let name = firstname+lastname;

        if(isMyAccount){
            name = firstname.trim()+' '+lastname;
        }

        /*if(firstname && firstname.split(' ')){
            let firstNameCount = firstname.split(' ').length;
            if(firstNameCount <= 2){
                name = firstname+lastname+' ';
            }
        } else {
            name = '';
        }*/

        return {
            label,
            value: name,
            validation: index === 0 ? ['notEmpty', 'full_name'] : [],
            maxlength: 100
        };
    }

    getStreetFields(label, index) {
        const { address: { street = [] } } = this.props;

        return {
            label,
            value: street[index],
            validation: index === 0 ? ['notEmpty'] : [],
            maxlength: 100
        };
    }


    //returns the fullname
    getFullNameFields() {
        return {
            firstname: this.getFirstNameFields(
                __('Full name'),
                0
            )
        };
    }


    // returns the address fields in quantity equal to BE
    getAddressFields() {
        const { addressLinesQty } = this.props;

        if (addressLinesQty === 1) {
            return {
                street: this.getStreetFields(
                    __('Street address'),
                    0
                )
            };
        }

        const streets = {};

        // eslint-disable-next-line fp/no-loops, fp/no-let
        for (let i = 0; i < addressLinesQty; i++) {
            streets[`street${i}`] = this.getStreetFields(
                __('Street address'),
                i
            );
            break;
        }

        return streets;

    }

    getVatField() {
        const { showVatNumber } = this.props;

        if (!showVatNumber) {
            return {};
        }

        return {
            vat_id: {
                label: __('VAT Number')
            }
        };
    }

    get fieldMap() {
        const { countryId } = this.state;
        const { countries, address, checkoutEditMode, isCheckout } = this.props;
        const { default_billing, default_shipping, region: { region } = {} } = address;

        let flagCheckout = false;
        if(checkoutEditMode){
            flagCheckout = true;
        }

        return {
            address_title: {
                label: __('Address Identifier'),
                validation: ['notEmpty'],
                maxlength: 35
            },
            ...this.getFullNameFields(),
            ...this.getAddressFields(),
            ...this.renderPostCodeAndCity(),
            country_id: {
                label: __('Country'),
                //value: 'PT',
                type: 'select',
                validation: ['notEmpty'],
                value: countryId,
                selectOptions: countries.map(({ id, label }) => ({ id, label, value: id })),
                isDisabled: true,
                disabled: true
                // onChange: this.onCountryChange
            },
            //...this.getRegionFields(),
            // region_id: {
            //     label: __('State/Province'),
            //     validation: ['notEmpty'],
            //     maxlength: 250
            // },
            // ...this.getVatField(),
            ...this.getAddressCheckBox(flagCheckout, default_billing, default_shipping, isCheckout),
            // Will be back with B2B update
            // company: {
            //     label: __('Company')
            // }
        };
    }

    renderPostCodeAndCity(){
        const { isCheckout } = this.props;

        if(isCheckout){
            return {
                city: {
                    label: __('State/Province'),
                    validation: ['notEmpty'],
                    maxlength: 100
                },
                postcode: {
                    type: 'postcode',
                    label: __('Zip/Postal code'),
                    validation: ['notEmpty', 'postcode'],
                    placeholder: '9999-999',
                    maxlength: 8
                },
            }
        } else {
            return {
                postcode: {
                    type: 'postcode',
                    label: __('Zip/Postal code'),
                    validation: ['notEmpty', 'postcode'],
                    placeholder: '9999-999',
                    maxlength: 8
                },
                city: {
                    label: __('State/Province'),
                    validation: ['notEmpty'],
                    maxlength: 100
                },
            }
        }
    }

    getAddressCheckBox(flagCheckout, default_billing, default_shipping, isCheckout) {

        if(!flagCheckout && !isCheckout){
            return {
                default_billing: {
                    type: 'checkbox',
                    label: __('This is default Billing Address'),
                    value: 'default_billing',
                    checked: default_billing
                },
                default_shipping: {
                    type: 'checkbox',
                    label: __('This is default Shipping Address'),
                    value: 'default_shipping',
                    checked: default_shipping
                }
            };
        }

        if(isCheckout){
            return {
                save_in_address_book: {
                    type: 'checkbox',
                    label: __('Save this address to my address book'),
                    value: 'save_in_address_book'
                }
            };
        }
    }

    getDefaultValues(fieldEntry) {
        const [key, { value }] = fieldEntry;
        const { address: { [key]: addressValue } } = this.props;
        return {
            ...super.getDefaultValues(fieldEntry),
            value: value !== undefined ? value : addressValue
        };
    }

    renderActions() {
        const { showEditForm, checkoutEditMode, activateEditMode, device, expandCustomAddress, isCheckout } = this.props;
        const { saveEnabled } = this.state;
        return (
            <>
            { device && !device.isMobile && checkoutEditMode && <button
                block="Button"
                mix={ { block: 'MyAccount', elem: 'Cancel' }  }
                className={ 'cancel-desktop' }
                onClick={ () => activateEditMode(false) }
                >
                { __('Cancel') }
                </button> }

            { device && !device.isMobile && isCheckout && <button
                block="Button"
                mix={ { block: 'MyAccount', elem: 'Cancel' }  }
                className={ 'cancel-desktop' }
                onClick={ () => expandCustomAddress(false) }
                >
                { __('Cancel') }
                </button> }

            <button
              type="submit"
              block="Button"
              disabled={ !saveEnabled }
              mix={ { block: 'MyAccount', elem: 'Button' } }
              formNoValidate
            >
                { __('Save Changes') }
            </button>

            { device && device.isMobile && checkoutEditMode && <button
                block="Button"
                mix={ { block: 'MyAccount', elem: 'Cancel' }  }
                onClick={ () => activateEditMode(false) }
                >
                { __('Cancel') }
                </button> }

            { device && device.isMobile && isCheckout && <button
                block="Button"
                mix={ { block: 'MyAccount', elem: 'Cancel' }  }
                onClick={ () => expandCustomAddress(false) }
                >
                { __('Cancel') }
                </button> }

            { !checkoutEditMode && !isCheckout && <button
                block="Button"
                mix={ { block: 'MyAccount', elem: 'Cancel' }  }
                onClick={ showEditForm }
                >
                { __('Cancel') }
                </button> }
            </>
        );
    }
}

export default MyAccountAddressForm;
