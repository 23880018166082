import {
    ProductPrice as SourceProductPrice,
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import {
    calculateFinalPrice,
    formatPrice,
    roundPrice,
    formatCurrency
} from 'Util/Price';
import { isSignedIn } from 'Util/Auth';
import Link from 'Component/Link';
import Loader from 'Component/Loader';

import './ProductPrice.style.override'

export class ProductPrice extends SourceProductPrice {

    static defaultProps = {
        isSchemaRequired: false,
        roundedRegularPrice: '0',
        priceCurrency: 'USD',
        discountPercentage: 0,
        formattedFinalPrice: '0',
        variantsCount: 0,
        canRenderCampaign: false,
        mix: {},
        price: {}
    };

    getCurrentPriceSchema() {
        const { isSchemaRequired, variantsCount, price, price_type } = this.props;

        if(price_type == 'max') {
            const content_price = price.maximal_price.final_price
                ? price.maximal_price.final_price.value : price.maximal_price.regular_price.value;

            if (variantsCount > 1) {
                return isSchemaRequired ? { itemProp: 'lowPrice', content: content_price } : {};
            }

            return isSchemaRequired ? { itemProp: 'price', content: content_price } : {};
        } else {
            const content_price = price.minimum_price.final_price
                ? price.minimum_price.final_price.value : price.minimum_price.regular_price.value;

            if (variantsCount > 1) {
                return isSchemaRequired ? { itemProp: 'lowPrice', content: content_price } : {};
            }

            return isSchemaRequired ? { itemProp: 'price', content: content_price } : {};
        }
    }

    renderCurrentPrice(campaignToUse) {
        const {
            discountPercentage,
            formattedFinalPrice,
            customer: { favourite_pharmacy_code },
            price: {
                minimum_price: {
                    final_price
                } = {},
            } = {},
            product
        } = this.props;

        const priceSchema = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {

            let finalPrice = parseFloat(final_price.value).toFixed(2);

            if(campaignToUse && campaignToUse.promotional_code_value) {
                if(campaignToUse.promotional_code_type_value == 'Cash'){
                    finalPrice = finalPrice - parseFloat(campaignToUse.promotional_code_value.replace(',', '.')).toFixed(2);
                } else if(campaignToUse.promotional_code_type_value == 'Percentage' && campaignToUse.max_benefit_value){
                    let discount = parseFloat(campaignToUse.promotional_code_value.replace(',', '.')).toFixed(2);
                    let maxBenefitValue = parseFloat(campaignToUse.max_benefit_value.replace(',', '.')).toFixed(2);
                    let finalDiscount = Math.min(((finalPrice * discount) / 100), maxBenefitValue);
                    finalPrice = finalPrice - finalDiscount;
                }
            }

            if(finalPrice < 0){
                finalPrice = 0;
            }

            let finalPriceFormatted = formatPrice(roundPrice(finalPrice), final_price.currency);

            return (
                <PriceSemanticElementName>
                    <span { ...priceSchema }>{finalPriceFormatted}</span>
                </PriceSemanticElementName>
            );
        } else if (priceSchema && priceSchema.hasOwnProperty('content') && priceSchema.content > 0) {
            priceSchema.content = parseFloat(priceSchema.content).toFixed(2);
            return (
                <PriceSemanticElementName>
                    <span { ...priceSchema }></span>
                </PriceSemanticElementName>
            );
        }
    }

    renderPoints() {
        const {
            points,
            customer: { favourite_pharmacy_code }
        } = this.props;

        let orLabel = favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code") ? __("or ") : '';

        return (
            <>
                <span
                    block="ProductPrice"
                    elem="Points"
                >{ orLabel +  points + __(" Points") }</span>
            </>
        );
    }

    renderConfigurablePrice() {
        const {
            points,
            customer: { favourite_pharmacy_code }
        } = this.props;

        let orLabel = favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code") ? __("or ") : '';

        return (
            <span
                block="ProductPrice"
                elem="Points"
            >{  orLabel + points + __(" Points") }</span>
        );
    }

    /*  style="
                font-size: 1.7rem;
                color: var(--primary-light-color);
    "*/

    getMaxPrice() {
        const {
            price: {
                maximal_price: {
                    final_price,
                    regular_price
                } = {},
            } = {}
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }
    }

    getMinPrice() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {},
            } = {}
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }
    }

    renderOldPrice(campaignToUse) {
        const {
            roundedRegularPrice,
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            formattedFinalPrice,
            customer: { favourite_pharmacy_code }
        } = this.props;

        if ((favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) && (campaignToUse.burn_value_type != null || campaignToUse.burn_value != null) ){
            return (
                <del
                    block="ProductPrice"
                    elem="HighPrice"
                    aria-label={ __('Old product price') }
                    { ...schema }
                >
                    { formattedFinalPrice }
                </del>
            );
            const schema = isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : {};

            if(campaignToUse && campaignToUse.promotional_code_value) {
                return (
                    <del
                        block="ProductPrice"
                        elem="HighPrice"
                        aria-label={ __('Old product price') }
                        { ...schema }
                    >
                        { formattedFinalPrice }
                    </del>
                );
            }

            if (discountPercentage === 0) {
                return null;
            }

            return (
                <del
                    block="ProductPrice"
                    elem="HighPrice"
                    aria-label={ __('Old product price') }
                    { ...schema }
                >
                    { roundedRegularPrice }
                </del>
            );
        }
    }

    renderCampaignMessage(campaignToUse, canRenderCampaign) {
        const {
            customer
        } = this.props;
        // removida verificacao sauda a pedido do cliente
        if (!canRenderCampaign) {
            return (
                ''
            )
        }

        if (!campaignToUse.burn_value_type) {
            let promoMessage = '';

            promoMessage = campaignToUse.description;
            /*
            if(campaignToUse && campaignToUse.promotional_code_value) {

                promoMessage += __("Oferta de") + " ";
                if(campaignToUse.promotional_code_type_value === 'Cash') {

                    promoMessage += campaignToUse.promotional_code_value + " € " + __("em vale");
                } else {

                    promoMessage += campaignToUse.promotional_code_value + " " + __("pontos adicionais");
                }
            }

             */

            return (
                <>
                <span
                    block="ProductPrice"
                    elem="Campaign"
                >{ promoMessage}</span>
                </>
            );
        }

    }

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {},
            } = {},
            formattedFinalPrice,
            mix,
            price_type,
            points,
            product: { campaigns },
            isLoading,
            canRenderCampaign
        } = this.props;
        let textPoints = null;

        if (price_type == 'max') {
            this.getMaxPrice();
        } else {
            this.getMinPrice();
        }

        if (!final_price || !regular_price) {
            return null;
        }

        if (points > 0) {
            textPoints =
                <>
                    { this.renderPoints() }
                </>
        }

        let maxValue      = 0;
        let campaignToUse = {};

        if ((campaigns) && (campaigns.length > 0)) {
            campaigns.map((campaign) => {
                if(campaign.burn_value_type != null || campaign.burn_value != null) {
                    if (campaign.promotional_code_type_value == 'Cash') {
                        if (parseInt(campaign.promotional_code_value) > maxValue) {
                            maxValue = parseInt(campaign.promotional_code_value);
                            campaignToUse = campaign;
                        }
                    } else if (campaign.promotional_code_type_value != 'Cash') {
                        const {product: {price_range: {maximal_price: {final_price: {value}}}}} = this.props;
                        let price = value * (parseInt(campaign.promotional_code_value) / 100);
                        if (price > maxValue) {
                            maxValue = price;
                            campaignToUse = campaign;
                        }
                    }
                }
                // check for earn campaigns label
                if (
                    campaign.promotional_code_type_value
                    && campaign.burn_value_type == null
                    && campaign.burn_value == null
                    && !campaign.pharmacy_codes
                ) {
                    campaignToUse = campaign;
                }
            });
        }

        return (
            <>
                <Loader isLoading={ isLoading } />
                <p
                    block="ProductPrice"
                    mix={ mix }
                    aria-label={ `Product price: ${formattedFinalPrice}` }
                >
                    <span block="ProductPrice" elem="TwoPrices">
                        { this.renderOldPrice(campaignToUse) }
                        { this.renderCurrentPrice(campaignToUse) }
                    </span>
                    { textPoints }
                    { this.renderCampaignMessage(campaignToUse, canRenderCampaign) }
                    { this.renderSchema() }
                </p>
            </>
        );
    }
};

export default ProductPrice;
