/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import React, { PureComponent } from 'react';
 
 import './DropdownByBlock.style';
 
 /** @namespace ScandiPWA/Blog/Component/BlogRecentPosts/Component */
 export class DropdownByBlock extends PureComponent {
    render() {
        const { fetched, showMore, fetchMore, showLess, showAction } = this.props;

        let action = () => ({});
        if (showMore) {
            action = fetchMore;
        } else {
            action = showLess;
        }
        
        return (
            <div block="DropdownByBlock">
                <div block="DropdownByBlock" elem="Container">{ fetched }</div>
                {(showAction) && <div block="DropdownByBlock" elem="Action" onClick={ action }>
                    { showMore? __('Show more') : __('Show less') }
                </div>}
            </div>
        );
    }
 }
 
 export default DropdownByBlock;
 