import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';

import {
    MyAccountDispatcher as SourceMyAccountDispatcher,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';

import MyAccountSignIn from './MyAccountSignIn.component';

//TODO: implement MyAccountDispatcher
export const MyAccountDispatcher = SourceMyAccountDispatcher;

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    device: state.ConfigReducer.device
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        state: PropTypes.string.isRequired,
        onFormError: PropTypes.func.isRequired,
        handleForgotPassword: PropTypes.func.isRequired,
        handleCreateAccount: PropTypes.func.isRequired,
        isCheckout: PropTypes.bool.isRequired,
        signIn: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        onSignIn: PropTypes.func.isRequired,
        setLoadingState: PropTypes.func.isRequired,
        emailValue: PropTypes.string,
        isEmailAvailable: PropTypes.bool,
        setSignInState: PropTypes.func,
        handleEmailInput: PropTypes.func,
        device: DeviceType.isRequired
    };

    containerProps = () => {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            device
        } = this.props;

        return {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            device
        };
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            customMessage: ''
        };
    }

    async onSignInSuccess(fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState
        } = this.props;

        try {
            await signIn(fields);
            onSignIn();
        } catch ([{ message }]) {
            setLoadingState(false);
            //showNotification('error', message);
            this.setState({
                customMessage: message
            });
        }
    }

    handleFirstLogin = () => {
        const { handleFirstLogin } = this.props;
        handleFirstLogin();
    }

    containerFunctions = {
        onSignInSuccess: this.onSignInSuccess.bind(this),
        onSignInAttempt: this.onSignInAttempt.bind(this),
        handleFirstLogin: this.handleFirstLogin.bind(this)
    };

    render() {
        return (
            <MyAccountSignIn
                { ...this.state }
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
