import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyAccountTabList from './MyAccountTabList.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';

import {
    MyAccountDispatcher as SourceMyAccountDispatcher,
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountTabListContainer as SourceMyAccountTabListContainer,
    mapStateToProps as SourceMapStateToProps,
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.container';
import {
    ADDRESS_BOOK,
    DASHBOARD,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION
} from 'Type/Account';
import { DeviceType } from 'Type/Device';
//TODO: implement MyAccountDispatcher
export const MyAccountDispatcher = SourceMyAccountDispatcher;

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(null, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
});

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    customer: state.MyAccountReducer.customer
});

export class MyAccountTabListContainer extends SourceMyAccountTabListContainer {
    // TODO implement logic
    static propTypes = {
        onSignOut: PropTypes.func,
        logout: PropTypes.func.isRequired,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        onSignOut: () => {}
    };

    containerFunctions = {
        handleLogout: this.handleLogout.bind(this),
        onClickPresNoStore: this.onClickPresNoStore.bind(this)
    };

    handleLogout() {
        const { onSignOut, logout, customer } = this.props;
        const { favourite_pharmacy_code } = customer;
        if(favourite_pharmacy_code) {
            let obj = {
                time:new Date().getTime(),
                value:3600000,
                expire:3600000,
            }
            let objStr = JSON.stringify(obj);
            localStorage.setItem('guest_pharmacy_code_expire_time',objStr);
            localStorage.setItem('guest_pharmacy_code', favourite_pharmacy_code);
        }
        localStorage.removeItem('email');
        localStorage.removeItem('access');
        localStorage.setItem('rememberMe', false);
        logout();
        onSignOut();
    }

    onClickPresNoStore() {
        const { showNotification, showPharacySelectorPopup, requestStores } = this.props;
        //showNotification('info', __('You must choose a pharmacy to access prescriptions'));
        //BrowserDatabase.setItem('/my-account/prescription', 'redirect_to');
        //history.push(appendWithStoreCode(`/selecionador-de-farmacias`));
        requestStores();
        showPharacySelectorPopup();
    }

    render() {
        return (
            <MyAccountTabList
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListContainer);
