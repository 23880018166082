import {
    CategorySortContainer as SourceCategorySortContainer,
} from 'SourceComponent/CategorySort/CategorySort.container';

export class CategorySortContainer extends SourceCategorySortContainer {

    _getLabel(option) {
        const { id, label: pureLabel } = option;

        // eslint-disable-next-line fp/no-let
        let [label] = pureLabel.split(' ');
        label = label.charAt(0).toUpperCase() + label.slice(1);

        switch (id) {
        case 'name':
            return {
                asc: __('Name: A to Z', label),
                desc: __('Name: Z to A', label)
            };
        case 'position':
            return {
                asc: __('Best match')
            };
        case 'price':
            return {
                asc: __('Price: Low to High', label),
                desc: __('Price: High to Low', label)
            };
        default:
            return {
                asc: __('%s: Ascending', label),
                desc: __('%s: Descending', label)
            };
        }
    }

};

export default CategorySortContainer;
