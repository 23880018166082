import {
    MetaDispatcher as SourceMetaDispatcher,
} from 'SourceStore/Meta/Meta.dispatcher';


import { appendWithStoreCode } from 'Util/Url';

export class MetaDispatcher extends SourceMetaDispatcher {
    // TODO implement logic

    /**
     * Get meta for product
     * @param {Object} product
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
     _getProductMeta(product) {
        const {
            name,
            meta_title,
            meta_keyword,
            canonical_url,
            meta_description
        } = product;

        let canonicalToRender = canonical_url;

        if(!canonicalToRender){
            canonicalToRender = '';
        }

        return {
            description: meta_description,
            keywords: meta_keyword,
            title: meta_title || name,
            canonical_url: `${window.location.origin}${appendWithStoreCode(canonicalToRender)}`
        };
    }

    /**
     * Get meta for category
     * @param {Object} category
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getCategoryMeta(category) {
        const {
            description, name, canonical_url,
            meta_title, meta_keyword, meta_description,
            meta_robots = 'follow, index'
        } = category;

        let canonicalToRender = canonical_url;

        if(!canonicalToRender){
            canonicalToRender = '';
        }

        return {
            description: meta_description || description,
            title: meta_title || name,
            keywords: meta_keyword,
            canonical_url: `${window.location.origin}${appendWithStoreCode(canonicalToRender)}`,
            robots: meta_robots
        };
    }
};

export default new MetaDispatcher();
