import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './HealthCardVouchersList.style';

class HealthCardVouchersList extends PureComponent {
    static propTypes = {
        cards: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    render() {
        const { cards, showMore, cardsToShow, showMoreFlag } = this.props;

        if (cards.length > 0) {
            return (
                <div block="HealthCardVouchersList">
                    <div block="HealthCardVouchersList" elem="Container">
                        { cardsToShow }
                    </div>
                    {
                        (showMoreFlag) &&
                        <div>
                            <button
                                block="HealthCardVouchersList"
                                elem="ShowMore"
                                onClick={ showMore }
                                mix={ { block: 'Button' } }
                            >{ __('Show more') }</button>
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <div class="health-card-list-empty">
                    <h3>{ __('You does not have any vouchers to use!') }</h3>
                </div>
            );
        }
    }
}

export default HealthCardVouchersList;
