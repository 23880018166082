import { PureComponent } from 'react';

import './Tooltip.style';

/** @namespace ScandiPWA/Blog/Component/BlogPostCard/Component */
export class Tooltip extends PureComponent {

    state = {
        displayTooltip: false
    }

    hideTooltip = () => {
        this.setState({displayTooltip: false})
    }

    showTooltip = () => {
        this.setState({displayTooltip: true})
    }

    render() {
        const { message, position, children } = this.props;
        const { displayTooltip } = this.state;
        return (
            <span block="Tooltip"
                onMouseLeave={ this.hideTooltip }
            >
                { displayTooltip &&
                <div block="Tooltip" elem="Bubble" mods={ { position } }>
                    <div block="Tooltip" elem="Message">
                        { message }
                    </div>
                </div> }
                <span
                    block="Tooltip" elem="Trigger"
                    onMouseOver={ this.showTooltip }
                >
                    { children }
                </span>
          </span>
        )
    }
}

export default Tooltip;
