import {
    MyAccountAddressBook as SourceMyAccountAddressBook,
} from 'SourceComponent/MyAccountAddressBook/MyAccountAddressBook.component';

import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { DeviceType } from 'Type/Device';
import Loader from 'Component/Loader';

import './MyAccountAddressBook.style.override'

import MyAccountPageManager from 'Component/MyAccountPageManager';

export class MyAccountAddressBook extends SourceMyAccountAddressBook {
    static propTypes = {
        showEditForm: PropTypes.func.isRequired,
        showHideForm: PropTypes.bool.isRequired,
        device: DeviceType.isRequired
    };

    renderAddress = (address, index) => {
        const { getDefaultPostfix, showEditForm } = this.props;
        const addressNumber = index + 1;
        const postfix = getDefaultPostfix(address);

        let title = __('Address #%s', addressNumber);
        if(address.address_title) {
            title = address.address_title;
        }

        return (
            <MyAccountAddressTable
              title={ title }
              showActions
              address={ address }
              showEditForm={ showEditForm }
              postfix={ postfix }
              key={ addressNumber }
            />
        );
    };

    renderPageManager(button) {
        let isEdit = true;

        const { title_page } = this.props;

        if(title_page == __('Add Address') || title_page == __('Edit Address')) {
            return (
                <>
                    <h1 block="MyAccount" elem="Heading" mods={{isEdit}}>{ button } { title_page }</h1>
                    <div block="MyAccountAddressBook">
                        <MyAccountPageManager
                            { ...this.props }
                        />
                    </div>
                </>
            );
        }
    }

    clickAddAddress = () => {
        const { showCreateNewPopup, showEditForm} = this.props;

        showEditForm(__('Add Address'));
    }

    renderActions() {
        const { showCreateNewPopup, showEditForm} = this.props;

        return (
            <button
              block="MyAccountAddressBook"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ this.clickAddAddress }
            >
                { __('Add new address') }
            </button>
        );
    }

    renderContent(button){
        const { device } = this.props; // Mobile Device
        const { customer } = this.props;
        let divActions =
                <div block="MyAccount" elem="Actions">
                    { this.renderActions() }
                </div>
        return (
            <>
            <Loader isLoading={ !Object.keys(customer).length } />
            <h1 block="MyAccount" elem="Heading">{ button }{ __('Address book') }</h1>
                <div block="MyAccountAddressBook">
                { this.renderAddressList() }
                { this.renderPopup() }
                { device.isMobile?null:divActions }   {/* Desktop div */}
                </div>
                { device.isMobile?divActions:null }   {/* Mobile div */}
            </>
        );
    }

    calcPagination(addresses){
        const { page, limit } = this.props;

        const addressesInPage = addresses.filter((_, index) => {
			return (index < page * limit);
        });
        return addressesInPage;
    }

    renderAddressList() {
        const { customer: { addresses = [] }, limit, page, onPageChange } = this.props;

        if (!addresses.length) {
            return this.renderNoAddresses();
        }
        
        const count = Math.ceil(addresses.length / limit);

        // split addresses
        const addressesInPage = this.calcPagination(addresses);

        return <>
                { addressesInPage.map(this.renderAddress) }

                {
                    page < count &&
                    <div block="MyAccountAddressBook" elem="ShowMore">
                        <button
                            block="MyAccountAddressBook"
                            elem="SelectButton"
                            mix={ { block: 'Button' } }
                            onClick={ () => { onPageChange(page + 1) } }
                        >{ __('Show more results') }</button>
                    </div>
                }
            </>;
    }

    render() {
        const { title, showHideForm, showEditForm}  = this.props;

        let button;
        if(showHideForm) {
            button = <button
                    block="MyAccount"
                    elem="BackButton"
                    onClick={ showEditForm }
                >
                    <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                    { __('Back') }
                </button>
        }

        return (
            <>
                { showHideForm ? this.renderPageManager(button) : this.renderContent(button) }
            </>
        );
    }

};

export default MyAccountAddressBook;
