/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import {
    SharedTransition as SourceSharedTransition,
} from 'SourceComponent/SharedTransition/SharedTransition.component';

 
 import './SharedTransition.style.override';
 
 /** @namespace Component/SharedTransition/Component */
 export class SharedTransition extends SourceSharedTransition {

 }
 
 export default SharedTransition;
 