import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MyAccountQuery from 'Query/MyAccount.query';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { fetchMutation } from 'Util/Request';

import MyAccountPrescriptionPopup from './MyAccountPrescriptionPopup.component';


export const mapStateToProps = state => ({
    payload: state.PopupReducer.popupPayload['MyAccountPrescriptionPopup'] || {},
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message))
});


export class MyAccountPrescriptionPopupContainer extends PureComponent {

    render() {
        return (
            <MyAccountPrescriptionPopup
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPrescriptionPopupContainer);
