import Image from 'Component/Image';
import pin from 'Route/StorePage/Images/pin.png';
import clock from 'Route/StorePage/Images/clock.png';
import phone from 'Route/StorePage/Images/phone.png';
import PharmacySchedule from 'Component/PharmacySchedule';
import base_logo from 'Component/MyPharmacy/Images/logo_pharmacy_default.jpg';

import './CheckoutPharmacy.style.override'
import { PureComponent } from 'react';

export class CheckoutPharmacy extends PureComponent {


    renderPharmacyInfo() {
        const { favStoreObj, baseLinkUrl, onShippingEstimationFieldsChange } = this.props;

        if(!Object.entries(favStoreObj).length) {
            return null;
        }

        const {
            name,
            street_name,
            all_schedules,
            number,
            telephone,
            telephone_secondary,
            schedule_values,
            status,
            logo,
            postal_code_zone,
            postal_code_sufix,
            postal_code_locality,
            locality
        } = favStoreObj;

        /*let customInput = {
            region: '',
            region_id: 0,
            region_code: null,
            country_id: 'PT',
            postcode: '1234-123',
            city: 'porto',
            firstname: '',
            lastname: '',
            email: '',
            telephone: '',
            street: [' '],
            customer_id: 0,
            same_as_billing: 1
        }
        onShippingEstimationFieldsChange(
            customInput,
            'pt',
            '',
            '',
            '1234-123'
        );*/

        let media = '/media/';
        let mediaUrl = baseLinkUrl.concat(media);
        let url = logo ? mediaUrl.concat(logo) : base_logo;

        let address = `${street_name} ${number} ${locality}, ${postal_code_zone}-${postal_code_sufix} `;
        
        if(postal_code_locality){
            address += `${postal_code_locality}`;
        }
        
        let storeStatusLabel = __('Closed');
        let storeSaturdays = __('Closed');
        let storeSundays = __('Closed');
        let storeWeekdays = __('Closed');

        let telephoneToShow = telephone_secondary ? telephone_secondary : telephone;

        if(status == 'open') {
            storeStatusLabel = <>{ __("Open today until ") } { schedule_values[schedule_values.length-1] }</>;
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        if(all_schedules.saturdays_start !== null) {
            storeSaturdays = <>{ all_schedules.saturdays_start } - { all_schedules.saturdays_end }</>;
        }

        if(all_schedules.sunday_start !== null) {
            storeSundays = <>{ all_schedules.sunday_start } - { all_schedules.sunday_end }</>;
        }

        if(all_schedules.holidays_start !== null) {
            storeWeekdays = <>{ all_schedules.holidays_start } - { all_schedules.holidays_end }</>;
        }

        let services = [];

        if (favStoreObj.services) {
            favStoreObj.services.forEach((item) => {
                services.push(<p block="StorePage" elem="Service_Info">{item.name}</p>);
            })
        }

        const CheckoutPharmacy = true;

        return (
            <div block="CheckoutAddressBook" mods={ { CheckoutPharmacy } }>
                <div block="CheckoutAddressBook" elem="PharmacyWrapper">
                    <div>
                        <div block="MyPharmacy" elem="PharmacyLogo">
                            <Image
                                src = {url}
                                width="70px"
                                height="50px"
                            />
                        </div>
                    </div>
                    <div>
                        <h3 block="CheckoutAddressBook" elem="PharmacyName">
                            { name || __('No store name') }
                        </h3>
                        <div block="CheckoutAddressBook" elem="IconInfo">
                            <Image
                                src={ pin }
                                width="20px"
                                height="20px"
                            />
                            <span block="MyPharmacy" elem="AddressRoute">
                                <p>{ address || __('No address') }</p>
                            </span>
                        </div>
                        <div block="CheckoutAddressBook" elem="IconInfo">
                            <Image
                                src={ phone }
                                width="20px"
                                height="20px"
                            />
                            <span block="MyPharmacy" elem="PhoneNumber">
                                <p>{ telephoneToShow  || __('No telephone')}</p>
                            </span>
                        </div>
                    </div>
                </div>
                <div block="CheckoutAddressBook" elem="Separator"></div>
                <div block="CheckoutAddressBook" elem="Section">
                        <div></div>
                        <div>
                        <h3 block="CheckoutAddressBook" elem="PharmacyHours">
                            { __('Schedule') }
                        </h3>
                        <h4 block="CheckoutAddressBook" elem="PharmacyStatus" mods={{ isOpen: (status == 'open' || status == 'open_24'), isClosing: status == 'closing' }}>
                            { storeStatusLabel }
                        </h4>
                        <div block="MyPharmacy" elem="PharmacyHours">
                            { <PharmacySchedule storeObj={ favStoreObj } /> }
                        </div>
                        </div>
                </div>
            </div>
        );
    }

    render() {

        return (<>
                { this.renderPharmacyInfo() }
            </>
        );
    }

};

export default CheckoutPharmacy;
