import {
    CartPage as SourceCartPage,
} from 'SourceRoute/CartPage/CartPage.component';

import './CartPage.style.override'
import ContentWrapper from 'Component/ContentWrapper';
import Image from 'Component/Image';
import CartItem from 'Component/CartItem';
import CartCoupon from 'Component/CartCoupon';
import CartFloating from 'Component/CartFloating';
import CmsBlock from 'Component/CmsBlock';
import banner from './images/BANNER.png';
import { isSignedIn } from 'Util/Auth';
import { Redirect } from 'react-router';
import Loader from 'Component/Loader';
import PropTypes, { objectOf } from 'prop-types';
export class CartPage extends SourceCartPage {

    static propTypes = {
        setActiveCampaigns: PropTypes.func.isRequired,
        activeCampaigns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired
    }

    renderCartItems() {
        const {
            totals: { items, quote_currency_code, is_wallet },
            isPointsActive,
            changePoints,
            handleItemQtyChange,
            customerAvailablePoints,
            getAvailablePointsWithoutItem,
            isLoadedSaudaCardPoint,
            isFetchingBuyProductWithPoints,
            isCartFetching,
            setActiveCampaigns,
            activeCampaigns,
            isFetchingCampaignStatus,
            customer
        } = this.props;

        if (!items || items.length < 1) {
            return (
                <p block="CartPage" elem="Empty">{ __('There are no products in cart.') }</p>
            );
        }

        const showLoading = isFetchingCampaignStatus || isCartFetching || isFetchingBuyProductWithPoints || (!isLoadedSaudaCardPoint && customer.length > 0);
        return (
            <>
                { showLoading && <Loader isLoading={true} /> }
                <p block="CartPage" elem="TableHead" aria-hidden>
                    <span>{ __('Product') }</span>
                    <span>{ __('Price') }</span>
                    <span>{ __('Quantity') }</span>
                    <span>{ __('subtotal') }</span>
                    <span>{ __('Delete') }</span>
                </p>
                <ul block="CartPage" elem="Items" aria-label="List of items in cart">
                    { items.map((item) => (
                        <CartItem
                            isPointsActive={ isPointsActive }
                            changePoints={ changePoints }
                            key={ item.item_id }
                            item={ item }
                            currency_code={ quote_currency_code }
                            isEditing
                            isLikeTable
                            isCartPage={ true }
                            updateCrossSellsOnRemove
                            is_wallet={ ((is_wallet && is_wallet == item.sku) ? item.sku : null) }
                            handleItemQtyChange={ handleItemQtyChange }
                            customerAvailablePoints={ customerAvailablePoints }
                            getAvailablePointsWithoutItem={ getAvailablePointsWithoutItem }
                            setActiveCampaigns={ setActiveCampaigns }
                            activeCampaigns={ activeCampaigns }
                        />
                    )) }
                </ul>
            </>
        );
    }

    renderPromoContent() {
        const { cart_content: { cart_cms } = {} } = window.contentConfiguration;

        if (cart_cms) {
            return <CmsBlock identifier={ cart_cms } />;
        }

        return (
            <figure
            block="CartPage"
            elem="PromoBlock"
            >
                <figcaption block="CartPage" elem="PromoText">
                    <span><b>{ __('Free shipping') }</b></span>
                    <span>{ __('on order 50€ and more') }</span>
                </figcaption>
            </figure>
        );
    }

    renderSecureCheckoutButton() {
        const { onCheckoutButtonClick, hasOutOfStockProductsInCart } = this.props;

        if (hasOutOfStockProductsInCart) {
            return (
                <div block="CartPage" elem="OutOfStockProductsWarning">
                    { __('Remove out of stock products from cart') }
                </div>
            );
        }

        return (
            <button
            block="CartPage"
            elem="CheckoutButton"
            mix={ { block: 'Button' } }
            onClick={ onCheckoutButtonClick }
            >
                <span />
                { __('Next') }
            </button>
        );
    }

    renderDiscountCode() {
        return (
            <>
                <h4 block="CartPage" elem="SubHeading">{ __('Promotional code') }</h4>
                <div block="CartPage" elem="Discount">
                    <CartCoupon  />
                </div>
            </>
        );
    }

    render() {
        if (!isSignedIn()) {
            if (!localStorage.getItem('guest_pharmacy_code')) {
                return <Redirect to="/" />;
            }
        }

        const { isLoading, device: { isMobile }, favStoreObj: { sauda }, saudaCardPoints: { cardNumber } } = this.props;
        let MobileCart, DesktopCart = null;

        if(isMobile) {
            MobileCart = <CartFloating
                            isMobile = { isMobile }
                            { ...this.props }
                        />
        } else {
            DesktopCart = <CartFloating
                                { ...this.props }
                            />
        }

        let cmsId = '';
        if(sauda && isSignedIn() && !cardNumber) {
            cmsId = 'cart_page_banner_sauda';
        } else if(sauda && !isSignedIn()) {
            cmsId = 'cart_page_banner_guest';
        } else if(!sauda) {
            cmsId = 'cart_page_banner';
        }

        return (
            <main block="CartPage" aria-label="Cart Page">
                <ContentWrapper
                    wrapperMix={ { block: 'CartPage', elem: 'Wrapper' } }
                    label="Cart page details"
                >
                    { this.renderHeading() }
                    <div
                        block="CartPage"
                        elem="Banner"
                    >
                        {cmsId && (
                            <CmsBlock identifier={ cmsId } />
                        )}
                    </div>
                    <div
                        block="CartPage"
                        elem="Content"
                    >
                        <div block="CartPage" elem="Static">
                            { this.renderCartItems() }
                            { isMobile ? null:this.renderTotalDetails(true) }
                            { sauda ? this.renderDiscountCode() : null }
                            { this.renderCrossSellProducts() }
                        </div>
                        <div block="CartPage" elem="Floating">
                            { DesktopCart }
                        </div>
                    </div>
                    { MobileCart }
                </ContentWrapper>
            </main>
        );
    }
};

export default CartPage;
