import {
    CheckoutGuestForm as SourceCheckoutGuestForm,
} from 'SourceComponent/CheckoutGuestForm/CheckoutGuestForm.component';

import FormPortal from 'Component/FormPortal';

import './CheckoutGuestForm.style.override'

export class CheckoutGuestForm extends SourceCheckoutGuestForm {

    renderGuestForm() {
        const { formId } = this.props;
        return (
            <>
                <FormPortal
                  id={ formId }
                  name="CheckoutGuestForm"
                >
                    { this.renderFields() }
                    { this.renderCreateUserCheckbox() }
                </FormPortal>
            </>
        );
    }

};

export default CheckoutGuestForm;
