import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ProductActionsContainer as SourceProductActionsContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/ProductActions/ProductActions.container';

import { customerType } from 'Type/Account';
import { StorePageDispatcher } from '../../store/StorePage';
import { CheckoutSaudaDispatcher } from '../../store/CheckoutSauda';
import { isSignedIn } from 'Util/Auth';
import { showNotification } from 'Store/Notification/Notification.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';
import { showPopup } from 'Store/Popup/Popup.action';

import history from 'Util/History';
import {
    convertQueryStringToKeyValuePairs,
    setQueryParams
} from 'Util/Url';

import ProductActions from './ProductActions.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    favStoreObj: state.StorePageReducer.favStoreObj,
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
    /*requestStore: (id) => {
        StorePageDispatcher.requestFavStore(dispatch, id);
    },*/
    /*requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),*/
    /*requestSaudaPoints: () => {
        CheckoutSaudaDispatcher.getSaudaCardPoints(dispatch);
    }*/
});

export class ProductActionsContainer extends SourceProductActionsContainer {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        customer: customerType.isRequired,
        //requestSaudaPoints: PropTypes.func.isRequired,
    };

    state = {
        hasStock: true,
        defaultProduct: false,
        isSignedIn: isSignedIn()
    }

    containerFunctions = {
        showOnlyIfLoaded: this.showOnlyIfLoaded.bind(this),
        onProductValidationError: this.onProductValidationError.bind(this),
        getIsOptionInCurrentVariant: this.getIsOptionInCurrentVariant.bind(this),
        setQuantity: this.setQuantity.bind(this),
        setGroupedProductQuantity: this._setGroupedProductQuantity.bind(this),
        clearGroupedProductQuantity: this._clearGroupedProductQuantity.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        isSauda: this.isSauda.bind(this),
        setHasStock: this.setHasStock.bind(this),
        isProductOutStock: this.isProductOutStock.bind(this),
    };


    setHasStock(status) {
        this.setState({ hasStock: status });
    }

    /*componentWillMount() {
        const {
            requestCustomerData,
            customer,
            isSignedIn
        } = this.props;
        
        if (isSignedIn && !Object.keys(customer).length) {
            requestCustomerData();
        }
    }*/

    /*componentDidMount() {
        const {
            requestStore,
            customer: { favourite_pharmacy_code },
            requestSaudaPoints
        } = this.props;

        if(favourite_pharmacy_code) {
            requestStore(favourite_pharmacy_code);
        }

        requestSaudaPoints();
    }*/

    isSauda() {
        const { favStoreObj: { sauda }, customer: { sauda_card, favourite_pharmacy_code } } = this.props;
        const { isSignedIn } = this.state;

        if (!isSignedIn) {
            return sauda;
        }

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            return sauda && sauda_card;
        }

        return true;
    }

    isProductOutStock() {
        const {
            product,
            product: { variants = [] },
            configurableVariantIndex
        } = this.props;

        const {
            stock_status,
            custom_attributes
        } = variants[configurableVariantIndex] || product;
    
        if (stock_status === 'OUT_OF_STOCK') {
            // item is out of stock
            return true;
        }
    
        let customStock = false;
        if (custom_attributes && custom_attributes.length > 0) {
            for (let i=0; i<custom_attributes.length; i++) {
                if (custom_attributes[i].attribute_code == 'custom_stock') {
                    if (custom_attributes[i].attribute_values[0] && custom_attributes[i].attribute_values[0] != 'Com Stock') {
                        customStock = true;
                        break;
                    }
                }
            }
        }
    
        return customStock;
    }

    getIsConfigurableAttributeAvailable({ attribute_code, attribute_value }) {
        const { parameters, product: { variants } } = this.props;

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

        // If value matches current attribute_value, option should be enabled
        if (isAttributeSelected && parameters[attribute_code] === attribute_value) {
            return true;
        }

        const parameterPairs = Object.entries(parameters);

        const selectedAttributes = isAttributeSelected
            // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
            ? parameterPairs.filter(([key]) => key !== attribute_code)
            : parameterPairs;

        return variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return (
                    stock_status === 'IN_STOCK'
                    // Variant must have currently checked attribute_code and attribute_value
                    && foundValue === attribute_value
                    // Variant must have all currently selected attributes
                    && selectedAttributes.every(([key, value]) => attributes[key] && attributes[key].attribute_value === value)
                );
            });
    }

    render() {
        const { favStoreObj } = this.props;

        return (
            <ProductActions
                { ...this.props }
                { ...this.state }
                { ...this.containerProps() }
                { ...this.containerFunctions }
                favStoreObj = { favStoreObj }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);