import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes,
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.style.override'
import ExpandableContent from 'Component/ExpandableContent';
import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';
export class ProductConfigurableAttributes extends SourceProductConfigurableAttributes {
    renderConfigurableAttributes() {
        const {
            configurable_options,
            isContentExpanded,
            getSubHeading
        } = this.props;

        return Object.values(configurable_options).map((option) => {
            const {
                attribute_label,
                attribute_code,
                attribute_options
            } = option;

            const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;
            let heading = null;

            switch (attribute_label) {
                case 'Odor':
                    heading = __("Odor");
                    break;
                case 'Flavor':
                    heading = __("Flavor");
                    break;
                case 'Capacity':
                    heading = __("Capacity");
                    break;
                case 'Quantity':
                    heading = __("Packing Quantity");
                    break;
                case 'Color':
                    heading = __("Color");
                    break;
                case 'Alphabetic Sizes':
                    heading = __("Alphabetic Sizes");
                    break;
                case 'Numeric Sizes':
                    heading = __("Numeric Sizes");
                    break;
                default:
                    break;
            }

            const isDropdown = !isSwatch;

            return (
                <ExpandableContent
                  key={ attribute_code }
                  heading={ heading != null ? heading : attribute_label }
                  subHeading={ getSubHeading(option) }
                  mix={ {
                      block: 'ProductConfigurableAttributes',
                      elem: 'Expandable',
                      mods: { isDropdown }
                  } }
                  isContentExpanded={ isContentExpanded }
                >
                    { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option) }
                </ExpandableContent>
            );
        });
    }
};

export default ProductConfigurableAttributes;
