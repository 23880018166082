import {
    CartOverlay as SourceCartOverlay,
} from 'SourceComponent/CartOverlay/CartOverlay.component';

import './CartOverlay.style.override.scss'

import CartItem from 'Component/CartItem';
import  icon_sauda_card  from 'Component/CartItem/images/card.png';
import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';
import { roundPrice } from 'Util/Price';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { formatPrice } from 'Util/Price';

export class CartOverlay extends SourceCartOverlay {

    renderCartItems() {
        const { totals: { items, quote_currency_code, is_wallet }, handleItemQtyChange } = this.props;

        if (!items || items.length < 1) {
            return this.renderNoCartItems();
        }

        return (
            <ul block="CartOverlay" elem="Items" aria-label="List of items in cart">
                { items.map((item) => (
                    <CartItem
                      key={ item.item_id }
                      item={ item }
                      currency_code={ quote_currency_code }
                      isEditing
                      isMiniCart={ true }
                      is_wallet={ is_wallet }
                      handleItemQtyChange={ handleItemQtyChange }
                    />
                )) }
            </ul>
        );
    }

    renderSecureCheckoutButton() {
        const { handleCheckoutClick, hasOutOfStockProductsInCart, showOutStockNotification, customer: { favourite_pharmacy_code } } = this.props;

        let action = handleCheckoutClick;
        if (hasOutOfStockProductsInCart) {
            action = showOutStockNotification;
        }

        let disabled = true;
        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            disabled = false;
        }

        return (
            <button
              block="CartOverlay"
              elem="CheckoutButton"
              mix={ { block: 'Button' } }
              onClick={ action }
              //disabled={ disabled }
            >
                { __('Complete Purchase') }
            </button>
        );
    }

    renderCardPoints() {
        const { favStoreObj: { sauda }, saudaCardPoints, customerAvailablePoints } = this.props;
        if(sauda && saudaCardPoints && saudaCardPoints.points) {
            return(
                <>
                    <img
                        src={ icon_sauda_card }
                        width="30px"
                        height="30px"
                    />
                    { __('Your card has ') }
                    <span>{ customerAvailablePoints } { __('points') }</span>
                    { __(' available') }
                </>
            );
        }
    }

    renderTotals() {
        const { totals: { items, subtotal_incl_tax = 0, subtotal_with_discount, is_wallet }, customer: { favourite_pharmacy_code } } = this.props;

        let points = 0;
        items.forEach(item => {
            if (item.buy_with_points) {
                points = points + parseInt(item.product_points);
            }
        });

        let renderPoints = '';

        if(points > 0){
            renderPoints = ' + '+points+' pts';
        }

        return (
            <>
                <dl
                block="CartOverlay"
                elem="ProductCount"
                >
                    <dt></dt>
                    <dd>{ items.length } {__('product(s)')}</dd>
                </dl>
                <dl
                block="CartOverlay"
                elem="Total"
                >
                    <dt>{ this.renderCardPoints() }</dt>
                    <dd>{ favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code") ? this.renderPriceLine(roundPrice(subtotal_with_discount), renderPoints) : '' }</dd>
                </dl>
                {
                    is_wallet && <dl block="CartOverlay" elem="Notice">
                        <br></br>
                        <span class="cms-notice">
                            <FontAwesomeIcon className="CartOverlay-AlertIcon" icon={ faExclamationCircle } />
                            <CmsBlock identifier={ "mini_cart_wallet_notice" } />
                        </span>
                    </dl>
                }
            </>
        );
    }

    renderPriceLine(price, renderPoints) {
        const { currencyCode, totals: { is_wallet } } = this.props;
        if(is_wallet){
            return (
                <>
                    <FontAwesomeIcon className="CartOverlay-AlertIcon" icon={ faExclamationCircle } />
                    { formatPrice(price, currencyCode) } { renderPoints }
                </>
            );
        }
        return (<>{ formatPrice(price, currencyCode) } { renderPoints }</>);
    }

    renderCartAdditional() {
        const { totals: { items } } = this.props;

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <div block="CartOverlay" elem="Additional">
                { this.renderTotals() }
                { /* this.renderOutOfStockProductsWarning() */ }
                { this.renderActions() }
            </div>
        );
    }

    renderActions() {
        const { hideActiveOverlay } = this.props;

        return (
            <div block="CartOverlay" elem="Actions">
                <Link
                  block="CartOverlay"
                  elem="CartButton"
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  to="/cart"
                  onClick={ hideActiveOverlay }
                >
                    { __('View cart') }
                </Link>
                { this.renderSecureCheckoutButton() }
            </div>
        );
    }

    renderPromo() {
        const { minicart_content: { minicart_cms } = {} } = window.contentConfiguration;

        if (minicart_cms) {
            return <CmsBlock identifier={ minicart_cms } />;
        }
    }

};

export default CartOverlay;
