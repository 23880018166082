import {
    CategoryPagination as SourceCategoryPagination,
} from 'SourceComponent/CategoryPagination/CategoryPagination.component';

import CategoryPaginationLink from 'Component/CategoryPaginationLink';
import './CategoryPagination.style.override.scss';

export class CategoryPagination extends SourceCategoryPagination {

    renderPageLinks() {
        const {
            totalPages,
            paginationFrame,
            paginationFrameSkip,
            currentPage
        } = this.props;

        let morePages = totalPages > 6 && (totalPages - 6) > currentPage;
        let pages = [];
        let i;

        const pF = morePages ? (paginationFrame - 3) : paginationFrame;
        const pfS = morePages ? (paginationFrameSkip - 2) : paginationFrameSkip;

        // Render next pagination links
        for (i = currentPage; i <= currentPage + pF; i++) {
            if (i <= totalPages && pages.length <= pfS) {
                pages.push(this.renderPageLink(
                    i,
                    __('Page %s', i),
                    i.toString(),
                    i === currentPage
                ));
            }
        }

        // Render previous pagination links if necessary
        for (i = 1; i < currentPage; i++) {
            if (pages.length < pF) {
                const id = currentPage - i;
                const pageData = this.renderPageLink(
                    id,
                    __('Page %s', id),
                    id.toString()
                );

                pages = [pageData, ...pages];
            }
        }

        // Edge case for rendering correct count of next links when current page is 1
        if (currentPage === 1 && pages.length < totalPages) {
            for (i = pages.length + 1; i <= pF; i++) {
                pages.push(this.renderPageLink(
                    i,
                    __('Page %s', i),
                    i.toString()
                ));
            }
        }

        // Render last links when total pages is greather than 6
        if (morePages) {

            pages.push(<li class="CategoryPagination-ListItem"><span class=" CategoryPaginationLink">...</span></li>);

            pages.push(this.renderPageLink(
                totalPages,
                    __('Page %s', totalPages),
                    totalPages.toString()
                ));
        }

        if (totalPages > 6 && currentPage > 2) {
            let pageData = [];

            let firstPage = 1;
            pageData.push(this.renderPageLink(
                firstPage,
                __('Page %s', firstPage),
                firstPage.toString()
            ));

            pageData.push(<li class="CategoryPagination-ListItem"><span class=" CategoryPaginationLink">...</span></li>);

            pages = [pageData, ...pages];

        }

        return pages;
    }

    renderPageLink(
        pageNumber,
        label,
        children,
        isCurrent = false
    ) {
        const {
            pathname,
            onPageSelect,
            getSearchQuery
        } = this.props;

        return (
            <li
              key={ pageNumber }
              block="CategoryPagination"
              elem="ListItem"
              mods={ { isArrow: (label == __('Next page') || label == __('Previous page')) } }
            >
                <CategoryPaginationLink
                  label={ label }
                  url_path={ pathname }
                  getPage={ onPageSelect }
                  isCurrent={ isCurrent }
                  pageNumber={ pageNumber }
                  getSearchQueryForPage={ getSearchQuery }
                >
                    { children }
                </CategoryPaginationLink>
            </li>
        );
    }

}

export default CategoryPagination;
