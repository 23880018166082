import {
    MyAccountQuery as SourceMyAccountQuery,
} from 'SourceQuery/MyAccount.query';

import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field } from 'Util/Query';

export class MyAccountQuery extends SourceMyAccountQuery {
    // TODO implement logic
    /**
     * Get SignIn mutation
     * @param {{email: String, password: String}} options
     * @return {Field}
     * @memberof MyAccount
     */
    getSignInMutation(options) {
        const { email, password, rememberMe } = options;
        const guestQuoteId = BrowserDatabase.getItem(GUEST_QUOTE_ID);

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addArgument('rememberMe', 'String!', rememberMe)
            .addArgument('guest_quote_id', 'String', guestQuoteId)
            .addField('token')
            .addField('email')
            .addField('access');
    }

    getCampaignAttrId() {
        return new Field('getCampaignAttrId')
            .addField('id');
    }

    getCustomerQuery() {
        return this._getCustomerField();
    }

    getCreateSaudaMutation(options) {
        return new Field('createSauda')
            .addArgument('input', 'SaudaInput!', options)
            .addFieldList(this._getSaudaFields());
    }

    getCreateSaudaWithoutLoginMutation(options) {
        return new Field('createSaudaWithoutLogin')
            .addArgument('input', 'SaudaWithoutLoginInput!', options)
            .addFieldList(this._getSaudaWithoutLoginFields());
    }

    getUpdatePhotoMutation(options) {
        return new Field('updateCustomerPhoto')
            .addArgument('input', 'CustomerPhotoInput!', options)
            .addFieldList(this._getCustomerPhotoField());
    }

    getCreateCoPaymentMutation(options) {
        return new Field('createCustomerCoPayment')
            .addArgument('input', 'CustomerCoPaymentInput!', options)
            .addFieldList(this._getCoPaymentExistFields());
    }

    getUpdateCoPaymentMutation(id, options) {
        return new Field('updateCustomerCoPayment')
            .addArgument('id', 'Int!', id)
            .addArgument('input', 'CustomerCoPaymentInput!', options)
            .addFieldList(this._getCoPaymentExistFields());
    }

    getDeleteCoPaymentMutation(id) {
        return new Field('deleteCustomerCoPayment')
            .addArgument('id', 'Int!', id);
    }

    _getCustomerField() {
        return new Field('customer')
            .addFieldList(this._getCustomerFields());
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'photo',
            'phone',
            'date_of_birth',
            'gender',
            'doc_type',
            'doc_number',
            'contact_preference',
            'allow_sms',
            'allow_sauda',
            'extension_attributes',
            'favourite_pharmacy_code',
            'preference_pharmacy_code',
            this._getCustomerSaudaField(),
            this._getAddressesField(),
            this._getCoPaymentField(),
            this._getCoPaymentExistField(),
            this._getCampaignsField()
        ];
    }

    _getCampaignsField() {
        return new Field('vouchers')
            .addFieldList(this._getCampaignsFields());
    }

    _getBurnConditionsField() {
        return new Field('burnConditions')
            .addFieldList(this._getBurnConditionsFields());
    }

    _getBurnConditionsFields() {
        return [
            this._getExclusivePharmaciesField(),
            'isPharmacyExclusive',
            this._getVouchersProductsField(),
            'redemptionChannel'
        ];
    }

    _getVouchersProductsField() {
        return new Field('products')
            .addFieldList(this._getVouchersProductsFields());
    }

    _getVouchersProductsFields(){
        return [
            'code',
            'description',
            'points',
            'pointsIncrease',
            'prescriptionCode',
            'price',
            'reference',
            'type'
        ];
    }

    _getExclusivePharmaciesField(){
        return new Field('exclusivePharmacies')
            .addFieldList(this._getExclusivePharmaciesFields());
    }

    _getExclusivePharmaciesFields() {
        return [
            'code',
            'name'
        ];
    }

    _getBurnConditionsField() {
        return new Field('burnConditions')
            .addFieldList(this._getBurnConditionsFields());
    }

    _getDetailedDescriptionField() {
        return new Field('detailedDescription')
            .addFieldList(this._getDetailedDescriptionFields());
    }

    _getDetailedDescriptionFields() {
        return [
            'description',
            'sponsor',
            'title',
            'usageMessage'
        ];
    }

    _getCampaignsFields() {
        return [
            'startDate',
            this._getBurnConditionsField(),
            'code',
            'creationDate',
            this._getDetailedDescriptionField(),
            'endDate',
            'expiryAlert',
            'imageId',
            'maxBenefitValue',
            'paymentType',
            'reference',
            'singleUseVoucher',
            'status',
            'type',
            'usedDate',
            'value',
            'valueType',
        ];
    }

    _getSaudaFields() {
        return [
            'phone',
            'date_of_birth',
            'gender',
            'doc_type',
            'doc_number',
            'address',
            'postcode',
            'locality',
            'conditions',
            'status',
            'message'
        ];
    }

    _getSaudaWithoutLoginFields() {
        return [
            'status',
            'healthcard'
        ];
    }

    _getCustomerPhotoField() {
        return [
            'photo'
        ];
    }

    _getAddressFields() {
        return [
            'id',
            'address_title',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            this._getRegionField(),
            'save_in_address_book'
        ];
    }

    _getCustomerSaudaField() {
        return new Field('sauda_card')
            .addFieldList(this._getCustomerSaudaFields());
    }

    _getCustomerSaudaFields() {
        return [
            'card_number',
            'client_number',
            'is_active',
            'status'
        ];
    }

    _getCoPaymentField() {
        return new Field('coPaymentList')
            .addFieldList(this._getCoPaymentFields());
    }

    _getCoPaymentFields() {
        return [
            'code',
            'name',
            'id'
        ];
    }

    _getCoPaymentExistField() {
        return new Field('customerCoPayment')
            .addFieldList(this._getCoPaymentExistFields());
    }

    _getCoPaymentExistFields() {
        return [
            'entity_id',
            'card_name',
            'co_payment_system_code',
            'co_payment_system_number',
            'photo_card_front',
            'photo_card_back'
        ];
    }

    getResetPasswordMutation(options) {
        const { token, password, password_confirmation, email } = options;

        return new Field('resetPassword')
            .addArgument('token', 'String!', token)
            .addArgument('resetPasswordToken', 'String!', token)
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addArgument('newPassword', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addField('status');
    }

    getResetPasswordMutationNew(options) {
        const { token, password, password_confirmation, date_of_birth, accept_terms } = options;

        return new Field('resetPasswordNew')
            .addArgument('token', 'String!', token)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addArgument('date_of_birth', 'String', date_of_birth)
            .addArgument('accept_terms', 'String', accept_terms)
            .addField('status');
    }

    sendSmsSauda(phone) {
        return new Field('smsSaudaPhone')
            .addArgument('phone', 'String!', phone)
            .addField('code')
            .addField('status')
            .addField(this._getCardDetails())
            .addField(this._getPositions());
    }

    _getCardDetails() {
        return new Field('cardDetails')
            .addFieldList(this._getCardDetailsFields());
    }

    _getPositions() {
        return new Field('positionsInfo')
            .addFieldList(this._getPositionsFields());
    }

    _getCardDetailsFields(){
        return [
            'number',
            'name',
            'allowPromotions',
            'isFamilyCard',
            'isFamilyHead',
            this._getCardDetailsClient()
        ]
    }

    _getPositionsFields() {
        return [
            'requestId',
            'securityPositions'
        ];
    }

    _getCardDetailsClient(){
        return new Field('client')
            .addFieldList(this._getCardDetailsClientFields());
    }

    _getCardDetailsClientFields(){
        return [
            'address',
            'billing',
            'birthDate',
            'contactPhone',
            'documentNumber',
            'documentType',
            'emailAddress',
            'fiscalNumber',
            'gender',
            'householdSize',
            'locale',
            'name',
            'number',
            'postalCode',
            'preferentialPharmacyCode'
        ];
    }

    sendAssociateSaudaCard(cardDetails, positions, positionsValidation) {
        const {
            number,
            name,
            allowPromotions,
            isFamilyCard,
            client
        } = cardDetails;

        const {
            positionFirst,
            positionSecond,
            positionThird
        } = positionsValidation;


        let positionsValidate = {
            'requestId' : positions.requestId,
            'cardNumber' : cardDetails.number,
            'email': cardDetails.client.emailAddress,
            'positionNumbers': positionsValidation
        }

        return new Field('associateSaudaCard')
        .addArgument('input', 'SaudaCardDetailsInput!', cardDetails)
        .addArgument('positions', 'PositionValidateInput!', positionsValidate)
        .addField('message')
        .addField('status');
        /*.addArgument('number', 'String!', number)
        .addArgument('name', 'String!', name)
        .addArgument('allowPromotions', 'String!', allowPromotions)
        .addArgument('isFamilyCard', 'String!', isFamilyCard)
        .addFieldList(this.setClientAssociateSaudaCard(client));*/
    }

    checkEmailActive(email) {
        return new Field('checkEmailActive')
        .addArgument('email', 'String!', email)
        .addField('redirect');
    }

    checkTokenActive(token) {
        return new Field('checkTokenActive')
        .addArgument('token', 'String!', token)
        .addField('redirect');
    }

    setClientAssociateSaudaCard(client) {
        const {
            address,
            billing,
            birthDate,
            contactPhone,
            documentNumber,
            documentType,
            emailAddress,
            fiscalNumber,
            gender,
            householdSize,
            locale,
            name,
            number,
            postalCode,
            preferentialPharmacyCode
        } = client;
        return new Field('client')
            .addArgument('address', 'String!', address)
            .addArgument('billing', 'String!', billing)
            .addArgument('birthDate', 'String!', birthDate)
            .addArgument('contactPhone', 'String!', contactPhone)
            .addArgument('documentNumber', 'String!', documentNumber)
            .addArgument('documentType', 'String!', documentType)
            .addArgument('emailAddress', 'String!', emailAddress)
            .addArgument('fiscalNumber', 'String!', fiscalNumber)
            .addArgument('gender', 'String!', gender)
            .addArgument('householdSize', 'String!', householdSize)
            .addArgument('locale', 'String!', locale)
            .addArgument('name', 'String!', name)
            .addArgument('number', 'String!', number)
            .addArgument('postalCode', 'String!', postalCode)
            .addArgument('preferentialPharmacyCode', 'String!', preferentialPharmacyCode);

    }

    storePrescription(copySms, manualInsert, smsContent, prescriptionNumber, accessCode, optionCode) {
        return new Field('addPrescription')
        .addArgument('copySms', 'Boolean!', copySms)
        .addArgument('manualInsert', 'Boolean!', manualInsert)
        .addArgument('smsContent', 'String!', smsContent)
        .addArgument('prescriptionNumber', 'String!', prescriptionNumber)
        .addArgument('accessCode', 'String!', accessCode)
        .addArgument('optionCode', 'String!', optionCode)
        .addField('message')
        .addField('entity_id')
        .addField('status');
    }

    _getPrescriptionsList() {
        return new Field('getPrescriptionList')
            .addFieldList(this._getPrescriptionListResponse());
    }

    _getPrescriptionListResponse(){
        return [
            'status',
            'message',
            this._getPrescriptionItemList()
        ]
    }

    _getPrescriptionItemList(){
        return new Field('data')
        .addFieldList(this._getPrescriptionItemListFields())
    }

    _getPrescriptionItemListFields(){
        return [
            'entity_id',
            'customer_id',
            'pharmacy_code',
            'number',
            'access_code',
            'option_code',
            'last_update',
            'status',
            'forfeit',
            'type',
            'created_at',
            'updated_at',
            'name',
            'medical_speciality',
            'lines'
        ]
    }

    _getPrescriptionDetails(prescriptionNumber, accessCode) {
        return new Field('getPrescriptionDetails')
            .addArgument('prescriptionNumber', 'String!', prescriptionNumber)
            .addArgument('prescriptionAccessCode', 'String!', accessCode)
            .addFieldList(this._getPrescriptionDetailsResponse());
    }

    _getPrescriptionDetailsResponse(){
        return [
            'status',
            'message',
            this._getPrescriptionDetailsResponseData()
        ]
    }

    _getPrescriptionDetailsResponseData(){
        return new Field('data')
        .addFieldList(this._getPrescriptionDetailsResponseFields());
    }

    _getPrescriptionDetailsResponseFields(){
        return [
            "prescriptionNumber",
            "prescriptionAccess",
            "prescriptionResponseDateTime",
            "prescriptionPatientName",
            "prescriptionStatus",
            "prescriptionForfeit",
            "prescriptionDate",
            "prescriptionLocal",
            "prescriptionPrescriptor",
            "medicalSpeciality",
            this._getPrescriptionDetailsResponseDataLines()
        ]
    }

    _getPrescriptionDetailsResponseDataLines(){
        return new Field('prescriptionLines')
        .addFieldList(this._getPrescriptionDetailsResponseDataLinesFields());
    }

    _getPrescriptionDetailsResponseDataLinesFields(){
        return [
            "prescriptionLineCode",
            "prescriptionLineForfeit",
            "prescriptionLineStatus",
            "prescriptionLineDescription",
            "prescriptionLineDosage",
            "prescriptionLineQuantity",
            "prescriptionLineType"
        ]
    }

    _addToCart(code, description, dosage, quantity, codeType, maxQty, prescriptionNumber){
        return new Field('addPrescriptionItem')
            .addArgument('prescriptionLineCode', 'String!', code)
            .addArgument('prescriptionLineDescription', 'String!', description)
            .addArgument('prescriptionLineDosage', 'String!', dosage)
            .addArgument('prescriptionLineQuantity', 'String!', quantity)
            .addArgument('prescriptionLineCodeType', 'String!', codeType)
            .addArgument('maxQty', 'String!', maxQty)
            .addArgument('prescriptionNumber', 'String!', prescriptionNumber)
            .addField('message')
            .addField('status');
    }

    getDeletePrescription(id) {
        return new Field('deletePrescription')
            .addArgument('id', 'Int!', id)
            .addField('message')
            .addField('status');
    }


    changePointsToVoucher(cardNumber, voucher){
        return new Field('changePointsToVoucher')
            .addArgument('cardNumber', 'String!', cardNumber)
            .addArgument('voucher', 'VoucherObjectInput', voucher)
            .addField('message')
            .addField('status');
    }

    checkCustomerIsImported(token){
        return new Field('checkCustomerIsImported')
            .addArgument('token', 'String!', token)
            .addField('message')
            .addField('status');
    }

    updateSaudaCard(){
        return new Field('updateSaudaCard')
            .addField('message')
            .addField('status');
    }
};

export default new MyAccountQuery();
