import {
    MyAccountMyWishlist as SourceMyAccountMyWishlist,
} from 'SourceComponent/MyAccountMyWishlist/MyAccountMyWishlist.component';

import './MyAccountMyWishlist.style.override'

export class MyAccountMyWishlist extends SourceMyAccountMyWishlist {
    // TODO implement logic

    render() {
        return (
            <div block="MyAccountMyWishlist">
                { this.renderActionBar() }
                { this.renderShareWishlist() }
                { this.renderContent() }
            </div>
        );
    }

    renderActionBar() {
        return (<></>);
    }
};

export default MyAccountMyWishlist;
