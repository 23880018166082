import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyAccountPrescriptionDetails from './MyAccountPrescriptionDetails.component';
import { showPopup } from 'Store/Popup/Popup.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'SourceUtil/Request';
import MyAccountQuery from 'Query/MyAccount.query';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const mapStateToProps = (state) => ({
    currency_code: state.ConfigReducer.default_display_currency_code,
    device: state.ConfigReducer.device,
});

export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showEditPopup: (payload) => dispatch(showPopup("MyAccountPrescriptionPopup", payload)),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
    showExpire2DaysPopup: (payload) => dispatch(showPopup("MyAccountPrescriptionDetailsExpire2Days", payload)),
});

export class MyAccountPrescriptionDetailsContainer extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            increment_id: PropTypes.string
        }).isRequired,
        showNotification: PropTypes.func.isRequired,
        currency_code: PropTypes.string.isRequired,
        updateCart: PropTypes.func.isRequired,
        syncCart: PropTypes.func.isRequired,
        showExpire2DaysPopup: PropTypes.func.isRequired
    };

    state = {
        prescriptonDetails: [],
        isLoading: true,
        quantity: 1
    };

    onInfoClick() {
        const { showEditPopup, prescription } = this.props;

        showEditPopup({
            action: "QRCODE_PRESCRIPTION",
            title: __('Prescription Details'),
            prescription
        });
    }

    setQuantity(value) {
        this.setState({ quantity: +value });
    }

    componentDidMount() {
        this.getPrescriptionDetails();
    }

    getPrescriptionDetails(){
        const { showNotification, prescription: { number, access_code, status } } = this.props;
        //if(status != 'Dispensed/Expired') {
            this.setState({isLoading: true});
            const query = MyAccountQuery._getPrescriptionDetails(number, access_code);
            fetchQuery(query).then(
                ({ getPrescriptionDetails }) => {
                    this.setState({isLoading: false});
                    if(getPrescriptionDetails.status && getPrescriptionDetails.status == 200) {
                        this.setState({prescriptonDetails: getPrescriptionDetails.data})
                    }
                    if(getPrescriptionDetails.message) {
                        showNotification('error', getPrescriptionDetails.message);
                    }
                },
                () => {
                    this.setState({isLoading: false});
                    showNotification('error', getPrescriptionDetails.message);
                }
            )
        //}
    }

    addToCart(code, description, dosage, quantity, maxQty, codeType, prescriptionNumber) {
        const { updateCart, showNotification, syncCart } = this.props;

        if (description == null) {
            description = 'N/A';
        }
        if (dosage == null) {
            dosage = 'N/A';
        }
        this.setState({isLoading: true});
        const query = MyAccountQuery._addToCart(code, description, dosage, quantity, codeType, maxQty, prescriptionNumber);
        fetchQuery(query).then(
            ({ addPrescriptionItem }) => {
                this.setState({isLoading: false});
                if(addPrescriptionItem.status && addPrescriptionItem.status == 200) {
                    showNotification('success', addPrescriptionItem.message);
                    updateCart().then(
                        () => {
                            syncCart();
                        }
                    );
                } else {
                    let msg = '';
                    try {
                        msg = JSON.parse(addPrescriptionItem.message);
                    } catch (e) {
                        msg = addPrescriptionItem.message;
                    }
                    if(msg == 'Os produtos desta encomenda não foram adicionados ao Carrinho por serem sujeitos a Receita Médica. Para a dispensa de Receitas Médicas, por favor, aceda ao menu Receitas Médicas.'){
                        showNotification('error', msg);
                    } else {
                        showNotification('info', msg);
                    }
                }
            },
            () => {
                this.setState({isLoading: false});
                let msg = '';
                try {
                    msg = JSON.parse(addPrescriptionItem.message);
                } catch (e) {
                    msg = addPrescriptionItem.message;
                }
                if(msg == 'Alguns dos produtos desta encomenda não foram adicionados ao Carrinho por serem sujeitos a Receita Médica. Para a dispensa de Receitas Médicas, por favor, aceda ao menu Receitas Médicas.'){
                    showNotification('info', msg);
                } else {
                    showNotification('error', msg);
                }
            }
        )
    }

    containerProps = () => {
        const { isLoading } = this.state;
        const { currency_code } = this.props;

        return {
            isLoading,
            currency_code
        };
    };

    showExpire2DaysPopup() {
        showExpire2DaysPopup();
    }

    containerFunctions = {
        onInfoClick: this.onInfoClick.bind(this),
        addToCart: this.addToCart.bind(this),
        setQuantity: this.setQuantity.bind(this),
        showExpire2DaysPopup: this.showExpire2DaysPopup.bind(this),
        getPrescriptionDetails: this.getPrescriptionDetails.bind(this)
    };

    render() {
        return (
            <MyAccountPrescriptionDetails
              { ...this.containerFunctions }
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPrescriptionDetailsContainer);
