import {
    CategoryFilterOverlay as SourceCategoryFilterOverlay,
} from 'SourceComponent/CategoryFilterOverlay/CategoryFilterOverlay.component';
import Overlay from 'Component/Overlay';
import { CATEGORY_FILTER_OVERLAY_ID } from './CategoryFilterOverlay.config';
import { DeviceType } from 'Type/Device';
import PropTypes from 'prop-types';
import CategoryConfigurableAttributes from 'Component/CategoryConfigurableAttributes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import ResetButton from 'Component/ResetButton';

import './CategoryFilterOverlay.style.override'
import { faWindowRestore } from '@fortawesome/pro-solid-svg-icons';
import {setQueryParams} from "Util/Url/Url";

export class CategoryFilterOverlay extends SourceCategoryFilterOverlay {

    static propTypes = {
        availableFilters: PropTypes.objectOf(PropTypes.shape).isRequired,
        areFiltersEmpty: PropTypes.bool.isRequired,
        isContentFiltered: PropTypes.bool.isRequired,
        isMatchingInfoFilter: PropTypes.bool,
        isInfoLoading: PropTypes.bool.isRequired,
        isProductsLoading: PropTypes.bool.isRequired,
        onSeeResultsClick: PropTypes.func.isRequired,
        onVisible: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        customFiltersValues: PropTypes.objectOf(PropTypes.array).isRequired,
        toggleCustomFilter: PropTypes.func.isRequired,
        getFilterUrl: PropTypes.func.isRequired,
        totalPages: PropTypes.number.isRequired,
        device: DeviceType.isRequired,
    };

    state = {
        minPrice: 0,
        maxPrice: 0,
        filters: [{}]
    }

    renderHeading() {
        return (
                <h2 block="CategoryFilterOverlay" elem="Heading">
                    { __('Filter to') }
                </h2>
        );
    }

    renderMobileHeading() {
        const { onSeeResultsClick} = this.props;
        return (
            <h3 block="CategoryFilterOverlay" elem="MobileHeading">
                { __('Filter Results') }
                <span block="CategoryFilterOverlay" elem="MobileHeadingClose" onClick={() => onSeeResultsClick()}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </h3>
        );
    }

    renderSeeResults() {
        const { onSeeResultsClick } = this.props;

        return (
            <div
              block="CategoryFilterOverlay"
              elem="SeeResults"
            >
                <button
                  block="CategoryFilterOverlay"
                  elem="Button"
                  mix={ { block: 'Button' } }
                  onClick={ onSeeResultsClick }
                >
                    { __('See results') }
                </button>
            </div>
        );
    }

    renderDefaultFilters() {

        const { totalItems, device } = this.props;

        if(device.isMobile){
            return (
                <div
                    block="CategoryFilterOverlay"
                    elem="FilterMobile">
                    { this.renderMobileHeading() }
                    { this.renderResetFilters() }
                    { this.renderFilters() }
                    { this.renderSeeResults() }
                </div>
            );
        }else {
            return (
                <div
                    block="CategoryFilterOverlay"
                    elem="FilterDesktop">
                    { this.renderHeading() }
                    { this.renderResetFilters() }
                    { this.renderFilters() }
                    { this.renderSeeResults() }
                </div>
            );
        }
    }

    /*renderFilters() {
        const {
            availableFilters,
            customFiltersValues,
            toggleCustomFilter,
            isMatchingInfoFilter,
            getFilterUrl
        } = this.props;

        return (
            <div>
                <h3 block="CategoryFilterOverlay" elem="FilterTitle">{ __('Filter to') }</h3>
                <CategoryConfigurableAttributes
                    mix={ { block: 'CategoryFilterOverlay', elem: 'Attributes' } }
                    isReady={ isMatchingInfoFilter }
                    configurable_options={ availableFilters }
                    getLink={ getFilterUrl }
                    parameters={ customFiltersValues }
                    updateConfigurableVariant={ toggleCustomFilter }
                />
            </div>
        );
    }*/

    renderResetFilters() {
        const { minPrice, maxPrice } = this.state;

        let activeFilters = this.getActiveFilters();
        let filters = [];
        filters.push(Object.entries(activeFilters).map(([label, value], i) => (
            this.renderMapFilters(label, value, i)
        )));
        if (minPrice != 0 || maxPrice != 0) {
            filters.push(this.getPricesFilters());
            if (filters.length > 0) {
                return (
                    <div class="custom-filter-reset-group">
                        { filters }
                    </div>
                );
            }
        }
    }

    getPricesFilters() {
        const { minPrice, maxPrice } = this.state;

        return (
            <>
                <span className="custom-filter-reset" onClick={() => this.deleteFilter()}>
                    €{minPrice} - €{maxPrice}
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </>
        );
    }

    renderMapFilters(label, value, i) {
        if (value) {

            if (label == 'priceMax') {
                this.setState({ maxPrice: value });
            } else if (label == 'priceMin') {
                this.setState({ minPrice: value });
            }
        }
    }

    deleteFilter = () => {
        this.setState({
            maxPrice: 0,
            minPrice: 0
        });
        const { maxPrice, minPrice } = this.state;
        let currentUrl = window.location.href;
        let allFilters = currentUrl.split("?");
        let filters = allFilters[1] ? allFilters[1].split("&") : [];
        let newFilters = "";
        let count = 0;
        for (let i=0; i<filters.length; i++) {
            if ((!filters[i].includes('priceMax=')) && (!filters[i].includes('priceMin='))) {
                if (count == 0) {
                    newFilters = '?' + newFilters + filters[i];
                } else {
                    newFilters = newFilters + '&' + filters[i];
                }
            }
        }
        let newUrl = window.location.pathname + newFilters;
        history.push({ pathname: appendWithStoreCode(newUrl) });
        window.location.reload();
    }

    cleanFiltersActive() {
        this.setState({
            maxPrice: 0,
            minPrice: 0
        });
    }

    getActiveFilters() {
        const { location: { search }, history } = this.props;
        const decoded = decodeURIComponent(search.replace(/\+/g, " "));

        if(decoded !== search){
            history.push({ search: decoded });
        }

        return decoded.substr(1).split('&').reduce((acc, part) => {
            const [key, value] = part.split('=');
            return { ...acc, [key]: value };
        }, {});
    }

    renderFilters() {
        const {
            availableFilters,
            customFiltersValues,
            toggleCustomFilter,
            isMatchingInfoFilter,
            getFilterUrl,
            favStoreObj: { sauda },
            customer: { sauda_card, favourite_pharmacy_code }
        } = this.props;

        if(availableFilters && availableFilters.brand && availableFilters.brand.attribute_values){
            this.removeOldBrands(availableFilters.brand.attribute_values);
        }

        const { filters } = this.state;

        let hasSaudaCard = sauda_card && sauda_card.card_number !== null;

        let configurable_options = {};
        // check if pharmacy is not sauda or client doesnt have sauda card
        if (sauda != 1) {
            let attrToCheck = ['price'];
            if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
                //attrToCheck = ['has_points', 'sauda'];
                attrToCheck = ['sauda_offers'];
            }
            configurable_options = Object.keys(availableFilters)
                .reduce((a,c) => {
                    if (!attrToCheck.includes(availableFilters[c].attribute_code)) {
                        a[c] = availableFilters[c];
                    }
                    return a;
                }, {});
        } else {
            configurable_options = availableFilters;
        }

        if(configurable_options.length > 0){
            filters.push(configurable_options);
        }

        return (
            <CategoryConfigurableAttributes
              mix={ { block: 'CategoryFilterOverlay', elem: 'Attributes' } }
              isReady={ isMatchingInfoFilter }
              configurable_options={ configurable_options }
              getLink={ getFilterUrl }
              parameters={ customFiltersValues }
              updateConfigurableVariant={ toggleCustomFilter }
            />
        );
    }

    removeOldBrands(values) {

        const { location, history } = this.props;

        let filters = this.getActiveFilters();

        if( (Array.isArray(filters) || typeof filters === 'object') &&
            filters["customFilters"] &&
            typeof filters["customFilters"] === 'string'
        ){
            let customFilters = filters["customFilters"].split(";");
            let bransdByCode = [];

            for(let i = 0;i < customFilters.length;i++){
                if(customFilters[i]){
                    let splited = customFilters[i].split(":");
                    bransdByCode[splited[0]] = splited[1].split(",");
                }
            }

            filters["customFilters"] = bransdByCode;

            if(filters["customFilters"]["brand"] &&
                Array.isArray(filters["customFilters"]["brand"])
            ){
                let brands = [];

                for (let i = 0; i < filters["customFilters"]["brand"].length; i++) {
                    let brand = filters["customFilters"]["brand"][i];
                    if (values.includes(brand)) {
                        brands.push(brand);
                    }
                }
                if(filters["customFilters"]["brand"].length === brands.length){
                    return;
                }

                filters["customFilters"]["brand"] = brands;

                let customFiltersString = "";

                for (const key in filters["customFilters"]){
                    if(filters["customFilters"][key].length > 0){
                        let value = filters["customFilters"][key];
                        customFiltersString += key + ":" + value.join(",") + ";";
                    }
                }

                customFiltersString = customFiltersString.slice(0,-1);

                setQueryParams({
                    customFilters: customFiltersString,
                    page: ''
                }, location, history);
            }
        }

    }

    renderNoResults() {
        return (
            <p block="CategoryFilterOverlay" elem="NoResults">
                { __(`The selected filter combination returned no results. Please try again, using a different set of filters.`) }
            </p>
        );
    }

    renderResetButton() {
        const { onSeeResultsClick } = this.props;

        return (
            <ResetButton
              onClick={ () => {
                    this.cleanFiltersActive();
                    onSeeResultsClick();
              } }
              mix={ { block: 'CategoryFilterOverlay', elem: 'ResetButton' } }
            />
        );
    }

    render() {
        const {
            onVisible,
            onHide,
            totalPages,
            isProductsLoading,
            isContentFiltered
        } = this.props;

        if (!isProductsLoading && totalPages === 0 && !isContentFiltered) {
            return (
                <div block="CategoryFilterOverlay" />
            );
        }

        return (
            <Overlay
              onVisible={ onVisible }
              onHide={ onHide }
              mix={ { block: 'CategoryFilterOverlay' } }
              id={ CATEGORY_FILTER_OVERLAY_ID }
              isRenderInPortal={ false }
            >
                <div block="CategoryFilterOverlay"
                    elem="DefaultFilters">
                    { this.renderContent() }
                    { this.renderLoader() }
                </div>
            </Overlay>
        );
    }
};

export default CategoryFilterOverlay;
