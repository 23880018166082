import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateMeta } from 'SourceStore/Meta/Meta.action';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { LocationType } from 'SourceType/Common';
import { getQueryParam } from 'SourceUtil/Url';

import {
    MyAccountDispatcher as SourceMyAccountDispatcher,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    PasswordChangePageContainer as SourcePasswordChangePageContainer,
    BreadcrumbsDispatcher,
} from 'SourceRoute/PasswordChangePage/PasswordChangePage.container';

import {
    STATUS_PASSWORD_MISS_MATCH,
    STATUS_PASSWORD_UPDATED
} from './PasswordChangePage.config';
import PasswordChangePage from './PasswordChangePage.component';
import { Redirect } from 'react-router';
import { fetchQuery } from 'Util/Request';
import MyAccountQuery from 'Query/MyAccount.query';

export {
    BreadcrumbsDispatcher,
};

//TODO: implement MyAccountDispatcher
export const MyAccountDispatcher = SourceMyAccountDispatcher;

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    device: state.ConfigReducer.device
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class PasswordChangePageContainer extends SourcePasswordChangePageContainer {
    // TODO implement logic
    static propTypes = {
        updateMeta: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        passwordResetStatus: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]).isRequired,
        resetPassword: PropTypes.func.isRequired,
        location: LocationType.isRequired
    };

    state = {
        passwordResetStatus: '',
        isLoading: false,
        showErrorMessagePassword: false,
        redirect: false,
        idFlag: false,
        showDob: false
    };

    componentDidMount() {
        const { showNotification } = this.props;
        this.updateMeta();
        this.updateBreadcrumbs();

        const token = getQueryParam('token', location);

        if(getQueryParam('id', location)) {
            this.setState({ idFlag: true });
        }

        this.setState({ isLoading: true });
        const query = MyAccountQuery.checkTokenActive(token);
        fetchQuery(query).then(
            ({ checkTokenActive }) => {
                if(checkTokenActive && checkTokenActive.redirect) {
                    showNotification('error', __('Your password reset link has expired.'));
                    this.setState({ redirect: true });
                } else {
                    this.setState({ isLoading: false });
                }
            },
            (error) => {
                this.setState({ isLoading: false });
                console.log(error)
            }
        );



        //check if customer is imported
        const queryImport = MyAccountQuery.checkCustomerIsImported(token);
        fetchQuery(queryImport).then(
            ({ checkCustomerIsImported }) => {
                if(checkCustomerIsImported.message == 'is_import'){
                    this.setState({ showDob: true });
                } else {
                    this.setState({ showDob: false });
                }
            },
            (error) => {
                this.setState({ showDob: false });
                console.log(error)
            }
        );
    }

    onError() {
        this.setState({ isLoading: false });
    }

    onPasswordSuccess(fields) {
        this.setState({ isLoading: true });
        const { resetPassword, location } = this.props;
        const { newPassword: password, newPasswordRepeat: password_confirmation, date_of_birth: date_of_birth, accept_terms: accept_terms } = fields;
        const token = getQueryParam('token', location);

        resetPassword({ token, password, password_confirmation, date_of_birth, accept_terms });

    }

    render() {
        const { passwordResetStatus } = this.state;

        if (passwordResetStatus === STATUS_PASSWORD_UPDATED) {
            return <Redirect to="/" />;
        }

        return (
            <PasswordChangePage
              { ...this.containerProps() }
              { ...this.state }
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChangePageContainer);
