import {
    SearchPage as SourceSearchPage,
} from 'SourceRoute/SearchPage/SearchPage.component';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';

import './SearchPage.style.override'

export class SearchPage extends SourceSearchPage {
    // TODO implement logic

    renderSearchHeading() {
        const { search, totalItems } = this.props;

        return (
            <div
              block="SearchPage"
              elem="TotalItems"
            //   mix={ {
            //       block: 'SearchPage',
            //       elem: 'Heading'
            //   } }
            >
                <span
                    block="SearchPage"
                    elem="TotalItemsCount">{totalItems} </span>
                <span
                    block="SearchPage"
                    elem="TotalItemsLabel">{ __('Search results for: ') }</span>
                <span
                    block="SearchPage"
                    elem="TotalItemsWord">"</span>
                {/* <span
                    block="SearchPage"
                    elem="TotalItemsWord">{ search }</span> */}
                <span
                    block="SearchPage"
                    elem="">"test12312</span>
            </div>
        );
    }

    renderMiscellaneous() {
        const { totalItems, isLoading, device } = this.props;

        if (!this.displayProducts()) {
            return null;
        }

        const loaded = !isLoading;

        if(device.isMobile){
            return (
                <div block="CategoryPage" elem="MiscellaneousMobile">
                    <aside block="CategoryPage" elem="MiscellaneousMobileSort">
                        { this.renderFilterButton() }
                        { this.renderCategorySort() }
                    </aside>
                    <div block="CategoryPage" elem="MiscellaneousLineProductCount">
                        <div block="CategoryPage" elem="MiscellaneousProductCount">
                            { this.renderItemsCounter() }
                            { this.renderIcons() }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <aside block="CategoryPage" elem="Miscellaneous" mods={ { loaded } }>
                    { !isLoading && this.renderItemsCounter() }
                    { !isLoading && this.renderFilterButton() }
                    { this.renderCategorySort() }
                </aside>
            );
        }
    }

    renderCategoryDetails() {
        return (
            <article block="CategoryDetails">
                <div block="CategoryDetails" elem="Description">
                    {/* { this.renderSearchHeading() } */}
                </div>
            </article>
        );
    }

    render() {
        const hideProducts = !this.displayProducts();
        const { totalItems, isLoading, checkIsFilterActive, customer, showPharacySelectorPopup, activeOverlay,requestStores } = this.props;

        const SearchProductsMissing = !isLoading && totalItems === 0 && !checkIsFilterActive();

        let favourite_pharmacy_code = null;
        let user = customer;

        if (!customer.favourite_pharmacy_code) {
            if (JSON.parse(localStorage.getItem('customer'))) {
                user = JSON.parse(localStorage.getItem('customer'));
                if (user.data.favourite_pharmacy_code) {
                    favourite_pharmacy_code = user.data.favourite_pharmacy_code;
                }
            }

            if (favourite_pharmacy_code == null) {
                if (localStorage.getItem('guest_pharmacy_code')) {
                    favourite_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
                }
            }
        } else {
            favourite_pharmacy_code = customer.favourite_pharmacy_code;
        }


        if(favourite_pharmacy_code == null){
            //window.location.href = "/localizador-de-farmacias";
            //history.push(appendWithStoreCode('/selecionador-de-farmacias'));
            if (activeOverlay !== 'PharmacySelector') {
                requestStores();
                showPharacySelectorPopup();
            }
        }

        return (
            <main block="CategoryPage">
                <Loader isLoading={ isLoading } />
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts, SearchProductsMissing }
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
};

export default SearchPage;
