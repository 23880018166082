import { connect } from 'react-redux';

import {
    MyAccountMyWishlistContainer as SourceMyAccountMyWishlistContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/MyAccountMyWishlist/MyAccountMyWishlist.container';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MyAccountMyWishlistContainer extends SourceMyAccountMyWishlistContainer {
    addAllToCart = () => {
        const { moveWishlistToCart } = this.props;

        this.setState({ isLoading: true });

        return moveWishlistToCart().then(
            /** @namespace Component/MyAccountMyWishlist/Container/moveWishlistToCartThen */
            () => this.showNotificationAndRemoveLoading(__('Wishlist moved to cart'))
        );
    };

    showNotificationAndRemoveLoading(message) {
        this.setState({ isLoading: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountMyWishlistContainer);
