// TODO update this import when renamed
import {
    SAUDA_DETAILS,
    SAUDA_CARD_POINTS,
    SAUDA_CARD_MATRIX,
    UPDATE_SAUDA_CARD_MATRIX,
    UPDATE_SAUDA_DISCOUNT,
    CART_BUY_PRODUCT_WITH_POINTS,
    IS_FETCHING,
    IS_FETCHING_BUY_PRODUCT_WITH_POINTS,
    CART_CHANGE_CAMPAIGN,
    IS_FETCHING_CHANGE_CAMPAIGN
} from './CheckoutSauda.action';

export const initialState = {
    saudaDetails: {},
    setIsFetching: {},
    getSaudaCardPoints: {},
    getSaudaCardMatrix: {},
    matrix: {},
    discount: '',
    buyProductWithPoints: '',
    isFetchingBuyProductWithPoints: false,
    isLoadedSaudaCardPoint: false,
    isLoadedSaudaCardMatrix: false,
    isFetching: false,
    cartChangeCampaign: '',
    isFetchingChangeCampaign: false
};

export const CheckoutSaudaReducer = (state = initialState, action) => {

    const { getSaudaCardMatrix, saudaDetails, getSaudaCardPoints, type, matrix, isFetching, buyProductWithPoints, isFetchingBuyProductWithPoints, cartChangeCampaign, isFetchingChangeCampaign } = action;
    switch (type) {

    case SAUDA_DETAILS:
        return {
            ...state,
            saudaDetails
        };

    case IS_FETCHING:
        return {
            ...state,
            isFetching
        };

    case SAUDA_CARD_POINTS:
        return {
            ...state,
            getSaudaCardPoints,
            isLoadedSaudaCardPoint: true
        };

    case SAUDA_CARD_MATRIX:
        return {
            ...state,
            getSaudaCardMatrix,
            isLoadedSaudaCardMatrix: true
        };

    case UPDATE_SAUDA_CARD_MATRIX:
        return {
            ...state,
            matrix
        };

    case UPDATE_SAUDA_DISCOUNT:
        return {
            ...state,
            discount
        };

    case CART_BUY_PRODUCT_WITH_POINTS:
        return {
            ...state,
            buyProductWithPoints
        };

    case IS_FETCHING_BUY_PRODUCT_WITH_POINTS:
        return {
            ...state,
            isFetchingBuyProductWithPoints
        };

    case CART_CHANGE_CAMPAIGN:
        return {
            ...state,
            cartChangeCampaign
        };

    case IS_FETCHING_CHANGE_CAMPAIGN:
        return {
            ...state,
            isFetchingChangeCampaign
        };

    default:
        return state;
    }
};

export default CheckoutSaudaReducer;
