import {
    SearchOverlay as SourceSearchOverlay,
} from 'SourceComponent/SearchOverlay/SearchOverlay.component';

import PropTypes from 'prop-types';

import Overlay from 'Component/Overlay';
import SearchItem from 'Component/SearchItem';
import { SearchItemsType } from 'Type/Search';

import { fetchQuery } from 'Util/Request';

import BlogPostsQuery from 'Query/BlogPosts.query';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';


export class SearchPostsOverlay extends SourceSearchOverlay {

    static propTypes = {
        searchCriteria: PropTypes.string,
        searchResults: SearchItemsType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        makeSearchRequest: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        isHideOverlay: PropTypes.bool,
        getPostsResults: PropTypes.func.isRequired,
        postsResults: PropTypes.arrayOf(PropTypes.string).isRequired,
        onSearchEnterPress: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        searchCriteria: '',
        isHideOverlay: false
    };

    /**
     * Only display results after user type
     */
    renderNoSearchCriteria() {
        return null;
    }

    render() {
        const { isHideOverlay, searchCriteria } = this.props;

        if (isHideOverlay) {
            return (
                <article
                  block="SearchOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { this.renderSearchResults() }
                </article>
            );
        }

        if(searchCriteria != '') {
            return (
                <Overlay
                  id="search"
                  mix={ { block: 'SearchOverlay' } }
                >
                    { this.renderSearchCriteria() }
                    <article
                      block="SearchOverlay"
                      elem="Results"
                      aria-label="Search results"
                    >
                        { this.renderSearchResults() }
                    </article>
                </Overlay>
            );
        } else {
            return (
                <></>
            );
        }
    }

    renderSearchItem(product, i) {
        const { closeSearch, clearSearch } = this.props;
        if(i < 4){
            return (
                <SearchItem
                product={ product }
                closeSearch={ closeSearch }
                clearSearch={ clearSearch }
                key={ i }
                />
            );
        }
    }

    renderSearchBlogItem(postsItems, i) {
        let product = {};
        if(i < 3){
            return (
                <SearchItem
                    postsItems={ postsItems }
                    product={ product }
                  key={ i }
                />
            );
        }
    }

    renderSearchResults() {
        const { searchCriteria, searchResults, isLoading, postsResults, getPostsResults } = this.props;

        let itemsPosts = [];

        if(postsResults && postsResults.posts) {
            if(postsResults.posts.items && postsResults.posts.items.length > 0){
                itemsPosts = postsResults.posts.items;
            }
        }

        if (!searchCriteria.trim()) {
            return this.renderNoSearchCriteria();
        }

        if (!searchResults.length && !isLoading && !this.timeout && (!itemsPosts || itemsPosts.length == 0)) {
            return this.renderBothNoResults();
        } else if (!searchResults.length && !isLoading && !this.timeout && (itemsPosts && itemsPosts.length > 0)) {
            return (
                <>
                    <ul class="search-blog-posts">
                        <p><strong>{ __('Blog') }</strong></p>
                        { itemsPosts.map((item, i) => this.renderSearchBlogItem(item, i)) }
                        <li class="search-posts-view-more" onClick={() => this.viewMorePosts()}>{ __('View more') }</li>
                    </ul>
                </>
            );
        }

        if (itemsPosts.length > 0) {
            return (
                <>
                    <ul class="search-blog-posts">
                        <p><strong>{ __('Blog') }</strong></p>
                        { itemsPosts.map((item, i) => this.renderSearchBlogItem(item, i)) }
                        <li class="search-posts-view-more" onClick={() => this.viewMorePosts()}>{ __('View more') }</li>
                    </ul>
                </>
            );
        } else {
            /*return (
                <ul>
                    { resultsToRender.map((item, i) => this.renderSearchItem(item, i)) }
                </ul>
            );*/
            return (
                <>
                    <ul class="search-blog-posts">
                        <p><strong>{ __('Blog') }</strong></p>
                        { this.renderNoResults() }
                    </ul>
                </>
            );
        }
    }

    renderBothNoResults() {
        const { device: { isMobile } } = this.props;;

        if (isMobile) {
            return (
                <div block="SearchOverlay" elem="NoResults">
                    <h2>{__('Sorry!')}</h2>
                    <h3>{__('There were no products found matching your request.')}</h3>
                    <p>{__('Please, check your spelling and search again, or try another term.')}</p>
                </div>
            );
        }

        return <p>{ __('No results found!') }</p>;
    }

    renderNoResults() {
        return <p>{ __('No results found!') }</p>;
    }

    viewMoreProducts = () => {
        const { onSearchEnterPress } = this.props;

        let e = {key: 'Enter'};

        onSearchEnterPress(e);
    }

    viewMorePosts = () => {
        const { searchCriteria, hideActiveOverlay, closeSearch } = this.props;
        const search = searchCriteria.trim().replace(/\s\s+/g, '%20');
        const trimmedSearch = searchCriteria.trim();
        const desired = trimmedSearch.replace(/[\/|\!“@#£$§%€&\[\]\(\){}=\?‘«»ºª\+\\*<>]/gi, '');
        hideActiveOverlay();
        closeSearch();

        history.push(appendWithStoreCode(`/blog/search/${ desired }`));
    }

    searchPosts(search) {
        const query = BlogPostsQuery._searchPosts(search);

        fetchQuery(query).then(
            /** @namespace Store/Order/Dispatcher/requestOrdersFetchQueryThen */
            ({ getSearchPosts: posts }) => {
                this.setState({ postsResults: posts })
            },
            /** @namespace Store/Order/Dispatcher/requestOrdersFetchQueryError */
            (error) => {
                console.log('error search posts', error);
            }
        );
    }

};

export default SearchPostsOverlay;
