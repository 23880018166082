import {
    HOME_TAB as SourceHOME_TAB,
    MENU_TAB as SourceMENU_TAB,
    ACCOUNT_TAB as SourceACCOUNT_TAB,
    CART_TAB as SourceCART_TAB,
    CHECKOUT_TAB as SourceCHECKOUT_TAB,
} from 'SourceComponent/NavigationTabs/NavigationTabs.config';

export const HOME_TAB = SourceHOME_TAB;
export const MENU_TAB = SourceMENU_TAB;
export const ACCOUNT_TAB = SourceACCOUNT_TAB;
export const CART_TAB = SourceCART_TAB;
export const CHECKOUT_TAB = SourceCHECKOUT_TAB;
