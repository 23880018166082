import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ADD_ADDRESS, ADDRESS_POPUP_ID } from 'Component/MyAccountAddressPopup/MyAccountAddressPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { customerType } from 'Type/Account';
import MyAccountQuery from 'Query/MyAccount.query';
import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { fetchMutation } from 'Util/Request';
import { COPAYMENT_POPUP_ID } from 'Component/MyAccountPageManager/MyAccountPageManager.config';

import {
    MyAccountDashboardContainer as SourceMyAccountDashboardContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/MyAccountDashboard/MyAccountDashboard.container';

import MyAccountDashboard from './MyAccountDashboard.component';

import { updateMeta } from 'Store/Meta/Meta.action';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    //TODO extend mapStatetoProps
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showPopup: (payload) => dispatch(showPopup(ADDRESS_POPUP_ID, payload)),
    updateCustomer: (customer) => dispatch(updateCustomerDetails(customer)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showDeletePopup: (payload) => dispatch(showPopup(COPAYMENT_POPUP_ID, payload)),
    updateCustomerDetails: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
});

export class MyAccountDashboardContainer extends SourceMyAccountDashboardContainer {

    static propTypes = {
        customer: customerType.isRequired,
        showPopup: PropTypes.func.isRequired,
        updateCustomer: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        showDeletePopup: PropTypes.func.isRequired,
        updateCustomerDetails: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired
    };

    state = {
        hideTables:false
    };

    componentDidMount() {
        const { updateMeta } = this.props;
        updateMeta({ title: __('Conta') });

    }

    disableTables = (activate) => {
        this.setState({hideTables:activate});
    };

    containerFunctions = {
        getDefaultAddress: this.getDefaultAddress.bind(this),
        showCreateNewPopup: this.showCreateNewPopup.bind(this),
        onPasswordChange: this.onPasswordChange.bind(this),
        disableTables: this.disableTables.bind(this),
        onDeleteClick: this.onDeleteClick.bind(this),
        handleDeleteCoPayment: this.handleDeleteCoPayment.bind(this)
    };

    onError = (error) => {
        const { showErrorNotification } = this.props;
        this.setState({ isLoading: false });
        showErrorNotification(error);
    };

    onDeleteClick(id) {
        const { showDeletePopup } = this.props;
        showDeletePopup({
            title: __('Are you sure you want to delete this co-payment?'), // Confirm delete
            id
        });
    }

    handleDeleteCoPayment(coPayment) {
        const query = MyAccountQuery.getDeleteCoPaymentMutation(coPayment.entity_id);
        fetchMutation(query).then(this.handleAfterDeleteAction, this.handleError);
    }

    handleAfterDeleteAction = () => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            hideActiveOverlay
        } = this.props;

        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                this.setState({ isLoading: false }, () => {
                    goToPreviousHeaderState();
                    hideActiveOverlay();
                });
            }, showErrorNotification
        );
    };

    handleError = (error) => {
        const { showErrorNotification } = this.props;
        showErrorNotification(error);
        this.setState({ isLoading: false });
    };

    showCreateNewPopup() {
        const { showPopup } = this.props;

        showPopup({
            action: ADD_ADDRESS,
            title: __('Add new address'),
            address: {}
        });
    }

    onPasswordChange(passwords) {
        const {
            showSuccessNotification,
            showErrorNotification,
            hideActiveOverlay,
            goToPreviousHeaderState
        } = this.props;

        if(passwords.newPasswordRepeat !== passwords.newPassword) {
            this.setState({ isLoading: false });
            showErrorNotification([{message:__('Your password and confirmation password do not match.')}]);
            return null;
        }

        const mutation = MyAccountQuery.getChangeCustomerPasswordMutation(passwords);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountCustomerPopup/Container/onPasswordChangeFetchMutationThen */
            () => {
                showSuccessNotification(__('Your password was successfully updated!'));
                this.setState({ isLoading: false }, () => {
                    hideActiveOverlay();
                    goToPreviousHeaderState();
                });
            },
            this.onError
        );
    }

    render() {
        return (
            <MyAccountDashboard
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountDashboardContainer);
