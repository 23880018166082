import {
    MyAccountNewsletterSubscription as SourceMyAccountNewsletterSubscription,
} from 'SourceComponent/MyAccountNewsletterSubscription/MyAccountNewsletterSubscription.component';

import './MyAccountNewsletterSubscription.style.override'

export class MyAccountNewsletterSubscription extends SourceMyAccountNewsletterSubscription {
    // TODO implement logic
};

export default MyAccountNewsletterSubscription;
