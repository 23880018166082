import PropTypes from 'prop-types';
import {
    Header as SourceHeader,
    CartOverlay,
    MyAccountOverlay,
} from 'SourceComponent/Header/Header.component';

import { Suspense, lazy } from 'react';

import Popup from 'Component/Popup';
import { TotalsType } from 'Type/MiniCart';
import { DeviceType } from 'Type/Device';
import Link from 'Component/Link';
import StoreSwitcher from 'Component/StoreSwitcher';
import Menu from 'Component/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt as faUserSolid } from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowLeft, faBars, faPaste, faBookOpen, faHeart, faTimes, faShoppingCart, faCreditCard, faUserAlt, faLoveseat, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import pin from 'Route/StorePage/Images/pin.png';
import clock from 'Route/StorePage/Images/clock.png';
import phone from 'Route/StorePage/Images/phone.png';
import icon_logged from './images/logged.png';
import icon_login from './images/login.png';
import arrowright from 'Route/StorePage/Images/arrowright.png';
import ClickOutside from 'Component/ClickOutside';
import PopupSuspense from 'Component/PopupSuspense';
import OfflineNotice from 'Component/OfflineNotice';
import { isSignedIn } from 'Util/Auth';
import SearchField from 'Component/SearchField';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { MY_WISHLIST } from 'Type/Account';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import { customerType } from 'Type/Account';
import Image from 'Component/Image';
import cardSauda from 'Route/StoreFinder/images/pecas-sauda-2-79-copy@2x.png';
import PharmacySchedule from 'Component/PharmacySchedule';
import BlogCategories from '../../component/BlogCategories';
import { BLOG_SEARCH } from 'Component/BlogSearchBar/BlogSearchBar.config';
import { POSTS_DETAILS } from 'Route/PostsDetails/PostsDetails.config';
import { POSTS_LISTING } from 'Route/PostsListing/PostsListing.config';
import base_logo from 'Component/MyPharmacy/Images/logo_pharmacy_default.jpg';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import CSS from 'Util/CSS';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import Loader from 'Component/Loader';

import PharmacySelector from 'Route/StoreSelector';
import BrowserDatabase from 'Util/BrowserDatabase';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import CmsBlock from 'Component/CmsBlock';

import {StoreFinderDispatcher} from "Store/StoreFinder";


import {
    WISHLIST_OVERLAY,
    CART,
    CART_EDITING,
    CART_OVERLAY,
    CATEGORY,
    CHECKOUT,
    CHECKOUT_ACCOUNT,
    CMS_PAGE,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    MENU,
    MENU_SUBCATEGORY,
    PDP,
    POPUP,
    SEARCH
} from './Header.config';

import Overlay from 'Component/Overlay';
import { renderPharmacySelectorPopup } from 'Util/PharmacySelector'

import './Header.style.override.scss';

export {
    CartOverlay,
    MyAccountOverlay,
};
export const WishlistOverlay = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "wishlist" */ 'Component/WishlistOverlay'));

export class Header extends SourceHeader {

    static propTypes = {
        navigationState: PropTypes.object.isRequired,
        cartTotals: TotalsType.isRequired,
        onBackButtonClick: PropTypes.func.isRequired,
        onCloseButtonClick: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        onMyAccountButtonClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onClearButtonClick: PropTypes.func.isRequired,
        isWishlistLoading: PropTypes.bool.isRequired,
        onEditButtonClick: PropTypes.func.isRequired,
        onMinicartButtonClick: PropTypes.func.isRequired,
        onOkButtonClick: PropTypes.func.isRequired,
        onCancelButtonClick: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onMyAccountOutsideClick: PropTypes.func.isRequired,
        onMinicartOutsideClick: PropTypes.func.isRequired,
        isClearEnabled: PropTypes.bool.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        shareWishlist: PropTypes.func.isRequired,
        header_logo_src: PropTypes.string,
        logo_alt: PropTypes.string,
        logo_height: PropTypes.number,
        logo_width: PropTypes.number,
        isLoading: PropTypes.bool,
        showMyAccountLogin: PropTypes.bool,
        isCheckout: PropTypes.bool.isRequired,
        onSignIn: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        onClickMenuToggle: PropTypes.func.isRequired,
        onWishlistOutsideClick: PropTypes.func.isRequired,
        onWishlistButtonClick: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        signIn: PropTypes.func.isRequired,
        getAvailableServices: PropTypes.func.isRequired,
    };

    state = {
        coordinates: null
    }

    componentDidMount() {
        const {
            signIn,
        } = this.props;
        window.scrollTo(0, 0);
        navigator.geolocation.getCurrentPosition(position => {
            let coords = [position.coords.latitude,position.coords.longitude];

            this.setState({coordinates:coords});
        });
        this.removeHiddenHeaderFooter();
        localStorage.setItem('fromWebsite',false)
        localStorage.setItem('showSelectorPopup',false);
    }

    componentDidUpdate(prevProps) {
        const {
            logo_height,
            logo_width,
            prevPathname: pathname,
            device
        } = this.props;

        const { prevPathname } = prevProps;

        if (pathname !== prevPathname && pathname.includes('checkout')) {
            CSS.setVariable(this.logoRef, 'header-logo-height', `${logo_height}px`);
            CSS.setVariable(this.logoRef, 'header-logo-width', `${logo_width}px`);
        }
        this.removeHiddenHeaderFooter();
        if(device.isMobile){
            let overlay = document.getElementsByClassName('PharmacySelector_isVisible')
            localStorage.setItem('locationMsg',overlay.length > 0 ? true : false);
        }
    }

    /**
     * Control what appears
     */
    stateMap = {
        [DEFAULT_STATE_NAME]: {
            title: true,
            logo: true
        },
        [POPUP]: {
            title: true,
            close: true
        },
        [PDP]: {
            back: true,
            title: true
        },
        [CATEGORY]: {
            back: true,
            title: true
        },
        [CUSTOMER_ACCOUNT]: {
            title: true
        },
        [CUSTOMER_SUB_ACCOUNT]: {
            title: true,
            back: true
        },
        [CUSTOMER_ACCOUNT_PAGE]: {
            title: true
        },
        [CUSTOMER_WISHLIST]: {
            share: true,
            title: true,
            edit: true,
            ok: true
        },
        [MENU]: {
            logo: true
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            title: true
        },
        [SEARCH]: {
            back: true,
            search: true
        },
        [CART]: {
            title: true
        },
        [CART_OVERLAY]: {
            title: true
        },
        [CART_EDITING]: {
            ok: true,
            title: true,
            cancel: true
        },
        [FILTER]: {
            close: true,
            clear: true,
            title: true
        },
        [CHECKOUT]: {
            back: true,
            title: true,
            account: true
        },
        [CHECKOUT_ACCOUNT]: {
            title: true,
            close: true
        },
        [CMS_PAGE]: {
            back: true,
            title: true
        },
        [BLOG_SEARCH]: {
            back: true,
            title: true
        },
        [POSTS_DETAILS]: {
            back: true,
            title: true
        },
        [POSTS_LISTING]: {
            back: true,
            title: true
        }
    };

    /** remove elements from rendermap */
    renderMap = {
        menuToggle: this.renderMenuToggle.bind(this),
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogoMobile.bind(this),
        //account: this.renderAccount.bind(this),
        search: this.renderSearchField.bind(this),
      //  wishlist: this.renderWishlistLink.bind(this),
      //  minicart: this.renderMinicart.bind(this),
        pharmacy: this.renderPharmacy.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this),
        //close: this.renderCloseButton.bind(this)
    };

    /**
     * Render menu toggler
     */
    renderMenuToggle() {
        const { device, onClickMenuToggle, isMenuVisible } = this.props;

        if(device.isMobile) {
            return null;
        }

        let icon = faBars;
        if(isMenuVisible) {
            icon = faTimes;
        }
        if (window.location.pathname.includes('blog') && !device.isMobile) {
            return (
                <></>
            );
        } else {
            return (
                <button
                  block="Header"
                  elem="MenuToggle"
                  tabIndex="0"
                  onClick={ onClickMenuToggle }
                  id="menuToggle"
                >
                    <div
                      block="Header"
                      elem="MenuToggleTitle"
                    >
                        <FontAwesomeIcon className="Header-IconLeft" icon={icon} />
                        { __('Find by category') }
                    </div>
                </button>
            );
        }
    }

    renderWishlistOverlayFallback() {
        return (
            <PopupSuspense
              actualOverlayKey={ WISHLIST_OVERLAY }
            />
        );
    }

    renderPostalCodesPopup() {
        return (
            <div>
                <Popup
                    id={ 'PostalCodeBlock' }
                    clickOutside={ true }
                    mix={ { block: 'PostalCodeBlock' } }
                    closedOn = { true }
                    onClose={ () => this.redirectBack() }
                >
                    <CmsBlock identifier={ "checkout_zip_codes_block" } />
                </Popup>
            </div>
        );
    }


    renderAlertNotSaudaPopup() {
        const {
            device
        } = this.props;

        if (device.isMobile) {
            return (
                <Overlay
                id={ 'AlertNotSauda' }
                mix={ { block: 'AlertNotSauda' } }
                >
                    { this.renderAlertNotSaudaPopupContent() }
                </Overlay>
            );
        } else {
            return (
                <div>
                    <Popup
                        id={ 'AlertNotSauda' }
                        clickOutside={ true }
                        mix={ { block: 'AlertNotSauda' } }
                        closedOn = { true }
                        onClose={ () => this.redirectBack() }
                    //   hideActiveOverlay = { hideActiveOverlay }
                    >
                        { this.renderAlertNotSaudaPopupContent() }
                    </Popup>
                </div>
            );
        }
    }

    renderAlertNotSaudaPopupContent() {
        return (
            <>
            <div block="AlertNotSauda" elem="Image">
                <FontAwesomeIcon className="MyAccountPrescriptionDetails-AlertIcon"
                icon={ faExclamationCircle }
                />
            </div>
            <div block="AlertNotSauda" elem="Info">
                <p>
                    { __('The purchase of products with Saúda points is exclusive to Saúda pharmacies.') }
                </p>
            </div>
            <div block="AlertNotSauda" elem="Actions">
                <button block="Button" onClick={() => this.proceedToProduct()}>
                    <span>{ __('Proceed') }</span>
                </button>
                <button block="Button" elem="ViewPharmacies" onClick={() => this.viewPharmacies()}>
                    <span>{ __('View Saúda Pharmacies') }</span>
                </button>
            </div>
            </>
        )
    }

    renderSaudaCardBlocked() {
        const {
            device
        } = this.props;

        if (device.isMobile) {
            return (
                <Overlay
                id={ 'SaudaCardBlocked' }
                mix={ { block: 'SaudaCardBlocked' } }
                >
                    { this.renderSaudaCardBlockedPopupContent() }
                </Overlay>
            );
        } else {
            return (
                <div>
                    <Popup
                        id={ 'SaudaCardBlocked' }
                        clickOutside={ true }
                        mix={ { block: 'SaudaCardBlocked' } }
                        closedOn = { true }
                        onClose={ () => this.redirectBack() }
                    //   hideActiveOverlay = { hideActiveOverlay }
                    >
                        { this.renderSaudaCardBlockedPopupContent() }
                    </Popup>
                </div>
            );
        }
    }

    renderSaudaCardBlockedPopupContent() {
        return (
            <>
                <h3>{ __('Your Saúda card is blocked and you cannot enjoy the associated benefits. For more info contact the Portuguese Pharmacy Customer Service.') }</h3>
            </>
        );
    }

    proceedToProduct = () => {
        const productUrl = BrowserDatabase.getItem('redirect_to');
        const { hideActiveOverlay, favStoreObj, showPharacySelectorPopup, requestStores,setShowPharmacySelectorPopup } = this.props;
        hideActiveOverlay();
        document.documentElement.classList.remove('scrollDisabled');
        document.body.style.marginTop = 0;
        window.scrollTo(0,  window.pageYOffset || document.body.scrollTop);

        if (favStoreObj && favStoreObj.pharmacy_code) {
            BrowserDatabase.deleteItem('redirect_to');
            history.push(appendWithStoreCode(productUrl));
        } else {
            BrowserDatabase.setItem('clicked_sauda', true);
            setShowPharmacySelectorPopup();
            requestStores();
            showPharacySelectorPopup();
        }
    }

    viewPharmacies = () => {
        const { hideActiveOverlay, showPharacySelectorPopup, requestStores,setShowPharmacySelectorPopup } = this.props;
        hideActiveOverlay();
        document.documentElement.classList.remove('scrollDisabled');
        document.body.style.marginTop = 0;
        window.scrollTo(0,  window.pageYOffset || document.body.scrollTop);
        //BrowserDatabase.deleteItem('redirect_to');
        BrowserDatabase.setItem('clicked_sauda', true);
        //history.push(appendWithStoreCode('/localizador-de-farmacias?searchingSchedule={"min":0,"max":86400}&searchValue=&searchingOnline=false&searchingDelivery=false&searchingSauda=true&searchingOpen=false'));
        setShowPharmacySelectorPopup();
        requestStores();
        showPharacySelectorPopup();
    }

    redirectBack(){
        const { isCateOrProductPage } = this.props;
        if(isCateOrProductPage) {
            history.push(appendWithStoreCode(`/`));
        }
    }

    /**
     * Render wishlist Overlay
     */
    renderWishlistOverlay() {
        const { shouldRenderWishlistOverlay } = this.props;

        if (!shouldRenderWishlistOverlay) {
            return null;
        }

        return (
            <Suspense fallback={ this.renderWishlistOverlayFallback() }>
                <WishlistOverlay />
            </Suspense>
        );
    }

    /**
     * render wishlist header link
     */
    renderWishlistLink(isVisible = false) {
        const {
            device,
            onWishlistOutsideClick
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onWishlistOutsideClick }
              key="wishlist"
            >
                <div
                  block="Header"
                  elem="Button"
                  mods={ { isVisible, type: 'wishlist' } }
                >
                    { this.renderWishlistButton() }
                    { this.renderWishlistOverlay() }
                </div>
            </ClickOutside>
        );
    }

    /**
     * render wishlist header link mobile
     */
    renderWishlistLinkMobile(isVisible = false) {

        return (
            <div
                block="Header"
                elem="Button"
                mods={ { isVisible, type: 'wishlist' } }
            >
                { this.renderWishlistButton() }
            </div>
        );
    }

    /**
     * Change account link
     * @param {*} isVisible
     */
    renderAccountButton(isVisible) {
        const {
            onMyAccountButtonClick,
            customer: { fullname }
        } = this.props;

        if(isSignedIn()) {
            return (
                <button
                  block="Header"
                  elem="MyAccountWrapper"
                  tabIndex="0"
                  onClick={ onMyAccountButtonClick }
                  aria-label="Open my account"
                  id="myAccount"
                >
                     <Image
                        src={ icon_logged }
                        width="20px"
                        height="20px"
                        mix={ { block: 'Header', elem: 'Logged', } }
                    />
                    <div
                      block="Header"
                      elem="MyAccountTitle"
                    >
                        {/* fullname*/ }
                    </div>
                </button>
            );
        }

        return (
            <button
              block="Header"
              elem="MyAccountWrapper"
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
              id="myAccount"
            >
                 <Image
                        src={ icon_login }
                        width="20px"
                        height="20px"
                        mix={ { block: 'Header', elem: 'Login', } }
                    />
            </button>
        );
    }

    /**
     * Render Wishlist Button
     */
    renderWishlistButton() {
        const {
            onWishlistButtonClick,
            hideActiveOverlay
        } = this.props;

        if(isSignedIn()) {
            return (
                <Link to={ `${ MY_ACCOUNT_URL }/${ MY_WISHLIST }` }
                    block="Header"
                    elem="WishlistButtonWrapper"
                    onClick={ hideActiveOverlay }
                    title={ __('Go To Wishlist') }
                >
                    <FontAwesomeIcon icon={faHeart} />
                </Link>
            );
        } else {
            return (
                <button
                  block="Header"
                  elem="WishlistButtonWrapper"
                  title={ __('Go To Wishlist') }
                  tabIndex="0"
                  onClick={ onWishlistButtonClick }
                >
                    <FontAwesomeIcon icon={faHeart} />
                    {/* { this.renderWishlistItemsQty() } */}
                </button>
            );
        }
    }

    /**
     * Remove cart text
     */
    renderMinicartButton() {
        const {
            onMinicartButtonClick,
            hideActiveOverlay
        } = this.props;

        return (
            <button
              block="Header"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              title={ __('View cart') }
              onClick={ () => {
                hideActiveOverlay();
                onMinicartButtonClick();
              } }
            >
                <FontAwesomeIcon icon={faShoppingCart} />
                { this.renderMinicartItemsQty() }
            </button>
        );
    }

    /**
     * Render account available in mobile
     * @param {*} isVisible
     */
    renderAccount(isVisible = false) {
        const {
            onMyAccountOutsideClick,
            isCheckout,
            device
        } = this.props;

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMyAccountOutsideClick }
              key="account"
            >
                <div
                  aria-label="My account"
                  block="Header"
                  elem="MyAccount"
                >
                    { this.renderAccountButton(isVisible) }
                    { this.renderAccountOverlay() }
                </div>
            </ClickOutside>
        );
    }

    /** Change link aria-label to admin logo alt */
    renderLogo(isVisible = false) {
        const {
            isLoading,
            logo_alt,
            hideActiveOverlay,
            favStoreObj: { store_url }
        } = this.props;

        if (isLoading) {
            return (
                <div block="Header" elem="LogoPlaceholder"></div>
            );
        }

        return (
            <Link
              to={ store_url ? store_url : '/' }
              aria-label={ logo_alt }
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible, isTopLogo:true } }
              key="logo"
              onClick={ hideActiveOverlay }
            >
                { this.renderLogoImage() }
            </Link>
        );
    }

    renderLogoMobile(isVisible = false) {
        const {
            isLoading,
            logo_alt,
            device,
            navigationState
        } = this.props;

        if(!device.isMobile) {
            return null;
        }

        if (isLoading) {
            return (
                <div block="Header" elem="LogoPlaceholder"></div>
            );
        }
        if(navigationState.name == 'default'){
            return (
                <Link
                  to="/"
                  aria-label={ logo_alt }
                  aria-hidden={ !isVisible }
                  tabIndex={ isVisible ? 0 : -1 }
                  block="Header"
                  elem="LogoWrapper"
                  mods={ { isVisible, isTopLogo:true } }
                  key="logo"
                >
                    { this.renderLogoImage() }
                </Link>
            );
        }else{
            return (
                <Link
                  to="/"
                  aria-label={ logo_alt }
                  aria-hidden={ !isVisible }
                  tabIndex={ isVisible ? 0 : -1 }
                  block="Header"
                  elem="LogoWrapper"
                  mods={ { isVisible, isLogin: true  } }
                  key="logo"
                >
                    { this.renderLogoImage() }
                </Link>
            );
        }
    }
    /**
     * Render Pharmacy button
     */
    renderPharmacy() {
        const {
            favStoreObj,
            baseLinkUrl,
            onInfoClick,
            isLoading
        } = this.props;
        const { device } = this.props;

        if(!Object.entries(favStoreObj).length){
            return null;
        }

        let logoSauda = null;
        let hasSauda = false;
        let media = '/media/';
        let mediaUrl = baseLinkUrl.concat(media);
        let url = favStoreObj.logo ? mediaUrl.concat(favStoreObj.logo) : base_logo;

        if (device.isMobile) {
            return null;
        }

        if (favStoreObj.sauda == 1) {
            logoSauda = <span><Image src = { cardSauda } /></span>;
            hasSauda = true;
        }

        if(!window.location.pathname.includes('blog')) {
            return (
                <>
                <Loader isLoading={ isLoading } />
                { !isLoading && <div
                block="Header"
                elem="Pharmacy"
                mods={{ hasSauda }}
                    >
                    { logoSauda }
                    <span
                    block="Header"
                    elem="LogoSauda"
                    >
                        <Image
                            src = {url}
                        />
                    </span>
                    <button
                        block="Header"
                        elem="PharmacyName"
                        onClick= { onInfoClick }
                    >{ favStoreObj.name }</button>
                </div> }
                { /* this.renderInfoPopup() */ }
                </>
            );
        } else {
            return(
                <></>
            )
        }
    }

    /**
     * Render Sauda card or Prescription link
     */
    renderCardPrescription() {
        if(isSignedIn()) {
            return (
                <Link
                  to="/"
                  block="Header"
                  elem="Prescription"
                >
                    <FontAwesomeIcon className="Header-IconLeft" icon={faPaste} />
                    { __('My Prescriptions') }
                </Link>
            );
        }

        return (
            <Link
              to="/"
              block="Header"
              elem="Sauda"
            >
                <FontAwesomeIcon className="Header-IconLeft" icon={faCreditCard} />
                { __('Enroll your Sauda card') }
            </Link>
        );
    }

    /**
     * Render Health link
     */
    renderHealth() {
        return (
            <Link
              to="/"
              block="Header"
              elem="Health"
            >
                <FontAwesomeIcon className="Header-IconLeft" icon={faBookOpen} />
                { __('Health from A-Z') }
            </Link>
        );
    }

    /**
     * Render Checkout
     */
    renderCheckout() {
        let isStep1,isStep2,isStep3 = false;

            if (window.location.pathname.includes('shipping')) {
                isStep1 = true;
            } else if (window.location.pathname.includes('billing')) {
                isStep1 = true;
                isStep2 = true;
            } else if (window.location.pathname.includes('confirm')) {
                isStep1 = true;
                isStep2 = true;
                isStep3 = true;
            } else if (window.location.pathname.includes('success')) {
                isStep1 = true;
                isStep2 = true;
                isStep3 = true;
            }

        return (
            <>
            <div block="Header" elem="Checkout">
                <div block="Header" elem="Checkout-Level">
                    <div block="Header" elem="Level" mods={ { isStep1: isStep1 } }>1</div>
                    <label block="Header" elem="DescriptionHeader">{ __('Delivery Data') }</label>
                    <div block="Header" elem="Line"> </div>
                </div>
                <div block="Header" elem="Checkout-Level">
                    <div block="Header" elem="Level" mods={ { isStep2: isStep2 } }>2</div>
                    <label block="Header" elem="DescriptionHeader">{ __('Payment data') }</label>
                    <div block="Header" elem="Line"> </div>
                </div>
                <div block="Header" elem="Checkout-LevelRight">
                    <div block="Header" elem="Level" mods={ { isStep3: isStep3 } }>3</div>
                    <label block="Header" elem="DescriptionHeader">{ __('Confirmation') }</label>
                </div>
            </div>
            </>
        );
    }
    /**
     * Render Checkout
     */
    renderCart() {
        return (
            <Link
              to="/cart"
              block="Header"
              elem="GoBack"
            >
                <FontAwesomeIcon className="Header-IconLeft" icon={faLongArrowLeft} />
            </Link>
        );
    }

    /**
     * Render search only desktop
     * @param {*} isVisible
     */
    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
            device,
            showPharacySelectorPopup,
            customer,
            setShowPharmacySelectorPopup
        } = this.props;

        if (isCheckout || device.isMobile) {
            return null;
        }

        if (window.location.pathname.includes('blog')) {
            return (
                <>
                <div>
                    <BlogCategories />
                    <SearchField
                    key="search"
                    searchCriteria={ searchCriteria }
                    onSearchOutsideClick={ onSearchOutsideClick }
                    onSearchBarFocus={ onSearchBarFocus }
                    onSearchBarChange={ onSearchBarChange }
                    onClearSearchButtonClick={ onClearSearchButtonClick }
                    isVisible={ isVisible }
                    isActive={ name === SEARCH }
                    hideActiveOverlay={ hideActiveOverlay }
                    showPharacySelectorPopup={ showPharacySelectorPopup }
                    customer={ customer }
                    setShowPharmacySelectorPopup={setShowPharmacySelectorPopup}
                    />
                </div>
                </>
            );
        } else {
            return (
                    <SearchField
                    key="search"
                    searchCriteria={ searchCriteria }
                    onSearchOutsideClick={ onSearchOutsideClick }
                    onSearchBarFocus={ onSearchBarFocus }
                    onSearchBarChange={ onSearchBarChange }
                    onClearSearchButtonClick={ onClearSearchButtonClick }
                    isVisible={ isVisible }
                    isActive={ name === SEARCH }
                    hideActiveOverlay={ hideActiveOverlay }
                    showPharacySelectorPopup={ showPharacySelectorPopup }
                    customer={ customer }
                    setShowPharmacySelectorPopup={setShowPharmacySelectorPopup}
                    />
            );
        }
    }

    /**
     * Render search only header visible
     * @param {*} isVisible
     */
    renderSearchFieldMobile(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
            device,
            showPharacySelectorPopup,
            customer,
            setShowPharmacySelectorPopup
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
              showPharacySelectorPopup={ showPharacySelectorPopup }
              customer={ customer }
              setShowPharmacySelectorPopup={setShowPharmacySelectorPopup}
            />
        );
    }

    renderPharmacyMobile() {
        const {
            favStoreObj,
            baseLinkUrl,
            onInfoClick,
            isStorePage,
            device,
            isCateOrProductPage,
            isHomePage,
            isCartPage,
            isWishlist
        } = this.props;

        if(!Object.entries(favStoreObj).length){
            return null;
        }

        let logoSauda = null;
        let media = '/media/';
        let mediaUrl = baseLinkUrl.concat(media);
        let url = favStoreObj.logo ? mediaUrl.concat(favStoreObj.logo) : base_logo;
        let hasSauda = false;

        if (favStoreObj.sauda == 1) {
            hasSauda = true;
            logoSauda = <Image
                            src = { cardSauda }
                        />
        }

        if (isCartPage || isHomePage || isCateOrProductPage || isStorePage || isWishlist) {
            return (
                <>
                <div
                  block="Header"
                  elem="PharmacyMobile"
                  mods={{ hasSauda }}
                    >
                    {!!logoSauda && <span>{ logoSauda }</span>}
                    <span
                      block="Header"
                      elem="LogoSauda"
                    >
                        <Image
                            src = {url}
                        />
                     </span>
                    <button
                        block="Header"
                        elem="PharmacyName"
                        onClick= { onInfoClick }
                    >{ favStoreObj.name }</button>
                </div>
                 { this.renderInfoPopup() }
                 </>
            );
        }

    }

    /**
     * Rebuild top menu for theme
     */
    renderTopMenu() {
        const { device, favStoreObj, services } = this.props;

        if( window.location.pathname.includes('checkout')){
            return (
                <div block="Header" elem="TopMenu TopMenu-Checkout">
                    { this.renderCart() }
                    { this.renderLogo() }
                    { this.renderCheckout() }
                </div>
            );
        }

        if (device.isMobile) {
            return (
                <div block="Header" elem="TopMenu">
                    { this.renderLogo() }
                    <div block="Header" elem="LinksContainer">
                        { this.renderSearchFieldMobile() }
                        { this.renderWishlistLinkMobile() }
                        { this.renderMinicartButton() }
                    </div>
                </div>
            );
        }

        let isNotSauda = Object.entries(favStoreObj).length ? favStoreObj.sauda == 0 : false;

        // check if theres any service that can be shown on services page
        // if yes, then we'll render the link for the services page in the top menu
        let hasServices = services.find(element => element.show_in_services_page === 1);

        return (
            <div block="Header" elem="TopMenu">
                { this.renderLogo() }
                {/* <div block="Header" elem="Switcher">
                    <StoreSwitcher />
                </div> */}
                { this.renderOnlineShop() }
                { this.renderPrescription() }
                { hasServices ? this.renderServices() : null}
                { this.renderStoreFinder() }
                { !isNotSauda ? this.renderBonus() : null }
                { this.renderBlog() }
                <div block="Header" elem="MenuActions">
                    { this.renderWishlistLinkMobile() }
                    <div>
                        { this.renderMinicart() }
                    </div>
                    { this.renderAccount() }
                </div>
            </div>
        );
    }

    renderPrescription() {
        const {
            customer: { favourite_pharmacy_code },
            onClickPresNoStore,
            onClickPresNoStoreNotLogged,
            hideActiveOverlay
        } = this.props;

        if(!isSignedIn()) {
            return (
                <button
                    onClick={ onClickPresNoStoreNotLogged }
                    block="Header"
                    elem="Prescription"
                >
                    { __('Prescription Wallet') }
                </button>
            );
        }

        if (favourite_pharmacy_code) {
            return (
                <Link
                  to="/my-account/prescription"
                  block="Header"
                  elem="Prescription"
                  onClick={ hideActiveOverlay }
                >
                    { __('Prescription Wallet') }
                </Link>
            );
        }

        return (
            <button
                onClick={ onClickPresNoStore }
                block="Header"
                elem="Prescription"
            >
                { __('Prescription Wallet') }
            </button>
        );
    }
    renderServices() {
        const { hideActiveOverlay } = this.props;

        return (
            <Link
                to="/servicos"
                block="Header"
                elem="Services"
                onClick={ hideActiveOverlay }
            >
                { __('Services') }
            </Link>
        );
    }

    renderAccount(isVisible = false) {
        const {
            onMyAccountOutsideClick,
            isCheckout,
            device
        } = this.props;

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMyAccountOutsideClick }
              key="account"
            >
                <div
                  aria-label="My account"
                  block="Header"
                  elem="MyAccount"
                >
                    { this.renderAccountButton(isVisible) }
                    { this.renderAccountOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderTitle(isVisible = false) {
        const { navigationState: { title } } = this.props;
        return (
            <h1
              key="title"
              block="Header"
              elem="Title"
              mods={ { isVisible } }
            >
                { title }
            </h1>
        );
    }

    renderOnlineShop() {
        const {
            customer: { favourite_pharmacy_code },
            onClickNoStore,
            favStoreObj: { store_url },
            hideActiveOverlay,
            onClickNoStoreContext
        } = this.props;

        if(!favourite_pharmacy_code && !localStorage.getItem('guest_pharmacy_code')){
            return (
                <Link
                    to="#"
                    block="Header"
                    elem="Shop"
                    onClick={ (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onClickNoStoreContext();
                    } }
                >
                    { __('Online Pharmacy') }
                </Link>
            );
        }

        if(favourite_pharmacy_code || localStorage.getItem('guest_pharmacy_code')) {
            return (
                <Link
                    to={ store_url ? store_url : '/' }
                    block="Header"
                    elem="Shop"
                    onClick={ hideActiveOverlay }
                >
                    { __('Online Pharmacy') }
                </Link>
            );

           /*  return (
                <button
                    onClick={ onClickNoStore }
                    block="Header"
                    elem="Shop"
                >
                    { __('Online Pharmacy') }
                </button>
            ); */
        }

        return (
            <Link
                to={ '/selecionador-de-farmacias' }
                block="Header"
                elem="Shop"
                onClick={ () => {
                    onClickNoStore();
                    hideActiveOverlay();
                } }
            >
                { __('Online Pharmacy') }
            </Link>
        );
    }

    renderStoreFinder() {
        const { hideActiveOverlay } = this.props;

        return (
            <Link
              to="/localizador-de-farmacias"
              block="Header"
              elem="StoreFinder"
              onClick={ hideActiveOverlay}
            >
                { __('Store Finder') }
            </Link>
        );
    }

    renderBlog() {
        const { hideActiveOverlay } = this.props;

        return (
            <Link
              to="/blog"
              block="Header"
              elem="Blog"
              onClick={ hideActiveOverlay }
            >
                { __('Health from A-Z') }
            </Link>
        );
    }

    renderBonus() {
        const { hideActiveOverlay } = this.props;

        return (
            <Link
              to="/vantagens-exclusivas"
              block="Header"
              elem="Bonus"
              onClick={ hideActiveOverlay }
            >
                { __('Exclusive Advantages') }
            </Link>
        );
    }

    renderInfoPopup() {

        const { favStoreObj } = this.props;

        if(!Object.entries(favStoreObj).length) {
            return null;
        }

        let distance2;

        if( this.state.coordinates== null ) {
            distance2= false;
        } else {
            distance2 = this.distance(this.state.coordinates[0], this.state.coordinates[1], favStoreObj.latitude, favStoreObj.longitude,'K');
        }

        let finalDistance = Math.round(distance2 * 100) / 100;

        let showDistance=false;

        if(finalDistance == 0) {
            showDistance = false;
        } else {
            showDistance = true;
        }

        const {
            name,
            street_name,
            all_schedules,
            number,
            locality,
            postal_code_zone,
            postal_code_sufix,
            owner,
            technical_team,
            telephone,
            telephone_secondary,
            schedule_values,
            status
        } = favStoreObj;

        let address = `${street_name} ${number}`;
        let postCode = `${postal_code_zone}-${postal_code_sufix} ${locality}`;

        let storeStatusLabel = __('Closed');
        let storeSaturdays = __('Closed');
        let storeSundays = __('Closed');
        let storeWeekdays = __('Closed');

        let telephoneToShow = telephone_secondary ? telephone_secondary : telephone;

        if(status == 'open') {
            storeStatusLabel = <>{ __("Open today until ") } { schedule_values[schedule_values.length-1] }</>;
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        if(all_schedules.saturdays_start !== null) {
            storeSaturdays = <>{ all_schedules.saturdays_start } - { all_schedules.saturdays_end }</>;
        }

        if(all_schedules.sunday_start !== null) {
            storeSundays = <>{ all_schedules.sunday_start } - { all_schedules.sunday_end }</>;
        }

        if(all_schedules.holidays_start !== null) {
            storeWeekdays = <>{ all_schedules.holidays_start } - { all_schedules.holidays_end }</>;
        }

        return (
            <div>
                <Popup
                    id={ 'InfoPopup' }
                    clickOutside={ false }
                    mix={ { block: 'InfoPopup' } }
                    closedOn = { true }
                //   hideActiveOverlay = { hideActiveOverlay }
                >
                    <div block="Header" elem="Content">
                        <div block="Header" elem="StoreInfo" mods={ { type: 'name' } }>
                            <h2>
                                { name || __('No store name') }
                            </h2>
                        </div>
                        <div block="Header" elem="PharmacyInfoPopup">
                            {showDistance && <div block="Header" elem="Address">
                                <div>
                                    <Image
                                        src={ pin }
                                        width="12px"
                                        height="12px"
                                    />
                                </div>
                                <p block="Header" elem="AdressDistance">
                                    { finalDistance } Km
                                </p>
                                <p block="Header" elem="AddressRoute">
                                    { address || __('No address') }, { postCode || __('No postcode') }
                                </p>
                            </div> }
                            {!showDistance && <div block="Header" elem="AddressWithoutDistance">
                                <div>
                                    <Image
                                        src={ pin }
                                        width="12px"
                                        height="12px"
                                    />
                                </div>
                                <p block="Header" elem="AddressRoute">
                                    { address || __('No address') }, { postCode || __('No postcode') }
                                </p>
                            </div> }

                            <div block="Header" elem="Contact">
                            <div>
                                <Image
                                    src={ phone }
                                    width="12px"
                                    height="12px"
                                />
                            </div>
                            <p>
                                { telephoneToShow }
                            </p>
                            <div>
                                <Image
                                    src={ clock }
                                    width="12px"
                                    height="12px"
                                />
                            </div>
                            <p>
                                { storeStatusLabel }
                            </p>
                            </div>
                        </div>
                        <div block="Header" elem="InfoDescription">
                            <p>{ favStoreObj.resume }</p>
                        </div>
                        <h4 block="Header" elem="TitlePharmacy">{ __("Opened Hours:") }</h4>
                        <div block="Header" elem="PharmacyHours">
                            { <PharmacySchedule storeObj={ favStoreObj } /> }
                        </div>
                        <h4 block="Header" elem="TitlePharmacy">{ __("Pharmaceutic Owner:") }</h4>
                        <div block="Header" elem="PharmacyOwner">
                            <p>{ owner }</p>
                        </div>
                        <h4 block="Header" elem="TitlePharmacy">{ __("Team:") }</h4>
                        <div block="Header" elem="PharmacyTeam">
                            <p>{ technical_team || __('No Team') }</p>
                        </div>
                        <div block="Header" elem="BookElem">
                            <a
                                href="https://www.livroreclamacoes.pt/inicio"
                                target="_blank"
                            >
                                <p>{  __("Complaint Book") }</p>
                            </a>
                            {/* {  __("Complaint Book") } */}
                            <Image
                                src={ arrowright }
                                width="12px"
                                height="12px"
                            />
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }

    distance(lat1, lon1, lat2, lon2, unit) {
        let radlat1 = Math.PI * lat1/180;
        let radlat2 = Math.PI * lat2/180;
        let theta = lon1-lon2;
        let radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344; }
        if (unit=="N") { dist = dist * 0.8684; }
        return dist;
    }

    /**
     * Pass isMenuVisible to Menu component
     */
    renderMenu() {
        const { isCheckout, device, onClickMenuToggle, isMenuVisible, onClickOut } = this.props;

        if (device.isMobile || isCheckout) {
            return null;
        }

        return <Menu isMenuVisible={ isMenuVisible }  onClickToggle = {  onClickMenuToggle } onClickOut= {  onClickOut } />;
    }

    renderNav(navMenu = "") {
        let isVisibleMobile = false;

        if(navMenu == "blog") {
            return(
                <nav block="Header" elem="NavBlog" mods={ { isVisibleMobile } }>
                    { this.renderNavigationState() }
                </nav>
            );
        }
        if(navMenu == "servicos") {
            return(
                <span></span>
            );
        }else {
            return(
                <nav block="Header" elem="Nav" mods={ { isVisibleMobile } }>
                    { this.renderNavigationState() }
                </nav>
            );
        }
    }

    removeHiddenHeaderFooter(){
        const cms = document.querySelectorAll('.CmsPage.FullPage');
        if(cms.length == 0){
            const header = document.querySelectorAll('.Header-Wrapper')[0];
            const footer = document.querySelectorAll('footer')[0];
            if(header){
                header.classList.remove('hidden');
            }
            if(footer){
                footer.classList.remove('hidden');
            }
        }

    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device,
            isMyAccount,
            accountIsLoading,
            loginIsLoading,
            customerRequestDone,
            showPharmacySelectorPopup
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        let isLogin = false;
        let wrapperContext = "Wrapper";
        let isVisibleMobile = false;
        const source = this.stateMap[name]
            ? this.stateMap[name]
            : this.stateMap[this.defaultStateName]

        if(isSignedIn()){
            isLogin = true;
        }

        if( window.location.pathname.includes('blog')){
            wrapperContext = "WrapperBlog";
        }
        if( window.location.pathname.includes('servicos')){
            wrapperContext = "WrapperServices";
        }

        const fromWebsite = localStorage.getItem('fromWebsite');
        const showSelectorPopup = localStorage.getItem('showSelectorPopup');

        if( window.location.pathname.includes('checkout')){
            return (
                <section block="Header" elem="Wrapper Header-Wrapper-Checkout" mods={ { isLogin } }>
                    { this.renderPostalCodesPopup() }
                    <header
                      block="Header"
                      mods={ { name, isHiddenOnMobile:false, isCheckout } }
                      mix={ { block: 'FixedElement', elem: 'Top' } }
                      ref={ this.logoRef }
                    >
                        <div block="Header" elem="iosBlackBar"></div>
                        { this.renderTopMenu() }
                    </header>
                    <OfflineNotice />
                </section>
            );
        } else {
            const pagePreloads = this.props.device.isMobile ? window.pagePreloads.mobile : window.pagePreloads.desktop;
            let preloads = [];

            if(window.location.pathname.split("/").pop() === ""){
                for (let key in pagePreloads) {
                    preloads.push(<link rel="preload" as="image" fetchpriority="high" href={pagePreloads[key]}/>);
                }
            }

            return (
                <section block="Header" elem={ wrapperContext } mods={ { isLogin, isMyAccount } }>
                    <div block="page-preloads">
                        { preloads }
                    </div>
                    { (showPharmacySelectorPopup || fromWebsite == 'true' || showSelectorPopup == 'true') && renderPharmacySelectorPopup(device) }
                    { device.isMobile?this.renderPharmacyMobile():null }
                    { this.renderSaudaCardBlocked() }
                    { window.location.pathname.includes('my-account') && this.renderAlertNotSaudaPopup() }
                    <header
                      block="Header"
                      mods={ { name, isHiddenOnMobile:false, isCheckout } }
                      mix={ { block: 'FixedElement', elem: 'Top' } }
                      ref={ this.logoRef }
                    >
                        <div block="Header" elem="iosBlackBar"></div>
                        { loginIsLoading && <div class="hidden-loading"></div> }
                        { this.renderTopMenu() }
                        { device.isMobile?this.renderPharmacyMobile():null }
                        { window.location.pathname.includes('blog') ?
                            this.renderNav("blog") :
                            window.location.pathname.includes('servicos') ?
                                this.renderNav("servicos") :
                                this.renderNav()
                        }
                        { this.renderMenu() }
                    </header>
                    <OfflineNotice />
                </section>
            );
        }
    }

};

export default Header;
