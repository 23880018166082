/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { getQueryParam } from 'Util/Url';

import { CategoriesDispatcher } from '../../store/Categories';
import { PostsDispatcher, updatePosts } from '../../store/Posts';
import { PostsByCategoryDispatcher } from '../../store/PostsByCategory';
import { PostsMostViewedDispatcher } from '../../store/PostsMostViewed';
import { PostsByTagDispatcher } from '../../store/PostsByTag';
import PostsListing from './PostsListing.component';
import { DeviceType } from 'Type/Device';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { useParams } from 'react-router';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
    );

/** @namespace ScandiPWA/Blog/Component/PostsListing/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    posts: state.PostsReducer.posts,
    isLoaded: state.PostsReducer.isLoaded,
    categories: state.CategoriesReducer.categories,
    posts_per_page: state.ConfigReducer.blog_posts_per_page,
    postsByCategory: state.PostsByCategoryReducer.postsByCategory,
    postsMostViewed: state.PostsMostViewedReducer.postsMostViewed,
    postsByTag: state.PostsByTagReducer.postsByTag,
    isCategoriesLoaded: state.CategoriesReducer.isLoaded,
    device: state.ConfigReducer.device
});

/** @namespace ScandiPWA/Blog/Component/PostsListing/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestPosts: (options) => PostsDispatcher.handleData(dispatch, options),
    requestCategories: (options) => CategoriesDispatcher.handleData(dispatch, options),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    updatePosts: (posts) => dispatch(updatePosts(posts)),
    requestPostByCategory: (options) => PostsByCategoryDispatcher.handleData(dispatch, options),
    requestPostsMostViewed: (options) => PostsMostViewedDispatcher.handleData(dispatch, options),
    requestPostsByTag: (options) => PostsByTagDispatcher.handleData(dispatch, options),
    toggleBreadcrumbs: (flag) => dispatch(toggleBreadcrumbs(flag))
});

/** @namespace ScandiPWA/Blog/Component/PostsListing/Container */
export class PostsListingContainer extends PureComponent {
    static propTypes = {
        requestPosts: PropTypes.func.isRequired,
        requestCategories: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        posts: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        categories: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                categoria: PropTypes.string
            })
        }).isRequired,
        posts_per_page: PropTypes.number.isRequired,
        updatePosts: PropTypes.func.isRequired,
        postsByCategory: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        requestPostByCategory: PropTypes.func.isRequired,
        postsMostViewed: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        requestPostsMostViewed: PropTypes.func.isRequired,
        postsByTag: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        requestPostsByTag: PropTypes.func.isRequired,
        device: DeviceType.isRequired
    };


    __construct(props) {
        super.__construct(props);

        const { posts_per_page } = this.props;

        this.state = {
            category: '',
            page: 1,
            sort: ['DESC'],
            sortFiled: 'publish_time'
        };
        this.options = {
            postsOptions: {
                pageSize: posts_per_page,
                getDescription: true,
                getMedia: true
            }
        };

        this.containerFunctions = {
            requestPosts: this.requestPosts.bind(this),
            requestCategories: this.requestCategories.bind(this),
            loadMore: this.loadMore.bind(this),
            reloadPosts: this.reloadPosts.bind(this),
            onPageChange: this.onPageChange.bind(this),
            requestPostByCategory: this.requestPostByCategory.bind(this),
            requestPostsMostViewed: this.requestPostsMostViewed.bind(this),
            requestPostsByTag: this.requestPostsByTag.bind(this)
        };
    }

    componentDidMount() {
        const { updateMeta } = this.props;
        this.requestCategories();
        updateMeta({ title: __('Blog Saúde A-Z') });

    }
    componentDidUpdate() {
        const { category: prevCategory } = this.state;
        if (this.getCategoryFromUrl() !== prevCategory) {
            this.updateCategory();
        }
    }

    onPageChange(page) {
        let p = page.selected ? page.selected  + 1 : 1;
        this.setState({ page: p });

        const { category, sort, sortFiled } = this.state;
        let options = category ? { filter: { category_id: { eq: category } }, currentPage: p } : {currentPage: p};

        if (sort) {
            options.sort = sort;
        }

        if (sortFiled) {
            options.sortFiled = sortFiled;
        }

        this.requestPosts(options);

        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    getCategoryFromUrl() {
        const param = 'categoria';

        const path = location.pathname;
      
        let parts = path.split('/');

        return parts[parts.length - 1];
    }
    
      
    updateCategory() {
        const category = this.getCategoryFromUrl();
        let options = category ? { filter: { category_id: { eq: category } } } : {};
        this.setState({ category, page: 1 });

        const { sort, sortFiled } = this.state;
        options.sort = sort;
        options.sortFiled = sortFiled;

        this.requestPosts(options);
    }

    reloadPosts(sortFiled) {
        let sort = ['DESC'];

       // sort items
       switch (sortFiled) {
            case 'title':
                sort = ['ASC'];
                break;
            default:
                break;
        }
        
        this.setState({ sortFiled, sort, page: 1});
        
        const { category } = this.state;
        let options = category ? { filter: { category_id: { eq: category } }, currentPage:1, sort, sortFiled } : {currentPage:1,sort, sortFiled};

        this.requestPosts(options);

    }

    requestPosts(options) {
        const { requestPosts } = this.props;
        const { postsOptions } = this.options;

        requestPosts({ ...postsOptions, ...options });
    }

    requestCategories() {
        const { requestCategories } = this.props;
        requestCategories();
    }

    requestPostByCategory(category) {
        const { requestPostByCategory } = this.props;
        const { postsOptions } = this.options;
        const options = { filter: { category_id: { eq: category } } };
        
        requestPostByCategory({...postsOptions, ...options});
    }

    requestPostsMostViewed() {
        const { requestPostsMostViewed } = this.props;
        const { postsOptions } = this.options;
        const options = { sort: ['DESC'], sortFiled: 'views_count', pageSize: 8 };

        requestPostsMostViewed({...postsOptions, ...options});
    }

    requestPostsByTag(tag) {
        const { requestPostsByTag } = this.props;
        const { postsOptions } = this.options;
        const options = { filter: { tag_id: { eq: tag } }, pageSize: 6 };

        requestPostsByTag({...postsOptions, ...options});
    }

    loadMore() {
        const { postsOptions, postsOptions: { pageSize } } = this.options;
        const { posts_per_page } = this.props;

        this.options = {
            postsOptions: { ...postsOptions, pageSize: pageSize + posts_per_page }
        };

        this.requestPosts();
    }

    render() {
        const { category, page } = this.state;
        const { postsOptions: { pageSize } } = this.options;

        return (
            <PostsListing
              { ...this.props }
              { ...this.containerFunctions }
              category={ category }
              pageSize={ pageSize }
              page={ page }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsListingContainer);
