import {
    Checkout as SourceCheckout,
} from 'SourceRoute/Checkout/Checkout.component';

import PropTypes from 'prop-types';
import gtag, { install } from 'ga-gtag';
import ReactPixel from 'react-facebook-pixel';
import { fetchQuery } from 'Util/Request';
import ConfigQuery from 'Query/Config.query';
import ContentWrapper from 'Component/ContentWrapper';
import CartFloating from 'Component/CartFloating';
import CheckoutBilling from 'Component/CheckoutBilling';
import CheckoutShipping from 'Component/CheckoutShipping';
import CheckoutConfirm from 'Component/CheckoutConfirm';
import CheckoutSuccess from 'Component/CheckoutSuccess';
import {
    BILLING_STEP,
    CHECKOUT_URL,
    DETAILS_STEP,
    SHIPPING_STEP,
    CONFIRM_STEP
} from './Checkout.config';
import { CHECKOUT, CHECKOUT_SUCCESS } from 'Component/Header/Header.config';
import { addressType } from 'Type/Account';
import { paymentMethodsType, shippingMethodsType } from 'Type/Checkout';
import { HistoryType } from 'Type/Common';
import { TotalsType } from 'Type/MiniCart';
import './Checkout.style.override';
import TagManager from 'react-gtm-module';

export class Checkout extends SourceCheckout {
    static propTypes = {
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        setConfirmStep: PropTypes.func.isRequired,
        shippingMethods: shippingMethodsType.isRequired,
        onShippingEstimationFieldsChange: PropTypes.func.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        paymentMethods: paymentMethodsType.isRequired,
        saveAddressInformation: PropTypes.func.isRequired,
        savePaymentInformation: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isDeliveryOptionsLoading: PropTypes.bool.isRequired,
        shippingAddress: addressType.isRequired,
        checkoutTotals: TotalsType.isRequired,
        orderID: PropTypes.string.isRequired,
        history: HistoryType.isRequired,
        onEmailChange: PropTypes.func.isRequired,
        paymentTotals: TotalsType,
        checkoutStep: PropTypes.oneOf([
            SHIPPING_STEP,
            BILLING_STEP,
            DETAILS_STEP,
            CONFIRM_STEP
        ]).isRequired,
        isCreateUser: PropTypes.bool.isRequired,
        onCreateUserChange: PropTypes.func.isRequired,
        onPasswordChange: PropTypes.func.isRequired,
        isGuestEmailSaved: PropTypes.bool.isRequired,
        goBack: PropTypes.func.isRequired,
        totals: TotalsType.isRequired,
        isMobile: PropTypes.bool.isRequired,
        changeFreeDelivery: PropTypes.func.isRequired,
        freeDelivery: PropTypes.bool.isRequired,
        setAddressTmp: PropTypes.func.isRequired,
        addressTmp: PropTypes.objectOf(PropTypes.string).isRequired,
        setAddressTmpCreating: PropTypes.func.isRequired,
        addressTmpCreating: PropTypes.bool.isRequired,
        setAddressBillingTmpCreating: PropTypes.func.isRequired,
        addressBillingTmpCreating: PropTypes.bool.isRequired,
        getAddressTmp: PropTypes.func.isRequired,
        setNotReady: PropTypes.func.isRequired,
        getNotReady: PropTypes.func.isRequired,
        setAddressBillingNotStored: PropTypes.func.isRequired,
        addressBillingNotStored: PropTypes.objectOf(PropTypes.string).isRequired,
        setCheckoutEditAddress: PropTypes.func.isRequired,
        zipCodeValid: PropTypes.bool.isRequired
    };

    stepMap = {
        [SHIPPING_STEP]: {
            title: __('Shipping step'),
            url: '/shipping',
            render: this.renderShippingStep.bind(this),
            areTotalsVisible: true,
            renderCartCoupon: this.renderCartCoupon.bind(this)
        },
        [BILLING_STEP]: {
            title: __('Billing step'),
            url: '/billing',
            render: this.renderBillingStep.bind(this),
            areTotalsVisible: true,
            renderCartCoupon: this.renderCartCoupon.bind(this)
        },
        [CONFIRM_STEP]: {
            title: __('Confirm step'),
            url: '/confirm',
            render: this.renderConfirmStep.bind(this),
            areTotalsVisible: true
        },
        [DETAILS_STEP]: {
            title: __('Thank you for your purchase!'),
            url: '/success',
            render: this.renderDetailsStep.bind(this),
            areTotalsVisible: false
        }
    };

    componentDidMount(){
        this.setState({
            flag : true
        })

        fetchQuery(ConfigQuery.getCheckoutSuccessText()).then(
            ({getCheckoutSuccessText}) => {
                this.setState({
                    checkoutSuccessText: getCheckoutSuccessText.checkout_success_text
                })
            }
        );
    }

    renderTitle() {
        const { checkoutStep } = this.props;
        const { title = '' } = this.stepMap[checkoutStep];

        return (
            <h2 block="Checkout" elem="Title">
                { title }
            </h2>
        );
    }

    //STEP 1
    renderShippingStep() {
        const {
            shippingMethods,
            onShippingEstimationFieldsChange,
            saveAddressInformation,
            isDeliveryOptionsLoading,
            onPasswordChange,
            onCreateUserChange,
            onEmailChange,
            isCreateUser,
            isGuestEmailSaved,
            isWallet,
            changeFreeDelivery,
            setSelectedShipping,
            setAddressTmp,
            addressTmp,
            setAddressTmpCreating,
            addressTmpCreating,
            getAddressTmp,
            setNotReady,
            setAddressBillingNotStored,
            isLoading,
            setCheckoutEditAddress,
            zipCodeValid
        } = this.props;

        this.updateHeader();

        return (
            <CheckoutShipping
              mainLoaderActive={ isLoading }
              isLoading={ isDeliveryOptionsLoading }
              shippingMethods={ shippingMethods }
              saveAddressInformation={ saveAddressInformation }
              onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
              onPasswordChange={ onPasswordChange }
              onCreateUserChange={ onCreateUserChange }
              onEmailChange={ onEmailChange }
              isCreateUser={ isCreateUser }
              isGuestEmailSaved={ isGuestEmailSaved }
              is_wallet={ isWallet }
              changeFreeDelivery={ changeFreeDelivery }
              setSelectedShipping={ setSelectedShipping }
              setAddressTmp={ setAddressTmp }
              addressTmp={ addressTmp }
              setAddressTmpCreating={ setAddressTmpCreating }
              addressTmpCreating={ addressTmpCreating }
              getAddressTmp={ getAddressTmp }
              setNotReady={ setNotReady }
              setAddressBillingNotStored={ setAddressBillingNotStored }
              setCheckoutEditAddress={ setCheckoutEditAddress }
              zipCodeValid={ zipCodeValid }
            />
        );
    }

    //STEP 2
    renderBillingStep() {
        const {
            setLoading,
            setConfirmStep,
            shippingAddress,
            paymentMethods = [],
            savePaymentInformation,
            getAdvanceButtonState,
            setNotReady,
            notReady,
            setSelectedPayment,
            seletedPaymentCustom,
            setCustomBillingPhone,
            customBillingPhone,
            setCustomBillingNif,
            customBillingNif,
            setCustomBillingMbway,
            customBillingMbway,
            totals: { is_wallet },
            isWallet,
            addressBillingNotStored,
            setAddressBillingTmpCreating,
            setAddressBillingNotStored,
            addressBillingTmpCreating,
            isLoading,
            setCheckoutEditAddress,
            zipCodeValid
        } = this.props;

        this.updateHeader();
        
        setNotReady(false);
        
        return (
            <CheckoutBilling
                setLoading={ setLoading }
                mainLoaderActive={ isLoading }
                paymentMethods={ paymentMethods }
                setConfirmStep={ setConfirmStep }
                shippingAddress={ shippingAddress }
                savePaymentInformation={ savePaymentInformation }
                setNotReady={ setNotReady }
                notReady={ notReady }
                seletedPaymentCustom={ seletedPaymentCustom }
                setSelectedPayment={ setSelectedPayment }
                setCustomBillingPhone={ setCustomBillingPhone }
                customBillingPhone={ customBillingPhone }
                setCustomBillingNif={ setCustomBillingNif }
                customBillingNif={ customBillingNif }
                setCustomBillingMbway={ setCustomBillingMbway }
                customBillingMbway={ customBillingMbway }
                is_wallet={ isWallet }
                addressBillingNotStored={ addressBillingNotStored }
                setAddressBillingTmpCreating={ setAddressBillingTmpCreating }
                setAddressBillingNotStored={ setAddressBillingNotStored }
                addressBillingTmpCreating={ addressBillingTmpCreating }
                setCheckoutEditAddress={ setCheckoutEditAddress }
                zipCodeValid={ zipCodeValid }
            />
        );
    }

    //STEP 3
    renderConfirmStep() {
        const {
            confirmPlaceOrder,
            setLoading,
            shippingAddress,
            billingAddress,
            paymentMethod,
            shippingMethod,
            totals: { is_wallet },
            customBillingMbway,
            setNotReady,
            notReady,
            getNotReady
        } = this.props;

        this.updateHeader();

        fetchQuery(ConfigQuery.getAnalyticsConfig('page_view_confirm')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {'send_to': getAnalyticsConfig.config_value});
            }
        );

        return (
            <CheckoutConfirm
                is_wallet = { is_wallet }
                setLoading={ setLoading }
                confirmPlaceOrder={ confirmPlaceOrder }
                shippingAddress={ shippingAddress }
                billingAddress={ billingAddress }
                paymentMethod={ paymentMethod }
                shippingMethod={ shippingMethod }
                customBillingMbway= { customBillingMbway }
                setNotReady={ setNotReady }
                notReady={ notReady }
                getNotReady={ getNotReady }
            />
        );
    }

    //STEP 4
    renderDetailsStep() {
        const {
            orderID,
            shippingAddress,
            billingAddress,
            paymentMethod,
            shippingMethod,
            mbData,
            customer,
            showPreferencePopup,
            updateCustomerPharmacyPreference,
            isSignedIn,
            checkoutTotals,
            totals: { is_wallet }
        } = this.props;

        const { flag,checkoutSuccessText } = this.state

        fetchQuery(ConfigQuery.getAnalyticsConfig('order_confirm')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'send_to': getAnalyticsConfig.config_value,
                    'value': checkoutTotals.grand_total,
                    'currency': 'EUR',
                    'transaction_id': orderID
                });
            }
        );
        if(checkoutTotals.grand_total && flag){
            this.setState({ flag : false });

            ReactPixel.track('Purchase',{
                value:checkoutTotals.grand_total,
                currency:'EUR'
            });
            
            let itemsGTM = Object.values(checkoutTotals.items).map( (item,index) => {
                return {
                    id: item.sku,
                    name: item.product.name,
                    price: item.price,
                    quantity: item.qty
                    
                }
            }) 
        
            TagManager.dataLayer({
                dataLayer: {
                    event: 'purchase',
                    currencyCode: checkoutTotals.quote_currency_code,
                    ecommerce: {
                        purchase: {    
                            actionField: {
                                id: orderID,
                                affiliation: "ANF",
                                revenue: checkoutTotals.grand_total,
                                tax: checkoutTotals.tax_amount,
                                shipping: checkoutTotals.shipping_amount,
                            },
                            products: itemsGTM,
                        }
                    }     
                },
            });
        }

        this.updateHeader();

        return (
            <CheckoutSuccess
                is_wallet={ is_wallet }
                orderID={ orderID }
                mbData={ mbData }
                shippingAddress={ shippingAddress }
                billingAddress={ billingAddress }
                paymentMethod={ paymentMethod }
                shippingMethod={ shippingMethod }
                customer={ customer }
                isSignedIn={ isSignedIn }
                showPreferencePopup={ showPreferencePopup }
                updateCustomerPharmacyPreference={ updateCustomerPharmacyPreference }
                checkoutSuccessText={ checkoutSuccessText }
            />
        );
    }

    updateHeader() {
        const { setHeaderState, checkoutStep, goBack } = this.props;
        const { title = '' } = this.stepMap[checkoutStep];

        setHeaderState({
            name: checkoutStep === CONFIRM_STEP ? CHECKOUT_SUCCESS : CHECKOUT,
            step: checkoutStep,
            title,
            onBackClick: () => goBack()
        });
    }

    renderStep() {
        const { checkoutStep } = this.props;
        const { render } = this.stepMap[checkoutStep];
        if (render) {
            return render();
        }

        return null;
    }

    render() {
        const { checkoutStep, isLoading, device, notReady, freeDelivery, selectedPaymentMethod, addressTmpCreating } = this.props;
        let label = __('Continue');
        let submit = checkoutStep;

        if(checkoutStep == CONFIRM_STEP) {
            label = __('Place order');
        }
        if(checkoutStep == DETAILS_STEP) {
            submit = false;
            cartFloatingRender = null;
        }

        let cartFloatingRender = <div block="Checkout" elem="Floating">
                                    <CartFloating
                                        { ...this.props }
                                        submit={ submit }
                                        label={ label }
                                        checkoutLoading={ isLoading }
                                        notReady={ notReady }
                                        freeDelivery={ freeDelivery }
                                        isOnShipping={ checkoutStep == SHIPPING_STEP }
                                        selectedPaymentMethod={ selectedPaymentMethod }
                                        canEstimate={ true }
                                        addressTmpCreating={ addressTmpCreating }
                                    />
                                    <div block="Checkout" elem="Cart">
                                        { this.renderSummary() }
                                        { this.renderPromo() }
                                    </div>
                                </div>

        if(checkoutStep == CONFIRM_STEP) {
            label = __('Place order');
            cartFloatingRender = <div block="Checkout" elem="Floating">
                                    <CartFloating
                                        { ...this.props }
                                        submit={ submit }
                                        label={ label }
                                        checkoutLoading={ isLoading }
                                        freeDelivery={ freeDelivery }
                                        canEstimate={ true }
                                        addressTmpCreating={ addressTmpCreating }
                                    />
                                </div>;
        }
        if(checkoutStep == DETAILS_STEP) {
            submit = false;
            cartFloatingRender = null;

            return (
                <main block="Checkout">
                    <ContentWrapper
                        wrapperMix={ { block: 'Checkout', elem: 'WrapperTitle' } }
                    >
                        <h1>{ __('Checkout') }</h1>
                    </ContentWrapper>
                    <ContentWrapper
                    wrapperMix={ { block: 'Checkout', elem: 'Wrapper Checkout-Success' } }
                    label={ __('Checkout page') }
                    >
                        { this.renderSummary(true) }
                        <div block="Checkout" elem="Step">
                            { this.renderStep() }
                            { this.renderLoader() }
                        </div>
                    </ContentWrapper>
                </main>
            );
        }

        return (
            <main block="Checkout">
                <ContentWrapper
                    wrapperMix={ { block: 'Checkout', elem: 'WrapperTitle' } }
                >
                    <h1>{ __('Checkout') }</h1>
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'Checkout', elem: 'Wrapper' } }
                  label={ __('Checkout page') }
                >
                    { this.renderSummary(true) }
                    <div block="Checkout" elem="Step">
                        { this.renderStep() }
                        { this.renderLoader() }
                    </div>

                    { cartFloatingRender }
                </ContentWrapper>
            </main>
        );
    }

};

export default Checkout;
