import {
    CheckoutAddressTable as SourceCheckoutAddressTable,
} from 'SourceComponent/CheckoutAddressTable/CheckoutAddressTable.component';

import './CheckoutAddressTable.style.override'

export class CheckoutAddressTable extends SourceCheckoutAddressTable {

    renderTable() {
        const { isSelected } = this.props;

        return (
            <button
              block="CheckoutAddressTable"
              elem="Button"
              type="button"
              mods={ { isSelected } }
              onClick={ this.onAddressClick }
            >
                { this.renderAddressInfo(true) }
            </button>
        );
    }

};

export default CheckoutAddressTable;
