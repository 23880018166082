/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { withRouter } from 'react-router';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import CarouselScroll from 'Component/CarouselScroll';
import Image from 'Component/Image';
import ProductGalleryBaseImage from 'Component/ProductGalleryBaseImage';
import ProductGalleryThumbnailImage from 'Component/ProductGalleryThumbnailImage';
import Slider from 'Component/Slider';
import VideoPopup from 'Component/VideoPopup';
import VideoThumbnail from 'Component/VideoThumbnail';
import { LocationType } from 'Type/Common';
import CSS from 'Util/CSS';
import Overlay from 'Component/Overlay';

import {
    IMAGE_TYPE,
    MAX_ZOOM_SCALE,
    PLACEHOLDER_TYPE,
    VIDEO_TYPE,
    PRODUCT_GALERY_POPUP_ID,
} from './ProductGallery.config';
import './ProductGallery.style';
import './ProductGallery.style.override';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import isMobile from 'Util/Mobile';
import  icon_sauda_product  from 'Component/CartItem/images/sauda.png';
import { DeviceType } from 'Type/Device';

/**
 * Product gallery
 * @class ProductGallery
 * @namespace Component/ProductGallery/Component
 */
export class ProductGallery extends PureComponent {
    static propTypes = {
        gallery: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string
                ]),
                image: PropTypes.string,
                isPlaceholder: PropTypes.bool,
                alt: PropTypes.string,
                type: PropTypes.string
            })
        ).isRequired,
        productId: PropTypes.number,
        isZoomEnabled: PropTypes.bool.isRequired,
        activeImage: PropTypes.number.isRequired,
        onActiveImageChange: PropTypes.func.isRequired,
        handleZoomChange: PropTypes.func.isRequired,
        registerSharedElementDestination: PropTypes.func.isRequired,
        disableZoom: PropTypes.func.isRequired,
        location: LocationType.isRequired,
        sliderRef: PropTypes.object.isRequired,
        handleImageZoomPopupActiveChange: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isImageZoomPopupActive: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
    };

    static defaultProps = {
        productId: 0
    };

    maxScale = MAX_ZOOM_SCALE;

    imageRef = createRef();

    state = {
        scrollEnabled: true,
        saudaPercent: ''
    };

    __construct(props, context) {
        super.__construct(props, context);
        this.renderSlide = this.renderSlide.bind(this);
    }

    componentDidMount() {
        this.updateSharedDestinationElement();
    }

    componentDidUpdate(prevProps) {
        const { productId, location: { pathname }, sliderRef } = this.props;
        const { productId: prevProductId, location: { pathname: prevPathname } } = prevProps;

        if (productId !== prevProductId) {
            this.updateSharedDestinationElement();
        }

        if (sliderRef && pathname !== prevPathname) {
            if(sliderRef.current.draggableRef) {
                CSS.setVariable(
                    sliderRef.current.draggableRef,
                    'animation-speed',
                    0
                );
            }
        }
    }

    handleSliderClick = () => {
        const { handleImageZoomPopupActiveChange } = this.props;

        handleImageZoomPopupActiveChange(true);
    };

    updateSharedDestinationElement() {
        const { registerSharedElementDestination } = this.props;
        registerSharedElementDestination(this.imageRef);
    }

    renderAdditionalPicture = (media, index = 0) => (
        <ProductGalleryThumbnailImage
          key={ index }
          media={ media }
        />
    );

    /**
     * Renders a video thumbnail which opens popup player on click/tap
     * @param media
     * @param index
     * @returns {*}
     * @private
     */
    renderVideo(media, index) {
        return (
            <VideoThumbnail
              key={ index }
              media={ media }
            />
        );
    }

    renderPlaceholder(index) {
        return (
            <Image
              key={ index }
              ratio="custom"
              mix={ {
                  block: 'ProductGallery',
                  elem: 'SliderImage',
                  mods: { isPlaceholder: true }
              } }
              isPlaceholder
            />
        );
    }

    renderCloseButton(){
        const { isMobile,hideActiveOverlay } = this.props;

       // if (!isMobile) {
        return (
            <div
                role="button"
                tabindex="0"
                block="ProductGallery"
                elem="CloseButton"
                onClick= { hideActiveOverlay }
            >
                <svg fill="black" viewBox="0 0 15.642 15.642" id="close" xmlns="http://www.w3.org/2000/svg"><path d="M8.882 7.821l6.541-6.541A.75.75 0 1 0 14.362.219L7.821 6.76 1.28.22A.75.75 0 1 0 .219 1.281L6.76 7.822l-6.54 6.54a.75.75 0 0 0 1.06 1.061l6.541-6.541 6.541 6.541a.75.75 0 1 0 1.06-1.061l-6.54-6.541z"></path></svg>
            </div>
        );
       // } else {

      //      return;
        //}
    }

    stopScrolling() {
        this.setState({ scrollEnabled: false });
        this.timeout = setTimeout(() => {
            this.setState({ scrollEnabled: true });
            this.timeout = null;

            // 20 ms is time give to scroll down, usually that is enough
            // eslint-disable-next-line no-magic-numbers
        }, 20);
    }

    onWheel = (zoomState) => {
        const { scale } = zoomState;

        if (this.timeout) {
            return;
        }

        if (scale === 1 || scale === MAX_ZOOM_SCALE) {
            this.stopScrolling();
        }
    };

    /**
     * Renders a product image to be displayed in the gallery
     * @param mediaData
     * @param index
     * @returns {*}
     * @private
     */
    renderImage(mediaData, index) {
        const {
            isZoomEnabled,
            handleZoomChange,
            disableZoom,
            isMobile,
            isImageZoomPopupActive
        } = this.props;
        const { scrollEnabled } = this.state;

      //  if (!isMobile) {
            const { base: { url: src } } = mediaData;

            const style = isImageZoomPopupActive ? { height: '100%' } : {};

            return (
                <Image
                  key={ index }
                  src={ src }
                  ratio="custom"
                  mix={ {
                      block: 'ProductGallery',
                      elem: 'SliderImage',
                      mods: { isPlaceholder: !src }
                  } }
                  isPlaceholder={ !src }
                  style={ style }
                />
            );
      //  }

        /*return (
            <TransformWrapper
              key={ index }
              onZoomChange={ handleZoomChange }
              onWheelStart={ this.onWheelStart }
              onWheel={ this.onWheel }
              wheel={ { limitsOnWheel: true, disabled: !scrollEnabled } }
            //   doubleClick={ { mode: 'reset' } }
              pan={ {
                  disabled: !isZoomEnabled,
                  limitToWrapperBounds: true,
                  velocity: false
              } }
              options={ {
                  limitToBounds: true,
                  minScale: 1
              } }
            >
                { ({
                    scale,
                    previousScale,
                    resetTransform,
                    setTransform
                }) => {
                    if (scale === 1 && previousScale !== 1) {
                        resetTransform();
                    }

                    return (
                        <ProductGalleryBaseImage
                          setTransform={ setTransform }
                          index={ index }
                          mediaData={ mediaData }
                          scale={ scale }
                          previousScale={ previousScale }
                          disableZoom={ disableZoom }
                          isZoomEnabled={ isZoomEnabled }
                        />
                    );
                } }
            </TransformWrapper>
        );*/
    }

    /**
     * Checks for the type of the slide and renders it accordingly
     * @param media
     * @param index
     * @returns {null|*}
     */
    renderSlide(media, index) {
        const { waitForCustomerData } = this.props;
        const { media_type } = media;

        if(waitForCustomerData) {
            return null;
        }

        switch (media_type) {
        case IMAGE_TYPE:
            return this.renderImage(media, index);
        case VIDEO_TYPE:
            return this.renderVideo(media, index);
        case PLACEHOLDER_TYPE:
            return this.renderPlaceholder(index);
        default:
            return null;
        }
    }

    renderAdditionalPictures() {
        const {
            gallery,
            isImageZoomPopupActive,
            activeImage,
            onActiveImageChange
        } = this.props;

        if (gallery.length === 0) {
            return <div block="ProductGallery" elem="Additional" />;
        }

        return (
            <div block="ProductGallery" elem="Additional" mods={ { isImageZoomPopupActive } }>
                <CarouselScroll activeItemId={ activeImage } onChange={ onActiveImageChange } showedItemCount={ 4 }>
                    { gallery.map(this.renderAdditionalPicture) }
                </CarouselScroll>
            </div>
        );
    }

    renderSlider(actionClick) {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            showOverlay
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive
        };

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
            >
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  isOverlay={ !actionClick }
                  showCrumbs
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ actionClick ? showOverlay : null } //this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }


    renderPromo() {
        const { saudaPercent } = this.state;
        const { isMobile, product, favStoreObj: { sauda }, customer: { favourite_pharmacy_code, sauda_card, vouchers }, customer } = this.props;
        const { campaigns, variants } = product;

        let maxValue      = 0;
        let maxPercentage = 0;
        let campaignToUse = {};
        let allCampaigns  = [];

        let customerVouchers = customer.vouchers;

        let flag = false;
        let discountsVariants = [];
        if ((campaigns) && (campaigns.length > 0)) {
            campaigns.map((campaign) => {
                if(campaign.burn_value_type != null || campaign.burn_value != null){
                    flag = true;
                    //if(campaign.promotional_code_type_value == 'Cash' && campaign.burn_value_type != 'Quantity'){
                    if(campaign.promotional_code_type_value == 'Cash'){
                        if(parseInt(campaign.promotional_code_value) > maxValue){
                            maxValue = parseInt(campaign.promotional_code_value);
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                        }
                    //} else if(campaign.promotional_code_type_value != 'Cash' && campaign.burn_value_type != 'Quantity'){
                    } else if(campaign.promotional_code_type_value != 'Cash'){
                        const { product : { price_range: { maximal_price: { final_price: { value } } } } } = this.props;
                        let price = value * (parseInt(campaign.promotional_code_value) / 100);
                        if(price > maxValue){
                            maxValue = price;
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                        }
                    }
                }else if(!campaign.pharmacy_codes){
                    flag = true;
                }
            });
        } else {
            if (variants && variants.length > 0) {
                variants.map((variant) => {
                    if(variant.price_range && variant.price_range.maximal_price){
                        let value = variant.price_range.maximal_price.final_price.value;

                        if ((variant.campaigns) && (variant.campaigns.length > 0)) {
                            flag = true;
                            variant.campaigns.map((campaign) => {

                                if(campaign.burn_value_type != null || campaign.burn_value != null){
                                    //if(campaign.promotional_code_type_value == 'Cash' && campaign.burn_value_type != 'Quantity'){
                                    if(campaign.promotional_code_type_value == 'Cash'){
                                        if(parseInt(campaign.promotional_code_value) > maxValue){
                                            maxValue = parseInt(campaign.promotional_code_value);
                                            campaignToUse = campaign;
                                            allCampaigns.push(campaign);
                                        }
                                    //} else if(campaign.promotional_code_type_value != 'Cash' && campaign.burn_value_type != 'Quantity'){
                                    } else if(campaign.promotional_code_type_value != 'Cash'){
                                        let price = value * (parseInt(campaign.promotional_code_value) / 100);
                                        if(price > maxValue){
                                            maxValue = price;
                                            campaignToUse = campaign;
                                            allCampaigns.push(campaign);
                                        }
                                    }
                                }
                            });
                        }
                    }
                });
            }
        }

        if(allCampaigns.length > 0){
            flag = true;
            if(campaignToUse.promotional_code_type_value == 'Cash'){
                this.setState({
                    saudaPercent: campaignToUse.promotional_code_value+'€'
                });
            } else {
                this.setState({
                    saudaPercent: campaignToUse.promotional_code_value+'%'
                });
            }
        }
        //return flag;
        let saudaIcon = null;
        let valid = false;
        localStorage.setItem('saudaPercent', false);
        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            if (sauda && (flag || this.getSaudaVerify())) {
                valid = true;
                localStorage.setItem('saudaPercent', true);
            }
        } else {
            if (flag || this.getSaudaVerify()) {
                valid = true;
                localStorage.setItem('saudaPercent', true);
            }
        }

        const points = this.getPoints();
        if(valid) {
            saudaIcon = <div block="ProductCard" elem="SaudaProduct">
                <img
                    src={ icon_sauda_product }
                    width={ isMobile ? "30px" : "60px" }
                    height={ isMobile ? "40px" : "70px" }
                    block="ProductCard"
                    elem="SaudaProductImg"
                    alt={ __('Sauda Card') }
                />
            </div>;

            if(allCampaigns.length > 0 && saudaPercent) {
                saudaIcon = <div block="ProductCard" elem="SaudaPercentLabel">
                    <span block="ProductCard" elem="SaudaPercentFrom">
                        { __('From') }
                    </span>
                    <span block="ProductCard" elem="SaudaPercentValue">
                        -{ saudaPercent }
                    </span>
                    <img
                        src={icon_sauda_product}
                        width={isMobile ? "30px" : "60px"}
                        height={isMobile ? "40px" : "70px"}
                        block="ProductCard"
                        elem="SaudaProductImg"
                        alt={ __('Sauda Card') }
                    />
                </div>;
            } else if (points) {
                saudaIcon = <div block="ProductCard" elem="SaudaPercentLabel">
                    <span block="ProductCard" elem="SaudaPercentFrom">
                        { __('Points') }
                    </span>
                    <span block="ProductCard" elem="SaudaPercentValue">
                        { points }
                    </span>
                    <img
                        src={icon_sauda_product}
                        width={isMobile ? "30px" : "60px"}
                        height={isMobile ? "40px" : "70px"}
                        block="ProductCard"
                        elem="SaudaProductImg"
                        alt={ __('Sauda Card') }
                    />
                </div>;
            }
        }
        return (
            <>
                { saudaIcon }
            </>
        );
    }

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    getSaudaVerify() {
        const { product, productOrVariant } = this.props;
        let sauda = null;
        try {
            sauda = product.attributes.has_points.attribute_value;
        } catch(err) {
            return null;
        }

        return sauda;
    }

    getPoints() {
        const { product, productOrVariant } = this.props;
        let points = null;

        try {
            points = product.attributes.points.attribute_value;
        } catch (err) {
            return null;
        }

        return points;

    }

    checkCustomerVoucher(code, customerVouchers) {
        let flag = false;
        customerVouchers.map((voucher) => {
            if(voucher.reference == code){
                flag = true;
            }
        });
        return flag;
    }

    render() {
        const { isMobile } = this.props;

        if(!isMobile){

            return (
                <div block="ProductGallery">

                    <Overlay
                        id={ PRODUCT_GALERY_POPUP_ID }
                        clickOutside={ false }
                        mix={ { block: PRODUCT_GALERY_POPUP_ID } }
                     >
                        { this.renderCloseButton() }
                        { this.renderSlider(false) }
                    </Overlay>
                    { this.renderAdditionalPictures() }
                    { this.renderSlider(true) }
                    { this.renderPromo() }
                    <VideoPopup />
                </div>
            );
        }

        return(
            <div block="ProductGallery">
                    <Overlay
                        id={ PRODUCT_GALERY_POPUP_ID }
                        mix={ { block: PRODUCT_GALERY_POPUP_ID } }
                     >
                        { this.renderCloseButton() }
                        { this.renderSlider(false)  }
                    </Overlay>
                    { this.renderSlider(true) }
                    { this.renderPromo() }
                    <VideoPopup />
            </div>
        );
    }
}

export default withRouter(ProductGallery);
