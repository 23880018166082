import {
    MyAccountDashboard as SourceMyAccountDashboard,
} from 'SourceComponent/MyAccountDashboard/MyAccountDashboard.component';

import Loader from 'Component/Loader';
import MyAccountAddressPopup from 'Component/MyAccountAddressPopup';
import MyAccountPasswordForm from 'Component/MyAccountPasswordForm';
import MyAccountPasswordTable from 'Component/MyAccountPasswordTable';
import MyAccountCustomerTable from 'Component/MyAccountCustomerTable';
import MyAccountNewsletterSubscription from 'Component/MyAccountNewsletterSubscription';
import MyAccountCoPaymentsTable from 'Component/MyAccountCoPaymentsTable';

import './MyAccountDashboard.style.override'

export class MyAccountDashboard extends SourceMyAccountDashboard {

    renderNoDefaultAddressConfigured(name) {
        return (
            <div key={ name }>
                <p block="MyAccountDashboard" elem="Info">{ __('No %s address configured.', name) }</p>
            </div>
        );
    }

    renderNoAddresses() {
        return (
            <div>
                <p block="MyAccountDashboard" elem="Info">{ __('You have no configured addresses.') }</p>
            </div>
        );
    }

    renderAddNewAddress() {
        const { showCreateNewPopup } = this.props;

        return (
            <button
              block="MyAccountDashboard"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ showCreateNewPopup }
            >
                { __('Add new address') }
            </button>
        );
    }

    renderDefaultAddressTables() {
        const { customer: { addresses = [] } } = this.props;

        if (!addresses.length) {
            return (
                <div block="MyAccountDashboard" elem="AddressesWrapper">
                    <h3 block="MyAccountCustomerTable" elem="Title">{ __('My Addresses') }</h3>
                    { this.renderAddNewAddress() }
                    <div block="MyAccountDashboard" elem="AddressesHolder">
                        { this.renderNoAddresses() }
                    </div>
                </div>
            );
        }

        return (
            <div block="MyAccountDashboard" elem="AddressesWrapper">
                <h3 block="MyAccountCustomerTable" elem="Title">{ __('My Addresses') }</h3>
                { this.renderAddNewAddress() }
                <div block="MyAccountDashboard" elem="AddressesHolder">
                    { this.renderDefaultAddressTable() }
                    <div block="MyAccountDashboard" elem="billingHolder">
                        { this.renderDefaultAddressTable(true) }
                    </div>
                </div>
            </div>
        );
    }

    renderAddressPopup() {
        return <MyAccountAddressPopup />;
    }

    renderChangePasswordSection() {
        const { onPasswordChange } = this.props;

        return (
            <div block="MyAccountDashboard" elem="AddressesWrapper">
                <h3 block="MyAccountCustomerTable" elem="Title">{ __('Change Password') }</h3>
                <div
                    block="MyAccountDashboard"
                    elem="AddressesHolder"
                    mix={ { block: 'MyAccountDashboard', elem: 'PasswordsHolder' } }
                >
                    <MyAccountPasswordForm
                        onPasswordChange={ onPasswordChange }
                    />
                </div>
            </div>
        );
    }
    renderSubscriptionsSection() {
        return (
            <div block="MyAccountDashboard" elem="AddressesWrapper">
                <h3 block="MyAccountCustomerTable" elem="Title">{ __('My Notifications') }</h3>
                <div
                    block="MyAccountDashboard"
                    elem="AddressesHolder"
                    mix={ { block: 'MyAccountDashboard', elem: 'SubscriptionsHolder' } }
                >
                    <MyAccountNewsletterSubscription />
                </div>
            </div>
        );
    }
    renderPasswordTable() {
        const { customer, disableTables , hideTables } = this.props;

        return (
            <MyAccountPasswordTable
                customer={ customer }
                disableTables= { disableTables }
                hideTables= { hideTables }
            />
        );
    }
    renderCustomerTable() {
        const { customer, disableTables , hideTables } = this.props;

        return (
                <MyAccountCustomerTable
                  customer={ customer }
                  disableTables= { disableTables }
                  hideTables= { hideTables }
                  title={ __('My profile') }
                />
        );
    }

    renderCoPaymentsTable() {
        const { customer, disableTables , hideTables, onDeleteClick, handleDeleteCoPayment } = this.props;

        return (
            <MyAccountCoPaymentsTable
                customer={ customer }
                disableTables= { disableTables }
                hideTables= { hideTables }
                onDeleteClick= { onDeleteClick }
                handleDeleteCoPayment={ handleDeleteCoPayment }
            />
        );
    }

    render() {
        const { customer } = this.props;

        return (
            <div block="MyAccountDashboard">
                <Loader isLoading={ !Object.keys(customer).length } />
                <div block="MyAccountDashboard" elem="CustomerData">
                { this.renderCustomerTable() }
                { /*this.renderDefaultAddressTables() */}
                {  this.renderPasswordTable() }
                {/* this.renderCoPaymentsTable() */}
                { /*this.renderSubscriptionsSection()*/ }
                { /*this.renderCustomerPopup()*/ }
                { /*this.renderAddressPopup()*/ }
                </div>
            </div>
        );
    }

};

export default MyAccountDashboard;
