// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Images/shape.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductWishlistButton-Heart{background-color:initial !important;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");transform:scale(0.7) rotate(0deg) !important;width:30px;background-repeat:no-repeat;height:30px}.ProductWishlistButton-Heart::after,.ProductWishlistButton-Heart::before{height:0px;width:0px}.ProductWishlistButton-Button{height:20px;width:20px}.ProductWishlistButton-Button svg{width:20px !important;height:20px;color:#aaa}.ProductWishlistButton-Button_isInWishlist svg{color:var(--primary-base-color)}\n", "",{"version":3,"sources":["webpack://src/app/component/ProductWishlistButton/ProductWishlistButton.style.override.scss"],"names":[],"mappings":"AAAA,6BAA6B,mCAAmC,CAAC,wDAA0C,CAAC,4CAA4C,CAAC,UAAU,CAAC,2BAA2B,CAAC,WAAW,CAAC,yEAAyE,UAAU,CAAC,SAAS,CAAC,8BAA8B,WAAW,CAAC,UAAU,CAAC,kCAAkC,qBAAqB,CAAC,WAAW,CAAC,UAAU,CAAC,+CAA+C,+BAA+B","sourcesContent":[".ProductWishlistButton-Heart{background-color:initial !important;background-image:url(\"./Images/shape.png\");transform:scale(0.7) rotate(0deg) !important;width:30px;background-repeat:no-repeat;height:30px}.ProductWishlistButton-Heart::after,.ProductWishlistButton-Heart::before{height:0px;width:0px}.ProductWishlistButton-Button{height:20px;width:20px}.ProductWishlistButton-Button svg{width:20px !important;height:20px;color:#aaa}.ProductWishlistButton-Button_isInWishlist svg{color:var(--primary-base-color)}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
