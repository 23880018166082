import { connect } from 'react-redux';

import { customerType, addressType } from 'Type/Account';

import MyAccountPageManager from './MyAccountPageManager.component';
import PropTypes from 'prop-types';

import MyAccountQuery from 'Query/MyAccount.query';
import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { fetchMutation, fetchQuery } from 'SourceUtil/Request';
import { ONE_MONTH_IN_SECONDS } from 'SourceUtil/Request/QueryDispatcher';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import gtag, { install } from 'ga-gtag';
import ReactPixel from 'react-facebook-pixel';
import ConfigQuery from 'Query/Config.query';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapDispatchToProps = dispatch => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    updateCustomer: (customer) => dispatch(updateCustomerDetails(customer)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    updateCustomerDetails: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    infoPopup: (payload) => dispatch(showPopup('FieldInfoPopup', payload)),
    accessPopup: (payload) => dispatch(showPopup('FieldAccessPopup', payload)),
    optionPopup: (payload) => dispatch(showPopup('FieldOptionPopup', payload))
});

export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    isSignedIn: state.MyAccountReducer.isSignedIn,
});

export class MyAccountPageManagerContainer extends PureComponent {

    static propTypes = {
        updateCustomer: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        address: addressType.isRequired,
        FormTitle: PropTypes.string.isRequired,
        updateCustomerDetails: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            address: addressType
        }).isRequired,
        changeCreateCardSuccessState: PropTypes.func.isRequired
    };

    static defaultProps = {
        FormTitle : "Customer"
    };

    state = {
        isEditingActive: false,
        isLoading: false,
        showSuccessCreate: false
    };

    containerFunctions = {
        onCustomerSave: this.onCustomerSave.bind(this),
        onPasswordChange: this.onPasswordChange.bind(this),
        onInputChange: this.onInputChange.bind(this),
        handleAddress: this.handleAddress.bind(this),
        handleCoPayments: this.handleCoPayments.bind(this),
        handleHealthCard: this.handleHealthCard.bind(this),
        onInfoPopup: this.onInfoPopup.bind(this),
        onAccessPopup: this.onAccessPopup.bind(this),
        onOptionPopup: this.onOptionPopup.bind(this)
    };

    onError = (error) => {
        const { showErrorNotification } = this.props;
        this.setState({ isLoading: false });
        showErrorNotification(error);
    };

    onInfoPopup() {
        const { infoPopup } = this.props;

        infoPopup({
            title: __('')
        });
    }

    onAccessPopup() {
        const { accessPopup } = this.props;

        accessPopup({
            title: __('')
        });
    }

    onOptionPopup() {
        const { optionPopup } = this.props;

        optionPopup({
            title: __('')
        });
    }

    onCustomerSave(customer) {
        const {
            updateCustomer,
            goToPreviousHeaderState,
            toggleEditForm,
            showSuccessNotification
        } = this.props;

        if(!customer.phone) {
            customer.phone = '';
        }

        const mutation = MyAccountQuery.getUpdateInformationMutation(customer);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountCustomerPopup/Container/onCustomerSaveFetchMutationThen */
            ({ updateCustomer: { customer } }) => {
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                updateCustomer(customer);
                this.setState({ isLoading: false }, () => {
                    goToPreviousHeaderState();
                    toggleEditForm();
                    showSuccessNotification(__('Information successfully updated!'));
                });
            },
            this.onError
        );
    }

    onInputChange(fields) {
    }

    onPasswordChange(passwords) {
        const {
            showSuccessNotification,
            goToPreviousHeaderState,
            showEditForm
        } = this.props;

        const mutation = MyAccountQuery.getChangeCustomerPasswordMutation(passwords);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountCustomerPopup/Container/onPasswordChangeFetchMutationThen */
            () => {
                showSuccessNotification(__('Your password was successfully updated!'));
                this.setState({ isLoading: false }, () => {
                    goToPreviousHeaderState();
                    showEditForm();
                });
            },
            this.onError
        );
    }

    handleAfterActionAddress = () => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            showEditForm,
            showSuccessNotification
        } = this.props;

        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                showSuccessNotification(__('Address successfully created!'));
                this.setState({ isLoading: false }, () => {
                    goToPreviousHeaderState();
                    showEditForm();
                });
            }, showErrorNotification
        );
    };

    handleAfterEditActionAddress = () => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            showEditForm,
            showSuccessNotification
        } = this.props;

        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                showSuccessNotification(__('Address successfully updated!'));
                this.setState({ isLoading: false }, () => {
                    goToPreviousHeaderState();
                    showEditForm();
                });
            }, showErrorNotification
        );
    };

    handleAfterActionCoPayment = () => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            showEditForm,
            showSuccessNotification
        } = this.props;

        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                showSuccessNotification(__('Co-payment successfully created!'));
                this.setState({ isLoading: false }, () => {
                    goToPreviousHeaderState();
                    showEditForm();
                });
            }, showErrorNotification
        );
    };

    handleAfterUpdateCoPayment = () => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            showEditForm,
            showSuccessNotification
        } = this.props;

        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                showSuccessNotification(__('Co-payment successfully updated!'));
                this.setState({ isLoading: false }, () => {
                    goToPreviousHeaderState();
                    showEditForm();
                });
            }, showErrorNotification
        );
    };

    handleAfterActionSaudaCard = () => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            showEditForm,
            showSuccessNotification,
            changeCreateCardSuccessState
        } = this.props;

        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                showSuccessNotification(__('Sauda Card successfully created!'));
                this.setState({ isLoading: false }, () => {
                    //goToPreviousHeaderState();
                    showEditForm(202);
                    changeCreateCardSuccessState();
                });
            }, showErrorNotification
        );
    };

    hideSuccessCreate = () => {
        this.setState({ showSuccessCreate: false });
        const { history } = this.props;
        const {
            location: {
                pathname
            }
        } = history;

        history.push({
            pathname: appendWithStoreCode('/my-account/healthcard')
        });
    }


    handleError = (error) => {
        const { showErrorNotification } = this.props;
        showErrorNotification(error);
        this.setState({ isLoading: false });
    };

    handleAddress(address) {
        const { address: { id } } = this.props;

        address.country_id = 'PT';

        this.setState({ isLoading: true });
        if (id) {
            return this.handleEditAddress(address);
        }

        return this.handleCreateAddress(address);
    }

    handleEditAddress(address) {
        const { address: { id } } = this.props;
        const query = MyAccountQuery.getUpdateAddressMutation(id, address);
        fetchMutation(query).then(this.handleAfterEditActionAddress, this.handleError);
    }

    handleCreateAddress(address) {
        const query = MyAccountQuery.getCreateAddressMutation(address);
        fetchMutation(query).then(this.handleAfterActionAddress, this.handleError);
    }

    handleCoPayments(coPayment) {
        const { currentCoPayment: { entity_id } } = this.props;
        this.setState({ isLoading: true });
        if (entity_id) {
            return this.handleEditCoPayment(coPayment);
        }

        return this.handleCreateCoPayment(coPayment);
    }

    handleCreateCard(healthcard) {
        const query = MyAccountQuery.getCreateSaudaMutation(healthcard);
        //fetchMutation(query).then(this.handleAfterActionSaudaCard, this.handleError);
        fetchMutation(query).then(
            (response) => {
                if(response.createSauda && response.createSauda.phone){
                    fetchQuery(ConfigQuery.getAnalyticsConfig('sauda_card')).then(
                        ({ getAnalyticsConfig }) => {
                            gtag('event', 'conversion', {
                                'send_to': getAnalyticsConfig.config_value
                            });
                        }
                    );
                    fetchQuery(ConfigQuery.getAnalyticsConfig('sauda_card_counter')).then(
                        ({ getAnalyticsConfig }) => {
                            gtag('event', 'conversion', {
                                'allow_custom_scripts': true,
                                'send_to': getAnalyticsConfig.config_value
                            });
                        }
                    );

       
                    ReactPixel.track('CompleteRegistration');
                 

                    this.handleAfterActionSaudaCard();
                } else {
                    this.handleError([{message:__('There was an error joining the Saúda Card. Please check that the registration data is correct and try again.')}]);
                }
            },
            (error) => {
                this.handleError([{message:__('Something went wrong.')}]);
            }
        );
    }

    handleAfterActionSaudaCardWithoutLogin = (response) => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            showEditForm,
            showSuccessNotification,
        } = this.props;

        showSuccessNotification(__('Sauda Card successfully created!'));
        this.setState({ isLoading: false }, () => {
            showEditForm(response.status,response.healthcard);
        });
    };

    handleCreateCardWithoutLogin(healthcard) {
        const query = MyAccountQuery.getCreateSaudaWithoutLoginMutation(healthcard);
        fetchMutation(query).then(
            (response) => {
                if(response.createSaudaWithoutLogin && response.createSaudaWithoutLogin.status != 400){
                    fetchQuery(ConfigQuery.getAnalyticsConfig('sauda_card')).then(
                        ({ getAnalyticsConfig }) => {
                            gtag('event', 'conversion', {
                                'send_to': getAnalyticsConfig.config_value
                            });
                        }
                    );
                    fetchQuery(ConfigQuery.getAnalyticsConfig('sauda_card_counter')).then(
                        ({ getAnalyticsConfig }) => {
                            gtag('event', 'conversion', {
                                'allow_custom_scripts': true,
                                'send_to': getAnalyticsConfig.config_value
                            });
                        }
                    );
                    ReactPixel.track('CompleteRegistration');

                    this.handleAfterActionSaudaCardWithoutLogin(response.createSaudaWithoutLogin);
                } else {
                    this.handleError([{message:__('There was an error joining the Saúda Card. Please check that the registration data is correct and try again.')}]);
                }
            },
            (error) => {
                this.handleError([{message:__('Something went wrong.')}]);
            }
        );
    }

    handleHealthCard(healthcard) {
        const {isSignedIn} = this.props;
        this.setState({ isLoading: true });
        if(isSignedIn){
            return this.handleCreateCard(healthcard);
        }else{
            return this.handleCreateCardWithoutLogin(healthcard);
        }
    }


    handleEditCoPayment(coPayment) {
        const { currentCoPayment: { entity_id } } = this.props;
        const query = MyAccountQuery.getUpdateCoPaymentMutation(entity_id, coPayment);
        fetchMutation(query).then(this.handleAfterUpdateCoPayment, this.handleError);
    }

    handleCreateCoPayment(coPayment) {
        const query = MyAccountQuery.getCreateCoPaymentMutation(coPayment);
        fetchMutation(query).then(this.handleAfterActionCoPayment, this.handleError);
    }

    render() {
        return (
            <MyAccountPageManager
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPageManagerContainer);
