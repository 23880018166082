import {
    CheckoutPayment as SourceCheckoutPayment,
} from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import './CheckoutPayment.style';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import iconMbWay from './images/mbway.png';
import iconMb from './images/mb.png';
import iconVisaLogo from './images/credit_card.png';
import iconPosPago from 'Component/MyAccountMyOrdersDetails/Images/Group745@2x.png';
import iconMbWayHover from './images/mbway_hover.png';
import iconMbHover from './images/mb_hover.png';
import iconVisaLogoHover from './images/creditcard_hover.png';
import iconPosPagoHover from './images/pos_hover.png';
import Image from 'Component/Image';
import CheckoutQuery from 'Query/Checkout.query';
import { fetchQuery } from 'Util/Request';

export class CheckoutPayment extends SourceCheckoutPayment {
    static propTypes = {
        updateMbWayPhone: PropTypes.func.isRequired
    };

    onClick = () => {
        const {
            onClick,
            method,
            setSelectedPayment
        } = this.props;
        setSelectedPayment(method.code);
        onClick(method);
    };


    /* doMbwayPhone = (e) => {
        const {
            method,
            onClick
        } = this.props;

        let phone = e.target.value;

        if (phone.length == 9) {
            this.doMutationphone(phone);
            onClick(method);
        }
    }

    doMutationphone(phone){
        const query = CheckoutQuery.updateQuoteMbwayPhone(phone);
            return fetchQuery(query).then(
                () => {
                },
                (error) => {
                    console.log(error)
                }
            );
    }*/

    doSelectMethod() {
        const {
            method,
            onClick
        } = this.props;

        onClick(method);
    }

    render() {
        const {
            isSelected,
            method: { title, code },
            renderStatus,
            isMobile,
            is_wallet,
            totals,
            method,
            seletedPaymentCustom,
            selectedPaymentCode
        } = this.props;

        let iconMethod = '';

        switch (code) {
            case "checkmo":
                isSelected ? iconMethod = iconPosPagoHover : iconMethod = iconPosPago;
                //this.doSelectMethod();
                is_wallet ? this.doSelectMethod():null;
                break;
            case "mb":
                isSelected ? iconMethod = iconMbHover : iconMethod = iconMb;
                break;
            case "creditcard":
                isSelected ? iconMethod = iconVisaLogoHover : iconMethod = iconVisaLogo;
                break;
            case "mbway":
                isSelected ? iconMethod = iconMbWayHover : iconMethod = iconMbWay;
                break;
            case "cashondelivery":
                isSelected ? iconMethod = iconPosPagoHover : iconMethod = iconPosPago;
                //this.doSelectMethod();
                is_wallet ? this.doSelectMethod():null;
                break;
            case "free":
                isSelected ? iconMethod = iconPosPagoHover : iconMethod = iconPosPago;
                is_wallet ? this.doSelectMethod():null;
                break;
            default:
                break;
        }

        if (seletedPaymentCustom != '' && seletedPaymentCustom == code) {
            this.onClick(method);
        }

        return (
            <>
                <li block="CheckoutPayment">
                    <button
                        block="CheckoutPayment"
                        mods={ { isSelected } }
                        elem="Button"
                        onClick={ this.onClick }
                        type="button"
                    >
                    <Image
                        src = { iconMethod }
                        height="70px"
                    />
                    { isSelected && <div block="CheckoutPayment" elem="Check">
                        <FontAwesomeIcon icon={ faCheck } />
                    </div> }
                    </button>
                </li>
                { isSelected && isMobile && renderStatus() }
            </>
        );
    }
};

export default CheckoutPayment;
