import {
    CheckoutOrderSummary as SourceCheckoutOrderSummary,
} from 'SourceComponent/CheckoutOrderSummary/CheckoutOrderSummary.component';

import CartItem from 'Component/CartItem';
import './CheckoutOrderSummary.style';

export class CheckoutOrderSummary extends SourceCheckoutOrderSummary {

    renderHeading() {
        const { totals: { items_qty } } = this.props;

        return (
            <h3
              block="CheckoutOrderSummary"
              elem="Header"
              mix={ { block: 'CheckoutPage', elem: 'Heading', mods: { hasDivider: true } } }
            >
                <span>{ __('Shopping cart') }</span>
            </h3>
        );
    }

    renderItem = (item) => {
        const {
            totals: {
                quote_currency_code,
                is_wallet
            }
        } = this.props;

        const { item_id } = item;

        return (
            <CartItem
              key={ item_id }
              item={ item }
              is_checkout={ true }
              currency_code={ quote_currency_code }
              is_wallet={ is_wallet }
            />
        );
    };
};

export default CheckoutOrderSummary;
