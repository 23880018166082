import { faGameConsoleHandheld } from '@fortawesome/pro-light-svg-icons';
import { faOtter } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    CategoryConfigurableAttributesContainer as SourceCategoryConfigurableAttributesContainer
} from 'SourceComponent/CategoryConfigurableAttributes/CategoryConfigurableAttributes.container';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    childrenCategories: state.CategoryReducer.category.children,
    customer: state.MyAccountReducer.customer,
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch)
});

export class CategoryConfigurableAttributesContainer extends SourceCategoryConfigurableAttributesContainer {

    containerFunctions = {
        ...this.containerFunctions,
        getSubCategories: this.getSubCategories.bind(this),
        handleOptionClick: this.handleOptionClick.bind(this)
    };

    handleOptionClick({ attribute_code, attribute_value }) {
        const { updateConfigurableVariant, childrenCategories, parameters } = this.props;

        const childrenSelected = this.getChildrenSelected(attribute_value, childrenCategories, parameters, attribute_code);

        if (childrenSelected.length > 0) {
            // remove children
            let newValues = [attribute_value, ...childrenSelected];
            updateConfigurableVariant(attribute_code, newValues);
        } else {
            updateConfigurableVariant(attribute_code, attribute_value);
        }
    }

    getChildrenSelected(id, item, parameters, attribute_code) {
        let valuesSelected = [];

        if(item && item.length) {
            for (let i = 0; i < item.length; i++) {
                // item selected
                if (item[i].id == id) {
                    // check if children are selected
                    if (item[i].children && parameters.category_id) {
                        for (let j = 0; j < item[i].children.length; j++) {
                            if (parameters.category_id.includes(item[i].children[j].id.toString())) {
                                // remove child
                                valuesSelected.push(item[i].children[j].id.toString());

                                // remove grand children
                                if (item[i].children[j].children) {
                                    for (let k = 0; k < item[i].children[j].children.length; k++) {
                                        if (parameters.category_id.includes(item[i].children[j].children[k].id.toString())) {
                                            valuesSelected.push(item[i].children[j].children[k].id.toString());
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (item[i].children) {
                        valuesSelected = [...valuesSelected, ...this.getChildrenSelected(id, item[i].children, parameters, attribute_code)];
                    }
                }
            }
        }

        return valuesSelected;
     }

    removeChildrenSelected(id, item, parameters, attribute_code) {
       for (let i = 0; i < item.length; i++) {
           // item selected
           if (item[i].id == id) {
               // check if children are selected
               if (item[i].children && parameters.category_id) {
                    for (let j = 0; j < item[i].children.length; j++) {
                        if (parameters.category_id.includes(item[i].children[j].id.toString())) {
                            // remove child
                            setTimeout(() => {
                                this.handleOptionClick({attribute_code, attribute_value: item[i].children[j].id.toString()});
                            }, 100);
                        }
                    }
               }
           } else {
               if (item[i].children) {
                   this.removeChildrenSelected(id, item[i].children, parameters, attribute_code);
               }
           }
       }
    }

    getSubCategories(option) {
        const optionWithSubcategories = { ...option };
        const { parameters, childrenCategories } = this.props;
        const { attribute_values } = option;
        const childrenOrdered = childrenCategories ? childrenCategories.sort((a, b) => {
            let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        }) : [];
        let subcategoriesFlag = false;
        let subChildrenIds = [];

        const childrenCategoryIds = childrenOrdered.filter(({ url_key }) => url_key !== 'catalogo').map((category) => category.id.toString());
        let subCategoriesIds = childrenCategoryIds.filter((item) => attribute_values.includes(item));


        // Get category children of main category (if its selected)
        if (parameters.category_id) {
            let mainCat = parameters.category_id;
            if(parameters.category_id.length > 1) {
                mainCat = parameters.category_id.slice(0,1);
            }

            let children = mainCat.reduce((a, c) => {
                    const childrenAux = this.getChildren(c, childrenOrdered);
                    return [...childrenAux, ...a];
                }, [])
                .sort((a, b) => {
                    let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                    if (nameA < nameB)
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0;
                });

            /** build next level categories */
            if(children.length) {
                let countNew = 0;
                children.slice().map((cat, i) => {
                    if(cat.id && parameters.category_id.includes(cat.id.toString())) {
                        let subChildrenAux = this.getChildren(cat.id, childrenOrdered);
                        if(subChildrenAux && subChildrenAux.length && !children.includes(subChildrenAux)) {
                            subChildrenAux.sort((a, b) => {
                                let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                                if (nameA < nameB)
                                    return -1;
                                if (nameA > nameB)
                                    return 1;
                                return 0;
                            });
                            subChildrenAux.map(cat => subChildrenIds.push(cat.id));
                            //insert subcategories after selected category
                            children.splice(i+1+countNew, 0, ...subChildrenAux);
                            countNew += subChildrenAux.length;
                        }
                    }
                });
            }

            let flag = false;
            for (let i = 0; i < children.length; i++) {
                let option = optionWithSubcategories.attribute_options[children[i].id];
                if (!subCategoriesIds.includes(children[i].id.toString()) && option !== undefined) {
                    //if(i==0){
                    if(i==0 || !flag) {
                        flag = true;
                        subCategoriesIds = [];
                        subCategoriesIds.push(mainCat[0]);
                    }
                    subCategoriesIds.push(children[i].id.toString());
                }
            }
            subcategoriesFlag = true;
        }

        optionWithSubcategories.attribute_values = subCategoriesIds;

        return {
            options: optionWithSubcategories,
            hasSubcategories: subcategoriesFlag,
            subChildrenIds: subChildrenIds
        };
    }

    getMainCategory(categories_id, categories) {
        for (let i = 0; i < categories_id.length; i++) {
            let catCompare = categories_id.filter(id => id !== categories_id[i]);

            let flag = false;
            for (let j = 0; j < catCompare.length; j++) {
                let cMap = this.getChildren(catCompare[j], categories);
                let isChildren = this.checkIfChildren(categories_id[i], cMap);

                flag = flag || isChildren;
            }

            if (!flag) {
                return [categories_id[i]];
            }
        }

        return categories_id.slice(0,1);
    }

    checkIfChildren(id, categories) {
        let flag = categories.some(({ id:c }) => c == id );

        if (!flag) {
            flag = categories.reduce((a, b) => {
                a = a || (b.children.length > 0 && this.checkIfChildren(id, b.children));
                return a;
            }, false);
        }

        return flag;
    }

    getChildren(id, children) {
        for (let i = 0; i < children.length; i++) {
            if (children[i].id == id) {
                return children[i].children ? children[i].children : [];
            } else {
                if (children[i].children) {
                    let c = this.getChildren(id, children[i].children);

                    if (c.length > 0) {
                        return c;
                    }
                }
            }
        }

        return [];
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryConfigurableAttributesContainer);
