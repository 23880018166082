/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_STORE_PAGE = 'UPDATE_STORE_PAGE';
export const UPDATE_FAVORITE_PHARMACY = 'UPDATE_FAVORITE_PHARMACY';
export const UPDATE_STORE_PAGE_LOADING = 'UPDATE_STORE_PAGE_LOADING';

/**
 * Update Store Page information
 * @namespace Scandiweb/StoreFinderGraphQl/Store/StorePage/Action/updateStoreFinder
 */
export const updateStorePage = storeObj => ({
    type: UPDATE_STORE_PAGE,
    storeObj
});

/**
 * Update Store Page information
 * @namespace Scandiweb/StoreFinderGraphQl/Store/StorePage/Action/updateStoreFinder
 */
 export const updateFavStore = favStoreObj => ({
    type: UPDATE_FAVORITE_PHARMACY,
    favStoreObj
});

/**
 * Update Store Page loading
 * @namespace Scandiweb/StoreFinderGraphQl/Store/StorePage/Action/updateStoreFinder
 */
 export const updateLoading = isLoading => ({
    type: UPDATE_STORE_PAGE_LOADING,
    isLoading
});