import { PureComponent } from 'react';
import { customerType } from 'Type/Account';

import PropTypes from 'prop-types';
import FieldForm from 'Component/FieldForm';

import './HealthCardForm.style';

export class HealthCardForm extends FieldForm {
    static propTypes = {
        customer: customerType.isRequired,
        handleHealthCard: PropTypes.func.isRequired,
    };

    state = {
        doc_type_maxlength: 10,
        doc_type_validation: 'passport',
        doc_type_minlength: 4,
        changed: false,
        isChecked: false
    }

    componentDidMount() {
        const str = 'aderir-sauda';
        if(window.location.href.includes(str)){
            window.history.replaceState(null, null, "aderir-sauda-s3");
        }
    }


    onFormSuccess = (fields) => {
        const { handleHealthCard } = this.props;
        handleHealthCard(fields);
    };

    get fieldMap() {
        const { customer, baseLinkUrl, showPopup, phoneNumber } = this.props;
        const { doc_type_maxlength, doc_type_validation, doc_type_minlength, changed } = this.state;

        let shippingAddress = '';
        let city = '';
        let postCode = '';
        let conditions = '';

        if(customer && customer.doc_type == 1 && !changed) {
            this.setState({
                doc_type_maxlength: 8,
                doc_type_validation: 'cc',
                doc_type_minlength: 7
            });
        }

        if (customer && customer.addresses && customer.addresses.length) {
            const address = customer.addresses.filter(address => { return address.default_shipping });

            if(address.length) {
                shippingAddress = address[0].street[0];
                city = address[0].city;
                postCode = address[0].postcode;
            }
        }
        conditions =
        <>
            <a block="HealthCard" elem="InfoPhone" onClick= { showPopup }>{__('general conditions.')}</a>
        </>;

        let dateNow = new Date(Date.now());
        let dataMax = ((dateNow.getUTCFullYear()-18) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;
        let dataMin = ((dateNow.getUTCFullYear()-120) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;

        return {
            phone: {
                label: __('Telephone'),
                validation: ['notEmpty'],
                full_line: true,
                value: phoneNumber ? phoneNumber : '',
                isDisabled: true
            },
            date_of_birth: {
                type: 'date',
                label: __('Date of Birth'),
                max: dataMax,
                min: dataMin,
                validation: ['notEmpty', 'date_adult', 'min_date_validation'],
                value: customer ? customer.date_of_birth : '',
            },
            gender: {
                type: 'select',
                label: __('Gender'),
                validation: ['notEmpty'],
                value: customer ? customer.gender : '',
                placeholder: __('Select a option'),
                selectOptions: [
                    {
                        id: 1,
                        value: 1,
                        label: __('Male')
                    },
                    {
                        id: 2,
                        value: 2,
                        label: __('Female')
                    },
                    {
                        id: 3,
                        value: 3,
                        label: __('Undefined')
                    }
                ]
            },
            doc_type: {
                type: 'select',
                label: __('Document ID'),
                validation: ['notEmpty'],
                value: customer ? customer.doc_type : '',
                placeholder: __('Select a option'),
                selectOptions: [
                    {
                        id: 1,
                        value: 1,
                        label: __('Citizen Card')
                    },
                    {
                        id: 2,
                        value: 2,
                        label: __('Passport')
                    }
                ],
                onChange: this.onDocTypeChange
            },
            doc_number: {
                label: __('Identification Number'),
                validation: ['notEmpty', doc_type_validation],
                maxlength: doc_type_maxlength,
                value: customer ? customer.doc_number : '',
            },
            address: {
                label: __('Address'),
                validation: ['notEmpty'],
                full_width: true,
                value: shippingAddress,
                maxlength: 250
            },
            postcode: {
                type: 'postcode',
                label: __('Zip/Postal code'),
                validation: ['notEmpty', 'postcode'],
                placeholder: '9999-999',
                value: postCode,
                maxlength: 8
            },
            locality: {
                label: __('City'),
                validation: ['notEmpty'],
                value: city,
                maxlength: 50
            },
            conditions: {
                type: 'checkbox',
                label: __('I agree with with the '),
                link: conditions,
                full_line: true,
                value: this.state.isChecked,
                onChange: this.onCheckboxChange,
                validation: ['notEmpty', 'checkbox_required']
            }
        };
    }

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    onDocTypeChange = (doc_type) => {
        this.setState({
            doc_type_maxlength: 10,
            doc_type_validation: 'passport',
            doc_type_minlength: 4,
            changed: true
        });

        if(doc_type == 1){
            this.setState({
                doc_type_maxlength: 8,
                doc_type_validation: 'cc',
                doc_type_minlength: 7
            });
        }
    };

    onCheckboxChange = () => {
        this.setState({
            isChecked: !this.state.isChecked
        });
    };

    /*getDefaultValues(fieldEntry) {
        const [key, { value }] = fieldEntry;
        const { currentCard: { [key]: cardValue } } = this.props;
        return {
            ...super.getDefaultValues(fieldEntry),
            value: value !== undefined ? value : cardValue
        };
    }*/

    renderActions() {
        const { showEditForm } = this.props;
        return (
            <>
                <button
                    type="submit"
                    block="Button"
                    mix={ { block: 'MyAccount', elem: 'Button' } }
                    formNoValidate
                >
                    { __('Save Changes') }
                </button>
                <button
                    block="Button"
                    mix={ { block: 'MyAccount', elem: 'Cancel' }  }
                    onClick={ showEditForm }
                >
                    { __('Cancel') }
                </button>
            </>
        );
    }

}

export default HealthCardForm;
