/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
SliderWidgetContainer as SourceSliderWidgetContainer,
mapDispatchToProps as SourceMapDispatchToProps,
mapStateToProps as SourceMapStateToProps,
} from 'SourceComponent/SliderWidget/SliderWidget.container';

import SliderWidget from './SliderWidget.component';

/** @namespace Component/SliderWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    favStoreObj: state.StorePageReducer.favStoreObj
});

/** @namespace Component/SliderWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
});

/** @namespace Component/SliderWidget/Container */
export class SliderWidgetContainer extends SourceSliderWidgetContainer {
    state = {
        slider: {
            slideSpeed: 0,
            slides: []
        }
    };

    componentDidUpdate(prevProps) {
        const { sliderId, favStoreObj } = this.props;
        const { sliderId: pSliderId, favStoreObj: prevFavStoreObj } = prevProps;

        if (sliderId !== pSliderId) {
            this.requestSlider();
        }

        if(favStoreObj != prevFavStoreObj){
            this.requestSlider();
        }
    }

    render() {
        return (
            <SliderWidget
            { ...this.props }
            { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderWidgetContainer);
