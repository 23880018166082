/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
module.exports = (plugin, memberName) => {
    const { implementation } = plugin;

    if (typeof plugin === 'function') {
        return plugin;
    }

    if (typeof implementation === 'function') {
        return implementation;
    }

    throw new Error(
        `No implementation found in plugin definition for member ${memberName}.`
    );
};
