import { Field } from 'Util/Query';

export class HeadScripts {
    getQuery() {
        // TODO implement query
        return this._getScripts();
    }

    _getScripts() {
        return new Field('getScripts')
        .addFieldList(this._getScriptsFields());
    }

    _getScriptsFields(){
        return [
            'googleAnalytics',
            'accountType'
        ];
    }
}

export default new HeadScripts();
