import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { customerType } from 'Type/Account';
import { StorePageDispatcher } from '../../store/StorePage';
import { showNotification } from 'Store/Notification/Notification.action';
import CheckoutPharmacy from './CheckoutPharmacy.component';

export const mapStateToProps = state => ({
    customer: state.MyAccountReducer.customer,
    favStoreObj: state.StorePageReducer.favStoreObj,
    baseLinkUrl: state.ConfigReducer.base_link_url
});

export const mapDispatchToProps = dispatch => ({
    /*requestStore: (id) => {
        StorePageDispatcher.requestStore(dispatch, id);
    },*/
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

export class CheckoutPharmacyContainer extends PureComponent {

    static propTypes = {
        customer: customerType.isRequired,
        addressLinesQty: PropTypes.number.isRequired,
        updateShippingFields: PropTypes.func.isRequired,
        requestStore: PropTypes.func.isRequired,
        baseLinkUrl: PropTypes.string.isRequired
    };

    /*componentDidMount() {
        const { requestStore, customer: { favourite_pharmacy_code } } = this.props;
        if(favourite_pharmacy_code) {
            requestStore(favourite_pharmacy_code);
        }
    }

    componentDidUpdate(prevProps) {
        const { requestStore, customer: { favourite_pharmacy_code } } = this.props;
        const { customer: { favourite_pharmacy_code: prevCode } } = prevProps;

        if(favourite_pharmacy_code && favourite_pharmacy_code != prevCode) {
            requestStore(favourite_pharmacy_code);
        }
    }*/

    render() {
        return (
            <CheckoutPharmacy
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPharmacyContainer);
