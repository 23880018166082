import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountMyOrdersContainer as SourceMyAccountMyOrdersContainer,
    OrderDispatcher,
} from 'SourceComponent/MyAccountMyOrders/MyAccountMyOrders.container';
import { orderType } from 'Type/Account';
import MyAccountMyOrders from './MyAccountMyOrders.component';
import PropTypes from 'prop-types';
import { debounce, fetchMutation, fetchQuery } from 'Util/Request';
import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import Loader from 'Component/Loader';
import BrowserDatabase from 'Util/BrowserDatabase';
import { MY_ACCOUNT_FILTERS } from 'Route/MyAccount/MyAccount.config';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { showPopup } from 'Store/Popup/Popup.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';

export {
    OrderDispatcher,
};

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    favStoreObj: state.StorePageReducer.favStoreObj
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    reloadTotals: () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        )
    },
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
});

export class MyAccountMyOrdersContainer extends SourceMyAccountMyOrdersContainer {
    // TODO implement logic
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        currency_code: PropTypes.string,
        order: orderType.isRequired,
        updateMeta: PropTypes.func.isRequired
    };

    state = {
        showDetailsOrder: localStorage.getItem('showDetailsOrder') ? localStorage.getItem('showDetailsOrder') : false,
        orderDetails: JSON.parse(localStorage.getItem('orderDetails')) ? JSON.parse(localStorage.getItem('orderDetails')) : {},
        title: localStorage.getItem('title') ? localStorage.getItem('title') : '',
        increment_id: localStorage.getItem('increment_id') ? localStorage.getItem('increment_id') : '',
        order: JSON.parse(localStorage.getItem('order')) ? JSON.parse(localStorage.getItem('order')) : orderType.isRequired,
        page: 1,
        orderId : localStorage.getItem('orderId') ? localStorage.getItem('orderId') : '',
        isLoadingRebuy: false,
        filters: BrowserDatabase.getItem(MY_ACCOUNT_FILTERS) || {},
        showFilter: false
    };

    containerFunctions = {
        manageShowDetail: this.manageShowDetail.bind(this),
        onPageChange: this.onPageChange.bind(this),
        showBack: this.showBack.bind(this),
        reBuy: this.reBuy.bind(this),
        removeFilters: this.removeFilters.bind(this),
        setFilters: this.setFilters.bind(this),
        getFilters: this.getFilters.bind(this),
        updateMeta: this.updateMeta.bind(this)
    };

    updateMeta() {
        const { updateMeta } = this.props;
        updateMeta({ title: __('Encomendas') });
    }

    componentWillUnmount() {
        this.setState({
            showDetailsOrder: false
        })
        localStorage.removeItem('showDetailsOrder');
        localStorage.removeItem('orderDetails');
        localStorage.removeItem('title');
        localStorage.removeItem('increment_id');
        localStorage.removeItem('order');
        localStorage.removeItem('orderId');

        this.removeFilters();
    }

    removeFilters() {
        BrowserDatabase.deleteItem(MY_ACCOUNT_FILTERS);
        this.setState({ filters: {}});
    }

    setFilters(filters) {
        const { filters: filterStored } = this.state;

        const newFilter = {...filterStored, ...filters};
        this.setState({ filters: newFilter});

        BrowserDatabase.setItem(
            newFilter,
            MY_ACCOUNT_FILTERS,
            ONE_MONTH_IN_SECONDS
        );
    }

    getFilters() {
        return BrowserDatabase.getItem(MY_ACCOUNT_FILTERS) || {};
    }

    /*componentDidMount() {
        this.getOrderList();
    }

    getOrderList() {
        this.setState({ isLoading: true });
        fetchQuery(query).then(

            ({ getOrderList: orders }) => {
                this.setState({ orderList: orders });
                this.setState({ isLoading: false });
            },
            (error) => {
                this.setState({ isLoading: false });
                showNotification('error', error[0].message)
            }
        );
    }*/

    manageShowDetail(order) {
        const { base_order_info: { id, increment_id } } = order;

        this.setState({
            showDetailsOrder: true,
            orderDetails: order,
            title: __('Order #%s', increment_id),
            increment_id: increment_id,
            order: order,
            orderId: id
        });

        localStorage.setItem('showDetailsOrder',true);
        localStorage.setItem('orderDetails',JSON.stringify(order));
        localStorage.setItem('title',__('Order #%s', increment_id));
        localStorage.setItem('increment_id',increment_id);
        localStorage.setItem('order',JSON.stringify(order));
        localStorage.setItem('orderId',id);
    }

    showBack() {
        this.setState({
            showDetailsOrder: false
        })
        localStorage.removeItem('showDetailsOrder');
        localStorage.removeItem('orderDetails');
        localStorage.removeItem('title');
        localStorage.removeItem('increment_id');
        localStorage.removeItem('order');
        localStorage.removeItem('orderId');
    }
    onPageChange(page) {
        this.setState({ page: page.selected + 1 });
    }

    reBuy(orderId){
        const { showNotification, reloadTotals } = this.props;

        this.setState({ isLoadingRebuy: true });
        fetchMutation(OrderQuery._getReorderByOrderId(
            orderId
        )).then(
            /** @namespace Route/Checkout/Container/onShippingEstimationFieldsChangeFetchMutationThen */
            ({ reorderItems: { cart, userInputErrors } }) => {
                if((Array.isArray(userInputErrors) && userInputErrors.length > 0) || (typeof userInputErrors === 'string' || userInputErrors instanceof String)) {
                    userInputErrors.map((error) => {
                        if(error.message == 'Alguns dos produtos desta encomenda não foram adicionados ao Carrinho por serem sujeitos a Receita Médica. Para a dispensa de Receitas Médicas, por favor, aceda ao menu Receitas Médicas.'){
                            showNotification('info', error.message);
                        } else {
                            showNotification('error', error.message);
                        }
                    });
                    reloadTotals();
                } else {
                    showNotification('success', __('All products in this order have been added to the cart.'));
                    //history.push({ pathname: appendWithStoreCode('/cart') });
                    reloadTotals();
                }
                this.setState({ isLoadingRebuy: false });
            },
            (errors) => {
                if(errors[0] && errors[0].debugMessage){
                    showNotification('error', errors[0].debugMessage);
                } else {
                    showNotification('error', __('Something went wrong! Please try again latter.'));
                }
                this.setState({ isLoadingRebuy: false });
            }
        );
    }

    render() {
        const { isLoadingRebuy } = this.state;
        const { isLoading } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading || isLoadingRebuy } />
                <MyAccountMyOrders
                    { ...this.containerFunctions }
                    { ...this.props }
                    { ...this.state }
                />
            </>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountMyOrdersContainer);
