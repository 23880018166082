/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import ContentWrapper from 'Component/ContentWrapper';
import { appendWithStoreCode } from 'Util/Url';
import {
    Breadcrumbs as SourceBreadcrumbs,
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';


/**
 * Breadcrumbs
 * @class Breadcrumbs
 * @namespace Component/Breadcrumbs/Component
 */
export class Breadcrumbs extends SourceBreadcrumbs {

    render() {
        const { breadcrumbs, areBreadcrumbsVisible } = this.props;

        if (
            !areBreadcrumbsVisible
            || location.pathname === appendWithStoreCode('/')
            || location.pathname === '/'
        ) {
            return null;
        }

        let breadcrumbsClass = "Breadcrumbs";
       // window.location.includes.name('blog') ? breadcrumbsClass = 'Breadcrumbs-Blog': breadcrumbsClass = 'Breadcrumbs';

        return (
            <ContentWrapper mix={ { block:  'Breadcrumbs-Blog' } } label={ __('Breadcrumbs (current location)...') }>
                <nav aria-label="Breadcrumbs navigation">
                    <ul
                      block="Breadcrumbs"
                      elem="List"
                      itemScope
                      itemType="http://schema.org/BreadcrumbList"
                    >
                        { (
                            breadcrumbs.length
                                ? this.renderBreadcrumbList(breadcrumbs)
                                : this.renderBreadcrumb({}, 0)
                        ) }
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default Breadcrumbs;
