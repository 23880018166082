import {
    MyAccountMyOrders as SourceMyAccountMyOrders,
} from 'SourceComponent/MyAccountMyOrders/MyAccountMyOrders.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faSortAlt, faSlidersH, faSortAmountDown, faSortAmountUp } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import LeftIcon from 'Route/StoreFinder/images/left.svg';
import RightIcon from 'Route/StoreFinder/images/right.svg';

import MyAccountOrderTableRow from 'Component/MyAccountOrderTableRow';
import MyAccountMyOrdersFilter from 'Component/MyAccountMyOrdersFilter';
import CategorySort from 'Component/CategorySort';
import MyAccountOrderPopup from 'Component/MyAccountOrderPopup';
import MyAccountMyOrdersDetails from 'Component/MyAccountMyOrdersDetails';
import PaginationView from 'Component/Pagination';
import Loader from 'Component/Loader';
import { ordersType } from 'Type/Account';
import { format } from "date-fns";
import PropTypes from 'prop-types';
import iconNoPharmacy from 'Component/MyPharmacy/Images/icon.png';
import Link from 'Component/Link';
import Image from 'Component/Image';
import Field from 'Component/Field';
import './MyAccountMyOrders.style.override'

import React, { createRef } from 'react';
export class MyAccountMyOrders extends SourceMyAccountMyOrders {

    static propTypes = {
        updateMeta: PropTypes.func.isRequired,
        sortFields: PropTypes.shape({
        options: PropTypes.array
        }).isRequired,
        selectedSort: PropTypes.shape({
        sortDirection: PropTypes.oneOf([
            'ASC',
            'DESC'
        ]),
        sortKey: PropTypes.string
    }).isRequired,
    onSortChange: PropTypes.func.isRequired,
        orderList: ordersType.isRequired,
        manageShowDetail: PropTypes.func.isRequired,
        showBack: PropTypes.func.isRequired,
        reBuy: PropTypes.func.isRequired
    };

    startTime = 0;
    endTime = 0;

    myRef = {};

    state = {
        showFilter: false,
        showSort: false,
        showSortAsc: false,
        clearFilter: false,
        sortData: [],
        showResults: false,
        searchingOrder: '',
        searchingTotal: '',
        searchingDate: '',
        searchingStatus: '',
        limit: 10
    }

    componentDidMount() {
        const { getFilters, updateMeta } = this.props;
        const { searchingOrder, searchingTotal, searchingDate, searchingStatus } = getFilters();

        updateMeta();

        let showFilter = false;
        if (searchingOrder && searchingOrder !== '') {
            this.setState({ searchingOrder });
            showFilter = true;
        }

        if (searchingTotal && searchingTotal !== '') {
            this.setState({ searchingTotal });
            showFilter = true;
        }

        if (searchingDate && searchingDate !== '') {
            this.setState({ searchingDate });
            showFilter = true;
        }

        if (searchingStatus && searchingStatus !== '') {
            this.setState({ searchingStatus });
            showFilter = true;
        }

        if (showFilter) {
            this.activeFilter();
            this.setState({showResults: true});
        }
    }

    changeOrder = (value) => {
        const { setFilters } = this.props;
        this.setState({ searchingOrder: value  });
        setFilters({ searchingOrder: value  });
    }

    changeTotal = (value) => {
        const { setFilters } = this.props;
        this.setState({ searchingTotal: value });
        setFilters({ searchingTotal: value  });
    }

    changeDate = (value) => {
        const { setFilters } = this.props;
        this.setState({ searchingDate: value });
        setFilters({ searchingDate: value  });
    }

    changeStatus = (value) =>{
        const { setFilters } = this.props;
        this.setState({ searchingStatus: value });
        setFilters({ searchingStatus: value  });
    }

    renderOrderHeadingRow = (order) => {
        const { manageShowDetail } = this.props;
        return (
            <tr block="MyAccountMyOrders" elem="Table-Title">
                <th>{ __('Order number') }</th>
                <th>{ __('Purchase date') }</th>
                <th>{ __('Value') }</th>
                <th>{ __('State') }</th>
                <th block="MyAccountOrderTableRow" elem="LinkRight"><a href="#" onClick={ () => manageShowDetail(order) } >{ __('View Details') }</a></th>
            </tr>
        );
    }

    renderTable= (order) =>  {
        return (
            <table block="MyAccountMyOrders" elem="Table">
                 { this.renderOrderRow(order) }
            </table>
        );
    }

    renderOrderRow = (order) => {
        const { base_order_info: { id, increment_id } } = order;
        const { manageShowDetail, reBuy } = this.props;

        return (
            <>
            <thead>
                {    this.renderOrderHeadingRow(order) }
            </thead>
            <tbody>
                <MyAccountOrderTableRow
                    key={ id }
                    order={ order }
                    manageShowDetail={ manageShowDetail }
                    reBuy={ reBuy }
                />
                <td block="MyAccountOrderTableRow" elem="LinkRight-Mobile">
                    <a href="#" onClick={ () => manageShowDetail(order) } >{ __('View Details') }</a>
                    <a onClick={ () => reBuy(increment_id) }>{ __('Rebuy') }</a>
                </td>
            </tbody>
            </>
        );
    };

    renderOrderRows() {
        const { orderList, isLoading } = this.props;
        const {
            searchingOrder,
            searchingTotal,
            searchingDate,
            searchingStatus,
            showResults
        } = this.state;
        let newOrderList = orderList;
        let searchingDateFormat = searchingDate;

        if(searchingDate != ''){
            searchingDateFormat = format(searchingDate, "DD.MM.YYYY");
        }

        if(( searchingOrder != '' || searchingTotal != '' || searchingDate != '' || searchingStatus != '' ) && showResults ) {
            newOrderList = orderList.filter(items => {
                return ((searchingOrder ? items.base_order_info.increment_id.toLowerCase().includes(searchingOrder.toLowerCase()) : true ) &&
                (searchingTotal ? items.base_order_info.grand_total == searchingTotal : true ) &&
                (searchingDateFormat ? items.base_order_info.created_at.toLowerCase().includes(searchingDateFormat) : true ) &&
                (searchingStatus ? items.base_order_info.status_label.toLowerCase() == searchingStatus.toLowerCase() : true ) );
            });
        }

        return newOrderList;
    }

    renderNoOrders() {
        const { device, favStoreObj: { store_url }, isLoading, showPharacySelectorPopup, requestStores } = this.props;

        if (isLoading) {
            return;
        }

        requestStores();
        let buyButton = <>
            <button onClick={ showPharacySelectorPopup } block="MyAccountMyOrders" elem="Button" mix={ { block: 'Button' } } >
                <span  block="MyAccountMyOrders" elem="HeadingLinkText">{ __('Start Buying!') }</span>
            </button>
        </>;
        if(store_url) {
            buyButton = <>
                <Link to={ store_url } block="MyAccountMyOrders" elem="Button">
                    <button block="MyAccountMyOrders" elem="Button" mix={ { block: 'Button' } } >
                        <span  block="MyAccountMyOrders" elem="HeadingLinkText">{ __('Start Buying!') }</span>
                    </button>
                </Link>
            </>;
        }

        return (
            <>
                <div class="block-image-message">
                    <Image
                        src={ iconNoPharmacy }
                        width="40px"
                        height="60px"
                    />
                    <p>{ __('There are no orders associated with your account.') }</p>
                </div>
                <div class="btn-choose-order">
                    { buyButton }
                </div>
            </>
        );
    }

    renderNoOrdersSearch() {
        const { device, favStoreObj: { store_url }, showPharacySelectorPopup, requestStores } = this.props;
        requestStores();
        let buyButton = <>
            <button onClick={ showPharacySelectorPopup } block="MyAccountMyOrders" elem="Button" mix={ { block: 'Button' } } >
                <span  block="MyAccountMyOrders" elem="HeadingLinkText">{ __('Start Buying!') }</span>
            </button>
        </>;
        if(store_url) {
            buyButton = <>
                <Link to={ store_url } block="MyAccountMyOrders" elem="Button">
                    <button block="MyAccountMyOrders" elem="Button" mix={ { block: 'Button' } } >
                        <span  block="MyAccountMyOrders" elem="HeadingLinkText">{ __('Start Buying!') }</span>
                    </button>
                </Link>
            </>;
        }

        return (
            <>
                <div class="block-image-message">
                    <Image
                        src={ iconNoPharmacy }
                        width="40px"
                        height="60px"
                    />
                    <p>{ __('Sorry, no orders matching your search were found.') }</p>
                </div>
                <div class="btn-choose-order">
                    { buyButton }
                </div>
            </>
        );
    }

    renderOrderFilter() {
        return (
            <div block="MyAccountMyOrders" elem="Filter">
                { this.renderFilters() }
                { this.renderSeeResults() }
            </div>
        );
    }

    renderFilters() {
        const { getFilters } = this.props;

        return (
            <div>
                <h3 block="MyAccountMyOrders" elem="Filter-Title">{ __('Filter to') }</h3>
                <MyAccountMyOrdersFilter
                    changeOrder = { this.changeOrder }
                    changeTotal = { this.changeTotal }
                    changeDate = { this.changeDate }
                    changeStatus = { this.changeStatus }
                    searchOrderFilter={ this.searchOrderFilter }
                    clearFilter = {this.state.clearFilter}
                    values={getFilters()}
                />
            </div>
        );
    }

    searchOrderFilter = () => {
        this.setState({
            showResults: true,
            clearFilter: false
        })
    }

    clearFilter = () => {
        const { removeFilters } = this.props;
        removeFilters();

        this.setState({
            showResults: false,
            searchingOrder: '',
            searchingTotal: '',
            searchingDate: '',
            searchingStatus: '',
            clearFilter: true
        })
    }

    renderSeeResults() {

        return (
            <div block="MyAccountMyOrders" elem="SeeResults">
                <button
                block="MyAccountMyOrders"
                elem="Button"
                mix={ { block: 'Button' } }
                onClick={ this.searchOrderFilter }
                >
                    { __('See results') }
                </button>
                <button
                  block="MyAccountMyOrders"
                  elem="Button-Clear"
                  mix={ { block: 'Button' } }
                  onClick={ this.clearFilter }
                >
                    { __('Clear search') }
                </button>
            </div>
        );
    }

    renderOrderName() {

        const { showFilter, showSortAsc } = this.state;
        let iconSort;
        if( showSortAsc ){
            iconSort =  <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faSortAmountDown} />;
        } else {
            iconSort =  <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faSortAmountUp} />;
        }
        return (
            <>
                <div block="MyAccountMyOrders" elem="Heading">
                    <h1 block="MyAccountMyOrders" elem="Title">
                        { __('My orders') }
                    </h1>
                    <button block="MyAccountMyOrders" elem="Heading-Right" onClick={ this.activeFilter }>
                        <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faSlidersH} />
                        <label>{ __('Filter') }</label>
                    </button>
                    <button block="MyAccountMyOrders" elem="Heading-Right" onClick={ this.activeSort }>
                        { iconSort }
                        <label>{ __('SORT') }</label>
                    </button>
                </div>
                { showFilter && this.renderOrderFilter() }
            </>
        );
        /*<div block="MyAccountMyOrders" elem="Heading-Right">
                        <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faSortAlt}  onClick={ this.renderSortField() }/>
                        { this.renderSortField() }
                    </div>*/
    }

    activeSort = () => {
        const { showSortAsc } = this.state;
        if(showSortAsc){
            this.setState({
                showSortAsc: false
            })
        } else{
            this.setState({
                showSortAsc: true
            })
        }

        this.renderPageManager();
    }
    sortListData(orderList){
        const { showSortAsc } = this.state;
        let sortedOrderList = '';

        if (showSortAsc) {
            sortedOrderList = orderList
                .sort(function(a, b) {                    
                    if (a.base_order_info.created_at === b.base_order_info.created_at) {
                        return a.base_order_info.increment_id.localeCompare(b.base_order_info.increment_id);
                    }

                    let daStr = a.base_order_info.created_at.split('.');
                    let dbStr = b.base_order_info.created_at.split('.');

                    let da = new Date(parseInt(daStr[2], 10), parseInt(daStr[1], 10) - 1, parseInt(daStr[0], 10));
                    let db = new Date(parseInt(dbStr[2], 10), parseInt(dbStr[1], 10) - 1, parseInt(dbStr[0], 10));

                    return da - db;
                });

        } else {
            sortedOrderList = orderList
                .sort(function (a, b) {
                    if (a.base_order_info.created_at === b.base_order_info.created_at) {
                        return b.base_order_info.increment_id.localeCompare(a.base_order_info.increment_id);
                    }

                    let daStr = a.base_order_info.created_at.split('.');
                    let dbStr = b.base_order_info.created_at.split('.');

                    let da = new Date(parseInt(daStr[2], 10), parseInt(daStr[1], 10) - 1, parseInt(daStr[0], 10));
                    let db = new Date(parseInt(dbStr[2], 10), parseInt(dbStr[1], 10) - 1, parseInt(dbStr[0], 10));

                    return db - da;
                });
        }

        return sortedOrderList;
    }

    activeFilter = () => {
        this.setState({
            showFilter: !this.state.showFilter
        })
    }

    calcPagination(orderList){
        const { page } = this.props;

        const ordersInPage = orderList.filter((order, index) => {
			return (index < page * this.state.limit);
        });
        return ordersInPage;
    }

    renderPageManager() {
        const { orderList, isLoading, onPageChange, page } = this.props;
        let ordersInPage = {};
        let newOrderList = orderList;
        const { showResults } = this.state;

        if(showResults){
            newOrderList = this.renderOrderRows();
            if (!isLoading && !newOrderList.length) {
                return (
                    <div block="MyAccount" elem="TabContent">
                        { this.renderOrderName() }
                        <div block="MyAccountMyOrders">
                            { this.renderNoOrdersSearch() }
                        </div>
                    </div>
                );
            }
        }
        newOrderList = this.sortListData(newOrderList);

        ordersInPage = this.calcPagination(newOrderList);

        if (!newOrderList.length) {
            return (
                <div block="MyAccount" elem="TabContent">
                    <div block="MyAccountMyOrders" elem="Heading">
                        <h1 block="MyAccountMyOrders" elem="Title">
                            { __('My orders') }
                        </h1>
                    </div>
                    <div block="MyAccountMyOrders">
                        { this.renderNoOrders() }
                    </div>
                </div>
            );
        }

        const count = Math.ceil(newOrderList.length / this.state.limit);

        return (
            <div block="MyAccount" elem="TabContent">
                { this.renderOrderName() }

                <div block="MyAccountMyOrders">
                    <Loader isLoading={ isLoading } />
                    { ordersInPage.map(order => this.renderTable(order)) /*renderTable*/ } 

                    {
                        page < count &&
                        <div block="MyAccountMyOrders" elem="ShowMore">
                            <button
                                block="MyAccountMyOrders"
                                elem="SelectButton"
                                mix={ { block: 'Button' } }
                                onClick={ () => { onPageChange({selected:page}) } }
                            >{ __('Show more results') }</button>
                        </div>
                    }
                </div>
            </div>
        );
    }


    renderDetailsOrder(button) {
        const { cardDetails} = this.state;
        const { orderList, reBuy } = this.props;
        let increment_id = '';
        let id = '';
        let orderDetails = {};
        let base_order_info = {};

        if(localStorage.getItem('orderId')){
            orderDetails    = JSON.parse(localStorage.getItem('orderDetails'));
            base_order_info = orderDetails.base_order_info;
            increment_id    = base_order_info.increment_id;
            id              = base_order_info.id;
        } else {
            const { orderList, orderDetails, reBuy } = this.props;
            const { base_order_info: { id,  increment_id} } = orderDetails;
        }

        return (
            <>
                <h1 block="MyAccount" elem="Heading MyAccountMyOrdersDetails-Heading">{ button }{ __('Order Details %s', increment_id) }</h1>
                <div block="MyAccountMyOrdersDetails">
                <MyAccountMyOrdersDetails
                    orderDetails = { orderDetails }
                    key = {id}
                    reBuy={ reBuy }
                />
                </div>
            </>
        );
    }

    render() {
        const { showDetailsOrder, showBack} = this.props;
        let button;
        if(showDetailsOrder) {
            button = <button block="MyAccount" elem="BackButton" onClick={ () => showBack() } >
                        <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                        { __('Back') }
                    </button>;
        }
        return (
            <>
                { showDetailsOrder ? this.renderDetailsOrder(button) : this.renderPageManager() }
            </>
        );

    }
};

export default MyAccountMyOrders;
