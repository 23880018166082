/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import { Field } from 'Util/Query';

 /**
  * Order Query
  * @class OrderQuery
  * @namespace Query/Order
  */
 export class OrderQuery {
    getOrderListQuery() {
        return new Field('getOrderList')
            .addFieldList(this._getOrderListFields(true));
    }

    getOrderByIdQuery(orderId) {
        return this._getOrderByIdField(orderId);
    }

    _getOrderListFields(isList) {
        return [
            this._getOrderItemsField(isList)
        ];
    }

    _getOrderByIdField(orderId) {
        return new Field('getOrderById')
            .addArgument('id', 'Int!', orderId)
            .addFieldList(this._getOrderItemsFields());
    }

    _getOrderProductsField() {
        return new Field('order_products')
            .addFieldList(this._getOrderProductsFields());
    }

    _getOrderProductsFields() {
        return [
            ...this._getDefaultFields(),
            ...this._prepareImageFields(),
            this._prepareAttributes()
        ];
    }

    _prepareImageFields() {
        return [
            new Field('thumbnail')
                .addFieldList(this._prepareThumbnailFields()),
            new Field('small_image')
                .addFieldList(this._prepareSmallImageFields())
        ];
    }

    _prepareSmallImageFields() {
        return [
            'url',
            'label',
            'path'
        ];
    }

    _prepareThumbnailFields() {
        return [
            'url',
            'label',
            'path'
        ];
    }

    _prepareAttributes() {
        return new Field('s_attributes')
            .setAlias('attributes')
            .addFieldList(this._prepareAttributesFields());
    }

    _prepareAttributesFields() {
        return [
            'attribute_value',
            'attribute_code',
            'attribute_type',
            'attribute_label',
            this._getAttributeOptions()
        ];
    }

    _getAttributeOptions() {
        return new Field('attribute_options')
            .addFieldList(this._getAttributeOptionsFields());
    }

    _getAttributeOptionsFields() {
        return [
            'label',
            'value',
            new Field('swatch_data')
                .addField('value')
        ];
    }

    _getDefaultFields() {
        return [
            'id',
            'name',
            (new Field('short_description').addField('html')),
            'sku',
            'qty',
            'row_total',
            'original_price',
            'base_price',
            'license_key',
            'buy_with_points',
            'product_points',
            'prescription',
            'img',
            'campaign_price',
            'before_campaign_price',
            'campaign_code',
            'campaign_is_active',
            'custom_total_price'
        ];
    }

    _prepareShippingInfo() {
        return new Field('shipping_info')
            .addFieldList(this._prepareShippingInfoFields());
    }

    _getCampaignsField() {
        return new Field('campaigns')
            .addFieldList(this._prepareCampaignsFields());
    }

    _prepareShippingInfoFields() {
        return [
            'base_shipping_amount',
            'base_discount_amount',
            'shipping_amount',
            'Sauda_discount_points',
            'sauda_discount',
            'coupon_code',
            'customer_taxvat',
            'mbway_phone',
            'mb_entity',
            'mb_reference',
            'mb_amount',
            'mb_begin_date',
            'mb_end_date',
            'shipping_incl_tax',
            'shipping_method',
            'shipping_description',
            'shipping_amount',
            'tracking_numbers',
            'tracking',
            'postal_code_locality',
            this._prepareOrderCustomerAddressInfo(),
            this._prepareOrderCustomerBillingAddressInfo()
        ];
    }

    _prepareCampaignsFields() {
        return [
            'description',
            'value'
        ];
    }

    _prepareOrderCustomerAddressInfo() {
        return new Field('shipping_address')
            .addFieldList(this._prepareOrderCustomerAddressInfoFields());
    }

    _prepareOrderCustomerBillingAddressInfo() {
        return new Field('billing_address')
            .addFieldList(this._prepareOrderCustomerAddressInfoFields());
    }

    _prepareOrderCustomerAddressInfoFields() {
        return [
            'city',
            'company',
            'firstname',
            'lastname',
            'middlename',
            'telephone',
            'district',
            'house_number',
            'apartment_number',
            'postomat_code',
            'store_pickup_code',
            'post_office_code',
            'postcode',
            'street',
            'is_b2b',
            'region',
            'organizationname',
            'organizationbin',
            'organizationaddress',
            'organizationiic',
            'organizationbik',
            'vat_id'
        ];
    }

    _getBaseOrderInfoField(isList) {
        return new Field('base_order_info')
            .addFieldList(this._getBaseOrderInfoFields(isList));
    }

    _getBaseOrderInfoFields(isList) {
        return [
            'id',
            'increment_id',
            'created_at',
            'status_label',
            'status_code',
            'grand_total',
            ...(isList ? [] : ['sub_total']),
            'pharmacy_code',
            'pharmacy_name'
        ];
    }

    _getPaymentInfoField() {
        return new Field('payment_info')
            .addFieldList(this._getPaymentInfoFields());
    }

    _getPaymentInfoFields() {
        return [
            'method',
            this._getAdditionalInformationField()
        ];
    }

    _getAdditionalInformationField() {
        return new Field('additional_information')
            .addFieldList(this._getAdditionalInformationFields());
    }

    _getAdditionalInformationFields() {
        return [
            'bank',
            'method_title',
            'credit_type',
            'month',
            this._getCustomerInfoField()
        ];
    }

    _getCustomerInfoField() {
        return new Field('customer_info')
            .addFieldList(this._getCustomerInfoFields());
    }

    _getCustomerInfoFields() {
        return [
            'first_name',
            'last_name',
            'phone'
        ];
    }

    _getOrderItemsField(isList) {
        return new Field('items')
            .addFieldList(this._getOrderItemsFields(isList));
    }

    _getOrderItemsFields(isList) {
        return [
            this._getBaseOrderInfoField(isList),
            'is_wallet',
            ...(!isList ? [
                this._getPaymentInfoField(),
                this._prepareShippingInfo(),
                this._getOrderProductsField(),
                this._getCampaignsField()
            ] : [])
        ];
    }

    _setOrderByIdCompleted(orderId) {
        return new Field('setOrderByIdCompleted')
            .addArgument('id', 'Int!', orderId)
            .addFieldList(this._getOrderByIdSetCompleteStatus());
    }

    _setOrderByIdCancelByUser(orderId){
        return new Field('setOrderByIdCancelByUser')
            .addArgument('id', 'Int!', orderId)
            .addFieldList(this._getOrderByIdSetCompleteStatus());
    }

    _getOrderByIdSetCompleteStatus(){
        return [
            'status',
            'message'
        ];
    }

    _getReorderByOrderId(orderId) {
        return new Field('reorderItems')
            .addArgument('orderNumber', 'String!', orderId)
            .addField(this._getCartField())
            .addField(this._getReorderByOrderIdFields());
    }

    _getReorderByOrderIdFields(){
        return new Field('userInputErrors')
            .addFieldList(this._getUserInputErrorsFieldList());
    }

    _getUserInputErrorsFieldList(){
        return [
            'message',
            'path',
            'code'
        ];
    }

    _getCartField() {
        return new Field('cart')
            .addFieldList(this._getCartFieldList());
    }

    _getCartFieldList() {
        return [
            'id'
        ];
    }
}

export default new OrderQuery();