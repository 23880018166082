import {
    MyAccountAddressTable as SourceMyAccountAddressTable,
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Loader from 'Component/Loader';

import './MyAccountAddressTable.style.override'

export class MyAccountAddressTable extends SourceMyAccountAddressTable {

    static defaultProps = {
        showAdditionalFields: false,
        showActions: false,
        mix: {},
        isPopup : false
    };

    handleAddressClick = () => {
        const { showEditForm, address } = this.props;

        let title =  __('Edit Address')

        showEditForm(__('Edit Address'),address);
    }

    renderActionsIcons() {
        const {
            onDeleteClick,
            address: { default_billing, default_shipping }
        } = this.props;

        const isDeleteAllowed = default_shipping || default_billing;

        return (
            <div block="MyAccountAddressTable" elem="ActionsIcons">
                <button
                  block="Button"
                  mix={ { block: 'MyAccountAddressTable', elem: 'ActionIcon' } }
                  title={ __('Edit address') }
                  onClick={ this.handleAddressClick }
                >
                    <FontAwesomeIcon className="fa-2x" icon={faEdit} />
                </button>
                <button
                  block="Button"
                  mods={ { isHollow: true } }
                  mix={ { block: 'MyAccountAddressTable', elem: 'ActionIcon' }   }
                  onClick={ onDeleteClick }
                  disabled={ isDeleteAllowed }
                  title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : __('Delete this address') }
                >
                    <FontAwesomeIcon className="fa-2x" icon={faTrashAlt} />
                </button>
            </div>
        );
    }

    renderAddressInfo(isCheckout) {
        const { address, getFormatedRegion } = this.props;
        const regionData = getFormatedRegion(address);
        const { firstname, lastname, street, postcode, city, telephone } = address;
        const { country, region } = regionData;

        let name = `${firstname} ${lastname}`;
        /*let firstNameCount = firstname.split(' ').length;
            if(firstNameCount <= 2){
                name = firstname+lastname;
            }*/

        if(isCheckout) {
            return(
                <div block="MyAccountAddressTable" elem="AddressInfo">
                    <strong>{ name }</strong><br/>
                    { street }, { city }<br/>{ postcode }, { country }
                </div>
            );
        }

        return(
            <div block="MyAccountAddressTable" elem="AddressInfo">
                <p><strong>{ name }</strong><br/>
                { street }<br/>{ postcode }, { city }<br/>{ country }</p>
                { this.renderActionsIcons() }
            </div>
        );
    }

    renderTable() {
        const { title, postfix, isPopup } = this.props;

            return (
                <div block="MyAccountAddressTable" elem="AddressWrapper">
                    <div block="MyAccountAddressTable" elem="AddressTitle">
                        { title }
                    </div>
                    { this.renderAddressInfo() }
                    <div block="MyAccountAddressTable" elem="AddressSuffix">
                        { postfix }
                    </div>
                </div>
            );
    }

    renderPopup(){
        const { address, getFormatedRegion } = this.props;
        const regionData = getFormatedRegion(address);
        const { firstname, lastname, street, postcode, city } = address;
        const { country, region } = regionData;
        return (
            <div block="MyAccountAddressTable" elem="AddressWrapper">
                    <strong>{ firstname } - </strong>
                    { street }, { postcode }, { city }
                    <div block="MyAccountAddressTable" elem="ActionIcon">
                    </div>
            </div>
        );
    }

    render() {
        const { countries, mix, isPopup } = this.props;

        if(isPopup){
            return (
                <>
                { this.renderPopup() }
                </>
            );
        }else{
            return (
                <div block="MyAccountAddressTable" mix={ mix }>
                    <Loader isLoading={ !countries.length } />
                    { this.renderTable() }
                </div>
            );
        }
    }

};

export default MyAccountAddressTable;
