// TODO rename action type
export const SAUDA_DETAILS = 'SAUDA_DETAILS';
export const IS_FETCHING = 'IS_FETCHING';
export const SAUDA_CARD_POINTS = 'SAUDA_CARD_POINTS';
export const SAUDA_CARD_MATRIX = 'SAUDA_CARD_MATRIX';
export const UPDATE_SAUDA_CARD_MATRIX = 'UPDATE_SAUDA_CARD_MATRIX';
export const UPDATE_SAUDA_DISCOUNT = 'UPDATE_SAUDA_DISCOUNT';
export const CART_BUY_PRODUCT_WITH_POINTS = 'CART_BUY_PRODUCT_WITH_POINTS';
export const IS_FETCHING_BUY_PRODUCT_WITH_POINTS = 'IS_FETCHING_BUY_PRODUCT_WITH_POINTS';
export const CART_CHANGE_CAMPAIGN = 'CART_CHANGE_CAMPAIGN';
export const IS_FETCHING_CHANGE_CAMPAIGN = 'IS_FETCHING_CHANGE_CAMPAIGN';


/** @namespace Store/CheckoutSauda/Action/getSaudaDetails */
export const getSaudaDetails = (saudaDetails) => ({
    type: SAUDA_DETAILS,
    saudaDetails
});

export const setIsFetching = (isFetching) => ({
    type: IS_FETCHING,
    isFetching
});

export const getCardPoints = (getSaudaCardPoints) => ({
    type: SAUDA_CARD_POINTS,
    getSaudaCardPoints
});

export const getCardMatrix = (getSaudaCardMatrix) => ({
    type: SAUDA_CARD_MATRIX,
    getSaudaCardMatrix
});

export const updateSaudaCardMatrix = matrix => ({
    type: UPDATE_SAUDA_CARD_MATRIX,
    matrix
});

export const updateSaudaDiscount = discount => ({
    type: UPDATE_SAUDA_DISCOUNT,
    discount
});

export const cartBuyProductWithPoints = buyProductWithPoints => ({
    type: CART_BUY_PRODUCT_WITH_POINTS,
    buyProductWithPoints
});

export const isFetchingBuyProductWithPoints = isFetchingBuyProductWithPoints => ({
    type: IS_FETCHING_BUY_PRODUCT_WITH_POINTS,
    isFetchingBuyProductWithPoints
});

export const cartChangeCampaign = cartChangeCampaign => ({
    type: CART_CHANGE_CAMPAIGN,
    cartChangeCampaign
});

export const isFetchingChangeCampaign = isFetchingChangeCampaign => ({
    type: IS_FETCHING_CHANGE_CAMPAIGN,
    isFetchingChangeCampaign
});
