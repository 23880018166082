/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CategoriesDispatcher } from '../../store/Categories';
import LinkedProductsDispatcher from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import { PostsDetailsDispatcher } from '../../store/PostsDetails';
import PostsDetails from './PostsDetails.component';

import { HistoryType } from 'Type/Common';
import { getQueryParam } from 'Util/Url';

import { POSTS_DETAILS } from './PostsDetails.config';
import { DeviceType } from 'Type/Device';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
    );

/** @namespace ScandiPWA/Blog/Route/PostsDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    post: state.PostsDetailsReducer.post,
    customer: state.MyAccountReducer.customer,
    categories: state.CategoriesReducer.categories,
    device: state.ConfigReducer.device
});

/** @namespace ScandiPWA/Blog/Route/PostsDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    requestPosts: (options) => {
        PostsDetailsDispatcher.handleData(dispatch, options);
        LinkedProductsDispatcher.clearLinkedProducts(dispatch);
    },
    requestCategories: (options) => CategoriesDispatcher.handleData(dispatch, options),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    toggleBreadcrumbs: (flag) => dispatch(toggleBreadcrumbs(flag))
});

export class PostsDetailsContainer extends PureComponent {
    static propTypes = {
        requestPosts: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        history: HistoryType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        post: PropTypes.object.isRequired,
        categories: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                handle: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        device: DeviceType.isRequired
    };

    state = {
        isCategoryLoading: false
    }

    componentDidMount() {
        this.options = {
            postOptions: {
                getDescription: true,
                getMedia: true,
                getRelated: true
            }
        };

        this.requestPosts();
        this.getPost();
        this.setHeaderState();
        this.storePathname();
        window.scrollTo({ top: 0 });
    }

    componentDidUpdate() {
        const { categories: { isLoaded }, requestCategories, device, toggleBreadcrumbs } = this.props;
        const { isCategoryLoading } = this.state;

        if (isLoaded === false && isCategoryLoading === false) {
            this.setState({ isCategoryLoading: true });
            requestCategories();
        }

        if (device.isMobile) {
            toggleBreadcrumbs(false);
        } else {
            this.updateBreadcrumbs();
        }

        this.updatePage();
        this.updateMeta();
    }

    setHeaderState() {
        const { setHeaderState, history } = this.props;
        setHeaderState({
            name: POSTS_DETAILS,
            title: 'Blog',
            onBackClick: () => history.goBack()
        });
    }

    updateMeta() {
        const {
            post: {
                title, meta_title, meta_description, meta_keyword
            },
            updateMeta
        } = this.props;

        updateMeta({
            title,
            meta_title,
            meta_description,
            description: meta_description,
            meta_keyword,
            canonical_url: this.getCanonicalUrl() || ''
        });
    }

    /**
     * Get url handle from router
     * @return {void | jsx}
     */
    getUrlParam() {
        const { match: { params: { handle } } } = this.props;
        return handle;
    }

    getPost() {
        const { post } = this.props;
        return post;
    }

    getCategoryFromUrl() {
        return getQueryParam('category', location) || '';
    }

    /**
     * Dispatch breadcrumbs update
     * @return {void}
     */
    updateBreadcrumbs() {
        const { updateBreadcrumbs, post, categories: { items }, history } = this.props;
        const { title } = post;

        if (!title) {
            return;
        }

        let categoryItem = [];
        if (items.length > 0) {
            const categoryIdentifier = history?.location?.state?.category;
            const category =  items.find(({ identifier }) => identifier === categoryIdentifier);

            if (category) {
                categoryItem = [
                    {
                        url: `/blog/categoria/${categoryIdentifier}`,
                        name: category.title
                    }
                ];
            }
        }

        const breadcrumbs = [
            {
                name: title
            },
            ...(categoryItem),
            {
                url: '/blog',
                name: __('Blog')
            },
            {
                url: '/',
                name: __('Home')
            }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    /**
     * Stores the old handle in state so that
     * it can compare it when a handle is changed
     */
    storePathname() {
        this.setState({ oldHandle: this.getUrlParam() });
    }

    /**
     * Gets the canonical url for the specific post
     */
    getCanonicalUrl() {
        const { post: { identifier } } = this.props;

        if (!identifier) {
            return null;
        }

        return `${window.location.origin}/blog/${identifier}`;
    }

    /**
     * Updates the page if handle is changed
     */
    updatePage() {
        const { oldHandle } = this.state;

        if (oldHandle !== this.getUrlParam()) {
            this.requestPosts();
            this.getPost();
            this.setHeaderState();
            this.storePathname();
            window.scrollTo({top: 0});
        }
    }

    requestPosts() {
        const { requestPosts } = this.props;
        const { postOptions } = this.options;

        requestPosts({
            ...postOptions,
            id: this.getUrlParam()
        });
    }

    render() {
        return (
            <PostsDetails
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsDetailsContainer);
