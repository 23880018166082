/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { customerType } from 'Type/Account';
import { COPAYMENT_POPUP_ID } from 'Component/MyAccountPageManager/MyAccountPageManager.config';

import MyAccountCoPaymentsTable from './MyAccountCoPaymentsTable.component';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

/** @namespace Component/MyAccountCoPaymentsTable/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload[COPAYMENT_POPUP_ID] || {}
});

/** @namespace Component/MyAccountCoPaymentsTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    //empty
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
});

/** @namespace Component/MyAccountCoPaymentsTable/Container */
export class MyAccountCoPaymentsTableContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        handleDeleteCoPayment: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            id: PropTypes.number
        }).isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        FormTitle : "CoPayments"
    };

    containerFunctions = {
        showEditForm: this.showEditForm.bind(this),
        deleteItem: this.deleteItem.bind(this)
    };

    state = {
        showHideForm: false,
        isLoading: false
    };

    showEditForm(currentCoPayment = []) {
        const { disableTables , hideTables } = this.props;
        this.setState({
            currentCoPayment: currentCoPayment
        }, () => {
            this.setState({showHideForm: !this.state.showHideForm});
        });
        disableTables(!hideTables);
        window.scrollTo(0,0);
     }

    deleteItem(currentCoPayment = []) {
        const { handleDeleteCoPayment } = this.props;
        handleDeleteCoPayment(currentCoPayment);
    }

    render() {
        return (
            <MyAccountCoPaymentsTable
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCoPaymentsTableContainer);
