import React, { PureComponent } from 'react';
import ContentWrapper from 'Component/ContentWrapper';
import MenuQuery from 'Query/Menu.query';
import CmsPagesQuery from 'Query/CmsPages.query';
import { fetchQuery } from 'Util/Request';
import MenuHelper from 'Util/Menu';
import { getSortedItems } from 'Util/Menu';
import './SiteMap.style.scss';

export class SiteMap extends PureComponent {
    currentLimit;
    state = {
        menu: {},
        cmsPages: {},
        limit: 20
    };

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: header_menu || 'new-main-menu'
        };
    }
    
    _getMenu() {
        const query = MenuQuery.getQuery(this._getMenuOptions());

        fetchQuery(query).then(
            ({menu}) => {
                this.setState({ menu: MenuHelper.reduce(menu)})
            },
            (error) => showErrorNotification('Something went wrong!')
        );
    }

    _getCmsPages(){
        const query = CmsPagesQuery.getCmsPages();

        fetchQuery(query).then(
            ({cms_pages}) => {
                this.setState({ cmsPages: cms_pages})
            },
            (error) => showErrorNotification('Something went wrong!')
        );
    }
    
    componentDidMount() {
        const {updateMeta} = this.props;

        updateMeta({title:'Sitemap'});
        this._getMenu();
        this._getCmsPages();
    }

    renderPage = (item) => {
        const { url, title} = item;

        return (
            <li>
                <a tabindex="0" href={ url }><span > { title } </span></a>
            </li>
        );
    }

    renderCmsPages(){
        const { match: { params: { map: urlStep } } } = this.props;
        const { cmsPages, limit } = this.state;
        const cmsPagesArray = Object.values(cmsPages);
        
        if (!cmsPagesArray.length) {
            return null;
        }

        let toRender;
        if(!urlStep &&  cmsPagesArray[0].length > (limit-2)){
            toRender = cmsPagesArray[0].slice(0,limit-2);
        }else{
            toRender = cmsPagesArray[0];
        }

        return(
            <ul>
                { toRender && toRender.map(this.renderPage) }
                <li><a tabindex="0" href="/localizador-de-farmacias"><span>Localizador de Farmácias</span></a></li>
                <li><a tabindex="0" href="/blog"><span >Saúde de A-Z</span></a></li>
                { !urlStep && cmsPagesArray[0].length > limit && <li block="SiteMap" elem="SeeAll"><a tabindex="0" href="/sitemap/paginas"><span>{__('See All')}</span></a></li>}
            </ul>
        );
    }

    renderChildren = (item) => {
        const { url,title, children } = item;
        const { match: { params: { map: urlStep } } } = this.props;
        const childrenArray = Object.values(children);

        if(!urlStep){
            this.currentLimit = this.currentLimit - 1;
            if(this.currentLimit < 0){
                return null;
            }
        }
        
        return (
            <li>
                <a tabindex="0" href={ url.pathname }><span > { title } </span></a>
                <ul>{ getSortedItems(childrenArray).map(this.renderChildren) }</ul>
            </li>
        );
    };

    renderCategories(){
        const { match: { params: { map: urlStep } } } = this.props;
        const { menu,limit } = this.state;
        const categoryArray = Object.values(menu);

        this.currentLimit = limit;
        if (!categoryArray.length) {
            return null;
        }

        const [{ children }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        return(
            <ul>
                { childrenArray.map(this.renderChildren) }
                { !urlStep && <li block="SiteMap" elem="SeeAll"><a tabindex="0" href="/sitemap/categorias"><span>{__('See All')}</span></a></li>}
            </ul>
        );   
    }

    render(){
        const { match: { params: { map: urlStep } } } = this.props;

        return(
            <main block="SiteMap">
                <ContentWrapper>
                    <h2>Sitemap</h2>
                    <div block="SiteMap" elem="Groups">
                        { (!urlStep || urlStep !== 'categorias') && <div block="SiteMap" elem="Group">
                            <h3>Web Pages</h3>
                            { this.renderCmsPages() }
                        </div>}
                        { (!urlStep || urlStep !== 'paginas') && <div block="SiteMap" elem="Group">
                            <h3>{__('Categories')}</h3>
                            { this.renderCategories() }

                        </div>}    
                    </div>     
                </ContentWrapper>
            </main>
        );
        
    }
}

export default SiteMap;