import {
    ProductActions as SourceProductActions,
} from 'SourceComponent/ProductActions/ProductActions.component';
import iconShare from './images/share@3x.png';
import {Helmet} from "react-helmet";
import {
    BUNDLE,
    CONFIGURABLE,
    GROUPED
} from 'Util/Product';
import Field from 'Component/Field';
import Link from 'Component/Link';
import './ProductActions.style.override'
import ProductPrice from 'Component/ProductPrice';
import isMobile from 'Util/Mobile';
import TextPlaceholder from 'Component/TextPlaceholder';
import Html from 'Component/Html';
import Popup from 'Component/Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLink } from '@fortawesome/pro-light-svg-icons';
import history from 'Util/History';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    CopyShareButton,
    CopyIcon
  } from "react-share";
import { propTypes } from 'react-barcode';
import PropTypes from 'prop-types';
import AddToCart from 'Component/AddToCart';
import  icon_sauda_product  from 'Component/CartItem/images/sauda.png';
import { DeviceType } from 'Type/Device';

export class ProductActions extends SourceProductActions {

    static propTypes = {
        setHasStock: PropTypes.func.isRequired,
        hasStock: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
    }

    state = {
        vouchersForValidation: [],
        origPrice: 0,
        campaignPrice: 0,
        campaignCode: null
    }

    getSelectOptions(items) {
        const copy = [];
        items.forEach(function(item) {
            if(item != undefined) {
                copy.push({'label' : item.name, 'value' : item.value, 'id' : item.id});
            }
        });

        return copy;
    }

    renderQuantityInput() {
        const {
            quantity,
            maxQuantity,
            minQuantity,
            setQuantity,
            product: { type_id },
            hasStock
        } = this.props;


        let itemsLength = 10 + 1;
        const listItems = [];

        for (let i = 1; i < itemsLength; i++) {
            let numString;

            if (i < 10) {
                numString = '0' + i.toString()
             } else {
                numString = i.toString();
            }
            listItems.push({id:i,name:numString,value:i})
        }


        const array_list = this.getSelectOptions(listItems);

        if (type_id === GROUPED) {
            return null;
        }


        return (
            <Field
              id="item_qty"
              name="item_qty"
              type="select"
              selectOptions={ array_list }
              value={ quantity }
              max={ maxQuantity }
              min={ minQuantity }
              mix={ { block: 'ProductActions', elem: 'Qty' } }
              onChange={ setQuantity }
              isDisabled= { !hasStock }
            />
        );
    }
/*
    renderQuantityInput() {
        const {
            quantity,
            maxQuantity,
            minQuantity,
            setQuantity,
            product: { type_id }
        } = this.props;

        if (type_id === GROUPED) {
            return null;
        }

        return (
            <Field
              id="item_qty"
              name="item_qty"
              type="number"
              value={ quantity }
              max={ maxQuantity }
              min={ minQuantity }
              mix={ { block: 'ProductActions', elem: 'Qty' } }
              onChange={ setQuantity }
            />
        );
    }*/

    renderGenericLink() {
        const { productOrVariant, showPopup } = this.props;

        if (!productOrVariant.generics || !productOrVariant.generics.length > 0) {
            return;
        }

        return (
            <div
            block="ProductActions"
            elem="LinkGeneric"
            >
                <a
                block="ProductActions"
                elem="LinkGeneric"
                onClick= { showPopup }
                >{ __("View Generic(s)") }
                </a>
            </div>
        );
    }

    renderProductShareButton() {
        const { baseLinkUrl, showSharePopup, productOrVariant, favStoreObj, showNotification, product: { configurable_options, type_id, attributes: attributesProduct } } = this.props;

        if(!productOrVariant) {
            return;
        }

        const { name, attributes, thumbnail } = productOrVariant;

        let shareUrl = '';
        if (type_id === 'configurable') {
            // Get query params
            let query = '';
            const keys  = Object.keys(configurable_options);
            if (keys.length > 0) {
                query = keys.reduce((a,c) => {
                    if (attributes[c]) {
                        a += (a === '' ? '?' : '&') + attributes[c].attribute_code + '=' + attributes[c].attribute_value;
                    }
                    return a;
                }, '');
                let queryPharmacy = '';
                if(favStoreObj.pharmacy_code){
                    queryPharmacy = '&pharmacy_code='+favStoreObj.pharmacy_code;
                }
                query += queryPharmacy;
            }else{
                let queryPharmacy = '';
                if(favStoreObj.pharmacy_code){
                    queryPharmacy = '?pharmacy_code='+favStoreObj.pharmacy_code;
                }
                query = queryPharmacy;
            }
            shareUrl = attributesProduct ? baseLinkUrl + attributesProduct.url_key.attribute_value + '.html' + query : baseLinkUrl;
        } else {
            let queryPharmacy = '';
            if(favStoreObj.pharmacy_code){
                queryPharmacy = '?pharmacy_code='+favStoreObj.pharmacy_code;
            }
            shareUrl = attributes ? baseLinkUrl + attributes.url_key.attribute_value + '.html' + queryPharmacy : baseLinkUrl;
        }

        const title = name;

        const copySharedUrl = async () => {
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(shareUrl);
                showNotification('info', __('Shared Link Copied'));
            }
            else {
                const textArea = document.createElement("textarea");
                textArea.value=shareUrl;
                document.body.appendChild(textArea);
                textArea.focus();textArea.select();
                try{
                    document.execCommand('copy');
                    showNotification('success', __('Shared Link Copied'));                }
                catch(err){
                    console.error('Unable to copy to clipboard',err)
                }
                    document.body.removeChild(textArea)
              }
          }
        return (
            <>
                <img
                    block="ProductShareButton"
                    src={ iconShare || '' }
                    onClick={ showSharePopup }
                />
                <Popup
                    id={ 'ProductPageShare' }
                    clickOutside={ true }
                    mix={ { block: 'ProductPageGeneric', elem: 'Share' } }
                    closedOn={ false }
                >
                    <FontAwesomeIcon className="ProductPageGeneric-Close" onClick={ showSharePopup } icon={ faTimes } />
                    <div block="ProductActions" elem="ShareSocial">
                        <h3 block="ProductActions" elem="ShareTitle">{ __("Share") }</h3>
                        <div block="ProductActions" elem="ShareNetwork">
                            <EmailShareButton
                                url={shareUrl}
                                quote={title}
                                className="ProductActions-ShareNetworkButton"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                        <div block="ProductActions" elem="ShareNetwork">
                            <FacebookShareButton
                                url={ shareUrl }
                                quote={title}
                                className="ProductActions-ShareNetworkButton"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div block="ProductActions" elem="ShareNetwork">
                            <TwitterShareButton
                                url={shareUrl}
                                quote={title}
                                className="ProductActions-ShareNetworkButton"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                        <div block="ProductActions" elem="ShareNetwork">
                            <WhatsappShareButton
                                url={shareUrl}
                                quote={title}
                                separator=":: "
                                className="ProductActions-ShareNetworkButton"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <div block="ProductActions" elem="ShareNetwork">
                            <LinkedinShareButton
                                url={shareUrl}
                                className="ProductActions-ShareNetworkButton"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div block="ProductActions" elem="ShareNetwork">
                            <PinterestShareButton
                                url={shareUrl}
                                media={thumbnail}
                                className="ProductActions-ShareNetworkButton"
                            >
                                <PinterestIcon size={32} round />
                            </PinterestShareButton>
                        </div>
                        <div block="ProductActions" elem="ShareNetwork">
                            <button
                                url={shareUrl}
                                media={thumbnail}
                                onClick= {copySharedUrl }
                                className="ProductActions-ShareNetworkButton LinkCopy"
                            >   <FontAwesomeIcon icon={faLink} size={32} round />
                            </button>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }

    copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
          return navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
    }

    renderSchema() {
        const {
            productName,
            stockMeta,
            metaLink
        } = this.props;

        return (
            <>
                { this.renderOfferCount() }
                <meta itemProp="availability" content={ stockMeta } />
                <h3>
                    { productName }
                </h3>
            </>
        );
    }

    renderDescription() {
        const {
            product,
            product: { variants },
            configurableVariantIndex,
            showOnlyIfLoaded
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { short_description } = productOrVariant;
        const { html } = short_description || {};

        const htmlWithItemProp = `<div class="description" itemProp="description"><h2>${ html }</h2></div>`;

        return (
            <>
            { short_description ?
                <section
                    block="ProductActions"
                    elem="SectionDesc"
                    mods={ { type: 'bundle_items' } }
                >
                { showOnlyIfLoaded(
                    short_description,
                    (
                        <Html content={ htmlWithItemProp } />
                    ),
                    <TextPlaceholder />
                ) }
                </section> : null
            }
            </>
        );
    }

    renderCapacity() {
        const {
            product,
            product: { variants },
            configurableVariantIndex,
            showOnlyIfLoaded
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { sku, stock_status } = productOrVariant;

        let dosage = this.getDosage();
        let capacity = this.getCapacity();
        let pharmaceuticalForm = this.getPharmaceuticalForm();

        let info = [];

        if (dosage) {
            info.push('<span>' + dosage + '</span>');
        }

        if (!this.isConfigurable() && capacity){
            info.push('<span>' + capacity + '</span>');
        }

        if (pharmaceuticalForm) {
            info.push('<span>' + pharmaceuticalForm + '</span>');
        }

        return (
            <>
                { info.length > 0 ?
                <section block="ProductActions" elem="Capacity" >
                    <Html content={ info.join('<span>|</span>') } />
                </section> : null }
            </>
        );
    }

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    checkCustomerVoucher(code, customerVouchers) {
        let flag = false;
        let vouchersForValidation = [];
        customerVouchers.map((voucher) => {
            if(voucher.reference != code){
                vouchersForValidation.push(voucher);
            }
        });
        this.setState({ vouchersForValidation: vouchersForValidation });
        return true;
    }

    renderContent() {
        const {
            product: { variants },
            configurableVariantIndex,
            device,
            product,
            favStoreObj: { sauda },
            customer: { sauda_card, vouchers },
            isSignedIn,
            showNotification
        } = this.props

        let burnCampaigns = [];
        let burnCampaignsCodes = [];
        let earnCampaigns = [];
        let earnCampaignsCodes = [];
        let allCampaigns = [];
        let allCampaignsHtml = [];
        let allCampaignsCodes = [];

        let maxValue = 0;
        let maxPercentage = 0;
        let campaignToUse = {};

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { price_range } = productOrVariant;

        if (price_range)  {
            const { maximal_price: { final_price: { value } } } = price_range;

            this.setState({
                origPrice: value
            });
        }

        if ((productOrVariant.campaigns) && (productOrVariant.campaigns.length > 0)) {
            let {
                price_range: { maximal_price: { final_price: { value } } }
            } = productOrVariant;
            productOrVariant.campaigns.forEach((campaign) => {

                const startDate = new Date(campaign.start_date);
                const startDateStr = this.addZero(startDate.getDate()) + '/' + this.addZero(startDate.getMonth() + 1) + '/' + startDate.getFullYear();

                const endDate = new Date(campaign.end_date);
                const endDateStr = this.addZero(endDate.getDate()) + '/' + this.addZero(endDate.getMonth() + 1) + '/' + endDate.getFullYear();

                /*if(!allCampaignsCodes.includes(campaign.code)){
                    allCampaignsHtml.push(
                        <li block="ProductActions" elem="SingleCampaign">
                            <p>{ campaign.description }</p>
                            <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
                        </li>
                    );
                    allCampaigns.push(campaign);
                    allCampaignsCodes.push(campaign.code);
                }*/

                /*if (campaign.burn_value_type != '' || campaign.burn_value != '') {
                    if (vouchers && this.checkCustomerVoucher(campaign.code, vouchers)) {
                        if(!burnCampaignsCodes.includes(campaign.code)){
                            burnCampaigns.push(
                                <li block="ProductActions" elem="SingleCampaign">
                                    <p>{ campaign.description }</p>
                                    <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
                                </li>
                            );
                            burnCampaignsCodes.push(campaign.code);
                        }
                    }
                } else {
                    if(!earnCampaignsCodes.includes(campaign.code)){
                        earnCampaigns.push(
                            <li block="ProductActions" elem="SingleCampaign">
                                <p>{ campaign.description }</p>
                                <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
                            </li>
                        );
                        earnCampaignsCodes.push(campaign.code);
                    }
                }*/
                if((campaign.burn_value_type == null || campaign.burn_value == null) && !earnCampaignsCodes.includes(campaign.code)){
                    if(!campaign.pharmacy_codes){
                        const onlineExclusive = campaign.is_online_exclusive ? 'Campanha Exclusiva Online ' : '';
                        earnCampaigns.push(
                            <li block="ProductActions" elem="SingleCampaign">
                                <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
                                <p block="ProductActions" elem="CampaignDescription">{ onlineExclusive + campaign.description }</p>
                            </li>
                        );
                        earnCampaignsCodes.push(campaign.code);
                    }
                } else {
                    if(campaign.promotional_code_type_value == 'Cash'){
                        if(parseInt(campaign.promotional_code_value) > maxValue){
                            maxValue = parseInt(campaign.promotional_code_value);
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                            this.setState({
                                origPrice: value,
                                campaignPrice: value - maxValue,
                                campaignCode: campaign.code
                            });
                        }
                    } else {
                        let price = value * (parseInt(campaign.promotional_code_value) / 100);
                        if(price > maxValue){
                            maxValue = price;
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                            this.setState({
                                origPrice: value,
                                campaignPrice: value - maxValue,
                                campaignCode: campaign.code
                            });
                        }
                    }
                }
            });
        } /*else {

            if (product && product.variants && product.variants.length > 0) {
                product.variants.map((variant) => {
                    if(variant.price_range && variant.price_range.maximal_price){
                        let value = variant.price_range.maximal_price.final_price.value;

                        if ((variant.campaigns) && (variant.campaigns.length > 0)) {
                            variant.campaigns.map((campaign) => {

                                const startDate = new Date(campaign.start_date);
                                const startDateStr = this.addZero(startDate.getDate()) + '-' + this.addZero(startDate.getMonth() + 1) + '-' + startDate.getFullYear();

                                const endDate = new Date(campaign.end_date);
                                const endDateStr = this.addZero(endDate.getDate()) + '-' + this.addZero(endDate.getMonth() + 1) + '-' + endDate.getFullYear();

                                /*if(!allCampaignsCodes.includes(campaign.code)){
                                    allCampaignsHtml.push(
                                        <li block="ProductActions" elem="SingleCampaign">
                                            <p>{ campaign.description }</p>
                                            <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
                                        </li>
                                    );
                                    allCampaignsCodes.push(campaign.code);
                                    allCampaigns.push(campaign);
                                }*/

                                /*if (campaign.burn_value_type != '' || campaign.burn_value != '') {
                                    if (vouchers && this.checkCustomerVoucher(campaign.code, vouchers)) {
                                        if(!burnCampaignsCodes.includes(campaign.code)){
                                            burnCampaigns.push(
                                                <li block="ProductActions" elem="SingleCampaign">
                                                    <p>{ campaign.description }</p>
                                                    <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
                                                </li>
                                            );
                                            burnCampaignsCodes.push(campaign.code);
                                        }
                                    }
                                } else {
                                    if(!earnCampaignsCodes.includes(campaign.code)){
                                        earnCampaigns.push(
                                            <li block="ProductActions" elem="SingleCampaign">
                                                <p>{ campaign.description }</p>
                                                <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
                                            </li>
                                        );
                                        earnCampaignsCodes.push(campaign.code);
                                    }
                                }*//*
                                if((campaign.burn_value_type == null || campaign.burn_value == null) && !earnCampaignsCodes.includes(campaign.code)){
                                    earnCampaigns.push(
                                        <li block="ProductActions" elem="SingleCampaign">
                                            <p>{ campaign.description }</p>
                                            <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
                                        </li>
                                    );
                                    earnCampaignsCodes.push(campaign.code);
                                } else {
                                    if(campaign.promotional_code_type_value == 'Cash'){
                                        if(campaign.promotional_code_value > maxValue){
                                            maxValue = campaign.promotional_code_value;
                                            campaignToUse = campaign;
                                            allCampaigns.push(campaign);
                                        }
                                    } else {
                                        let price = value * (campaign.promotional_code_value / 100);
                                        if(price > maxValue){
                                            maxValue = price;
                                            campaignToUse = campaign;
                                            allCampaigns.push(campaign);
                                        }
                                    }
                                    this.setState({
                                        origPrice: value,
                                        campaignPrice: value - maxValue,
                                        campaignCode: campaign.code
                                    });
                                }
                            });
                        }
                    }
                });
            }
        }*/

        let linkInfarmed = '';
        const hasIndicationDismissal = this.hasIndicationDismissal(product);
        if (hasIndicationDismissal) {
            linkInfarmed = <a href="https://extranet.infarmed.pt/INFOMED-fo/" target="_blank">{ __("Information leaflet.") }</a>
        }

        let hasCard = sauda_card && sauda_card.card_number !== null;
        let saudaLinkLabel = '';
        let saudaLink = ''
        if (sauda) {
            saudaLinkLabel =__("Only applicable to customers with a Health Card.")

            if (!hasCard) {
                if (isSignedIn) {
                    saudaLink = (<Link to="/my-account/healthcard">{ __("Join here.") }</Link>)
                } else {
                    saudaLink = (<a onClick={ () => showNotification('info', __('You must be logged in to joining the Saúda Card')) } >{ __("Join here.") }</a>)
                }
            }
        }

        return (
            <>
            { allCampaigns.length > 0 || earnCampaigns.length > 0 ||
            (this.getSaudaVerify() == "1" && this.getPoints() && sauda) ?
            <section
              block="ProductActions"
              elem="SectionHealth"
              mods={ { type: 'bundle_items' } }
            >
                <strong>{ __('Product with exclusive benefit(s) for Sauda Card holders.') }</strong><br/>
                { /* saudaLinkLabel */ }
                { saudaLink }
                { /*(sauda && hasCard) && this.renderBurnSection(burnCampaigns) */}
                { sauda && allCampaigns.length > 0 && this.renderCampaignsSection(campaignToUse) }
                { (sauda && hasCard) && this.renderEarnSection(earnCampaigns) }
            </section>: null }
            {hasIndicationDismissal && <section
                block="ProductActions"
                elem="SectionContent"
                mods={ { type: 'bundle_items' } }
                >
                    { __("read the package leaflet carefully and in case of doubt or persistence of symptoms consult your doctor or pharmacist.") }
                    { linkInfarmed }
                </section>}
            </>
        );
    }

    hasIndicationDismissal(product) {
        const { attributes, custom_attributes } = product;

        if (attributes && attributes.indication_of_dismissal) {
            return attributes.indication_of_dismissal.attribute_value !== '';
        }

        if (custom_attributes) {
            let indicationDismissal = custom_attributes.find(({ attribute_code }) => attribute_code === 'indication_of_dismissal');

            if (indicationDismissal)  {
                return indicationDismissal.attribute_values !== '';
            }
        }

        return false;
    }

    renderBurnSection(burnCampaigns) {
        if (burnCampaigns.length > 0) {
            return (
                <ul>{ burnCampaigns }</ul>
            );
        }
    }
    renderEarnSection(earnCampaigns) {
        if (earnCampaigns.length > 0) {
            return (
                <ul>{ earnCampaigns }</ul>
            );
        }
    }
    renderCampaignsSection(campaignToUse){
        const startDate = new Date(campaignToUse.start_date);
        const startDateStr = this.addZero(startDate.getDate()) + '/' + this.addZero(startDate.getMonth() + 1) + '/' + startDate.getFullYear();

        const endDate = new Date(campaignToUse.end_date);
        const endDateStr = this.addZero(endDate.getDate()) + '/' + this.addZero(endDate.getMonth() + 1) + '/' + endDate.getFullYear();

        const onlineExclusive = campaignToUse.is_online_exclusive ? 'Campanha Exclusiva Online ': '';
        return (
            <li block="ProductActions" elem="SingleCampaign">
                <p>{ onlineExclusive + campaignToUse.description }</p>
                <p>{ __('Promotion valid between %s and %s', startDateStr, endDateStr) }</p>
            </li>
        );
    }

    getIsConfigurableAttributeAvailable({ attribute_code, attribute_value }) {
        const { parameters, product: { variants } } = this.props;

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

        // If value matches current attribute_value, option should be enabled
        if (isAttributeSelected && parameters[attribute_code] === attribute_value) {
            return true;
        }

        const parameterPairs = Object.entries(parameters);

        const selectedAttributes = isAttributeSelected
            // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
            ? parameterPairs.filter(([key]) => key !== attribute_code)
            : parameterPairs;

        return variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return (
                    stock_status === 'IN_STOCK'
                    // Variant must have currently checked attribute_code and attribute_value
                    && foundValue === attribute_value
                    // Variant must have all currently selected attributes
                    && selectedAttributes.every(([key, value]) => attributes[key].attribute_value === value)
                );
            });
    }

    isConfigurable() {
      const {  product: { type_id } } = this.props;

        if (type_id !== CONFIGURABLE) {
            return false;
        } else {
            return true;
        }

    }

    getPoints() {
        const { product, productOrVariant } = this.props;
        let points = null;

        try {
            points = productOrVariant.attributes.points.attribute_value;
        } catch(err) {
            return null;
        }

        return points;

    }

    getSaudaVerify() {
        const { product, productOrVariant } = this.props;
        let sauda = null;

        try {
            sauda = productOrVariant.attributes.has_points.attribute_value;
        } catch(err) {
            return null;
        }

        return sauda;
    }

    getDosage() {
        const { product } = this.props;
        let dosage = null;

        try {
            dosage = product.attributes.dosage.attribute_value;
        } catch(err) {
            return null;
        }

        return dosage;
    }

    getCapacity() {
        const { product } = this.props;
        let capacity = null;
        let magentoAttributes = product.custom_attributes;

        try {
            if(magentoAttributes) {
                magentoAttributes.forEach(attribute_item => {
                    if( attribute_item.attribute_code == "capacity") {
                        capacity = attribute_item.attribute_values;
                    }
                });
            }
        } catch(err) {
            return null;
        }

        return capacity;
    }

    getPharmaceuticalForm() {
        const { product } = this.props;
        let capacity = null;
        let magentoAttributes = product.custom_attributes;

        try {
            if(magentoAttributes) {
                magentoAttributes.forEach(attribute_item => {
                    if( attribute_item.attribute_code == "pharmaceutical_form") {
                        capacity = attribute_item.attribute_values;
                    }
                });
            }
        } catch(err) {
            return null;
        }

        return capacity;
    }

    renderNameAndBrand() {
        const {
            product:
                {
                    name,
                    attributes: { brand: { attribute_value: brand } = {} } = {}
                },
            showOnlyIfLoaded
        } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'name' } }
            >
                <h2 block="ProductActions" elem="Title" itemProp="name">
                    <TextPlaceholder content={ name } length="medium" />
                </h2>
            </section>
        );
    }

    renderSkuAndStock() {
        const {
            product,
            product: { variants },
            configurableVariantIndex,
            showOnlyIfLoaded
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { attributes = {} , sku, stock_status } = productOrVariant;
        const { cnpem } = attributes;

        if(this.isConfigurable() && configurableVariantIndex < 0) {
            return (<></>);
        }

        if(sku) {
            return (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'sku' } }
                  aria-label="Product SKU and availability"
                >
                    <span block="ProductActions" elem="Sku">
                        CNP:
                    </span>
                    <span block="ProductActions" elem="Sku" itemProp="sku">
                        { `${ sku }` }
                    </span>
                </section>
            );
        }

        return;
    }

    renderConfigurablePriceBadge() {
        const {
            configurableVariantIndex,
            product: { type_id },
            customer: { favourite_pharmacy_code }
        } = this.props;

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            if (
                type_id !== CONFIGURABLE
                || configurableVariantIndex > -1
            ) {
                return null;
            }

            return (
                <p
                mix={ {
                    block: 'ProductActions',
                    elem: 'ConfigurablePriceBadge'
                } }
                >
                    { __('As Low as') }
                </p>
            );
        }
    }

    renderPriceWithSchema() {
        const {
            productPrice,
            offerCount,
            getIsConfigurableAttributeAvailable,
            isSauda,
            product,
            product: { type_id, variants },
            customer,
            configurableVariantIndex,
            showNotification
        } = this.props;

        let isSaudaFlag = isSauda();

        let isMultiplePrices = true;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        if(isMobile.any()) {
            return (
                <div
                block="ProductActions"
                elem="PriceWrapper"
                >
                    { this.renderConfigurablePriceBadge() }
                    { this.renderSchema() }
                </div>
            );
        } else {
            if(this.isConfigurable()){

                let final_price_max = productPrice.maximal_price.final_price.value;
                let final_price_min = productPrice.minimum_price.final_price.value;
                let points = 0;

                if (isSaudaFlag && this.getSaudaVerify()=="1") {
                    points = this.getPoints();
                }

                if(final_price_max == final_price_min) {
                    isMultiplePrices = false;
                }

                if(type_id === 'configurable' && configurableVariantIndex === -1) {
                    return (<></>);
                    /*return (
                        <div
                        block="ProductActions"
                        elem="PriceWrapper"
                        >
                            { this.renderConfigurablePriceBadge() }
                            { this.renderSchema() }
                            <div
                                block="ProductActions"
                                elem="ItemPrices"
                                >
                            <ProductPrice
                                points = { points }
                                price_type= { "min" }
                                isSchemaRequired
                                variantsCount={ offerCount }
                                price={ productPrice }
                                mix={ { block: 'ProductActions', elem: 'Price' } }
                            />
                            <p
                                block="ProductPrice"
                                >
                                    <span>-</span>
                                    <span>-</span>
                                    <span>-</span>
                                </p>
                            <ProductPrice
                                points = { points }
                                price_type= { "max" }
                                isSchemaRequired
                                variantsCount={ offerCount }
                                price={ productPrice }
                                mix={ { block: 'ProductActions', elem: 'Price' } }
                            />
                            </div>
                        </div>
                    );*/
                } else {
                    return (
                        <div
                        block="ProductActions"
                        elem="PriceWrapper"
                        >
                            { this.renderConfigurablePriceBadge() }
                            { this.renderSchema() }
                            <ProductPrice
                                points = { points }
                                isSchemaRequired
                                variantsCount={ offerCount }
                                price={ productPrice }
                                mix={ { block: 'ProductActions', elem: 'Price' } }
                                customer={ customer }
                                product={ productOrVariant }
                                showNotification={ showNotification }
                            />
                        </div>
                    );
                }
            } else {
                let points = 0;

                if (isSaudaFlag && this.getSaudaVerify()=="1") {
                    points = this.getPoints();
                }

                return (
                    <div
                      block="ProductActions"
                      elem="PriceWrapper"
                    >
                        { this.renderConfigurablePriceBadge() }
                        { this.renderSchema() }
                        <ProductPrice
                          points = { points }
                          isSchemaRequired
                          variantsCount={ offerCount }
                          price={ productPrice }
                          mix={ { block: 'ProductActions', elem: 'Price' } }
                          customer={ customer }
                          product={ productOrVariant }
                          showNotification={ showNotification }
                        />
                    </div>
                );
            }
        }
    }

    renderStockValue() {
        const { isProductOutStock, setHasStock } = this.props;

        let stockFlag = isProductOutStock();
        setHasStock(true);

        if (stockFlag) {
            setHasStock(false);

            return (
                <div block="ProductActions" elem="Stock" mods={ { Soldoff:true } }>
                    <span>{ __("Sold off") }</span>
                </div>
            );
        }
    }

    showPharacySelectorPopup = () => {
        const { requestStores, showPharacySelectorPopup} = this.props;
        localStorage.setItem('fromWebsite',false)
        localStorage.setItem('showSelectorPopup',true)
        requestStores();
        showPharacySelectorPopup();
    }

    renderAddToCart() {
        const {
            configurableVariantIndex,
            product,
            quantity,
            groupedProductQuantity,
            onProductValidationError,
            productOptionsData,
            hasStock,
            customer: { favourite_pharmacy_code }
        } = this.props;

        const { campaignPrice, campaignCode, origPrice } = this.state;

        if(favourite_pharmacy_code || localStorage.getItem('guest_pharmacy_code')) {
            return (
                <AddToCart
                  product={ product }
                  configurableVariantIndex={ configurableVariantIndex }
                  mix={ { block: 'ProductActions', elem: 'AddToCart' } }
                  quantity={ quantity }
                  groupedProductQuantity={ groupedProductQuantity }
                  onProductValidationError={ onProductValidationError }
                  productOptionsData={ productOptionsData }
                  hasStock={ hasStock }
                  priceWithDiscount={ campaignPrice }
                  campaignCode={ campaignCode }
                  origPrice={ origPrice }
                />
            );
        }else{
            return (
                <button
                    block="ProductActions"
                    elem="ShowPrice"
                    mix={ { block: 'Button' } }
                    onClick={ this.showPharacySelectorPopup }
                >Ver Preço</button>
            );
        }
    }

    render() {
        const {
            mobileComponent,
            productPrice,
            offerCount,
            isSauda,
            customer,
            product,
            product: { variants,type_id, attributes: attributesProduct },
            configurableVariantIndex,
            showNotification,
            baseLinkUrl
        } = this.props;
        const isSaudaFlag = isSauda();

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { name, attributes, thumbnail} = productOrVariant;

        let shareUrl = '';
        if (type_id === 'configurable') {
            shareUrl = attributesProduct ? baseLinkUrl + attributesProduct.url_key.attribute_value + '.html' + query : baseLinkUrl;
        } else {
            shareUrl = attributes ? baseLinkUrl + attributes.url_key.attribute_value + '.html' : baseLinkUrl;
        }

        if(mobileComponent){
            let points = 0;

            if (isSaudaFlag && this.getSaudaVerify()=="1") {
                points = this.getPoints();
            }

            return (
                <article block="ProductActions">
                    <Helmet>
                        <meta property="og:url"           content={shareUrl} />
                        <meta property="og:type"          content="product" />
                        <meta property="og:title"         content={name}/>
                        <meta property="og:image"         content={thumbnail !== undefined ? thumbnail.url : ''} />
                    </Helmet>
                    <div block="ProductHeader">
                    <div>
                        { this.renderPriceWithGlobalSchema() }
                    <div
                    block="ProductActions"
                    elem="AddToCartWrapper"
                    mix={ { block: 'FixedElement', elem: 'Bottom' } }
                    >
                        { this.renderQuantityInput() }
                        { this.renderAddToCart() }
                    </div>
                        { this.renderReviews() }
                    </div>
                    <div
                        block="ProductActions"
                        elem="Wishlist"
                    >
                    { this.renderNameAndBrand() }
                    { this.renderProductWishlistButton() }
                    { this.renderProductShareButton() }
                    </div>
                    </div>
                    <div
                     block="ProductActions"
                     elem="SubContentMobile"
                    >
                        <div
                            block="ProductActions"
                            elem="SkuElements"
                        >
                            { this.renderCapacity() }
                            { this.renderSkuAndStock() }
                        </div>
                        <div
                            block="ProductActions"
                            elem="MobilePrice"
                        >
                            { this.renderConfigurableAttributes() }
                            { this.renderCustomizableOptions() }
                            { this.renderBundleItems() }
                            { this.renderGroupedItems() }
                            { this.renderContent() }
                            { this.renderTierPrices() }
                            { this.renderStockValue() }
                            <ProductPrice
                                points = { points }
                                isSchemaRequired
                                variantsCount={ offerCount }
                                price={ productPrice }
                                mix={ { block: 'ProductActions', elem: 'Price' } }
                                customer={ customer }
                                product={ productOrVariant }
                                showNotification={ showNotification }
                            />
                        </div>
                    </div>
                </article>
            );
        } else {
            if(isMobile.any()) {
                return (
                    <article block="ProductActions_Mobile">
                        <Helmet>
                            <meta property="og:url"           content={shareUrl} />
                            <meta property="og:type"          content="product" />
                            <meta property="og:title"         content={name}/>
                            <meta property="og:image"         content={thumbnail !== undefined ? thumbnail.url : ''} />
                        </Helmet>
                        { this.renderConfigurableAttributes() }
                        { this.renderCustomizableOptions() }
                        { this.renderBundleItems() }
                        { this.renderDescription() }
                        { this.renderGroupedItems() }
                        { this.renderContent() }
                        { this.renderTierPrices() }
                        { this.renderGenericLink() }
                    </article>
                );
            }else{
                return (
                    <article block="ProductActions">
                        <Helmet>
                            <meta property="og:url"           content={shareUrl} />
                            <meta property="og:type"          content="product" />
                            <meta property="og:title"         content={name}/>
                            <meta property="og:image"         content={thumbnail !== undefined ? thumbnail.url : ''} />
                        </Helmet>
                            { this.renderPriceWithGlobalSchema() }
                        <div
                        block="ProductActions"
                        elem="AddToCartWrapper"
                        mix={ { block: 'FixedElement', elem: 'Bottom' } }
                        >
                            { this.renderQuantityInput() }
                            { this.renderAddToCart() }
                        </div>
                        <div
                            block="ProductActions"
                            elem="Wishlist"
                        >
                        { this.renderNameAndBrand() }
                        { this.renderProductWishlistButton() }
                        { this.renderProductShareButton() }
                        </div>
                        { this.renderCapacity() }
                        { this.renderSkuAndStock() }
                        { this.renderConfigurableAttributes() }
                        { this.renderCustomizableOptions() }
                        { this.renderStockValue() }
                        { this.renderBundleItems() }
                        { this.renderDescription() }
                        { this.renderGroupedItems() }
                        { this.renderContent() }
                        { this.renderTierPrices() }
                        { this.renderGenericLink() }
                    </article>
                );
            }
        }
    }
};

export default ProductActions;
