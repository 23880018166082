/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Store Finder Query
 * @class StoreFinderQuery
 * @namespace Query/StoreFinderQuery
 */
export class StoreFinderQuery {
    /**
     * get Stores query
     * @return {Query} Store finder query
     * @memberof StoreFinderQuery
     */
    getQuery() {
        /*
        return new Field('getStores')
            .addFieldList([
                'store_name',
                'address',
                'city',
                'phone_number',
                'latitude',
                'longitude',
                'store_hours',
                'working_days'
            ]);
            */
            return new Field('getPharmacies')
                .addFieldList([
                    'entity_id',
                    'pharmacy_code',
                    'sauda',
                    'home_delivery',
                    'name',
                    'email',
                    'longitude',
                    'latitude',
                    'street_name',
                    'number',
                    'locality',
                    'postal_code_zone',
                    'postal_code_sufix',
                    'postal_code_locality',
                    'postal_code_region',
                    'telephone',
                    'telephone_secondary',
                    'status',
                    'schedule',
                    'schedule_values',
                    'on_call',
                    'shifts',
                    'online_store_active',
                    'store_url',
                    'service_message',
                    'home_delivery',
                    'free_shipping_value',
                    this._getServicesField()
                ]);
    }

    getServicesQuery() {
        return new Field('getServices')
            .addFieldList(this._getServicesFields());
    }

    getStoreOnline() {
        return new Field('getPharmaciesOnline')
                .addFieldList([
                    'entity_id',
                    'pharmacy_code',
                    'sauda',
                    'home_delivery',
                    'name',
                    'email',
                    'longitude',
                    'latitude',
                    'street_name',
                    'number',
                    'locality',
                    'postal_code_zone',
                    'postal_code_sufix',
                    'postal_code_locality',
                    'postal_code_region',
                    'telephone',
                    'telephone_secondary',
                    'status',
                    'schedule',
                    'schedule_values',
                    'on_call',
                    'shifts',
                    'online_store_active',
                    'store_url',
                    'service_message',
                    'home_delivery',
                    'free_shipping_value',
                    this._getServicesField()
                ]);
    }

    _getServicesField() {
        return new Field('services')
            .addFieldList(this._getServicesFields());
    }

    _getServicesFields() {
        return [
            'entity_id',
            'code',
            'name',
            'active',
            'show_in_services_page',
            'assoc_cms_block'
        ]
    }
}

export default new (StoreFinderQuery)();
