/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import React, { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';

import BlogRelatedPosts from '../../component/BlogRelatedPosts';
import BlogRelatedProducts from '../../component/BlogRelatedProducts';
import { convertDateFormat } from '../../util/Date';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
  } from "react-share";

import './PostsDetails.style';
import NoMatch from 'Route/NoMatch';

/**
 * @class PostsDetails
 * @namespace ScandiPWA/Blog/Route/PostsDetails/Component
 */
export class PostsDetails extends PureComponent {
    renderTitle() {
        const { post: { title } } = this.props;

        return (
            <h3 mix={ { block: 'PostsDetails', elem: 'Title' } }>
                <TextPlaceholder length="medium" content={ title } />
            </h3>
        );
    }

    /**
     * Renders the featured image
     * @returns {*}
     */
    renderImage() {
        const { post: { first_image, post_id } } = this.props;

        if (!first_image && post_id) {
            return null;
        }

        return (
            <Image
              block="PostsListing"
              elem="FeaturedImage"
              src={ first_image }
              ratio="16x9"
              isPlaceholder={ !first_image }
            />
        );
    }

    renderPublishDate() {
        const { post: { publish_time } } = this.props;

        if (!publish_time) {
            return null;
        }

        return (
            <div mix={ { block: 'PostsDetails', elem: 'Date' } }>
                <TextPlaceholder
                  mix={ { block: 'BlogRecentPosts', elem: 'DatePlaceholder' } }
                  content={ convertDateFormat(publish_time) }
                />
            </div>
        );
    }

    renderAuthorName() {
        const { post: { author } } = this.props;

        if (!author) {
            return null;
        }

        return (
            <div mix={ { block: 'PostsDetails', elem: 'Author' } }>
                { author.name }
            </div>
        );
    }

    renderContent() {
        const { post: { content } } = this.props;

        if (!content) {
            return (
                <TextPlaceholder
                  mix={ { block: 'PostsDetails', elem: 'ContentPlaceholder' } }
                  length="custom"
                />
            );
        }

        return (
            <div mix={ { block: 'PostsDetails', elem: 'Content' } }>
                <Html content={ content } />
            </div>

        );
    }

    renderSocialShare() {
        const {
            post: {
                title,
                first_image
            },
            post
        } = this.props;
        let shareUrl = window.location.href;

        return (
            <div block="PostsDetails" elem="ShareSocial">
                <div block="PostsDetails" elem="ShareNetwork">
                    <EmailShareButton
                        url={shareUrl}
                        quote={title}
                        className="PostsDetails-ShareNetworkButton"
                    >
                        <EmailIcon size={32} round />
                    </EmailShareButton>
                </div>
                <div block="PostsDetails" elem="ShareNetwork">
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className="PostsDetails-ShareNetworkButton"
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </div>
                <div block="PostsDetails" elem="ShareNetwork">
                    <TwitterShareButton
                        url={shareUrl}
                        quote={title}
                        className="PostsDetails-ShareNetworkButton"
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </div>
                <div block="PostsDetails" elem="ShareNetwork">
                    <WhatsappShareButton
                        url={shareUrl}
                        quote={title}
                        separator=":: "
                        className="PostsDetails-ShareNetworkButton"
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </div>
                <div block="PostsDetails" elem="ShareNetwork">
                    <LinkedinShareButton
                        url={shareUrl}
                        className="PostsDetails-ShareNetworkButton"
                    >
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div>
                <div block="PostsDetails" elem="ShareNetwork">
                    <PinterestShareButton
                        url={shareUrl}
                        media={first_image}
                        className="PostsDetails-ShareNetworkButton"
                    >
                        <PinterestIcon size={32} round />
                    </PinterestShareButton>
                </div>
            </div>
        );
    }

    render() {
        if(this.props.post.post_id === -1){
            return (<NoMatch/>);
        }

        const { customer } = this.props;
        return (
            <main block="PostsDetails">
                <ContentWrapper
                  wrapperMix={ { block: 'PostsDetails', elem: 'Wrapper' } }
                  label="Post"
                >
                    { /* this.renderTitle() */ }
                    {/* { this.renderImage() } */}
                    {/* { this.renderPublishDate() }
                    { this.renderAuthorName() } */}
                    { this.renderContent() }
                    { this.renderSocialShare() }
                    <BlogRelatedProducts customer={customer}/>
                    <BlogRelatedPosts />
                </ContentWrapper>
            </main>
        );
    }
}

export default PostsDetails;
