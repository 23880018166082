import { Field } from 'Util/Query';

export class CheckoutSauda {
    getQuery() {
        // TODO implement query

        return new Field('getSaudaCardDetails')
            .addFieldList([
                'clientNumber',
                'cardNumber'
            ]);
    }

    getSaudaCardDetails(){
        return [
            'clientNumber',
            'cardNumber'
        ];
    }

    getSaudaCardPoints(){
        return new Field('getSaudaCardPoints')
            .addFieldList([
                'name',
                'clientNumber',
                'cardNumber',
                'points',
                'transactionCode',
                'pointsBalance',
                'pointsToPrescribe',
                'pointsToPrescribeNextMonth',
                'totalValueFolded',
                'valueFoldedStartDate',
                this._getSaudaVouchersFields()
            ]);
    }

    _getSaudaVouchersFields() {
        return new Field('vouchers')
            .addFieldList(this._getSaudaVouchersField());
    }

    _getSaudaVouchersField() {
        return [
            'startDate',
            this._getVouchersBurnConditionsFields(),
            'code',
            'creationDate',
            this._getVouchersDetailedDescriptionFields(),
            'endDate',
            'expiryAlert',
            'imageId',
            'maxBenefitValue',
            'paymentType',
            'reference',
            'singleUseVoucher',
            'status',
            'type',
            'usedDate',
            'value',
            'valueType',
            'in_quote',
            'is_locked',
            'in_order',
            'is_online'
        ];
    }

    _getVouchersBurnConditionsFields() {
        return new Field('burnConditions')
            .addFieldList(this._getVouchersBurnConditionsField());
    }
    _getVouchersBurnConditionsField() {
        return [
            this._getSaudaExclusivePharmaciesFields(),
            'isPharmacyExclusive',
            this._getSaudaProductsFields(),
            'redemptionChannel'
        ];
    }

    _getSaudaExclusivePharmaciesFields() {
        return new Field('exclusivePharmacies')
            .addFieldList(this._getSaudaExclusivePharmaciesField());
    }
    _getSaudaExclusivePharmaciesField() {
        return [
            'code',
            'name'
        ];
    }

    _getSaudaProductsFields() {
        return new Field('products')
            .addFieldList(this._getSaudaProductsField());
    }
    _getSaudaProductsField() {
        return [
            'code',
            'description',
            'points',
            'pointsIncrease',
            'prescriptionCode',
            'price',
            'reference',
            'type'
        ];
    }

    _getVouchersDetailedDescriptionFields() {
        return new Field('detailedDescription')
            .addFieldList(this._getVouchersDetailedDescriptionField());
    }
    _getVouchersDetailedDescriptionField() {
        return [
            'description',
            'sponsor',
            'title',
            'usageMessage'
        ];
    }

    getSaudaCardMatrix() {
        return new Field('getSaudaCardMatrix')
            .addFieldList(this._getSaudaCardMatrixFields());
    }

    _getSaudaCardMatrixField() {
        return new Field('matrix')
            .addFieldList(this._getSaudaCardMatrixFields());
    }

    _getSaudaCardMatrixFields() {
        return [
            'description',
            'points',
            'reference',
            'type',
            'value'
        ]
    }

    updateSaudaCardMutation(discount, ref, points) {
        return new Field('discount')
        .addArgument('discount', 'Int!', discount)
        .addArgument('ref', 'String!', ref)
        .addArgument('points', 'String!', points);
    }

    updateProductWithPoints(productId, points) {
        return new Field('buyProductWithPoints')
        .addArgument('sku', 'String!', productId)
        .addArgument('points', 'String!', points);
    }

    applyExclusiveVoucher(voucher) {
        return new Field('applyExclusiveVoucher')
        .addArgument('input', 'SaudaClientVouchersInput!', voucher)
        .addField('message')
        .addField('status');
    }

    applyAllVouchers() {
        return new Field('applyAllVouchers')
        .addField('message')
        .addField('status');
    }

}

export default new CheckoutSauda();
