import {
    OPTION_TYPE_COLOR as SourceOPTION_TYPE_COLOR,
    OPTION_TYPE_TEXT as SourceOPTION_TYPE_TEXT,
    validOptionTypes as SourceValidOptionTypes,
    IN_STOCK as SourceIN_STOCK,
} from 'SourceComponent/ProductCard/ProductCard.config';

//TODO: implement OPTION_TYPE_COLOR
export const OPTION_TYPE_COLOR = SourceOPTION_TYPE_COLOR;

//TODO: implement OPTION_TYPE_TEXT
export const OPTION_TYPE_TEXT = SourceOPTION_TYPE_TEXT;

//TODO: implement validOptionTypes
export const validOptionTypes = SourceValidOptionTypes;

//TODO: implement IN_STOCK
export const IN_STOCK = SourceIN_STOCK;
