import {
    Field as SourceField,
} from 'SourceComponent/Field/Field.component';

import FieldInput from 'Component/FieldInput';
import FieldSelect from 'Component/FieldSelect';

import {
    CHECKBOX_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    DATE_TYPE,
    TEXTAREA_TYPE,
    FILE_TYPE,
    BASE64_TYPE,
    PHONE_TYPE,
    NUMERIC_TYPE,
    POST_CODE
} from './Field.config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faLock } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import './Field.style.override'

export class Field extends SourceField {

    state = {
        passVisible: false
    };

    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case DATE_TYPE:
            return this.renderTypeDate();
        case FILE_TYPE:
            return this.renderTypeFile();
        case BASE64_TYPE:
            return this.renderTypeBase64();
        case PHONE_TYPE:
            return this.renderTypePhone();
        case NUMERIC_TYPE:
            return this.renderTypeNumeric();
        case POST_CODE:
            return this.renderTypePostcode();
        default:
            return this.renderTypeText();
        }
    }

    togglePassword = () => {
        this.setState({ passVisible: !this.state.passVisible });
    }

    renderTypePassword() {
        let icon = faEye;

        if(this.state.passVisible) {
            icon = faEyeSlash;
        }

        return (
            <div block="Field" elem="PassHolder">
                <FieldInput
                    { ...this.props }
                    type={ this.state.passVisible ? 'text' : 'password' }
                />
                <FontAwesomeIcon className="Field-IconRight" icon={ icon } onClick={ this.togglePassword } />
            </div>
        );
    }

    renderCheckbox() {
        const {
            id,
            onChangeCheckbox,
            link,
            label,
            validation
        } = this.props;

        const isRequired = validation.includes('notEmpty');

        return (
            <>
                { link }
                <label
                    block="Field"
                    elem="Label"
                    mods={ { isRequired } }
                    htmlFor={ id }
                >
                    { label }
                </label>
                <FieldInput
                  { ...this.props }
                  type="checkbox"
                  onChange={ onChangeCheckbox }
                />
                <label htmlFor={ id } />
            </>
        );
    }

    renderTypeText() {
        const { isDisabled, isBlock, isInfo, specialEnterDown, onKeyEnterDown } = this.props;
        if(!isDisabled && !isInfo ){
            return (
                <FieldInput
                    { ...this.props }
                    type="text"
                    onKeyDown={ specialEnterDown ? specialEnterDown : onKeyEnterDown }
                />
            );
        } else if(isDisabled) {
            return (<>
                { this.renderTypeLock() }
                </>
            );
        } else if(isInfo) {
            return (<>
                { this.renderTypeInfo() }
                </>
            );
        } else {
            return (<>
                { this.renderTypeBlock() }
                </>
            );
        }
    }

    renderSelectWithOptions() {
        const { isDisabled } = this.props;
        if(isDisabled) {
            return (
                <div block="Field" elem="LockHolder">
                    <FieldSelect
                        { ...this.props }
                    />
                    <FontAwesomeIcon className="Field-IconRight" icon={ faLock }/>
                </div>
            );
        }

        return (
            <FieldSelect
              { ...this.props }
            />
        );
    }

    renderTypeBlock() {
        let icon = faLock;
        return (
            <div block="Field" elem="LockHolder">
                <FieldInput
                    { ...this.props }
                    type='text'
                />
            </div>
        );
    }

    renderTypeLock() {
        let icon = faLock;
        return (
            <div block="Field" elem="LockHolder">
                <FieldInput
                    { ...this.props }
                    type='text'
                />
                <FontAwesomeIcon className="Field-IconRight" icon={ icon }/>
            </div>
        );
    }

    renderTypeInfo() {
        const {
            infoTitle,
            value,
            onKeyEnterDown,
            handleChange,
            handleInfoClick
        } = this.props;

        return (
            <>
                <FieldInput
                    isDisabled = { this.props.disabled }
                    { ...this.props }
                    type='text'
                    min=""
                    max=""
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={ (e) => handleChange(e, e.target.value) }
                    onKeyDown={ onKeyEnterDown }
                />
                <FontAwesomeIcon className="Field-IconRight Field-IconRightHover" icon={ faInfoCircle } title={ infoTitle } onClick={ handleInfoClick } />
            </>
        );
    }

    renderTypeDate() {
        const {
            onKeyEnterDown,
            specialEnterDown,
            value,
            elemCss
        } = this.props;

        if (value) {
            return (
                <FieldInput
                    { ...this.props }
                    type="date"
                    onKeyDown={ specialEnterDown ? specialEnterDown : onKeyEnterDown }
                />
            );
        } else if(elemCss && elemCss == 'showPlaceholder') {
            return (
                <>
                <FieldInput
                    { ...this.props }
                    type="date"
                    onKeyDown={ specialEnterDown ? specialEnterDown : onKeyEnterDown }
                />
                <FontAwesomeIcon icon={ faCalendar } />
                </>
            );
        } else {
            return (
                <FieldInput
                    { ...this.props }
                    type="text"
                    onFocus={ (e) => e.target.type = 'date' }
                    onKeyDown={ specialEnterDown ? specialEnterDown : onKeyEnterDown }
                />
            );
        }
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange
        } = this.props;

        return (
            <>
                <FieldInput
                  { ...this.props }
                  type="number"
                  readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e, e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                />
                <button
                  disabled={ +value === max }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleChange(e, +value + 1) }
                />
                <button
                  disabled={ +value === min }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleChange(e, +value - 1) }
                />
            </>
        );
    }

    renderTypePhone() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange,
            isInfo
        } = this.props;

        if(isInfo) {
           return(
               <>
               { this.renderTypeInfo() }
               </>
           );
        } else {
            return (
                <>
                    <FieldInput
                        { ...this.props }
                        type="text"
                        min=""
                        maxlength="9"
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={ (e) => handleChange(e, e.target.value, false, true) }
                        onKeyDown={ onKeyEnterDown }
                    />
                </>
            );
        }
    }

    renderTypePostcode() {
        const {
            onKeyEnterDown,
            handleChange,
            isInfo
        } = this.props;

        if(isInfo) {
           return(
               <>
               { this.renderTypeInfo() }
               </>
           );
        } else {
            return (
                <>
                    <FieldInput
                    { ...this.props }
                    type="text"
                    // eslint-disable-next-line react/jsx-no-bind
                    onKeyUp={ (e) => handleChange(e, e.target.value, false, false, false, true) }
                    onKeyDown={ onKeyEnterDown }
                    />
                </>
            );
        }
    }

    renderTypeNumeric() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange,
            isInfo,
            handleInfoClick,
            infoTitle
        } = this.props;

        return (
            <>
                <FieldInput
                  { ...this.props }
                  type="text"
                  min=""
                  max=""
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e, e.target.value, false, false, true) }
                  onKeyDown={ onKeyEnterDown }
                />
                { isInfo ? <FontAwesomeIcon className="Field-IconRight Field-IconRightHover" icon={ faInfoCircle } title={ infoTitle } onClick={ handleInfoClick } /> : '' }
            </>
        );
    }

    renderTypeFile() {
        return (
            <FieldInput
                { ...this.props }
                type="file"
            />
        );
    }

    onFileChange = (e) => {
        this.setState({ isLoading: true });
        const elem = e.target;
        const file = elem.files[0];

        //4194304 = 4Mb
        if(file.size > 4194304) {
            const { showError } = this.props;
            showError(__('File is bigger than the allowed 4Mb.'));
            elem.value = '';
            this.setState({ isLoading: false });
            return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({ isLoading: false });
            elem.parentElement.childNodes[2].value = reader.result;
        };
        reader.onerror = (error) => {
            this.setState({ isLoading: false });
            console.log('Error: ', error);
        };
    }

    renderTypeBase64() {
        const { formRef, label, validation, name, value } = this.props;
        let altName = name + '_placeholder';
        return (
            <>
                <input ref={ formRef }
                    label={ label }
                    validation={ validation }
                    name={ altName }
                    id={ altName }
                    type="file"
                    data-skip-value="false"
                    autocomplete="off"
                    onChange={ this.onFileChange }
                    accept="image/*"
                />
                <input
                    ref={ formRef }
                    name={ name }
                    id={ name }
                    type="hidden"
                    class="hidden-base64"
                    value={value}
                />
            </>
        );
    }

    renderMessage() {
        const { message } = this.props;

        if (!message) {
            return false;
        }

        return (
            <p block="Field" elem="Message">
                { message }
            </p>
        );
    }

    render() {
        const {
            mix,
            type,
            message,
            full_line,
            full_width
        } = this.props;

        if(type == "checkbox"){
            return (
                <>
                    <div
                    block="Field"
                    mods={ { type, full_line, full_width, hasError: !!message } }
                    mix={ mix }
                    >
                        { this.renderInputOfType(type) }
                    </div>
                    { this.renderMessage() }
                </>
            );
        }else{
            return (
                <div
                    block="Field"
                    mods={ { type, full_line, full_width, hasError: !!message } }
                    mix={ mix }
                >
                    <div
                        block="Field"
                        elem="Layer"
                    >
                        { this.renderLabel() }
                        { this.renderInputOfType(type) }
                    </div>
                    { this.renderMessage() }
                </div>
             );
        }
    }

};

export default Field;
