import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './SearchPosts.style';

import Image from 'Component/Image';
import BlogPostCard from 'Component/BlogPostCard';
import ContentWrapper from 'Component/ContentWrapper';

import PaginationView from 'Component/Pagination';
import LeftIcon from 'Route/StoreFinder/images/left.svg';
import RightIcon from 'Route/StoreFinder/images/right.svg';
import CmsBlock from 'Component/CmsBlock';
import Loader from 'Component/Loader';

class SearchPosts extends PureComponent {
    static propTypes = {
        getPosts: PropTypes.func.isRequired,
        searchPosts: PropTypes.func.isRequired,
        showContent: PropTypes.bool.isRequired,
        posts: PropTypes.array
    };

    state = {
        limit: 9
    }

    renderNoContents() {
        return (
            <div>
                <CmsBlock identifier={ "blog_search_noposts" } />
            </div>
        );
    }

    renderResultCount() {
        const { totalPosts, searchCriteria } = this.props;
        return (
            <>
                <span block="PostsListing" elem="ResultCount"><bold>{ totalPosts }</bold> { __('Search results for: ') } "{ searchCriteria }"</span>
            </>
        );
    }

    renderContents() {
        const { posts } = this.props;

        if(posts && posts.length > 0) {
            return (
                <>{ this.renderCard() }</>
            );
        }
    }

    renderCard() {
        return (
            <ContentWrapper label="PostsListing">
                <div block="PostsListing" elem="ResultContainer">
                    { this.renderResultCount() }
                    { this.renderFilter() }
                </div>
                <div block="PostsListing" elem="ColumnWrapper">
                    <div block="PostsListing" elem="Grid">
                        { this.renderGrid() }
                    </div>
                </div>
            </ContentWrapper>
        );
    }

    calcPagination(list){
        const { pageNumber } = this.props;
        const { limit } = this.state;

        return list.filter((_, index) => {
			return (index < pageNumber * limit && index >= (pageNumber - 1) * limit);
        });
    }

    onOrderChange(orderBy) {
        const { sortPosts } = this.props;
        // request new posts
        sortPosts(orderBy);
    }

    renderPagination() {
        const { pageNumber, onPageChange, totalPages, device } = this.props;
        const pageRangeDisplayed = device.isMobile ? 1 : 5;

        return (
            <>
                <div block="PostsListing" elem="Pagination">
                    <PaginationView
                        previousLabel={<Image
                            src={ LeftIcon }
                            width="20px"
                            height="20px"
                            mix={ { block: 'Paginate', elem: 'Icon', } }
                            />}
                        nextLabel={<Image
                            src={ RightIcon }
                            width="20px"
                            height="20px"
                            mix={ { block: 'Paginate', elem: 'Icon', } }
                                />}
                        breakLabel={<a href="">...</a>}
                        pageCount={ totalPages }
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={ pageRangeDisplayed }
                        onPageChange={onPageChange}
                        containerClassName={'pagination_container'}
                        pageClassName={'page_item'}
                        pageLinkClassName={'page_link'}
                        previousClassName={'page_item'}
                        nextClassName={'page_item'}
                        previousLinkClassName={'page_link'}
                        nextLinkClassName={'page_link'}
                        activeClassName={'active'}
                        initialPage={ 0 }
                        forcePage={ pageNumber-1 }
                    />
                </div>
            </>
        );
    }

    renderFilter() {
        const { posts, sortPosts } = this.props;

        if (posts.length) {
            return (
                <div block="PostsListing" elem="Filter">
                    <span>{ __("Sort by") }</span>
                    <div block="PostsListing" elem="FilterSelect">
                        <select name="order" onChange={ (e) => sortPosts(e.target.value) }>
                            <option value="publish_time">{ __("Date") }</option>
                            <option value="title">{ __("Name") }</option>
                            <option value="views_count">{ __("Most viewed") }</option>
                        </select>
                    </div>
                </div>
            )
        }

        return '';
    }

    renderGrid() {
        const { posts, totalPages } = this.props;

        return (
            <>
                <div block="PostsListing" elem="GridContainer">
                    {
                        posts.map((post) => (
                            <BlogPostCard
                                key={ post.post_id }
                                block="BlogPostCard"
                                post={ post }
                            />
                        ))
                    }
                </div>
                { totalPages > 1 && this.renderPagination() }
            </>
        )
    }

    render() {
        const { showContent, totalPosts } = this.props;
        if(!showContent && totalPosts <= 0) {
            return (
                <div block="SearchPosts">
                    { this.renderNoContents() }
                </div>
            );
        }

        if (showContent) {
            return (
                <div block="SearchPosts">
                    <main block="PostsListing">
                        { this.renderContents() }
                    </main>
                </div>
            );
        }

        return (
            <div block="SearchPosts">
                <Loader isLoading={true}/>
            </div>
        );
    }
}

export default SearchPosts;
