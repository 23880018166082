import {
    MenuItem as SourceMenuItem,
} from 'SourceComponent/MenuItem/MenuItem.component';

import Image from 'Component/Image';
import media from 'Util/Media';

export class MenuItem extends SourceMenuItem {

    renderItemContentImage(icon, itemMods) {
        const { device } = this.props;
        const { isBanner, isLogo, type } = itemMods;

        if (!icon
            /*|| (!device.isMobile && !isBanner && !isLogo)*/
            || (type === 'subcategory')
        ) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'Menu', elem: 'Image', mods: itemMods } }
              src={ icon && media(icon) }
              ratio="custom"
            />
        );
    }

};

export default MenuItem;
