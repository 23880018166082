/* eslint-disable react/no-array-index-key */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Slider from "react-slick";
import Html from 'Component/Html';
import Image from 'Component/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-light-svg-icons';

import {
   SliderWidget as SourceSliderWidget,
} from 'SourceComponent/SliderWidget/SliderWidget.component';

import './SliderWidget.style';

 /**
  * Homepage slider
  * @class SliderWidget
  * @namespace Component/SliderWidget/Component
  */
 export class SliderWidget extends SourceSliderWidget {
   constructor(props) {
      super(props);
      this.slider = React.createRef();
   }

   componentDidUpdate(prevProps) {
      if (this.slider && this.slider.current && this.slider.current.innerSlider && this.slider.current.innerSlider.list) {
         const slide = this.slider.current.innerSlider.list.querySelector('.SliderWidget-Figure');

         if (slide) {
            const maxHeight = slide.clientHeight +'px';
            
            if (this.slider.current.innerSlider.list.style.maxHeight !== maxHeight) {
               this.slider.current.innerSlider.list.style.maxHeight = maxHeight;
            }
         }
      }
   }

   renderRightArrow() {
      let icon = faChevronRight;
      const { slider: { title } } = this.props;

      if (title.includes('Categories')) icon = faArrowRight;


      return (
         <div>
            <FontAwesomeIcon class="arrow-right" icon={icon} />
         </div>
      );
   }

   renderLeftArrow() {
      let icon = faChevronLeft;
      const { slider: { title } } = this.props;

      if (title.includes('Categories')) icon = faArrowLeft;

      return (
         <div>
            <FontAwesomeIcon class="arrow-left" icon={icon} />
         </div>
      );
   }

   renderSlide = (slide, i) => {
      const {
          slide_text,
          isPlaceholder,
          title: block,
          slide_link,
          slide_link_target
      } = slide;

      if (slide_link) {
         return (
            <a href={ slide_link } target={ slide_link_target ? slide_link_target : '_self' }>
               <figure
                  block="SliderWidget"
                  elem="Figure"
                  key={ i }
               >
                  <Image
                     mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                     ratio="custom"
                     src={ this.getSlideImage(slide) }
                     isPlaceholder={ isPlaceholder }
                     isFirst={i == 0 ? true : false}
                  />
                  <figcaption
                     block="SliderWidget"
                     elem="Figcaption"
                     mix={ { block } }
                  >
                        <Html content={ slide_text || '' } />
                  </figcaption>
               </figure>
            </a>
         );
      }

      return (
          <figure
            block="SliderWidget"
            elem="Figure"
            key={ i }
          >
              <Image
                mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                ratio="custom"
                src={ this.getSlideImage(slide) }
                isPlaceholder={ isPlaceholder }
                isFirst={i == 0 ? true : false}
              />
              <figcaption
                block="SliderWidget"
                elem="Figcaption"
                mix={ { block } }
              >
                  <Html content={ slide_text || '' } />
              </figcaption>
          </figure>
      );
   };

   render() {
      const { activeImage } = this.state;
      const { slider, favStoreObj } = this.props;
      const {
         slides,
         title: block,
         slides_to_display,
         slides_to_display_mobile,
         slides_to_display_tablet,
         slides_to_scroll,
         slides_to_scroll_mobile,
         slides_to_scroll_tablet,
         show_menu,
         show_navigation,
         slideSpeed
      } = slider;

      if (!slides.length) {
         return (<></>);
      }

      let slidesVisible = slides.filter(slide => {
         return slide.is_sauda == 1 || slide.is_sauda == 2;
      });

      if(favStoreObj && favStoreObj.pharmacy_code && favStoreObj.sauda !== 1) {
         slidesVisible = slides.filter(slide => {
            return !slide.is_sauda || slide.is_sauda == 2;
         });
      }

      const settings = {
         dots: show_menu,
         arrows: show_navigation,
         infinite: false,
         speed: 500,
         slidesToShow: slides_to_display,
         slidesToScroll: slides_to_scroll,
         adaptiveHeight: true,
         nextArrow: this.renderRightArrow(),
         prevArrow: this.renderLeftArrow(),
         responsive: [
             {
                 breakpoint: 1024,
                 settings: {
                     slidesToShow: slides_to_display_tablet,
                     slidesToScroll: slides_to_scroll_tablet,
                     className: "center",
                     infinite: false,
                     arrows: false,
                 }
             },
             {
                 breakpoint: 600,
                 settings: {
                     slidesToShow: Number(slides_to_display_mobile),
                     slidesToScroll: slides_to_scroll_mobile,
                     className: "center",
                     infinite: false,
                     arrows: false,
                 }
             }
         ]
     };

     if (slideSpeed && slideSpeed > 0) {
         settings.autoplay = true;
         settings.infinite = true;
         settings.autoplaySpeed = slideSpeed;
     }

     return (
         <div mix={ { block: 'SliderWidget', mix: { block } } }>
            <Slider {...settings} ref={ this.slider }>
               { slidesVisible.map(this.renderSlide) }
            </Slider>
         </div>
     );
   }
}

export default SliderWidget;
