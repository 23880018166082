import PropTypes from 'prop-types';
import './HealthCardSmsConfirmCodeForm.style';
import FieldForm from 'Component/FieldForm';

class HealthCardSmsConfirmCodeForm extends FieldForm {

    onFormSuccess = (fields) => {
        window.scrollTo(0, 0);
        const { onCodeConfirm } = this.props;
        onCodeConfirm();
    };

    get fieldMap() {
        return {
            code: {
                label: __('Confirmation Code'),
                validation: ['notEmpty'],
                full_line: true,
                onChange: this.onCodeChange
            }
        };
    }

    onCodeChange = (fields) => {
        const { onCodeConfirmChange } = this.props;
        onCodeConfirmChange(fields);
    }

    renderActions() {
        return (
            <button
                block="HealthCard"
                elem="Button"
                mix={ { block: 'Button' } }
            >
                <p
                    block="HealthCard"
                    elem="ButtonJoinNow">
                        { __('Next') }
                </p>
            </button>
        );
    }
}

export default HealthCardSmsConfirmCodeForm;
