/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';
import MyAccountHelpAndContacts from './MyAccountHelpAndContacts.component';
import { updateMeta } from 'Store/Meta/Meta.action';
import PropTypes from 'prop-types';

/** @namespace Component/MyAccountHelpAndContacts/mapStateToProps */
export const mapStateToProps = (state) => ({

});

/** @namespace Component/MyAccountHelpAndContacts/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),

});

/** @namespace Component/MyAccountHelpAndContacts/Container */
export class MyAccountHelpAndContactsContainer extends PureComponent  {

    static propTypes = {
        updateMeta: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { updateMeta } = this.props;
        updateMeta({ title: __('Ajuda e Contactos') });
    }

    render() {

        return (
            <>
                <MyAccountHelpAndContacts/>
            </>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountHelpAndContactsContainer);
