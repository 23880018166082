import {
    PasswordChangePage as SourcePasswordChangePage,
} from 'SourceRoute/PasswordChangePage/PasswordChangePage.component';


import PropTypes from 'prop-types';
import ContentWrapper from 'Component/ContentWrapper';
import Field from 'Component/Field';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import FieldForm from 'Component/FieldForm';
import { Redirect } from 'react-router';
import Link from 'Component/Link';

import './PasswordChangePage.style';

export class PasswordChangePage extends FieldForm {
    // TODO implement logic
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        onPasswordAttempt: PropTypes.func.isRequired,
        onPasswordSuccess: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired
    };

    state = {
        isChecked: false,
        isSubscribedNecessary: false
    }

    onFormSuccess = (fields) => {
        const { showDob } = this.props;
        const { accept_terms } = fields;
        this.setState({
            isSubscribedNecessary: false
        });

        if (showDob && !accept_terms) {
            this.setState({
                isSubscribedNecessary: true
            });

            return false;
        }

        const { onPasswordSuccess } = this.props;
        onPasswordSuccess(fields);
    };

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    get fieldMap() {
        const { showDob, device: { isMobile } } = this.props;
        const { isSubscribedNecessary } = this.state;

        let dateNow = new Date(Date.now());
        let dataMax = ((dateNow.getUTCFullYear()-18) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;
        let dataMin = ((dateNow.getUTCFullYear()-120) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;

        if(showDob) {
            let conditionsLink = <>{__('I accept the ')}<Link to="condicoes-gerais">
                { __('Terms and Conditions') }
            </Link></>;

            return {
                /*dob: {
                    type: 'date',
                    label: __('Birthday'),
                    validation: ['notEmpty', 'date_adult', 'min_date_validation'],
                    full_width: true,
                    max:{dataMax},
                    min:{dataMin}
                },*/
                date_of_birth: {
                    type: 'date',
                    label: isMobile ? null : __('Date of Birth'),
                    placeholder: isMobile ? __('Date of Birth') : null,
                    validation: ['notEmpty', 'date_adult', 'min_date_validation'],
                    max: dataMax,
                    min: dataMin,
                    elemCss: 'showPlaceholder'
                },
                newPassword: {
                    type: 'password',
                    label: isMobile ? null : __('New password'),
                    placeholder: isMobile ? __('New password') : null,
                    validation: ['notEmpty', 'password'],
                    full_width: true
                },
                newPasswordRepeat: {
                    type: 'password',
                    label: isMobile ? null : __('Confirm password'),
                    placeholder: isMobile ? __('Confirm password') : null,
                    validation: ['notEmpty', 'password', 'new_password_match'],
                    full_width: true
                },
                accept_terms: {
                    type:"checkbox",
                    label: conditionsLink,
                    mix:{ block: 'MyAccountOverlay', elem: 'Checkbox', mods: { error: isSubscribedNecessary } },
                    name:"accept_terms",
                    value: this.state.isChecked,
                    onChange: this.onCheckboxChange,
                    validation: ['notEmpty']
                }
            };
        } else {
            return {
                /*email: {
                    type: 'email',
                    label: __('Email'),
                    validation: ['notEmpty', 'email'],
                    full_width: true
                },*/
                newPassword: {
                    type: 'password',
                    label: isMobile ? null : __('New password'),
                    placeholder: isMobile ? __('New password') : null,
                    validation: ['notEmpty', 'password'],
                    full_width: true
                },
                newPasswordRepeat: {
                    type: 'password',
                    label: isMobile ? null : __('Confirm password'),
                    placeholder: isMobile ? __('Confirm password') : null,
                    validation: ['notEmpty', 'password', 'new_password_match'],
                    full_width: true
                }
            };
        }
    }

    onCheckboxChange = () => {
        this.setState({
            isChecked: !this.state.isChecked
        });
    };

    /*renderForm() {
        const {
            onPasswordAttempt,
            onPasswordSuccess,
            onError,
            showErrorMessagePassword
        } = this.props;

        // TODO: use FieldForm instead!!!

        return (
            <Form
              key="reset-password"
              onSubmit={ onPasswordAttempt }
              onSubmitSuccess={ onPasswordSuccess }
              onSubmitError={ onError }
            >
                <div block="FieldForm" elem="Fields">
                    <Field
                        type="email"
                        label={ __('Email') }
                        id="email"
                        name="email"
                        validation={ ['notEmpty', 'email'] }
                        full_width={ true }
                    />
                    <Field
                        type="password"
                        label={ __('New password') }
                        id="newPassword"
                        name="newPassword"
                        autocomplete="new-password"
                        validation={ ['notEmpty', 'password'] }
                        full_width={ true }
                    />
                    <Field
                        type="password"
                        label={ __('Confirm password') }
                        id="newPasswordRepeat"
                        name="newPasswordRepeat"
                        autocomplete="new-password"
                        validation={ ['notEmpty', 'password', 'password_match'] }
                        full_width={ true }
                    />
                </div>
                <div block="MyAccount" elem="Buttons">
                    <button
                      type="submit"
                      block="PasswordChangePage"
                      elem="Button"
                      mix={ { block: 'Button' } }
                    >
                        { __('Update Password') }
                    </button>
                </div>
            </Form>
        );
    }*/

    renderActions() {
        const { showDob } = this.props;

        let btnText = __('Confirm');

        return (
            <button
                type="submit"
                block="PasswordChangePage"
                elem="Button"
                mix={ { block: 'Button' } }
            >
                { btnText }
            </button>
        );
    }

    render() {
        const { isLoading, redirect, showDob } = this.props;


        if (redirect) {
            return <Redirect to="/" />;
        }

        if (isLoading) {
            return (<Loader isLoading={ isLoading } />);
        }

        let title = <>
                <h3>{ __('Create a new password') }</h3>
                <p>{ __('To have access again to your account fill your new password') }</p>
            </>;

        if(showDob){
            title = <><h3>{ __('Your account validation process is almost finished!') }</h3><p>{ __('Confirm and fill in the fields below to access the new features.') }</p></>;
        }

        return (
            <main block="PasswordChangePage" aria-label={ __('Password Change Page') }>
                <ContentWrapper
                  mix={ { block: 'PasswordChangePage' } }
                  wrapperMix={ { block: 'PasswordChangePage', elem: 'Wrapper' } }
                  label={ __('Password Change Actions') }
                >
                    <Loader isLoading={ isLoading } />
                    { title }
                    <Form
                        onSubmitSuccess={ this.onFormSuccess }
                        mix={ { block: 'FieldForm' } }
                    >
                        { this.renderFields() }
                        { this.renderActions() }
                    </Form>
                </ContentWrapper>
            </main>
        );
    }
};

export default PasswordChangePage;
