import PharmacySelector from 'Route/StoreSelector';
import Overlay from 'Component/Overlay';
import Popup from 'Component/Popup';

export const renderPharmacySelectorPopup = (device) => {
    if (device.isMobile) {
        return (
            <Overlay
                id={ 'PharmacySelector' }
                mix={ { block: 'PharmacySelector' } }
            >
                <PharmacySelector/>
            </Overlay>
        );
    } else {
        return (
            <div>
                <Popup
                    id={ 'PharmacySelector' }
                    clickOutside={ false }
                    mix={ { block: 'PharmacySelector' } }
                    closedOn = { true }
                    onClose={ () => this.redirectBack() }
                >
                    <PharmacySelector/>
                </Popup>
            </div>
        );
    }
}