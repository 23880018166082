/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import BlogPostCard from '../BlogPostCard';
import { NUMBER_OF_RELATED_POSTS } from './BlogRelatedPosts.config';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import './BlogRelatedPosts.style';

/** @namespace ScandiPWA/Blog/Component/BlogRelatedPosts/Component */
export class BlogRelatedPosts extends PureComponent {
    static propTypes = {
        related_posts: PropTypes.array,
        isLoaded: PropTypes.number
    };

    static defaultProps = {
        related_posts: [],
        isLoaded: null
    };

    renderRelatedPosts() {
        const { related_posts, isLoaded } = this.props;

        if (!related_posts.length && !isLoaded) {
            return Array.from({ length: NUMBER_OF_RELATED_POSTS }, (_, i) => (
                <BlogPostCard
                  key={ i }
                  isPlaceholder
                />
            ));
        }

        return related_posts.map((post) => (
            <BlogPostCard
              key={ post.post_id }
              post={ post }
              mix={ { block: 'BlogRelatedPosts', elem: 'PostCard' } }
              isCompactOnMobile
            />
        ));
    }

    renderTitle() {
        const { related_posts } = this.props;

        if (!related_posts.length) {
            return null;
        }

        return (
            <h3 block="BlogRelatedPosts" elem="Title">
                { __('Related Posts') }
            </h3>
        );
    }

    renderRightArrow(){
        return (
            <div>
                <FontAwesomeIcon class="arrow-right" icon={ faChevronRight } />
            </div>
        );
    }

    renderLeftArrow(){
        return (
            <div>
                <FontAwesomeIcon class="arrow-left" icon={ faChevronLeft } />
            </div>
        );
    }

    render() {
        let settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            adaptiveHeight: true,
            nextArrow: this.renderRightArrow(),
            prevArrow: this.renderLeftArrow(),
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                    arrows: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1.4,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                  }
                }
              ]
          };

        return (
            <div block="BlogRelatedPosts" elem="Wrapper">
                { this.renderTitle() }
                <div block="BlogRelatedPosts" elem="PostsWrapper">
                    <Slider { ...settings } >
                        { this.renderRelatedPosts() }
                    </Slider>
                </div>
            </div>
        );
    }
}

export default BlogRelatedPosts;
