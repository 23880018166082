import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DeviceType } from 'Type/Device';
import TabsComponent from './Tabs.component';

import { PureComponent } from 'react';


export const mapStateToProps = state => ({
    device: state.ConfigReducer.device,
});

export const mapDispatchToProps = dispatch => ({

});

export class TabsContainer extends PureComponent {

    render() {

        return (
            <TabsComponent
            { ...this.props }
            { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsContainer);
