import {
    Footer as SourceFooter,
} from 'SourceComponent/Footer/Footer.component';

import './Footer.style.override.scss'

export class Footer extends SourceFooter {
    // TODO implement logic
};

export default Footer;
