import {
    CategoryConfigurableAttributes as SourceCategoryConfigurableAttributes,
} from 'SourceComponent/CategoryConfigurableAttributes/CategoryConfigurableAttributes.component';

import CategoryPriceRange from 'Component/CategoryPriceRange';
import ExpandableContent from 'Component/ExpandableContent';
import DropdownByBlock from 'Component/DropdownByBlock';
import ProductAttributeValue from 'Component/ProductAttributeValue';


export class CategoryConfigurableAttributes extends SourceCategoryConfigurableAttributes {

    state = {
        allBrands: {
            attribute_code: 'brand',
            attribute_label: 'Brand',
            attribute_options: {},
            attribute_type: 'select',
            attribute_values: []
        },
        saudaOffers: {
            attribute_code: "sauda_offers",
            attribute_label: '',
            attribute_options: {},
            attribute_type: 'select',
            attribute_values: []
        },
        allAttributes: [],
        subChildrenIds: []
    }

    renderConfigurableAttributes() {
        const { configurable_options, customer: { favourite_pharmacy_code } } = this.props;
        const { allAttributes, saudaOffers } = this.state;
        let new_configurable_options = Object.values(configurable_options);

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            let configurable_options_price = Object.values(configurable_options)[0];
            new_configurable_options = Object.values(configurable_options).splice(1, 5);
            new_configurable_options.push(configurable_options_price);
        }

        if(new_configurable_options.length) {
            let flag = false;
            for(let i=0; i<new_configurable_options.length;i++){
                if(new_configurable_options[i] && new_configurable_options[i].attribute_code == 'sauda_offers'){
                    flag = true;
                }
            }
            if(saudaOffers.attribute_values.length > 0 && !flag){
                let newOptions = [];
                for(let i=0; i<new_configurable_options.length;i++){
                    if(new_configurable_options[i]){
                        newOptions.push(new_configurable_options[i]);
                        if(new_configurable_options[i].attribute_code == 'sauda_offers'){
                            newOptions.push(saudaOffers);
                        }
                    } else {
                        newOptions = new_configurable_options;
                    }
                }
                return Object.values(newOptions)
                .map(this.renderConfigurableOption);
            } else {
                return Object.values(new_configurable_options)
                .map(this.renderConfigurableOption);
            }
        }
    }

    renderDropdownByBlock(option, subChildrenIds) {
        const { attribute_values } = option;
        const { parameters, getParamIds } = this.props;

        let children = attribute_values.map((attribute_value) => {
            let is_sub_category = subChildrenIds && subChildrenIds.includes(parseInt(attribute_value));
            return this.renderConfigurableAttributeValue({ ...option, attribute_value, is_sub_category })
        });

        if(parameters && parameters.category_id && (parameters.category_id.length >= 1)){
            let categoriesSelected = parameters.category_id;
            for(let i=0;i<children.length;i++){
                if(!categoriesSelected.includes(children[i].key)){
                    children[i].props.isSelected = false;
                } else {
                    children[i].props.isSelected = true;
                }
            }
        }

        if(parameters && parameters.brand && (parameters.brand.length >= 1)){
            for(let i=0;i<children.length;i++){

                if(!children[i] ||
                    !children[i].props ||
                    !children[i].props.attribute ||
                    !children[i].props.attribute.attribute_code
                ) continue;

                if(children[i].props.attribute.attribute_code !== "brand") continue;

                if(!parameters.brand.includes(children[i].key)){
                    children[i].props.isSelected = false;
                } else {
                    children[i].props.isSelected = true;
                }
            }
        }

        return (
            (children.length > 0) && <DropdownByBlock children={ children } />
        );
    }

    renderDropdown(option) {
        const { attribute_values } = option;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="DropDownList"
            >
                { attribute_values.map((attribute_value) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                )) }
            </div>
        );
    }

    renderPriceSwatch(option) {
        const { attribute_options, ...priceOption } = option;

        if (attribute_options) {
            // do not render price filter if it includes "*_*" aggregation
            if (attribute_options['*_*']) {
                return null;
            }

            priceOption.attribute_options = Object.entries(attribute_options).reduce((acc, [key, option]) => {
                acc[key] = {
                    ...option,
                    label: this.getPriceLabel(option)
                };

                return acc;
            }, {});
        }

        return <CategoryPriceRange />;

        //return this.renderDropdownOrSwatch(priceOption);
    }

    renderSubCategories(option) {
        const { getSubCategories, parameters } = this.props;

        const optionWithSubcategories = getSubCategories(option);
        const { attribute_values = [] } = optionWithSubcategories.options;

        if (!attribute_values.length) {
            return null;
        }
        return this.renderDropdownOrSwatch(optionWithSubcategories.options, optionWithSubcategories.subChildrenIds, optionWithSubcategories.hasSubcategories);
    }

    renderConfigurableOption = (option) => {
        if(option) {

            const { attribute_code } = option;
            const { allAttributes } = this.state;
            allAttributes.push(attribute_code);
            switch (attribute_code) {
                case 'price':
                    return this.renderPriceSwatch(option);
                case 'category_id':
                    return this.renderSubCategories(option);
                default:
                    return this.renderDropdownOrSwatch(option);
            }
        }
    };

    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            getLink,
            isSelected,
            subChildrenIds
        } = this.props;

        const { attribute_value, is_sub_category } = attribute;

        return (
            <ProductAttributeValue
              key={ attribute_value }
              attribute={ attribute }
              isSelected={ isSelected(attribute) }
              isAvailable={ getIsConfigurableAttributeAvailable(attribute) }
              onClick={ handleOptionClick }
              getLink={ getLink }
              isSubCategory={ is_sub_category }
            />
        );
    }

    renderDropdownOrSwatch(option, subChildrenIds, flagCategories = false) {
        const {
            isContentExpanded,
            getSubHeading
        } = this.props;

        const {
            attribute_label,
            attribute_code,
            attribute_options,
            attribute_values
        } = option;

        const { allBrands, saudaOffers } = this.state;

        if(attribute_code == 'has_points'){
            return (<></>);
        }

        let customOption = option;

        if(attribute_code == 'sauda' || attribute_code == 'has_points') {
            Object.keys(attribute_options).forEach(element => {
                if (attribute_options[element].value_string == 1) {
                    attribute_options[element].label = __("Yes");
                } else {
                    attribute_options[element].label = __("No");
                }
            });
        }

        if(attribute_code == 'brand' && customOption.attribute_values && customOption.attribute_values.length > 0) {

            customOption.attribute_values = Object.values(customOption.attribute_values).sort((a, b) => {
                let nameA = customOption.attribute_options[a].label.toLowerCase(), nameB = customOption.attribute_options[b].label.toLowerCase();
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            });

            if(customOption.attribute_values.length > allBrands.attribute_values.length){
                this.setState({ allBrands: customOption });
            }

            /*if(allBrands.attribute_values.length > 0){
                customOption = allBrands;
            }*/

        }

        if(attribute_code == 'sauda_offers' && customOption.attribute_values && customOption.attribute_values.length > 0){
            if(customOption.attribute_values.length > saudaOffers.attribute_values.length){
                this.setState({ saudaOffers: customOption });
            }

            if(saudaOffers.attribute_values.length > 0){
                customOption = saudaOffers;
            }
        }

        const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
        const isSwatch = !!swatch_data;

        /** unable to translate var fix */
        let title = attribute_label;
        if(attribute_label == 'Category') {
            title = __('Category');
        }
        if(attribute_label == 'Brand') {
            title = __('Brand');
        }

        let content = null;
        if (['category_id', 'brand'].includes(attribute_code)) {
            content = this.renderDropdownByBlock(customOption, subChildrenIds);
        } else {
            content = isSwatch ? this.renderSwatch(customOption) : this.renderDropdown(customOption);
        }

        return (
            <ExpandableContent
              key={ attribute_code }
              heading={ title }
              subHeading={ getSubHeading(customOption) }
              mix={ {
                  block: 'ProductConfigurableAttributes',
                  elem: 'Expandable',
                  mods: { isCategories: flagCategories }
              } }
              isContentExpanded={ isContentExpanded }
            >
                { content }
            </ExpandableContent>
        );
    }

};

export default CategoryConfigurableAttributes;
