/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const MIN_PASSWORD_LENGTH = 8;

/** @namespace Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => {
    /** Regex 1 lower cas + 1 upper case + number + special char + at least 8 chars */
    const regularExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$\\%^&*(),.?":{}|+=_<>-])[A-Za-z\d!@#$%^&*(),.?":{}\\\/\[\]|+=_<>-]{8,}$/;
    let testRegularExpression = regularExpression.test(value);

    if(testRegularExpression && value.length >= MIN_PASSWORD_LENGTH) {
        return true;
    }

    return false;
};

/** @namespace Component/Form/Config/validateTelephone */
//export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^[+]351[0-9]{9}$/);
export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^9[0-9]{8}$/);

/** @namespace Component/Form/Config/validateTelephoneOptional */
//export const validateTelephoneOptional = ({ value }) => value.length > 0 && value != '+351' ? value.match(/^[+]351[0-9]{9}$/) : true;
export const validateTelephoneOptional = ({ value }) => value.length > 0 ? value.match(/^9[0-9]{8}$/) : true;

/** @namespace Component/Form/Config/validateNIF */
export const validateNIF = ({ value }) => {
    if(!value || !value.length) {
        return true;
    }

    const nif = typeof value === 'string' ? value : value.toString();
    const validationSets = {
        one: ['1', '2', '3', '5', '6', '8'],
        two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99']
    };

    if (nif.length !== 9) {
        return false;
    }

    if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) {
        return false;
    }

    const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
    const modulo11 = (Number(total) % 11);
    const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

    return checkDigit === Number(nif[8]);
};

/** @namespace Component/Form/Config/validateAccessCode */
export const validateAccessCode = ({ value }) => value.length ? value.match(/[0-9]{6}$/) : true;

/** @namespace Component/Form/Config/validatePrescription */
export const validatePrescription = ({ value }) => value.length ? value.match(/^[0-9A-Z]{19}$/) : true;

/** @namespace Component/Form/Config/validateOptionCode */
export const validateOptionCode = ({ value }) => value.length ? value.match(/[0-9]{4}$/) : true;

/** @namespace Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 0;

/** @namespace Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    const { current: { value: passwordValue } } = password || { current: {} };
    return value === passwordValue;
};

/** @namespace Component/Form/Config/validateNewPasswordMatch */
export const validateNewPasswordMatch = ({ value }, { newPassword }) => {
    const { current: { value: passwordValue } } = newPassword || { current: {} };
    return value === passwordValue;
};

/** @namespace Component/Form/Config/validateCc */
export const validateCc = ({ value }) => value.length ? value.match(/^[0-9]{7,8}$/) : true;

/** @namespace Component/Form/Config/validatePassport */
export const validatePassport = ({ value }) => value.length ? value.match(/^[0-9A-Z]{4,10}$/) : true;

/** @namespace Component/Form/Config/validateNewPasswordMatch */
export const validateAge = ({ value, max }) => {
    return value <= max;
};

export const validateMinDate = ({ value, max, min }) => {
    return ((value <= max) && (value >= min));
};

/** @namespace Component/Form/Config/validatePostCode */
export const validatePostCode = ({ value }) => value.length ? value.match(/^[0-9]{4}-[0-9]{3}$/) : true;

/** @namespace Component/Form/Config/validateFullName */
export const validateFullName = ({ value }) => {
    let name = value.trim();
    return name.length ? name.match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+ [a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+/) : true;
};

/** @namespace Component/Form/Config/isNotEmpty */
export const checkboxRequired = ({ value }) => value && value != 'false';

export const addZero = (numero) => {
    if (numero <= 9)
        return "0" + numero;
    else
        return numero;
}

/** @namespace Component/Form/Config */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password needs 1 upper letter, 1 lower case, 1 special character and at least 8 characters long.')
    },
    telephone: {
        validate: validateTelephone,
        //message: __('Phone number is invalid! Phone must start with +351')
        message: __('Phone must start with 9!')
    },
    telephone_optional: {
        validate: validateTelephoneOptional,
        //message: __('Phone number is invalid! Phone must start with +351')
        // message: __('Phone number is invalid!')
        message: __('Phone must start with 9!')
    },
    nif: {
        validate: validateNIF,
        message: __('The NIF number is invalid!')
    },
    prescription: {
        validate: validatePrescription,
        message: __('The Prescription number is invalid!')
    },
    access_code: {
        validate: validateAccessCode,
        message: __('The Access Code number is invalid!')
    },
    option_code: {
        validate: validateOptionCode,
        message: __('The Option Code number is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    new_password_match: {
        validate: validateNewPasswordMatch,
        message: __('Password does not match.')
    },
    cc: {
        validate: validateCc,
        message: __('Citizen card number is invalid.')
    },
    passport: {
        validate: validatePassport,
        message: __('Passport number is invalid.')
    },
    date_adult: {
        validate: validateAge,
        message: __('You must be older than 18.')
    },
    min_date_validation: {
        validate: validateMinDate,
        message: __('Please enter a valid date.')
    },
    postcode: {
        validate: validatePostCode,
        message: __('Postcode is invalid!')
    },
    full_name: {
        validate: validateFullName,
        message: __('Please enter your full name.')
    },
    checkbox_required: {
        validate: checkboxRequired,
        message: __('This field is required!')
    }
});

export default formConfig();
