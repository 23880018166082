import {
    ProductGalleryThumbnailImage as SourceProductGalleryThumbnailImage,
} from 'SourceComponent/ProductGalleryThumbnailImage/ProductGalleryThumbnailImage.component';

import Image from 'Component/Image';
import {
    IMAGE_TYPE, PLACEHOLDER_TYPE, THUMBNAIL_KEY, VIDEO_TYPE
} from 'Component/ProductGallery/ProductGallery.config';
import media, { PRODUCT_MEDIA } from 'Util/Media';

export class ProductGalleryThumbnailImage extends SourceProductGalleryThumbnailImage {

    renderImage() {
        const {
            media: {
                label: alt,
                file,
                thumbnail: { url: thumbnailUrl } = {},
                id
            }
        } = this.props;

        /*if (id === THUMBNAIL_KEY) {
            return this.renderPlaceholder();
        }*/

        const src = thumbnailUrl || media(file, PRODUCT_MEDIA);

        return (
            <Image
              src={ src }
              alt={ alt }
              ratio="custom"
              mix={ { block: 'ProductGalleryThumbnailImage' } }
            />
        );
    }

};

export default ProductGalleryThumbnailImage;
