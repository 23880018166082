/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { StoreFinderDispatcher } from '../../store/StoreFinder';
import StoreSelector from './StoreSelector.component';
import { showNotification } from 'Store/Notification/Notification.action';
import StorePageQuery from 'Query/StorePage.query';
import { fetchMutation } from 'SourceUtil/Request';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { StorePageDispatcher } from '../../store/StorePage';
import { showPopup } from 'Store/Popup/Popup.action';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { store } from 'Store/';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace Scandiweb/StoreFinderGraphQl/Route/StoreFinder/Container/mapStateToProps */
export const mapStateToProps = state => ({
    storeListCities: state.StoreFinderReducer.storeSelectorCities,
    storeListMapped: state.StoreFinderReducer.storeSelectorMapped,
    storeByName: state.StoreFinderReducer.storeSelectorByName,
    serviceList: state.StoreFinderReducer.serviceList,
    storeFinderIsLoading: state.StoreFinderReducer.isLoadingSelector,
    device: state.ConfigReducer.device,
    customer: state.MyAccountReducer.customer,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    cartTotals: state.CartReducer.cartTotals,
    isCategoriesLoaded: state.CategoriesReducer.isLoaded,
    isLoading: state.MyAccountReducer.isLoading
});

/** @namespace Scandiweb/StoreFinderGraphQl/Route/StoreFinder/Container/mapDispatchToProps */
export const mapDispatchToProps = dispatch => ({
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
    requestServices: () => {
        StoreFinderDispatcher.requestServicesData(dispatch);
    },
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    requestStore: (id) => {
        StorePageDispatcher.requestStore(dispatch, id);
    },
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
    showCartPopup: (payload) => dispatch(showPopup('CartItemsAlert', payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    toggleBreadcrumbs: () => dispatch(toggleBreadcrumbs()),
    syncWishList: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    )
});

export class StoreSelectorContainer extends PureComponent {

    state = {
        allStores: {},
        totalFetch: 0,
        storesToShow: {},
        showMoreFlag: true,
        showMoreValue: 2
    }

    static propTypes = {
        requestStores: PropTypes.func.isRequired,
        requestServices: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired
    };

    containerFunctions = {
        updateContextPharmacy: this.handleUpdateCustomerContextPharmacy.bind(this)
    }

    componentDidMount() {
        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(false);
    }

    _updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const breadcrumbs = [
            { url: '/selecionador-de-farmacias', name: __('Select Pharmacies') },
            { url: '/', name: __('Home') }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    handleUpdateCustomerContextPharmacy(storeObj) {
        const { hideActiveOverlay, updateCart, syncCart, isSignedIn, showErrorNotification, showSuccessNotification, requestCustomerData, requestStore, customer: { preference_pharmacy_code, favourite_pharmacy_code }, syncWishList } = this.props;

        localStorage.setItem('updatingPharmacyContext', true);

        if (favourite_pharmacy_code && favourite_pharmacy_code == storeObj.pharmacy_code) {            
            if (BrowserDatabase.getItem('redirect_to')) {
                const redirect = BrowserDatabase.getItem('redirect_to');
                BrowserDatabase.deleteItem('redirect_to');
                history.push(appendWithStoreCode(redirect));
            } else {
                history.push(appendWithStoreCode(storeObj.store_url ? storeObj.store_url : '/'));
            }

            localStorage.setItem('updatingPharmacyContext', false);
        } else {
            if(isSignedIn){
                let setNewPharmacy = BrowserDatabase.getItem('change-pharmacy');//localStorage.getItem('change-pharmacy');
                if(setNewPharmacy || !preference_pharmacy_code) {
                    localStorage.removeItem('change-pharmacy');
                    const query = StorePageQuery.getUpdateCustomerPharmacyPreference(storeObj.pharmacy_code);
                    fetchMutation(query).then(
                        () => {
                            this.setState({ isLoading: true }, () => {
                                //requestCustomerData();
                                //showSuccessNotification(__('This pharmacy is now your favourite pharmacy.'));
                                /*updateCart().then(
                                    () => {
                                        syncCart();
                                        if (BrowserDatabase.getItem('redirect_to')) {
                                            const redirect = BrowserDatabase.getItem('redirect_to');
                                            BrowserDatabase.deleteItem('redirect_to');
                                            history.push(appendWithStoreCode(redirect));
                                        }
                                    }
                                );*/
                            });
                        },
                        (error) => {
                            this.handleError([{message:__('Something went wrong.')}])
                        }
                    );
                }

                //this.setState({ isLoading: true });

                const query = StorePageQuery.getUpdateCustomerContextPharmacy(storeObj.pharmacy_code);
                fetchMutation(query).then(
                    (response) => {
                        requestCustomerData().then(
                            () => {
                                showSuccessNotification(__('This pharmacy is now your favourite pharmacy.'));
                                updateCart().then(
                                    () => {
                                        syncCart();
                                        if (BrowserDatabase.getItem('redirect_to')) {
                                            const redirect = BrowserDatabase.getItem('redirect_to');
                                            BrowserDatabase.deleteItem('redirect_to');
                                            history.push(appendWithStoreCode(redirect));
                                        } else {
                                            history.push(appendWithStoreCode(storeObj.store_url ? storeObj.store_url : '/'));
                                        }
                                        //this.setState({ isLoading: false });
                                        localStorage.setItem('updatingPharmacyContext', false);
                                    }
                                );
                                syncWishList();
                            }
                        );
                    },
                    (error) => {
                        this.handleError([{message:__('Something went wrong.')}])
                    }
                );

                this.setState({ isLoading: false });
            } else {
                const query = StorePageQuery.getUpdateGuestContextPharmacy(storeObj.pharmacy_code);
                fetchMutation(query).then(
                    ({updateGuestContextPharmacy}) => {

                        if(updateGuestContextPharmacy != 'false'){

                            //this.setState({ isLoading: false });

                            localStorage.setItem('guest_pharmacy_code', storeObj.pharmacy_code);

                            let obj = {
                                time:new Date().getTime(),
                                value:3600000,
                                expire:3600000,
                            }
                            // You can only store strings
                            let objStr = JSON.stringify(obj);
                            localStorage.setItem('guest_pharmacy_code_expire_time',objStr);

                            //requestStore(storeObj.pharmacy_code);
                            showSuccessNotification(__('This pharmacy is now your favourite pharmacy.'));
                            updateCart().then(
                                () => {
                                    syncCart();
                                    if (BrowserDatabase.getItem('redirect_to')) {
                                        let redirect = BrowserDatabase.getItem('redirect_to');
                                        if(redirect == '/farmacia-info/') {
                                            redirect = storeObj.store_url;
                                            //hideActiveOverlay();
                                        }
                                        BrowserDatabase.deleteItem('redirect_to');
                                        history.push(appendWithStoreCode(redirect));
                                    } else {
                                        history.push(appendWithStoreCode(storeObj.store_url ? storeObj.store_url : '/'));
                                    }
                                }
                            );
                            syncWishList();
                            if(!localStorage.getItem('goToProductPage')){
                                window.location.reload();
                            }
                        } else {
                            //this.handleUpdateCustomerContextPharmacy(storeObj);
                            showErrorNotification([{message:__('Something went wrong!')}]);
                        }

                        localStorage.setItem('updatingPharmacyContext', false);
                    },
                    (error) => {
                        this.handleError([{message:__('Something went wrong.')}]);
                        showErrorNotification([{message:__('You need to be logged in to make this pharmacy as favourite.')}]);

                        localStorage.setItem('updatingPharmacyContext', false);
                    }
                );
            }
        }
        hideActiveOverlay();
    }

    render() {
        return (
            <StoreSelector
            { ...this.props }
            { ...this.state }
            { ...this.containerFunctions }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreSelectorContainer);
