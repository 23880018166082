import { Field } from "Util/Query";

export class CmsPagesQuery {

    
    getCmsPages(){
        return new Field('getCmsPages')
            .addFieldList([
                this._getCmsPageFields()
            ])
            .setAlias('cms_pages');
     
    }

    _getCmsPageFields(){
        return new Field('cmspages')
                .addFieldList(
                    this._getCmsPageField()
                );
    }

    _getCmsPageField(){
        return [
            'id',
            'title',
            'url'
        ];
    }

    getHeaderFooter(cmsPageId){
        return new Field('getHeaderFooter')
            .addArgument('cmsPageId', 'Int', cmsPageId)
            .addField('header_footer');
    }
}

export default new CmsPagesQuery();