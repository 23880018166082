/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateMeta } from 'Store/Meta/Meta.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { LocationType } from 'Type/Router';
import { convertQueryStringToKeyValuePairs } from 'Util/Url';
import { fetchQuery } from 'Util/Request';
import MyAccountQuery from 'Query/MyAccount.query';
import StorePageQuery from 'Query/StorePage.query';
import { fetchMutation } from 'SourceUtil/Request';

import ConfirmAccountPage from './ConfirmAccountPage.component';

import { StorePageDispatcher } from '../../store/StorePage';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace Route/ConfirmAccountPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
    device: state.ConfigReducer.device,
});

/** @namespace Route/ConfirmAccountPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => {
        BreadcrumbsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
        );
    },
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    confirmAccount: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.confirmAccount(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    signIn: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.signIn(options, dispatch)
    ),
    requestStore: (id) => {
        StorePageDispatcher.requestFavStore(dispatch, id);
    },
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
    syncWishList: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    )
});

/** @namespace Route/ConfirmAccountPage/Container */
export class ConfirmAccountPageContainer extends PureComponent {
    static propTypes = {
        location: LocationType.isRequired,
        signIn: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        confirmAccount: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired
    };

    containerFunctions = {
        onConfirmAttempt: this.onConfirmAttempt.bind(this),
        onConfirmSuccess: this.onConfirmSuccess.bind(this),
        onFormError: this.onFormError.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            redirect: false,
            isLoading: false
        };
    }

    componentDidMount() {
        const { location: { search }, updateMeta, showNotification, device } = this.props;

        updateMeta({ title: __('Confirm account') });

        this._updateBreadcrumbs();

        const options = convertQueryStringToKeyValuePairs(search);
        const { email } = options;

        this.setState({ isLoading: true });
        const query = MyAccountQuery.checkEmailActive(email);
        fetchQuery(query).then(
            ({ checkEmailActive }) => {
                if(checkEmailActive && checkEmailActive.redirect) {
                    showNotification('error', __('Your account is already active'));
                    this.setState({ redirect: true });
                } else {
                    this.setState({ isLoading: false });
                }

                if (!device.isMobile) {
                    window.scrollTo(0,0);
                }
            },
            (error) => {
                this.setState({ isLoading: false });
                console.log(error)

                if (!device.isMobile) {
                    window.scrollTo(0,0);
                }
            }
        );
    }

    onConfirmAttempt() {
        //this.setState({ isLoading: true });
    }

    onConfirmSuccess(fields) {
        const {
            location: { search },
            confirmAccount,
            signIn,
            requestStore,
            requestCustomerData,
            updateCart,
            syncCart,
            syncWishList
        } = this.props;

        this.setState({ isLoading: true });

        const { password } = fields;

        const options = convertQueryStringToKeyValuePairs(search);
        const { email } = options;

        confirmAccount({ ...options, password })
            .then(
                /** @namespace Route/ConfirmAccountPage/Container/confirmAccountThen */
                () => {
                    signIn({ email, password })
                        .then(() => {
                            // request store
                            if (localStorage.getItem('guest_pharmacy_code')) {
                                /* if (localStorage.getItem('guest_pharmacy_code_expire_time')) {
                
                                    let expire = localStorage.getItem('guest_pharmacy_code_expire_time');
                                    let expireObj = JSON.parse(expire);
                
                                    if (new Date().getTime() - expireObj.time >= expireObj.expire) {
                                        localStorage.removeItem('guest_pharmacy_code');
                                        localStorage.removeItem('guest_pharmacy_code_expire_time')
                                    } else {
                                        requestStore(localStorage.getItem('guest_pharmacy_code'));
                                        this.setState({ guestPrevPharmacyCode: localStorage.getItem('guest_pharmacy_code') })
                                    }
                
                                } else {
                                    localStorage.removeItem('guest_pharmacy_code');
                                } */
                                
                                const selectedCode = localStorage.getItem('guest_pharmacy_code');

                                this.setState({ guestPrevPharmacyCode: selectedCode });

                                const queryPreference = StorePageQuery.getUpdateCustomerPharmacyPreference(selectedCode);
                                fetchMutation(queryPreference);

                                const query = StorePageQuery.getUpdateCustomerContextPharmacy(selectedCode);
                                fetchMutation(query).then(
                                    () => {
                                        requestCustomerData();
                                        updateCart().then(syncCart);
                                        syncWishList();
                                    },
                                    (error) => {
                                        this.handleError([{message:__('Something went wrong.')}])
                                    }
                                );
                            }
                        })
                }
            )
            .then(
                /** @namespace Route/ConfirmAccountPage/Container/confirmAccountThenThen */
                () => this.setState({ redirect: true })
            )
            .catch(
                /** @namespace Route/ConfirmAccountPage/Container/confirmAccountThenThenCatch */
                () => this.setState({ isLoading: false })
            );
    }

    onFormError() {
        this.setState({ isLoading: false });
    }

    _updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const breadcrumbs = [
            {
                url: '/account/confirmAccount',
                name: __('Confirm Account')
            },
            {
                url: '/',
                name: __('Home')
            }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    render() {
        return (
            <ConfirmAccountPage
                { ...this.props }
                { ...this.containerFunctions }
                { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAccountPageContainer);
