import {
    ProductAttributeValue as SourceProductAttributeValue,
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import PropTypes from 'prop-types';
import { MixType } from 'Type/Common';
import { AttributeType } from 'Type/ProductList';

import './ProductAttributeValue.style.override'

export class ProductAttributeValue extends SourceProductAttributeValue {

    state = {
            changingPrice: {
                min: 0,
                max: 24,
            },
    };

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            isSubCategory
        } = this.props;

        const {
            changingPrice
        } = this.state;

        if (attribute_code && !attribute_value) {
            return null;
        }
        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        return (
            <a
              href={ href }
              block="ProductAttributeValue"
              mods={ { isNotAvailable, isSubCategory } }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
};

export default ProductAttributeValue;
