import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MyAccountPageManager from 'Component/MyAccountPageManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import CmsBlock from 'Component/CmsBlock';
import HealthCardSms from 'Component/HealthCardSms';
import HealthCardAssociateForm from 'Component/HealthCardAssociateForm';
import MyAccountQuery from 'Query/MyAccount.query';
import { fetchQuery } from 'Util/Request';
import Popup from 'Component/Popup';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { customerType } from 'Type/Account';
import cartaoSauda from './Images/cartaosauda_new.png';
import cartaoSaudaSuccess from './Images/group5@2x.png';
import Image from 'Component/Image';
import { DeviceType } from 'Type/Device';
import Loader from 'Component/Loader';
import Barcode from 'react-barcode';
import Link from 'Component/Link';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import BrowserDatabase from 'Util/BrowserDatabase';

import HealthCardVouchersSlider from 'Component/HealthCardVouchersSlider';
import HealthCardExclusiveVouchersSlider from 'Component/HealthCardExclusiveVouchersSlider';
import HealthCardPointsExclusiveSlider from 'Component/HealthCardPointsExclusiveSlider';
import HealthCardVouchersList from 'Component/HealthCardVouchersList';
import HealthCardProductList from 'Component/HealthCardProductList';

import './HealthCard.style';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';

export class HealthCard extends PureComponent {
    static propTypes = {
        showEditForm: PropTypes.func.isRequired,
        setLoading: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        saudaCardMatrix: PropTypes.arrayOf(PropTypes.string).isRequired,
        saudaCardPoints: PropTypes.arrayOf(PropTypes.string).isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        changeCreateCardSuccessState: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        updateCustomer: PropTypes.func.isRequired,
        requestCustomerData: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        submitPointsToVoucher: PropTypes.func.isRequired,
        updatePage: PropTypes.func.isRequired,
        associationFinish: PropTypes.bool.isRequired,
        setAssociationFinish: PropTypes.func.isRequired
    };

    state = {
        showCreateNewCard: false,
        phoneNumber: 0,
        cardDetails: {},
        positions: {},
        positionFirst: '',
        positionSecond: '',
        positionThird: '',
        showPopup: false,
        showPointsBlock: false,
        showSuccessAssociate: false,
        isBtnPointsActive: true,
        currentVoucher: false,
        currentCardNumber: false
    }

    static defaultProps = {
        FormTitle : "HealthCard"
    };

    renderPageManager(button) {
        let isEdit = true;
        const { phoneNumber } = this.state;
        const { showInfoPopup, changeCreateCardSuccessState } = this.props;

        return (
            <>
            <h1 block="MyAccount" elem="Heading" mods={{isEdit}}>{ button }{ __('Add Card') }</h1>
                <div block="HealthCard">
                <CmsBlock identifier={ "HealthCardAddCardDescription" } />
                <MyAccountPageManager
                    phoneNumber={ phoneNumber }
                    { ...this.props }
                    changeCreateCardSuccessState={ changeCreateCardSuccessState }
                />
                <Popup
                    id={ 'HealthCardConditions' }
                    clickOutside={ true }
                    mix={ { block: 'HealthCardConditions' } }
                    closedOn={ false }
                >
                    <FontAwesomeIcon className="HealthCard-Close" onClick={  showInfoPopup } icon={ faTimes } />
                    <div block="HealthCard" elem="Popup-Content">
                        <CmsBlock identifier={ "HealthCardConditions" } />
                    </div>
                </Popup>
                </div>
            </>
        );
    }

    renderActions() {
        const { showEditForm } = this.props;

        return (
            <div
                block="HealthCard"
                elem="Links">
                <a
                    href="/vantagens-exclusivas"
                    target="_blank"
                    block="HealthCard"
                    elem="KnowMore"
                >
                    {  __("Know more") }
                </a>
                <button
                    block="HealthCard"
                    elem="Button"
                    mix={ { block: 'Button' } }
                    onClick={ showEditForm }
                >
                    <p
                        block="HealthCard"
                        elem="ButtonJoinNow">
                            { __('Join now') }
                    </p>
                </button>
                <a
                    href="/ver-condicoes"
                    target="_blank"
                    block="HealthCard"
                    elem="SeeConditions"
                >
                    {  __("See conditions") }
                </a>
            </div>
        );
    }

    renderTable() {
        return (
            <div block="HealthCard" elem="InfoWrapper">
                <div block="HealthCard">
                    <CmsBlock identifier={ "cartao_sauda" } />
                </div>
            </div>
        );
    }

    renderContent(button) {
        const { device, customer, saudaCardPoints, isLoadedSaudaCardPoint, isLoadedSaudaCardMatrix } = this.props; // Mobile Device
        const { showPointsBlock } = this.state;

        if (!isLoadedSaudaCardPoint || !isLoadedSaudaCardMatrix) {
            return <Loader isLoading={ true } />
        }

        if ((customer && customer.sauda_card && customer.sauda_card.card_number) || (saudaCardPoints && saudaCardPoints.cardNumber)) {
            if(showPointsBlock || localStorage.getItem('showPointsToChangeBlock') === 'true') {
                button = <button
                            block="MyAccount"
                            elem="BackButton"
                            mods={ { VoucherBackButton:true } }
                            onClick={ () => this.showPointsToChangeBlock(false) }
                        >
                            <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                            { device.isMobile ? '' : __('Back') }
                        </button>;
                return (
                    <>
                        <h1 block="MyAccount" elem="Heading">{ button }{ __('Change Saúda Points') }</h1>
                        { this.renderPointsBlock() }
                    </>
                );
            } else {
                return (
                    <>
                        { this.renderSaudaCard() }
                        { /* this.renderCmsBlockExplainPoints() */ }
                        { this.renderSaudaVouchers() }

                        { /* this.renderSaudaExclusivePoints() */ }
                        { /* this.renderSaudaExclusiveVouchers() */ }
                    </>
                );
            }
        } else {
            const { checkJoinCard, showEditForm, isLoadedSaudaCardPoint, isLoadedSaudaCardMatrix } = this.props;
            if (isLoadedSaudaCardMatrix && isLoadedSaudaCardPoint) {
                // show join card form if param action is given
                if (checkJoinCard()) {
                    showEditForm();
                }
            }

            return (
                <>
                <h1 block="MyAccount" elem="Heading">{ button }{ __('Health Card') }</h1>
                    <div block="HealthCard">
                            <div block="HealthCard">
                                { this.renderTable() }
                            </div>
                            <div block="MyAccount" elem="Actions">
                                    { this.renderActions() }
                            </div>
                    </div>
                </>
            );
        }
    }

    renderSavedPoints(points) {
        if(points) {
            return (
                <div class="points-saved">
                    <p>{ __('You saved ') }<strong>€{ points }</strong><br></br> { __('with the Saúda Card.') }</p>
                </div>
            );
        }
    }

    renderSaudaCard(){
        const { customer, saudaCardPoints, customer: { sauda_card } } = this.props;
        const { showPointsBlock, isBtnPointsActive } = this.state;

        if (saudaCardPoints.points != null) {
            this.setState({ isBtnPointsActive: false });
        }

        if( customer && sauda_card &&
            !sauda_card.is_active &&
            (sauda_card.status == 'PreCanceledAuto' ||
            sauda_card.status == 'NotDefined' ||
            sauda_card.status == 'PreCanceledBackOffice' ||
            sauda_card.status == 'Blocked')
        ){
            this.setState({ isBtnPointsActive: true });
        }

        return (
            <>
                <Loader isLoading={ !saudaCardPoints.cardNumber } />
                <h1 block="MyAccount" elem="Heading">{ __('Health Card') }</h1>
                <div block="HealthCard">
                    <h3 block="HealthCard" elem="Title">{ __('My Sauda Points') }</h3>
                    <div class="row card-block">
                        <div block="HealthCardPoints">
                            <div block="HealthCard" elem="CardImageInfo">
                                <Image
                                    src={ cartaoSauda }
                                    width="auto"
                                    height="auto"
                                    block="HealthCard"
                                    elem="CardImage"
                                />
                                { saudaCardPoints.cardNumber && <div block="HealthCard" elem="CardImageCode">
                                    <Barcode value={ saudaCardPoints.cardNumber } background='transparent' width="2" height="50"/>
                                    <p block="HealthCard" elem="CardImageName">{ saudaCardPoints.name ? saudaCardPoints.name.toLowerCase() : '' }</p>
                                </div>
                                }
                            </div>
                            { /* this.renderSavedPoints(saudaCardPoints.totalValueFolded) */ }
                        </div>
                        <div block="HealthCard" elem="Points">
                            <ul>
                                <li class="points-available">
                                    <h3>{ __('Points available') }</h3>
                                    <span>{saudaCardPoints.points}</span>
                                </li>
                                <li class="points-expire">
                                    <h3>{ __('Points to expire this month') }</h3>
                                    <span>{saudaCardPoints.pointsToPrescribeNextMonth}</span>
                                </li>
                            </ul>
                            <div class="button-change-points">
                                <button
                                    block="HealthCardPoints"
                                    elem="ButtonChangePoints"
                                    mix={ { block: 'Button' } }
                                    onClick={ () => this.showPointsToChangeBlock(true) }
                                    disabled={ isBtnPointsActive }
                                >
                                    <p
                                        block="HealthCardPoints"
                                        elem="ButtonChangePoints">
                                            { this.renderChangePointsButtonText() }
                                    </p>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    checkIfExclusiveCanRender(voucher, customerPharmacy) {
        if (customerPharmacy) {
            if (voucher.burnConditions.exclusivePharmacies && (voucher.burnConditions.exclusivePharmacies.length > 0)) {
                let pharmaciesArray = voucher.burnConditions.exclusivePharmacies;
                for (let i=0;i<=pharmaciesArray.length;i++) {
                    if (pharmaciesArray[0] && pharmaciesArray[0].code == customerPharmacy) {
                        return true;
                    }
                }
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    renderSaudaVouchers() {
        const {
            customer,
            saudaCardPoints,
            customer: { sauda_card },
            showInfoPopupCardInfo
        } = this.props;

        let cards = [];

        let countVouchers = 0;

        let show = true;

        if( customer && sauda_card &&
            !sauda_card.is_active &&
            (sauda_card.status == 'PreCanceledAuto' ||
            sauda_card.status == 'NotDefined' ||
            sauda_card.status == 'PreCanceledBackOffice' ||
            sauda_card.status == 'Blocked')
        ){
            show = false;
        }

        if(!show){
            return (
                <></>
            );
        }

        if (saudaCardPoints.vouchers) {
            countVouchers = saudaCardPoints.vouchers.length;
            if (countVouchers > 0) {
                saudaCardPoints.vouchers
                    .sort((a,b) => {
                        return new Date(a.endDate) - new Date(b.endDate);
                    })
                    .forEach((voucher) => {
                        //if (voucher.type == 'Voucher' && voucher.status == 'Available') {
                        if (voucher.status == 'Available' && !voucher.in_order) {
                            if (this.checkIfExclusiveCanRender(voucher, customer.favourite_pharmacy_code)) {
                                cards.push(
                                    <div class="card">
                                        <div class="title">
                                            <h3 block="HealthCard" elem="Title" class="title-item">{ voucher.detailedDescription.title }</h3>
                                        </div>
                                        <div class="description">
                                            <p><strong>{ __('Conditions') }</strong></p>
                                            <p class="description-item">{ voucher.detailedDescription.description }</p>
                                            <p class="see-more" onClick={ () => showInfoPopupCardInfo('HealthCardInfo' + voucher.code.trim()) }>{ __('See more') } <FontAwesomeIcon icon={ faArrowRight } /></p>
                                            
                                            <Popup
                                                id={ 'HealthCardInfo' + voucher.code.trim() }
                                                clickOutside={ true }
                                                mix={ { block: 'HealthCard', elem: 'PopupInfo' } }
                                                closedOn={ false }
                                            >
                                                <FontAwesomeIcon className="HealthCard-Close" onClick={ () => showInfoPopupCardInfo('HealthCardInfo' + voucher.code.trim()) } icon={ faTimes } />
                                                <div block="HealthCard" elem="HealthCardInfoPopup-Content">
                                                    <div class="title">
                                                        <h3 block="HealthCard" elem="Title" class="title-item">{ voucher.detailedDescription.title }</h3>
                                                    </div>  
                                                    <div class="description">
                                                        <p><strong>{ __('Conditions') }</strong></p>
                                                        <p class="description-item">{ voucher.detailedDescription.description }</p>
                                                        <div class="code">
                                                            <div class="code-block">
                                                                <p class="code-content">
                                                                    <span class="code-item-title">{ __('Code') }</span> <br></br>
                                                                    <span class="code-item-code" id={voucher.code.trim()}>{ voucher.code }</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div class="actions">
                                                        <button
                                                            block="HealthCardPoints"
                                                            elem="HealthCardVouchers"
                                                            mix={ { block: 'Button' } }
                                                            onClick={ () => this.copyToClipboard(voucher.code.trim()) }
                                                        >
                                                            <p
                                                                block="HealthCardPoints"
                                                                elem="HealthCardVouchers">
                                                                    { __('Copy Code') }
                                                            </p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Popup>
                                        </div>
                                        <div class="actions-continer">
                                            <div class="code">
                                                <div class="code-block">
                                                    <p class="code-content">
                                                        <span class="code-item-title">{ __('Code') }</span> <br></br>
                                                        <span class="code-item-code" id={voucher.code.trim()}>{ voucher.code }</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="actions">
                                                {this.renderVoucherButton(voucher)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    });
            }
        }

        let voucherMessage = '';
        let voucherTitle = '';
        if (cards.length > 0) {
            voucherMessage = <p>{ __('These vouchers were exclusively assigned to you, enjoy!') }</p>;
            voucherTitle = __('My Exclusive Sauda Vouchers');
        } else {
            voucherMessage = <p>{ __('No vouchers available') }</p>;
            voucherTitle = __('My Exclusive Sauda Vouchers');
        }

        return (
            <div block="HealthCard">
                <div class="heading-vouchers">
                    <h3 block="HealthCard" elem="Title">{ voucherTitle }</h3>
                    { voucherMessage }
                </div>
                {cards.length > 0 && <HealthCardVouchersList cards={ cards } />}
            </div>
        );

    }

    renderVoucherButton(voucher) {
        if(voucher.is_online){
            return(
                <button
                        block="HealthCardPoints"
                        elem="HealthCardVouchers"
                        mix={ { block: 'Button' } }
                        onClick={ () => this.copyToClipboard(voucher.code.trim()) }
                    >
                        <p
                            block="HealthCardPoints"
                            elem="HealthCardVouchers">
                                { __('Copy Code') }
                        </p>
                </button>
            );
        } else {
            return(
                <button
                        block="HealthCardPoints"
                        elem="HealthCardVouchers"
                        mix={ { block: 'Button Physical' } }
                    >
                        <p>{__('Campaign only applicable at the Pharmacy')}</p>
                </button>
            );
        }
    }

    renderSaudaExclusiveVouchers() {
        const {
            customer,
            saudaCardPoints
        } = this.props;

        let cards = [];

        let countVouchers = 0;

        if (saudaCardPoints.vouchers) {
            countVouchers = saudaCardPoints.vouchers.length;

            if (countVouchers > 0) {
                saudaCardPoints.vouchers.forEach((voucher) => {
                    if (voucher.paymentType != 'Points' && voucher.type == 'Voucher' && voucher.status == 'Available') {
                        if (this.checkIfExclusiveCanRender(voucher, customer.favourite_pharmacy_code)) {
                            cards.push(
                                <div class="card">
                                    <div class="title">
                                        <h3 block="HealthCard" elem="Title" class="title-item">{ voucher.detailedDescription.title }</h3>
                                    </div>
                                    <div class="description">
                                        <p><strong>{ __('Conditions') }</strong></p>
                                        <p class="description-item">{ voucher.detailedDescription.description }</p>
                                    </div>
                                    <div class="code">
                                        <div class="code-block">
                                            <p class="code-content">
                                                <span class="code-item-title">{ __('Code') }</span> <br></br>
                                                <span class="code-item-code" id={voucher.code.trim()}>{ voucher.code }</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="actions">
                                    <button
                                                block="HealthCardPoints"
                                                elem="HealthCardVouchers"
                                                mix={ { block: 'Button' } }
                                                onClick={ () => this.copyToClipboard(voucher.code.trim()) }
                                            >
                                                <p
                                                    block="HealthCardPoints"
                                                    elem="HealthCardVouchers">
                                                        { __('Copy Code') }
                                                </p>
                                            </button>
                                    </div>
                                </div>
                            );
                        }
                    }
                });
            }
        }
        return (
            <div block="HealthCard">
                <div class="heading-exclusive">
                    <h3 block="HealthCard" elem="Title">{ __('My Exclusive Sauda Vouchers') }</h3>
                    <p>{ __('These vouchers were exclusively assigned to you, enjoy!') }</p>
                </div>
                <HealthCardExclusiveVouchersSlider cards={cards}  bigScreenNumber="3"/>
                </div>
        );

    }

    renderSaudaExclusivePoints() {
        const {
            customer,
            saudaCardPoints
        } = this.props;

        let cardsPoints = [];

        let countVouchers = 0;

        if (saudaCardPoints.vouchers) {
            countVouchers = saudaCardPoints.vouchers.length;

            if (countVouchers > 0) {
                saudaCardPoints.vouchers.forEach((voucher) => {
                    if (voucher.paymentType == 'Points' && voucher.type == 'Voucher' && voucher.status == 'Available') {
                        if (this.checkIfExclusiveCanRender(voucher, customer.favourite_pharmacy_code)) {
                            cardsPoints.push(
                                <div class="card">
                                    <div class="title">
                                        <h3 block="HealthCard" elem="Title" class="title-item">{ voucher.detailedDescription.title }</h3>
                                    </div>
                                    <div class="description">
                                        <p><strong>{ __('Conditions') }</strong></p>
                                        <p class="description-item">{ voucher.detailedDescription.description }</p>
                                    </div>
                                </div>
                            );
                        }
                    }
                });
            }
        }

        return (
            <div block="HealthCard">
                <div class="heading-exclusive">
                    <h3 block="HealthCard" elem="Title">{ __('My Discount Vouchers') }</h3>
                    <p>{ __('Discount vouchers available to use on your next purchases.') }</p>
                </div>
                <HealthCardPointsExclusiveSlider cards={cardsPoints}  bigScreenNumber="3"/>
                </div>
        );

    }

    copyToClipboard = (code) => {
        const { showSuccessNotification, showErrorNotification } = this.props;

        const elem = document.createElement('textarea');
        elem.value = code;
        document.body.appendChild(elem);
        elem.select();
        var successful = document.execCommand('copy');
        document.body.removeChild(elem);

        if (successful) {
            showSuccessNotification(__('Code Copied!'))
        } else {
            showErrorNotification(__('Something went wrong!'))
        }
    }

    renderCmsBlockExplainPoints() {
        return (
            <div block="HealthCard">
                    <CmsBlock identifier={ "health_card_explain_points" } />
            </div>
        );
    }

    renderChangePointsButtonText() {
        const { showPointsBlock } = this.state;
        if (!showPointsBlock) {
            return (
                <>
                    { __('Change Saúda Points') }
                </>
            );
        } else {
            return (
                <>
                    { __('Cancel') }
                </>
            );
        }
    }

    componentWillUnmount() {
        this.showPointsToChangeBlock(false);
    }

    showPointsToChangeBlock = (value) => {
        const { showPointsBlock } = this.state;

        localStorage.setItem('showPointsToChangeBlock', value);
        BrowserDatabase.setItem(true, 'clicked_sauda');

        window.scrollTo(0, 0);

        this.setState( { showPointsBlock: value } );
    }

    renderPointsBlock(){
        const { saudaCardMatrix, saudaCardPoints } = this.props;

        if (saudaCardMatrix) {
            return (
                <>
                    <div block="HealthCard" elem="Points">
                        <p>{ __('You have ') }<span>
                            {saudaCardPoints && saudaCardPoints.points ? saudaCardPoints.points : 0}
                        </span> { __('points available!') }</p>
                    </div>
                    <div block="HealthCard">
                        <div>
                            <h3 block="HealthCard" elem="Title">{ __('Change Points to Vouchers') } <FontAwesomeIcon className="HealthCard-InfoVoucher" icon={ faInfoCircle } title={ __("The voucher will be valid for 1 year after its generation.") } /></h3>
                            { this.renderMatrix() }
                        </div>
                    </div>
                    <div block="HealthCard">
                        <div>
                            <h3 block="HealthCard" elem="Title">{ __('Change Points to Products') }</h3>
                            <HealthCardProductList
                                 saudaCardMatrix = { saudaCardMatrix } 
                                />
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div block="HealthCard">
                        <div class="row">
                            { __('Vouchers are not available.') }
                        </div>
                    </div>
                </>
            );
        }
    }

    renderMatrix(){
        const {
            saudaCardMatrix,
            saudaCardPoints,
            showInfoPopupVoucher
        } = this.props;

        let cards = [];

        let saudaExistingPoints = parseInt(saudaCardPoints.points);

        if (saudaCardMatrix.length > 0) {
            saudaCardMatrix.forEach((matrix) => {
                if (matrix.type == 'Voucher') {

                    let matrixPoints = parseInt(matrix.points);

                    let button = {};

                    if (saudaExistingPoints > matrixPoints) {
                        button = <button
                                        block="HealthCardPoints"
                                        elem="ButtonChangePoints"
                                        mix={ { block: 'Button' } }
                                        onClick={ () => this.changePointsToVoucher(saudaCardPoints.cardNumber, matrix) }
                                    >
                                        <p
                                            block="HealthCardPoints"
                                            elem="ButtonChangePoints">
                                                { __('Generate Voucher') }
                                        </p>
                                    </button>
                    } else {
                        button = <p>{ __('You still don\'t have enough points to exchange.') }</p>;
                    }

                    cards.push(
                        <div class="card">
                            <div class="price">
                                <p class="price-item">{ matrix.value }€</p>
                            </div>
                            <div class="points">
                                <p>{ matrix.points } { __('points') }</p>
                            </div>
                            <div class="actions">
                                {button}
                            </div>
                        </div>
                    );
                }
            });
        }
        /*return (
            <div class="cards-matrix">
                { cards }
            </div>
        );*/
        return (
            <>
                <HealthCardVouchersSlider cards={cards} />
                <Popup
                    id={ 'GenerateVoucher' }
                    clickOutside={ true }
                    mix={ { block: 'GenerateVoucher' } }
                    closedOn={ false }
                >
                    <FontAwesomeIcon className="HealthCard-Close" onClick={ showInfoPopupVoucher } icon={ faTimes } />
                    <div block="GenerateVoucher" elem="Popup-Content">
                        <CmsBlock identifier={ "GenerateVoucher" } />
                        <p>{ __('After generating the voucher, it is immediately available for use and the corresponding points will be withdrawn from your account.') }</p>
                        <div class="actions">
                            <button block="HealthCardPoints"
                                    elem="ButtonChangePointsPopup"
                                    mix={ { block: 'Button' } }
                                    onClick={ () => this.confirmSubmitVoucher() }>
                                <p
                                    block="HealthCardPoints"
                                    elem="ButtonChangePointsPopup">
                                    { __('Yes, I want to generate the voucher') }
                                </p>
                            </button>
                            <button block="HealthCardPoints"
                                    elem="ButtonCancelChangePointsPopup"
                                    mix={ { block: 'Button' } }
                                    onClick={ showInfoPopupVoucher }>
                                <p
                                    block="HealthCardPoints"
                                    elem="ButtonChangePointsPopup">
                                    { __('Cancel') }
                                </p>
                            </button>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }


    changePointsToVoucher = (cardNumber, voucher) => {
        const  { showInfoPopupVoucher } = this.props;

        this.setState({
            currentVoucher: voucher,
            currentCardNumber: cardNumber
        });

        showInfoPopupVoucher();
    }


    confirmSubmitVoucher = () => {
        const  { submitPointsToVoucher, showInfoPopupVoucher } = this.props;

        submitPointsToVoucher(this.state.currentCardNumber, this.state.currentVoucher);
        showInfoPopupVoucher();
    }


    renderSmsForm(button) {
        const { showInfoPopupPhone, customer } = this.props;
        const { phoneNumber } = this.state;

        return (
            <>
                <h1 block="MyAccount" elem="Heading  Title-HealthCard" mods={{SmsForm:true}}>{ button }{ __('Sign up / Add Sauda Card') }</h1>
                <HealthCardSms
                    manageShowForm={ this.manageShowForm }
                    phoneNumber={ phoneNumber }
                    setPhoneNumber={ this.setPhoneNumber }
                    showPopupPhone={ showInfoPopupPhone }
                    setCardDetails={ this.setCardDetails }
                    customer={ customer }
                />
                <Popup
                    id={ 'HealthCardPhone' }
                    clickOutside={ true }
                    mix={ { block: 'HealthCardPhone' } }
                    closedOn={ false }
                >
                    <FontAwesomeIcon className="HealthCard-Close" onClick={ showInfoPopupPhone } icon={ faTimes } />
                    <div block="HealthCard" elem="Popup-Content">
                        <CmsBlock identifier={ "HealthCardPhone" } />
                    </div>
                </Popup>
            </>
        );
    }


    renderAssociateForm(button) {
        const { cardDetails, positions } = this.state;
        return (
            <>
                <h1 block="MyAccount" elem="Heading">{ button }{ __('Associate Sauda Card') }</h1>
                <HealthCardAssociateForm
                    cardDetails={ cardDetails }
                    positions={ positions }
                    getCardNumbersConfirmation={ this.getCardNumbersConfirmation }
                    setCardPostion={ this.setCardPostion }
                    />
            </>
        );
    }

    getCardNumbersConfirmation = () => {
        const { showErrorNotification } = this.props;
        const { positionFirst, positionSecond, positionThird } = this.state;

        if (positionFirst !== '' && positionSecond !== '' && positionThird !== '') {
            this.doQueryAssociateSaudaCard();
        } else {
            showErrorNotification(__('The numbers you provided are incorrect. Please check and try again.'));
        }
    }

    doQueryAssociateSaudaCard(){
        const { cardDetails, positions, positionFirst, positionSecond, positionThird } = this.state;
        let positionsValidation = {
            positionFirst,
            positionSecond,
            positionThird
        }
        const { showSuccessNotification, showErrorNotification, showHideForm, setLoading, updatePage, setAssociationFinish } = this.props;
        setLoading(true);
        const query = MyAccountQuery.sendAssociateSaudaCard(cardDetails, positions, positionsValidation);
            return fetchQuery(query).then(
                (response) => {
                    setLoading(false);
                    
                    if(response.associateSaudaCard.status != 500){
                        showSuccessNotification(response.associateSaudaCard.message);
                        this.setState({
                            showCreateNewCard: false,
                            showAssociateCard: false,
                            showSuccessAssociate: true
                        });
                        setAssociationFinish(false);
                    }else{
                        showErrorNotification(response.associateSaudaCard.message);
                    }
                    //showHideForm();
                    //updatePage();

                },
                (error) => {
                    setLoading(false);
                    showErrorNotification(response.associateSaudaCard.message);
                    //showHideForm();
                    this.setState({
                        showCreateNewCard: false,
                        showAssociateCard: true
                    });
                    console.log(error)
                    setAssociationFinish(false);
                }
            );
    }

    setCardPostion = (position, value) => {
        if (position == 1) {
            this.setState({positionFirst: value})
        }
        if (position == 2) {
            this.setState({positionSecond: value})
        }
        if (position == 3) {
            this.setState({positionThird: value})
        }
    }

    setPhoneNumber = (phone) => {
        this.setState({ phoneNumber: phone });
    }

    setCardDetails = (details, positions) => {
        this.setState({
            cardDetails: details,
            positions: positions,
            showCreateNewCard: false,
            showAssociateCard: true
        });
    }

    manageShowForm = () => {
        const { showCreateNewCard } = this.state;
        if (!showCreateNewCard) {
            this.setState({
                showCreateNewCard: true,
                showAssociateCard: false
            });
        }
    }

    backButton = () => {
        const { showCreateNewCard } = this.state;
        if (showCreateNewCard) {
            this.setState({
                showCreateNewCard: true,
                showAssociateCard: false
            });
        }
    }

    hideSuccessAssociate = () => {
        const { requestSaudaMatrix, requestSaudaPoints, showEditForm, updateCustomer } = this.props;
        showEditForm();
        this.setState({ showSuccessAssociate: false });
        requestSaudaMatrix();
        requestSaudaPoints();
    }

    renderShowSuccessAssociate = (button) => {
        const { device } = this.props;

        let flagIsLoading = false;

        if(localStorage.getItem('updatingPharmacyContext')){
            if(localStorage.getItem('updatingPharmacyContext') != 'false'){
                flagIsLoading = true;
            }
        }

        if( !device.isMobile ) {

            return (
                <>
                    <Loader isLoading={ flagIsLoading } />
                    <h1 block="MyAccount" elem="Heading">{ button }{ __('Associate Sauda Card') }</h1>
                    <div block="HealthCard">
                        <div class="message-success">
                                <h3>{ __('Congratilations!') }</h3>
                                <p>
                                    { __('Your Sauda Card was associated with your account with success!') }
                                </p>
                                <p>
                                    { __('Now you can use the application with your orders and have some advantages from your Sauda Card.') }
                                </p>
                                <p>
                                    <strong>
                                        { __('You will receive an confirmation e-mail about your Sauda Card.') }
                                    </strong>
                                </p>
                        </div>
                        { this.renderSuccessActions() }
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Loader isLoading={ flagIsLoading } />
                    <h1 block="MyAccount" elem="Heading">{ button }{ __('Associate Sauda Card') }</h1>
                    <div block="HealthCard">
                        <div>
                            <h3>{ __('Congratilations!') }</h3>
                            <p>
                                { __('Your Sauda Card was associated with your account with success!') }
                            </p>
                            <p>
                                { __('Now you can use the application with your orders and have some advantages from your Sauda Card.') }
                            </p>
                            <p>
                                <strong>
                                    { __('You will receive an confirmation e-mail about your Sauda Card.') }
                                </strong>
                            </p>
                        </div>
                        <div>
                            <Image
                                src={ cartaoSaudaSuccess }
                                width="auto"
                                height="auto"
                            />
                        </div>
                        { this.renderSuccessActions() }
                    </div>
                </>
            );
        }
    }

    hideSuccessCreate = () => {
        const { requestSaudaMatrix, requestSaudaPoints, changeCreateCardSuccessState, requestCustomerData } = this.props;
        changeCreateCardSuccessState();
        requestSaudaMatrix();
        requestSaudaPoints();
        requestCustomerData();
    }

    renderShowSuccessCreate = (button) => {
        const { device } = this.props;

        if( !device.isMobile ) {

            return (
                <>
                    <h1 block="MyAccount" elem="Heading">{ button }{ __('Associate Sauda Card') }</h1>
                    <div block="HealthCard">
                        <div class="message-success">
                                <h3>{ __('Congratilations!') }</h3>
                                <p>
                                    { __('Your Sauda Card was created with success!') }
                                </p>
                                <p>
                                    { __('Now you can use the application with your orders and have some advantages from your Sauda Card.') }
                                </p>
                                <p>
                                    <strong>
                                        { __('You will receive an confirmation e-mail about your Sauda Card.') }
                                    </strong>
                                </p>
                        </div>
                        { this.renderSuccessActions() }
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1 block="MyAccount" elem="Heading">{ button }{ __('Associate Sauda Card') }</h1>
                    <div block="HealthCard">
                        <div>
                            <h3>{ __('Congratilations!') }</h3>
                            <p>
                                { __('Your Sauda Card was created with success!') }
                            </p>
                            <p>
                                { __('Now you can use the application with your orders and have some advantages from your Sauda Card.') }
                            </p>
                            <p>
                                <strong>
                                    { __('You will receive an confirmation e-mail about your Sauda Card.') }
                                </strong>
                            </p>
                        </div>
                        <div>
                            <Image
                                src={ cartaoSaudaSuccess }
                                width="auto"
                                height="auto"
                            />
                        </div>
                        { this.renderSuccessActions() }
                    </div>
                </>
            );
        }
    }

    onClickSeePharma () {
        const { campaignAttrId, showPharacySelectorPopup, requestStores } = this.props;
        const link = `/catalogo.html?customFilters=sauda_offers:${campaignAttrId}`;
        BrowserDatabase.setItem(link, 'redirect_to');
        BrowserDatabase.setItem(true, 'clicked_sauda');
        requestStores();
        showPharacySelectorPopup();
    }

    renderSuccessActions() {
        const { favStoreObj: { online_store_active, sauda }, updatePage, campaignAttrId } = this.props;
        //updatePage();

        if(online_store_active && sauda) {
            const link = `/catalogo.html?customFilters=sauda_offers:${campaignAttrId}`;
            return (
                <div block="HealthCard" elem="SuccessButtons">
                    <Link to={ link } block="MyAccount" elem="Button">{ __('Start Buying!') }</Link>
                    <Link to="/my-account/healthcard" block="HealthCard" elem="SkipButton" onClick={ () => window.location.reload() }>{ __('Skip this step') }</Link>
                </div>
            );
        }

        return (
            <div block="HealthCard" elem="SuccessButtons">
                <button onClick={ () => this.onClickSeePharma() } block="MyAccount" elem="Button" mix={ { block: 'Button' } } >
                    { __('See Pharmacies') }
                </button>
                <Link to="/my-account/healthcard" block="HealthCard" elem="SkipButton" onClick={ () => window.location.reload() }>{ __('Skip this step') }</Link>
            </div>
        );
    }

    render() {
        const { title, showHideForm, showEditForm, showSuccessCreate, device }  = this.props;
        const { showCreateNewCard, showAssociateCard, showSuccessAssociate } = this.state;

        let button;

        if (showSuccessAssociate) {
            button = <button
                        block="MyAccount"
                        elem="BackButton"
                        onClick={ () => this.hideSuccessAssociate() }
                    >
                    <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                    { __('Back') }
                </button>
            return (
                <>
                    { this.renderShowSuccessAssociate(button) }
                </>
            );
        }

        if (showSuccessCreate) {
            button = <button
                        block="MyAccount"
                        elem="BackButton"
                        onClick={ () => this.hideSuccessCreate() }
                    >
                    <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                    { __('Back') }
                </button>
            return (
                <>
                    { this.renderShowSuccessCreate(button) }
                </>
            );
        }

        if(showHideForm) {
            button = <button
                    block="MyAccount"
                    elem="BackButton"
                    onClick={ showEditForm }
                >
                    <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                    <span>{ __('Back') }</span>
                </button>
        }
        if (showCreateNewCard) {
            return (
                <>
                    { showHideForm ? this.renderPageManager(button) : this.renderContent(button) }
                </>
            );
        }

        if (showAssociateCard) {
            return (
                <>
                    { showHideForm ? this.renderAssociateForm(button) : this.renderContent(button) }
                </>
            );
        }

        return (
            <>
                { showHideForm ? this.renderSmsForm(button) : this.renderContent(button) }
            </>
        );
    }
}

export default HealthCard;
