import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './HealthCardPointsExclusiveSlider.style';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faChevronCircleRight, faChevronCircleLeft } from '@fortawesome/pro-light-svg-icons';

class HealthCardPointsExclusiveSlider extends PureComponent {
    static propTypes = {
        cards: PropTypes.arrayOf(PropTypes.string).isRequired,
        bigScreenNumber: PropTypes.string.isRequired
    };

    renderRightArrow(){
        return (
            <div>
                <FontAwesomeIcon class="vouchers-arrow-right" icon={faChevronCircleRight} />
            </div>
        );
    }

    renderLeftArrow(){
        return (
            <div>
                <FontAwesomeIcon class="vouchers-arrow-left" icon={faChevronCircleLeft} />
            </div>
        );
    }

    render() {
        const { cards, bigScreenNumber } = this.props;

        if (cards.length > 0) {
            var settings = {
                dots: false,
                arrows: true,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                adaptiveHeight: true,
                nextArrow: this.renderRightArrow(),
                prevArrow: this.renderLeftArrow(),
                responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: true,
                        arrows: false
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                      }
                    }
                  ]
              };
            return (
                <div class="custom-slick-exclusive-points">
                    <Slider {...settings}>
                        { cards }
                    </Slider>
                </div>
            );
        } else {
            return (
                <div class="custom-slick-exclusive-points">
                    <h3>{ __('You does not have any vouchers to use!') }</h3>
                </div>
            );
        }
    }
}

export default HealthCardPointsExclusiveSlider;
