// TODO update this import when action is done
import { action, getCardMatrix, updateSaudaCardMatrix, getCardPoints, setIsFetching, updateSaudaDiscount, isFetchingBuyProductWithPoints, isFetchingChangeCampaign } from 'Store/CheckoutSauda/CheckoutSauda.action';


import CheckoutSaudaQuery from 'Query/CheckoutSauda.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher, executePost } from 'Util/Request';
import { prepareQuery, prepareMutation } from 'Util/Query';
import CartQuery from 'Query/Cart.query';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);


export class CheckoutSaudaDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    getSaudaCardDetails(dispatch) {
        //const query = CheckoutSaudaQuery.getSaudaCardDetails();

        const query = CheckoutSaudaQuery.getQuery();

        return executePost(prepareQuery([query])).then(
            ({ saudaDetails }) => {
                //dispatch(getSaudaCardDetails(saudaDetails));
            },
            error => console.log('error', error[0].message)
        );
    }

    getSaudaCardPoints(dispatch) {
        //const query = CheckoutSaudaQuery.getSaudaCardDetails();

        const query = CheckoutSaudaQuery.getSaudaCardPoints();
        dispatch(setIsFetching(true));

        return executePost(prepareQuery([query])).then(
            ({ getSaudaCardPoints }) => {
                dispatch(getCardPoints(getSaudaCardPoints));
                dispatch(setIsFetching(false));
            },
            error => console.log('error', error[0].message)
        );
    }

    getSaudaCardMatrix(dispatch) {
        //const query = CheckoutSaudaQuery.getSaudaCardDetails();

        const query = CheckoutSaudaQuery.getSaudaCardMatrix();

        return executePost(prepareQuery([query])).then(
            ({ getSaudaCardMatrix }) => {
                dispatch(getCardMatrix(getSaudaCardMatrix));
            },
            error => console.log('error', error[0].message)
        );
    }

    updateSaudaDiscount(dispatch, discount, ref, points) {
        const query = CheckoutSaudaQuery.updateSaudaCardMutation(discount, ref, points);

        /*const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerPhoto(customerPhoto));
        }*/


        return executePost(prepareQuery([query])).then(
            /** @namespace Store/CheckoutSauda/Dispatcher/updateSaudaDiscountThen */
            ({ discount }) => {
                CartDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
                )
                //dispatch(updateSaudaDiscount(discount));
            },
            /** @namespace Store/CheckoutSauda/Dispatcher/updateSaudaDiscountThen */
            (error) => dispatch(showNotification('error', error[0].message))
        );
    }

    productWithPoints(dispatch, sku, points) {
        dispatch(isFetchingBuyProductWithPoints(true));
        const query = CheckoutSaudaQuery.updateProductWithPoints(sku, points);

        return executePost(prepareQuery([query])).then(
            /** @namespace Store/CheckoutSauda/Dispatcher/updateSaudaDiscountThen */
            ({ buyProductWithPoints }) => {
                dispatch(isFetchingBuyProductWithPoints(false));
                CartDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
                )
                //dispatch(updateSaudaDiscount(discount));
            },
            /** @namespace Store/CheckoutSauda/Dispatcher/updateSaudaDiscountThen */
            (error) => {
                dispatch(isFetchingBuyProductWithPoints(false));
                dispatch(showNotification('error', error[0].message));
            }
        );
    }

    changeCampaignStatus(dispatch, item_id) {
        dispatch(isFetchingChangeCampaign(true));
        const mutation = CartQuery.getChangeCampaignStatusMutation(item_id);
        return executePost(prepareMutation([mutation])).then(
            /** @namespace Store/CheckoutSauda/Dispatcher/updateSaudaDiscountThen */
            ({ changeCampaignStatus }) => {
                dispatch(isFetchingChangeCampaign(false));
                if(changeCampaignStatus.status && changeCampaignStatus.status == '200') {
                    CartDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
                    )
                } else {
                    dispatch(showNotification('error', changeCampaignStatus.message));
                }
            },
            /** @namespace Store/CheckoutSauda/Dispatcher/updateSaudaDiscountThen */
            (error) => {
                dispatch(isFetchingChangeCampaign(false));
                dispatch(showNotification('error', error[0].message));
            }
        );
    }

    onSuccess({ matrix }, dispatch) {
        //dispatch(updateSaudaCardMatrix(matrix));
    }

    onError(error, dispatch, options) {
        dispatch(showNotification('error', 'Error fetching CheckoutSauda!', error));
    }

    prepareRequest(options) {
        // TODO implement query retrieval
        // return CheckoutSaudaQuery.getQuery(options);
    }
}

export default new (CheckoutSaudaDispatcher)();
