/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */
import StorePageReducer from './StorePage.reducer';
import StorePageDispatcher from './StorePage.dispatcher';

import {
    UPDATE_STORE_PAGE,
    updateStorePage,
    UPDATE_FAVORITE_PHARMACY,
    updateFavStore,
    updateLoading
} from './StorePage.action';

export {
    StorePageReducer,
    StorePageDispatcher,
    UPDATE_STORE_PAGE,
    updateStorePage,
    UPDATE_FAVORITE_PHARMACY,
    updateFavStore,
    updateLoading
};
