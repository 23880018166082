/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import { PureComponent } from 'react';
import './StorePage.style';
import CmsBlock from 'Component/CmsBlock';
import Image from 'Component/Image';
import PropTypes from 'prop-types';
import Overlay from 'Component/Overlay';
import { DeviceType } from 'Type/Device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faDirections, faClock } from '@fortawesome/pro-solid-svg-icons';
import Popup from 'Component/Popup';
import PharmacySchedule from 'Component/PharmacySchedule';
import Loader from 'Component/Loader';

import shop from 'Route/StorePage/Images/shop.png';
import truck from 'Route/StorePage/Images/truck.png';
import clock from 'Route/StorePage/Images/clock.png';
import phone from 'Route/StorePage/Images/phone.png';
import store from 'Route/StorePage/Images/store.png';
import list from 'Route/StorePage/Images/list.png';
import infos from 'Route/StorePage/Images/infos.png';
import arrowright from 'Route/StorePage/Images/arrowright.png';
import arrowdown from 'Route/StorePage/Images/arrowdown.png';
import arrowUp from 'Route/StorePage/Images/arrowUp.png';
import pin from 'Route/StorePage/Images/pin.png';
import arrowLeft from 'Route/StorePage/Images/arrowLeft.png';
import close from 'Route/StorePage/Images/close.png';
import image from 'Route/StorePage/Images/banner_pharmacy_default.jpg';
import { customerType } from 'Type/Account';
import {Redirect} from "react-router";
import delete_icon from 'Component/MyAccountAddressTable/images/icon_delete_modal.png';
import HomePage from 'Route/HomePage';

/** @namespace Route/SearchPage/Component */
export class StorePage extends PureComponent {

    static propTypes = {
        OpenScheduleMenu: PropTypes.bool.isRequired,
        OpenServicesMenu: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        onInfoClick: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        updateContextPharmacy: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        removeContextPharmacy: PropTypes.func.isRequired
    };

    state = {
        coordinates: null,
        showPopup:false,
        clickedPharmacy: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        navigator.geolocation.getCurrentPosition(position => {
            let coords = [position.coords.latitude,position.coords.longitude];

            this.setState({coordinates:coords});
        });
    }

    distance(lat1, lon1, lat2, lon2, unit) {
        let radlat1 = Math.PI * lat1/180;
        let radlat2 = Math.PI * lat2/180;
        let theta = lon1-lon2;
        let radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344; }
        if (unit=="N") { dist = dist * 0.8684; }
        return dist;
    }

    renderCloseButton() {

        const { onInfoClick } = this.props;

        return(
            <div
                onClick={ onInfoClick }
                block="StorePage"
                elem="ClosePopup"
                >
                <Image
                    src = {close}
                    block="StorePage"
                    elem="ClosePopup"
                />
            </div>
        );
    }


    renderStoreHeading() {

        const {
            storeObj,
            storeObj: { name },
            OpenSheduleMenu,
            OpenServicesMenu,
            ScheduleMenu,
            ServicesMenu,
            OpenMenuMobile,
            MenuMobile,
            baseLinkUrl,
            onInfoClick,
            InfoMenu,
            updateContextPharmacy
        } = this.props;

        if(!Object.entries(storeObj).length){
            return null;
        }

        let media = '/media/';
        let mediaUrl = baseLinkUrl.concat(media);
        let url = mediaUrl.concat(storeObj.logo);
        let urlBanner;
        if(storeObj.photo != null) {
            urlBanner = mediaUrl.concat(storeObj.photo);
        }else {
            urlBanner = image;
        }

        let distance2;

        if( this.state.coordinates== null ) {
            distance2 = false;
        } else {
            distance2 = this.distance(this.state.coordinates[0], this.state.coordinates[1], storeObj.latitude, storeObj.longitude,'K');
        }

        let finalDistance = Math.round(distance2 * 100) / 100;

        return (
            <div block="StorePage" elem="Menus">
                {/* <div
                    block="StorePage" elem="BackgroundImage"
                    style={{backgroundImage: `url(${urlBanner})`  }}
                >
                </div> */}

                <div block="StorePage" elem="PharmacyColumns">
                   {/*  <Image
                        src = {url}
                        width="130px"
                        height="90px"
                    /> */}
                    <div block="StorePage" elem="PharmacyInfo">
                        {/* <div block="StorePage" elem="PharmacyInfoLine1">
                            <h1
                                block="StorePage"
                                elem="Heading"
                                mix={ {
                                    block: 'StorePage',
                                    elem: 'Heading'
                                } }
                                >
                                    { name }
                            </h1>
                            {distance2 &&<div block="StorePage" elem="PharmacyStreetName">
                                <Image
                                    src={ pin }
                                    width="20px"
                                    height="20px"
                                />
                                <h4
                                    block="StorePage"
                                    elem="PharmacyDistance"
                                    >
                                        { finalDistance } Km
                                </h4>
                                <h4
                                    block="StorePage"
                                    elem="StreetName"
                                    >
                                        { storeObj.street_name }
                                </h4>
                            </div>}
                            {!distance2 &&<div block="StorePage" elem="PharmacyStreetNameWithoutPermission">
                                <Image
                                    src={ pin }
                                    width="20px"
                                    height="20px"
                                />
                                <h4
                                    block="StorePage"
                                    elem="StreetName"
                                    >
                                        { storeObj.street_name }
                                </h4>
                            </div>}
                        </div> */}
                        <div block="StorePage" elem="PharmacyInfoLine2">
                            {/* <div block="StorePage"
                                elem="info-Schedule"
                                onClick={ OpenSheduleMenu }
                                //mods={ { isVisible:this.OpenSheduleMenu}}
                            >
                                <span>
                                    <Image
                                        src={ clock }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                {storeObj.schedule_values[(storeObj.schedule_values).length - 1] && <span
                                    block="StorePage"
                                    elem="Schedule"
                                    mods={{ isVisible:ScheduleMenu }}
                                    >
                                        {__('Open today up until ')} {storeObj.schedule_values[(storeObj.schedule_values).length - 1]}
                                </span> }
                                {!storeObj.schedule_values[(storeObj.schedule_values).length - 1] && <span
                                    block="StorePage"
                                    elem="Schedule"
                                    >
                                        {__('Closed')}
                                </span> }

                                { !ScheduleMenu &&
                                <span>
                                    <Image
                                        src={ arrowdown }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>}
                                { ScheduleMenu &&
                                <span>
                                    <Image
                                        src={ arrowUp }
                                        width="20px"
                                        height="20px"
                                    />
                                </span> }
                                {  this.renderMenuOverlay(1) }
                            </div>
                            <div block="StorePage" elem="info">
                                <span>
                                    <Image
                                        src={ phone }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                <span
                                    block="StorePage"
                                    elem="Telephone"
                                    >
                                        { storeObj.telephone_secondary ? storeObj.telephone_secondary : storeObj.telephone }
                                </span>
                            </div>
                            <div block="StorePage"
                                elem="info-services"
                                onClick={ OpenServicesMenu }
                                //mods={ { isVisible:this.OpenServicesMenu}}
                            >
                                <span>
                                    <Image
                                        src={ list }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                <span
                                    block="StorePage"
                                    elem="List"
                                    mods={ { isVisible:ServicesMenu}}
                                    >
                                        {__('Services')}
                                </span>

                                { !ServicesMenu &&
                                    <span>
                                        <Image
                                            src={ arrowdown }
                                            width="20px"
                                            height="20px"
                                        />
                                    </span>
                                }
                                { ServicesMenu &&
                                    <span>
                                        <Image
                                            src={ arrowUp }
                                            width="20px"
                                            height="20px"
                                        />
                                    </span>
                                    }

                                {  this.renderMenuOverlay(2) }
                            </div>
                            <div block="StorePage" elem="info"  onClick={ () => onInfoClick(storeObj)  }>
                                <span>
                                    <Image
                                        src={ infos }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                <span
                                    block="StorePage"
                                    elem="Info"
                                    mods={ { isVisible:InfoMenu}}
                                >
                                    {__('Info')}
                                </span>
                            </div>*/}
                            { /*this.renderPharmacyPreference('desktop')*/ }
                        </div>
                    </div>
                </div>
                <div block="StorePage" elem="PharmacyColumnsMobile">
                    { /*this.renderPharmacyPreference('desktop')*/ }
                    {/* <Image
                        src={ url }
                        width="130px"
                        height="90px"
                    />
                    <div block="StorePage" elem="PharmacyInfo">
                        <div block="StorePage" elem="PharmacyInfoLine1">
                            <h3
                                block="StorePage"
                                elem="Heading"
                                mix={ {
                                    block: 'StorePage',
                                    elem: 'Heading'
                                } }
                                >
                                    { name }
                            </h3>
                            <div block="StorePage" elem="PharmacyInfoMenu" onClick={ OpenMenuMobile }>
                                <h4
                                    block="StorePage"
                                    elem="StreetName"
                                    >
                                        {__('Pharmacy Info')}
                                </h4>
                                { !MenuMobile &&<Image
                                    src={ arrowdown }
                                    width="20px"
                                    height="24px"
                                />}
                                { MenuMobile &&<Image
                                    src={ arrowUp }
                                    width="20px"
                                    height="24px"
                                />}
                            </div>
                        </div>
                    </div> */}
                </div>
                { /* this.renderMenuOverlayMobile(3) */ }
            </div>
        );
    }

    renderPharmacyPreference(type) {
        const { customer, storeObj, updateContextPharmacy, showCartPopup, cartTotals: { items_qty } } = this.props;

        if(!storeObj.online_store_active) {
            return;
        }

        let favouriteCode = null;
        if (customer && customer.favourite_pharmacy_code) {
            let favouriteCode = customer.favourite_pharmacy_code;
        }

        let elementBlock = 'info';
        let elementH4 = 'Pharmacy';
        if(type == 'mobile'){
            elementBlock = 'PharmacyMobileSchedule';
            elementH4 = 'PharmacyMobile';
        }

        if(favouriteCode && (favouriteCode == storeObj.pharmacy_code)){
            return (
                <div block="StorePage" elem={elementBlock}>
                                <span>
                                    <Image
                                        src={ store }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                <span
                                    block="StorePage"
                                    elem={elementH4}
                                >
                                    {__('Favourite Pharmacy')}
                                </span>
                            </div>
            );
        } else {
            return (
                <div block="StorePage" elem={elementBlock} onClick={ () => !items_qty ? updateContextPharmacy() : showCartPopup() }>
                                <span>
                                    <Image
                                        src={ store }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                <span
                                    block="StorePage"
                                    elem={elementH4}
                                >
                                    {__('Change my pharmacy')}
                                </span>
                                <span>
                                    <Image
                                        src={ arrowright }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                            </div>
            );
        }
    }

    prepareHours(store) {
        const { schedule } = store;

        if (!schedule) {
            return __('No info about hours');
        }

        return (
            <strong>{ schedule }</strong>
        );
    }

    renderInfoPopup() {

        const { storeObj, hideActiveOverlay,changeStatusPopup,showPopup,InfoMenu } = this.props;

        if(!Object.entries(storeObj).length) {
            return null;
        }

        let distance2;

        if( this.state.coordinates== null ) {
            distance2= false;
        } else {
            distance2 = this.distance(this.state.coordinates[0], this.state.coordinates[1], storeObj.latitude, storeObj.longitude,'K');
        }

        let finalDistance = Math.round(distance2 * 100) / 100;

        let showDistance=false;

        if(finalDistance == 0) {
            showDistance = false;
        } else {
            showDistance = true;
        }

        const {
            name,
            street_name,
            all_schedules,
            number,
            locality,
            postal_code_zone,
            postal_code_sufix,
            owner,
            technical_team,
            telephone,
            telephone_secondary,
            schedule_values,
            status
        } = storeObj;

        let address = `${street_name} ${number}`;
        let postCode = `${postal_code_zone}-${postal_code_sufix} ${locality}`;

        let storeStatusLabel = __('Closed');
        let storeSaturdays = __('Closed');
        let storeSundays = __('Closed');
        let storeWeekdays = __('Closed');

        let telephoneToShow = telephone_secondary ? telephone_secondary : telephone;

        if(status == 'open' || status == 'open-shift') {
            storeStatusLabel = <>{ __("Open today until ") } { schedule_values[schedule_values.length-1] }</>;
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        if(all_schedules.saturdays_start !== null) {
            storeSaturdays = <>{ all_schedules.saturdays_start } - { all_schedules.saturdays_end }</>;
        }

        if(all_schedules.sunday_start !== null) {
            storeSundays = <>{ all_schedules.sunday_start } - { all_schedules.sunday_end }</>;
        }

        if(all_schedules.holidays_start !== null) {
            storeWeekdays = <>{ all_schedules.holidays_start } - { all_schedules.holidays_end }</>;
        }
        
        let resumeFormated = '';
        if(storeObj.resume){
            resumeFormated = storeObj.resume.replaceAll('\n','<br>');
        }

        let teamFormated = '';

        if(technical_team){
            teamFormated = technical_team.replaceAll('\n','<br>');
        } else {
            teamFormated = __('No Team');
        }

        return (
            <div>
                {InfoMenu &&<Popup
                    id={ 'MyAccountAddressPopup' }
                    clickOutside={ false }
                    mix={ { block: 'MyAccountAddressPopup' } }
                    closedOn = { false }
                //   hideActiveOverlay = { hideActiveOverlay }
                >
                    {this.renderCloseButton()}
                    <div block="StorePage" elem="Content">
                        <div block="StorePage" elem="StoreInfo" mods={ { type: 'name' } }>
                            <h2>
                                { name || __('No store name') }
                            </h2>
                        </div>
                        <div block="StorePage" elem="PharmacyInfoPopup">
                            {showDistance && <div block="StorePage" elem="Address">
                                <div>
                                    <Image
                                        src={ pin }
                                        width="12px"
                                        height="12px"
                                    />
                                </div>
                                <p block="StorePage" elem="AdressDistance">
                                    { finalDistance } Km
                                </p>
                                <p block="StorePage" elem="AddressRoute">
                                    { address || __('No address') }, { postCode || __('No postcode') }
                                </p>
                            </div> }
                            {!showDistance && <div block="StorePage" elem="AddressWithoutDistance">
                                <div>
                                    <Image
                                        src={ pin }
                                        width="12px"
                                        height="12px"
                                    />
                                </div>
                                <p block="StorePage" elem="AddressRoute">
                                    { address || __('No address') }, { postCode || __('No postcode') }
                                </p>
                            </div> }

                            <div block="StorePage" elem="Contact">
                            <div>
                                <Image
                                    src={ phone }
                                    width="12px"
                                    height="12px"
                                />
                            </div>
                            <p>
                                { telephoneToShow }
                            </p>
                            <div>
                                <Image
                                    src={ clock }
                                    width="12px"
                                    height="12px"
                                />
                            </div>
                            <p>
                                { storeStatusLabel }
                            </p>
                            </div>
                        </div>
                        <div block="StorePage" elem="InfoDescription">
                            <div dangerouslySetInnerHTML={{ __html: resumeFormated }} />
                        </div>
                        <h4 block="StorePage" elem="Title">{ __("Opened Hours:") }</h4>
                        <div block="StorePage" elem="PharmacyHours">
                            { <PharmacySchedule storeObj={ storeObj } /> }
                        </div>
                        <h4 block="StorePage" elem="Title">{ __("Pharmaceutic Owner:") }</h4>
                        <div block="StorePage" elem="PharmacyOwner">
                            <p>{ owner }</p>
                        </div>
                        <h4 block="StorePage" elem="Title">{ __("Team:") }</h4>
                        <div block="StorePage" elem="PharmacyTeam">
                            <div dangerouslySetInnerHTML={{ __html: teamFormated }} />
                        </div>
                        <div block="StorePage" elem="BookElem">
                            <a
                                href="https://www.livroreclamacoes.pt/inicio"
                                target="_blank"
                            >
                                <p>{  __("Complaint Book") }</p>
                            </a>
                            {/* {  __("Complaint Book") } */}
                            <Image
                                src={ arrowright }
                                width="12px"
                                height="12px"
                            />
                        </div>
                    </div>
                </Popup>}
            </div>
        );
    }

    renderMenuOverlayMobile(MenuSelected){

        const {
            storeObj,
            OpenSheduleMenu,
            OpenServicesMenu,
            ScheduleMenu,
            ServicesMenu,
            OpenMenuMobile,
            MenuMobile,
            OpenMenuSchedule,
            MenuScheduleMobile,
            GoBack,
            OpenMenuServices,
            MenuServicesMobile,
            OpenMenuInfo,
            MenuInfoMobile
        } = this.props;

        if(!Object.entries(storeObj).length) {
            return null;
        }

        let showDistance = false;

        if(finalDistance == 0) {
            showDistance = false;
        } else {
            showDistance = true;
        }

        const {
            name,
            street_name,
            all_schedules,
            number,
            locality,
            postal_code_zone,
            postal_code_sufix,
            owner,
            technical_team,
            telephone,
            telephone_secondary,
            schedule_values,
            status,
            updateContextPharmacy
        } = storeObj;

        let address = `${street_name} ${number}`;
        let postCode = `${postal_code_zone}-${postal_code_sufix} ${locality}`;

        let storeStatusLabel = __('Closed');
        let storeSaturdays = __('Closed');
        let storeSundays = __('Closed');
        let storeWeekdays = __('Closed');

        let telephoneToShow = telephone_secondary ? telephone_secondary : telephone;

        if(status == 'open' || status == 'open-shift') {
            storeStatusLabel = <>{ __("Open today until ") } { schedule_values[schedule_values.length-1] }</>;
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        if(all_schedules.saturdays_start !== null) {
            storeSaturdays = <>{ all_schedules.saturdays_start } - { all_schedules.saturdays_end }</>;
        }

        if(all_schedules.sunday_start !== null) {
            storeSundays = <>{ all_schedules.sunday_start } - { all_schedules.sunday_end }</>;
        }

        if(all_schedules.holidays_start !== null) {
            storeWeekdays = <>{ all_schedules.holidays_start } - { all_schedules.holidays_end }</>;
        }

        if(!Object.entries(storeObj).length) {
            return null;
        }

        let services = [];

        if (storeObj.services) {
            storeObj.services.forEach((item) => {
                services.push(<p block="StorePage" elem="Service_Info">{item.name}</p>);
            });
        }

        let distance2;

        if( this.state.coordinates== null ) {
            distance2= false;
        } else {
            distance2 = this.distance(this.state.coordinates[0], this.state.coordinates[1], storeObj.latitude, storeObj.longitude,'K');
        }

        let finalDistance = Math.round(distance2 * 100) / 100;


        let resumeFormated = '';
        if(storeObj.resume){
            resumeFormated = storeObj.resume.replaceAll('\n','<br>');
        }

        let teamFormated = '';
        if(technical_team){
            teamFormated = technical_team.replaceAll('\n','<br>');
        } else {
            teamFormated = __('No Team');
        }


        if( MenuMobile ) {
            return(
                <div block="StorePage" elem="PharmacyMenuMobile">
                    { (!MenuScheduleMobile && !MenuServicesMobile && !MenuInfoMobile) && <div block="StorePage" elem="PharmacyMobileAllMenu">
                        <div block="StorePage" elem="PharmacyMobileSchedule" onClick={OpenMenuSchedule}>
                            <span>
                                <Image
                                    src={ clock }
                                    width="20px"
                                    height="20px"
                                />
                            </span>
                            {storeObj.schedule_values[(storeObj.schedule_values).length - 1] &&
                                <span
                                    block="StorePage"
                                    elem="ScheduleMobile"
                                >
                                    {__('Open today up until ')} {storeObj.schedule_values[(storeObj.schedule_values).length - 1]}
                                </span>
                            }
                            {!storeObj.schedule_values[(storeObj.schedule_values).length - 1] && <span
                                block="StorePage"
                                elem="ScheduleMobile"
                                >
                                    {__('Closed')}
                            </span> }
                            <span>
                                <Image
                                    src={ arrowright }
                                    width="20px"
                                    height="20px"
                                />
                            </span>
                        </div>
                        <div block="StorePage" elem="PharmacyMobileSchedule" onClick={OpenMenuServices}>
                            <Image
                                src={ list }
                                width="20px"
                                height="20px"
                            />
                            <h4
                                block="StorePage"
                                elem="ListMobile"
                                >
                                    {__('Services')}
                            </h4>
                            <Image
                                src={ arrowright }
                                width="20px"
                                height="20px"
                            />
                        </div>
                        <div block="StorePage" elem="PharmacyMobileSchedule" onClick={OpenMenuInfo}>
                            <Image
                                src={ infos }
                                width="20px"
                                height="20px"
                            />
                            <h4
                                block="StorePage"
                                elem="InfoMobile"
                                >
                                    {__('Info')}
                            </h4>
                            <Image
                                src={ arrowright }
                                width="20px"
                                height="20px"
                            />
                        </div>
                        <div block="StorePage" elem="PharmacyMobileDistance">
                            <Image
                                src={ pin }
                                width="20px"
                                height="20px"
                            />
                            <h4>{storeObj.street_name}</h4>
                            {distance2 && <h4 block="StorePage" elem="PharmacyDistance">{finalDistance} Km</h4>}
                        </div>
                        <div block="StorePage" elem="PharmacyMobileSchedule">
                            <Image
                                src={ phone }
                                width="20px"
                                height="20px"
                            />
                            <h4>{telephoneToShow}</h4>
                        </div>
                        { /*this.renderPharmacyPreference('mobile') */}
                    </div>}

                    { MenuScheduleMobile && <div block="StorePage">
                        <div block="StorePage" elem="PharmacyMobileScheduleBack" onClick={GoBack}>
                            <Image
                                src={ arrowLeft }
                                width="20px"
                                height="24px"
                            />
                            <h4>{__('Back')}</h4>
                        </div>

                        <div block="StorePage" elem="PharmacyMobileMenuTitle">
                            <Image
                                    src={ clock }
                                    width="20px"
                                    height="20px"
                            />
                            { storeObj.schedule_values[(storeObj.schedule_values).length - 1] && <p
                                block="StorePage"
                                elem="ScheduleMobile"
                                >
                                    {__('Open today up until ')} {storeObj.schedule_values[(storeObj.schedule_values).length - 1]}
                            </p> }

                            { !storeObj.schedule_values[(storeObj.schedule_values).length - 1] && <p
                                block="StorePage"
                                elem="ScheduleMobile"
                                >
                                    {__('Closed')}
                            </p> }
                        </div>

                        { <PharmacySchedule storeObj={ storeObj } isMobile={ true } /> }
                    </div>}

                    { MenuServicesMobile && <div block="StorePage">
                        <div block="StorePage" elem="PharmacyMobileScheduleBack" onClick={GoBack}>
                            <Image
                                src={ arrowLeft }
                                width="20px"
                                height="24px"
                            />
                            <h4>{__('Back')}</h4>
                        </div>

                        <div block="StorePage" elem="PharmacyMobileMenuTitle">
                            <Image
                                src={ list }
                                width="20px"
                                height="20px"
                            />
                            <p
                                block="StorePage"
                                elem="List"
                            >
                                {__('Services')}
                            </p>
                        </div>

                        <div block="StorePage" elem="ScheduleLineMobile">
                            <p block="StorePage" elem="Schedule_Info_Label"> {services}</p>
                        </div>
                    </div>}

                    { MenuInfoMobile && <div block="StorePage">
                        <div block="StorePage" elem="PharmacyMobileScheduleBack" onClick={GoBack}>
                            <Image
                                src={ arrowLeft }
                                width="20px"
                                height="24px"
                            />
                            <h4>{__('Back')}</h4>
                        </div>
                        <div block="StorePage" elem="Content">
                            <div block="StorePage" elem="StoreInfo" mods={ { type: 'name' } }>
                                <h2>
                                    { name || __('No store name') }
                                </h2>
                            </div>
                            <div block="StorePage" elem="PharmacyInfoPopup">
                                {showDistance && <div block="StorePage" elem="AddressMobile">
                                    <div>
                                        <Image
                                            src={ pin }
                                            width="12px"
                                            height="12px"
                                        />
                                    </div>
                                    <div block="StorePage" elem="AddressRoute">
                                        <p >
                                            { address || __('No address') }
                                        </p>
                                        <p block="StorePage" elem="AddressDistance">
                                            { finalDistance } Km
                                        </p>
                                    </div>
                                </div> }
                                {!showDistance && <div block="StorePage" elem="AddressWithoutDistance">
                                    <div>
                                        <Image
                                            src={ pin }
                                            width="12px"
                                            height="12px"
                                        />
                                    </div>
                                    <p block="StorePage" elem="AddressRoute">
                                        { address || __('No address') }
                                    </p>
                                </div> }

                                <div block="StorePage" elem="ContactMobile">
                                    <div>
                                        <Image
                                            src={ phone }
                                            width="12px"
                                            height="12px"
                                        />
                                    </div>
                                    <p>
                                        { telephoneToShow }
                                    </p>
                                    <div>
                                        <Image
                                            src={ clock }
                                            width="12px"
                                            height="12px"
                                        />
                                    </div>
                                    <p>
                                        { storeStatusLabel }
                                    </p>
                                </div>
                            </div>
                            <div block="StorePage" elem="InfoDescription">
                                <div dangerouslySetInnerHTML={{ __html: resumeFormated }} />
                            </div>
                            <h4 block="StorePage" elem="Title">{ __("Opened Hours:") }</h4>
                            <div block="StorePage" elem="PharmacyHours">
                                { <PharmacySchedule storeObj={ storeObj } isMobile={ true } /> }
                            </div>
                            <h4 block="StorePage" elem="Title">{ __("Pharmaceutic Owner:") }</h4>
                            <div block="StorePage" elem="PharmacyOwner">
                                { owner }
                            </div>
                            <h4 block="StorePage" elem="Title">{ __("Team:") }</h4>
                            <div block="StorePage" elem="PharmacyTeam">
                                <div dangerouslySetInnerHTML={{ __html: teamFormated }} />
                            </div>
                            <div block="StorePage" elem="BookElem">
                                {  __("Complaint Book") }
                                <Image
                                    src={ arrowright }
                                    width="12px"
                                    height="12px"
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            );
        }
    }

    renderMenuOverlay(MenuSelected){

        const { storeObj, device } = this.props;

        let tabMenu = "";
        switch(MenuSelected) {
            case 1:
                tabMenu = "MenuSchedule";
                break;
            case 2:
                tabMenu = "MenuServices";
                break;
        }

        if(!Object.entries(storeObj).length){
            return null;
        }

        let services = [];

        if (storeObj.services) {
            storeObj.services.forEach((item) => {
                services.push(<p block="StorePage" elem="Service_Info">{item.name}</p>);
            });
        }


        if( !device.isMobile ) {
            if( tabMenu == "MenuServices" ) {
                return(
                    <Overlay
                        id={ tabMenu }
                        clickOutside={ false }
                        mix={ { block: 'StorePage' } }
                    >
                        <div block="StorePage" elem="PharmacyInfo2">
                            { services }
                        </div>
                    </Overlay>
                );
            }

            if( tabMenu == "MenuSchedule" ){
                return(
                    <Overlay
                        id={ tabMenu }
                        clickOutside={ false }
                        mix={ { block: 'StorePage' } }
                    >
                        <div block="StorePage" elem="PharmacyInfo2">
                            { <PharmacySchedule storeObj={ storeObj } /> }
                        </div>
                    </Overlay>
                );
            }
        }
    }

    renderCategoryDetails() {
        return (
            <article block="StorePageDetails">
                <div block="StorePageDetails" elem="Description">
                    { this.renderStoreHeading() }
                </div>
            </article>
        );
    }

    renderContent() {

        const {
            MenuScheduleMobile,
            MenuServicesMobile,
            MenuInfoMobile,
            storeObj: { home_delivery }
        } = this.props;

        return (
            <>
                { this.renderInfoPopup() }
                { this.renderCategoryDetails() }
                { (!MenuInfoMobile && !MenuScheduleMobile && !MenuServicesMobile) &&<div
                    block="StorePage"
                    elem="ContentPharmacyMobileHidden"
                    >
                    <CmsBlock identifier={ "bloco-1" } />
                    <CmsBlock identifier={ "bloco-2" } />
                    <div
                        block="StorePage"
                        elem="DesktopHide"
                    >
                        <div
                            block="StorePage"
                            elem="Title1"
                        >
                            <h3>{__('After choice your product you can choice best way to receive')}</h3>
                        </div>
                        <div
                            block="StorePage"
                            elem="Title2"
                        >
                            <p>{__('Your pharmacy give all of this choices')}</p>
                        </div>

                        <div
                            block="StorePage"
                            elem="icons"
                        >
                            { home_delivery ? <div
                                block="StorePage"
                                elem="truck"
                            >
                                <Image
                                    src={ truck }
                                    width="100px"
                                    height="100px"
                                />
                                <h4>{__('Home delivery')}</h4>
                            </div> : '' }
                            <div
                                block="StorePage"
                                elem="shop"
                            >
                                <Image
                                    src={ shop }
                                    width="100px"
                                    height="100px"
                                />
                                <h4>{__('Store pickup')}</h4>
                            </div>
                        </div>
                        <div
                            block="StorePage"
                            elem="Description"
                        >
                            <p>{__('Any question, beyond ours help channel, you can contact direct your pharmacy')}</p>
                        </div>
                    </div>
                    <div
                        block="StorePage"
                        elem="MobileHide"
                    >
                        <div
                            block="StorePage"
                            elem="Title1"
                        >
                            <h4>{__('After choice your product you can choice best way to receive')}</h4>
                        </div>

                        <div
                            block="StorePage"
                            elem="Title2"
                        >
                            <p>{__('Your pharmacy give all of this choices')}</p>
                        </div>

                        <div
                            block="StorePage"
                            elem="icons-mobile"
                        >
                            { home_delivery ? <div
                                block="StorePage"
                                elem="truck"
                            >
                                <Image
                                    src={ truck }
                                    width="100px"
                                    height="100px"
                                />
                                <h4>{__('Home delivery')}</h4>
                            </div> : '' }
                            <div
                                block="StorePage"
                                elem="shop"
                            >
                                <Image
                                    src={ shop }
                                    width="100px"
                                    height="100px"
                                />
                                <h4>{__('Store pickup')}</h4>
                            </div>
                        </div>
                        <div
                            block="StorePage"
                            elem="Description"
                        >
                            <p>{__('Any question, beyond ours help channel, you can contact direct your pharmacy')}</p>
                        </div>
                    </div>
                </div>}
            </>
        );
    }

    onPopupChangeStore() {
        const { updateContextPharmacy, showCartPopup } = this.props;
        updateContextPharmacy();
        showCartPopup();
    }

    render() {
        const { storeObj, isLoadingStore, getStoreId, removeContextPharmacy } = this.props;
        const { clickedPharmacy } = this.state;

        if (isLoadingStore || getStoreId() !== storeObj.pharmacy_code) {
            return <><Loader isLoading={ true }/></>;
        }

        if (storeObj && storeObj.online_store_active == 0) {
            localStorage.removeItem('guest_pharmacy_code');
            removeContextPharmacy();
            return <Redirect to="/farmacia-n-o-esta-existe" />;
        }

        return (
            <HomePage { ...this.props } />
        );

        /*return (
            <main block="StorePage">
                <ContentWrapper
                    wrapperMix={ {
                        block: 'StorePage',
                        elem: 'Wrapper'
                    } }
                    label="StorePage page"
                >
                    <Popup
                        id={ 'CartItemsAlert' }
                        clickOutside={ false }
                        mix={ { block: 'CartItemsAlert' } }
                        closedOn={ false }
                    >
                        <header class="Popup-Header">
                            <h3 class="Popup-Heading">
                                <Image src={ delete_icon } width="35px" height="35px" />
                                <br/>
                                { __('By changing pharmacy your cart may be changed.') }
                            </h3>
                        </header>
                        <p>{ __('Do you wish to continue?') }</p>
                        <div block="CartItemsAlert" elem="Actions" >
                            <button
                                block="CartItemsAlert"
                                elem="PrimaryButton"
                                mix={ { block: 'Button' } }
                                onClick={ () => this.onPopupChangeStore() }
                            >{ __('Yes') }</button>
                        </div>
                        <div block="CartItemsAlert" elem="Actions">
                            <button
                                block="CartItemsAlert"
                                elem="SecondaryButton"
                                mix={ { block: 'Button' } }
                                onClick={ () => showCartPopup() }
                            >{ __('Cancel') }</button>
                        </div>
                    </Popup>
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );*/
    }
}

export default StorePage;
