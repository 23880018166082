import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './CheckoutSignin.style';
import ContentWrapper from 'Component/ContentWrapper';
import { customerType } from 'Type/Account';
import { DeviceType } from 'Type/Device';
import { isSignedIn } from 'Util/Auth';
import CheckoutMyAccountOverlay from 'Component/CheckoutMyAccountOverlay';


class CheckoutSignin extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        customer: customerType.isRequired,
        hideOverlay: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        activeTab: PropTypes.string.isRequired,
        isMyAccount: PropTypes.bool,
        onSignIn: PropTypes.func.isRequired,
        createAccount: PropTypes.bool
    };

    renderHeading() {
        return (
            <h1 block="CheckoutSignin" elem="Heading">
                { __('You must login or register to complete your order') }
            </h1>
        );
    }

    renderAccountOverlay() {
        const {
            onSignIn,device
        } = this.props;

            return (
                <CheckoutMyAccountOverlay
                    device = { device }
                    onSignIn={ onSignIn }
                />
        );



    }

    render() {
        return (
            <main block="CheckoutSignin" aria-label="Checkout Signin">
                <ContentWrapper
                    wrapperMix={ { block: 'CheckoutSignin', elem: 'Wrapper' } }
                    label="Checkout Signin details"
                >
                    { this.renderHeading() }
                    <div block="CheckoutSauda" elem="Content">
                        <div block="CheckoutSauda" elem="Static">
                            <div block="CheckoutSignin">
                                { this.renderAccountOverlay() }
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default CheckoutSignin;
