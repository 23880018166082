import {
    ProductInformation as SourceProductInformation,
} from 'SourceComponent/ProductInformation/ProductInformation.component';
import ContentWrapper from 'Component/ContentWrapper';

import './ProductInformation.style.override'

export class ProductInformation extends SourceProductInformation {
    renderDescription() {
        const { description } = this.props;

        if (!description) {
            return null;
        }

        if(Array.isArray(description)) {
            return (
                <div block="ProductInformation" elem="Attributes">
                    { description.map((item,index)=>{
                        return  <p>
                                    { item }
                                </p>
                    }) }
                </div>
            );
        }

        return (
            <div block="ProductInformation" elem="Attributes">
                <p dangerouslySetInnerHTML={{__html: description}}></p>
            </div>
        );
    }

    render() {
        const {
            areDetailsLoaded,
            product: {
                description: { html } = {}
            }
        } = this.props;

        /*if (this.isHTMLWhiteSpaceOrUndefined(html) && areDetailsLoaded) {
            return null;
        }*/

        return (
            <ContentWrapper
              label="Product information"
              mix={ { block: 'ProductInformation' } }
              wrapperMix={ { block: 'ProductInformation', elem: 'Wrapper' } }
            >
                { this.renderContent() }
            </ContentWrapper>
        );
    }
};

export default ProductInformation;
