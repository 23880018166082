import {
    ConfirmAccountPage as SourceConfirmAccountPage,
} from 'SourceRoute/ConfirmAccountPage/ConfirmAccountPage.component';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import Form from 'Component/Form';
import Field from 'Component/Field';
import { Redirect } from 'react-router';

import './ConfirmAccountPage.style.override'

export class ConfirmAccountPage extends SourceConfirmAccountPage {
    scrollToElement = (e) => {
        if (e.target) {
            const { device } = this.props;

            if (device.isMobile) {
                window.scrollTo(0, (e.target.offsetTop + 82)); // header height: 82px;   
            }
        }
    }

    renderForm() {
        const {
            onConfirmAttempt,
            onConfirmSuccess,
            onFormError
        } = this.props;

        // TODO: use FieldForm instead!!!

        return (
            <Form
              mix={ { block: 'ConfirmAccountPage', elem: 'Form' } }
              key="confirm-account"
              onSubmit={ onConfirmAttempt }
              onSubmitSuccess={ onConfirmSuccess }
              onSubmitError={ onFormError }
            >
                { /*
                    Added email field with display:none to fix warning
                    `Password forms should have (optionally hidden) username fields for accessibility`
                */ }
                <Field
                  type="text"
                  label={ __('Email') }
                  id="confirm-email"
                  name="email"
                  mix={ { block: 'ConfirmAccountPage', elem: 'EmailInput' } }
                />
                <Field
                  type="password"
                  label={ __('Password') }
                  id="confirm-password"
                  name="password"
                  validation={ ['notEmpty'] }
                  onFocus={ this.scrollToElement }
                />
                <button
                  type="submit"
                  block="Button"
                  mix={ { block: 'ConfirmAccountPage', elem: 'Button' } }
                >
                    { __('Confirm your account') }
                </button>
            </Form>
        );
    }

    render() {
        const {
            redirect,
            isLoading,
            isSignedIn
        } = this.props;

        if (redirect || isSignedIn) {
            return <Redirect to="/" />;
        }

        return (
            <main block="ConfirmAccountPage" aria-label={ __('Confirm Account Page') }>
                <ContentWrapper
                  wrapperMix={ { block: 'ConfirmAccountPage', elem: 'Wrapper' } }
                  label={ __('Confirm Account Action') }
                >
                    <Loader isLoading={ isLoading } />
                    <h1 block="ConfirmAccountPage" elem="Heading">
                        { __('Please confirm your account') }
                    </h1>
                    { this.renderForm() }
                </ContentWrapper>
            </main>
        );
    }

};

export default ConfirmAccountPage;
