import {
    CategoryDetails as SourceCategoryDetails,
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';
import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import { CategoryTreeType } from 'Type/Category';
import PropTypes from 'prop-types';
import CategorySort from 'Component/CategorySort';
import Image from 'Component/Image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faThLarge } from '@fortawesome/pro-solid-svg-icons';

import './CategoryDetails.style.override'
import TextPlaceholder from 'Component/TextPlaceholder';

export class CategoryDetails extends SourceCategoryDetails {
    // TODO implement logic
    static propTypes = {
        category: CategoryTreeType.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired,
    };

    onFilterButtonClick = this.onFilterButtonClick.bind(this);

    state = {
        showColorColumns: false,
        showColorRow: false
    };
    renderCategoryName() {
        const { category: { name, id }, isLoadingCount } = this.props;

        if ((id && !name)) {
            return null;
        }

        const loaded = !isLoadingCount;

        return (
            <div block="CategoryDetails" elem="Miscellaneous" mods={ { loaded } }>
                { this.renderCategoryCount() }
                {/* <h1 block="CategoryDetails" elem="Heading">
                    <TextPlaceholder content={ name } />
                </h1> */}
                <aside block="CategoryDetails" elem="MiscellaneousSort">
                    {this.renderCategorySort() }
                </aside>
                { /*this.renderIcons()*/ }
            </div>
        );
    }

    renderCategoryCount() {
        const { totalItems, isMatchingInfoFilter, isLoadingCount } = this.props;

        if (!isMatchingInfoFilter || isLoadingCount) {
            return;
        }

        if (!totalItems) {
            return (
                <div
                    block="CategoryDetails"
                    elem="TotalItems"
                >
                    <span
                        block="CategoryDetails"
                        elem="TotalItemsLabel">{ __('No results found!') }</span>
                </div>
            );
        }

        return (
            <div
              block="CategoryDetails"
              elem="TotalItems"
            >
                <span
                    block="CategoryDetails"
                    elem="TotalItemsCount">{ totalItems } </span>
                <span
                    block="CategoryDetails"
                    elem="TotalItemsLabel">{ __('Result(s)') }</span>
            </div>
        );
    }

    onFilterButtonClick() {
        const { toggleOverlayByKey } = this.props;
        toggleOverlayByKey(CATEGORY_FILTER_OVERLAY_ID);
    }

    renderFilterButton() {
        const { isContentFiltered, totalPages } = this.props;

        // if (!isContentFiltered && totalPages === 0) {
        //     return null;
        // }

        return (
            <button
              block="CategoryDetails"
              elem="Filter"
              onClick={ this.onFilterButtonClick }
            >
                { __('Filter') }
            </button>
        );
    }

    renderCategorySort() {
        const {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter
        } = this.props;

        const { options = {} } = sortFields;
        const updatedSortFields = Object.values(options).map(({ value: id, label }) => ({ id, label }));
        const { sortDirection, sortKey } = selectedSort;

        return (
            <CategorySort
              isMatchingInfoFilter={ isMatchingInfoFilter }
              onSortChange={ onSortChange }
              sortFields={ updatedSortFields }
              sortKey={ sortKey }
              sortDirection={ sortDirection }
            />
        );
    }

    renderFilterButton() {
        const { isContentFiltered, totalPages } = this.props;

        if (!isContentFiltered && totalPages === 0) {
            return null;
        }

        return (
            <button
              block="CategoryPage"
              elem="Filter"
              onClick={ this.onFilterButtonClick }
            >
                { __('Filter') }
            </button>
        );
    }

    renderIcons() {

        const { onClickIcon } = this.props;

        return(
            <div block="CategoryDetails" elem="Icons">
                <button onClick={ onClickIcon }>
                    <FontAwesomeIcon className="CategoryDetails-ListMode" icon={faSquare} />
                </button>
                <button onClick={ onClickIcon }>
                    <FontAwesomeIcon className="CategoryDetails-GridMode" icon={faThLarge} />
                </button>
            </div>
        )
    }

    render() {
        const { totalItems } = this.props;
        const NoResults = totalItems === 0;

        return (
            <article block="CategoryDetails">
                <div block="CategoryDetails" elem="DescriptionShowDesktop">
                    { this.renderCategoryName() }
                    {/* this.renderCategoryDescription() */}
                    { this.renderFilterButton() }
                </div>

                <div block="CategoryDetails" elem="DescriptionShowMobile">
                    { this.renderCategoryName() }
                    {/* this.renderCategoryDescription() */}
                </div>

                {/*<aside block="CategoryDetails" elem="MiscellaneousMobileSort">
                        { this.renderCategorySort() }
                        <div block="CategoryPage" elem="MiscellaneousMobileLine"></div>
                        { this.renderFilterButton() }
                    </aside> */}
                { this.renderCategoryImage() }
            </article>
        );
    }
};

export default CategoryDetails;
