import {
    Html as SourceHtml,
} from 'SourceComponent/Html/Html.component';
import media from 'Util/Media';
import Image from 'Component/Image';
import Slider from "react-slick";
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import Tabs from 'Component/Tabs';
import Link from 'Component/Link';
import { isSignedIn } from 'Util/Auth';

import './Html.style.override.scss';
import HealthCardWithoutLogin from 'Component/HealthCardWithoutLogin';

export class Html extends SourceHtml {

    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { attribs: [{class: 'health-card-button'}] },
            replace: this.replaceCardButton
        },
        {
            query: { attribs: [{class: 'home-card-button'}] },
            replace: this.replaceHomeCardButton
        },
        {
            query: { attribs: [{class: 'blog-posts-slider'}] },
            replace: this.replaceBlog
        },
        {
            query: { attribs: [{'data-content-type': 'tabs'}] },
            replace: this.replaceTabs
        },
        {
            query: { attribs: [{class: 'health-card-without-login'}] },
            replace: this.renderHealthCardFormWithoutLogin
        },
        {
            query: { attribs: [{id: 'healthcard'}] },
            replace: this.renderHealthCardNumber
        }
    ];

    /**
     * Replace links to native React Router links
     * @param  {{ attribs: Object, children: Array }}
     * @return {void|JSX} Return JSX if link is allowed to be replaced
     * @memberof Html
     */
     replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;

        if (href) {
            if(href.includes('cookiebot')) {
                return (
                    <Link { ...attributesToProps({ ...attrs, to: '#' }) } onClick={ (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let elem = document.getElementsByClassName("CookiebotWidget-logo")[0];
                        if(elem) {
                            elem.click();
                        }
                    } }>
                        { domToReact(children, this.parserOptions) }
                    </Link>
                );
            } else {
                const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
                const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

                if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                    return (
                        <Link { ...attributesToProps({ ...attrs, to: href }) }>
                            { domToReact(children, this.parserOptions) }
                        </Link>
                    );
                }
            }
        }
    }

    replaceCardButton({ children }) {
        const { showNotification } = this.props;
        const { 0: link } = children;
        if(link) {
            const { attribs, children: content } = link;
            const { href, ...attrs } = attribs;
            if(!isSignedIn()) {
                delete attrs.href;
                return (
                    <a { ...attributesToProps({ ...attrs }) } onClick={ () => { showNotification('info', __('You must be logged in to access health card')) } }>
                        { domToReact(content, this.parserOptions) }
                    </a>
                );
            }

            if (href) {
                const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
                const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

                if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                    return (
                        <Link { ...attributesToProps({ ...attrs, to: href }) }>
                            { domToReact(content, this.parserOptions) }
                        </Link>
                    );
                }
            }
        }
    }

    replaceHomeCardButton({ children }) {
        const { showNotification, customer: { sauda_card } } = this.props;
        const { 0: link } = children;
        if(link) {
            const { attribs, children: content } = link;
            const { href, ...attrs } = attribs;
            if(!isSignedIn()) {
                delete attrs.href;
                return (
                    <a { ...attributesToProps({ ...attrs }) } onClick={ () => { showNotification('info', __('You must be logged in to enroll/access health card')) } }>
                        { domToReact(content, this.parserOptions) }
                    </a>
                );
            }

            let hasCard = sauda_card && sauda_card.card_number !== null;
            if(hasCard) {
                return (<></>);
            }

            if (href) {
                const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
                const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

                if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                    return (
                        <Link { ...attributesToProps({ ...attrs, to: href }) }>
                            { domToReact(content, this.parserOptions) }
                        </Link>
                    );
                }
            }
        }
    }

    replaceImages({ attribs }) {
        if (attribs.src) {
            if(attribs.src.includes('media url=')) {
                let newSrc = attribs.src.replace('{{media url=', '').replace('}}', '').replaceAll('"', '');
                attribs.src = media(newSrc, '' , true);
            }
            const attributes = attributesToProps(attribs);

            return <Image { ...attributes } />;
        }
    }

    renderRightArrow(){
        return (
            <div>
                <FontAwesomeIcon class="arrow-right" icon={ faChevronRight } />
            </div>
        );
    }

    renderLeftArrow(){
        return (
            <div>
                <FontAwesomeIcon class="arrow-left" icon={ faChevronLeft } />
            </div>
        );
    }

    replaceBlog({ children }) {
        // Get ul element
        const ulElement = children.find(({ attribs }) => {
            return attribs && attribs.class && attribs.class.split(' ').includes('blog-widget-recent');
        });

        // Get li elements
        const liElements = ulElement.children.reduce((a,c) => {
            if (c.name === 'ul') {
                return c.children.reduce((a1,c1) => {
                    if (c1.name === 'li') {
                        a1.push(c1);
                    }

                    return a1;
                }, []);
            }

            return a;
        }, []);

        let settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            adaptiveHeight: true,
            nextArrow: this.renderRightArrow(),
            prevArrow: this.renderLeftArrow(),
            className: 'post-list',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: false,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1.4,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }
              ]
          };

        return <div class="post-list-wrapper">
            <Slider { ...settings } >
                { domToReact(liElements, this.parserOptions) }
            </Slider>
        </div>
    }

    replaceTabs({ children }) {
        // Get tabs elements
        const tabsElements = children.find(({ attribs }) => {
            return attribs && attribs.role && attribs.role == "tablist";
        });

        // Get tabs content elements
        const tabsContentElements = children.find(({ attribs }) => {
            return attribs && attribs.class && attribs.class == "tabs-content";
        });

        return (
            <Tabs
                { ...this.props }
                tabs={ tabsElements }
                tabsContent={ tabsContentElements }
                parserOptions={ this.parserOptions }
            />
        );
    }

    renderHealthCardFormWithoutLogin() {
         return (
             <HealthCardWithoutLogin/>
         );
    }

    renderHealthCardNumber(){
        const healthcard = localStorage.getItem('healthcard_number');
        localStorage.removeItem('healthcard_number');
        return (
            <>
                {healthcard}
            </>
        );
    }

};

export default Html;
