import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    CartItemContainer as SourceCartItemContainer,
    CartDispatcher,
} from 'SourceComponent/CartItem/CartItem.container';
import { CartItemType } from 'Type/MiniCart';
import { CheckoutSaudaDispatcher } from '../../store/CheckoutSauda';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import SwipeToDelete from 'Component/SwipeToDelete';
import CartItem from './CartItem.component';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export {
    CartDispatcher
};

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints,
    isLoadedSaudaCardPoint: state.CheckoutSaudaReducer.isLoadedSaudaCardPoint,
    cartData: state.CartReducer.cartTotals,
    favStoreObj: state.StorePageReducer.favStoreObj,
    customer: state.MyAccountReducer.customer,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isFetchingBuyProductWithPoints: state.CheckoutSaudaReducer.isFetchingBuyProductWithPoints,
    isFetching: state.CheckoutSaudaReducer.isFetching,
    isCartFetching: state.CartReducer.isFetching,
    customerRequestDone: state.MyAccountReducer.customerRequestDone,
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    /*requestSaudaPoints: () => {
        CheckoutSaudaDispatcher.getSaudaCardPoints(dispatch);
    },*/
    buyWithPoints: (sku, points) => {
        CheckoutSaudaDispatcher.productWithPoints(dispatch, sku, points);
    },
    changeCampaignStatus: (item_id) => {
        CheckoutSaudaDispatcher.changeCampaignStatus(dispatch, item_id);
    },
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    /*requestStore: (id) => {
        StorePageDispatcher.requestFavStore(dispatch, id);
    },*/
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    _syncCartWithBE: () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
        )
    },
    showCartCampaign: (id, payload) => dispatch(showPopup("CartItemCampaign_" + id, payload))
});

export class CartItemContainer extends SourceCartItemContainer {
    static propTypes = {
        item: CartItemType.isRequired,
        currency_code: PropTypes.string.isRequired,
        changeItemQty: PropTypes.func.isRequired,
        removeProduct: PropTypes.func.isRequired,
        updateCrossSellProducts: PropTypes.func.isRequired,
        updateCrossSellsOnRemove: PropTypes.bool,
        customerAvailablePoints: PropTypes.int,
        handleItemQtyChange: PropTypes.func
    };

    componentDidUpdate(prevProps, prevState) {
        const { item, handleItemQtyChange } = this.props;
        const { item: prevItem } = prevProps;

        // check qty and buy with points
        if ((item.qty !== prevItem.qty) || (item.buy_with_points !== prevItem.buy_with_points)) {
            handleItemQtyChange();
        }
    }

    containerFunctions = {
        handleChangeQuantity: this.handleChangeQuantity.bind(this),
        handleRemoveItem: this.handleRemoveItem.bind(this),
        getCurrentProduct: this.getCurrentProduct.bind(this),
        getProductVariant: this.getProductVariant.bind(this),
        handleChangeCampaignStatus: this.handleChangeCampaignStatus.bind(this)
    };

    /**
     * Handle item quantity change. Check that value is <1
     * @param {Number} value new quantity
     * @return {void}
     */
     handleChangeQuantity(quantity) {
        this.setState({ isLoading: true }, () => {
            this.hideLoaderAfterPromise(this.changeItemQty(quantity));
        });
    }

    async changeItemQty(quantity) {
        const {
            changeItemQty,
            item: { item_id, sku },
            item,
            saudaCardPoints,
            favStoreObj,
            buyWithPoints,
            getAvailablePointsWithoutItem
        } = this.props;

        await changeItemQty({ item_id, quantity, sku });

        if(isSignedIn()) {
            try {
                if (typeof getAvailablePointsWithoutItem == 'function') {
                    const pointsAvailable = getAvailablePointsWithoutItem(item);
                    if (favStoreObj.sauda) {
                        const product = this.getCurrentProduct() || {};
                        if (product.attributes) {
                            const { has_points, points } = product.attributes;

                            if (has_points.attribute_value && points.attribute_value) {
                                if ((parseInt(points.attribute_value) * quantity) > pointsAvailable) {
                                    buyWithPoints(sku, 'null');
                                }
                            }
                        }
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }
    }

    handleChangeCampaignStatus(item_id) {
        const { changeCampaignStatus } = this.props;
        changeCampaignStatus(item_id);
    }

    /*componentDidMount() {
        const {
            //requestStore,
            customer: { favourite_pharmacy_code }
        } = this.props;

        if(favourite_pharmacy_code) {
            requestStore(favourite_pharmacy_code);
        }
    }*/

    render() {
        const { is_checkout, isConfirm } = this.props;

        if(is_checkout || isConfirm) {
            return (
                <CartItem
                    { ...this.props }
                    { ...this.state }
                    { ...this.containerFunctions }
                    { ...this.containerProps() }
                />
            );
        }

        return (
            <SwipeToDelete
              renderRightSideContent={ this.renderRightSideContent }
              onAheadOfDragItemRemoveThreshold={ this.containerFunctions.handleRemoveItem }
            >
                <CartItem
                  { ...this.props }
                  { ...this.state }
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
            </SwipeToDelete>
        );
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(CartItemContainer);
