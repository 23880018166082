import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    CategoryPageContainer as SourceCategoryPageContainer,
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import PropTypes from 'prop-types';
import { HistoryType, LocationType, MatchType } from 'Type/Common';
import { CategoryTreeType } from 'Type/Category';
import CategoryPage from './CategoryPage.component';
import { fetchQuery } from 'Util/Request';
import ProductListQuery from 'Query/ProductList.query';
import { customerType } from 'Type/Account';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { debounce } from 'Util/Request';
import { LOADING_TIME } from './CategoryPage.config';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';
import { executeGet } from 'Util/Request';
import { prepareQuery } from 'Util/Query';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    totalItems: state.ProductListReducer.totalItems,
    isLoadingCount: state.ProductListReducer.isLoading,
    favStoreObj: state.StorePageReducer.favStoreObj,
    activeOverlay: state.OverlayReducer.activeOverlay,
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    toggleBreadcrumbs: (flag) => dispatch(toggleBreadcrumbs(flag)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
    // TODO extend mapDispatchToProps
});

export class CategoryPageContainer extends SourceCategoryPageContainer {
    // TODO implement logic

    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        history: HistoryType.isRequired,
        category: CategoryTreeType.isRequired,
        location: LocationType.isRequired,
        match: MatchType.isRequired,
        requestCategory: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        changeNavigationState: PropTypes.func.isRequired,
        requestProductListInfo: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        updateMetaFromCategory: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateLoadStatus: PropTypes.func.isRequired,
        updateNoMatch: PropTypes.func.isRequired,
        filters: PropTypes.objectOf(PropTypes.shape).isRequired,
        sortFields: PropTypes.shape({
            options: PropTypes.array
        }).isRequired,
        currentArgs: PropTypes.shape({
            filter: PropTypes.shape({
                categoryIds: PropTypes.number
            })
        }),
        selectedInfoFilter: PropTypes.shape({
            categoryIds: PropTypes.number
        }),
        isInfoLoading: PropTypes.bool.isRequired,
        isOffline: PropTypes.bool.isRequired,
        categoryIds: PropTypes.number,
        isSearchPage: PropTypes.bool
    };

    containerFunctions = {
        onSortChange: this.onSortChange.bind(this)
    }
    state = {
        magentoPageSize: 12
    }

    config = {
        sortKey: 'position',
        sortDirection: 'ASC'
    };

    componentDidMount() {
        const {
            showPharacySelectorPopup,
            categoryIds,
            category: {
                id
            },
            device,
            toggleBreadcrumbs,
            activeOverlay
        } = this.props;
        const { showNotification, customer } = this.props;

        let favourite_pharmacy_code = null;
        let user = customer;

        if (!customer.favourite_pharmacy_code) {
            if (JSON.parse(localStorage.getItem('customer'))) {
                user = JSON.parse(localStorage.getItem('customer'));
                if (user.data.favourite_pharmacy_code) {
                    favourite_pharmacy_code = user.data.favourite_pharmacy_code;
                }
            }

            if (favourite_pharmacy_code == null) {
                if (localStorage.getItem('guest_pharmacy_code')) {
                    favourite_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
                }
            }
        } else {
            favourite_pharmacy_code = customer.favourite_pharmacy_code;
        }



        /**
         * Ensure transition PLP => homepage => PLP always having proper meta
         */
        this.updateMeta();

        /**
         * Always make sure the navigation show / hide mode (on scroll)
         * is activated when entering the category page.
         * */
        this.updateNavigationState();

        /**
         * Always update the history, ensure the history contains category
         */
        this.updateHistory();

        /**
         * Get Magento Configuration for Page Size
         */
        this.getMagentoPageSize();

        /**
         * Make sure to update header state, if the category visited
         * was already loaded.
         */
        if (categoryIds === id) {
            if (device.isMobile) {
                toggleBreadcrumbs(false);
            } else {
                this.updateBreadcrumbs();
            }

            this.updateHeaderState();
        } else {
            /**
             * Still update header and breadcrumbs, but ignore
             * the category data, as it is outdated
             */
            this.updateHeaderState(true);

            if (device.isMobile) {
                toggleBreadcrumbs(false);
            } else {
                this.updateBreadcrumbs(true);
            }
        }

        if( favourite_pharmacy_code == null && !!Object.values(customer).length || !Object.values(user).length){
            if (favourite_pharmacy_code == null) {
                //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
                let path = location.pathname;
                BrowserDatabase.setItem(path,'redirect_to');

                /*if (activeOverlay !== 'PharmacySelector') {
                    showPharacySelectorPopup();
                }*/
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            categoryIds,
            category: {
                id
            },
            currentArgs: {
                filter
            } = {},
            favStoreObj: { pharmacy_code },
            device,
            toggleBreadcrumbs,
            location: { search }
        } = this.props;

        const {
            breadcrumbsWereUpdated
        } = this.state;

        const {
            categoryIds: prevCategoryIds,
            category: {
                id: prevId
            },
            currentArgs: {
                filter: prevFilter
            } = {},
            favStoreObj: { pharmacy_code: prevCode },
            location: { search: prevSearch }
        } = prevProps;

        // TODO: category scrolls up when coming from PDP

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * If the URL rewrite has been changed, make sure the category ID
         * will persist in the history state.
         */
        if (categoryIds !== prevCategoryIds) {
            this.updateHistory();
        }

        /**
         * If the currently loaded category ID does not match the ID of
         * category from URL rewrite, request category.
         */
        if (categoryIds !== id || prevCode != pharmacy_code) {
            this.requestCategory();
        }

        /**
         * If category ID was changed => it is loaded => we need to
         * update category specific information, i.e. breadcrumbs.
         *
         * Or if the breadcrumbs were not yet updated after category request,
         * and the category ID expected to load was loaded, update data.
         */
        const categoryChange = id !== prevId || (!breadcrumbsWereUpdated && id === categoryIds);

        if (categoryChange) {
            this.checkIsActive();
            this.updateMeta();

            if (device.isMobile) {
                toggleBreadcrumbs(false);
            } else {
                this.updateBreadcrumbs();
            }

            this.updateHeaderState();
        }

        /*
        ** if category wasn't changed we still need to update meta for correct robots meta tag [#928](https://github.com/scandipwa/base-theme/issues/928)
        */
        if (!categoryChange
            && filter
            && prevFilter
            && filter.customFilters
            && prevFilter.customFilters
            && Object.keys(filter.customFilters).length !== Object.keys(prevFilter.customFilters).length
        ) {
            this.updateMeta();
        }

        if (prevSearch !== search) {
            document.getElementById('root').scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    updateMeta() {
        const { updateMetaFromCategory, category} = this.props;
        const meta_robots = 'follow, index';

        let currentUrl = new URL(window.location.href);

        if(category.url &&
            window.location.href.includes(category.url) &&
            currentUrl.searchParams.has("page") &&
            !category.canonical_url.includes("page=")
        ) {
            category.canonical_url = category.canonical_url.includes("?") ?
                category.canonical_url + "&page=" + currentUrl.searchParams.get("page") :
                category.canonical_url + "?page=" + currentUrl.searchParams.get("page");
        }

        updateMetaFromCategory({
            ...category,
            meta_robots
        });
    }

    getMagentoPageSize(){
        executeGet(prepareQuery(ProductListQuery._getMagentoPageSize()), 'CategoryPage').then(
            /** @namespace Component/MyAccountOrderPopup/Container/requestOrderDetailsFetchQueryThen */
            ({ getPageSize }) => {
                this.setState({ magentoPageSize: getPageSize.pageSize });
            },
            /** @namespace Component/MyAccountOrderPopup/Container/requestOrderDetailsFetchQueryCatch */
            () => {
                showNotification('error', __('Error getting Magento PageSize!'));
                this.setState({ isLoading: false });
            }
        );
    }

    render() {
        const { magentoPageSize } = this.state;
        const { pageSize } = this.config;

        return (
            <CategoryPage
                { ...this.props }
                pageSize={ pageSize }
                magentoPageSize={ magentoPageSize }
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }

    requestCategory() {
        const {
            categoryIds,
            isSearchPage,
            requestCategory
        } = this.props;

        const {
            currentCategoryIds
        } = this.state;

        /**
         * Prevent non-existent category from being requested
         */
        if (categoryIds === -1) {
            return;
        }

        /**
         * Do not request a category again! We are still waiting for
         * a requested category to load!
         */
        /*if (categoryIds === currentCategoryIds) {
            return;
        }*/

        /**
         * Update current category to track if it is loaded or not - useful,
         * to prevent category from requesting itself multiple times.
         */
        this.setState({
            currentCategoryIds: categoryIds,
            breadcrumbsWereUpdated: false
        });

        requestCategory({
            isSearchPage,
            categoryIds
        });
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer);
