/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { customerType, addressType } from 'Type/Account';

import CheckoutAddressBook from './CheckoutAddressBook.component';
import { fetchMutation, fetchQuery } from 'SourceUtil/Request';
import MyAccountQuery from 'Query/MyAccount.query';
import { showNotification } from 'SourceStore/Notification/Notification.action';

import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { ONE_MONTH_IN_SECONDS } from 'SourceUtil/Request/QueryDispatcher';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Component/CheckoutAddressBook/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    shippingFields: state.CheckoutReducer.shippingFields,
    device: state.ConfigReducer.device,
    cartData: state.CartReducer.cartTotals,
});

/** @namespace Component/CheckoutAddressBook/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    updateCustomer: (customer) => dispatch(updateCustomerDetails(customer)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    updateCustomerDetails: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
});

/** @namespace Component/CheckoutAddressBook/Container */
export class CheckoutAddressBookContainer extends PureComponent {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        requestCustomerData: PropTypes.func.isRequired,
        onShippingEstimationFieldsChange: PropTypes.func,
        onAddressSelect: PropTypes.func,
        customer: customerType.isRequired,
        isBilling: PropTypes.bool,
        onSelectChange: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        address: addressType.isRequired,
        FormTitle: PropTypes.string.isRequired,
        updateCustomerDetails: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            address: addressType
        }).isRequired,
        setAddressTmpCreating: PropTypes.func.isRequired,
        addressTmpCreating: PropTypes.bool.isRequired,
        setNotReady: PropTypes.func.isRequired,
        setAddressBillingNotStored: PropTypes.func.isRequired,
        setAddressBillingTmpCreating: PropTypes.func.isRequired,
        addressBillingTmpCreating: PropTypes.bool.isRequired,
        zipCodeValid: PropTypes.bool.isRequired
    };

    state = {
        editMode: false
    }

    static defaultProps = {
        isBilling: false,
        onAddressSelect: () => {},
        onSelectChange: () => {},
        onShippingEstimationFieldsChange: () => {}
    };

    static _getDefaultAddressId(props) {
        const { customer, isBilling } = props;
        const defaultKey = isBilling ? 'default_billing' : 'default_shipping';
        const { [defaultKey]: defaultAddressId, addresses } = customer;

        if (defaultAddressId) {
            return +defaultAddressId;
        }
        if (addresses && addresses.length) {
            return addresses[0].id;
        }

        return 0;
    }

    containerFunctions = ({
        onAddressSelect: this.onAddressSelect.bind(this),
        onSelectChange: this.onSelectChange.bind(this),
        handleAddressShipping: this.handleAddressShipping.bind(this),
        handleAddressBilling: this.handleAddressBilling.bind(this),
        activateEditMode: this.activateEditMode.bind(this),
        setEditAddressId: this.setEditAddressId.bind(this),
        disableNewAddressMode: this.disableNewAddressMode.bind(this),
        updateCustomerData: this.updateCustomerData.bind(this)
    });

    __construct(props) {
        super.__construct(props);

        const {
            requestCustomerData,
            customer,
            onAddressSelect,
            isSignedIn
        } = props;

        if (isSignedIn && !Object.keys(customer).length) {
            requestCustomerData();
        }

        const defaultAddressId = CheckoutAddressBookContainer._getDefaultAddressId(props);

        if (defaultAddressId) {
            onAddressSelect(defaultAddressId);
            this.estimateShipping(defaultAddressId);
        } else {
            this.estimateShipping(null);
        }

        this.state = {
            prevDefaultAddressId: defaultAddressId,
            selectedAddressId: defaultAddressId,
            selectChanged: false,
            editMode: false,
            editAddressId: 0,
            createNewAddress: false,
            prevSubtotal: 0
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { prevDefaultAddressId } = state;
        const defaultAddressId = CheckoutAddressBookContainer._getDefaultAddressId(props);

        if (defaultAddressId !== prevDefaultAddressId) {
            return {
                selectedAddressId: defaultAddressId,
                prevDefaultAddressId: defaultAddressId
            };
        }

        return null;
    }

    componentDidUpdate(_, prevState) {
        const {
            onAddressSelect,
            requestCustomerData,
            isSignedIn,
            customer,
            addressTmpCreating,
            cartData,
            addressBillingTmpCreating
        } = this.props;
        const { selectedAddressId: prevSelectedAddressId } = prevState;
        const { selectedAddressId, editMode } = this.state;

        if (isSignedIn && !Object.keys(customer).length) {
            requestCustomerData();
        }

        if (selectedAddressId !== prevSelectedAddressId) {
            onAddressSelect(selectedAddressId);
            if(!editMode){
                this.estimateShipping(selectedAddressId);
            }
        }/* else {
            if(cartData.subtotal != this.state.prevSubtotal){
                console.log('update 2');
                this.estimateShipping(selectedAddressId);
                this.setState({ prevSubtotal: cartData.subtotal });
            }
        }*/

        if(addressTmpCreating || addressBillingTmpCreating){
            this.setState({ createNewAddress: true });
        }
    }

    activateEditMode(value) {
        const { setIsEditing, setNotReady } = this.props;
        this.setState({ editMode: value });
        setIsEditing(value);
        setNotReady(value);
    }

    disableNewAddressMode(value) {
        const { setNotReady } = this.props;
        this.setState({createNewAddress: value});
        setNotReady(false);
    }

    setEditAddressId(value) {
        this.setState({ editAddressId: value });
    }

    handleAfterEditActionAddress = () => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            showSuccessNotification
        } = this.props;
        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                //showSuccessNotification(__('Address successfully updated!'));
                this.setState({ isLoading: false }, () => {
                    //goToPreviousHeaderState();
                    this.activateEditMode(false);
                });
            }, (error) => this.handleError(error)
        );
    };

    handleAfterActionAddress = (response) => {
        const {
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            showEditForm,
            showSuccessNotification,
            setIsCreatingAddress
        } = this.props;

        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                showSuccessNotification(__('Address successfully created!'));
                this.setState({ isLoading: false }, () => {
                    //goToPreviousHeaderState();
                    this.disableNewAddressMode(false);
                    if(response && response.createCustomerAddress) {
                        this.onAddressSelect(response.createCustomerAddress);
                    }
                    setIsCreatingAddress(false);
                });
            }, (error) => this.handleError(error)
        );
    };

    onAddressSelect(address) {
        const { id = 0 } = address;
        const { isBilling, setAddressBillingTmpCreating, setAddressTmpCreating } = this.props;
        this.setState({ selectedAddressId: id, selectChanged: true });
    }

    onSelectChange(e) {
        const { isBilling, setAddressBillingTmpCreating, setAddressTmpCreating } = this.props;
        const id = parseInt(e.target.value) || 0;
        this.setState({ selectedAddressId: id, selectChanged: true });

        /*if(id && id > 0){
            if(isBilling){
                setAddressBillingTmpCreating(false);
            } else {
                setAddressTmpCreating(false);
            }
        }*/
    };

    handleAddressShipping(address) {
        const { setAddressNotStored, setAddressTmpCreating, isBilling, zipCodeValid } = this.props;
        address.country_id = 'PT';

        this.setState({ isLoading: true });
        if (this.state.editAddressId) {
            return this.handleEditAddress(address);
        } else {
            setAddressNotStored(address);
            if(address.save_in_address_book){
                setAddressTmpCreating(false);
                return this.handleCreateAddress(address);
            } else {
                if(zipCodeValid){
                    this.disableNewAddressMode(true);
                }
                address.firstname = address.firstname.trim();
                address.region = '';
                this.setState({isLoading: false});
                if(!isBilling){
                    return this.handleCreateAddressNotSaving(address);
                } else {
                    return ;
                }
            }
        }
    }


    handleAddressBilling(address) {
        const { setAddressBillingNotStored, setAddressBillingTmpCreating, isBilling } = this.props;
        address.country_id = 'PT';

        this.setState({ isLoading: true });
        if (this.state.editAddressId) {
            return this.handleEditAddress(address);
        } else {
            setAddressBillingNotStored(address);
            if(address.save_in_address_book){
                setAddressBillingTmpCreating(false);
                return this.handleCreateAddress(address);
            } else {
                this.disableNewAddressMode(true);
                address.firstname = address.firstname.trim();
                address.region = '';
                if(!isBilling){
                    return this.handleCreateAddressNotSaving(address);
                } else {
                    this.setState({ isLoading: false });
                    return;
                }
            }
        }
    }

    handleCreateAddressNotSaving(address) {
        const { onShippingEstimationFieldsChange } = this.props;
        let customInput = {
            region: '',
            region_id: 0,
            region_code: null,
            country_id: 'PT',
            postcode: address.postcode,
            city: address.city,
            firstname: address.firstname,
            lastname: address.lastname,
            email: '',
            telephone: '',
            street: address.street,
            customer_id: 0,
            same_as_billing: 0
        }
        let postCode = address.postCode;
        return onShippingEstimationFieldsChange(
            customInput,
            'PT',
            '',
            '',
            postCode
        );
    }

    handleEditAddress(address) {
        const query = MyAccountQuery.getUpdateAddressMutation(this.state.editAddressId, address);
        fetchMutation(query).then(this.handleAfterEditActionAddress, this.handleError);
    }

    handleCreateAddress(address) {
        const query = MyAccountQuery.getCreateAddressMutation(address);
        fetchMutation(query).then((response) => {
            this.handleAfterActionAddress(response);
        }, this.handleError);
    }

    updateCustomerData(customer) {
        const {
            updateCustomer,
            showSuccessNotification
        } = this.props;

        const mutation = MyAccountQuery.getUpdateInformationMutation(customer);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountCustomerPopup/Container/onCustomerSaveFetchMutationThen */
            ({ updateCustomer: { customer } }) => {
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                updateCustomer(customer);
                this.setState({ isLoading: false }, () => {
                    showSuccessNotification(__('Information successfully updated!'));
                });
            },
            this.handleError
        );
    }

    handleError = (error) => {
        const { showErrorNotification } = this.props;
        showErrorNotification(error);
        this.setState({ isLoading: false });
    }

    estimateShipping(addressId) {
        const {
            onShippingEstimationFieldsChange,
            customer: { addresses = [] }
        } = this.props;

        const address = addresses.find(({ id }) => id === addressId);

        if (!address) {
            let customInput = {
                region: '',
                region_id: 0,
                region_code: null,
                country_id: 'PT',
                postcode: '1234-123',
                city: 'porto',
                firstname: '',
                lastname: '',
                email: '',
                telephone: '',
                street: [' '],
                customer_id: 0,
                same_as_billing: 1
            }
            return onShippingEstimationFieldsChange(
                customInput,
                'pt',
                '',
                '',
                '1234-123'
            );
        }

        const {
            city,
            country_id,
            postcode,
            region: {
                region_id,
                region
            } = {}
        } = address;

        if (!country_id) {
            return;
        }

        onShippingEstimationFieldsChange({
            city,
            country_id,
            region_id,
            region,
            postcode
        });
    }

    render() {
        return (
            <CheckoutAddressBook
            { ...this.props }
            { ...this.state }
            { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressBookContainer);
