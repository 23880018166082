import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckoutSauda from './CheckoutSauda.component';

import { CheckoutSaudaDispatcher } from '../../store/CheckoutSauda';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { hasOutOfStockProductsInCartItems } from 'Util/Cart';
import { appendWithStoreCode } from 'Util/Url';
import { isSignedIn } from 'Util/Auth';
import { TotalsType } from 'Type/MiniCart';
import history from 'Util/History';
import { HistoryType } from 'Type/Common';
import { showNotification } from 'Store/Notification/Notification.action';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { customerType } from 'Type/Account';
import { fetchQuery } from 'Util/Request';
import CheckoutSaudaQuery from 'Query/CheckoutSauda.query';
import Loader from 'Component/Loader';
import { showPopup } from 'Store/Popup/Popup.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);


export const mapStateToProps = state => ({
    matrix: state.CheckoutSaudaReducer.matrix,
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints,
    isFetching: state.CheckoutSaudaReducer.isFetching,
    saudaCardMatrix: state.CheckoutSaudaReducer.getSaudaCardMatrix,
    totals: state.CartReducer.cartTotals,
    updateDiscount: state.CheckoutSaudaReducer.discount,
    isMobile: state.ConfigReducer.device.isMobile,
    guest_checkout: state.ConfigReducer.guest_checkout,
    customer: state.MyAccountReducer.customer,
    updateDiscount: state.CheckoutSaudaReducer.discount
});

export const mapDispatchToProps = (dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
    requestSaudaDetails: () => {
        CheckoutSaudaDispatcher.getSaudaCardDetails(dispatch);
    },
    requestSaudaPoints: () => {
        CheckoutSaudaDispatcher.getSaudaCardPoints(dispatch);
    },
    requestSaudaMatrix: () => {
        CheckoutSaudaDispatcher.getSaudaCardMatrix(dispatch);
    },
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showInfoPopupCardInfo: (id, payload) => dispatch(showPopup(id, payload)),
    requestUpdateSaudaDiscount: (discount, ref, points) => {
        CheckoutSaudaDispatcher.updateSaudaDiscount(dispatch, discount, ref, points);
    },
    renderDisableEnableAdvanceButton: () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        )
    },
    _syncCartWithBE: () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
        )
    }
});

export class CheckoutSaudaContainer extends PureComponent {
    static propTypes = {
        requestSaudaDetails: PropTypes.func.isRequired,
        requestSaudaPoints: PropTypes.func.isRequired,
        requestSaudaMatrix: PropTypes.func.isRequired,
        requestUpdateSaudaDiscount: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        showOverlay: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        guest_checkout: PropTypes.bool.isRequired,
        history: HistoryType.isRequired,
        totals: TotalsType.isRequired,
        customer: customerType.isRequired,
        requestUpdateSaudaDiscount: PropTypes.func.isRequired,
        renderDisableEnableAdvanceButton: PropTypes.func.isRequired,
        _syncCartWithBE: PropTypes.func.isRequired
    };

    state = {
        isButtonAdvanceDisable: true,
        isLoading: false
    }

    containerFunctions = {
        onCheckoutButtonClick: this.onCheckoutButtonClick.bind(this),
        checkSaudaDiscountStored: this.checkSaudaDiscountStored.bind(this),
        changeAdvanceButtonState: this.changeAdvanceButtonState.bind(this),
        getAdvanceButtonState: this.getAdvanceButtonState.bind(this),
        applyVoucher: this.applyVoucher.bind(this),
        getAvailablePoints: this.getAvailablePoints.bind(this)
    };

    containerProps = () => {
        const { totals } = this.props;

        return {
            hasOutOfStockProductsInCart: hasOutOfStockProductsInCartItems(totals.items)
        };
    };

    applyAllVouchers() {
        const { showNotification, _syncCartWithBE, requestSaudaPoints} = this.props;
        this.setState({ isLoading: true });

        fetchQuery(CheckoutSaudaQuery.applyAllVouchers()).then(
            /** @namespace Component/CheckoutSauda/Container/applyExclusiveVoucher */
            ({ applyAllVouchers }) => {
                if (applyAllVouchers.status == '200') {
                    requestSaudaPoints();
                }
                _syncCartWithBE();
                this.setState({ isLoading: false });
            },
            /** @namespace Component/CheckoutSauda/Container/applyExclusiveVoucher */
            () => {
                this.setState({ isLoading: false });
            }
        );
    }

    componentDidMount(){
        const { totals: { quote_campaigns },customer: { sauda_card } } = this.props;
        if(sauda_card && sauda_card.is_active && sauda_card.status == 'Active'){
            this.applyAllVouchers()
        }
    }

    changeAdvanceButtonState(value) {
        this.setState({isButtonAdvanceDisable: value});
        this.getAdvanceButtonState();
    }

    getAdvanceButtonState() {
        const {isButtonAdvanceDisable} = this.state;
        return isButtonAdvanceDisable;
    }

    checkSaudaDiscountStored(ref) {
        const { totals } = this.props;

        if (totals.sauda_discount_ref) {
            if (ref == totals.sauda_discount_ref) {
                return true;
            }
        }

        return false;
    }

    onCheckoutButtonClick(e) {
        const {
            guest_checkout,
            showOverlay,
            showNotification,
            isMobile,
            totals,
            customer
        } = this.props;

        const {
            location: {
                pathname
            }
        } = history;

        // to prevent outside-click handler trigger
        e.nativeEvent.stopImmediatePropagation();

        let redirectUrl = CHECKOUT_URL;

        if (hasOutOfStockProductsInCartItems(totals.items)) {
            return;
        }

        if (guest_checkout && isSignedIn()) {
            if(customer && customer.sauda_card && customer.sauda_card.card_number && !pathname.includes('/checkout-sauda')) {
                redirectUrl = '/checkout-sauda';
            }
            history.push({
                pathname: appendWithStoreCode(redirectUrl)
            });

            return;
        }

        // fir notification whatever device that is
        showNotification('info', __('Please sign-in to complete checkout!'));

        if (isMobile) { // for all mobile devices, simply switch route
            history.push({ pathname: appendWithStoreCode('/my-account') });
            return;
        }

        // for desktop, just open customer overlay
        showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
    }

    applyVoucher(voucher) {
        const { renderDisableEnableAdvanceButton, showNotification, _syncCartWithBE, requestSaudaPoints, requestSaudaMatrix } = this.props;

        let voucherArray = [];
        voucherArray.push(voucher);

        this.setState({ isLoading: true });

        fetchQuery(CheckoutSaudaQuery.applyExclusiveVoucher(voucher)).then(
            /** @namespace Component/CheckoutSauda/Container/applyExclusiveVoucher */
            ({ applyExclusiveVoucher }) => {
                if (applyExclusiveVoucher.status == '200') {
                    showNotification('success', applyExclusiveVoucher.message);
                    requestSaudaPoints();
                } else {
                    showNotification('error', applyExclusiveVoucher.message);
                }
                _syncCartWithBE();
                this.setState({ isLoading: false });
                //setTimeout(function(){ renderDisableEnableAdvanceButton(); }, 5000);
            },
            /** @namespace Component/CheckoutSauda/Container/applyExclusiveVoucher */
            () => {
                showNotification('error', __('Something went wrong!'));
                this.setState({ isLoading: false });
            }
        );
    }

    getAvailablePoints() {
        const { totals: { items }, saudaCardPoints } = this.props;

        let customerAvailablePoints = items.reduce((a, { qty, buy_with_points, product }) => {
            if (buy_with_points && product.attributes && product.attributes.points) {
                a -= parseInt(product.attributes.points.attribute_value * qty);
            }

            return a;
        }, parseInt(saudaCardPoints.points || 0));

        customerAvailablePoints = customerAvailablePoints < 0 ? 0 : customerAvailablePoints;

        return customerAvailablePoints;
    }

    render() {
        const { isFetching } = this.props;
        const { isLoading } = this.state;

        return (
            <>
                <Loader isLoading={ isLoading || isFetching } />
                <CheckoutSauda
                    { ...this.props }
                    { ...this.containerFunctions }
                    { ...this.containerProps() }
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSaudaContainer);
