import { connect } from 'react-redux';

import {
    MyAccountCustomerPopupContainer as SourceMyAccountCustomerPopupContainer,
    mapStateToProps
} from 'SourceComponent/MyAccountCustomerPopup/MyAccountCustomerPopup.container';


import PropTypes from 'prop-types';

import MyAccountQuery from 'Query/MyAccount.query';
import { updateCustomerDetails, updateCustomerPhoto } from 'Store/MyAccount/MyAccount.action';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { hideActiveOverlay } from 'SourceStore/Overlay/Overlay.action';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { fetchMutation } from 'SourceUtil/Request';
import { ONE_MONTH_IN_SECONDS } from 'SourceUtil/Request/QueryDispatcher';

import MyAccountCustomerPopup from './MyAccountCustomerPopup.component';
import { CUSTOMER_POPUP_ID } from './MyAccountCustomerPopup.config';

export {
    mapStateToProps
};

export const mapDispatchToProps = (dispatch) => ({
    updateCustomer: (customer) => dispatch(updateCustomerDetails(customer)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    updateCustomerPhoto: (customer) => dispatch(updateCustomerPhoto(customer)),
});

export class MyAccountCustomerPopupContainer extends SourceMyAccountCustomerPopupContainer {
    // TODO implement logic
    static propTypes = {
        updateCustomer: PropTypes.func.isRequired,
        updateCustomerPhoto: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired
    };

    state = {
        isLoading: false
    };

    containerFunctions = {
        onCustomerSave: this.onCustomerSave.bind(this),
        onPasswordChange: this.onPasswordChange.bind(this),
        onPhotoSave: this.onPhotoSave.bind(this)
    };

    onError = (error) => {
        const { showErrorNotification } = this.props;
        this.setState({ isLoading: false });
        showErrorNotification(error);
    };

    onPhotoSave(customerPhoto) {
        const {
            updateCustomerPhoto,
            hideActiveOverlay,
            goToPreviousHeaderState
        } = this.props;

        const mutation = MyAccountQuery.getUpdatePhotoMutation(customerPhoto);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountCustomerPopup/Container/onPhotoSaveFetchMutationThen */
            ({ updateCustomerPhoto: { customerPhoto } }) => {
                //BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                updateCustomerPhoto(customerPhoto);
                this.setState({ isLoading: false }, () => {
                    hideActiveOverlay();
                    //goToPreviousHeaderState();
                });
            },
            this.onError
        );
    }

    onCustomerSave(customer) {
        const {
            updateCustomer,
            hideActiveOverlay,
            goToPreviousHeaderState
        } = this.props;

        const mutation = MyAccountQuery.getUpdateInformationMutation(customer);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountCustomerPopup/Container/onCustomerSaveFetchMutationThen */
            ({ updateCustomer: { customer } }) => {
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS*8);
                updateCustomer(customer);
                this.setState({ isLoading: false }, () => {
                    hideActiveOverlay();
                    goToPreviousHeaderState();
                });
            },
            this.onError
        );
    }

    onPasswordChange(passwords) {
        const {
            showSuccessNotification,
            hideActiveOverlay,
            goToPreviousHeaderState
        } = this.props;

        const mutation = MyAccountQuery.getChangeCustomerPasswordMutation(passwords);
        this.setState({ isLoading: true });

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountCustomerPopup/Container/onPasswordChangeFetchMutationThen */
            () => {
                showSuccessNotification(__('Your password was successfully updated!'));
                this.setState({ isLoading: false }, () => {
                    hideActiveOverlay();
                    goToPreviousHeaderState();
                });
            },
            this.onError
        );
    }

    render() {
        return (
            <MyAccountCustomerPopup
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCustomerPopupContainer);
