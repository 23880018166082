import {
    InstallPromptIOS as SourceInstallPromptIOS,
} from 'SourceComponent/InstallPromptIOS/InstallPromptIOS.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import './InstallPromptIOS.style.override.scss';

export class InstallPromptIOS extends SourceInstallPromptIOS {

    renderCloseButton() {
        const { handleBannerClose } = this.props;

        return (
            <button
              block="InstallPromptIOS"
              elem="Close"
              onClick={ handleBannerClose }
            >
                <FontAwesomeIcon icon={ faTimes } />
            </button>
        );
    }

    renderContent() {
        return (
            <p block="InstallPromptIOS" elem="Content">
                <span>{ __('Tap:') }</span>
                <span block="InstallPromptIOS" elem="Share" />
                <span>{ __(', then') }</span>
                <span block="InstallPromptIOS" elem="Plus" />
                <span>{ __('Add to Home Screen') }</span>
            </p>
        );
    }

};

export default InstallPromptIOS;
