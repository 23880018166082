import {
    MyAccountAddressPopup as SourceMyAccountAddressPopup,
} from 'SourceComponent/MyAccountAddressPopup/MyAccountAddressPopup.component';

import MyAccountAddressTable from 'Component/MyAccountAddressTable';

import './MyAccountAddressPopup.style.override'

export class MyAccountAddressPopup extends SourceMyAccountAddressPopup {

    renderDeleteNotice() {
        const { payload: { address }, handleDeleteAddress, hideActiveOverlay } = this.props;

        let title = __('Address details');
        if(address.address_title) {
            title = address.address_title;
        }

        return (
            <>
                <p>
                    <div block="MyAccountAddressPopup" elem="Address">
                    <MyAccountAddressTable isPopup={ true } address={ address } title={ title } />
                    </div>
                </p>
                <div block="MyAccountAddressPopup" elem="Actions" >
                    <button block="Button" onClick={ handleDeleteAddress }>
                        { __('Yes, delete address') }
                    </button>
                </div>
                <div block="MyAccountAddressPopup" elem="Actions">
                    <button
                    block="Button"
                    mix={ { block: 'MyAccount', elem: 'Cancel' }  }
                    onClick={ hideActiveOverlay }
                    >{ __('Cancel') } </button>
                </div>

            </>
        );
    }

};

export default MyAccountAddressPopup;
