import { PureComponent } from 'react';
import PropTypes from 'prop-types';
//import './MyAccountProfilePicture.style';
import { customerType } from 'Type/Account';
import { updateCustomerDetails } from 'SourceStore/MyAccount/MyAccount.action';

class MyAccountProfilePicture extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
        //onphotoChange: PropTypes.string.isRequired,
        fileUploaded: PropTypes.string.isRequired,
        returnUploadState: PropTypes.func.isRequired,
        customer: PropTypes.func.isRequired,
        updateCustomerDetails: PropTypes.func.isRequired
    };

    onphotoChange = (e) => {
        const { customer } = this.props

        customer.photo = e.target.files[0];
        customer.customerId = 3;
        customer.id = 3;
        updateCustomerDetails(customer);

    }

    render() {

        return (
            <div block="MyAccountProfilePicture">
                <label class="Field-Label" for="profilePhoto">{__('Profile Photo')}</label>
                <input type="file" class="MyAccountProfilePicture-FileInput" name="profilePhoto" id="profilePhoto" onChange={this.onphotoChange}/>
            </div>
        );
    }
}

export default MyAccountProfilePicture;
