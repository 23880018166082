/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ProductTab from 'Component/ProductTab';
import { isMobile } from 'Util/Mobile';

import iconPrescription from './images/group-8@3x.png';
import iconPrecautions from './images/group-9@3x.png';

import './ProductTabs.style';
import './ProductTabs.style.override';

/** @namespace Component/ProductTabs/Component */
export class ProductTabs extends PureComponent {
    static propTypes = {
        tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
        children: PropTypes.node,
        defaultTab: PropTypes.string
    };

    static defaultProps = {
        children: null,
        defaultTab: null
    };

    __construct(props) {
        super.__construct(props);

        const { defaultTab, tabNames } = this.props;

        this.state = {
            activeTab: defaultTab || tabNames[0]
        };

        this.renderActiveTab = this.renderActiveTab.bind(this);
    }

    onTabClick = (activeTab) => {
        this.setState({
            activeTab
        });
    };

    renderActiveTab(activeTab, childrenArray) {
        const { tabNames } = this.props;

        return childrenArray.map((item, i) => {
            if (tabNames[i].toLowerCase() === activeTab.toLowerCase()) {
                return item;
            }

            return false;
        });
    }

    renderAllTabs(childrenArray) {
        return childrenArray.map((item) => item);
    }

    renderTab = (_, i) => {
        const { children } = this.props;
        const { tabNames } = this.props;
        const { activeTab } = this.state;

        if(tabNames[i] != 'Indication Of Dismissal'){
            return (
                <>
                <ProductTab
                  tabName={ tabNames[i] }
                  key={ tabNames[i] }
                  renderActive= { this.renderActiveTab }
                  children = { children }
                  //onClick={ this.onTabClick }
                  //isActive={ activeTab && tabNames[i].toLowerCase() === activeTab.toLowerCase() }
                />
                { /*(activeTab && tabNames[i].toLowerCase() === activeTab.toLowerCase() ? this.renderActiveTab(activeTab, children) : null) */ }
                </>
            );
        }
    };

    renderTabs() {
        const { children } = this.props;
        const { activeTab } = this.state;

        return (
            <>
                <ul
                  block="ProductTabs"
                > { children.map(this.renderTab)  /* this.renderActiveTab(activeTab, children) */ }
                </ul>
            </>
        );
    }

    renderAttributesItems() {
        const { product: { custom_attributes } } = this.props;
        let showPrec = '';

        let indicationOfDismissal = null;
        let legalBase = null;

        if(custom_attributes && custom_attributes.length) {
            custom_attributes.forEach(attr => {
                if (attr.attribute_code == 'indication_of_dismissal') {
                    indicationOfDismissal = attr.attribute_values;
                }

                if (attr.attribute_code == 'legal_base') {
                    legalBase = attr.attribute_values;
                }

                /*switch(attr.attribute_code) {
                    case 'amamentacao':
                        showPrec += __('Breast-feeding') + ' ';
                        break;
                    case 'precautions_in_pregnancy':
                        showPrec += __('Pregnancy') + ' ';
                        break;
                    case 'pediatric_precautions':
                        showPrec += __('Children') + ' ';
                        break;
                }*/
            });
        }

        let messageDefault = '';

        if (indicationOfDismissal != null && legalBase == null) {
            messageDefault = __('Medicine not subject to prescription');
        } else if (indicationOfDismissal != null && legalBase != null) {
            legalBase.forEach( base => {
                messageDefault = messageDefault + ' ' + base;
            });
        } else if (indicationOfDismissal == null && legalBase != null) {
            legalBase.forEach( base => {
                messageDefault = messageDefault + ' ' + base;
            });
        } else if (indicationOfDismissal == null && legalBase == null) {
            messageDefault = '';
        }

        return (
            <ul block="PrescriptionList">
                <li
                    block="PrescriptionList"
                    elem="Offset"
                >
                    { messageDefault }
                </li>
                { showPrec && <li
                    block="PrescriptionList"
                    elem="Offset"
                >
                    { showPrec }
                </li> }
            </ul>

        );
    }


    render() {

        //if(!isMobile.any()){
            return (
                <ContentWrapper
                wrapperMix={ { block: 'ProductTabs', elem: 'Wrapper' } }
                label={ __('Product tabs') }
                >
                    <div>
                        { this.renderAttributesItems() }
                    </div>
                    <h2
                    block="ProductTabs"
                    elem="Title">
                        { __("Technical Informations") }
                    </h2>
                    { this.renderTabs() }
                </ContentWrapper>
            );
        /*} else {
            return (
               <>
               </>
            );
        }*/
    }
}

export default ProductTabs;
