/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import gtag, { install } from 'ga-gtag';
import ReactPixel from 'react-facebook-pixel';
import ConfigQuery from 'Query/Config.query';

import { BRAINTREE, KLARNA } from 'Component/CheckoutPayments/CheckoutPayments.config';
import {
    TERMS_AND_CONDITIONS_POPUP_ID
} from 'Component/CheckoutTermsAndConditionsPopup/CheckoutTermsAndConditionsPopup.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { addressType, customerType } from 'Type/Account';
import { paymentMethodsType } from 'Type/Checkout';
import { TotalsType } from 'Type/MiniCart';
import { getFormFields, trimAddressFields, trimCustomerAddress } from 'Util/Address';
import CheckoutQuery from 'Query/Checkout.query';
import { fetchQuery } from 'Util/Request';
import { validateNIF } from 'Component/Form/Form.config';

import CheckoutBilling from './CheckoutBilling.component';

/** @namespace Component/CheckoutBilling/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    totals: state.CartReducer.cartTotals,
    termsAreEnabled: state.ConfigReducer.terms_are_enabled,
    termsAndConditions: state.ConfigReducer.checkoutAgreements,
    addressLinesQty: state.ConfigReducer.address_lines_quantity
});

/** @namespace Component/CheckoutBilling/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showPopup: (payload) => dispatch(showPopup(TERMS_AND_CONDITIONS_POPUP_ID, payload))
});

/** @namespace Component/CheckoutBilling/Container */
export class CheckoutBillingContainer extends PureComponent {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired,
        paymentMethods: paymentMethodsType.isRequired,
        savePaymentInformation: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        shippingAddress: addressType.isRequired,
        customer: customerType.isRequired,
        totals: TotalsType.isRequired,
        addressLinesQty: PropTypes.number.isRequired,
        termsAndConditions: PropTypes.arrayOf(PropTypes.shape({
            checkbox_text: PropTypes.string,
            content: PropTypes.string,
            name: PropTypes.string
        })).isRequired,
        addressBillingNotStored: PropTypes.objectOf(PropTypes.string).isRequired,
        addressBillingTmpCreating: PropTypes.bool.isRequired,
        setAddressBillingNotStored: PropTypes.func.isRequired
    };

    static getDerivedStateFromProps(props, state) {
        const { paymentMethod, prevPaymentMethods } = state;
        const { paymentMethods } = props;

        if (!prevPaymentMethods.length && !paymentMethod) {
            const [method] = paymentMethods;
            const { code: paymentMethod } = method || {};

            return {
                prevPaymentMethods: paymentMethods,
                paymentMethod
            };
        }

        return null;
    }

    componentDidMount(){

        fetchQuery(ConfigQuery.getAnalyticsConfig('page_view_billing')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );

        fetchQuery(ConfigQuery.getAnalyticsConfig('page_view_billing_counter')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );


        ReactPixel.track('AddPaymentInfo');
    
    }

    containerFunctions = {
        onBillingSuccess: this.onBillingSuccess.bind(this),
        onBillingError: this.onBillingError.bind(this),
        onAddressSelect: this.onAddressSelect.bind(this),
        onSameAsShippingChange: this.onSameAsShippingChange.bind(this),
        onPaymentMethodSelect: this.onPaymentMethodSelect.bind(this),
        showPopup: this.showPopup.bind(this),
        setPhone: this.setPhone.bind(this),
        setBillingName: this.setBillingName.bind(this),
        setBillingTax: this.setBillingTax.bind(this),
        setMbwayPhone: this.setMbwayPhone.bind(this),
        setMbwayPhoneFlag: this.setMbwayPhoneFlag.bind(this),
        getMbwayStatus: this.getMbwayStatus.bind(this),
        getBillingPhoneFlag: this.getBillingPhoneFlag.bind(this),
        setIsEditing: this.setIsEditing.bind(this),
        setIsCreatingAddress: this.setIsCreatingAddress.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        const { paymentMethods, customer } = props;
        const [method] = paymentMethods;
        const { code: paymentMethod } = method || {};

        this.state = {
            isSameAsShipping: false,//this.isSameShippingAddress(customer),
            selectedCustomerAddressId: 0,
            prevPaymentMethods: paymentMethods,
            billing_phone: customer.phone,
            billing_name: '',
            billing_tax: '',
            paymentMethod,
            mbway_phone: "",
            mbway_status: false,
            billing_phone_flag: true,
            mbway_phone_flag: true,
            isEditing: false,
            isCreatingAddress: false,
        };
    }

    setIsCreatingAddress(value) {
        this.setState({ isCreatingAddress: value });
    }

    isSameShippingAddress({ default_billing, default_shipping }) {
        const { totals: { is_virtual } } = this.props;

        if (is_virtual) {
            return false;
        }

        return default_billing === default_shipping;
    }

    onAddressSelect(id) {
        this.setState({ selectedCustomerAddressId: id });
    }

    onSameAsShippingChange() {
        this.setState(({ isSameAsShipping }) => ({ isSameAsShipping: !isSameAsShipping }));
    }

    onPaymentMethodSelect(code) {
        this.setState({ paymentMethod: code });
    }

    setPhone(phone) {
        this.setState({ billing_phone: phone });
    }

    setBillingName(name) {
        this.setState({ billing_name: name });
    }

    setBillingTax(tax) {
        this.setState({ billing_tax: tax });
    }

    setMbwayPhone(phone) {
        this.setState({ mbway_phone: phone });
    }

    getMbwayStatus(status) {
        this.setState({ mbway_status: status });
    }

    setBillingPhoneFlag(status) {
        this.setState({ billing_phone_flag: status });
    }

    getBillingPhoneFlag() {
        return this.state.billing_phone_flag;
    }

    setMbwayPhoneFlag(status) {
        this.setState({ mbway_phone_flag: status });
    }

    getMbwayPhoneFlag() {
        return this.state.mbway_phone_flag;
    }

    setIsEditing(value) {
        const { setNotReady } = this.props;
        this.setState({ isEditing: value });
        setNotReady(value);
    }

    onBillingSuccess(fields, asyncData) {
        const { savePaymentInformation, showErrorNotification, addressBillingNotStored, addressBillingTmpCreating, setAddressBillingNotStored } = this.props;
        const { billing_phone, mbway_phone, billing_name, billing_tax, isEditing, isCreatingAddress } = this.state;

        let nifValid = validateNIF({ value: billing_tax });

        if(!nifValid) {
            showErrorNotification(__('The NIF number is invalid!'));
            return;
        }

        if (isCreatingAddress) {
            return;
        }

        let addr = this._getAddress(fields);

        if(addressBillingTmpCreating && addressBillingNotStored.firstname != ''){
            addr = addressBillingNotStored;
            addr.region = '';
        }

        //const address = this._getAddress(fields);
        const paymentMethod = this._getPaymentData(asyncData);

        if(!billing_phone || !billing_phone.match(/^9[0-9]{8}$/)) {
            showErrorNotification(__('Phone must start with 9!'));
            this.setBillingPhoneFlag(false);
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
              });
            return;
        }

        this.setBillingPhoneFlag(true);


        if((paymentMethod.code == 'mbway') && (!mbway_phone || !mbway_phone.match(/^9[0-9]{8}$/))) {
            showErrorNotification(__('MBWay Phone must start with 9!'));
            this.setMbwayPhoneFlag(false);

            return;
        }

        this.setMbwayPhoneFlag(true);
        if(!addr.firstname) {
            addr.firstname = 'Cliente';
            addr.lastname = 'Final';
            addr.region = 'Localidade do Cliente Final';
            addr.region_code = '-';
            addr.save_in_address_book = false
            addr.region_id = 0;
            addr.street = ['Morada do Cliente Final'];
            addr.city = 'Localidade do Cliente Final';
            addr.country_id = 'PT';
            addr.postcode = '9999-999';

            //showErrorNotification(__('The billing address is missing. Set the address and try again.'));
            //this.getMbwayStatus(false);
            //return;
        }
        this.prepareAddress(addr, billing_phone, billing_name, billing_tax);


        if(!isEditing){
            savePaymentInformation({
                billing_address: addr,
                paymentMethod,
                mbway_phone
            });
            let cleanTmp = {
                firstname: ''
            };
            if(!addressBillingTmpCreating){
                setAddressBillingNotStored(cleanTmp);
            }
        }

    }

    prepareAddress(address, billing_phone, billing_name, billing_tax) {
        address.telephone = billing_phone;
        address.vat_id = billing_tax;
        if (!billing_name) {
            /*let nameArray = billing_name.split(' ');
            let count = nameArray.length;
            address.lastname  = nameArray[(count - 1)];
            let lastNameLength = address.lastname.length + 1;
            address.firstname = address.firstname.slice(0, -lastNameLength);
            //address.firstname = billing_name.replace(address.lastname, '');
        } else {*/
            if(address.firstname && !address.lastname) {
                /*let nameArray    = address.firstname.split(' ');
                address.firstname = nameArray[0];
                address.lastname  = nameArray.pop();*/
                let nameArray    = address.firstname.split(' ');
                let count = nameArray.length;
                address.lastname  = nameArray[(count - 1)];
                address.firstname = address.firstname.replace(address.lastname, '');
            } else if (!address.firstname && address.lastname) {
                address.lastname = address.lastname.split(' ')[0];
                //address.firstname = address.firstname.replace(address.lastname, '');
                let lastNameLength = address.lastname.length + 1;
                address.firstname = address.firstname.slice(0, -lastNameLength);
            }
        }
    }

    onBillingError(fields, invalidFields, error) {
        const { showErrorNotification } = this.props;

        if (error) {
            const { message = __('Something went wrong!') } = error;
            showErrorNotification(message);
        }
    }

    showPopup() {
        const { showPopup, termsAndConditions } = this.props;
        const {
            name: title = __('Terms and Conditions'),
            content: text = __('There are no Terms and Conditions configured.')
        } = termsAndConditions[0] || {};

        return showPopup({
            title, text
        });
    }

    _getPaymentData(asyncData) {
        const { paymentMethod: code } = this.state;

        switch (code) {
        case BRAINTREE:
            const [{ nonce }] = asyncData;

            return {
                code,
                additional_data: {
                    payment_method_nonce: nonce,
                    is_active_payment_token_enabler: false
                }
            };

        case KLARNA:
            const [{ authorization_token }] = asyncData;

            return {
                code,
                additional_data: {
                    authorization_token
                }
            };

        default:
            return { code };
        }
    }

    _getAddress(fields) {
        const { addressLinesQty, shippingAddress } = this.props;

        const {
            isSameAsShipping,
            selectedCustomerAddressId
        } = this.state;

        const formFields = getFormFields(fields, addressLinesQty);

        /*if (isSameAsShipping) {
            return shippingAddress;
        }*/

        if (!selectedCustomerAddressId) {
            return trimAddressFields(formFields);
        }

        const { customer: { addresses } } = this.props;
        const address = addresses.find(({ id }) => id === selectedCustomerAddressId);

        return {
            ...trimCustomerAddress(address),
            save_in_address_book: false
        };
    }

    render() {
        return (
            <CheckoutBilling
            { ...this.props }
            { ...this.state }
            { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBillingContainer);
 