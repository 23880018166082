import {
    Loader as SourceLoader,
} from 'SourceComponent/Loader/Loader.component';
import PropTypes from 'prop-types';

import './Loader.style'

export class Loader extends SourceLoader {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired
    };

    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <span />
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader">
                <div block="Loader" elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
};

export default Loader;
