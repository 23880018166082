import { createRef } from 'react';

import {
    ProductPage as SourceProductPage,
} from 'SourceRoute/ProductPage/ProductPage.component';

import PropTypes from 'prop-types';
import ContentWrapper from 'Component/ContentWrapper';
import ProductActions from 'Component/ProductActions';
import ProductAttributes from 'Component/ProductAttributes';
import ProductGallery from 'Component/ProductGallery';
import ProductInformation from 'Component/ProductInformation';
import ProductLinks from 'Component/ProductLinks';
import ProductReviews from 'Component/ProductReviews';
import ProductTabs from 'Component/ProductTabs';
import { RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';
import { customerType } from 'Type/Account';
import Popup from 'Component/Popup';
import Html from 'Component/Html';
import Link from 'Component/Link';
import Image from 'Component/Image';
import ProductPrice from 'Component/ProductPrice';
import AddToCart from 'Component/AddToCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGameConsoleHandheld, faTimes } from '@fortawesome/pro-light-svg-icons';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import Field from 'Component/Field';

import './ProductPage.style.override'
import isMobile from 'Util/Mobile';

export class ProductPage extends SourceProductPage {

    static propTypes = {
        customer: customerType.isRequired,
        configurableVariantIndex: PropTypes.number.isRequired,
        productOrVariant: ProductType.isRequired,
        getLink: PropTypes.func.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        updateConfigurableVariant: PropTypes.func.isRequired,
        dataSource: ProductType.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired,
        getSelectedCustomizableOptions: PropTypes.func.isRequired,
        productOptionsData: PropTypes.object.isRequired,
        setBundlePrice: PropTypes.func.isRequired,
        selectedBundlePrice: PropTypes.number.isRequired,
        device: DeviceType.isRequired
    };
    imageRef = createRef();

    renderProductPageContent() {
        const {
            parameters,
            dataSource,
            productOrVariant,
            areDetailsLoaded,
            configurableVariantIndex,
            getLink,
            updateConfigurableVariant,
            getSelectedCustomizableOptions,
            productOptionsData,
            setBundlePrice,
            selectedBundlePrice,
            showInfoPopup,
            saudaCardPoints,
            waitForCustomerData
        } = this.props;

        let MobileComponent= null;
        let is_mnsrm = false;

        if(isMobile.any()) {
            MobileComponent = <ProductActions
                saudaCardPoints= { saudaCardPoints }
                getLink={ getLink }
                updateConfigurableVariant={ updateConfigurableVariant }
                product={ dataSource }
                productOrVariant={ productOrVariant }
                parameters={ parameters }
                areDetailsLoaded={ areDetailsLoaded }
                configurableVariantIndex={ configurableVariantIndex }
                getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
                productOptionsData={ productOptionsData }
                setBundlePrice={ setBundlePrice }
                selectedBundlePrice={ selectedBundlePrice }
                showPopup= { showInfoPopup }
            />;
        }

        let arrayofAttributes = [];
        let magentoAttributes = productOrVariant.custom_attributes;

        if(magentoAttributes) {
            const attributesNotAllowed = ['brand','capacity','custom_stock','pharmaceutical_form','legal_base','administration_methods'];
            magentoAttributes.forEach(attribute_item => {
                if(!attributesNotAllowed.includes(attribute_item.attribute_code)) {
                    let attribute = {};
                    attribute.heading = attribute_item.attribute_label;
                    attribute.description = attribute_item.attribute_values;
                    attribute.code = attribute_item.attribute_code;
                    arrayofAttributes.push(attribute);
                }
                if(attribute_item.attribute_code == 'indication_of_dismissal' && attribute_item.attribute_values) {
                    is_mnsrm = true;
                }
            });

            if(arrayofAttributes) {
                //Set precautions tabs as one
                let precTab = arrayofAttributes.filter(attr => {
                    return attr.code == 'precautions';
                });

                const pregPrec = arrayofAttributes.filter(attr => {
                    return attr.code == 'precautions_in_pregnancy';
                })
                if(pregPrec.length) {
                    precTab.push(pregPrec[0]);
                }
                const precAma = arrayofAttributes.filter(attr => {
                    return attr.code == 'amamentacao';
                })
                if(precAma.length) {
                    precTab.push(precAma[0]);
                }
                const pedPrec = arrayofAttributes.filter(attr => {
                    return attr.code == 'pediatric_precautions';
                })
                if(pedPrec.length) {
                    precTab.push(pedPrec[0]);
                }
                if(precTab && precTab.length) {
                    let newPrec = {
                        heading: __('Administration Methods and Precautions'),
                        description: ''
                    };
                    precTab.forEach(item => {
                        if (item.code != 'precautions') {
                            newPrec.description += '<h3 class="ProductInformation-SubTitle">'+item.heading+'</h3>';
                        }

                        newPrec.description += '<p>' + (Array.isArray(item.description) ? item.description.join('. ') + '.' : item.description) + '</p>';
                    });
                    arrayofAttributes.unshift(newPrec);
                    arrayofAttributes = arrayofAttributes.filter(attr => {
                        return attr.code != 'precautions' && attr.code != 'pediatric_precautions' && attr.code != 'precautions_in_pregnancy' && attr.code != 'amamentacao';
                    });
                }
                //Set active substances and constituents as one tab
                if(is_mnsrm) {
                    const subsTab = arrayofAttributes.filter(attr => {
                        return attr.code == 'active_substances' || attr.code == 'constituents';
                    });

                    if(subsTab && subsTab.length) {
                        let newAttr = {
                            heading: __('Substances'),
                            description: ''
                        };
                        subsTab.forEach(item => {
                            newAttr.description += '<h3 class="ProductInformation-SubTitle">'+item.heading+'</h3>';
                            item.description.forEach(desc => {
                                newAttr.description += '<p>'+desc+'</p>';
                            });
                        });
                        arrayofAttributes.unshift(newAttr);
                        arrayofAttributes = arrayofAttributes.filter(attr => {
                            return attr.code != 'active_substances' && attr.code != 'constituents';
                        });
                    }
                } else {
                    const subsTab = arrayofAttributes.filter(attr => {
                        return attr.code == 'constituents';
                    });

                    if(subsTab && subsTab.length) {
                        let newAttr = {
                            heading: __('Substances'),
                            description: ''
                        };
                        subsTab.forEach(item => {
                            item.description.forEach(desc => {
                                newAttr.description += '<p>'+desc+'</p>';
                            });
                        });
                        arrayofAttributes.unshift(newAttr);
                        arrayofAttributes = arrayofAttributes.filter(attr => {
                            return attr.code != 'active_substances' && attr.code != 'constituents';
                        });
                    }
                }

                const capTab = arrayofAttributes.filter(attr => {
                    return attr.code == 'capacity';
                });
                if(capTab && capTab.length) {
                    arrayofAttributes = arrayofAttributes.filter(attr => {
                        return attr.code != 'capacity';
                    });
                }

            }

            //Set Long Description
            const { description } = productOrVariant;
            const { html } = description || {}

            if(html && html != '' && html.replace(/(<([^>]+)>)/gi, '') != '') {
                let newAttr = {
                    heading: __('Description'),
                    description: ''
                };

                newAttr.description = html;

                arrayofAttributes.unshift(newAttr);
            }
        }

        let attributeNames = [];
        arrayofAttributes = arrayofAttributes.filter(({ code }) => code !== 'indication_of_dismissal')
        arrayofAttributes.forEach(element => {
            attributeNames.push(element.heading);
        });

        return (
            <>
                <ProductGallery
                    product={ productOrVariant }
                    areDetailsLoaded={ areDetailsLoaded }
                    waitForCustomerData={ waitForCustomerData }
                />
                <p block="ProductPage" elem="ImageMessage">{ __('Preview image') }</p>
                {  MobileComponent }
                <ProductTabs
                    tabNames = { attributeNames }
                    numberOfAttributes = { (arrayofAttributes.length - 1) }
                    product={ productOrVariant }
                >
                    { arrayofAttributes.map((item,index)=>{
                        return <ProductInformation
                            product={ { ...dataSource, parameters } }
                            description = { item.description }
                            areDetailsLoaded={ areDetailsLoaded }
                        />
                    } )}

                    {/*<ProductAttributes
                    product={ { ...dataSource, parameters } }
                    areDetailsLoaded={ areDetailsLoaded }
                    />*/}
                </ProductTabs>
            </>
        );
    }

    renderAdditionalSections() {
        const {
            dataSource,
            parameters,
            areDetailsLoaded
        } = this.props;

        return (
            <>
                { this.renderCustomizableOptions() }
            </>
        );
    }

    renderGenericContent(product) {
        const { customer, showInfoPopup, hideActivePopup, showNotification } = this.props;
        const { url, name, id, thumbnail, image, price_range } = product;

        return (
            <div
                block="GenericCard"
                elem="Wrapper"
            >
                <Link
                    block="GenericCard"
                    elem="Link"
                    to={ url }
                    onClick={ hideActivePopup }
                >
                    <figure block="GenericCard" elem="Figure">
                        <Image
                            imageRef={ this.imageRef }
                            src={ image ? image : thumbnail.url }
                            alt={ name }
                            ratio="custom"
                            mix={ { block: 'GenericCard', elem: 'Picture' } }
                            isPlaceholder={ !id }
                        />
                    </figure>
                    <strong block="GenericCard" elem="Name">{ name }</strong>
                </Link>
                <div block="GenericCard" elem="Price">
                    <ProductPrice
                        price={ price_range }
                        mix={ { block: 'GenericCard', elem: 'Price' } }
                        customer={ customer }
                        product={ product }
                        showNotification={ showNotification }
                    />
                </div>
                <div block="GenericCard" elem="AddToCart">
                    <AddToCart
                        product={ product }
                        mix={ { block: 'ProductActions', elem: 'AddToCart' } }
                        quantity={ 1 }
                        isGeneric={ true }
                    />
                </div>
            </div>
        );
    }

    renderGenericsSort() {
        const { productOrVariant, onSortChange, genericSort: { sortDirection, sortKey } } = this.props;

        if(productOrVariant.generics && productOrVariant.generics.length > 1) {
            const sortFields = [
                { id:'name_ASC', label: __('Name: A to Z'), value:'name_ASC' },
                { id:'name_DESC', label: __('Name: Z to A'), value: 'name_DESC' },
                { id:'price_ASC', label: __('Price: Low to High'), value:'price_ASC' },
                { id:'price_DESC', label: __('Price: High to Low'), value:'price_DESC' }
            ];

            return (
                <div block="ProductPageGeneric" elem="SortBar">
                    <div block="ProductPageGeneric" elem="GenericsTotal"><strong>{ productOrVariant.generics.length }</strong> { __('Result(s)') } </div>
                    <div block="ProductPageGeneric" elem="SortSelect">
                        <div block="ProductPageGeneric" elem="SortLabel">
                            <Field
                                id="generics-sort"
                                name="generics-sort"
                                type="select"
                                label={ __('SORT') }
                                mix={ { block: 'ProductPageGenericSort', elem: 'Select' } }
                                selectOptions={ sortFields }
                                value={ `${sortKey}_${sortDirection}` }
                                onChange={ onSortChange }
                            />
                        </div>
                    </div>
                </div>
            );
        }
        
        return;
    }

    sortGenerics(generics) {
        const { genericSort: { sortDirection, sortKey } } = this.props;

        generics = generics.sort((a, b) => {
            switch (sortKey) {
                case 'price':
                    if (sortDirection === 'ASC') {
                        if(a.price_range.minimum_price.final_price.value < b.price_range.minimum_price.final_price.value) return -1;
                        if(a.price_range.minimum_price.final_price.value > b.price_range.minimum_price.final_price.value) return 1;
                        return 0;
                    } else {
                        if(a.price_range.minimum_price.final_price.value > b.price_range.minimum_price.final_price.value) return -1;
                        if(a.price_range.minimum_price.final_price.value < b.price_range.minimum_price.final_price.value) return 1;
                        return 0;
                    }
                default:
                    if (sortDirection === 'ASC') {
                        if(a.name < b.name) return -1;
                        if(a.name > b.name) return 1;
                        return 0;
                    } else {
                        if(a.name > b.name) return -1;
                        if(a.name < b.name) return 1;
                        return 0;
                    }
            }
        });

        return generics;
    }

    renderGenericProducts() {
        const { productOrVariant } = this.props;

        if(productOrVariant.generics && productOrVariant.generics.length > 0) {
            let generics = this.sortGenerics(productOrVariant.generics);

            return generics.map(product => {
                return this.renderGenericContent(product);
            });
        }

        return;
    }

    render() {
        const {
            configurableVariantIndex,
            parameters,
            getLink,
            dataSource,
            updateConfigurableVariant,
            productOrVariant,
            areDetailsLoaded,
            getSelectedCustomizableOptions,
            productOptionsData,
            setBundlePrice,
            selectedBundlePrice,
            showInfoPopup,
            showSharePopup,
            baseLinkUrl,
            saudaCardPoints,
            customer,
            device
        } = this.props;
        let MobileComponent= null;
        let DesktopComponent= null;

        if(isMobile.any()) {
            MobileComponent = <ProductActions
                saudaCardPoints= { saudaCardPoints }
                mobileComponent={ true }
                getLink={ getLink }
                updateConfigurableVariant={ updateConfigurableVariant }
                product={ dataSource }
                productOrVariant={ productOrVariant }
                parameters={ parameters }
                areDetailsLoaded={ areDetailsLoaded }
                configurableVariantIndex={ configurableVariantIndex }
                getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
                productOptionsData={ productOptionsData }
                setBundlePrice={ setBundlePrice }
                selectedBundlePrice={ selectedBundlePrice }
                showSharePopup={ showSharePopup }
                baseLinkUrl={ baseLinkUrl }
            />;
        } else {
            DesktopComponent = <ProductActions
                saudaCardPoints= { saudaCardPoints }
                getLink={ getLink }
                updateConfigurableVariant={ updateConfigurableVariant }
                product={ dataSource }
                productOrVariant={ productOrVariant }
                parameters={ parameters }
                areDetailsLoaded={ areDetailsLoaded }
                configurableVariantIndex={ configurableVariantIndex }
                getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
                productOptionsData={ productOptionsData }
                setBundlePrice={ setBundlePrice }
                selectedBundlePrice={ selectedBundlePrice }
                showPopup= { showInfoPopup }
                showSharePopup={ showSharePopup }
                baseLinkUrl={ baseLinkUrl }
            />
        }
        const fromWebsite = localStorage.getItem('fromWebsite');
        return (
            <main
                block="ProductPage"
                aria-label="Product page"
                itemScope
                itemType="http://schema.org/Product"
            >
                <div>
                    <div>
                        <ContentWrapper
                            wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                            label={ __('Main product details') }
                        >
                            {  MobileComponent }
                            <div
                                block="ProductPage"
                                elem="ProductContent"
                            >
                                { this.renderProductPageContent() }
                            </div>

                            <Popup
                                id={ 'ProductPageGeneric' }
                                clickOutside={ true }
                                mix={ { block: 'ProductPageGeneric' } }
                                closedOn={ false }
                            >
                                <FontAwesomeIcon className="ProductPageGeneric-Close" onClick={  showInfoPopup } icon={ faTimes } />
                                <h3 block="ProductPageGeneric" elem="Title">{ __('Generics') }</h3>
                                { this.renderGenericsSort() }
                                <div block="ProductPageGeneric" elem="Container">
                                    { this.renderGenericProducts() }
                                </div>
                            </Popup>
                            { DesktopComponent }
                        </ContentWrapper>
                        { this.renderAdditionalSections() }
                        <ProductLinks
                            linkType={ RELATED }
                            title={ __('Recommended for you') }
                            areDetailsLoaded={ areDetailsLoaded }
                        />
                        { /*<ProductLinks
                            linkType={ UPSELL }
                            title={ __('You might also like') }
                            areDetailsLoaded={ areDetailsLoaded }
                        />*/ }
                    </div>
                </div>
            </main>
        );
    }
};

export default ProductPage;
