import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    CartPageContainer as SourceCartPageContainer,
    BreadcrumbsDispatcher,
    CartDispatcher,
} from 'SourceRoute/CartPage/CartPage.container';

import { CART } from 'Component/Header/Header.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { appendWithStoreCode } from 'Util/Url';
import { isSignedIn } from 'Util/Auth';
import { hasOutOfStockProductsInCartItems } from 'Util/Cart';
import history from 'Util/History';
import { customerType } from 'Type/Account';
import { HistoryType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { TotalsType } from 'Type/MiniCart';
import BrowserDatabase from 'Util/BrowserDatabase';
import { showPopup } from 'Store/Popup/Popup.action';

import { StorePageDispatcher } from '../../store/StorePage';
import StorePageQuery from 'Query/StorePage.query';
import { fetchMutation } from 'SourceUtil/Request';
import gtag, { install } from 'ga-gtag';
import ConfigQuery from 'Query/Config.query';
import CheckoutSaudaQuery from 'Query/CheckoutSauda.query';
import { fetchQuery } from 'Util/Request';
import { StoreFinderDispatcher } from '../../store/StoreFinder';
import { CheckoutSaudaDispatcher } from '../../store/CheckoutSauda';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export {
    BreadcrumbsDispatcher,
    CartDispatcher,
};

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    favStoreObj: state.StorePageReducer.favStoreObj,
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints,
    isLoadedSaudaCardPoint: state.CheckoutSaudaReducer.isLoadedSaudaCardPoint,
    isFetchingBuyProductWithPoints: state.CheckoutSaudaReducer.isFetchingBuyProductWithPoints,
    isCartFetching: state.CartReducer.isFetching,
    isFetchingCampaignStatus: state.CheckoutSaudaReducer.isFetchingCampaignStatus,
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    /*requestStore: (id) => {
        StorePageDispatcher.requestFavStore(dispatch, id);
    }*/
    // TODO extend mapDispatchToProps
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
    showSaudaCardBlockedPopup: (payload) => dispatch(showPopup('SaudaCardBlocked', payload)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
    requestSaudaPoints: () => {
        CheckoutSaudaDispatcher.getSaudaCardPoints(dispatch);
    },
    buyWithPoints: (sku, points) => {
        CheckoutSaudaDispatcher.productWithPoints(dispatch, sku, points);
    }
});

export class CartPageContainer extends SourceCartPageContainer {

    static propTypes = {
        updateBreadcrumbs: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        updateCrossSellProducts: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        guest_checkout: PropTypes.bool.isRequired,
        history: HistoryType.isRequired,
        totals: TotalsType.isRequired,
        device: DeviceType.isRequired,
        customer: customerType.isRequired
    };

    containerFunctions = {
        onCheckoutButtonClick: this.onCheckoutButtonClick.bind(this),
        changePoints: this.changePoints.bind(this),
        getAdvanceButtonState: this.getAdvanceButtonState.bind(this),
        handleItemQtyChange: this.handleItemQtyChange.bind(this),
        getAvailablePointsWithoutItem: this.getAvailablePointsWithoutItem.bind(this),
        setActiveCampaigns: this.setActiveCampaigns.bind(this),
        updateVouchers: this.updateVouchers.bind(this)
    };

    state = {
        checkedSaudaCardPoints: false
    }

    updateVouchers(){
        const { syncCart, requestSaudaPoints} = this.props;
        this.setState({ isLoading: true });
        fetchQuery(CheckoutSaudaQuery.applyAllVouchers()).then(
            /** @namespace Component/CheckoutSauda/Container/applyExclusiveVoucher */
            ({ applyAllVouchers }) => {
                if (applyAllVouchers.status == '200') {
                    requestSaudaPoints();
                }
                this.setState({ isLoading: false });
                syncCart();
            }
        );
        this.setState({ isLoading: false });
    }

    componentDidMount() {
        const {
            showPharacySelectorPopup,
            customer,
            updateMeta,
            totals: { items_qty,
            quote_campaigns },
            saudaCardPoints,
            customer: { sauda_card },
            showSaudaCardBlockedPopup,
            requestStores,
            requestSaudaPoints
        } = this.props;

        const { location: { state: { checkFavouritePharmacyCode, guest_pharmacy_code } = {} } } = history;

        updateMeta({ title: __('Cart') });

        this._updateBreadcrumbs();
        this._changeHeaderState();
        this._updateCrossSellProducts();

        if (!items_qty) {
            history.push({ pathname: appendWithStoreCode('/') });
        }

        if (saudaCardPoints.cardNumber) {
            this.handleItemQtyChange();
        }

        let favourite_pharmacy_code = null;
        let user = customer;

        if (!customer.favourite_pharmacy_code) {
            if (JSON.parse(localStorage.getItem('customer'))) {
                user = JSON.parse(localStorage.getItem('customer'));
                if (user.data.favourite_pharmacy_code) {
                    favourite_pharmacy_code = user.data.favourite_pharmacy_code;
                }
            }

            if (favourite_pharmacy_code == null) {
                if (localStorage.getItem('guest_pharmacy_code')) {
                    favourite_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
                }
            }
        } else {
            favourite_pharmacy_code = customer.favourite_pharmacy_code;
        }

        if (favourite_pharmacy_code == null && checkFavouritePharmacyCode && guest_pharmacy_code) {
            favourite_pharmacy_code = guest_pharmacy_code;
        }

        if (favourite_pharmacy_code == null) {
            //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
            BrowserDatabase.setItem('/cart','redirect_to');
            requestStores();
            showPharacySelectorPopup();
        }

        if(sauda_card && sauda_card.card_number){
            if(!sauda_card.is_active){
                showSaudaCardBlockedPopup();
            }
        }
        fetchQuery(ConfigQuery.getAnalyticsConfig('page_view_cart_counter')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );

        if( sauda_card && sauda_card.is_active && sauda_card.status == 'Active' ){
            this.updateVouchers()
        }
    }

    componentDidUpdate(prevProps) {
        const {
            changeHeaderState,
            totals: { items_qty, quote_campaigns},
            headerState,
            headerState: { name },
            customer,
            saudaCardPoints,
            updateCart,
            syncCart,
            requestCustomerData,
            customer: { sauda_card },
            showSaudaCardBlockedPopup
        } = this.props;

        const {
            totals: { items_qty: prevItemsQty,quote_campaigns: prevQuoteCampaigns },
            headerState: { name: prevName },
            saudaCardPoints: prevSaudaCardPoints,
            customer: prevCustomer,
            customer: { sauda_card: prevCard }
        } = prevProps;

        const { checkedSaudaCardPoints } = this.state;

        if (name !== prevName) {
            if (name === CART) {
                this._changeHeaderState();
            }
        }

        if (items_qty !== prevItemsQty) {
            const title = `${ items_qty || '0' } Items`;
            changeHeaderState({
                ...headerState,
                title
            });
            if (!items_qty) {
                history.push({ pathname: appendWithStoreCode('/') });
            }
        }


        if(customer && customer.firstname){
            this.checkPharmacyCode(customer);
        }

        if (prevSaudaCardPoints.cardNumber !== saudaCardPoints.cardNumber || items_qty !== prevItemsQty) {
            this.handleItemQtyChange();
        }

        if (prevCustomer.email !== customer.email) {
            const { location: { state: { checkFavouritePharmacyCode, guest_pharmacy_code } = {} } } = history;
            // update context and favourite if needed
            if (checkFavouritePharmacyCode) {
                if (customer.favourite_pharmacy_code !== guest_pharmacy_code) {
                    this.setState({ isLoading: true });

                    const query = StorePageQuery.getUpdateCustomerContextPharmacy(guest_pharmacy_code);
                    fetchMutation(query).then(
                        () => {
                            this.setState({ isLoading: false });
                            requestCustomerData();
                            updateCart().then(() => { syncCart() });
                        },
                        (error) => {
                            this.setState({ isLoading: false });
                            this.handleError([{message:__('Something went wrong.')}])
                        }
                    );
                }

                if (!customer.preference_pharmacy_code) {
                    const query = StorePageQuery.getUpdateCustomerPharmacyPreference(guest_pharmacy_code);
                    fetchMutation(query).then(
                        () => { this.setState({ isLoading: false }) },
                        (error) => {
                            this.setState({ isLoading: false });
                            this.handleError([{message:__('Something went wrong.')}])
                        }
                    );
                }
            }
        }

        if(prevCard != sauda_card){
            if(sauda_card && sauda_card.card_number){
                if(
                    !sauda_card.is_active &&
                    (sauda_card.status == 'PreCanceledAuto' ||
                    sauda_card.status == 'NotDefined' ||
                    sauda_card.status == 'PreCanceledBackOffice' ||
                    sauda_card.status == 'Blocked')
                ){
                    showSaudaCardBlockedPopup();
                }
            }
        }

        const wasUsedBuyWithPoints = this.wasUsedBuyWithPoints(prevProps);
        if( (!wasUsedBuyWithPoints || prevItemsQty != items_qty) && sauda_card && sauda_card.is_active && sauda_card.status == 'Active'){
            this.updateVouchers()
        }

        if(!checkedSaudaCardPoints && saudaCardPoints.points ){
            this.checkSaudaCardPoints();
        }
    }

    checkSaudaCardPoints() {
        const { totals: { items}, saudaCardPoints,buyWithPoints } = this.props;
        const pointsUsedByProduct = Object.values(items).map(item => parseInt(item.product_points));
        const pointsUsed = pointsUsedByProduct.reduce((total, number) => total + number, 0);

        if(saudaCardPoints.pointsBalance < pointsUsed){
                for (let index = 0; index < items.length; index++) {
                    if(items[index].buy_with_points ){
                        buyWithPoints(items[index].sku,'null')
                    }
                }
        }
        this.setState({checkedSaudaCardPoints:true});
    }

    wasUsedBuyWithPoints(prevProps) {
        const { totals : { items }} = this.props;
        const { totals : { items : prevItems }} = prevProps;
        const buyWithPoints = Object.values(items).map(item => item.buy_with_points);
        const prevBuyWithPoints = Object.values(prevItems).map(item => item.buy_with_points);
        return buyWithPoints.length == prevBuyWithPoints.length && buyWithPoints.every((value, index) => value === prevBuyWithPoints[index]);
    }

    checkPharmacyCode(customerValue) {
        const { showPharacySelectorPopup, history, showNotification, customer: { favourite_pharmacy_code } } = this.props;

        const {
            location: {
                pathname
            }
        } = history;

        if(!customerValue.favourite_pharmacy_code) {
            BrowserDatabase.setItem('/cart', 'redirect_to');
            //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
            //history.push({ pathname: appendWithStoreCode('/selecionador-de-farmacias') });
            //showPharacySelectorPopup();
            return;
        }

        /*if(!customer && !localStorage.getItem("guest_pharmacy_code")) {
            BrowserDatabase.setItem('/cart', 'redirect_to');
            showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
            history.push({ pathname: appendWithStoreCode('/selecionador-de-farmacias') });
            return;
        }*/
    }

    changePoints() {
        this.setState({ isPointsActive: !this.state.isPointsActive });
    }

    state = {
        isEditing: false,
        isPointsActive: false,
        customerAvailablePoints: 0,
        activeCampaigns: []
    };

    setActiveCampaigns(value) {
        this.setState({ activeCampaigns: value });
    }

    getAdvanceButtonState() {
        return false;
    }

    onCheckoutButtonClick(e) {
        const {
            showPharacySelectorPopup,
            history,
            guest_checkout,
            showOverlay,
            showNotification,
            device,
            totals,
            customer,
            favStoreObj: { sauda },
            customer: { favourite_pharmacy_code },
            requestStores
        } = this.props;

        const {
            location: {
                pathname
            }
        } = history;

        // to prevent outside-click handler trigger
        e.nativeEvent.stopImmediatePropagation();

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            let redirectUrl = CHECKOUT_URL;

            if (hasOutOfStockProductsInCartItems(totals.items)) {
                showNotification('info', __('Remove out of stock products from cart'));
                return;
            }

            if (guest_checkout || isSignedIn()) {
                if(sauda && customer && customer.sauda_card && customer.sauda_card.card_number && !pathname.includes('/checkout-sauda')) {
                    if( customer && customer.sauda_card &&
                        !customer.sauda_card.is_active &&
                        (customer.sauda_card.status == 'PreCanceledAuto' ||
                        customer.sauda_card.status == 'PreCanceledBackOffice' ||
                        customer.sauda_card.status == 'Blocked')
                    ){
                        history.push({
                            pathname: appendWithStoreCode(redirectUrl)
                        });
                    } else {
                        redirectUrl = '/checkout-sauda';
                    }
                } else {
                    if (!isSignedIn() && localStorage.getItem('guest_pharmacy_code')) {
                        redirectUrl = '/checkout-signin';
                    }
                }
                history.push({
                    pathname: appendWithStoreCode(redirectUrl)
                });

                return;
            }

            // fir notification whatever device that is
            showNotification('info', __('Please sign-in to complete checkout!'));

            if (device.isMobile) { // for all mobile devices, simply switch route
                history.push({ pathname: appendWithStoreCode('/my-account') });
                return;
            }

            // for desktop, just open customer overlay
            showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
        } else {
            BrowserDatabase.setItem('/cart', 'redirect_to');
            //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
            //history.push({ pathname: appendWithStoreCode('/selecionador-de-farmacias') });
            requestStores();
            showPharacySelectorPopup();
            return;
        }
    }

    handleItemQtyChange() {
        const { totals: { items }, saudaCardPoints } = this.props;

        if (items) {
            let customerAvailablePoints = items.reduce((a, { qty, buy_with_points, product }) => {
                if (buy_with_points && product.attributes && product.attributes.points) {
                    a -= parseInt(product.attributes.points.attribute_value * qty);
                }
                
                return a;
            }, parseInt(saudaCardPoints.points || 0));
    
            customerAvailablePoints = customerAvailablePoints < 0 ? 0 : customerAvailablePoints;
    
            this.setState({ customerAvailablePoints });
        } else {
            this.setState({ customerAvailablePoints: parseInt(saudaCardPoints.points || 0) });
        }
    }

    getAvailablePointsWithoutItem(item) {
        const { totals: { items }, saudaCardPoints } = this.props;

        let customerAvailablePoints = items.reduce((a, { item_id, qty, buy_with_points, product }) => {
            if (item_id === item.item_id) {
                return a;
            }

            if (buy_with_points && product.attributes && product.attributes.points) {
                a -= parseInt(product.attributes.points.attribute_value * qty);
            }
            
            return a;
        }, parseInt(saudaCardPoints.points || 0));

        customerAvailablePoints = customerAvailablePoints < 0 ? 0 : customerAvailablePoints;

        return customerAvailablePoints;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPageContainer);
