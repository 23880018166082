import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { DASHBOARD,MY_ORDERS,ADDRESS_BOOK,NEWSLETTER_SUBSCRIPTION,NOTIFICATION,HEALTH_CARD,CONTACTS, PHARMACY, PRESCRIPTION } from 'Type/Account';
import Link from 'Component/Link';
import Image from 'Component/Image';
// import icon_prescription from 'Component/MyAccountTabList/Images/icon-prescription.svg';
// import icon_healthcard from 'Component/MyAccountTabList/Images/icon-sauda-card.svg';
// import icon_address from 'Component/MyAccountTabList/Images/icon-address.svg';
import icon_notifications from 'Component/MyAccountTabList/Images/icon-notifications.svg';
// import icon_help from 'Component/MyAccountTabList/Images/icon-help.svg';
// import icon_pharmacy from 'Component/MyAccountTabList/Images/icon-red-cross.svg';
// import icon_ship from 'Component/MyAccountTabList/Images/icon-ship.svg';
// import icon_mydata from 'Component/MyAccountTabList/Images/icon-mydata.svg';

import icon_prescription from 'Component/MyAccountOverlay/Images/icon-prescription.svg';
import icon_healthcard from 'Component/MyAccountOverlay/Images/icon-sauda-card.svg';
import icon_address from 'Component/MyAccountOverlay/Images/icon-address.svg';
import icon_help from 'Component/MyAccountOverlay/Images/icon-help.svg';
import icon_pharmacy from 'Component/MyAccountOverlay/Images/icon-red-cross.svg';
import icon_ship from 'Component/MyAccountOverlay/Images/icon-ship.svg';
import icon_mydata from 'Component/MyAccountOverlay/Images/icon-mydata.svg';
import './MenuMobileItem.style.scss'


export class MenuMobileItem extends PureComponent {

    hideOverlay= () => {
        const { idName , hideOverlay} = this.props;
        hideOverlay(idName);
    };

    renderIconByTab() {
        const { idName } = this.props;
        let icon;

        switch (idName) {
            case 'dashboard':
                icon = icon_mydata;
                break;
            case 'address-book':
                icon = icon_address;
                break;
            case 'my-orders':
                icon = icon_ship;
                break;
            // case 'notification':
            //     icon = icon_notifications;
            //     break;
            case 'prescription':
                icon = icon_prescription;
                break;
            case 'pharmacy':
                icon = icon_pharmacy;
                break;
            case 'health-card':
                icon = icon_healthcard;
                break;
            //     break;
            // case 'newsletter-subscription':
            //     icon = faEnvelope;
            //     break;
            case 'help-and-contacts':
                icon = icon_help;
                break;
            // default:
            //     icon = faAlignLeft;
            //     break;
        }

        return (
            <Image
                    src={ icon }
                    width="20px"
                    height="20px"
                    mix={ { block: 'MenuMobile', elem: 'Icon', } }
            />
            // <FontAwesomeIcon className="MyAccountTabListItem-IconLeft" icon={icon} />
        );
    }

    render() {
        const { isSelected, idName} = this.props;
        let name = '';
        let card_route = '';

        switch (idName) {
            case 'dashboard':
                name = __('Dashboard');
                card_route = DASHBOARD;
                break;
            case 'health-card':
                name = __('Health Card');
                card_route = HEALTH_CARD;
                break;
            case 'address-book':
                name = __('My Addresses');
                card_route = ADDRESS_BOOK;
                break;
            case 'my-orders':
                name = __('My orders');
                card_route = MY_ORDERS;
                break;
            // case 'notification':
            //     name = __('Notifications');
            //     card_route = NOTIFICATION;
            //     break;
            case 'help-and-contacts':
                name = __('HelpAndContacts');
                card_route = CONTACTS;
                break;
            case 'prescription':
                name = __('Prescription Wallet');
                card_route = PRESCRIPTION;
                break;
            case 'pharmacy':
                name = __('My Pharmacy');
                card_route = PHARMACY;
                break;
            // default:
            //     break;
        }

        return (<>
                { this.renderIconByTab() }
                <Link to={ `${ MY_ACCOUNT_URL }/${ card_route }` }
                    block="MenuMobileItem"
                    elem="option"
                    mods={ { isSelected } }
                    onClick={ this.hideOverlay }
                >
                { name }
                </Link>
                </>
                );
            }

};

export default MenuMobileItem;
