import {
    Notification as SourceNotification,
} from 'SourceComponent/Notification/Notification.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

import './Notification.style.override'

export class Notification extends SourceNotification {

    render() {
        const { notification } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        let icon = faCheckCircle;

        if(mods.type == 'info') {
            icon = faExclamationTriangle;
        } else if(mods.type == 'error') {
            icon = faExclamationCircle;
        }

        return (
            <div block="Notification" mods={ mods } ref={ this.notification }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>{ __('Close') }</button>
                <p block="Notification" elem="Text"><FontAwesomeIcon icon={ icon }/> { msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }

};

export default Notification;
