/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// TODO: try SEARCH type
import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { debounce } from 'Util/Request';
import { StorePageDispatcher } from '../../store/StorePage';
import { hideActiveOverlay,toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

import StorePageInfo from './StorePageInfo.component';
import { PureComponent } from 'react';
import { customerType } from 'Type/Account';

import StorePageQuery from 'Query/StorePage.query';
import { fetchMutation } from 'SourceUtil/Request';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'

);export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const MetaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Meta/Meta.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace Route/StoreInfoPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    currentArgs: state.ProductListReducer.currentArgs,
    device: state.ConfigReducer.device,
    storeObj: state.StorePageReducer.storeObj,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    customer: state.MyAccountReducer.customer,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    cartTotals: state.CartReducer.cartTotals,
    favStoreObj: state.StorePageReducer.favStoreObj,
    isLoadingStore: state.StorePageReducer.isLoading
});

/** @namespace Route/StoreInfoPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    changeNavigationState: (state) => dispatch(changeNavigationState(BOTTOM_NAVIGATION_TYPE, state)),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    requestStore: (id) => {
        StorePageDispatcher.requestStore(dispatch, id);
    },
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showMenuServices: (payload) => dispatch(showPopup("MenuServices", payload)),
    showMenuSchedule: (payload) => dispatch(showPopup("MenuSchedule", payload)),
    showMenuMobile: (payload) => dispatch(toggleOverlayByKey("MenuMobile", payload)),
    showInfoPopup: (payload) => dispatch(showPopup('MyAccountAddressPopup', payload)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
    showCartPopup: (payload) => dispatch(showPopup('CartItemsAlert', payload)),
    toggleBreadcrumbs: () => dispatch(toggleBreadcrumbs()),
    syncWishList: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    )
});

/** @namespace Route/StoreInfoPage/Container */
export class StorePageInfoContainer extends PureComponent {
    static defaultProps = {
        ...this.defaultProps,
        isStorePage: true
    };

    __construct(props) {
        super.__construct(props);

        const {
            requestCustomerData,
            customer,
            isSignedIn
        } = props;

        if (isSignedIn && !Object.keys(customer).length) {
            requestCustomerData();
        }
    }

    state = {
        ScheduleMenu: false,
        ServicesMenu: false,
        MenuMobile: false,
        MenuScheduleMobile: false,
        MenuServicesMobile: false,
        InfoMenu:false,
        isLoading: false
    }

    static propTypes = {
        requestStores: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            id: PropTypes.number
        }).isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        customer: customerType.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        updateCart: PropTypes.func.isRequired,
        syncCart: PropTypes.func.isRequired
    };

    containerFunctions = {
        OpenSheduleMenu: this.OpenSheduleMenu.bind(this),
        OpenServicesMenu: this.OpenServicesMenu.bind(this),
        OpenMenuMobile: this.OpenMenuMobile.bind(this),
        OpenMenuSchedule: this.OpenMenuSchedule.bind(this),
        GoBack: this.GoBack.bind(this),
        OpenMenuServices: this.OpenMenuServices.bind(this),
        onInfoClick: this.onInfoClick.bind(this),
        OpenMenuInfo: this.OpenMenuInfo.bind(this),
        changeStatusPopup: this.changeStatusPopup.bind(this),
        updateContextPharmacy: this.handleUpdateCustomerContextPharmacy.bind(this)
    }

    updateMeta() {
        const { updateMeta, storeObj: { name }} = this.props;
        updateMeta({ title: name });
    }

    getStoreId() {
        const { match: { params: { id } } } = this.props;
        if(id && id.includes('-')) {
            let parts = id.split('-');
            return parts[parts.length - 1];
        }
        return id;
    }

    OpenMenuMobile() {
        this.setState({
            MenuMobile: !this.state.MenuMobile
        })

        if(!this.state.MenuMobile){
            this.GoBack();
        }
    }

    GoBack() {
        this.setState({
            MenuScheduleMobile: false,
            MenuServicesMobile: false,
            MenuInfoMobile: false
        })
    }

    OpenMenuSchedule() {
        this.setState({
            MenuScheduleMobile: !this.state.MenuScheduleMobile
        })
    }

    OpenMenuServices() {
        this.setState({
            MenuServicesMobile: !this.state.MenuServicesMobile
        })
    }

    OpenMenuInfo() {
        this.setState({
            MenuInfoMobile: !this.state.MenuInfoMobile
        })
    }

    OpenSheduleMenu() {
        const { showMenuSchedule, hideActiveOverlay } = this.props;

        this.setState({
            ScheduleMenu: !this.state.ScheduleMenu
        });

        if(!this.state.ScheduleMenu) {
            showMenuSchedule({
                title: __('MenuSchedule'),
                id: 1
            });
            this.setState({
                ServicesMenu: false
            })
        } else {
            hideActiveOverlay();
        }
    }

    changeStatusPopup() {
        const { hideActiveOverlay } = this.props;

        this.setState({
            InfoMenu: false
        })

        hideActiveOverlay();
    }

    OpenServicesMenu() {
        const { showMenuServices, hideActiveOverlay } = this.props;

        this.setState({
            ServicesMenu: !this.state.ServicesMenu
        });

        if(!this.state.ServicesMenu) {
            showMenuServices({
                title: __('MenuServices'),
                id: 2
            });
            this.setState({
                ScheduleMenu: false
            });
        } else {
            hideActiveOverlay();
        }
    }

    onInfoClick(store) {
        const { showInfoPopup, hideActiveOverlay } = this.props;

        this.setState({
            InfoMenu: !this.state.InfoMenu,
            showPopup:true,
        });

        showInfoPopup({
            action: 'MyAccountAddressPopup',
            title: __(''), // Confirm delete
            store
        });

        hideActiveOverlay();
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs, storeObj: { name } } = this.props;

        updateBreadcrumbs([{
            url: '',
            name: name
        }, {
            url: '/',
            name: __('Home')
        }]);
    }

    componentDidMount() {
        const { requestStore, toggleBreadcrumbs } = this.props;
        const storeId = this.getStoreId();
        if(storeId) {
            requestStore(storeId);
            this.updateMeta();
            toggleBreadcrumbs(false);
            // this.updateBreadcrumbs();
            //this.updateNavigationState();
        } else {
            history.push(appendWithStoreCode('/'));
        }
    }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            match: { params: { id } },
            storeObj,
            requestStore
        } = this.props;

        const {
            match: { params: { id: prevId } },
            storeObj: prevStoreObj
        } = prevProps;

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (storeObj !== prevStoreObj) {
            this.updateMeta();
            //this.updateBreadcrumbs();
        }

        /**
         * If search query has changed - update related information
         */
        if (id !== prevId) {
            requestStore(id);
            this.updateMeta();
            //this.updateBreadcrumbs();
        }
    }

    handleUpdateCustomerContextPharmacy(selectedCode, selectedPharmaUrl) {
        const { customer: { preference_pharmacy_code }, favStoreObj: { pharmacy_code: favCode }, updateCart, syncCart, isSignedIn, showErrorNotification, showSuccessNotification, requestCustomerData, requestStore, syncWishList } = this.props;

        if(favCode == selectedCode) {
            history.push(appendWithStoreCode(selectedPharmaUrl ? selectedPharmaUrl : '/'));
        } else {
            if(isSignedIn){
                if(!preference_pharmacy_code) {
                    const query = StorePageQuery.getUpdateCustomerPharmacyPreference(selectedCode);
                    fetchMutation(query).then(
                        () => {
                            this.setState({ isLoading: false }, () => {
                                showSuccessNotification(__('This pharmacy is now your favourite pharmacy.'));
                            });
                        },
                        (error) => {
                            this.handleError([{message:__('Something went wrong.')}])
                        }
                    );
                }

                const query = StorePageQuery.getUpdateCustomerContextPharmacy(selectedCode);
                fetchMutation(query).then(
                    (response) => {
                        this.setState({ isLoading: false }, () => {
                            requestCustomerData();
                            updateCart().then(
                                () => {
                                    syncCart();
                                    if (BrowserDatabase.getItem('redirect_to')) {
                                        const redirect = BrowserDatabase.getItem('redirect_to');
                                        BrowserDatabase.deleteItem('redirect_to');
                                        history.push(appendWithStoreCode(redirect));
                                    } else {
                                        history.push(appendWithStoreCode(selectedPharmaUrl ? selectedPharmaUrl : '/'));
                                    }
                                }
                            );
                            syncWishList();
                        });
                    },
                    (error) => {
                        this.handleError([{message:__('Something went wrong.')}])
                    }
                );
            } else {
                const query = StorePageQuery.getUpdateGuestContextPharmacy(selectedCode);
                fetchMutation(query).then(
                    ({updateGuestContextPharmacy}) => {

                        if(updateGuestContextPharmacy != 'false'){
                            this.setState({ isLoading: false }, () => {
                                localStorage.setItem('guest_pharmacy_code', selectedCode);

                                let obj = {
                                    time:new Date().getTime(),
                                    value:3600000,
                                    expire:3600000,
                                }
                                // You can only store strings
                                let objStr = JSON.stringify(obj);
                                localStorage.setItem('guest_pharmacy_code_expire_time',objStr);

                                requestStore(selectedCode);
                                updateCart().then(
                                    () => {
                                        syncCart();
                                        if (BrowserDatabase.getItem('redirect_to')) {
                                            const redirect = BrowserDatabase.getItem('redirect_to');
                                            BrowserDatabase.deleteItem('redirect_to');
                                            history.push(appendWithStoreCode(redirect));
                                        } else {
                                            history.push(appendWithStoreCode(selectedPharmaUrl ? selectedPharmaUrl : '/'));
                                        }
                                    }
                                );
                                syncWishList();
                            });
                            window.location.reload();
                        }
                    },
                    (error) => {
                        this.handleError([{message:__('Something went wrong.')}]);
                        showErrorNotification([{message:__('You need to be logged in to make this pharmacy as favourite.')}]);
                    }
                );
            }

        }
    }

    handleError = (error) => {
        const { showErrorNotification } = this.props;
        showErrorNotification(error);
        this.setState({ isLoading: false });
    };


    render() {
        const { storeObj } = this.props;

        return (
            <StorePageInfo
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.state }
              storeObj={ storeObj }
              // addded here to not override the container props
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePageInfoContainer);
