import {
    ProductConfigurableAttributeDropdown as SourceProductConfigurableAttributeDropdown,
} from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

import Field from 'Component/Field';

export class ProductConfigurableAttributeDropdown extends SourceProductConfigurableAttributeDropdown {

    render() {
        const {
            selectOptions,
            selectValue,
            selectName,
            selectLabel,
            onChange
        } = this.props;

        return (
            <Field
              id={ selectName }
              name={ selectName }
              type="select"
              placeholder={ __('Choose Option') }
              mix={ { block: 'ProductConfigurableAttributeDropdown' } }
              selectOptions={ selectOptions }
              value={ selectValue }
              onChange={ onChange }
              showPlaceholder={ true }
            />
        );
    }

};

export default ProductConfigurableAttributeDropdown;
