/**
 * Toogas_LifeTimer Module
 * @author Fabio Teixeira <fabio.teixeira@toogas.com>
 */
import { Field } from 'Util/Query';

/** @namespace Query/LifeTimer */
export class LifeTimerQuery {
    // TODO implement logic

    getQuery(){
        return this._getTimer();
    }

    _getTimer() {
        return new Field('getTimer')
            .addFieldList(this._getTimerFields());
    }

    _getTimerFields(){
        return [
            'timer'
        ];
    }
};

export default new (LifeTimerQuery)();
