import {
    MyAccountCustomerForm as SourceMyAccountCustomerForm,
} from 'SourceComponent/MyAccountCustomerForm/MyAccountCustomerForm.component';

import PropTypes from 'prop-types';
import FieldForm from 'Component/FieldForm';
import { customerType } from 'Type/Account';

//import 'MyAccountCustomerForm.component.style.override'
import MyAccountProfilePicture from 'Component/MyAccountProfilePicture';

export class MyAccountCustomerForm extends SourceMyAccountCustomerForm {
    // TODO implement logic
    static propTypes = {
        customer: customerType.isRequired,
        onSave: PropTypes.func.isRequired
    };

    onFormSuccess = (fields) => {
        const { onSave } = this.props;
        this.splitFullName(fields);
        if(fields.phone && fields.phone == '+351') {
            fields.phone = '';
        }
        if(fields.taxvat == "" || !fields.taxvat){
            fields.taxvat = '';
        }
        if(fields.gender != 0) {
            onSave(fields);
        }
    };

    splitFullName(fields) {

        let fullName = fields.fullname.trim();
        let nameArray    = fullName.split(' ');
        let count = nameArray.length;

        for (let i = 0; i<count; i++) {
            if (i == (count-1)) {
                fields.lastname  = nameArray[i];
            } else {
                if (fields.firstname) {
                    fields.firstname = fields.firstname + ' ' + nameArray[i];
                } else {
                    fields.firstname = nameArray[i];
                }
            }
        }


        /*let nameArray    = fields.fullname.split(' ');
        fields.firstname = nameArray[0];
        fields.lastname  = nameArray.pop();*/
        delete fields.fullname;
    }

    getDefaultValues(fieldEntry) {
        const [key] = fieldEntry;
        const { customer: { [key]: value } } = this.props;

        return {
            ...super.getDefaultValues(fieldEntry),
            value
        };
    }

    state = {
        doc_type_maxlength: 0,
        doc_type_validation: [],
        doc_type_minlength: 0,
        changed: false
    }

    get fieldMap() {
        const { customer, vatNumberValidation  } = this.props;
        const { doc_type_maxlength, doc_type_validation, doc_type_minlength, changed } = this.state;

        let dateNow = new Date(Date.now());
        let dataMax = ((dateNow.getUTCFullYear()-18) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;
        let dataMin = ((dateNow.getUTCFullYear()-200) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;

        if(!changed) {
            if(customer && customer.doc_type == 1) {
                this.setState({
                    doc_type_maxlength: 8,
                    doc_type_validation: ['cc', 'notEmpty'],
                    doc_type_minlength: 7,
                    changed: true
                });
            }
            if(customer && customer.doc_type == 2) {
                this.setState({
                    doc_type_maxlength: 10,
                    doc_type_validation: ['passport', 'notEmpty'],
                    doc_type_minlength: 4,
                    changed: true
                });
            }
        }

        if(customer.phone == __('No phone associated.')) {
            customer.phone = '';
        }

        return {
            fullname: {
                label: __('Full name'),
                validation: ['notEmpty', 'full_name'],
                maxlength: 100
            },
            lastname: {
                label: __('Last name'),
                type:'hidden',
                validation: ['notEmpty']
            },
            date_of_birth: {
                type: 'date',
                label: __('Date of Birth'),
                validation: ['notEmpty', 'date_adult', 'min_date_validation'],
                max: dataMax,
                min: dataMin
            },
            gender: {
                type: 'select',
                label: __('Gender'),
                validation: [],
                placeholder: __('Select a option'),
                value: '',
                selectOptions: [
                    {
                        id: 1,
                        value: 1,
                        label: __('Male')
                    },
                    {
                        id: 2,
                        value: 2,
                        label: __('Female')
                    },
                    {
                        id: 3,
                        value: 3,
                        label: __('Undefined')
                    }
                ]
            },
            doc_type: {
                type: 'select',
                label: __('Document ID'),
                validation: [],
                value: customer.doc_type,
                placeholder: __('Select a option'),
                selectOptions: [
                    {
                        id: 1,
                        value: 1,
                        label: __('Citizen Card')
                    },
                    {
                        id: 2,
                        value: 2,
                        label: __('Passport')
                    }
                ],
                onChange: this.onDocTypeChange
            },
            doc_number: {
                label: __('Identification Number'),
                validation: doc_type_validation,
                maxlength: doc_type_maxlength,
                minlength: doc_type_minlength
            },
            phone: {
                type: 'phone',
                label: __('Telephone'),
                validation: ['telephone_optional']
            },
            taxvat: {
                type: 'numeric',
                label: __('Tax/VAT Number'),
                validation: ['nif'],
            }
        };
    }

    onDocTypeChange = (doc_type) => {
        this.setState({
            doc_type_maxlength: 10,
            doc_type_validation: ['passport', 'notEmpty'],
            doc_type_minlength: 4,
            changed: true
        });

        if(doc_type == 1){
            this.setState({
                doc_type_maxlength: 8,
                doc_type_validation: ['cc', 'notEmpty'],
                doc_type_minlength: 7
            });
        }
    };

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    renderActions() {
        const { toggleEditForm } =  this.props;
        return (
            <>
                <button
                    type="submit"
                    block="Button"
                    mix={ { block: 'MyAccount', elem: 'Button' } }
                    formNoValidate
                >
                    { __('Save Changes') }
                </button>
                <button
                    type="button"
                    block="Button"
                    mix={ { block: 'MyAccount', elem: 'Cancel' } }
                    onClick={ toggleEditForm }
                >
                    { __('Cancel') }
                </button>
            </>

        );
    }
};

export default MyAccountCustomerForm;
