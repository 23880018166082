import { connect } from 'react-redux';
import gtag, { install } from 'ga-gtag';
import ReactPixel from 'react-facebook-pixel';
import { fetchQuery } from 'Util/Request';
import ConfigQuery from 'Query/Config.query';
import TagManager from 'react-gtm-module';

import {
    AddToCartContainer as SourceAddToCartContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';

import { showNotification } from 'Store/Notification/Notification.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state)
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    reloadCart: () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        )
    }
});

export class AddToCartContainer extends SourceAddToCartContainer {
    addSimpleProductToCart() {
        const {
            product,
            quantity,
            addProduct,
            productOptionsData,
            reloadCart,
            priceWithDiscount,
            campaignCode,
            origPrice
        } = this.props;

        addProduct({
            product,
            quantity,
            productOptionsData,
            priceWithDiscount,
            campaignCode,
            origPrice
        }).then(
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
            ([{ message }]) => {
                this.resetLoading();

                if (message === 'Encomenda limitada a 10 unidades por produto.') {
                    reloadCart();
                }
            }
        );
    }

    addConfigurableProductToCart() {
        const {
            product,
            product: { variants },
            quantity,
            addProduct,
            configurableVariantIndex,
            productOptionsData,
            priceWithDiscount,
            campaignCode,
            origPrice
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { sku: singleSku } = productOrVariant;

        addProduct({
            product: {
                ...product,
                configurableVariantIndex
            },
            quantity,
            productOptionsData,
            priceWithDiscount,
            campaignCode,
            origPrice,
            singleSku
        }).then(
            /** @namespace Component/AddToCart/Container/addConfigurableProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addConfigurableProductToCartAddProductCatch */
            ([{ message }]) => {
                this.resetLoading();

                if (message === 'Encomenda limitada a 10 unidades por produto.') {
                    reloadCart();
                }
            }
        );
    }

    afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault,
            product,
            quantity,
            origPrice
        } = this.props;

        
        fetchQuery(ConfigQuery.getAnalyticsConfig('add_to_cart')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );
        

        fetchQuery(ConfigQuery.getAnalyticsConfig('add_to_cart_counter')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );
        

        ReactPixel.track('AddToCart');
    
        TagManager.dataLayer({
            dataLayer: {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'EUR',
                    add: {                                
                      products: [{                        
                        name: product.name,
                        id: product.sku,
                        price: origPrice,
                        brand: product.custom_attributes[1].attribute_values[0],
                        category: '',
                        variant: '',
                        quantity: quantity
                       }]
                    }
                }
            }
        });

        showNotification('success', __('Product added to cart!'));
        setQuantityToDefault();

        //this.removeProductFromWishlist();
        this.setState({ isLoading: false });
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);