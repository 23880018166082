import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ProductConfigurableAttributeDropdownContainer as SourceProductConfigurableAttributeDropdownContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export class ProductConfigurableAttributeDropdownContainer extends SourceProductConfigurableAttributeDropdownContainer {
    _getSelectOptions = () => {
        const {
            option: {
                attribute_options,
                attribute_code
            },
            getIsConfigurableAttributeAvailable
        } = this.props;

        if (!attribute_options) {
            // eslint-disable-next-line no-console
            console.warn(`Please make sure "${ attribute_code }" is visible on Storefront.`);
            return [];
        }

        return Object.values(attribute_options)
            .reduce((acc, option) => {
                const { value } = option;

                const isAvailable = getIsConfigurableAttributeAvailable({
                    attribute_code,
                    attribute_value: value
                });

                if (!isAvailable) {
                    return [...acc, {
                        ...option,
                        disabled: true,
                        id: value
                    }];
                }

                return [...acc, {
                    ...option,
                    id: value
                }];
            }, []);
    };
};

export default ProductConfigurableAttributeDropdownContainer;