import {
    getInitialState as SourceGetInitialState,
    updateEveryTime,
    filterData,
    MetaReducer,
} from 'SourceStore/Meta/Meta.reducer';

export {
    updateEveryTime,
    filterData,
    MetaReducer,
};

//TODO: implement getInitialState
export const getInitialState = SourceGetInitialState;

export default MetaReducer;
