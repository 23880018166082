/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import { UPDATE_POSTS } from './PostsByCategory.action';

 export const emptyPosts = {
    items: [],
    count: 0
 };
 
 export const initialState = {
    postsByCategory: emptyPosts
 };
 
 /** @namespace ScandiPWA/Blog/Store/Posts/Reducer/postsReducer */
 export const PostsByCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_POSTS:
            const {
                posts: {
                    items,
                    total_count: count
                },
                isLoaded
            } = action;
            
            return {
                ...state,
                postsByCategory: {
                    items,
                    count,
                    isLoaded
                }
            };
        default:
            return state;
    }
 };
 
 export default PostsByCategoryReducer;
 