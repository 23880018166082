import PropTypes from 'prop-types';
import {
    CartItem as SourceCartItem,
} from 'SourceComponent/CartItem/CartItem.component';
import Field from 'Component/Field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle ,faTrashAlt, faTag, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCircle as faCircleCheck, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import  icon_sauda_product  from './images/sauda.png';
import  icon_sauda_card  from './images/card.png';
import icon_prescription from './images/prescription.png';
import CartItemPrice from 'Component/CartItemPrice';
import { roundPrice } from 'Util/Price';
import Loader from 'Component/Loader';
import Link from 'Component/Link';
import Image from 'Component/Image';
import Popup from 'Component/Popup';
import CmsBlock from 'Component/CmsBlock';

import './CartItem.style.override'
import MyAccountCoPaymentsTableContainer from 'Component/MyAccountCoPaymentsTable';

export class CartItem extends SourceCartItem {

    static propTypes = {
        //requestSaudaPoints: PropTypes.func.isRequired,
        saudaCardPoints: PropTypes.arrayOf(PropTypes.string).isRequired,
        buyWithPoints: PropTypes.func.isRequired,
        setActiveCampaigns: PropTypes.func.isRequired,
        activeCampaigns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired
    };

    static defaultProps = {
        isEditing: false,
        isLikeTable: false,
        isConfirm: false
    };

    state = {
        isProductBuyWithPoints: false,
        isProductCapacity: false,
        isProductBrand: false,
        isCampaignActive: false,
        isCampaignActiveLabel: __('Use voucher discount now'),
    }

    /*componentWillMount() {
        const {
            requestStore,
            customer: { favourite_pharmacy_code },
            requestSaudaPoints
        } = this.props;

        if(favourite_pharmacy_code) {
            requestStore(favourite_pharmacy_code);
        }

        requestSaudaPoints();
    }*/

    getSelectOptions(items) {
        const copy = [];
        items.forEach(function(item) {
            if (item != undefined) {
                copy.push({'label' : item.name, 'value' : item.value, 'id' : item.id});
            }
        });

        return copy;
    }

    renderWrapperContent() {
        const {
            isCartPage,
            isMiniCart,
            isFetchingBuyProductWithPoints,
            isCartFetching,
            is_checkout,
            isConfirm,
            item,
            item: { campaign_code, campaign_price, product: { campaigns } }
        } = this.props;
        let CartMobile = null;

        /*if (device.isMobile) {
            CartMobile =  <>
                        <div block="CartItem" elem="Card">
                            { this.renderImage() }
                            <figcaption
                                block="CartItem"
                                elem="Content"
                                mods={ { isLikeTable } }
                            >
                                { this.renderOutOfStockMessage() }
                                <div block="CartItem" elem="ContentDescription">
                                    { this.renderProductName() }
                                    { this.renderProductDetails() }
                                </div>
                            </figcaption>
                        </div>
                            { this.renderContentMobile() }
                          </>
        } else {
            CartMobile =  <div block="CartItem" elem="Card">
                            { this.renderImage() }
                            { this.renderContent() }
                        </div>
        }*/
        CartMobile =  <div block="CartItem" elem="Card" mods={ { noActions: is_checkout || isConfirm } }>
                            { this.renderImage() }
                            { this.renderContent() }
                            { isCartPage ? this.renderActions() : '' }
                        </div>

        return (
            <figure block="CartItem" elem="Wrapper">
                { CartMobile }
                { !isMiniCart && this.renderBottomInfo() }
                { /* (isFetchingBuyProductWithPoints || isCartFetching) && <Loader isLoading={true} /> */ }
                { campaign_code && campaign_price && isCartPage && campaigns && campaigns.length > 0 && this.renderNoticeCampaign() }
                { isCartPage && this.renderSavings() }
            </figure>
        );
    }

    renderSavings() {
        const { customerRequestDone, showCartCampaign, isMiniCart, item, item: { campaign_code, product: { campaigns }, sku }, customer, device, setActiveCampaigns, activeCampaigns } = this.props;

        if(isMiniCart){
            return false;
        }

        let maxValue      = 0;
        let maxPercentage = 0;
        let campaignToUse = {};
        let allCampaigns  = [];

        if ((campaigns) && (campaigns.length > 0)) {
            campaigns.map((campaign) => {
                //if(campaign.promotional_code_type_value == 'Cash' && campaign.burn_value_type != 'Quantity'){
                if(campaign.burn_value_type != null || campaign.burn_value != null){
                        if(parseInt(campaign.promotional_code_value) > maxValue){
                            maxValue = parseInt(campaign.promotional_code_value);
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                        }
                }
            });
        }

        if(customerRequestDone){
            if(!customer || (customer && campaignToUse.promotional_code_value && (!customer.sauda_card || !customer.sauda_card.card_number || !customer.sauda_card.is_active))) {
                let currencyIcon = '€';
                let valueToShow = 0;
                if (campaignToUse.promotional_code_value) {
                    valueToShow = campaignToUse.promotional_code_value;
                }
                return (
                    <div block="CartItem" elem="NoticeCampaignsNoCard">
                        <div>
                            { __("With the ") }
                            <b>{ __('Sauda Card') }</b>
                            { __(" you wold save ") }
                            <b>{ valueToShow + currencyIcon}</b>
                            { __(" on this product.") }
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <Loader isLoading={ true } />
            );
        }

        return (<></>);
    }

    renderBottomInfo() {
        const {
            saudaCardPoints,
            isLoadedSaudaCardPoint,
            is_wallet,
            item,
            item: {
                sku,
                campaigns
            },
            getCurrentProduct,
            isConfirm,
            favStoreObj: { sauda },
            isFetchingBuyProductWithPoints,
            isCartFetching
        } = this.props;

        if(is_wallet && is_wallet == sku && !isConfirm ) {
            let prescriptionNumber = null;
            if (item.customizable_options) {
                let options = item.customizable_options;
                options.forEach(element => {
                    if (element.label == 'Prescription Number') {
                        prescriptionNumber = element.values[0].value;
                    }
                });
            }
            return (
                <div block="CartItem" elem="Notice" mods={ { isWallet:true } }>
                    <img
                        src={ icon_prescription }
                        width="20px"
                        height="20px"
                    />
                    { !isConfirm && <div block="CartItem" elem="NoticeDesc">
                        <span>
                            <b>{ __('ID Prescription') }</b> <strong>{prescriptionNumber}</strong> | <FontAwesomeIcon className="CartFloating-AlertIcon" icon={ faExclamationCircle } />
                            <CmsBlock identifier={ "cart_item_wallet_notice" } />
                        </span>
                    </div> }
                </div>
            );
        }

        const { attributes = [] } = getCurrentProduct() || {};

       /*  if (!isLoadedSaudaCardPoint) {
            return (<><Loader isLoading={true} /></>);
        } */

        if (!Object.entries(attributes).length) {
            return null;
        }

        let points = 0;
        try {
            points = attributes.points.attribute_value;
        } catch(err) {
            return null;
        }

        if(sauda && points && !isConfirm) {
            if(saudaCardPoints.points && this.getSaudaVerify()){
                return this.renderNoticeSauda();
            } else {
                return this.renderNotice();
            }
        }
    }

    renderNoticeCampaign() {
        const { showCartCampaign, isMiniCart, item, item: { campaign_code, product: { campaigns, id }, sku }, customer, device, setActiveCampaigns, activeCampaigns } = this.props;

        if(!customer){
            return false;
        }

        if(isMiniCart){
            return false;
        }

        let maxValue      = 0;
        let maxPercentage = 0;
        let campaignToUse = {};
        let allCampaigns  = [];

        let customerVouchers = customer.vouchers;
        let activeCp = {};

        let flag = false;
        let discountsVariants = [];
        if ((campaigns) && (campaigns.length > 0)) {
            campaigns.map((campaign) => {
                //if(campaign.promotional_code_type_value == 'Cash' && campaign.burn_value_type != 'Quantity'){
                if(campaign.burn_value_type != null || campaign.burn_value != null){
                    if(campaign.promotional_code_type_value == 'Cash'){
                        if(parseInt(campaign.promotional_code_value) > maxValue){
                            maxValue = parseInt(campaign.promotional_code_value);
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                        }
                    //} else if(campaign.promotional_code_type_value != 'Cash' && campaign.burn_value_type != 'Quantity'){
                    } else if(campaign.promotional_code_type_value != 'Cash'){
                        const { item: { product : { price_range: { maximal_price: { final_price: { value } } } } } } = this.props;
                        let price = value * (parseInt(campaign.promotional_code_value) / 100);
                        if(price > maxValue){
                            maxValue = price;
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                        }
                    }
                }
            });
        }


        if(allCampaigns.length > 0){
            activeCp.sku = sku;
            activeCp.campaign_code = campaignToUse.code;
            activeCp.isActive = true;
            activeCp.allSkus = campaignToUse.burn_products;


            var array = activeCampaigns;
            if(activeCampaigns && activeCampaigns.length > 0 ){
                if (!array.find(o => o.sku === activeCp.sku && o.campaign_code === activeCp.campaign_code)) {
                    array.push(activeCp);
                }
            } else {
                array.push(activeCp);
            }

            setActiveCampaigns(array);

            flag = true;
            let MobileCart = null;
            let currencyIcon = '€';
            this.setState({ isCampaignActive: true });
            if(campaignToUse.promotional_code_type_value != 'Cash'){
                currencyIcon = '%';
            }

            const endDate = new Date(campaignToUse.end_date);
            const endDateStr = this.addZero(endDate.getDate()) + '/' + this.addZero(endDate.getMonth() + 1) + '/' + endDate.getFullYear();

            if (device.isMobile) {
                MobileCart = <div>
                                {/* <FontAwesomeIcon icon={faTag} /> */}
                                <span block="CartItem" elem="NoticeCampaignsDesc">
                                    { __("Voucher discount of ") }
                                    <b>{ campaignToUse.promotional_code_value + currencyIcon}</b>
                                    { __(" on product.") }
                                    <FontAwesomeIcon className="CartItem-CampaignIcon" onClick={ () => showCartCampaign(id) } icon={ faExclamationCircle } />
                                    <br/>
                                    <span>{ __('You can use this voucher until ') + endDateStr }</span>
                                    { this.renderButtonCampaigns() }
                                </span>
                            </div>
            } else {
                MobileCart = <>
                                {/* <FontAwesomeIcon icon={faTag} /> */}
                                <span>
                                    { __("Voucher discount of ") }
                                    <b>{ campaignToUse.promotional_code_value + currencyIcon}</b>
                                    { __(" on product.") }
                                    <FontAwesomeIcon className="CartItem-CampaignIcon" onClick={ () => showCartCampaign(id) } icon={ faExclamationCircle } />
                                    <br/>
                                    <span>{ __('You can use this voucher until ') + endDateStr }</span>
                                </span>
                                { this.renderButtonCampaigns() }
                            </>
            }

            return (
                <div block="CartItem" elem="NoticeCampaigns">
                    { MobileCart }
                    <Popup
                        id={ 'CartItemCampaign_' + id  }
                        clickOutside={ false }
                        mix={ { block: 'CartItem', elem: 'CartItemCampaign' } }
                        closedOn={ false }
                    >
                        <FontAwesomeIcon className="CartItem-Close" onClick={ () => showCartCampaign(id) } icon={ faTimes } />
                        <p>{ campaignToUse.description ? campaignToUse.description : campaignToUse.title }</p>
                    </Popup>
                </div>
            );

        }
        return (
            <></>
        );
    }

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    renderNotice() {
        const {
            cartData,
            device,
            favStoreObj: { sauda },
            saudaCardPoints,
            customer: { sauda_card },
            isSignedIn,
            showNotification
        } = this.props;

        let hasSaudaCard = sauda_card && sauda_card.card_number !== null;

        if (cartData == undefined) {
           return null;
        }

        if (device.isMobile) {
            if (sauda && this.getSaudaVerify()=="1") {
                return (
                    <div block="CartItem" elem="Notice">
                            {/* <img
                                src={ icon_sauda_card }
                                width="40px"
                                height="40px"
                            /> */}
                        <div block="CartItem" elem="NoticeDesc">
                            <span>
                                { __("Product with exclusive benefit(s) for Sauda Card holders.") } {
                                    (!hasSaudaCard) &&
                                        (isSignedIn ?
                                            <Link to="my-account/healthcard">{ __("Join here.") }</Link> :
                                            <a onClick={ () => showNotification('info', __('You need to be logged in to join in sauda card.')) }>{ __("Join here.") }</a>
                                        )
                                }
                            </span>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        } else {
            if (sauda && this.getSaudaVerify()=="1") {
                return (
                    <div block="CartItem" elem="Notice">
                            {/* <img
                                src={ icon_sauda_card }
                                width="40px"
                                height="40px"
                            /> */}
                            <span>
                                { __("Product with exclusive benefit(s) for Sauda Card holders.") } {
                                    (!hasSaudaCard) &&
                                        (isSignedIn ?
                                            <Link to="my-account/healthcard">{ __("Join here.") }</Link> :
                                            <a onClick={ () => showNotification('info', __('You need to be logged in to join in sauda card.')) }>{ __("Join here.") }</a>
                                        )
                                }
                            </span>
                    </div>
                );
            } else {
                return null;
            }
        }
    }

    getSaudaVerify() {
        const { getCurrentProduct } = this.props;
        const { attributes = [] } = getCurrentProduct() || {};

        if (!Object.entries(attributes).length) {
            return null;
        }

        let sauda = null;
        try {
            sauda = attributes.has_points.attribute_value;
        } catch(err) {
            return null;
        }

        return sauda;
    }

    renderNoticeSauda() {
        const {
            isPointsActive,
            changePoints,
            buyWithPoints,
            saudaCardPoints,
            device,
            getCurrentProduct,
            item: {
                buy_with_points
            } = {},
            favStoreObj: { sauda },
            customerAvailablePoints
        } = this.props;
        const currentProduct = getCurrentProduct() || {};
        const { sku, attributes = [] } = currentProduct;

        let points = 0;
        let MobileCart = null;

        if (saudaCardPoints.points) {
            points = saudaCardPoints.points
        }

        if (buy_with_points) {
            this.setState({isProductBuyWithPoints: true});
        } else {
            this.setState({isProductBuyWithPoints: false});
        }

        if (!Object.entries(attributes).length) {
            return null;
        }

        let flagSauda = null;
        if (sauda == 1) {
            try {
                flagSauda = attributes.has_points.attribute_value;
            } catch(err) {
                return null;
            }
        }

        if (device.isMobile) {
            MobileCart = <div>
                            {/* <img
                                src={ icon_sauda_card }
                                width="40px"
                                height="40px"
                            /> */}
                            <span block="CartItem" elem="NoticeSaudaDesc">
                                { __("Your Card have ") }
                                <b>{ customerAvailablePoints }{ __(" Points") }</b>
                                { __(" available.") }
                                { this.renderButtonBuyWithPoints(sku, currentProduct, flagSauda) }
                            </span>
                        </div>
        } else {
            MobileCart =<>
                            {/* <img
                                src={ icon_sauda_card }
                                width="40px"
                                height="40px"
                            /> */}
                            <span>
                                { __("Your Card have ") }
                                <b>{ customerAvailablePoints }{ __(" Points") }</b>
                                { __(" available.") }
                            </span>
                            { this.renderButtonBuyWithPoints(sku, currentProduct, flagSauda) }
                        </>
        }

        return (
            <div block="CartItem" elem="NoticeSauda">
                { MobileCart }
            </div>
        );
    }

    renderButtonCampaigns() {
        const { item: { item_id, campaign_is_active } } = this.props;
        const { isCampaignActiveLabel } = this.state;

        return (
            <div block="CartItem" elem="ManageCampaign">
                <b>{ isCampaignActiveLabel }</b>
                <button
                    block="CartItem"
                    elem="ToggleCampaign"
                    mods={ { isActive: campaign_is_active } }
                    aria-label="Manage Campaign"
                    onClick={ () => this.changeCampaignStatus(item_id) }
                >
                    <span></span>
                </button>
            </div>
        );
    }

    changeCampaignStatus = (item_id) => {
        const { handleChangeCampaignStatus } = this.props;
        handleChangeCampaignStatus(item_id);
    }

    renderButtonBuyWithPoints(sku, product, flagSauda) {
        const { isProductBuyWithPoints } = this.state;
        const { saudaCardPoints: { points }, item: { qty }, item, getAvailablePointsWithoutItem, customer, customer: { favourite_pharmacy_code, sauda_card } } = this.props;


        if (flagSauda) {
            let disabled = false;

            if (product.attributes && product.attributes.points) {
                let _points = parseInt(product.attributes.points.attribute_value) * qty;

                const pointsAvailable = typeof getAvailablePointsWithoutItem == 'function'?
                    getAvailablePointsWithoutItem(item) : points;

                if (parseInt(pointsAvailable) < _points) {
                    disabled = true;
                }
            } else {
                disabled = true;
            }

            if( customer && sauda_card &&
                !sauda_card.is_active &&
                (sauda_card.status == 'PreCanceledAuto' ||
                sauda_card.status == 'NotDefined' ||
                sauda_card.status == 'PreCanceledBackOffice' ||
                sauda_card.status == 'Blocked')
            ){
                disabled = true;
            }

            return (
                <div block="CartItem" elem="BuyPoints">
                    <b>{ __(" Buy with Points ") }</b>
                    <button
                        block="CartItem"
                        elem="TogglePoints"
                        mods={ { isActive: isProductBuyWithPoints } }
                        disabled={ disabled }
                        aria-label="Change to points"

                        onClick={ () => this.buyProductWithPointsAction(sku, product) }
                    >
                        <span></span>
                    </button>
                </div>
            );
        }
    }

    buyProductWithPointsAction(sku, product) {
        const { buyWithPoints, saudaCardPoints, showNotification, item, getAvailablePointsWithoutItem } = this.props;
        const { qty } = item;
        const { isProductBuyWithPoints } = this.state;

        let points = 0;

        try {
            points = product.attributes.points.attribute_value * qty;
        } catch(err) {
            return null;
        }

        if (points == null || points == 0) {
            return;
        }

        if (isProductBuyWithPoints) {
            points = 'null';
            buyWithPoints(sku, points);
        } else {
            const availablePoints = typeof getAvailablePointsWithoutItem === 'function' ?
                getAvailablePointsWithoutItem(item) : saudaCardPoints.points;

            if (parseInt(availablePoints) >= parseInt(points)) {
                buyWithPoints(sku, points);
            } else {
                showNotification('info', 'Não tem pontos suficientes no Cartão.');
            }
        }
    }

    renderProductDetails() {
        const { isProductCapacity, isProductBrand } = this.state;

        const { item, device, is_wallet, item: { qty }, getCurrentProduct, isConfirm, is_checkout } = this.props;
        const { attributes = [], custom_attributes = [] } = getCurrentProduct() || {};
        let showcase_name = '';
        let brand = '';

        try {
            showcase_name = attributes.showcase_name.attribute_value;
        } catch(err) {
            //escape silently
        }

        try {
            const aux = custom_attributes.filter(item => {
                return item.attribute_code == 'brand';
            });

            if(aux.length) {
                brand = aux[0].attribute_values[0];
            }
        } catch(err) {
            //escape silently
        }

        return (
            <>
                <div block="CartItem" elem="Details">
                { !isConfirm  ?
                <>
                    { brand && <span block="CartItem" elem="Brand">{ brand }</span> }
                    {/* { showcase_name && brand && <span> | </span> }
                    { showcase_name && <span block="CartItem" elem="Capacity">{ showcase_name }</span> } */}
                    {/* <span block="CartItem" elem="Details-Qtd">{ __('qty ') + item.qty }</span> */}
                </>
                : device.isMobile ? '' : __("Qtd. ") + qty }
                </div>
                { isConfirm && !device.isMobile ? this.renderProductSubtotal() : '' }
            </>
        );
    }
    renderProductValue(){
        const { item, customer: { favourite_pharmacy_code } } = this.props;
        const value = roundPrice(item.price);

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            if (item.buy_with_points) {
                return (
                    <div block="CartItem" elem="DetailsPrice">
                        <span aria-label={ __('Current product price') } block= 'CartItem' elem= 'Price'>
                            <data value={ value }>{ item.product_points + __(" Points") }</data>
                        </span>
                    </div>
                );
            } else {
                return (
                    <div block="CartItem" elem="DetailsPrice">
                        { this.renderProductPrice() }
                    </div>
                );
            }
        }
    }

    renderProductSubtotal() {
        const {
            isLikeTable,
            currency_code,
            item,
            item: {
                row_total,
                row_total_incl_tax,
                qty,
                sku,
                price
            },
            isPointsActive,
            getCurrentProduct,
            is_wallet,
            favStoreObj: { sauda },
            customer: { favourite_pharmacy_code },
            isConfirm,
            buyWithPointsFlag
        } = this.props;

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            let points = 0;
            const currentProduct = getCurrentProduct() || {};
            const { attributes = [] } = currentProduct;

            if (sauda) {
                try {
                    points = attributes.points.attribute_value * qty;
                } catch(err) {
                    //escape silently
                }
            }

            let iswallet = false;

            if(is_wallet && is_wallet == sku) {
                iswallet = true;
            } else {
                iswallet = false;
            }

            return (
                <CartItemPrice
                    is_wallet = { false }
                    is_walletSub = { iswallet }
                    points={ points }
                    currentProduct={ currentProduct }
                    isPointsActive={ isPointsActive }
                    priceType={ "sub" }
                    row_total={ row_total }
                    singlePrice={ price }
                    row_total_incl_tax={ row_total_incl_tax }
                    currency_code={ currency_code }
                    isConfirm={ isConfirm }
                    isSubtotal={ true }
                    mix={ {
                        block: 'CartItem',
                        elem: 'Price',
                        mods: { isLikeTable, isSub: true, iswallet }
                    } }
                    buyWithPointsFlag={ buyWithPointsFlag }
                    item={ item }
                />
            );
        } else {
            return (
                <div block="ProductPrice" aria-label={ __('Product Price') } mix={{
                    block: 'CartItem',
                    elem: 'Price',
                    mods: { isLikeTable, isSub: true }
                }}>
                </div>
            );
        }
    }

    renderProductPrice() {
        const {
            isLikeTable,
            currency_code,
            getCurrentProduct,
            item,
            item: {
                row_total,
                row_total_incl_tax,
                price,
                sku,
                qty
            },
            is_wallet,
            isConfirm,
            is_checkout,
            isMiniCart,
            isCartPage,
            favStoreObj: { sauda },
            customer: { favourite_pharmacy_code },
            buyWithPointsFlag
        } = this.props;

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            const currentProduct = getCurrentProduct() || {};
            let iswallet = false;
            let qtyInfo = '';

            if(is_wallet && is_wallet == sku) {
                iswallet = true;
            }
            let points = 0;

            if (sauda) {
                if (isMiniCart) {
                    const { attributes = [] } = currentProduct;
                    try {
                        points = attributes.points.attribute_value;
                    } catch(err) {
                        //escape silently
                    }
                }
            }

            if(is_checkout) {
                qtyInfo = <span block="CartItem" elem="SmQty">{ __("Qtd. ") + qty }</span>;
            }

            return (
                <>
                    { qtyInfo }
                    <CartItemPrice
                        is_wallet = { iswallet }
                        is_walletSub = { isConfirm?iswallet:false }
                        is_checkout={ is_checkout }
                        isMiniCart={ isMiniCart }
                        isCartPage={ isCartPage }
                        isConfirm={ isConfirm }
                        points={ points }
                        currentProduct={ currentProduct }
                        buyWithPointsFlag={ buyWithPointsFlag }
                        row_total={ price }
                        row_total_incl_tax={ row_total_incl_tax }
                        currency_code={ currency_code }
                        mix={ {
                            block: 'CartItem',
                            elem: 'Price',
                            mods: { isLikeTable }
                        } }
                        item={ item }
                    />
                </>
            );
        } else {
            return (
                <div block="ProductPrice" aria-label={ __('Product Price') } mix={ {
                    block: 'CartItem',
                    elem: 'Price',
                    mods: { isLikeTable }
                } }>
                </div>
            );
        }
    }


    renderActions() {
        const {
            isEditing,
            isLikeTable,
            device,
            isMiniCart
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <div
              block="CartItem"
              elem="Actions"
              mods={ { isEditing, isLikeTable } }
            >
                { this.renderProductPrice() }
                { this.renderQuantityChangeField() }
                { !isMiniCart && this.renderProductSubtotal() }
                { this.renderDeleteButton() }
            </div>
        );
    }

    renderWrapper() {
        const { linkTo, isProductInStock, device } = this.props;

        // TODO: implement shared-transition here?

        if (!isProductInStock || Object.keys(linkTo).length === 0) {
            // If product is out of stock, or link is not set
            return (
                <div>
                    { this.renderWrapperContent() }
                </div>
            );
        }

        if (device.isMobile) {
            return (
                <div>
                    { this.renderWrapperContent() }
                </div>
            );
        }

        return (
            <>
                { this.renderWrapperContent() }
            </>
        );
    }

    renderDeleteButton(isVisibleOnMobile = false) {
        const { handleRemoveItem, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <button
              block="CartItem"
              elem="RemoveItem"
              name="RemoveItem"
              aria-label="Remove item from cart"
              onClick={ handleRemoveItem }
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
        );
    }

    renderContentMobile() {
        const {
            isLikeTable,
            item: {
                customizable_options,
                bundle_options
            } = {},
            device,
            isConfirm
        } = this.props;

        return (<>
                { !isConfirm && <div block="CartItem" elem="ActionsMobile">
                    {/*{ this.renderProductOptions(customizable_options) }
                    { this.renderProductOptions(bundle_options) }
                    { this.renderProductConfigurations() }*/}
                    { this.renderQuantityChangeField(true) }
                    { this.renderProductPrice() }
                    <div block="CartItem" elem="HeadingWrapper">
                        { this.renderDeleteButton(true) }
                    </div>
                </div> }
            </>
        );

    }

    renderContent() {
        const {
            isLikeTable,
            item: {
                customizable_options,
                bundle_options,
                sku
            } = {},
            device,
            is_checkout,
            is_wallet,
            isConfirm
        } = this.props;

        if((is_checkout || isConfirm) && is_wallet == sku) {
            return (
                <>
                    <figcaption
                        block="CartItem"
                        elem="Content"
                        mods={ { isLikeTable } }
                    >
                        <div block="CartItem" elem="ContentDescription">
                            { this.renderProductOptions(customizable_options) }
                            { this.renderProductName() }
                            { this.renderProductDetails() }
                        </div>
                    </figcaption>
                    { device.isMobile && <div block="CartItem" elem="MobileInfoHolder">
                        <div block="CartItem" elem="MobileInfoHolderItem" mods={{Qty:true}}>{ this.renderQuantityChangeField(true) }</div>
                        <div block="CartItem" elem="MobileInfoHolderItem" mods={{Price:true}}>{ this.renderProductSubtotal() }</div>
                        { !(is_checkout || isConfirm) ? <div block="CartItem" elem="MobileInfoHolderItem" mods={{Delete:true}}>{ this.renderDeleteButton(true) }</div> : '' }
                    </div> }
                </>
            );
        }

        if (device.isMobile) {
            return (
                <>
                    <figcaption
                        block="CartItem"
                        elem="Content"
                        mods={ { isLikeTable } }
                    >
                        { this.renderOutOfStockMessage() }
                        <div block="CartItem" elem="ContentDescription">
                            { this.renderProductName() }
                            { this.renderProductDetails() }
                            { this.renderProductOptions(customizable_options) }
                        </div>
                    </figcaption>
                    <div block="CartItem" elem="MobileInfoHolder">
                        <div block="CartItem" elem="MobileInfoHolderItem" mods={{Qty:true}}>{ this.renderQuantityChangeField(true) }</div>
                        <div block="CartItem" elem="MobileInfoHolderItem" mods={{Price:true}}>{ this.renderProductSubtotal() }</div>
                        { !(is_checkout || isConfirm) ? <div block="CartItem" elem="MobileInfoHolderItem" mods={{Delete:true}}>{ this.renderDeleteButton(true) }</div> : '' }
                    </div>
                </>
            );
        }

        return (
            <figcaption
                block="CartItem"
                elem="Content"
                mods={ { isLikeTable } }
            >
                { this.renderOutOfStockMessage() }
                <div block="CartItem" elem="ContentDescription">
                    { this.renderProductName() }
                    { this.renderProductDetails() }
                </div>
                <div block="CartItem" elem="HeadingWrapper">
                    { this.renderDeleteButton(true) }
                </div>
                { this.renderDeleteButton(true) }
                { this.renderProductOptions(customizable_options) }
                { this.renderProductOptions(bundle_options) }
                { this.renderProductConfigurations() }
                { this.renderQuantityChangeField(true) }
            </figcaption>
        );
    }

    renderProductOption = (option) => {
        const { isMiniCart } = this.props;
        const { label, values, id } = option;

        if(label == 'Dosagem' || label == 'Code' || label == 'Code Type' || (label == 'Prescription Number' && !isMiniCart)) {
            return;
        }

        if (label == 'Descrição') {
            return (
                <div
                    block="CartItem"
                    elem="ItemOption"
                    key={ id }
                >
                    <div
                        block="CartItem"
                        elem="ItemOptionLabel"
                        key={ `label-${ id }` }
                    >
                    </div>
                    <div block="CartItem" elem="ItemOptionValuesDescription">
                        { values.map(this.renderProductOptionValue) }
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    block="CartItem"
                    elem="ItemOption"
                    key={ id }
                >
                    <div
                        block="CartItem"
                        elem="ItemOptionLabel"
                        key={ `label-${ id }` }
                    >
                    </div>
                    <div block="CartItem" elem="ItemOptionValues">
                        { label == 'Prescription Number' ? `${__('Prescription')}: ` : ''}
                        { values.map(this.renderProductOptionValue) }
                    </div>
                </div>
            );
        }
    };


    renderProductOptionValue = (optionValue, i, array) => {
        const { label, value } = optionValue;
        const isNextAvailable = Boolean(array[i + 1]);

        return (
            <span
              block="CartItem"
              elem="ItemOptionValue"
              key={ label }
            >
                { label || value }{ isNextAvailable && ', ' }
            </span>
        );
    };

   /* renderContent() {
        const {
            isLikeTable,
            item: {
                customizable_options,
                bundle_options
            } = {}
        } = this.props;

        return (
                <figcaption
                  block="CartItem"
                  elem="Content"
                  mods={ { isLikeTable } }
                >
                    { this.renderOutOfStockMessage() }
                    <div>
                        { this.renderProductName() }
                        { this.renderProductDetails() }
                    </div>
                    <div block="CartItem" elem="HeadingWrapper">
                        { this.renderDeleteButton(true) }
                    </div>
                    { this.renderProductOptions(customizable_options) }
                    { this.renderProductOptions(bundle_options) }
                    { this.renderProductConfigurations() }
                    { this.renderQuantityChangeField(true) }
                </figcaption>
        );
    }*/

    checkIsWallet() {
        const {
            item: { customizable_options }
        } = this.props;

        let isWallet = false;
        const aux = customizable_options.filter(item => {
            return item.label == 'Dosagem';
        });

        if (aux.length > 0) {
            isWallet = true;
        }

        return isWallet;
    }

    renderQuantityChangeField(isVisibleOnMobile = false) {
        const {
            item: { qty },
            minSaleQuantity,
            maxSaleQuantity,
            handleChangeQuantity,
            isProductInStock,
            device,
            is_checkout,
            isConfirm
        } = this.props;

        /* if (!isProductInStock) {
            return null;
        }*/

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        let maxQty = maxSaleQuantity;

        let itemsLength = 10 + 1;
        const listItems = [];

        if (this.checkIsWallet()) {
            maxQty = qty;
            itemsLength = maxSaleQuantity;
        }

        for (let i = 1; i < itemsLength; i++) {
            listItems.push({id:i,name:i,value:i})
        }

        const array_list = this.getSelectOptions(listItems);

        if((is_checkout || isConfirm) && device.isMobile) {
            return (
                <div block="CartItem" elem="QuantityWrapper">
                    <span block="CartItem" elem="QuantityText">{ __('Qtd. ') }</span>
                    <span>{ qty }</span>
                </div>
            );
        }

        return (
            <div block="CartItem" elem="QuantityWrapper">
                { !device.isMobile ? <span block="CartItem" elem="QuantityText">{ __('Qtd. ') }</span> : ''}
                <Field
                  id="item_qty"
                  name="item_qty"
                  type="select"
                  selectOptions={ array_list }
                  min={ minSaleQuantity }
                  max={ maxQty }
                  mix={ { block: 'CartItem', elem: 'Qty' } }
                  value={ qty }
                  onChange={ handleChangeQuantity }
                  isDisabled={ this.checkIsWallet() }
                />
            </div>
        );
    }

    renderProductName() {
        const {
            item: {
                product: {
                    name
                }
            },
            linkTo,
            hideActiveOverlay
        } = this.props;

        if(this.checkIsWallet()) {
            return;
        }

        return (
            <p
              block="CartItem"
              elem="Heading"
            >
                <Link to={ linkTo } block="CartItem" elem="ProductName" onClick={ hideActiveOverlay }>{ name }</Link>
            </p>
        );
    }

    renderImage() {
        const { linkTo, device, favStoreObj: { sauda }, is_wallet , item: { sku } } = this.props;

        if (is_wallet && is_wallet == sku) {
            return (
                <div block="CartItem" elem="Link">
                    { this.renderImageElement() }
                </div>
            );
        } else {
            return (
                <Link to={ linkTo } block="CartItem" elem="Link">
                    { this.renderImageElement() }
                </Link>
            );
        }
    }

    renderImageElement() {
        const { cartData: { quote_campaigns }, item: { product: { name, campaigns }, sku }, thumbnail, isProductInStock, getCurrentProduct, favStoreObj: { sauda }} = this.props;
        const isNotAvailable = !isProductInStock;
        const currentProduct = getCurrentProduct() || {};
        const { attributes = [] } = currentProduct;
        let flagSauda = null;

        try {
            if(sauda && attributes.has_points.attribute_value) {
                flagSauda = <img class="CartItem-FlagSauda" src={ icon_sauda_product } alt={ __('Sauda Card') } />
            }
        } catch(err) {
            //escape silently
        }

        let checkTarget = false;
        if(quote_campaigns && quote_campaigns.length > 0) {
            quote_campaigns.map(campaign => {
                const { skus } = campaign;
                if(skus && skus.includes(sku)) {
                    checkTarget = true;
                }
            });
        }

        if((sauda && campaigns && campaigns.length > 0) || checkTarget) {
            flagSauda = <img class="CartItem-FlagSauda" src={ icon_sauda_product } alt={ __('Sauda Card') } />
        }

        return (
            <>
            { flagSauda }
            <Image
              src={ thumbnail }
              mix={ {
                  block: 'CartItem',
                  elem: 'Picture',
                  mods: {
                      isNotAvailable
                  }
              } }
              ratio="custom"
              alt={ `Product ${name} thumbnail.` }
            />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    render() {
        const { isCartPage, isLoading, isMiniCart, is_wallet , item: { sku } } = this.props;

        return (
            <li block="CartItem" mods={ { isMiniCart, isWallet: is_wallet && is_wallet == sku } }>
                <Loader isLoading={ isLoading } />
                { this.renderWrapper() }
                { isCartPage ? '' : this.renderActions() }
            </li>
        );
    }
};

export default CartItem;
