import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './HealthCardAssociateForm.style';
import CmsBlock from 'Component/CmsBlock';

class HealthCardAssociateForm extends PureComponent {
    static propTypes = {
        getCardNumbersConfirmation: PropTypes.func.isRequired,
        setCardPostion: PropTypes.func.isRequired
    };

    state = {
        acceptFirst: false,
        acceptSecond: false,
        acceptThird: false,
        value1: '',
        value2: '',
        value3: ''
    };

    render() {
        const { positions } = this.props;
        const { value1, value2, value3, acceptFirst, acceptSecond, acceptThird } = this.state;

        return (
            <div block="HealthCardAssociateForm">
                <CmsBlock identifier={ "HealthCardAssociateDescription" } />
                <div class="Field-Layer title">
                    <label class="Field-Label Field-Label_isRequired title-lable">
                        { __('Document of Identification') }
                    </label>
                </div>
                <div class="FieldForm-Fields numbers-block">
                    <span class="card-position">{ positions.securityPositions[0] }º</span>
                    <div className={ acceptFirst && acceptSecond && acceptThird ? 'Field-Layer card-number accept' : 'Field-Layer card-number not-accept' }>
                        <input type="text" value={ value1 } name="first_number" onChange={this.onChangePositionFirst}/>
                    </div>

                    <span class="card-position">{ positions.securityPositions[1] }º</span>
                    <div className={ acceptFirst && acceptSecond && acceptThird ? 'Field-Layer card-number accept' : 'Field-Layer card-number not-accept' }>
                        <input type="text" value={ value2 } name="second_number" onChange={this.onChangePositionSecond}/>
                    </div>

                    <span class="card-position">{ positions.securityPositions[2] }º</span>
                    <div className={ acceptFirst && acceptSecond && acceptThird ? 'Field-Layer card-number accept' : 'Field-Layer card-number not-accept' }>
                        <input type="text" value={ value3 } name="third_number" onChange={this.onChangePositionThird}/>
                    </div>
                </div>
                <div class="HealthCardAssociateForm-Actions">
                    { this.renderActions() }
                </div>
            </div>
        );
    }

    onChangePositionFirst = (e) => {
        const { setCardPostion, positions } = this.props;

        let number = e.target.value;
        number = number.slice(0, 1);
        this.setState({
            value1: number
        });
        if (this.getCardNumberPosition(positions.securityPositions[0]) == number) {
            setCardPostion(1, number);
            this.setState({ acceptFirst: true });
        } else {
            this.setState({ acceptFirst: false });
            setCardPostion(1, number);
        }
    }

    onChangePositionSecond = (e) => {
        const { setCardPostion, positions } = this.props;

        let number = e.target.value;
        number = number.slice(0, 1);
        this.setState({
            value2: number
        });
        if (this.getCardNumberPosition(positions.securityPositions[1]) == number) {
            setCardPostion(2, number);
            this.setState({ acceptSecond: true });
        } else {
            this.setState({ acceptSecond: false });
            setCardPostion(2, number);
        }
    }

    onChangePositionThird = (e) => {
        const { setCardPostion, positions } = this.props;

        let number = e.target.value;
        number = number.slice(0, 1);
        this.setState({
            value3: number
        });
        if (this.getCardNumberPosition(positions.securityPositions[2]) == number) {
            setCardPostion(3, number);
            this.setState({ acceptThird: true });
        } else {
            this.setState({ acceptThird: false });
            setCardPostion(3, number);
        }
    }

    getCardNumberPosition(position) {
        const { cardDetails: { client: { documentNumber } } } = this.props;
        return documentNumber.charAt(position - 1);
    }

    renderActions() {
        const { getCardNumbersConfirmation } = this.props;
        return (
            <>
                <button block="Button" onClick={ getCardNumbersConfirmation } mix={ { block: 'MyAccount', elem: 'Button' } }>
                        { __('Associate Card') }
                </button>
            </>
        );
    }

}

export default HealthCardAssociateForm;
