import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MenuItem from './MenuItem.component';

/** @namespace Component/Menu/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Component/Menu/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export class MenuItemContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    static propTypes = {
        closeMenu: PropTypes.func,
        onCategoryHover: PropTypes.func,
        item: PropTypes.object.isRequired,
        onClickOut: PropTypes.func
    };

    static defaultProps = {
        closeMenu: () => {},
        onCategoryHover: () => {}
    };

    onItemClick() {
        const { closeMenu } = this.props;
        window.scrollTo({ top: 0 });
        closeMenu();
    }

    containerFunctions = {
        handleCategoryHover: this.handleCategoryHover.bind(this),
        onItemClick: this.onItemClick.bind(this)
    };

    componentDidMount() {
        //document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        //document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        const { onClickOut, activeMenuItemsStack } = this.props;

        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) &&  activeMenuItemsStack.length == 0) {
            try{
                onClickOut();
            }catch(t){

            }
        }
    }

    handleCategoryHover() {
        const { onCategoryHover, item } = this.props;

        onCategoryHover(item);
    }

    render() {
        const { device } = this.props;

        if(device.isMobile) {
            return (
                    <MenuItem
                        { ...this.props }
                        { ...this.containerFunctions }
                    />
            );
        } else {
            return (
                <div ref={this.wrapperRef}>
                    <MenuItem
                        { ...this.props }
                        { ...this.containerFunctions }
                    />
                </div>

            );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
