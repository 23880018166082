import {
    NavigationTabs as SourceNavigationTabs,
} from 'SourceComponent/NavigationTabs/NavigationTabs.component';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation, faStore, faPaste, faBars, faUserAlt } from '@fortawesome/pro-light-svg-icons';
import { customerType } from 'Type/Account';
import { DeviceType } from 'Type/Device';
import { isSignedIn } from 'Util/Auth';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Overlay from 'Component/Overlay';
import icon_locator from './images/iconLocalize.svg';
import icon_store from './images/iconLojaFarmCia.svg';
import icon_menu from './images/iconMenu.svg';
import icon_prescription from './images/iconMenuReceitas.svg';
import icon_login from 'Component/Header/images/login.png';
import icon_logged from 'Component/Header/images/logged.png';
import BrowserDatabase from 'Util/BrowserDatabase';

import MenuMobile from 'Component/MenuMobile';
import './NavigationTabs.style.override.scss'

export class NavigationTabs extends SourceNavigationTabs {

    static propTypes = {
        device: DeviceType.isRequired,
        customer: customerType.isRequired,
        hideOverlay: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        activeTab: PropTypes.string.isRequired,
        isMyAccount: PropTypes.bool
    };

    renderMap = {
        //home: this.renderHomeButton.bind(this),
        menu: this.renderMenuButton.bind(this),
        //minicart: this.renderMinicartButton.bind(this),
        locator: this.renderLocatorButton.bind(this),
        store: this.renderStoreButton.bind(this),
        prescription: this.renderPrescriptionButton.bind(this),
        account: this.renderAccountButton.bind(this)
    };

    renderLocatorButton(isActive = false) {
        const { handleOverlayAndActiveTab } = this.props;

        return (
            <Link
                to= "/localizador-de-farmacias"
                block="NavigationTabs"
                elem="Button"
                onClick={ handleOverlayAndActiveTab }
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                >
                     <Image
                        src={ icon_locator }
                        width="20px"
                        height="20px"
                        mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                    />
                </div>
                <p block="NavigationTabs" elem="ButtonLabel">
                    { __('Locator') }
                </p>
            </Link>
        );
    }

    onClickSelectorRedirect = () => {
        const {
            handleOverlayAndActiveTab
        } = this.props;
        BrowserDatabase.setItem('/', 'redirect_to');
        handleOverlayAndActiveTab();
    }

    renderStoreButton(isActive = false) {
        const {
            customer: { favourite_pharmacy_code },
            favStoreObj: { store_url },
            handleOverlayAndActiveTab,
            showPharmacySelectorPopup
        } = this.props;

        let storeUrl = '/selecionador-de-farmacias';

        if(favourite_pharmacy_code || localStorage.getItem('guest_pharmacy_code')){
            storeUrl = store_url ? store_url : '/';
        }

        if(!favourite_pharmacy_code && !localStorage.getItem('guest_pharmacy_code')){
            return (
                <Link
                    to="#"
                    block="NavigationTabs"
                    elem="Button"
                    onClick={ () => showPharmacySelectorPopup() }
                >
                    <div
                      block="Header"
                      elem="Button"
                      mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                    >
                         <Image
                            src={ icon_store }
                            width="20px"
                            height="20px"
                            mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                        />
                    </div>
                    <p block="NavigationTabs" elem="ButtonLabel">
                        { __('Pharmacy') }
                    </p>
                </Link>
            );
        } else {
            return (
                <Link
                    to={ storeUrl }
                    block="NavigationTabs"
                    elem="Button"
                    onClick={ favourite_pharmacy_code || localStorage.getItem('guest_pharmacy_code') ? handleOverlayAndActiveTab : this.onClickSelectorRedirect }
                >
                    <div
                      block="Header"
                      elem="Button"
                      mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                    >
                         <Image
                            src={ icon_store }
                            width="20px"
                            height="20px"
                            mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                        />
                    </div>
                    <p block="NavigationTabs" elem="ButtonLabel">
                        { __('Pharmacy') }
                    </p>
                </Link>
            );
        }
    }

    renderPrescriptionButton(isActive = false) {
        const { hideActiveOverlay, customer: { favourite_pharmacy_code }, showPharmacySelectorPopupOrLoginWarning } = this.props;

        if(!favourite_pharmacy_code && !localStorage.getItem('guest_pharmacy_code')){

            return (
                <Link
                    to="#"
                    block="NavigationTabs"
                    elem="Button"
                    onClick={ showPharmacySelectorPopupOrLoginWarning }
                >
                    <div
                      block="Header"
                      elem="Button"
                      mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                    >
                         <Image
                            src={ icon_prescription }
                            width="20px"
                            height="20px"
                            mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                        />
                    </div>
                    <p block="NavigationTabs" elem="ButtonLabel">
                        { __('Prescriptions') }
                    </p>
                </Link>
            );
        } else {
            return (
                <Link
                    to="/my-account/prescription"
                    block="NavigationTabs"
                    elem="Button"
                    onClick={ hideActiveOverlay }
                >
                    <div
                      block="Header"
                      elem="Button"
                      mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                    >
                         <Image
                            src={ icon_prescription }
                            width="20px"
                            height="20px"
                            mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                        />
                    </div>
                    <p block="NavigationTabs" elem="ButtonLabel">
                        { __('Prescriptions') }
                    </p>
                </Link>
            );
        }
    }

    renderMenuButton(isActive = false) {
        const { onMenuButtonClick } = this.props;

        return (
            <button
              key="menu"
              block="NavigationTabs"
              elem="Button"
              aria-label="Go to menu"
              onClick={ onMenuButtonClick }
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                >
                     <Image
                        src={ icon_menu }
                        width="20px"
                        height="20px"
                        mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                    />
                </div>
                <p block="NavigationTabs" elem="ButtonLabel">
                    { __('Menu') }
                </p>
            </button>
        );
    }


    renderMenuMobile() {
        const {
            hideOverlay,
            handleLogout,
            activeTab,
            hideActiveOverlay,
            favStoreObj,
            isMyAccount,
            customer,
            showSaudaCardPopup,
            baseLinkUrl,
            showMenuOverlay,
            logo_alt,
            header_logo_src,
            saudaCardPoints
        } = this.props;

        return(
            <Overlay
              id={ "MenuMobile" }
              clickOutside={ false }
              mix={ { block: 'MenuMobile', mods: { isMyAccount } } }
            >
                <MenuMobile
                    customer={ customer }
                    storeObj={ favStoreObj }
                    hideOverlay={ hideOverlay }
                    hideActiveOverlay={ hideActiveOverlay }
                    activeTab={ activeTab }
                    handleLogout={ handleLogout }
                    showSaudaCardPopup={ showSaudaCardPopup }
                    baseLinkUrl={ baseLinkUrl }
                    showMenuOverlay={ showMenuOverlay }
                    logo_alt={ logo_alt }
                    header_logo_src={ header_logo_src}
                    saudaCardPoints={ saudaCardPoints }
                ></MenuMobile>
            </Overlay>
        );
    }

    renderUserPhoto() {
        const { customer: { photo } } = this.props;

        if(photo) {
            return (
                <Image
                mix={ { block: 'NavigationTabs', elem: 'UserPhoto' } }
                src={ photo }
            />
            );
        }

        return(
            <Image
                    src={ icon_logged }
                    width="20px"
                    height="20px"
                    mix={ { block: 'NavigationTabs', elem: 'Logged' } }
                />
        );
    }

    renderAccountOverlay() {
        const {
            onSignIn,device
        } = this.props;

        if(device.isMobile){
            return (
                    <MyAccountOverlay
                        device = { device }
                        onSignIn={ onSignIn }
                    />
            );
        }

        return null;
    }

    renderAccountButton(isActive = false) {
        const { onMyAccountButtonClick, customer: { firstname, lastname } } = this.props;

        if(isSignedIn()) {
            return (
                <>
                <button
                  key="account"
                  block="NavigationTabs"
                  elem="Button"
                  onClick={ onMyAccountButtonClick }
                  aria-label="Open my account"
                >
                    <div
                      block="Header"
                      elem="Button"
                      mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                    >
                        { this.renderUserPhoto() }
                    </div>
                    <p block="NavigationTabs" elem="ButtonLabel">
                        { __("My Account") }
                    </p>
                </button>
                </>
            );
        }

        return (
            <button
              key="account"
              block="NavigationTabs"
              elem="Button"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                >
                    <Image
                        src={ icon_login }
                        width="20px"
                        height="20px"
                        mix={ { block: 'MyAccountOverlay', elem: 'Login', } }
                    />
                </div>
                <p block="NavigationTabs" elem="ButtonLabel">
                    { __('Account') }
                </p>
            </button>
        );
    }

    render() {
        const { navigationState: { isHidden }, device, isCheckout } = this.props;

        if (isCheckout || !device.isMobile) {
            return null;
        }
        const header = document.querySelectorAll('.Header-Wrapper.hidden')[0]

        return (
            <>
            <footer
              block="NavigationTabs"
              mods={ { isHidden } }
              mix={ { block: header ? 'hidden FixedElement' : 'FixedElement', elem: 'Bottom' } }
            >
                <nav block="NavigationTabs" elem="Nav">
                    { this.renderNavigationState() }
                </nav>
            </footer>
            { this.renderMenuMobile() }
            </>
        );
    }

};

export default NavigationTabs;
