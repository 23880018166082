import { connect } from 'react-redux';

import {
    MyAccountDispatcher as SourceMyAccountDispatcher,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountAddressPopupContainer as SourceMyAccountAddressPopupContainer,
} from 'SourceComponent/MyAccountAddressPopup/MyAccountAddressPopup.container';
import MyAccountQuery from 'Query/MyAccount.query';
import { fetchMutation } from 'Util/Request';

//TODO: implement MyAccountDispatcher
export const MyAccountDispatcher = SourceMyAccountDispatcher;

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    // TODO extend mapStateToProps
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MyAccountAddressPopupContainer extends SourceMyAccountAddressPopupContainer {
    // TODO implement logic
    handleAfterAction = () => {
        const {
            hideActiveOverlay,
            updateCustomerDetails,
            showErrorNotification,
            goToPreviousHeaderState,
            showSuccessNotification
        } = this.props;

        updateCustomerDetails().then(
            /** @namespace Component/MyAccountAddressPopup/Container/updateCustomerDetailsThen */
            () => {
                showSuccessNotification(__("Address deleted successfully!"));
                this.setState({ isLoading: false }, () => {
                    hideActiveOverlay();
                    goToPreviousHeaderState();
                });
            }, showErrorNotification
        );
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressPopupContainer);
