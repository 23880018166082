import {
    ProductDispatcher as SourceProductDispatcher,
} from 'SourceStore/Product/Product.dispatcher';

import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { updateProductDetails } from 'Store/Product/Product.action';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

export class ProductDispatcher extends SourceProductDispatcher {

    onSuccess(data, dispatch) {
        const { products: { items } } = data;

        /**
         * In case there are no items, or item count is
         * smaller then 0 => the product was not found.
         */
        if (!items || items.length <= 0) {
            dispatch(updateNoMatch(true));
            //add redirect to home if prod not found
            history.push(appendWithStoreCode(`/`));
            return;
        }

        const [product] = items;

        const product_links = items.reduce((links, product) => {
            const { product_links } = product;

            if (product_links) {
                Object.values(product_links).forEach((item) => {
                    links.push(item);
                });
            }

            return links;
        }, []);

        LinkedProductsDispatcher.then(
            ({ default: dispatcher }) => {
                if (product_links.length > 0) {
                    dispatcher.handleData(dispatch, product_links);
                } else {
                    dispatcher.clearLinkedProducts(dispatch);
                }
            }
        );

        dispatch(updateProductDetails(product));
    }

}

export default new ProductDispatcher();
