import { connect } from 'react-redux';


import CmsPage from 'Route/CmsPage';

import './MyAccountHelpAndContacts.scss'
import FieldForm from 'Component/FieldForm';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import CmsBlock from 'Component/CmsBlock';

import './MyAccountHelpAndContacts.scss'

export const mapStateToProps = (state) => ({
    pageIdentifiers: state.ConfigReducer.cms_home_page
});

export const mapDispatchToProps = (dispatch) => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

export class MyAccountHelpAndContacts extends FieldForm {

    render() {
        const { header_content: { header_cms } = {} } = window.contentConfiguration;

        return (
            <>
                <h1 block="MyAccount" elem="Heading">{ __('HelpAndContacts') }</h1>
                <div
                    block="MyAccount"
                    elem="HelpAndContacts">
                    <CmsBlock identifier={ "help_and_contacts" } />
                </div>
            </>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountHelpAndContacts);
