import {
    MyAccountSignIn as SourceMyAccountSignIn,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import Field from 'Component/Field';
import Form from 'Component/Form';
import { signInStateType } from 'Type/Account';

//import 'MyAccountSignIn.component.style.override'

export class MyAccountSignIn extends SourceMyAccountSignIn {
    scrollToElement(e) {
        if (e.target) {
            window.scrollTo(0, (e.target.offsetTop + 82)); // header height: 82px;
        }
    }

    renderSignInForm() {
        const {
            onSignInAttempt,
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            handleEmailInput,
            isCheckout,
            customMessage,
            handleFirstLogin,
            device
        } = this.props;

        if(customMessage == 'first_login') {
            handleFirstLogin();
        } else {
            return (<>
                <section block="MyAccountOverlay" elem="LoginDesc">
                { __('Log in and get access and exclusive benefits.') }
                </section>
                { customMessage && <p block="MyAccountOverlay" elem="CustomMessage">{ customMessage }</p> }
                <Form
                  key="sign-in"
                  onSubmit={ onSignInAttempt }
                  onSubmitSuccess={ onSignInSuccess }
                  onSubmitError={ onFormError }
                >
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      value={ emailValue }
                      autocomplete={ isCheckout ? 'off' : 'email' }
                      validation={ ['notEmpty', 'email'] }
                      placeholder={ __('E-mail address') }
                      onChange={ handleEmailInput }
                      onFocus={ device.isMobile ? this.scrollToElement : null }
                    />
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      autocomplete="current-password"
                      validation={ ['notEmpty'] }
                      placeholder={ __('Password') }
                      onFocus={ device.isMobile ? this.scrollToElement : null }
                    />
                    <div className="rememberMeBlock">
                        <Field
                        type="checkbox"
                        id="rememberMe"
                        name="rememberMe"
                        validation={ ['notEmpty'] }
                        placeholder={ __('Remember me') }
                        label={ __('Remember me') }
                        onFocus={ device.isMobile ? this.scrollToElement : null }
                        />
                    </div>
                    <a
                     block="MyAccountOverlay" elem="Buttons"
                      onClick={ handleForgotPassword }
                    >
                        { __('Forgot password?') }
                    </a>
                    <div block="MyAccountOverlay" elem="Buttons">
                        <button block="Button">{ __('Sign in') }</button>
                    </div>
                </Form>
                </>
            );
        }
    }

    renderAdditionalField() {
        const {
            isCheckout,
            handleCreateAccount,
            state,
            customMessage,
            handleFirstLogin
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                <section elem="ForgotPassword">
                    <h4 id="forgot-password-label">{ __('Don`t have an account?') }</h4>
                    <a
                      mods={ { isHollow: true } }
                      onClick={ handleCreateAccount }>{ __('Register now') }</a>
                </section>
            </article>
        );
    }
};

export default MyAccountSignIn;
