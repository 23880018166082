import { connect } from 'react-redux';

import {
    SearchOverlayContainer as SourceSearchOverlayContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/SearchOverlay/SearchOverlay.container';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    device: state.ConfigReducer.device
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
});

export class SearchOverlayContainer extends SourceSearchOverlayContainer {

    makeSearchRequest() {
        const {
            makeSearchRequest,
            clearSearchResults,
            searchCriteria
        } = this.props;
        const trimmedSearch = searchCriteria.trim();

        if (trimmedSearch && trimmedSearch != '') {
            clearSearchResults();
            const desired = trimmedSearch.replaceAll('-', '').replace(/[\/|\!“@#£$§%€&\[\]\(\){}=\?‘«»ºª\+\\*<>]/gi, '');
            makeSearchRequest({
                args: {
                    search: desired,
                    pageSize: 24,
                    currentPage: 1
                }
            });
        }
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(SearchOverlayContainer);
