import { connect } from 'react-redux';

import {
    HomePageContainer as SourceHomePageContainer,
    mapStateToProps,
    mapDispatchToProps,
} from 'SourceRoute/HomePage/HomePage.container';

import NewsletterSubscription from 'Component/NewsletterSubscription';
import Footer from 'Component/Footer';
import InstallPrompt from 'Component/InstallPrompt';
import CmsPage from 'Route/CmsPage';

import './Home.style.override.scss'

export {
    mapStateToProps,
    mapDispatchToProps,
};

export class HomePageContainer extends SourceHomePageContainer {

    renderNewsletterBlock() {
        return (
            <div>
                <div class="newsletter-desktop">
                    <div class="news" style={{}}>
                        <p class="title" style={{}}>Receba as nossas novidades</p>
                        <div class="button" style={{}}>
                            <p style={{}}>Lorem ipsum dolor sit amet, consectetur adipiscing elitut torto orem ipsum dolor sit amet, consectetur adipiscing</p>
                            <NewsletterSubscription/>
                        </div>
                    </div>
                </div>
                <div class="newsletter-mobile">
                    <div class="news" style={{}}>
                        <p class="title" style={{   }}>Receba as nossas novidades</p>
                        <div class="button" style={{}}>
                            <p style={{}}>Lorem ipsum dolor sit amet, consectetur adipiscing elitut torto orem ipsum dolor sit amet, consectetur adipiscing</p>
                            <NewsletterSubscription/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div block="HomePage">
                <InstallPrompt />
                <CmsPage { ...this.props } />
                { /* this.renderNewsletterBlock() */ }
                <Footer isVisibleOnMobile />
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
