import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import MyAccountPrescriptionCard from './MyAccountPrescriptionCard.component';
import PropTypes from 'prop-types';
import { showPopup } from 'Store/Popup/Popup.action';

export const mapDispatchToProps = dispatch => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showEditPopup: (payload) => dispatch(showPopup("MyAccountPrescriptionPopup", payload))
});

export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

export class MyAccountPrescriptionCardContainer extends PureComponent {

    onInfoClick() {
        const { showEditPopup, prescription } = this.props;

        showEditPopup({
            action: "QRCODE_PRESCRIPTION",
            title: __('Prescription Details'),
            prescription
        });
    }

    static propTypes = {
        showEditPopup: PropTypes.func.isRequired
    };

    containerFunctions = {
        onInfoClick: this.onInfoClick.bind(this)
    };


    render() {
        return (
            <MyAccountPrescriptionCard
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPrescriptionCardContainer);
