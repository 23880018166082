import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './PharmacySchedule.style';

class PharmacySchedule extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderSingleScheduleLine(open, complete, morning_start, morning_end, afternoon_start, afternoon_end) {
        if(open && open != '0') {
            if(complete && complete != '0') {
                return (
                    <div block="PharmacySchedule" elem="ScheduleLine3">
                        <p block="PharmacySchedule" elem="Schedule_Info_Start">{ morning_start } - { morning_end }</p>
                        <p block="PharmacySchedule" elem="Schedule_Info_Separator">|</p>
                        <p block="PharmacySchedule" elem="Schedule_Info_End">{ afternoon_start } - { afternoon_end }</p>
                    </div>
                );
            } else {
                return (
                    <div block="PharmacySchedule" elem="ScheduleLine3">
                        <p block="PharmacySchedule" elem="Schedule_Info_Start">{ morning_start }</p>
                        <p block="PharmacySchedule" elem="Schedule_Info_Separator">-</p>
                        <p block="PharmacySchedule" elem="Schedule_Info_End">{ afternoon_end }</p>
                    </div>
                );
            }
        }

        return (
            <p block="PharmacySchedule" elem="Schedule_Info">{ __('Closed')}</p>
        );
    }

    render() {
        const { storeObj, isMobile } = this.props;
        let classCol = 'ScheduleColumns';
        let classCol1 = 'ScheduleColumn1';
        let classCol2 = 'ScheduleColumn2';

        if(isMobile) {
            classCol = 'ScheduleLineMobileColumns';
            classCol1 = 'ScheduleLineMobileColumn1';
            classCol2 = 'ScheduleLineMobileColumn2';
        }

        return (
            <div block="PharmacySchedule" elem={ classCol }>
                <div block="PharmacySchedule" elem={ classCol1 }>
                    <p block="PharmacySchedule" elem="Schedule_Info_Label"> {__('Mond.:')}</p>
                    <p block="PharmacySchedule" elem="Schedule_Info_Label"> {__('Tue.:')}</p>
                    <p block="PharmacySchedule" elem="Schedule_Info_Label"> {__('Wed.:')}</p>
                    <p block="PharmacySchedule" elem="Schedule_Info_Label"> {__('Thu.:')}</p>
                    <p block="PharmacySchedule" elem="Schedule_Info_Label"> {__('Fri.:')}</p>
                    <p block="PharmacySchedule" elem="Schedule_Info_Label"> {__('Sat.:')}</p>
                    <p block="PharmacySchedule" elem="Schedule_Info_Label"> {__('Sun.:')}</p>
                    <p block="PharmacySchedule" elem="Schedule_Info_Label"> {__('Holidays.:')}</p>
                </div>
                <div block="PharmacySchedule" elem={ classCol2 }>
                    { this.renderSingleScheduleLine(
                        storeObj.all_schedules.monday_open,
                        storeObj.all_schedules.monday_schedule_complete,
                        storeObj.all_schedules.monday_morning_start,
                        storeObj.all_schedules.monday_morning_end,
                        storeObj.all_schedules.monday_afternoon_start,
                        storeObj.all_schedules.monday_afternoon_end,
                    ) }
                    { this.renderSingleScheduleLine(
                        storeObj.all_schedules.tuesday_open,
                        storeObj.all_schedules.tuesday_schedule_complete,
                        storeObj.all_schedules.tuesday_morning_start,
                        storeObj.all_schedules.tuesday_morning_end,
                        storeObj.all_schedules.tuesday_afternoon_start,
                        storeObj.all_schedules.tuesday_afternoon_end,
                    ) }
                    { this.renderSingleScheduleLine(
                        storeObj.all_schedules.wednesday_open,
                        storeObj.all_schedules.wednesday_schedule_complete,
                        storeObj.all_schedules.wednesday_morning_start,
                        storeObj.all_schedules.wednesday_morning_end,
                        storeObj.all_schedules.wednesday_afternoon_start,
                        storeObj.all_schedules.wednesday_afternoon_end,
                    ) }
                    { this.renderSingleScheduleLine(
                        storeObj.all_schedules.thursday_open,
                        storeObj.all_schedules.thursday_schedule_complete,
                        storeObj.all_schedules.thursday_morning_start,
                        storeObj.all_schedules.thursday_morning_end,
                        storeObj.all_schedules.thursday_afternoon_start,
                        storeObj.all_schedules.thursday_afternoon_end,
                    ) }
                    { this.renderSingleScheduleLine(
                        storeObj.all_schedules.friday_open,
                        storeObj.all_schedules.friday_schedule_complete,
                        storeObj.all_schedules.friday_morning_start,
                        storeObj.all_schedules.friday_morning_end,
                        storeObj.all_schedules.friday_afternoon_start,
                        storeObj.all_schedules.friday_afternoon_end,
                    ) }
                    { this.renderSingleScheduleLine(
                        storeObj.all_schedules.saturday_open,
                        storeObj.all_schedules.saturday_schedule_complete,
                        storeObj.all_schedules.saturday_morning_start,
                        storeObj.all_schedules.saturday_morning_end,
                        storeObj.all_schedules.saturday_afternoon_start,
                        storeObj.all_schedules.saturday_afternoon_end,
                    ) }
                    { this.renderSingleScheduleLine(
                        storeObj.all_schedules.sunday_open,
                        storeObj.all_schedules.sunday_schedule_complete,
                        storeObj.all_schedules.sunday_morning_start,
                        storeObj.all_schedules.sunday_morning_end,
                        storeObj.all_schedules.sunday_afternoon_start,
                        storeObj.all_schedules.sunday_afternoon_end,
                    ) }
                    { this.renderSingleScheduleLine(
                        storeObj.all_schedules.holidays_open,
                        storeObj.all_schedules.holidays_schedule_complete,
                        storeObj.all_schedules.holidays_morning_start,
                        storeObj.all_schedules.holidays_morning_end,
                        storeObj.all_schedules.holidays_afternoon_start,
                        storeObj.all_schedules.holidays_afternoon_end,
                    ) }
                </div>
            </div>
        );
    }
}

export default PharmacySchedule;
