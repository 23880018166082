import { Field } from 'Util/Query';
import { isSignedIn } from 'Util/Auth';
import ProductListQuery from 'Query/ProductList.query';

export class CartQuery {
    getCartQuery(quoteId,pharmacy_code) {
        const query = new Field('getCartForCustomer')
            .addFieldList(this._getCartTotalsFields())
            .setAlias('cartData');

        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', quoteId);
            query.addArgument('pharmacy_code','String',pharmacy_code);
        }

        return query;
    }

    getFreeShipping(pharmacy_code) {
        const query = new Field('getFreeShipping')
        .addFieldList(this._getFreeShippingFields())
        .addArgument('pharmacy_code', 'String', pharmacy_code);

        return query;
    }

    getCreateEmptyCartMutation() {
        return new Field('createEmptyCart');
    }

    getSaveCartItemMutation(product, quoteId) {
        const mutation = new Field('saveCartItem')
            .addArgument('cartItem', 'CartItemInput!', product)
            .addFieldList(this._getSaveCartItemFields(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    getChangeCampaignStatusMutation(item_id) {
        return new Field('changeCampaignStatus')
            .addArgument('item_id', 'String!', item_id)
            .addField('message')
            .addField('status');
    }

    getQuoteCampaignsDeleteMutation(campaign) {
        return new Field('quoteCampaignsDelete')
            .addArgument('input', 'QuoteCampaignsInput!', campaign)
            .addField('message')
            .addField('status');
    }

    getRemoveCartItemMutation(item_id, quoteId) {
        const mutation = new Field('removeCartItem')
            .addArgument('item_id', 'Int!', item_id)
            .addFieldList(this._getRemoveCartItemFields(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    getApplyCouponMutation(couponCode, quoteId) {
        const mutation = new Field('applyCoupon')
            .addArgument('coupon_code', 'String!', couponCode)
            .addField(this.getCartQuery(quoteId,localStorage.getItem('guest_pharmacy_code')));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    getRemoveCouponMutation(quoteId) {
        const mutation = new Field('removeCoupon')
            .addField(this.getCartQuery(quoteId,localStorage.getItem('guest_pharmacy_code')));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    _getSaveCartItemFields(quoteId) {
        return [
            this.getCartQuery(quoteId,localStorage.getItem('guest_pharmacy_code'))
        ];
    }

    _getRemoveCartItemFields(quoteId) {
        return [
            this.getCartQuery(quoteId,localStorage.getItem('guest_pharmacy_code'))
        ];
    }

    _getCartTotalsFields() {
        return [
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'shipping_incl_tax',
            'is_virtual',
            'applied_rule_ids',
            this._getCartItemsField(),
            this._getCartDisplayConfigField(),
            this._getAppliedTaxesField(),
            'sauda_discount',
            'sauda_discount_ref',
            'sauda_discount_points',
            'is_wallet',
            this._getQuoteCampaignsField()
        ];
    }

    _getFreeShippingFields() {
        return [
            'free_shipping_value'
        ];
    }

    _getBundleOptionValuesFields() {
        return [
            'id',
            'label',
            'quantity',
            'price'
        ];
    }

    _getQuoteCampaignsField() {
        return new Field('quote_campaigns')
            .addFieldList(this._getQuoteCampaignsFields());
    }

    _getQuoteCampaignsFields() {
        return [
            'code',
            'title',
            'description',
            'value',
            'valueType',
            'skus'
        ];
    }

    _getBundleOptionValuesField() {
        return new Field('values')
            .addFieldList(this._getBundleOptionValuesFields());
    }

    _getBundleOptionsFields() {
        return [
            'id',
            'label',
            'type',
            this._getBundleOptionValuesField()
        ];
    }

    _getBundleOptionsField() {
        return new Field('bundle_options')
            .addFieldList(this._getBundleOptionsFields());
    }

    _getCustomizableOptionPriceFields() {
        return [
            'value',
            'units',
            'type'
        ];
    }

    _getCustomizableOptionPriceField() {
        return new Field('price')
            .addFieldList(this._getCustomizableOptionPriceFields());
    }

    _getCustomizableOptionValueFields() {
        return [
            'id',
            'label',
            'value',
            this._getCustomizableOptionPriceField()
        ];
    }

    _getCustomizableOptionValueField() {
        return new Field('values')
            .addFieldList(this._getCustomizableOptionValueFields());
    }

    _getCustomizableOptionsFields() {
        return new Field('customizable_options')
            .addFieldList([
                'id',
                'label',
                'is_required',
                this._getCustomizableOptionValueField(),
                'sort_order'
            ]);
    }

    _getCartItemFields() {
        return [
            'qty',
            'sku',
            'price',
            'item_id',
            'row_total',
            'row_total_incl_tax',
            'tax_amount',
            'tax_percent',
            'discount_amount',
            'discount_percent',
            this._getCustomizableOptionsFields(),
            this._getBundleOptionsField(),
            this._getProductField(),
            'buy_with_points',
            'product_points',
            'campaign_price',
            'before_campaign_price',
            'campaign_code',
            'campaign_is_active',
            'custom_total_price'
        ];
    }

    _getProductField() {
        ProductListQuery.options.isForLinkedProducts = true;

        const productQuery = new Field('product')
            .addFieldList(ProductListQuery._getProductInterfaceFields(false, true));

        ProductListQuery.options.isForLinkedProducts = false;

        return productQuery;
    }

    _getCartItemsField() {
        return new Field('items')
            .addFieldList(this._getCartItemFields());
    }

    _getCartDisplayConfigField() {
        return new Field('cart_display_config')
            .addFieldList(this._getCartDisplayConfigFields());
    }

    _getCartDisplayConfigFields() {
        return [
            'display_tax_in_price',
            'display_tax_in_subtotal',
            'display_tax_in_shipping_amount',
            'include_tax_in_order_total',
            'display_full_tax_summary',
            'display_zero_tax_subtotal'
        ];
    }

    _getAppliedTaxesField() {
        return new Field('applied_taxes')
            .addField(this._getAppliedTaxesRatesField());
    }

    _getAppliedTaxesRatesField() {
        return new Field('rates')
            .addFieldList(this._getAppliedTaxesRatesFields());
    }

    _getAppliedTaxesRatesFields() {
        return [
            'percent',
            'title'
        ];
    }
}

export default new CartQuery();
