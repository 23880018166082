import {
    MyAccountForgotPasswordSuccess as SourceMyAccountForgotPasswordSuccess,
} from 'SourceComponent/MyAccountForgotPasswordSuccess/MyAccountForgotPasswordSuccess.component';

//import 'MyAccountForgotPasswordSuccess.component.style.override'

export class MyAccountForgotPasswordSuccess extends SourceMyAccountForgotPasswordSuccess {
    // TODO implement logic
    render() {
        const { state, handleSignIn } = this.props;

        return (
            <article
              aria-labelledby="forgot-password-success"
              block="MyAccountOverlay"
              elem="Additional"
              mods={ { state } }
            >
                <p id="forgot-password-success">
                    { /* eslint-disable-next-line max-len */ }
                    { __('Wait a few moments, and check your spam folder.') }
                </p>
                <button
                  block="Button"
                  onClick={ handleSignIn }
                >
                    { __('Got it') }
                </button>
            </article>
        );
    }
};

export default MyAccountForgotPasswordSuccess;
