import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './HealthCardSms.style';
import Loader from 'Component/Loader';
import HealthCardSmsNumberForm from 'Component/HealthCardSmsNumberForm';
import HealthCardSmsConfirmCodeForm from 'Component/HealthCardSmsConfirmCodeForm';
import CmsBlock from 'Component/CmsBlock';
import { isSignedIn } from 'Util/Auth';

class HealthCardSms extends PureComponent {
    static propTypes = {
        handleSteps: PropTypes.func.isRequired,
        manageShowForm: PropTypes.func.isRequired,
        setPhoneNumber: PropTypes.func.isRequired,
        setCardDetails: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        doQuerySendPhone: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    /*onFormSuccess = () => {
        const { phoneNumber, doQuerySendPhone } = this.props;
        doQuerySendPhone(phoneNumber);
    };*/

    onPhoneChange = (value) => {
        const { setPhoneNumber } = this.props;
        setPhoneNumber(value);
    }

    onCodeConfirmChange = (value) => {
        const { setCodeConfirmation } = this.props;
        setCodeConfirmation(value);
    }

    renderForm() {
        const { doQuerySendPhone, customer } = this.props;
        return (
            <div block="HealthCard">
                <CmsBlock identifier={ "HealthCardSmsDescription" } />
                <div block="HealthCard" elem="Contact">
                    <HealthCardSmsNumberForm customer={ customer } onPhoneChange={this.onPhoneChange} doQuerySendPhone={ doQuerySendPhone } />
                </div>
                { this.renderActions() }
            </div>
        );
    }

    renderCodeConfirm() {
        const str = 'aderir-sauda';
        if(window.location.href.includes(str)){
            window.history.replaceState(null, null, "aderir-sauda-s2");
        }

        return (
            <div block="HealthCard">
                <div block="HealthCard" elem="title">
                    <CmsBlock identifier={ "HealthCardCodeValidation" } />
                </div>
                <div block="HealthCard" elem="Contact">
                    <HealthCardSmsConfirmCodeForm onCodeConfirmChange={this.onCodeConfirmChange} onCodeConfirm={ this.onCodeConfirm }/>
                    { /*this.renderConfirmActions()*/ }
                </div>
                <div className="more-actions">
                    <a block="HealthCard" elem="InfoPhone" onClick={ this.resendCode }>{ __('Resend code') }</a>
                </div>
            </div>
        );
    }

    resendCode = () => {
        const { phoneNumber, doQuerySendPhone } = this.props;
        doQuerySendPhone(phoneNumber);
    }

    renderActions() {
        const { showPopupPhone } = this.props;
        return (
            <>
                <a block="HealthCard" elem="InfoPhone" onClick= { showPopupPhone }>{ __('Why we ask the phone number?') }</a>
            </>
        );
    }

    renderConfirmActions() {
        return (
            <>
                <button
                    block="HealthCard"
                    elem="Button"
                    mix={ { block: 'Button' } }
                    onClick={ this.onCodeConfirm }
                >
                    <p
                        block="HealthCard"
                        elem="ButtonJoinNow">
                            { __('Next') }
                    </p>
                </button>
            </>
        );
    }

    onCodeConfirm = () => {
        const { manageShowForm, setCardDetails, codeConfirmation, generatedCode, showFormAssociate, cardDetails, positions, showErrorNotification } = this.props;

        if (codeConfirmation === generatedCode) {
            if (showFormAssociate) {
                setCardDetails(cardDetails, positions);
            } else {
                manageShowForm();
            }
        } else {
            showErrorNotification(__('Invalid confirmation code!'));
        }
    }


    render() {
        const { isLoading, showFormAssociate, showFormNewCard, showConfirmationCode } = this.props;

        if (showConfirmationCode) {
            return (
                <div block="HealthCardSms">
                    <Loader isLoading={ isLoading } />
                    { this.renderCodeConfirm() }
                </div>
            );
        }

        return (
            <div block="HealthCardSms">
                <Loader isLoading={ isLoading } />
                { this.renderForm() }
            </div>
        );
    }

    renderNexStep(){
        const { handleSteps, showFormAssociate, showFormNewCard } = this.props;

        if (showFormAssociate) {
            handleSteps(true);
        } else {
            handleSteps(false);
        }
    }
}

export default HealthCardSms;
