/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Slider from "react-slick";
import ProductCard from 'Component/ProductCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductList } from 'Component/ProductList/ProductList.component';

import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import './ProductCarousel.style';

/**
 * List of category products
 * @class ProductList
 * @namespace Component/ProductList/Component
 */
export class ProductCarousel extends ProductList {
    componentDidMount(){
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, {passive: false});
    }

    componentWillUnmount(){
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }

    touchStart(e){
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e){
        let isCarousel = !!e.path.find(el => el.className && el.className.includes('ProductCarousel'));
        if (isCarousel) {
            const minValue = 5; // threshold

            this.clientX = e.touches[0].clientX - this.firstClientX;
            this.clientY = e.touches[0].clientY - this.firstClientY;

            // Vertical scrolling does not work when you start swiping horizontally.
            if(Math.abs(this.clientX) > minValue){
                e.preventDefault();
                e.returnValue = false;
                return false;
            }
        }
    }

    renderRightArrow() {
        return (
            <div>
                <FontAwesomeIcon class="arrow-right" icon={ faChevronRight } />
            </div>
        );
    }

    renderLeftArrow() {
        return (
            <div>
                <FontAwesomeIcon class="arrow-left" icon={ faChevronLeft } />
            </div>
        );
    }

    renderCard(item) {
        return (
            <ProductCard product={ item }  showNewLabel={ true }/>
        )
    }

    renderPlaceholder() {
        const { numberOfPlaceholders, device } = this.props;

        return (
            <ul block="ProductCarousel" elem="Placeholder">
                {
                    Array.from(
                        { length: device.isMobile? 1 : numberOfPlaceholders },
                        (_, i) => (
                        <ProductCard
                            key={ i }
                            product={ {} }
                        />
                    )
                )}
            </ul>
        );
    }

    renderSlider() {
        const { pages, isLoading } = this.props;

        if (isLoading) {
            return this.renderPlaceholder();
        }

        if (!pages[1]) {
            return <></>;
        }

        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            adaptiveHeight: true,
            nextArrow: this.renderRightArrow(),
            prevArrow: this.renderLeftArrow(),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        className: "center",
                        infinite: false,
                        arrows: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1,
                        className: "center",
                        infinite: false,
                        arrows: false,
                        dots: true
                    }
                }
            ]
        };

        return (
            <Slider {...settings} >
                { pages[1].map(this.renderCard) }
            </Slider>
        );
    }

    render() {
        const {
            totalPages,
            isLoading,
            mix
        } = this.props;

        if (!isLoading && totalPages === 0) {
            return this.renderNoProducts();
        }

        return (
            <div
                block="ProductCarousel"
                mods={ { isLoading } }
                mix={ mix }
            >
                { this.renderLoadButton() }
                { this.renderSlider() }
            </div>
        );
    }
}

export default ProductCarousel;
