import {
    ProductLinks as SourceProductLinks,
} from 'SourceComponent/ProductLinks/ProductLinks.component';
import Slider from "react-slick";
import ContentWrapper from 'Component/ContentWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import ProductCard from 'Component/ProductCard';

import './ProductLinks.style.override'

export class ProductLinks extends SourceProductLinks {
    renderProductCard = this.renderProductCard.bind(this);

    renderProductCard(product, i) {
        const {
            // productCardProps,
            productCardFunctions
        } = this.props;
        const { id = i } = product;

        const productCardProps = {
            siblingsHaveBrands: false,
            siblingsHavePriceBadge: true,
            siblingsHaveTierPrice: true,
            siblingsHaveConfigurableOptions: false
        }

        return (
            <ProductCard
              block="ProductLinks"
              elem="Card"
              product={ product }
              key={ id }
              { ...productCardProps }
              { ...productCardFunctions }
            />
        );
    }

    renderRightArrow(){
        return (
            <div>
                <FontAwesomeIcon class="arrow-right" icon={ faChevronRight } />
            </div>
        );
    }

    renderLeftArrow(){
        return (
            <div>
                <FontAwesomeIcon class="arrow-left" icon={ faChevronLeft } />
            </div>
        );
    }

    renderContent() {
        const { isSlider } = this.props;

        if(isSlider) {
            let settings = {
                dots: false,
                arrows: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 4,
                adaptiveHeight: true,
                nextArrow: this.renderRightArrow(),
                prevArrow: this.renderLeftArrow(),
                responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: true,
                        arrows: false
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1.4,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1.2,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                      }
                    }
                  ]
              };

            return (
                <Slider { ...settings } >
                    { this.renderItems() }
                </Slider>
            );
        } else {
            return (
                <ul block="ProductLinks" elem="List">
                    { this.renderItems() }
                </ul>
            );
        }
    }

    render() {
        const { areDetailsLoaded, isSlider } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper', mods: { isSlider } } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                { this.renderContent() }
            </ContentWrapper>
        );
    }

};

export default ProductLinks;
