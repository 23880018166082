import {
    MyAccountCustomerPopup as SourceMyAccountCustomerPopup,
} from 'SourceComponent/MyAccountCustomerPopup/MyAccountCustomerPopup.component';

//import 'MyAccountCustomerPopup.component.style.override'

import PropTypes from 'prop-types';


import Loader from 'SourceComponent/Loader';
import MyAccountCustomerForm from 'SourceComponent/MyAccountCustomerForm';
import MyAccountPasswordForm from 'SourceComponent/MyAccountPasswordForm';
import Popup from 'SourceComponent/Popup';
import { customerType } from 'Type/Account';

import { CHANGE_PASSWORD, CUSTOMER_POPUP_ID, EDIT_CUSTOMER } from './MyAccountCustomerPopup.config';

import MyAccountProfilePicture from 'Component/MyAccountProfilePicture';

export class MyAccountCustomerPopup extends SourceMyAccountCustomerPopup {
    // TODO implement logic
    static propTypes = {
        onCustomerSave: PropTypes.func.isRequired,
        onPasswordChange: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        payload: PropTypes.shape({
            action: PropTypes.oneOf([
                CHANGE_PASSWORD,
                EDIT_CUSTOMER
            ]),
            customer: customerType
        }).isRequired,
        onphotoChange: PropTypes.func.isRequired,
        onPhotoSave: PropTypes.func.isRequired
    };

    renderChangePasswordForm() {
        const { onPasswordChange } = this.props;

        return (
            <MyAccountPasswordForm
              onPasswordChange={ onPasswordChange }
            />
        );
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onphotoChange = (e) => {
        const { payload: { customer }, onPhotoSave } = this.props

        let base64 = '';

        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            base64 = reader.result;

            let customerInfo = {
                photo: base64
            }

            onPhotoSave(customerInfo);

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

    }

    renderCustomerForm() {
        const { payload: { customer }, onCustomerSave, onphotoChange } = this.props;

        return (
            <>
                {/* <div block="MyAccountProfilePicture">
                    <label class="Field-Label" for="profilePhoto">{__('Profile Photo')}</label>
                    <input type="file" name="profilePhoto" id="profilePhoto" onChange={this.onphotoChange}/>
                </div> */}
                <MyAccountCustomerForm
                customer={ customer }
                onSave={ onCustomerSave }
                />
            </>
        );
    }



    renderContent() {
        const { payload: { action } } = this.props;

        switch (action) {
        case CHANGE_PASSWORD:
            return this.renderChangePasswordForm();
        case EDIT_CUSTOMER:
            return this.renderCustomerForm();
        default:
            return null;
        }
    }

    render() {
        const { isLoading } = this.props;

        return (
            <Popup
              id={ CUSTOMER_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'MyAccountCustomerPopup' } }
            >
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </Popup>
        );
    }
};

export default MyAccountCustomerPopup;
