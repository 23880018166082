import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountAddressTableContainer as SourceMyAccountAddressTableContainer,
    mapStateToProps as SourceMapStateToProps,
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.container';

import delete_icon from './images/icon_delete_modal.png';
import Image from 'Component/Image';

import { showNotification } from 'Store/Notification/Notification.action';

import PropTypes from 'prop-types';

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export const mapStateToProps = (state) => ({
    countries: state.ConfigReducer.countries
});

import {
    ADDRESS_POPUP_ID,
    DELETE_ADDRESS,
    EDIT_ADDRESS
} from 'Component/MyAccountAddressPopup/MyAccountAddressPopup.config';

export class MyAccountAddressTableContainer extends SourceMyAccountAddressTableContainer {

    onDeleteClick() {
        const { showEditPopup, address } = this.props;

        showEditPopup({
            action: DELETE_ADDRESS,
            title: <> <Image src={ delete_icon } width="35px" height="35px" /> <br/>{ __('Confirm delete address') } </>,
            address
        });
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressTableContainer);
