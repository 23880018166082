import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'Component/Link';
import PharmacySchedule from 'Component/PharmacySchedule';
import ExpandableContent from 'Component/ExpandableContent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange } from '@fortawesome/pro-light-svg-icons';
import { faEnvelope, faPlus, faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import pin from 'Route/StorePage/Images/pin.png';
import clock from 'Route/StorePage/Images/clock.png';
import phone from 'Route/StorePage/Images/phone.png';
import arrowright from 'Route/StorePage/Images/arrowright.png';
import Image from 'Component/Image';
import iconNoPharmacy from 'Component/MyPharmacy/Images/icon.png';
import base_logo from 'Component/MyPharmacy/Images/logo_pharmacy_default.jpg';
import image from 'Route/StorePage/Images/banner_pharmacy_default.jpg';
import cardSauda from 'Route/StoreFinder/images/pecas-sauda-2-79-copy@2x.png';
import livroreclamacoes from 'Route/MyAccount/Images/livro-reclamacoes.png';
import delete_icon from 'Component/MyAccountAddressTable/images/icon_delete_modal.png';

import './MyPharmacy.style';
import { faLessThan } from '@fortawesome/pro-regular-svg-icons';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import Loader from 'Component/Loader/Loader.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import Popup from 'Component/Popup';

class MyPharmacy extends PureComponent {
    state = {
        coordinates: null
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        navigator.geolocation.getCurrentPosition(position => {
            let coords = [position.coords.latitude,position.coords.longitude];

            this.setState({coordinates:coords});
        });
    }

    distance(lat1, lon1, lat2, lon2, unit) {
        let radlat1 = Math.PI * lat1/180;
        let radlat2 = Math.PI * lat2/180;
        let theta = lon1-lon2;
        let radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344; }
        if (unit=="N") { dist = dist * 0.8684; }
        return dist;
    }

    prepareHours(store) {
        const { schedule } = store;

        if (!schedule) {
            return __('No info about hours');
        }

        return (
            <strong>{ schedule }</strong>
        );
    }

    renderMessage() {
        return (
            <>
                    <div class="block-image-message">
                        {/*<Image
                            src={ iconNoPharmacy }
                            width="40px"
                            height="60px"
                        />*/}
                        <p>{ __('Define your favorite Pharmacy and find everything you are looking for.') }</p>
                    </div>
                    <div class="btn-choose-pharmacy">
                        <button
                            block="MyPharmacy"
                            elem="Button"
                            mix={ { block: 'Button' } }
                            onClick={ () => this.onChangePharmacy() }
                        >
                            <span >{ __('Choose My Pharmacy') }</span>
                        </button>
                    </div>
            </>
        );
    }

    renderScheduleMobile() {
        const { storeObj, ScheduleMenu, storeObj: { status } } = this.props;

        // pharmacy schedule status
        let storeStatusLabel = __('Closed');
        let showHours = true;

        if(status == 'open') {
            storeStatusLabel = __('Open');
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
            showHours = false;
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        const heading = <>
            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                <Image src={ clock } width="20px" height="20px" />
            </div>
            <div block="MyPharmacy" elem="PharmacyColumnsLineInfo">
                <span block="MyPharmacy" elem="MyPharmacyInfoLabel" mods={ { type: status } }>
                    { storeStatusLabel }
                </span>
                { showHours ? <span> - </span> : '' }
                { showHours ? this.prepareHours(storeObj) : '' }
            </div>
            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                <Image src={ arrowright } width="20px" height="20px" />
            </div>
        </>;

        return (
            <ExpandableContent
              key="pharmacySchedule"
              heading={ heading }
              mix={ { block: 'MyPharmacy', elem: 'Expandable' } }
            >
                <PharmacySchedule storeObj={ storeObj } />
            </ExpandableContent>
        );
    }

    removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }

    onChangePharmacy() {
        const { showPharacySelectorPopup,requestStores } = this.props;
        BrowserDatabase.setItem(true, 'change-pharmacy');
        BrowserDatabase.setItem('/my-account/pharmacy', 'redirect_to');
        //history.push({ pathname: appendWithStoreCode('/selecionador-de-farmacias') });
        localStorage.setItem('showSelectorPopup',true)
        requestStores();
        showPharacySelectorPopup();
    }

    renderAboutMobile() {
        const { storeObj: {
            technical_team,
            resume,
            director
        } } = this.props;

        const heading = <>
            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                <FontAwesomeIcon icon={ faUserFriends } />
            </div>
            <div block="MyPharmacy" elem="PharmacyColumnsLineInfo">{ __('About Us') }</div>
            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                <Image src={ arrowright } width="20px" height="20px" />
            </div>
        </>;

        let resumeFormated = resume ? resume.replaceAll('\n','<br>') : '';

        let teamFormated = '';
        if(technical_team){
            teamFormated = technical_team.replaceAll('\n','<br>');
        } else {
            teamFormated = __('No Team');
        }


        return (
            <ExpandableContent
                key="pharmacyAbout"
                heading={ heading }
                mix={ { block: 'MyPharmacy', elem: 'Expandable' } }
            >
                {resumeFormated && <div block="MyPharmacy" elem="PharmacyInfo">
                    <div dangerouslySetInnerHTML={{ __html: resumeFormated }} />
                </div>}

                <div block='MyPharmacy' elem='InfoContainer'>
                    <h4>{ __("Pharmaceutic Owner:") }</h4>
                    <p>{ director }</p>
                </div>
               
                {technical_team && <div block='MyPharmacy' elem='InfoContainer'>
                    <h4>{ __("Team:") }</h4>
                    <div dangerouslySetInnerHTML={{ __html: teamFormated }} />
                </div>}
            </ExpandableContent>
        );
    }

    renderServiceMobile() {
        const { storeObj } = this.props;

        const heading = <>
            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                <FontAwesomeIcon icon={ faPlus } />
            </div>
            <div block="MyPharmacy" elem="PharmacyColumnsLineInfo">{ __('Pharmacy services') }</div>
            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                <Image src={ arrowright } width="20px" height="20px" />
            </div>
        </>;

        let services = [];

        if (storeObj.services) {
            storeObj.services
                .sort((a,b) => {
                    if(this.removeAccents(a.name) < this.removeAccents(b.name)) { return -1; }
                    if(this.removeAccents(a.name) > this.removeAccents(b.name)) { return 1; }
                    return 0;
                })
                .forEach((item) => {
                    services.push(<li block="MyPharmacy" elem="Service_Info">{item.name}</li>);
                });
        }

        return (
            <>
                {services.length > 0 && <div block="MyPharmacy" elem="PharmacyColumnsLine">
                    <ExpandableContent
                        key="pharmacyService"
                        heading={ heading }
                        mix={ { block: 'MyPharmacy', elem: 'Expandable' } }
                    >
                        <ul>{ services }</ul>
                    </ExpandableContent>
                </div>}
            </>
        );
    }

    calcDistance(lat1, lon1, lat2, lon2) {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;

        return dist;
    };

    clickContextPharmacy() {
        const {
            cartTotals: { items_qty },
            storeObj: { store_url, pharmacy_code },
            updateContextPharmacy,
            showCartPopup,
            customer: { favourite_pharmacy_code, preference_pharmacy_code }
        } = this.props;

        if (favourite_pharmacy_code === preference_pharmacy_code) {
            history.push(appendWithStoreCode(store_url ? store_url : '/'));
        } else {
            if (!items_qty) {
                updateContextPharmacy(pharmacy_code, store_url)
            } else {
                showCartPopup();
            }
        }
    }

    onPopupChangeStore(clickedPharmacy, clickedPharmacyUrl) {
        const { updateContextPharmacy, showCartPopup } = this.props;
        updateContextPharmacy(clickedPharmacy, clickedPharmacyUrl);
        showCartPopup();
    }

    renderStore() {
        const { storeObj, baseLinkUrl, device: { isMobile } } = this.props;

        if(!Object.entries(storeObj).length) {
            return null;
        }

        let showDistance = false;

        const {
            all_schedules,
            email,
            latitude,
            locality,
            longitude,
            logo,
            name,
            number,
            online_store_active,
            director,
            photo,
            postal_code_zone,
            postal_code_sufix,
            resume,
            sauda,
            schedule_values,
            status,
            store_url,
            street_name,
            technical_team,
            telephone,
            telephone_secondary,
        } = storeObj;

        let media = '/media/';
        let mediaUrl = baseLinkUrl.concat(media);
        let url = logo ? mediaUrl.concat(logo) : base_logo;

        let urlBanner;
        if(photo != null) {
            urlBanner = mediaUrl.concat(photo);
        }else {
            urlBanner = image;
        }

        let telephoneToShow = telephone_secondary ? telephone_secondary : telephone;

        if(!Object.entries(storeObj).length) {
            return null;
        }

        let services = [];

        if (storeObj.services) {
            storeObj.services
                .sort((a,b) => {
                    if(this.removeAccents(a.name) < this.removeAccents(b.name)) { return -1; }
                    if(this.removeAccents(a.name) > this.removeAccents(b.name)) { return 1; }
                    return 0;
                })
                .forEach((item) => {
                    services.push(<li block="MyPharmacy" elem="ServiceInfo">{item.name}</li>);
                });
        }

        let distance2;

        const { coordinates } = this.state;

        if( coordinates== null ) {
            distance2= false;
        } else {
            distance2 = this.distance(coordinates[0], coordinates[1], storeObj.latitude, storeObj.longitude,'K');
        }

        let finalDistance = Math.round(distance2 * 100) / 100;

        if(finalDistance == 0) {
            showDistance = false;
        } else {
            showDistance = true;
        }

        let distance = '';
        if(coordinates && coordinates.length) {
            let dist = this.calcDistance(coordinates[0], coordinates[1], latitude, longitude);
            let finalDistance = Math.round(dist * 100) / 100;
            distance = ` - ${finalDistance} km`;
        }

        let address = `${street_name} ${number}`;
        let postCode = `${postal_code_zone}-${postal_code_sufix} ${locality}`;

        if (isMobile) {
            return (
                <div block="MyPharmacy" elem="Menus">
                    <div block="MyPharmacy" elem="PharmacyHeaderMobile">
                        <div block="MyPharmacy" elem="PharmacyColumnMobile">
                            <div block="MyPharmacy" elem="PharmacyInfo">
                                <h1 block="MyPharmacy" elem="Heading" mix={ { block: 'MyPharmacy', elem: 'Heading' } }>
                                    { name }
                                </h1>
                            </div>
                        </div>
                        {!!storeObj.sauda && <div block="MyPharmacy" elem="PharmacyColumnMobile">
                            <Image src={ cardSauda } width="85px" height="60px"/>
                        </div>}
                    </div>
                    <div block="MyPharmacy" elem="PharmacyColumnsMobile">
                        <div block="MyPharmacy" elem="PharmacyMobileBanner">
                            <Image src={ urlBanner } />
                        </div>
                    </div>
                    <div block="MyPharmacy" elem="PharmacyColumnsMobile" mods={ { Action:true } }>
                        {!!online_store_active && <a class="MyPharmacy-Button Button" onClick={ () => this.clickContextPharmacy() }>{ __("Buy online") }</a>}
                        <div block="MyPharmacy" elem="ChangePharmacy">
                            <a block="MyPharmacy" elem="HeadingLink" onClick={ () => this.onChangePharmacy() }>
                                <FontAwesomeIcon className="MyPharmacy-HeadingLinkIcon" icon={ faExchange } /> { __("Change my pharmacy") }
                            </a>
                        </div>
                    </div>
                    <div block="MyPharmacy" elem="PharmacyColumnsMobile">
                        <div block="MyPharmacy" elem="PharmacyColumnsLine" mods={ { Schedule:true } }>
                            { this.renderScheduleMobile() }
                        </div>
                        <div block="MyPharmacy" elem="PharmacyColumnsLine">
                            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                                <Image src={ pin } width="20px" height="20px" />
                            </div>
                            {
                                distance2 && <div block="MyPharmacy" elem="PharmacyColumnsLineInfo" mods={ { Address: true } }>
                                    {name && street_name && postCode ? <a href={`https://www.google.com/maps/dir//${name},${address},${postCode}`}
                                        target="_blank"
                                        block="StoreFinder"
                                        elem="StoreInfoLink"
                                        >
                                        <h4 block="MyPharmacy" elem="StreetName">
                                            <span block="MyPharmacy" elem="PharmacyDistance">{ finalDistance } Km</span>- { address + ', ' + postCode }
                                        </h4>
                                    </a> : ''}
                                </div>
                            }
                            {
                                !distance2 && <div block="MyPharmacy" elem="PharmacyColumnsLineInfo"  mods={ { Address: true } }>
                                    {name && street_name && postCode ? <a href={`https://www.google.com/maps/dir//${name},${address},${postCode}`}
                                        target="_blank"
                                        block="StoreFinder"
                                        elem="StoreInfoLink"
                                        >
                                        <h4 block="MyPharmacy" elem="StreetName">
                                            { address+ ', ' + postCode }
                                        </h4>
                                    </a> : ''}
                                </div>
                            }
                        </div>
                        <div block="MyPharmacy" elem="PharmacyColumnsLine">
                            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                                <Image src={ phone } width="20px" height="20px"/>
                            </div>
                            <div block="MyPharmacy" elem="PharmacyColumnsLineInfo">
                                <a href={ 'tel:' +  telephoneToShow }>{ telephoneToShow }</a>
                            </div>
                        </div>
                        { !!storeObj.email && <div block="MyPharmacy" elem="PharmacyColumnsLine">
                            <div block="MyPharmacy" elem="PharmacyColumnsLineIcon">
                                <FontAwesomeIcon icon={ faEnvelope } />
                            </div>
                            <div block="MyPharmacy" elem="PharmacyColumnsLineInfo">
                                <a href={ 'mailto:' + storeObj.email }>{ storeObj.email }</a>
                            </div>
                        </div>}
                        { this.renderServiceMobile() }
                        <div block="MyPharmacy" elem="PharmacyColumnsLine">
                            { this.renderAboutMobile() }
                        </div>
                    </div>
                </div>
            );
        }

        // pharmacy schedule status
        let storeStatusLabel = __('Closed');
        let showHours = true;

        if(status == 'open') {
            storeStatusLabel = __('Open');
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
            showHours = false;
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        let resumeFormated = resume ? resume.replaceAll('\n','<br>') : '';

        let teamFormated = '';
        if(technical_team){
            teamFormated = technical_team.replaceAll('\n','<br>');
        } else {
            teamFormated = __('No Team');
        }

        return (
            <div block="MyPharmacy" elem="Content">
                <div block="MyPharmacy" elem="TopInfo">
                    <div block="MyPharmacy" elem="PharmacyLogo">
                        <Image
                            src = { url }
                            width="130px"
                            height="90px"
                        />
                        {!!sauda && <div block="MyPharmacy" elem="SaudaCard">
                            <Image src={ cardSauda } width="85px" height="60px"/>
                        </div>}
                    </div>
                    <div block="MyPharmacy" elem="PharmacyHeaderBlock">
                        <div block="MyPharmacy" elem="PharmacyNameBlock">
                            <h3 block="MyPharmacy" elem="PharmacyName">
                                { name || __('No store name') }
                            </h3>
                        </div>
                        <div block="MyPharmacy" elem="PharmacScheduleBlock">
                            <Image
                                src={ clock }
                                width="20px"
                                height="20px"
                            />
                            <span block="MyPharmacy" elem="MyPharmacyInfoLabel" mods={ { type: status } }>
                                { storeStatusLabel }
                            </span>
                            { showHours ? <span> - </span> : '' }
                            { showHours ? this.prepareHours(storeObj) : '' }
                        </div>
                        {showDistance && <div block="MyPharmacy" elem="Address">
                            <Image
                                src={ pin }
                                width="20px"
                                height="20px"
                            />
                            <div block="MyPharmacy" elem="AddressRoute">
                                <h4 block="MyPharmacy" elem="AddressDistance">
                                    { finalDistance } Km
                                </h4>
                                <h4 >
                                    { address ? address + ', ' + postCode :  __('No address') }
                                </h4>
                            </div>
                        </div> }
                        {!showDistance && <div block="MyPharmacy" elem="Address">
                            <Image
                                src={ pin }
                                width="20px"
                                height="20px"
                            />
                            <h4 block="MyPharmacy" elem="AddressRoute">
                                { address ? address + ', ' + postCode :  __('No address') }
                            </h4>
                        </div> }
                        <div block="MyPharmacy" elem="ContactsContainer">
                            {!!email && <div block="MyPharmacy" elem="ContactEmail">
                                <FontAwesomeIcon icon={ faEnvelope } />
                                <h4 block="MyPharmacy" elem="Email">
                                    <a href={ 'mailto:' + email }>{ email }</a>
                                </h4>
                            </div>}
                            <div block="MyPharmacy" elem="ContactMobile">
                                <Image
                                    src={ phone }
                                    width="20px"
                                    height="20px"
                                />
                                <h4 block="MyPharmacy" elem="PhoneNumber">
                                    <a href={ 'tel:' +  telephoneToShow }>{ telephoneToShow }</a>
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div block="MyPharmacy" elem="BuyOnline" mods={ { Action:true } }>
                        {!!online_store_active && <a class="MyPharmacy-Button Button" onClick={ () => this.clickContextPharmacy() }>{ __("Buy online") }</a> }
                        <div block="MyPharmacy" elem="ChangePharmacy">
                            <a block="MyPharmacy" elem="HeadingLink" onClick={ () => this.onChangePharmacy() }>
                                <FontAwesomeIcon className="MyPharmacy-HeadingLinkIcon" icon={ faExchange } /> { __("Change my pharmacy") }
                            </a>
                        </div>
                    </div>
                </div>

                <div block="MyPharmacy" elem="BackgroundImage"
                    style={ { backgroundImage: `url(${urlBanner})` } }
                >
                </div>

                <h3 block="MyPharmacy" elem="Title">{ __("Opened Hours:") }</h3>
                <div block="MyPharmacy" elem="PharmacyHours">
                    { <PharmacySchedule storeObj={ storeObj } /> }
                </div>
                <div block="MyPharmacy" elem="Separator"/>
                <div block="MyPharmacy" elem="InfoDescription">
                    <h3 block="MyPharmacy" elem="Title">{ __("About us:") }</h3>
                    <div dangerouslySetInnerHTML={{ __html: resumeFormated }} />
                </div>
                <div block="MyPharmacy" elem="InfoContainer">
                    <h4 block="MyPharmacy" elem="Title">{ __("Pharmaceutic Owner:") }</h4>
                    <p>{ director }</p>
                </div>
                {  technical_team && <div block="MyPharmacy" elem="InfoContainer">
                    <h4 block="MyPharmacy" elem="Title">{ __("Team:") }</h4>
                    <div dangerouslySetInnerHTML={{ __html: teamFormated }} />
                </div> }
                {services.length > 0 && <><div block="MyPharmacy" elem="Separator"/>
                <div block="MyPharmacy" elem="Services">
                    <h3 block="MyPharmacy" elem="Title">{ __("Services:") }</h3>
                    <ul>
                        { services }
                    </ul>
                </div></>}
                <div block="MyPharmacy" elem="Separator"/>
                <a
                    href="https://www.livroreclamacoes.pt/inicio"
                    target="_blank"
                    block="MyPharmacy"
                    elem="ComplaintLink"
                >
                    <Image src = {livroreclamacoes} width="120px" height="50px" />
                </a>
            </div>
        );
    }

    renderPopupCartAlert() {
        const { showCartPopup, storeObj: { store_url, pharmacy_code } } = this.props;

        return (
            <Popup
                id={ 'CartItemsAlert' }
                clickOutside={ false }
                mix={ { block: 'CartItemsAlert' } }
                closedOn={ false }
            >
                <header class="Popup-Header">
                    <h3 class="Popup-Heading">
                        <Image src={ delete_icon } width="35px" height="35px" />
                        <br/>
                        { __('By changing pharmacy your cart may be changed.') }
                    </h3>
                </header>
                <p>{ __('Do you wish to continue?') }</p>
                <div block="CartItemsAlert" elem="Actions" >
                    <button
                        block="CartItemsAlert"
                        elem="PrimaryButton"
                        mix={ { block: 'Button' } }
                        onClick={ () => showCartPopup() }
                    >{ __('No') }</button>
                </div>
                <div block="CartItemsAlert" elem="Actions">
                    <button
                        block="CartItemsAlert"
                        elem="SecondaryButton"
                        mix={ { block: 'Button' } }
                        onClick={ () => this.onPopupChangeStore(pharmacy_code, store_url) }
                    >{ __('Yes') }</button>
                </div>
            </Popup>
        );
    }

    render() {
        const { storeObj,  customer:{ favourite_pharmacy_code } } = this.props;

        return (
            <>
                <Loader isLoading={!storeObj} />
                <h1 block="MyAccount" elem="Heading">
                    { __('My Pharmacy') }
                </h1>
                <div block="MyPharmacy">
                    {favourite_pharmacy_code != null && Object.entries(storeObj).length ? this.renderStore() : this.renderMessage()}
                    { this.renderPopupCartAlert() }
                </div>
            </>
        );
    }
}

export default MyPharmacy;
