/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import { MAX_CATEGORIES } from './BlogCategories.config';

import './BlogCategories.style';

/** @namespace ScandiPWA/Blog/Component/BlogCategories/Component */
export class BlogCategories extends PureComponent {
    static propTypes = {
        categories: PropTypes.array,
        isLoaded: PropTypes.bool
    };

    static defaultProps = {
        categories: {},
        isLoaded: false
    };

    constructor(props) {
        super(props);
        this.categoryList = React.createRef();
        this.controlLeft = React.createRef();
        this.controlRight = React.createRef();
    }

    componentDidMount() {
        const { device: { isMobile }, parent } = this.props;
        /*if (isMobile && !parent) {
            this.checkControls();   
        }*/
    }

    componentDidUpdate() {
        window.scrollTo(0,0);
    }

    renderCategory(category, key) {
        const { title, identifier, category_level } = category;
        let titleLowerCase = "";

        if (typeof title !== 'undefined'){
            titleLowerCase = title.toLowerCase();
        }

        return (
            <div block="BlogCategories" elem="Category" key={ key }>
                <Link to={ `blog/categoria/${identifier}` } title={ __('View %s', titleLowerCase) }>
                    <h3 block="BlogCategories" elem="CategoryTitle">
                        <TextPlaceholder
                            mix={ { block: 'BlogCategories', elem: 'CategoryTitlePlaceholder' } }
                            content={ titleLowerCase }
                            length="short"
                        />
                    </h3>
                </Link>
            </div>
        );
    }

    renderContent() {
        const { categories, isLoaded, getChildren, parent } = this.props;
        const category = {};
        let categoriesArray = [];

        if (!isLoaded) {
            return Array(MAX_CATEGORIES).fill().map((_, i) => this.renderCategory(category, i));
        }

        if(getChildren && parent) {
            categoriesArray = categories.filter(({ parent_category_id }) => parent_category_id === parent);
        } else {
            categoriesArray = categories.filter(({ category_level, is_active }) => category_level < 1 && is_active );
        }

        if (isLoaded && categoriesArray.length === 0) {
            return <></>;
        }

        return categoriesArray.map(this.renderCategory);
    }

    onClickRight = () => {
        let $inner = this.categoryList.current.querySelector('.BlogCategories-CarousselInner');
        let parentRect = $inner.getBoundingClientRect();
        let max = parentRect.width + ($inner.dataset.x ? parseInt($inner.dataset.x) : 0);

        let $children = $inner.querySelectorAll('.BlogCategories-Category');
        let flag = 0;
        for(let i = 0; i < $children.length; i++) {
            let rect = $children[i].getBoundingClientRect();
            
            flag += rect.width;
            if (flag < max) {
                this.checkControls();
            } else {
                let translateX = rect.x + ($inner.dataset.x ? parseInt($inner.dataset.x) : 0) - 30;
                $inner.dataset.x  = translateX;
                $inner.style.transform = 'translateX(-' + (translateX) + 'px)';

                this.checkControls();

                break;
            }

        }
    }

    onClickLeft = () => {
        let $inner = this.categoryList.current.querySelector('.BlogCategories-CarousselInner');
        let parentRect = $inner.getBoundingClientRect();
        let max = parentRect.width + ($inner.dataset.x ? parseInt($inner.dataset.x) : 0);
        
        let $children = $inner.querySelectorAll('.BlogCategories-Category');
        let flag = 0;
        let diff = 0;
        let check = parentRect.width;
        for(let i = ($children.length - 1); i >= 0; i--) {
            let rect = $children[i].getBoundingClientRect();

            if ((flag + rect.width) < max) {
                if (check - rect.width > 0) {
                    check -= rect.width
                    diff += rect.width;

                    this.checkControls();
                } else {
                    let translateX = ($inner.dataset.x ? parseInt($inner.dataset.x) : 0) - diff;
                    translateX = translateX < 0 ? 0 : translateX;
                    $inner.dataset.x  = translateX;
                    $inner.style.transform = 'translateX(-' + (translateX) + 'px)';

                    this.checkControls();
                    break;
                }
            }
        }
    }

    checkControls() {
        let $inner = this.categoryList.current.querySelector('.BlogCategories-CarousselInner');
        let parentRect = $inner.getBoundingClientRect();
        let innerX = $inner.dataset.x ? parseInt($inner.dataset.x) : 0;
        let max = parentRect.width + innerX;

        let $children = $inner.querySelectorAll('.BlogCategories-Category');
        let flag = 0;

        let right = true;
        for(let i = 0; i < $children.length; i++) {
            let rect = $children[i].getBoundingClientRect();
            
            flag += rect.width;
            if (flag >= max) {
                right = false;
            }
        }

        if (right) {
            this.controlRight.current.style.opacity = '0.5';
        } else {
            this.controlRight.current.style.opacity = '1';
        }

        if (innerX === 0) {
            this.controlLeft.current.style.opacity = '0.5';
        } else {
            this.controlLeft.current.style.opacity = '1';
        }
    }

    renderRightArrow(){
        return (
            <div block="BlogCategories" elem="Control" mods={ { Right:true } } onClick={ this.onClickRight } ref={ this.controlRight }>
                <FontAwesomeIcon class="arrow-right" icon={ faChevronRight } />
            </div>
        );
    }

    renderLeftArrow(){
        return (
            <div block="BlogCategories" elem="Control" mods={ { Left:true } }  onClick={ this.onClickLeft } ref={ this.controlLeft }>
                <FontAwesomeIcon class="arrow-left" icon={ faChevronLeft } />
            </div>
        );
    }

    render() {
        const { device: { isMobile }, parent } = this.props;

        if (!isMobile || parent) {
            return (
                <div block="BlogCategories" elem="Wrapper" mods={ { HomeBlog:!parent} }>
                   { this.renderContent() }
                </div>
            );
        }

        return (
            <div block="BlogCategories" elem="Wrapper" mods={ { Main:true} }>
                { this.renderContent() }
                { /* this.renderLeftArrow() */ }
                { /* <div block="BlogCategories" elem="Caroussel" ref={this.categoryList}>
                    <div block="BlogCategories" elem="CarousselInner">
                        { this.renderContent() }
                    </div>
                    </div> */ }
                { /* this.renderRightArrow() */ }
            </div>
        );
    }
}

export default BlogCategories;
