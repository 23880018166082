/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import { PureComponent } from 'react';
import './StorePageInfo.style';
import CmsBlock from 'Component/CmsBlock';
import Image from 'Component/Image';
import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPlus, faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import PharmacySchedule from 'Component/PharmacySchedule';
import Loader from 'Component/Loader';

import clock from 'Route/StorePage/Images/clock.png';
import phone from 'Route/StorePage/Images/phone.png';
import store from 'Route/StorePage/Images/store.png';
import arrowright from 'Route/StorePage/Images/arrowright.png';
import arrowdown from 'Route/StorePage/Images/arrowdown.png';
import arrowUp from 'Route/StorePage/Images/arrowUp.png';
import pin from 'Route/StorePage/Images/pin.png';
import image from 'Route/StorePage/Images/banner_pharmacy_default.jpg';
import { customerType } from 'Type/Account';
import base_logo from 'Component/MyPharmacy/Images/logo_pharmacy_default.jpg';
import livroreclamacoes from './Images/livro-reclamacoes.png';

import ExpandableContent from 'Component/ExpandableContent';
import Popup from 'Component/Popup';

import cardSauda from 'Route/StoreFinder/images/pecas-sauda-2-79-copy@2x.png';
import delete_icon from 'Component/MyAccountAddressTable/images/icon_delete_modal.png';

/** @namespace Route/StorePageInfo/Component */
export class StorePageInfo extends PureComponent {

    static propTypes = {
        device: DeviceType.isRequired,
        updateContextPharmacy: PropTypes.func.isRequired,
        customer: customerType.isRequired,
    };

    state = {
        coordinates: null,
        showPopup:false,
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            clickedPharmacy: ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        navigator.geolocation.getCurrentPosition(position => {
            let coords = [position.coords.latitude,position.coords.longitude];

            this.setState({coordinates:coords});
        });
    }

    distance(lat1, lon1, lat2, lon2, unit) {
        let radlat1 = Math.PI * lat1/180;
        let radlat2 = Math.PI * lat2/180;
        let theta = lon1-lon2;
        let radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344; }
        if (unit=="N") { dist = dist * 0.8684; }
        return dist;
    }

    prepareHours(store) {
        const { schedule } = store;

        if (!schedule) {
            return __('No info about hours');
        }

        return (
            <strong>{ schedule }</strong>
        );
    }

    renderScheduleMobile() {
        const { storeObj, storeObj: { status } } = this.props;

        // pharmacy schedule status
        let storeStatusLabel = __('Closed');
        let showHours = true;

        if(status == 'open' || status == 'open-shift') {
            storeStatusLabel = __('Open');
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
            showHours = false;
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        const heading = <>
            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                <Image src={ clock } width="20px" height="20px" />
            </div>
            <div block="StorePageInfo" elem="PharmacyColumnsLineInfo">
                <span block="StorePageInfo" elem="StoreInfoLabel" mods={ { type: status } }>
                    { storeStatusLabel }
                </span>
                { showHours ? <span> - </span> : '' }
                { showHours ? this.prepareHours(storeObj) : '' }
            </div>
            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                <Image src={ arrowright } width="20px" height="20px" />
            </div>
        </>;

        return (
            <ExpandableContent
              key="pharmacySchedule"
              heading={ heading }
              mix={ { block: 'StorePageInfo', elem: 'Expandable' } }
            >
                <h3>{ __("Opened Hours:") }</h3>
                <PharmacySchedule storeObj={ storeObj } />
            </ExpandableContent>
        );
    }

    removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }

    renderAboutMobile() {
        const { storeObj: {
            technical_team,
            resume,
            director
        } } = this.props;

        const heading = <>
            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                <FontAwesomeIcon icon={ faUserFriends } />
            </div>
            <div block="StorePageInfo" elem="PharmacyColumnsLineInfo">{ __('About Us') }</div>
            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                <Image src={ arrowright } width="20px" height="20px" />
            </div>
        </>;


        let resumeFormated = '';
        if(resume){
            resumeFormated = resume.replaceAll('\n','<br>');
        }

        let teamFormated = '';
        if(technical_team){
            teamFormated = technical_team.replaceAll('\n','<br>');
        } else {
            teamFormated = __('No Team');
        }

        return (
            <ExpandableContent
                key="pharmacyAbout"
                heading={ heading }
                mix={ { block: 'StorePageInfo', elem: 'Expandable' } }
            >
                <div block='StorePageInfo' elem='ExpandableContent'>
                    {resumeFormated && <div block="StorePageInfo" elem="PharmacyInfo">
                        <div dangerouslySetInnerHTML={{ __html: resumeFormated }} />
                    </div>}

                    <h4>{ __("Pharmaceutic Owner:") }</h4>
                    <p>{ director }</p>

                    {technical_team && <>
                        <h4>{ __("Team:") }</h4>
                        <div dangerouslySetInnerHTML={{ __html: teamFormated }} />
                    </>}
                </div>
            </ExpandableContent>
        );
    }

    renderServiceMobile() {
        const { storeObj } = this.props;

        const heading = <>
            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                <FontAwesomeIcon icon={ faPlus } />
            </div>
            <div block="StorePageInfo" elem="PharmacyColumnsLineInfo">{ __('Pharmacy services') }</div>
            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                <Image src={ arrowright } width="20px" height="20px" />
            </div>
        </>;

        let services = [];

        if (storeObj.services) {
            storeObj.services
                .sort((a,b) => {
                    if(this.removeAccents(a.name) < this.removeAccents(b.name)) { return -1; }
                    if(this.removeAccents(a.name) > this.removeAccents(b.name)) { return 1; }
                    return 0;
                })
                .forEach((item) => {
                    services.push(<li block="StorePageInfo" elem="Service_Info">{item.name}</li>);
                });
        }

        return (
            <ExpandableContent
                key="pharmacyService"
                heading={ heading }
                mix={ { block: 'StorePageInfo', elem: 'Expandable' } }
            >
                <ul>{ services }</ul>
            </ExpandableContent>
        );
    }

    prepareShowCartPopup(clickedCode, store_url) {
        const { favStoreObj: { pharmacy_code: favCode }, updateContextPharmacy, showCartPopup } = this.props;
        if(favCode == clickedCode) {
            updateContextPharmacy(clickedCode, store_url);
        } else {
            this.setState({
                clickedPharmacy: clickedCode,
                clickedPharmacyUrl: store_url
            });
            showCartPopup();
        }
    }

    renderStoreContent() {
        const {
            updateContextPharmacy,
            storeObj,
            storeObj: {
                name,
                street_name,
                number,
                locality,
                postal_code_zone,
                postal_code_sufix,
                postal_code_locality,
                latitude,
                longitude,
                store_url,
                pharmacy_code
            },
            ScheduleMenu,
            OpenMenuMobile,
            MenuMobile,
            baseLinkUrl,
            device: { isMobile },
            showMenuSchedule,
            showMenuServices,
            cartTotals: { items_qty }
        } = this.props;

        const { coordinates } = this.state;

        if(!Object.entries(storeObj).length){
            return null;
        }

        let media = '/media/';
        let mediaUrl = baseLinkUrl.concat(media);
        let url = storeObj.logo ? mediaUrl.concat(storeObj.logo) : base_logo;
        let urlBanner;
        if(storeObj.photo != null) {
            urlBanner = mediaUrl.concat(storeObj.photo);
        }else {
            urlBanner = image;
        }

        let distance = '';
        if(coordinates && coordinates.length) {
            let dist = this.calcDistance(coordinates[0], coordinates[1], latitude, longitude);
            let finalDistance = Math.round(dist * 100) / 100;
            distance = ` - ${finalDistance} km`;
        }

        let distance2;

        if( this.state.coordinates== null ) {
            distance2 = false;
        } else {
            distance2 = this.distance(this.state.coordinates[0], this.state.coordinates[1], storeObj.latitude, storeObj.longitude,'K');
        }

        let finalDistance = Math.round(distance2 * 100) / 100;

        let address = `${street_name} ${number} ${locality}`;
        let postCode = `${postal_code_zone}-${postal_code_sufix}`;

        if(postal_code_locality){
            postCode += ` ${postal_code_locality}`;
        }

        const {
            director,
            technical_team,
            online_store_active,
            status,
            telephone,
            telephone_secondary
        } = storeObj;

        const telephoneToShow = telephone_secondary ? telephone_secondary : telephone;

        if (isMobile) {
            return (
                <div block="StorePageInfo" elem="Menus">
                    <div block="StorePageInfo" elem="PharmacyHeaderMobile">
                        <div block="StorePageInfo" elem="PharmacyColumnMobile">
                            <div block="StorePageInfo" elem="PharmacyInfo">
                                <h1 block="StorePageInfo" elem="Heading" mix={ { block: 'StorePageInfo', elem: 'Heading' } }>
                                    { name }
                                </h1>
                            </div>
                        </div>
                        {!!storeObj.sauda && <div block="StorePageInfo" elem="PharmacyColumnMobile">
                            <Image src={ cardSauda } width="85px" height="60px"/>
                        </div>}
                    </div>
                    <div block="StorePageInfo" elem="PharmacyColumnsMobile">
                        <div block="StorePageInfo" elem="PharmacyMobileBanner">
                            <Image src={ urlBanner } />
                        </div>
                    </div>
                    {(!!online_store_active && !!store_url) && <div block="StorePageInfo" elem="PharmacyColumnsMobile" mods={ { Action:true } }>
                        <a class="StorePageInfo-Button Button" onClick={ () => !items_qty ? updateContextPharmacy(pharmacy_code, store_url) : this.prepareShowCartPopup(pharmacy_code, store_url) }>{ __("Buy online") }</a>
                    </div>}
                    <div block="StorePageInfo" elem="PharmacyColumnsMobile">
                        <div block="StorePageInfo" elem="PharmacyColumnsLine" mods={ { Schedule:true } } onClick={ showMenuSchedule }>
                            { this.renderScheduleMobile() }
                        </div>
                        <div block="StorePageInfo" elem="PharmacyColumnsLine">
                            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                                <Image src={ pin } width="20px" height="20px" />
                            </div>
                            {
                                distance2 && <div block="StorePageInfo" elem="PharmacyColumnsLineInfo" mods={ { Address: true } }>
                                    {name && street_name && postCode ? <a href={`https://www.google.com/maps/dir//${name},${address},${postCode}`}
                                        target="_blank"
                                        block="StoreFinder"
                                        elem="StoreInfoLink"
                                        >
                                        <h4 block="StorePageInfo" elem="StreetName">
                                            <span block="StorePageInfo" elem="PharmacyDistance">{ finalDistance } Km</span> - { address + ', ' + postCode  }
                                        </h4>
                                    </a> : ''}
                                </div>
                            }
                            {
                                !distance2 && <div block="StorePageInfo" elem="PharmacyColumnsLineInfo" mods={ { Address: true } }>
                                    {name && street_name && postCode ? <a href={`https://www.google.com/maps/dir//${name},${address},${postCode}`}
                                        target="_blank"
                                        block="StoreFinder"
                                        elem="StoreInfoLink"
                                        >
                                        <h4 block="StorePageInfo" elem="StreetName">
                                            { address + ', ' + postCode  }
                                        </h4>
                                    </a> : ''}
                                </div>
                            }
                        </div>
                        <div block="StorePageInfo" elem="PharmacyColumnsLine">
                            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                                <Image src={ phone } width="20px" height="20px"/>
                            </div>
                            <div block="StorePageInfo" elem="PharmacyColumnsLineInfo">
                                <a href={ 'tel:' +  telephoneToShow }>{ telephoneToShow }</a>
                            </div>
                        </div>
                        {!!storeObj.email && <div block="StorePageInfo" elem="PharmacyColumnsLine">
                            <div block="StorePageInfo" elem="PharmacyColumnsLineIcon">
                                <FontAwesomeIcon icon={ faEnvelope } />
                            </div>
                            <div block="StorePageInfo" elem="PharmacyColumnsLineInfo">
                                <a href={ 'mailto:' + storeObj.email }>{ storeObj.email }</a>
                            </div>
                        </div>}
                        <div block="StorePageInfo" elem="PharmacyColumnsLine" onClick={ showMenuServices }>
                            { this.renderServiceMobile() }
                        </div>
                        <div block="StorePageInfo" elem="PharmacyColumnsLine">
                            { this.renderAboutMobile() }
                        </div>
                    </div>
                </div>
            );
        }

        let services = [];
        if (storeObj.services) {
            storeObj.services
                .sort((a,b) => {
                    if(this.removeAccents(a.name) < this.removeAccents(b.name)) { return -1; }
                    if(this.removeAccents(a.name) > this.removeAccents(b.name)) { return 1; }
                    return 0;
                })
                .forEach((item) => {
                    services.push(<li block="StorePageInfo" elem="Service_Info">{item.name}</li>);
                });
        }

        // pharmacy schedule status
        let storeStatusLabel = __('Closed');
        let showHours = true;

        if(status == 'open' || status == 'open-shift') {
            storeStatusLabel = __('Open');
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
            showHours = false;
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }


        let resumeFormated = '';
        if(storeObj.resume){
            resumeFormated = storeObj.resume.replaceAll('\n','<br>');
        }

        let teamFormated = '';
        if(technical_team){
            teamFormated = technical_team.replaceAll('\n','<br>');
        } else {
            teamFormated = __('No Team');
        }

        return (
            <div block="StorePageInfo" elem="Menus">
                <div block="StorePageInfo" elem="PharmacyColumns" mods={ { Header:true } }>
                    <div block="StorePageInfo" elem="PharmacyColumn" mods={ { ImageSizeAuto: true} }>
                        <Image src = {url} width="130px" height="90px" />
                        {!!storeObj.sauda && <div block="StorePageInfo" elem="SaudaCard">
                            <Image src={ cardSauda } width="100px" height="72px"/>
                        </div>}
                    </div>

                    <div block="StorePageInfo" elem="PharmacyColumn">
                        <div block="StorePageInfo" elem="PharmacyInfo">
                            <h1 block="StorePageInfo" elem="Heading" mix={ { block: 'StorePageInfo', elem: 'Heading' } }>
                                { name }
                            </h1>
                            <div block="StorePageInfo" elem="info-Schedule">
                                <span>
                                    <Image src={ clock } width="20px" height="20px" />
                                </span>
                                <span block="StorePageInfo" elem="StoreInfoLabel" mods={ { type: status } }>
                                    { storeStatusLabel }
                                </span>
                                { showHours ? <span> - </span> : '' }
                                { showHours ? this.prepareHours(storeObj) : '' }
                            </div>
                            {
                                distance2 && <div block="StorePageInfo" elem="PharmacyStreetName">
                                    <Image src={ pin } width="20px" height="20px" />
                                    <h4 block="StorePageInfo" elem="PharmacyDistance">
                                        { finalDistance } Km
                                    </h4>
                                    <h4 block="StorePageInfo" elem="StreetName">
                                        { address + ', ' + postCode  }
                                    </h4>
                                </div>
                            }
                            {
                                !distance2 && <div block="StorePageInfo" elem="PharmacyStreetNameWithoutPermission">
                                    <Image src={ pin } width="20px" height="20px"/>
                                    <h4 block="StorePageInfo" elem="StreetName">
                                        { address + ', ' + postCode  }
                                    </h4>
                                </div>
                            }

                            <div block="StorePageInfo" elem="info">
                                {!!storeObj.email && <div block="StorePageInfo" elem="InfoEmail">
                                    <span>
                                        <FontAwesomeIcon icon={ faEnvelope } />
                                    </span>
                                    <span block="StorePageInfo" elem="Email">
                                        <a href={ 'mailto:' + storeObj.email }>{ storeObj.email }</a>
                                    </span>
                                </div>}
                                <div block="StorePageInfo" elem="InfoPhone">
                                    <span>
                                        <Image src={ phone } width="20px" height="20px"/>
                                    </span>
                                    <span block="StorePageInfo" elem="Telephone">
                                        <a href={ 'tel:' +  telephoneToShow }>{ telephoneToShow }</a>  ({ __('Call cost to national fixed/mobile network') })
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(!!online_store_active && !!store_url) ? <div block="StorePageInfo" elem="PharmacyColumn" mods={ { BuyButton: true,TextAlignRight: true } }>
                        <a class="StorePageInfo-Button Button" onClick={ () => !items_qty ? updateContextPharmacy(pharmacy_code, store_url) : this.prepareShowCartPopup(pharmacy_code, store_url) }>{ __("Buy online") }</a>
                    </div> : ''}
                </div>

                <div block="StorePageInfo" elem="PharmacyColumnsMobile">
                    { this.renderPharmacyPreference('desktop') }
                    <Image
                        src={ url }
                        width="130px"
                        height="90px"
                    />
                    <div block="StorePageInfo" elem="PharmacyInfo">
                        <div block="StorePageInfo" elem="PharmacyInfoLine1">
                            <h3
                                block="StorePageInfo"
                                elem="Heading"
                                mix={ {
                                    block: 'StorePageInfo',
                                    elem: 'Heading'
                                } }
                                >
                                    { name }
                            </h3>
                            <div block="StorePageInfo" elem="PharmacyInfoMenu" onClick={ OpenMenuMobile }>
                                <h4
                                    block="StorePageInfo"
                                    elem="StreetName"
                                    >
                                        {__('Pharmacy Info')}
                                </h4>
                                { !MenuMobile &&<Image
                                    src={ arrowdown }
                                    width="20px"
                                    height="24px"
                                />}
                                { MenuMobile &&<Image
                                    src={ arrowUp }
                                    width="20px"
                                    height="24px"
                                />}
                            </div>
                        </div>
                    </div>
                </div>

                <div block="StorePageInfo" elem="BackgroundImage"
                    style={ { backgroundImage: `url(${urlBanner})` } }
                >
                </div>

                <div block="StorePageInfo" elem="PharmacyDetails">
                    <div block="StorePageInfo" elem="PharmacyColumn">
                        <h3>{ __("Opened Hours:") }</h3>
                        <div block="StorePageInfo" elem="PharmacyHours">
                            { <PharmacySchedule storeObj={ storeObj } /> }
                        </div>
                    </div>

                    <div block="StorePageInfo" elem="PharmacyColumn" mods={ { AboutUs:true } }>
                        <h3>{ __("About us:") }</h3>
                       { resumeFormated &&  <div block="StorePageInfo" elem="PharmacyInfo">
                            <div dangerouslySetInnerHTML={{ __html: resumeFormated }} />
                        </div>}

                        <h4>{ __("Pharmaceutic Owner:") }</h4>
                        <div block="StorePageInfo" elem="PharmacyOwner">
                            <p>{ director }</p>
                        </div>

                        {technical_team && <>
                            <h4>{ __("Team:") }</h4>
                            <div block="StorePageInfo" elem="PharmacyTeam">
                                <div dangerouslySetInnerHTML={{ __html: teamFormated }} />
                            </div>
                        </>}
                    </div>

                    {services.length > 0 && <div block="StorePageInfo" elem="PharmacyColumn" mods={ { PharmacyServices:true } }>
                        <h3>{ __("Services:") }</h3>
                        <div block="StorePageInfo" elem="PharmacyServices">
                            <ul>
                                { services }
                            </ul>
                        </div>
                    </div>}

                    <div block="StorePageInfo" elem="PharmacyColumn" mods={ { PharmacyComplaintBook:true } }>
                        <a
                            href="https://www.livroreclamacoes.pt/inicio"
                            target="_blank"
                        >
                            <Image src = {livroreclamacoes} width="120px" height="50px" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    calcDistance(lat1, lon1, lat2, lon2) {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;

        return dist;
    };

    renderPharmacyPreference(type) {
        const {customer, storeObj, updateContextPharmacy} = this.props;

        if(!storeObj.online_store_active) {
            return;
        }

        let favouriteCode = null;
        if (customer && customer.favourite_pharmacy_code) {
            let favouriteCode = customer.favourite_pharmacy_code;
        }

        let elementBlock = 'info';
        let elementH4 = 'Pharmacy';
        if(type == 'mobile'){
            elementBlock = 'PharmacyMobileSchedule';
            elementH4 = 'PharmacyMobile';
        }

        if(favouriteCode && (favouriteCode == storeObj.pharmacy_code)){
            return (
                <div block="StorePageInfo" elem={elementBlock}>
                                <span>
                                    <Image
                                        src={ store }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                <span
                                    block="StorePageInfo"
                                    elem={elementH4}
                                >
                                    {__('Favourite Pharmacy')}
                                </span>
                            </div>
            );
        } else {
            return (
                <div block="StorePageInfo" elem={elementBlock} onClick={ () => updateContextPharmacy() }>
                                <span>
                                    <Image
                                        src={ store }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                <span
                                    block="StorePageInfo"
                                    elem={elementH4}
                                >
                                    {__('Change my pharmacy')}
                                </span>
                                <span>
                                    <Image
                                        src={ arrowright }
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                            </div>
            );
        }
    }

    renderCategoryDetails() {
        const { isLoadingStore } = this.props;

        if (isLoadingStore) {
            return <><Loader isLoading={ true }/></>;
        }

        return (
            <article block="StorePageDetails">
                <div block="StorePageDetails" elem="Description">
                    { this.renderStoreContent() }
                </div>
            </article>
        );
    }

    onPopupChangeStore(clickedPharmacy, clickedPharmacyUrl) {
        const { updateContextPharmacy, showCartPopup } = this.props;
        updateContextPharmacy(clickedPharmacy, clickedPharmacyUrl);
        showCartPopup();
    }

    renderPopupCartAlert() {
        const { showCartPopup, storeObj: { store_url, pharmacy_code }, } = this.props;

        return (
            <Popup
                id={ 'CartItemsAlert' }
                clickOutside={ false }
                mix={ { block: 'CartItemsAlert' } }
                closedOn={ false }
            >
                <header class="Popup-Header">
                    <h3 class="Popup-Heading">
                        <Image src={ delete_icon } width="35px" height="35px" />
                        <br/>
                        { __('By changing pharmacy your cart may be changed.') }
                    </h3>
                </header>
                <p>{ __('Do you wish to continue?') }</p>
                <div block="CartItemsAlert" elem="Actions" >
                    <button
                        block="CartItemsAlert"
                        elem="PrimaryButton"
                        mix={ { block: 'Button' } }
                        onClick={ () => showCartPopup() }
                    >{ __('No') }</button>
                </div>
                <div block="CartItemsAlert" elem="Actions">
                    <button
                        block="CartItemsAlert"
                        elem="SecondaryButton"
                        mix={ { block: 'Button' } }
                        onClick={ () => this.onPopupChangeStore(pharmacy_code, store_url) }
                    >{ __('Yes') }</button>
                </div>
            </Popup>
        );
    }

    render() {
        return (
            <main block="StorePage">
                <ContentWrapper
                    wrapperMix={ {
                        block: 'StorePage',
                        elem: 'Wrapper'
                    } }
                    label="StorePage page"
                >
                    { this.renderPopupCartAlert() }
                    { this.renderCategoryDetails() }
                </ContentWrapper>
            </main>
        );
    }
}

export default StorePageInfo;
