/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common';
import { PriceType } from 'Type/ProductList';
import {
    calculateFinalPrice,
    formatPrice,
    roundPrice,
    formatCurrency
} from 'Util/Price';

import ProductPrice from './ProductPrice.component';

/**
 * Product price
 * @class ProductPrice
 * @namespace Component/ProductPrice/Container
 */
export class ProductPriceContainer extends PureComponent {
    static propTypes = {
        isSchemaRequired: PropTypes.bool,
        price: PriceType,
        mix: MixType
    };

    static defaultProps = {
        isSchemaRequired: false,
        mix: {},
        price: {},
        price_type: 'min'
    };

    state = {
        isLoading: true
    }

    containerProps = () => {
        const { price_type } = this.props;
        const {
            price: {
                maximal_price,
                minimum_price
            } = {}
        } = this.props;

        let roundedRegularPrice = null;
        let finalPrice = null;
        let formattedFinalPrice = null;
        let discountPercentage = null;
        let priceCurrency = null;
        let priceValue = null;
        let regularPriceValue = null;

        if (maximal_price == undefined || minimum_price == undefined ) {
            return {};
        }

        if(price_type == 'max') {
            discountPercentage = maximal_price.discount.percent_off;
            priceCurrency = maximal_price.final_price.currency;
            priceValue = maximal_price.final_price.value;
            regularPriceValue = maximal_price.regular_price.value;

            let finalPriceValue = parseFloat(maximal_price.final_price.value).toFixed(2);

            roundedRegularPrice = formatCurrency(minimum_price.final_price.currency) + " " + roundPrice(maximal_price.regular_price.value);
            finalPrice = calculateFinalPrice(maximal_price.discount.percent_off, maximal_price.final_price.value, maximal_price.regular_price.value);
            formattedFinalPrice = formatPrice(finalPriceValue, maximal_price.final_price.currency);
        } else {
            discountPercentage = minimum_price.discount.percent_off;
            priceCurrency = minimum_price.final_price.currency;
            priceValue = minimum_price.final_price.value;
            regularPriceValue = minimum_price.regular_price.value;

            let finalPriceValue = parseFloat(minimum_price.final_price.value).toFixed(2);

            roundedRegularPrice = formatCurrency(minimum_price.final_price.currency) + " " + roundPrice(minimum_price.regular_price.value);
            finalPrice = calculateFinalPrice(minimum_price.discount.percent_off, minimum_price.final_price.value, minimum_price.regular_price.value);
            formattedFinalPrice = formatPrice(finalPriceValue, minimum_price.final_price.currency);
        }

        this.setState({isLoading: false});

        return {
            roundedRegularPrice,
            priceCurrency,
            discountPercentage,
            formattedFinalPrice
        };
    };

    render() {
        return (
            <ProductPrice
              { ...this.props }
              { ...this.state }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductPriceContainer;