import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { showNotification } from 'Store/Notification/Notification.action';
import MyAccountCreateAccount from './MyAccountCreateAccount.component';

import {
    MyAccountDispatcher as SourceMyAccountDispatcher,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';


//TODO: implement MyAccountDispatcher
export const MyAccountDispatcher = SourceMyAccountDispatcher;

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    // TODO extend mapStateToProps
    isLoading: state.MyAccountReducer.isLoading
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
    createAccount: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createAccount(options, dispatch)
    ),
    //showNotification: (type, message) => dispatch(showNotification(type, message))
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
});

export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    // TODO implement logic

    static propTypes = {
        createAccount: PropTypes.func.isRequired,
        onSignIn: PropTypes.func.isRequired,
        setSignInState: PropTypes.func.isRequired,
        setLoadingState: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    state = {
        showErrorMessage:false,
        isSubscribedNecessary: false
    }

    containerFunctions = {
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(this),
        onCreateAccountAttempt: this.onCreateAccountAttempt.bind(this)
    };

    onCreateAccountAttempt(_, invalidFields) {
        const { showNotification, setLoadingState } = this.props;

        /*if (invalidFields) {
            showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
        }*/

        setLoadingState(!invalidFields);
    }



    async onCreateAccountSuccess(fields) {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading
        } = this.props;

        const {
            password,
            email,
            firstname,
            lastname,
            accept_terms,
            fullname,
            dob
        } = fields;

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                accept_terms,
                dob
            },
            password
        };

        if (isLoading) {
            return;
        }

        setLoadingState(true);

        try {
            if(!accept_terms) {
                this.setState({
                    isSubscribedNecessary: true
                });
                return false;
            }

            this.splitFullname(fields, customerData);

            this.setState ({
                showErrorMessage: false,
                isSubscribedNecessary: false
            })
            const code = await createAccount(customerData);
            //if user needs confirmation
            if (code === 2) {
                setSignInState(STATE_CONFIRM_EMAIL);
            } else {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }


    splitFullname(fields, customerData) {
        let fullName = fields.fullname.trim();
        let nameArray    = fullName.split(' ');
        let count = nameArray.length;
        let name = '';
        for (let i = 0; i<count; i++) {
            if (i == (count-1)) {
                fields.lastname  = nameArray[i];
            } else {
                if (fields.firstname) {
                    fields.firstname = fields.firstname + ' ' + nameArray[i];
                } else {
                    fields.firstname = nameArray[i];
                }
            }
        }

        /*for (let index = 2; index < nameArray.length; index++) {
            fields.lastname = fields.lastname + ' ' + nameArray[index];
        }*/

        customerData.customer.firstname = fields.firstname.trim();
        customerData.customer.lastname  = fields.lastname.trim();
        return customerData;
    }

    render() {
        return (
            <MyAccountCreateAccount
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
