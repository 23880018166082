// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../../node_modules/slick-carousel/slick/slick.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../../node_modules/slick-carousel/slick/slick-theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HealthCardVouchersSlider{}.HealthCardVouchersSlider .custom-slick{display:block}.HealthCardVouchersSlider .slick-list{display:flex}.HealthCardVouchersSlider .slick-prev:before,.HealthCardVouchersSlider .slick-next:before{background:black;border:2px solid #fff;border-radius:20px}.HealthCardVouchersSliderSlick .slick-slider .slick-list .slick-track .slick-slide>div{text-align:center}.HealthCardVouchersSliderSlick .slick-slider .slick-list .slick-track .slick-slide>div .card{margin:0 10px}\n", "",{"version":3,"sources":["webpack://src/app/component/HealthCardVouchersSlider/HealthCardVouchersSlider.style.scss"],"names":[],"mappings":"AAA8F,0BAA0B,CAAC,wCAAwC,aAAa,CAAC,sCAAsC,YAAY,CAAC,0FAA0F,gBAAgB,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,uFAAuF,iBAAiB,CAAC,6FAA6F,aAAa","sourcesContent":["@import url(slick-carousel/slick/slick.css);@import url(slick-carousel/slick/slick-theme.css);.HealthCardVouchersSlider{}.HealthCardVouchersSlider .custom-slick{display:block}.HealthCardVouchersSlider .slick-list{display:flex}.HealthCardVouchersSlider .slick-prev:before,.HealthCardVouchersSlider .slick-next:before{background:black;border:2px solid #fff;border-radius:20px}.HealthCardVouchersSliderSlick .slick-slider .slick-list .slick-track .slick-slide>div{text-align:center}.HealthCardVouchersSliderSlick .slick-slider .slick-list .slick-track .slick-slide>div .card{margin:0 10px}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
