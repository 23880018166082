import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Field from 'Component/Field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

import './MyAccountPrescriptionDetailsLine.style';

class MyAccountPrescriptionDetailsLine extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        quantity: 0
    }

    renderButtonAddToCart(
        isExpiring2Days,
        prescriptionLineCode,
        prescriptionLineDescription,
        prescriptionLineDosage,
        prescriptionLineQuantity,
        prescriptionLineType,
        prescriptionNumber,
        fDate,
        disabled
    ) {
        const { addToCart, showExpire2DaysPopup } = this.props;
        const { quantity } = this.state;

        if (isExpiring2Days) {
            return (
                <button
                    block="MyAccountPrescriptionDetails"
                    elem="Button"
                    disabled={ this.isDisabled(fDate) }
                    mix={ { block: 'Button' } }
                    onClick={ () => showExpire2DaysPopup() }
                >
                    { __('Add') }
                </button>
            );
        } else {
            return (
                <button
                    block="MyAccountPrescriptionDetails"
                    elem="ButtonAdd"
                    disabled={ disabled || this.isDisabled(fDate) }
                    mix={ { block: 'Button' } }
                    onClick={ () => addToCart(
                        prescriptionLineCode,
                        prescriptionLineDescription,
                        prescriptionLineDosage,
                        quantity,
                        prescriptionLineQuantity,
                        prescriptionLineType,
                        prescriptionNumber
                        ) }
                >
                    { __('Add') }
                </button>
            );
        }
    }

    isDisabled(expireDate) {
        const date1 = new Date();
        date1.setDate(date1.getDate()-1);
        // const arr = expireDate.split(/[- :\/]/);
        // const date2 = new Date(parseInt(arr[2]), parseInt(arr[0])-1, parseInt(arr[1]));
        // const date2 = new Date(expireDate);

        if (expireDate < date1) {
            return true;
        }
        return false;
    }

    getSelectOptions(items) {
        const copy = [];
        items.forEach(function(item) {
            if(item != undefined) {
                copy.push({'label' : item.name, 'value' : item.value, 'id' : item.id});
            }
        });

        return copy;
    }

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    render() {
        const { i, item, isMobile, prescriptonDetails, totals: { is_wallet, items } } = this.props;
        const { quantity } = this.state;
        const listItems = [];
        // let fDeconstruct = item.prescriptionLineForfeit.split('T');

        // let fParts = fDeconstruct[0].split('-');
        // let fDate = new Date(fParts[0], parseInt(fParts[1]) - 1, parseInt(fParts[2]));
        // IOs fixing: https://coderedirect.com/questions/583350/date-on-ios-device-returns-nan
        // fDate = new Date(fDate);

        let fDate = new Date(Date.parse(item.prescriptionLineForfeit));

        let date = new Date();
        let isExpired = fDate < date;
        date.setDate(date.getDate() + 7);
        let isExpiring = !isExpired && fDate < date;

        let date2 = new Date();
        date2.setDate(date2.getDate() + 1);
        let isExpiring2Days = fDate <= date2;

        for (let i = 1; i <= item.prescriptionLineQuantity; i++) {
            let numString;

            if (i < 10) {
                numString = '0' + i.toString()
            } else {
                numString = i.toString();
            }
            listItems.push({id:i,name:numString,value:i})
        }

        const array_list = this.getSelectOptions(listItems);
        if(quantity == 0) {
            this.setState({ quantity: item.prescriptionLineQuantity });
        }

        let disabled = false;
        if (is_wallet === 'PRODWALLET') {
            // Check if cart has product
            let itemWallet = items.find(_i => {
                let code = Object.keys(_i.customizable_options).find(k => _i.customizable_options[k].label === 'Code');

                return code && _i.customizable_options[code] && _i.customizable_options[code].values[0].value === item.prescriptionLineCode;
            });

            if (itemWallet && parseInt(quantity) > (parseInt(item.prescriptionLineQuantity) - itemWallet.qty)) {
                disabled = true;
            }
        }

        const fDateStr = this.addZero(fDate.getDate()) + '/' + this.addZero(fDate.getMonth() + 1) + '/' + fDate.getFullYear();

        return (
            <div key={ item.prescriptionLineCode || i.toString() } block="MyAccountPrescriptionDetails" elem="Product-Row">
                <div block="MyAccountPrescriptionDetails" elem="Product-Row-Image">
                        <div block="MyAccountPrescriptionDetails" elem="Qtd">{ !isMobile &&  __("Quantity Available") }<strong>{item.prescriptionLineQuantity}</strong></div>
                </div>
                <div block="MyAccountPrescriptionDetails" elem="Product-Row-Info">
                    <div block="MyAccountPrescriptionDetails" elem="Product-Row-InfoTable">
                        <div>
                            <label><strong>{ item.prescriptionLineDescription }</strong></label>
                            <div block="MyAccountPrescriptionDetails" elem="Details">
                                <span block="MyAccountPrescriptionDetails" elem="Capacity">{ item.prescriptionLineDosage }</span>
                            </div>
                        </div>
                        <div block="MyAccountPrescriptionDetails" elem="Validity">
                            { !isMobile && <p>
                            <br></br>
                            </p> }
                            { !isExpiring && <div><p><strong>{ __("Validity: ") }</strong>{ fDateStr } </p></div> }
                            { isExpiring && <div block="MyAccountPrescriptionDetails" elem="Expired"><p><strong>{ __("Validity: ") }</strong>{ fDateStr } <FontAwesomeIcon className="MyAccountPrescriptionDetails-AlertIcon" icon={ faExclamationCircle } title={ __("Prescription line expiring in the next 7 days") } /></p></div> }<br/>
                            { /*<strong>fDate.toLocaleDateString()</strong>*/ }
                        </div>
                        <div block="MyAccountPrescriptionDetails" elem="Group">
                            <div block="MyAccountPrescriptionDetails" elem="Qtn">
                                <Field
                                    id={"item_qty_" + i}
                                    name={"item_qty_" + i}
                                    type="select"
                                    selectOptions={ array_list }
                                    value={ item.prescriptionLineQuantity }
                                    max={ item.prescriptionLineQuantity }
                                    min="1"
                                    mix={ { block: 'MyAccountPrescriptionDetails', elem: 'Qty' } }
                                    onChange={ value => this.setState({ quantity: value }) }
                                    />
                            </div>
                            <div block="MyAccountPrescriptionDetails" elem="Add">
                                { this.renderButtonAddToCart(
                                    isExpiring2Days,
                                    item.prescriptionLineCode,
                                    item.prescriptionLineDescription,
                                    item.prescriptionLineDosage,
                                    item.prescriptionLineQuantity,
                                    item.prescriptionLineType,
                                    prescriptonDetails.prescriptionNumber,
                                    fDate,
                                    disabled
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyAccountPrescriptionDetailsLine;
