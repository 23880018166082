import {
    CheckoutDeliveryOptions as SourceCheckoutDeliveryOptions,
} from 'SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.component';

import './CheckoutDeliveryOptions.style.override'

export class CheckoutDeliveryOptions extends SourceCheckoutDeliveryOptions {
    // TODO implement logic
};

export default CheckoutDeliveryOptions;
