import {
    TEXT_TYPE as SourceTEXT_TYPE,
    NUMBER_TYPE as SourceNUMBER_TYPE,
    RADIO_TYPE as SourceRADIO_TYPE,
    CHECKBOX_TYPE as SourceCHECKBOX_TYPE,
    TEXTAREA_TYPE as SourceTEXTAREA_TYPE,
    PASSWORD_TYPE as SourcePASSWORD_TYPE,
    SELECT_TYPE as SourceSELECT_TYPE,
    ENTER_KEY_CODE as SourceENTER_KEY_CODE
} from 'SourceComponent/Field/Field.config';

export const TEXT_TYPE = SourceTEXT_TYPE;
export const NUMBER_TYPE = SourceNUMBER_TYPE;
export const RADIO_TYPE = SourceRADIO_TYPE;
export const CHECKBOX_TYPE = SourceCHECKBOX_TYPE;
export const TEXTAREA_TYPE = SourceTEXTAREA_TYPE;
export const PASSWORD_TYPE = SourcePASSWORD_TYPE;
export const SELECT_TYPE = SourceSELECT_TYPE;
export const ENTER_KEY_CODE = SourceENTER_KEY_CODE;
export const DATE_TYPE = 'date';
export const FILE_TYPE = 'file';
export const BASE64_TYPE = 'image-base64';
export const PHONE_TYPE = 'phone';
export const NUMERIC_TYPE = 'numeric';
export const POST_CODE = 'postcode';