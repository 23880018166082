import React, { PureComponent } from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { DeviceType } from 'Type/Device';




import AvailableServicesPage from './AvailableServicesPage.component';
import {StoreFinderDispatcher} from "Store/StoreFinder";
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

import { updateMeta } from 'Store/Meta/Meta.action';


export const BreadcrumbsDispatcher = import(
        /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
        'Store/Breadcrumbs/Breadcrumbs.dispatcher'
    );

export const mapStateToProps = state => ({

    services: state.StoreFinderReducer.serviceList,
    device: state.ConfigReducer.device

});

export const mapDispatchToProps = (dispatch) => ({
    getAvailableServices: () => StoreFinderDispatcher.requestServicesData(dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    toggleBreadcrumbs: (state) => dispatch(toggleBreadcrumbs(state))

});

export class AvailableServicesPageContainer extends PureComponent {
    static propTypes = {
        getAvailableServices: PropTypes.func.isRequired,
        toggleBreadcrumbs: PropTypes.func.isRequired,
        device: DeviceType.isRequired
    };


    __construct(props) {
        super.__construct(props);
        this.containerFunctions = {};

        const { toggleBreadcrumbs } = props;

        toggleBreadcrumbs(false);

    }

    componentDidMount() {
        const { updateMeta } = this.props;
        this.getAvailableServices();
        updateMeta({ title: __('Serviços') });
        toggleBreadcrumbs(true);
    }


    componentDidUpdate() {
        const {device, toggleBreadcrumbs} = this.props;

        if (device.isMobile) {
            toggleBreadcrumbs(false);
        } else {
            this.updateBreadcrumbs();
        }

    }

    getAvailableServices() {
        const { getAvailableServices } = this.props;

        getAvailableServices();

    }

    /**
     * Dispatch breadcrumbs update
     * @return {void}
     */
    updateBreadcrumbs() {
        const {updateBreadcrumbs} = this.props;

        const breadcrumbs = [
            {
                url: '/servicos',
                name: __('Serviços')
            },
            {
                url: '/',
                name: __('Home')
            }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    render() {

        return (
            <>
                <AvailableServicesPage
                    { ...this.props }
                    { ...this.containerFunctions }
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableServicesPageContainer);
