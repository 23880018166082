/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Field from 'Component/Field/Field.component';
import InputRange from 'react-input-range';
import {  faSlidersH, faTimes, faList, faMap, faMapMarkerAlt  } from '@fortawesome/pro-light-svg-icons';




/** @namespace Component/MyAccountCoPaymentsTable/Component */
export class StoreSelectorMapFilters extends PureComponent {
    __construct(props) {
        super.__construct(props);

        this.state = {
            selectedCity: 'All cities',
            selectedStore: {},
            searchValue: '',
            filtersVisible: false,
            mapVisible: false,
            listVisible: true,
            searchingOnline: false,
            searchingDelivery: false,
            searchingSauda: (localStorage.getItem('saudaPercent') == "true") ? true : false,
            searchingOpen: false,
            viewAll: true,
            searchingOnCall: false,
            searchingService: '',
            changingSchedule: {
                min: 0,
                max: 86400,
            },
            coordinates: [],
            showMarker: false,
            map: null,
            tmpChangeServiceValue: '',
            tmpChangingSchedule: {
                min: 0,
                max: 86400,
            },
            isMapRender: false
        };

        this.handleClick = this.handleClick.bind(this);

    }

    state = {
        tmpViewAll: false,
        controllViewAll: false,
        controllButtonClick: false
    }

    handleClick = (e) => {
        const { device } = this.props;

        if (!device.isMobile) {
            if(!this.state.controllButtonClick) {
                window.addEventListener('click', this.windowClicker);
            } else {
                window.removeEventListener('click', this.windowClicker);
            }
        }
    }

    windowClicker = () => {
        this.state.changeAction = true;
        this.setState({ filtersVisible: !this.state.filtersVisible });
        window.removeEventListener('click', this.windowClicker);
    }

    mouseFiltersEnter = (e) => {
        const { device } = this.props;

        if (!device.isMobile) {
            this.setState({controllButtonClick: false});
            window.removeEventListener('click', this.windowClicker);
        }
    }

    onInputChange(value, callback) {
        this.setState({ searchValue: value });
        callback(value);
    }

    onSearchEnterPress = (e) => {
        const { searchValue } = this.state;
        const { device, handleInputThrottled } = this.props;

        const search = searchValue ? searchValue.trim().replace(/\s\s+/g, '%20') : '';
        const trimmedSearch = search ? search.trim() : '';

        if (device.isMobile) {
            if ((e.key === 'Enter' || e.key == '13') && trimmedSearch !== '') {
                handleInputThrottled(searchValue);
                e.target.blur();
            }
        }
    }

    toggleFilters = () =>  {
        this.state.changeAction = true;
        this.setState({ filtersVisible: !this.state.filtersVisible });
        const { device } = this.props;
        if (!device.isMobile) {
            setTimeout(() => {
                if(!this.state.controllButtonClick) {
                    window.addEventListener('click', this.windowClicker);
                } else {
                    window.removeEventListener('click', this.windowClicker);
                }
            }, 300);
        }
    }

    renderFilters() {
        const { filtersVisible, searchValue, isMapRender } = this.state;
        const {  useLocation, toggleMap, listVisible, mapVisible, handleInputThrottled } = this.props;

        const { device } = this.props;

        if(!device.isMobile){
            return (
                <>
                        <div block="StoreSelector" elem="FilterSection" mods={ { filtersVisible } } onMouseLeave={ (e) => this.handleClick(e) } onMouseEnter={ (e) => this.mouseFiltersEnter(e) }>
                            { this.renderSelectList() }
                        </div>
                        <div block="StoreSelector">
                            <div block="StoreSelector" elem="UseLocation" onClick={ () => useLocation() }>
                                <FontAwesomeIcon
                                    className="StoreSelector-UseLocationIcon"
                                    icon={ faMapMarkerAlt }
                                />
                                <span>{ __('Use my location') }</span>
                            </div>
                            <div block="StoreSelector" elem="SearchSection">
                                <Field
                                    id="search-map"
                                    name="search-map"
                                    type="text"
                                    placeholder={ __('Name or City') } // Name or City
                                    mix={ { block: 'SearchField', elem: 'Input' } }
                                    value={ searchValue }
                                    onChange={ event => this.onInputChange(event.target.value, handleInputThrottled) }
                                />
                                {/* <FontAwesomeIcon
                                    className="StoreSelector-FilterToggle"
                                    icon={ faSlidersH }
                                    onClick={ this.toggleFilters }
                                /> */}
                                <div id="search-result"></div>
                            </div>
                            <div block="StoreSelector" elem="FiltersTab">
                                { this.renderFilterTab() }
                            </div>
                            <div block="StoreSelector" elem="MobileMapButtons">
                                <div block="StoreSelector" elem="MobileMapButton" mods={ { listVisible } } onClick={ () => toggleMap() }>
                                    <FontAwesomeIcon
                                        className="StoreSelector-MobileMapButtonIcon"
                                        icon={ faList }
                                    />
                                    { __('See List') }
                                </div>
                                <div block="StoreSelector" elem="MobileMapButton" mods={ { mapVisible } } onClick={ () => toggleMap() }>
                                    <FontAwesomeIcon
                                        className="StoreSelector-MobileMapButtonIcon"
                                        icon={ faMap }
                                    />
                                    { __('See Map') }
                                </div>
                            </div>
                        </div>
                </>
            );
        } else {
            return (
                <>
                        <div block="StoreSelector" elem="FilterSection" mods={ { filtersVisible } } onMouseLeave={ (e) => this.handleClick(e) } onMouseEnter={ (e) => this.mouseFiltersEnter(e) }>
                            { this.renderSelectList() }
                        </div>
                        <div block="StoreSelector">
                            <div block="StoreSelector" elem="SearchSection">
                                <Field
                                    id="search-map"
                                    name="search-map"
                                    type="text"
                                    placeholder={ __('Name or City') } // Name or City
                                    mix={ { block: 'SearchField', elem: 'Input' } }
                                    value={ searchValue }
                                    onChange={ event => this.onInputChange(event.target.value, handleInputThrottled) }
                                    specialEnterDown={ this.onSearchEnterPress }
                                />
                                <FontAwesomeIcon
                                    className="StoreSelector-UseLocationIcon"
                                    icon={ faMapMarkerAlt }
                                    onClick={ () => useLocation() }
                                />
                                { mapVisible &&
                                    <FontAwesomeIcon
                                        className="StoreSelector-MobileMapButtonIconList"
                                        icon={ faList }
                                        onClick={ () => this.changeFilterButton() }
                                    />
                                }
                                { !mapVisible &&
                                    <FontAwesomeIcon
                                        className="StoreSelector-MobileMapButtonIconMap"
                                        icon={ faMap }
                                        onClick={ () => this.changeFilterButton() }
                                    />
                                }
                                {/* <FontAwesomeIcon
                                    className="StoreSelector-FilterToggle"
                                    icon={ faSlidersH }
                                    onClick={ this.toggleFilters }
                                /> */}
                                <div id="search-result"></div>
                            </div>
                            <div block="StoreSelector" elem="FiltersTab">
                                { this.renderFilterTab() }
                            </div>
                        </div>
                </>
            );
        }
    }

    changeFilterButton() {
        const { toggleMap } = this.props;

        this.setState({ isMapRender: !this.state.isMapRender });
        toggleMap();
    }

    renderFilterTab() {
        const {
            searchingOnline,
            searchingDelivery,
            searchingSauda,
            changeSearchingOnline,
            changeSearchingDelivery,
            changeSearchingSauda

        } = this.props;

        return (<>
                <div block="StoreSelector" elem="Select">
                    <Field
                        id="has-sauda"
                        name="has-sauda"
                        type="checkbox"
                        label={ __('Sauda card') }
                        checked={ searchingSauda }
                        onChangeCheckbox={ value => changeSearchingSauda() }
                    />
                </div>
            {/*  <div block="StoreSelector" elem="Select">
                    <Field
                    id="has-delivery"
                    name="has-delivery"
                    type="checkbox"
                    label={ __('Home delivery') }
                    checked={ searchingDelivery }
                    onChangeCheckbox={ value => changeSearchingDelivery() }
                    />
                </div>
                <div block="StoreSelector" elem="Select">
                    <Field
                    id="online-store"
                    name="online-store"
                    type="checkbox"
                    label={ __('Buy online') }
                    checked={ searchingOnline }
                    onChangeCheckbox={ value => changeSearchingOnline() }
                    />
                </div> */}
            </>);
    }

    onServiceChange = (value) => {
        let val = value;
        if (typeof value !== 'number') {
            val = value.target.value;
        }
        this.setState({ tmpChangeServiceValue: val });
    };

    renderSelectList() {
        const { changingSchedule, tmpChangeServiceValue, tmpViewAll, tmpChangingSchedule } = this.state;
        const {
            selectedCity,
            searchingOpen,
            searchingOnline,
            searchingDelivery,
            searchingSauda,
            searchingOnCall,
            searchingService,
            changeSearchingOpen,
            changeViewAll,
            changeSearchingOnline,
            changeSearchingDelivery,
            changeSearchingSauda,
            changeSearchingOnCall,
            changeService,
            prepareServicesOptions,
            startSearchSchedule,
            viewAll

        } = this.props;

        return (
            <div block="StoreSelector" elem="SelectList">
                { this.renderHeading() }
                <div block="StoreSelector" elem="SelectMapFilters">
                    <Field
                    id="show-all"
                    name="show-all"
                    type="checkbox"
                    label={ __('View all pharmacies') }
                    checked={ tmpViewAll }
                    onChangeCheckbox={ value => this.changeTmpViewAll(value) }
                    />
                </div>

                <div block="StoreSelector" elem="Select">
                    <Field
                    id="services"
                    name="services"
                    type="select"
                    label={ __('Pharmacy services') }
                    mix={ { block: 'StoreLocation', elem: 'Search' } }
                    mods={ { select: true } }
                    selectOptions={ prepareServicesOptions() }
                    value={ tmpChangeServiceValue }
                    isStoreSelector={true}
                    onChange={ value => this.onServiceChange(value) }
                    />
                </div>
                <div block="StoreSelector" elem="Select" mods={ { isRange: true } }>
                    <strong block="StoreSelector" elem="RangeLabel">
                    { __('Filter by schedule') }
                    </strong>
                    <InputRange
                        maxValue={ 86400 }
                        minValue={ 0 }
                        formatLabel={ value => this.formatLabel(value) }
                        value={ tmpChangingSchedule }
                        step= { 900 }
                        onChange={ value => this.setState({ tmpChangingSchedule: value }) }
                    />
                </div>
                <div block="StoreSelector" elem="Actions">
                    <button block="Button" onClick={ () => this.applyFilters() }>
                        <span>{ __('Apply') }</span>
                    </button>

                    <button block="Button" elem="BtnCleanFilters" onClick={ () => this.resetFilters() }>
                            <span>{ __('Clean all') }</span>
                    </button>
                </div>
            </div>
        );
    }

    changeTmpViewAll = (value) => {
        this.setState({ tmpViewAll: !this.state.tmpViewAll });
    }

    applyFilters = () => {
        const { viewAll, searchingService, changingSchedule, tmpChangeServiceValue, tmpChangingSchedule, tmpViewAll, controllViewAll } = this.state;
        const { changeViewAll, changeService, startSearchSchedule, device } = this.props;
        if (!device.isMobile) {
            window.removeEventListener('click', this.windowClicker);
        }
        this.setState({controllButtonClick: true});

        if (tmpViewAll) {
            changeViewAll(false);
        } else {
            changeViewAll(true);
        }
        changeService(tmpChangeServiceValue);
        startSearchSchedule(tmpChangingSchedule);
        this.state.changeAction = true;
        this.setState({ filtersVisible: !this.state.filtersVisible });

    }

    resetFilters = () => {
        const { tmpViewAll, controllViewAll } = this.state;
        const { changeViewAll, changeService, startSearchSchedule, device } = this.props;
        this.setState({controllButtonClick: true});
        this.setState({ tmpChangeServiceValue: '' });
        this.setState({ tmpChangingSchedule: {
                min: 0,
                max: 86400,
            }
        });

        changeViewAll(true);
        this.setState({ tmpViewAll: false });
        changeService('');
        startSearchSchedule({
            min: 0,
            max: 86400,
        });
    }

    formatLabel(secs) {
        if (secs === 86400) secs = 86360;
        var minutes = Math.floor(secs / 60);
        var hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        if (secs < 3600) {
        hours = 0;
        }
        return `${hours}:${("0" + minutes).slice(-2)}`;
    }

    renderHeading() {
        return (
            <div block="StoreSelector" elem="Heading">
                <h2 block="StoreSelector" elem="Heading" mods={ { Title: true } }>
                    { __('Filter Results') }
                    <FontAwesomeIcon
                        className="StoreSelector-FilterToggle"
                        icon={ faTimes }
                        onClick={ () => this.closeFilters() }
                    />
                </h2>

            </div>

        );
    }

    closeFilters = () => {
        const { device } = this.props;
        if (!device.isMobile) {
            this.setState({controllButtonClick: true});
            window.removeEventListener('click', this.windowClicker);
        }
        this.state.changeAction = true;
        this.setState({ filtersVisible: !this.state.filtersVisible });
    }

    render(){

        return(<>
            { this.renderFilters() }
            </>
        );
    }
};

export default StoreSelectorMapFilters;