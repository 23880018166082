import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountPasswordTableContainer as SourceMyAccountPasswordTableContainer,
    mapStateToProps as SourceMapStateToProps,
} from 'SourceComponent/MyAccountCustomerTable/MyAccountCustomerTable.container';


import {
    CHANGE_PASSWORD,
    CUSTOMER_POPUP_ID,
    EDIT_CUSTOMER
} from 'Component/MyAccountCustomerPopup/MyAccountCustomerPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { customerType } from 'Type/Account';

import MyAccountPasswordTable from './MyAccountPasswordTable.component';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';



export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
    showPopup: (payload) => dispatch(showPopup(CUSTOMER_POPUP_ID, payload))
});

export const mapStateToProps = (state) => ({});

export class MyAccountPasswordTableContainer extends PureComponent{
    // TODO implement logic
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        FormTitle: PropTypes.string.isRequired
    };

    static defaultProps = {
        FormTitle : "Password"
    };

    state = {
        showHideForm: false
    };

    showEditForm(){
        const { disableTables , hideTables } = this.props;
        this.setState({
           showHideForm: !this.state.showHideForm
        });
        disableTables(!hideTables);
        window.scrollTo(0,0);
     }

    containerFunctions = {
        showEditPopup: this.showEditPopup.bind(this),
        showChangePasswordPopup: this.showChangePasswordPopup.bind(this),
        showEditForm: this.showEditForm.bind(this)
    };

    showEditPopup() {
        const { showPopup, customer } = this.props;

        showPopup({
            action: EDIT_CUSTOMER,
            customer,
            title: __('Edit customer details')
        });
    }

    showChangePasswordPopup() {
        const { showPopup, customer } = this.props;

        showPopup({
            action: CHANGE_PASSWORD,
            customer,
            title: __('Change password')
        });
    }

    render() {
        return (
            <MyAccountPasswordTable
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPasswordTableContainer);
