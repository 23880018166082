import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    MyAccountOrderTableRowContainer as SourceMyAccountOrderTableRowContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/MyAccountOrderTableRow/MyAccountOrderTableRow.container';

import MyAccountOrderTableRow from './MyAccountOrderTableRow.component';

import OrderQuery from 'Query/Order.query';
import { fetchQuery } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    // TODO extend mapStateToProps
});
export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getOrderList: () => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestOrders(dispatch)
    )
});

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

export class MyAccountOrderTableRowContainer extends SourceMyAccountOrderTableRowContainer {
    static propTypes = {
        manageShowDetail: PropTypes.func.isRequired,
        reBuy: PropTypes.func.isRequired
    }
    state = {
        showDetails: false,
        isLoading: false
    }

    containerFunctions = {
        setOrderStatusComplete: this.setOrderStatusComplete.bind(this),
        setOrderStatusCancelByUser: this.setOrderStatusCancelByUser.bind(this),
        clickRebuy: this.clickRebuy.bind(this)
    };


    onViewClick() {
        const { manageShowDetail, order } = this.props;
        const { base_order_info:{increment_id} } = order;

        this.setState({showDetails: true});
        const { showDetails } = this.state;
        manageShowDetail(order);

        /*showPopup({
            title: __('Order #%s', increment_id),
            increment_id,
            order
        });*/
    }

    clickRebuy(id){
        const { reBuy } = this.props;

        reBuy(id);
    }

    setOrderStatusComplete(orderId){
        const { showNotification, getOrderList } = this.props;
        this.setState({ isLoading: true });
        fetchQuery(OrderQuery._setOrderByIdCompleted(orderId)).then(
            /** @namespace Component/MyAccountOrderPopup/Container/requestOrderDetailsFetchQueryThen */
            ({ setOrderByIdCompleted: { status } }) => {
                if (status === 500) {
                    showNotification('error', __('Error setting order to Complete!'));
                    this.setState({ isLoading: false });
                } else {
                    // window.location.reload();
                    getOrderList()
                        .then(() => {
                            this.setState({ isLoading: false });
                        })
                        .catch(() => {
                            this.setState({ isLoading: false });
                        });
                }
                //showNotification('success', setOrderByIdCompleted.message);
            },
            /** @namespace Component/MyAccountOrderPopup/Container/requestOrderDetailsFetchQueryCatch */
            () => {
                showNotification('error', __('Error setting order to Complete!'));
                this.setState({ isLoading: false });
            }
        );
    }

    setOrderStatusCancelByUser(orderId){
        const { showNotification } = this.props;
        this.setState({ isLoading: true });
        fetchQuery(OrderQuery._setOrderByIdCancelByUser(orderId)).then(
            /** @namespace Component/MyAccountOrderPopup/Container/requestOrderDetailsFetchQueryThen */
            ({ setOrderByIdCancelByUser }) => {
                //showNotification('success', setOrderByIdCancelByUser.message);
                window.location.reload();
            },
            /** @namespace Component/MyAccountOrderPopup/Container/requestOrderDetailsFetchQueryCatch */
            () => {
                showNotification('error', __('Error setting order to Canceled By User!'));
                this.setState({ isLoading: false });
            }
        );
    }

    containerProps = () => {
        const { order: { base_order_info, is_wallet }, currency_code } = this.props;
        return { base_order_info, currency_code, is_wallet };
    };

    render() {
        return (
            <MyAccountOrderTableRow
              { ...this.state }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderTableRowContainer);
