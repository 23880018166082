import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TotalsType } from 'Type/MiniCart';

import MyAccountPrescriptionDetailsLine from './MyAccountPrescriptionDetailsLine.component';

export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals
});

export const mapDispatchToProps = (dispatch) => ({
    // ...
});

export class MyAccountPrescriptionDetailsLineContainer extends PureComponent {
    static propTypes = {
        totals: TotalsType.isRequired,
    };

    containerFunctions = {
        //...
    };

    state = {
        //...
    };

    render() {
        return (
            <MyAccountPrescriptionDetailsLine
              { ...this.containerFunctions }
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPrescriptionDetailsLineContainer);