import React, { PureComponent } from 'react';
import SiteMapComponent from './SiteMap.component';
import PropTypes from 'prop-types';
import { updateMeta } from 'Store/Meta/Meta.action';
import {connect} from 'react-redux';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'

);

export const mapStateToProps = state => ({
    categories: state.CategoriesReducer.categories.items,
});


export const mapDispatchToProps = dispatch => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
});


export class SiteMap extends PureComponent {

    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                map: PropTypes.string
            })
        }).isRequired,
    };

    _updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;

        updateBreadcrumbs([{
            url: '/',
            name: 'Sitemap'
        }, {
            url: '/',
            name: __('Home')
        }]);
    }

    componentDidMount(){
        this._updateBreadcrumbs()
    }

    render() {
        const { match: { params: { map: urlStep } } } = this.props;

        return(
            <SiteMapComponent
                { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SiteMap);