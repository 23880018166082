import PropTypes from 'prop-types';
import './MyAccountMyOrdersFilter.style';
import FieldForm from 'Component/FieldForm';
import {
    ENTER_KEY_CODE
} from 'Component/Field/Field.config';

class MyAccountMyOrdersFilter extends FieldForm {

    get fieldMap() {
        const { changeOrder, changeTotal, changeDate, changeStatus, clearFilter, searchOrderFilter, values} = this.props;
        let changeOrderValue = changeOrder;
        let changeTotalValue = changeTotal;
        let changeDateValue = changeDate;
        let changeStatusValue = changeStatus;

        if(clearFilter){
            changeOrderValue = "";
            changeTotalValue = "";
            changeDateValue = "";
            changeStatusValue = ""
        } else {
            const { searchingOrder, searchingTotal, searchingDate, searchingStatus } = values;
            if (searchingOrder && searchingOrder !== "") {
                changeOrderValue = searchingOrder;
            }

            if (searchingTotal && searchingTotal !== "") {
                changeTotalValue = searchingTotal;
            }

            if (searchingDate && searchingDate !== "") {
                changeDateValue = searchingDate;
            }

            if (searchingStatus && searchingStatus !== "") {
                changeStatusValue = searchingStatus;
            }
        }
        return {
            order: {
                label: __('Order'),
                type:'text',
                value: changeOrderValue,
                validation: ['notEmpty'],
                onChange: this.onChangeOrder,
                specialEnterDown: this.searchOrderFilter
            },
            total: {
                label: __('Total'),
                type:'text',
                value: changeTotalValue,
                validation: ['notEmpty'],
                onChange: this.onChangeTotal,
                specialEnterDown: this.searchOrderFilter
            },
            date: {
                key: 'date',
                label: __('Date'),
                value: changeDateValue,
                type: 'date',
                onChange: this.onChangeDate,
                specialEnterDown: this.searchOrderFilter
            },
            status: {
                type: 'select',
                label: __('Status:'),
                validation: ['notEmpty'],
                placeholder: __('Select a option'),
                value: changeStatusValue,
                selectOptions: [
                    /*{
                        id: 1,
                        value: 'Pendente',
                        label: __('Pending')
                    },*/
                    {
                        id: 2,
                        value: 'Em Validação',
                        label: __('Em Validação')
                    },
                    {
                        id: 3,
                        value: 'Aguarda Processamento',
                        label: __('Aguarda Processamento')
                    },
                    {
                        id: 4,
                        value: 'Em Processamento',
                        label: __('Em Processamento')
                    },
                    {
                        id: 5,
                        value: 'Aguarda Stock',
                        label: __('Aguarda Stock')
                    },
                    {
                        id: 6,
                        value: 'Pronta para Levantar',
                        label: __('Pronta para Levantar')
                    },
                    {
                        id: 7,
                        value: 'Em Distribuição',
                        label: __('Em Distribuição')
                    },
                    {
                        id: 8,
                        value: 'Concluída',
                        label: __('Concluída')
                    },
                    {
                        id: 9,
                        value: 'Cancelada',
                        label: __('Cancelada')
                    },
                    {
                        id: 10,
                        value: 'Erro',
                        label: __('Erro')
                    },
                    {
                        id: 11,
                        value: 'Aguarda Pagamento',
                        label: __('Aguarda Pagamento')
                    }
                    ,
                    {
                        id: 12,
                        value: 'Pagamento Rejeitado',
                        label: __('Pagamento Rejeitado')
                    }
                    ,
                    {
                        id: 13,
                        value: 'Pagamento Expirado',
                        label: __('Pagamento Expirado')
                    }
                    ,
                    {
                        id: 14,
                        value: 'Pagamento Devolvido',
                        label: __('Pagamento Devolvido')
                    }
                    ,
                    {
                        id: 15,
                        value: 'Paga',
                        label: __('Paga')
                    }
                ],
                onChange: this.onChangeStatus
            },
        };
    }

    searchOrderFilter = (event) => {
        const { searchOrderFilter } = this.props;
        if (event.keyCode === ENTER_KEY_CODE) {
            searchOrderFilter();
        }
    }

    onChangeOrder = (fields) => {
        const { changeOrder } = this.props;
        changeOrder(fields);
    }

    onChangeTotal = (fields) => {
        const { changeTotal } = this.props;
        changeTotal(fields);
    }

    onChangeDate = (fields) => {
        const { changeDate } = this.props;
        changeDate(fields);
    }

    onChangeStatus = (fields) => {
        const { changeStatus, searchOrderFilter } = this.props;
        changeStatus(fields);
        searchOrderFilter();
    }
}

export default MyAccountMyOrdersFilter;
