import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HealthCardVouchersList from './HealthCardVouchersList.component';
import { faLoveseat } from '@fortawesome/pro-duotone-svg-icons';

export const mapStateToProps = (state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

export const mapDispatchToProps = (dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

export class HealthCardExclusiveVouchersSliderContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
        cards: PropTypes.array.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
        showMore: this.showMore.bind(this)
    };

    containerProps = () => {
        const {
            showMoreFlag,
            total,
            cardsToShow
        } = this.state;

        return {
            showMoreFlag,
            total,
            cardsToShow
        }
    };

    state = {
        total: 0,
        totalFetch: 0,
        showMoreValue: 6,
        showMoreFlag: false,
        cardsToShow: []
    };

    componentDidMount() {
        const { cards } = this.props;
        const { showMoreValue } = this.state;

        this.setState({ total: cards.length});
        this.setState({ showMoreFlag: cards.length > showMoreValue });

        let cardsToShow =  cards.slice(0, showMoreValue);
        let totalFetch = cardsToShow.length;
        this.setState({ cardsToShow, totalFetch });

        setTimeout(() => {
            this.checkSeeMoreDescription();
        }, 200);
    }

    showMore() {
        const { cards } = this.props;
        const { totalFetch,  showMoreValue, total} = this.state;

        let showMoreFlag = true;
        let newTotalFetch = totalFetch + showMoreValue;
        if (newTotalFetch >= total) {
            newTotalFetch = total;
            showMoreFlag = false;
        }

        let cardsToShow =  cards.slice(0, newTotalFetch);

        this.setState({ totalFetch:newTotalFetch, cardsToShow, showMoreFlag});

        setTimeout(() => {
            this.checkSeeMoreDescription();
        }, 200);
    }

    /**
     * Check if card has see more action
     */
    checkSeeMoreDescription() {
        const cards = document.querySelectorAll('.HealthCardVouchersList-Container .card');
        
        for (let i = 0; i < cards.length; i++) {
            let description = cards[i].querySelector('.description-item');

            if (description.scrollHeight > description.offsetHeight) {
                let seeMore = cards[i].querySelector('.see-more');
                seeMore.style.display = 'block';
            }
        }
    }

    render() {
        return (
            <HealthCardVouchersList
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthCardExclusiveVouchersSliderContainer);
