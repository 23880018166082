import {
    ProductWishlistButton as SourceProductWishlistButton,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.style.override'
import iconWish from '../ProductActions/images/shape@3x.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartFull } from '@fortawesome/pro-solid-svg-icons';



export class ProductWishlistButton extends SourceProductWishlistButton {
    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
             // mix={ { block: 'Button', mods: { isHollow: !isInWishlist }, mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <FontAwesomeIcon icon={ isInWishlist ? faHeartFull : faHeart } />
            </button>
        );
    }
};

export default ProductWishlistButton;
