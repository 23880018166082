import {
    CartCoupon as SourceCartCoupon,
} from 'SourceComponent/CartCoupon/CartCoupon.component';
import Field from 'Component/Field';
import Loader from 'Component/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faTrashAlt, faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';

import './CartCoupon.style.override'

export class CartCoupon extends SourceCartCoupon {

    state = {
        enteredCouponCode: '',
        activeCampaignDesc: ''
    };

    handleApplyCoupon = () => {
        const { handleApplyCouponToCart } = this.props;
        const { enteredCouponCode } = this.state;

        handleApplyCouponToCart(enteredCouponCode);

        this.setState({
            enteredCouponCode: ''
        });
    };

    setActiveDesc(code) {
        const { activeCampaignDesc } = this.state;
        this.setState({
            activeCampaignDesc: code == activeCampaignDesc ? '' : code
        });
    }

    renderApplyCoupon() {
        const { enteredCouponCode } = this.state;

        return (
            <>
                <Field
                  type="text"
                  id="couponCode"
                  name="couponCode"
                  value={ enteredCouponCode }
                  placeholder={ __('Enter your coupon code.') }
                  onChange={ this.handleCouponCodeChange }
                  mix={ { block: 'CartCoupon', elem: 'Input' } }
                />
                <button
                  block="CartCoupon"
                  elem="Button"
                  type="button"
                  mix={ { block: 'Button' } }
                  disabled={ !enteredCouponCode }
                  onClick={ this.handleApplyCoupon }
                >
                    { __('Apply') }
                </button>
            </>
        );
    }

    renderActiveCampaigns() {
        const { totals: { quote_campaigns } } = this.props;
        //if there are active target campaigns
        if(quote_campaigns && quote_campaigns.length > 0) {
            //render each line
            return quote_campaigns.map(this.renderCampaign) ;
        }
    }

    renderCampaign = (campaign) => {
        const { has_target, title, value, valueType, description, code } = campaign;
        const { activeCampaignDesc } = this.state;
        let descActive = code == activeCampaignDesc;

        let discount = value.replace(',','.');

        let symbol = '';
        if(valueType == 'Cash') {
            symbol = '€';
        }
        if(valueType == 'Percentage') {
            symbol = '%';
        }

        //if(has_target) {
            return (
                <div block="CartCoupon" elem="TargetCampaign">
                    <FontAwesomeIcon className="CartCoupon-TargetCampaignIcon" icon={ faTag } />
                    <div block="CartCoupon" elem="TargetCampaignInfo">
                        <p>{ __('Added') }</p>
                        <p>{ title }</p>
                        <div>
                            <p block="CartCoupon" elem="TargetCampaignDescTrigger" onClick={ () => this.setActiveDesc(code) }>
                                { __('Discount details') } <FontAwesomeIcon icon={ descActive ? faChevronUp : faChevronDown } />
                            </p>
                            <p block="CartCoupon" elem="TargetCampaignDesc" mods={{ descActive }}>{ description }</p>
                        </div>
                    </div>
                    <span>{ symbol } -{ parseFloat(discount).toFixed(2).replace('.',',') }</span>
                    <FontAwesomeIcon className="CartCoupon-TargetCampaignDelete" icon={ faTrashAlt } onClick={ () => this.onCampaignDelete(campaign) } />
                </div>
            );
        //}
    }

    onCampaignDelete(campaign) {
        const { deleteCampaign } = this.props;
        deleteCampaign(campaign);
    }

    render() {
        const { isLoading, couponCode, mix } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                <form block="CartCoupon" onSubmit={ this.handleFormSubmit } mix={ mix }>
                    { this.renderTitle() }
                    { (couponCode
                        ? this.renderRemoveCoupon()
                        : this.renderApplyCoupon()
                    ) }
                </form>
                { this.renderActiveCampaigns() }
            </>
        );
    }

};

export default CartCoupon;
