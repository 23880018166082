import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './MyAccountPrescriptionDetails.style';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import MyAccountPrescriptionCard from 'Component/MyAccountPrescriptionCard';
import MyAccountPrescriptionPopup from 'Component/MyAccountPrescriptionPopup';
import { orderType } from 'Type/Account';
import { formatPrice } from 'Util/Price';
import QRCode from 'qrcode.react';
import icon_green from 'Component/MyAccountPrescriptionCard/images/icon_green.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import Field from 'Component/Field';
import Popup from 'Component/Popup';
import MyAccountPrescriptionDetailsLine from 'Component/MyAccountPrescriptionDetailsLine';
import { showNotification } from 'Store/Notification/Notification.action';

class MyAccountPrescriptionDetails extends PureComponent {

    static propTypes = {
        order: orderType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        currency_code: PropTypes.string.isRequired,
        requestOrderDetails: PropTypes.func.isRequired,
        prescriptonDetails: PropTypes.arrayOf(PropTypes.string).isRequired,
        addToCart: PropTypes.func.isRequired,
        onDeleteClick: PropTypes.func.isRequired,
        showExpire2DaysPopup: PropTypes.func.isRequired,
        getPrescriptionDetails: PropTypes.func.isRequired
    };

    state = { isTabName: { name: 'available', title: __('Available') } };

    renderBaseInfo() {
        const { prescriptonDetails: { prescriptionResponseDateTime }, isLoading } = this.props;

        if (!prescriptionResponseDateTime || isLoading) {
            return <Loader isLoading={ isLoading } />;
        }

        const today = new Date(prescriptionResponseDateTime.replaceAll(' ', 'T'));
        //const todatStr = this.addZero(today.getDay()) + '-' + this.addZero(today.getMonth()) + '-' + today.getFullYear();
        const todatStr = today.toLocaleString('pt-PT', { timeZone: 'Europe/Lisbon' }).replaceAll(':', 'h');
        let newDate = todatStr.split(',');
        let todayDate = newDate[0];

        const dateCalc = new Date(prescriptionResponseDateTime.replaceAll(' ', 'T'));

        const time = this.addZero(today.getHours()) + "h" + this.addZero(today.getMinutes());

        return (
            <div block="MyAccountPrescriptionDetails" elem="BaseInfo">
                <div block="MyAccountPrescriptionDetails" elem="Pharmacy">
                    <div>
                        <p>
                            { __("Information updated day ") } <b>{ todayDate }</b> { __(" at ") } <b>{ time }</b>.
                        </p>
                    </div>
                </div>
                <div block="MyAccountPrescriptionDetails" elem="Content">
                    <button block="MyAccountPrescriptionDetails" elem="Button" mix={ { block: 'Button' } } onClick={ () => this.showMessage(dateCalc) }>
                        { __('Information update') }
                    </button>
                </div>
            </div>
        );
    }

    showMessage = (dateCalc) => {
        const { showNotification, getPrescriptionDetails } = this.props;

        const now = new Date();
        let diffMs = (now - dateCalc);
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        if(diffMins >= 30){
            getPrescriptionDetails();
        } else {
            showNotification('info', __('This prescription was updated less than 30 minutes ago'));
        }
    }

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    renderPrescription() {
        const { device: { isMobile }, prescription } = this.props;

        let isExpired = prescription.status == 'Dispensed/Expired';
        let isExpiring = !isExpired;

        if(prescription.forfeit && prescription.forfeit != '') {
            let date = new Date();
            date.setDate(date.getDate()-1);
            let fParts = prescription.forfeit.split('-');
            let fDate = new Date(fParts[2], parseInt(fParts[1]) - 1, parseInt(fParts[0]));

            isExpired = prescription.status == 'Dispensed/Expired' || fDate < date;
            let date2 = new Date();
            date2.setDate(date2.getDate() + 7);
            isExpiring = !isExpired && fDate < date2;
        } else {
            isExpiring = false;
        }

        return (
            <div block="MyAccountPrescriptionDetails" elem="PrescriptionWrapper">
                { !isMobile &&<div block="MyAccountPrescriptionDetails" elem="Title">
                     <h4>{ prescription.medical_speciality ? prescription.medical_speciality : __('No Especiality') }</h4>
                </div> }
                { !isMobile &&<div block="MyAccountPrescriptionDetails" elem="DetailsTab">
                <div>
                    <strong>{ __("User: ") }</strong>{ prescription.name }<br/>
                    { !isExpiring && <div><p><strong>{ __("Validity: ") }</strong>{ prescription.forfeit ? prescription.forfeit.replaceAll('-','/') : __('Unavailable') } </p></div> }
                    { isExpiring && <div block="MyAccountPrescriptionDetails" elem="Expired"><p><strong>{ __("Validity: ") }</strong>{ prescription.forfeit.replaceAll('-','/') ? prescription.forfeit : __('Unavailable') } { prescription.forfeit ? <FontAwesomeIcon className="MyAccountPrescriptionDetails-AlertIcon" icon={ faExclamationCircle } title={ __("Prescription line expiring in the next 7 days") } /> : null } </p></div> }<br/>
                </div>
                <div block="MyAccountPrescriptionDetails" elem="Notice">{ __("Present this QRCode at your pharmacy for simplified reading of the prescription.") }</div>
                    { this.renderImage() }
                </div>}
                { isMobile &&  this.renderImage() }
                { isMobile &&
                 <div block="MyAccountPrescriptionDetails" elem="DetailsTab">
                    <div block="MyAccountPrescriptionDetails" elem="Title">
                        <h4>{ prescription.medical_speciality ? prescription.medical_speciality : __('No Especiality') }</h4>
                    </div>
                    <div>
                        <p block="MyAccountPrescriptionDetails" elem="User"><strong>{ __("User: ") }</strong>{ prescription.name }<br/></p>
                        { !isExpiring && <div><p><strong>{ __("Validity: ") }</strong>{ prescription.forfeit ? prescription.forfeit.replaceAll('-','/') : __('Unavailable') } </p></div> }
                        { isExpiring && <div block="MyAccountPrescriptionDetails" elem="Expired"><p><strong>{ __("Validity: ") }</strong>{ prescription.forfeit.replaceAll('-','/') ? prescription.forfeit : __('Unavailable') } { prescription.forfeit ? <FontAwesomeIcon className="MyAccountPrescriptionDetails-AlertIcon" icon={ faExclamationCircle } title={ __("Prescription line expiring in the next 7 days") } /> : null } </p></div> }<br/>
                    </div>
                 </div>
                  }
            </div>
        );
    }

    onClick = (item) => {
        this.setState({ isTabName: item });
    };

    renderTabItem(item) {
        const { isTabName } = this.state;
        const { device: { isMobile } } = this.props;

        let isSelected = false;

        if (isTabName.name == item.name)
        {
            isSelected = true;
        }

        if (isMobile && item.name == 'expired') {
            return (
                <div block="MyAccountPrescriptionDetails" elem="TabItem" mods={ { isSelected } }>
                    <button onClick={  () => this.onClick(item) }>
                        <strong>{__('Expired')}</strong><br></br>
                        <strong>{__('Dispensed')}</strong>
                    </button>
                </div>
            );
        } else {
            return (
                <div block="MyAccountPrescriptionDetails" elem="TabItem" mods={ { isSelected } }>
                    <button onClick={  () => this.onClick(item) }><strong>{ item.title }</strong></button>
                </div>
            );
        }
    }

    renderTabs() {
        let tabList = [
            { name: 'available', title: __('Available') },
            { name: 'expired', title: __('Expired/Dispensed') },
            { name: 'details', title: __('Add Details') }
        ];
        return (
            <div block="MyAccountPrescriptionDetails" elem="TabList">
                { tabList.map(tabitem => this.renderTabItem(tabitem)) }
            </div>
        );
    }

    renderImage() {
        const {
            onInfoClick
        } = this.props;

        return (
            <>
                <a onClick={ onInfoClick } block="MyAccountPrescriptionDetails" elem="Link">
                    { this.renderImageElement() }
                    <p block="MyAccountPrescriptionDetails" elem="Description">{ __("See Codes") }</p>
                </a>
            </>
        );
    }

    renderPopup() {
        return <MyAccountPrescriptionPopup />;
    }

    renderPopupExpire2Days() {
        return (
            <Popup
              id={ "MyAccountPrescriptionDetailsExpire2Days" }
              clickOutside={ true }
              closedOn={ true }
              mix={ { block: 'MyAccountPrescriptionDetails' } }
            >
                <p>{ __("The selected prescription line has a short shelf life! In order to guarantee the dispensing of your medicine, please contact your pharmacy directly or call 1400.") }</p>
            </Popup>
        );
    }

    renderImageElement() {
        const { prescription: { number, access_code, option_code } } = this.props;

        //const code = `${ number }|${ access_code }|${ option_code }`;
        //29/02/2022 pedido para option code ser "0000"
        const code = `${ number }|${ access_code }|0000`;

        return (
            <>
            <QRCode value={ code } size={ 120 } />
            <img
                    block="MyAccountPrescriptionDetails"
                    elem="QrCodeIcon"
                    src={ icon_green }
                    width="40px"
                    height="40px"
                />
            </>
        );
    }

    renderPrescriptionCard() {

        return (
            <MyAccountPrescriptionCard
              title={ __('Card Prescription') }
              mix={ { block: 'MyAccountPrescriptionDetails', elem: 'Prescription' } }
            />
        );
    }

    renderItemsExpired() {
        const { prescriptonDetails } = this.props;

        if (prescriptonDetails && (prescriptonDetails.prescriptionLines) && (prescriptonDetails.prescriptionLines.length > 0)) {
            let prepareData = this.prepareExpired();
            if(prepareData && prepareData.length > 0) {
                return prepareData.map((item, i) => {
                    const { device: { isMobile } } = this.props;
                    let fDeconstruct = item.prescriptionLineForfeit.split('T');
                    let fParts = fDeconstruct[0].split('-');
                    let fDate = new Date(fParts[0], parseInt(fParts[1]) - 1, parseInt(fParts[2]));
                    const fDateStr = this.addZero(fDate.getDate()) + '/' + this.addZero(fDate.getMonth() + 1) + '/' + fDate.getFullYear();

                    return (
                        <div key={ item.prescriptionLineCode || i.toString() } block="MyAccountPrescriptionDetails" elem="Product-RowExpired">
                            <div block="MyAccountPrescriptionDetails" elem="Product-RowExpired-Image">
                                <div block="MyAccountPrescriptionDetails" elem="QtdExpired">{ !isMobile &&  __("Quantity Available") }<strong>{item.prescriptionLineQuantity}</strong></div>
                            </div>
                            <div block="MyAccountPrescriptionDetails" elem="Product-RowExpired-Info">
                                <div>
                                    <label><strong>{ item.prescriptionLineDescription }</strong></label>
                                    { !isMobile && <div block="MyAccountPrescriptionDetails" elem="Details">
                                                    <span block="MyAccountPrescriptionDetails" elem="Capacity">{ item.prescriptionLineDosage }</span>
                                                </div>
                                    }
                                </div>
                                { isMobile && <div block="MyAccountPrescriptionDetails" elem="Details">
                                                    <span block="MyAccountPrescriptionDetails" elem="Capacity">{ item.prescriptionLineDosage }</span>
                                                </div>
                                }
                                <div block="MyAccountPrescriptionDetails" elem="ValidityExpired">
                                    <p><strong>{ __("Validity") } </strong>{ fDateStr }</p>
                                </div>
                            </div>
                        </div>
                    );
                });
            }
        }

        return (
            <div block="MyAccountPrescriptionDetails">
                <span>{ __("As per the latest update, there are no expired/waived prescription lines") }</span>
            </div>
        );
    }

    prepareExpired(){
        const { prescriptonDetails } = this.props;

        return prescriptonDetails.prescriptionLines.filter(item => {
            return (item.prescriptionLineStatus == 'Dispensed/Expired');
        });
    }

    prepareAvailable(){
        const { prescriptonDetails } = this.props;

        return prescriptonDetails.prescriptionLines.filter(item => {
            return (item.prescriptionLineStatus != 'Dispensed/Expired');
        });
    }

    renderItems() {
        const { prescriptonDetails, addToCart, showExpire2DaysPopup, device: { isMobile } } = this.props;

        if (prescriptonDetails && prescriptonDetails.prescriptionLines && prescriptonDetails.prescriptionLines.length > 0) {
            let prepareData = this.prepareAvailable();
            if(prepareData && prepareData.length > 0) {
                return prepareData.map((item, i) =>
                    <MyAccountPrescriptionDetailsLine
                        item={ item }
                        i={ i }
                        prescriptonDetails={ prescriptonDetails }
                        isMobile={ isMobile }
                        addToCart={ addToCart }
                        showExpire2DaysPopup={ showExpire2DaysPopup }
                    />
                );
            }
        }

        return (
            <div block="MyAccountPrescriptionDetails">
                <span>{ __("As of the latest update, there are no prescription lines available.") }</span>
            </div>
        );
    }

    renderTabContent() {
        const { isTabName } = this.state;
        const { device: { isMobile } } = this.props;

        return (
            <div block="MyAccountPrescriptionDetails" elem="ProductsWrapper">
                { !isMobile &&<div block="MyAccountPrescriptionDetails" elem="Title">
                    <h4>{ isTabName.title }</h4>
                </div>}
               {/*  <Loader isLoading={ isLoading } /> */}
                { isTabName.name =='expired' && this.renderExpired() }
                { isTabName.name =='details' && this.renderDetails() }
                { isTabName.name =='available' && this.renderMedications() }
            </div>
        );
    }

    renderMedications() {
        return(
            <div block="MyAccountPrescriptionDetails" elem="Products">
                { this.renderItems() }
            </div>
        );
    }

    renderDetails() {
        const { device: { isMobile }, prescriptonDetails } = this.props;
        let fDate = null;
        let fDateStr = null;
        let fDeconstruct = null;

        if (prescriptonDetails && prescriptonDetails.prescriptionDate) {
            fDeconstruct = prescriptonDetails.prescriptionDate.split('T');
            let fParts = fDeconstruct[0].split('-');
            fDate = new Date(fParts[0], parseInt(fParts[1]) - 1, parseInt(fParts[2]));
            fDateStr = this.addZero(fDate.getDate()) + '/' + this.addZero(fDate.getMonth() + 1) + '/' + fDate.getFullYear();
        }

        return(
            <div block="MyAccountPrescriptionDetails" elem="DetailsDescription">
                <div><p>
                        <span>{ __("Emission") }{ isMobile&& ":"} </span>
                        <strong>{ fDateStr ? fDateStr : __("No Date") }</strong>
                </p></div>
                <div><p>
                    <span>{ __("Prescription Site") }{ isMobile&& ":"}</span>
                    <strong>{ prescriptonDetails && prescriptonDetails.prescriptionLocal ? prescriptonDetails.prescriptionLocal : __("No Site") }</strong>
                </p></div>
                <div><p>
                    <span>{ __("Prescriber") }{ isMobile&& ":"}</span>
                    <strong>{ prescriptonDetails && prescriptonDetails.prescriptionPrescriptor ? prescriptonDetails.prescriptionPrescriptor : __("No Prescriber") }</strong>
                </p></div>
                <div><p>
                    <span>{ __("Especiality") }{ isMobile&& ":"}</span>
                    <strong>{ prescriptonDetails && prescriptonDetails.medicalSpeciality ? prescriptonDetails.medicalSpeciality : __("No Especiality")}</strong>
                </p></div>
            </div>
        );
    }

    renderExpired() {
        return(
            <div block="MyAccountPrescriptionDetails" elem="Products">
                { this.renderItemsExpired() }
            </div>
        );
    }


    renderContent() {

        return (
            <>
                { this.renderBaseInfo() }
                { this.renderPrescription() }
                { this.renderTabs() }
                { this.renderTabContent() }
            </>
        );
    }

    render() {
        const { isLoading } = this.props;
        //TODO: <Loader isLoading={ isLoading } />
        return (
            <div block="MyAccountPrescriptionDetails">
                { this.renderContent() }
                { this.renderPopup() }
                { this.renderPopupExpire2Days() }
            </div>
        );
    }
}

export default MyAccountPrescriptionDetails;
