import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';

import {
    MenuContainer as SourceMenuContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/Menu/Menu.container';

import browserHistory from 'Util/History';
import Menu from './Menu.component';
import {StoreFinderDispatcher} from "Store/StoreFinder";

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    favStoreObj: state.StorePageReducer.favStoreObj,
    services: state.StoreFinderReducer.serviceList
});

export const mapDispatchToProps = dispatch => ({
    getAvailableServices: () => StoreFinderDispatcher.requestServicesData(dispatch),

    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MenuContainer extends SourceMenuContainer {

    static propTypes = {
        goToPreviousHeaderState: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        onClickOut: PropTypes.func,
        isLogin: PropTypes.bool,
        getAvailableServices: PropTypes.func.isRequired

    };

    state = {
        activeMenuItemsStack: [],
        activeSubMenuItemsStack: [],
        menu: {}
    };

    containerFunctions = {
        handleSubcategoryClick: this.handleSubcategoryClick.bind(this),
        closeMenu: this.closeMenu.bind(this),
        closeMenuHover: this.closeMenuHover.bind(this),
        onCategoryHover: this.onCategoryHover.bind(this),
        onClickMenuToggle: this.onClickMenuToggle.bind(this),
        onClickSubCategoryToggle: this.onClickSubCategoryToggle.bind(this),
        goBackMenuMobile: this.goBackMenuMobile.bind(this),
        closeMenuMobile: this.closeMenuMobile.bind(this),
        goBackSubMenuMobile: this.goBackSubMenuMobile.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.lastSeenMenu = 0;

        this.state = {
            menuVisible: false
        };
    }

    closeMenu() {
        const { device, onClickToggle} = this.props;
        if (device.isMobile) {
            return;
        }

        this.setState({ activeMenuItemsStack: [] });
        onClickToggle();
    }

    closeMenuHover() {
        const { device } = this.props;
        if (device.isMobile) {
            return;
        }

        this.setState({ activeMenuItemsStack: [] });
    }

    closeMenuMobile() {
        browserHistory.go(-1);

        this.lastSeenMenu = 0;
    }

    goBackSubMenuMobile() {
        this.setState({ activeMenuItemsStack: [] });
    }

    goBackMenuMobile() {
        this.setState({ menuVisible: !this.state.menuVisible });
    }

    onClickMenuToggle() {
        this.setState({ menuVisible: !this.state.menuVisible });
    }

    onClickSubCategoryToggle(activeSubcategory) {
        const { activeSubMenuItemsStack } = this.state;
        const { item_id } = activeSubcategory;

        if (!activeSubMenuItemsStack.includes(parseInt(item_id))) {
            this.setState({ activeSubMenuItemsStack: [parseInt(item_id)] });
        } else {
            this.setState({ activeSubMenuItemsStack: [] });
        }
    }

    render() {
        return (
            <Menu
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
