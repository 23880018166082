import {
    PopupSuspense as SourcePopupSuspense,
} from 'SourceComponent/PopupSuspense/PopupSuspense.component';

import { CART_OVERLAY, WISHLIST_OVERLAY } from 'Component/Header/Header.config';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';

export class PopupSuspense extends SourcePopupSuspense {

    styleMap = {
        [CART_OVERLAY]: 'CartOverlay',
        [CUSTOMER_ACCOUNT_OVERLAY_KEY]: 'MyAccountOverlay',
        [WISHLIST_OVERLAY]: 'WishlistOverlay'
    };

};

export default PopupSuspense;
