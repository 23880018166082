import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
    CategoryPriceRangeContainer as SourceCategoryPriceRangeContainer,
    mapStateToProps,
    mapDispatchToProps,
} from 'SourceComponent/CategoryPriceRange/CategoryPriceRange.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

export class CategoryPriceRangeContainer extends SourceCategoryPriceRangeContainer {
    // TODO implement logic
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CategoryPriceRangeContainer)
);
