import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './CheckoutSauda.style';
import ContentWrapper from 'Component/ContentWrapper';
import CartCoupon from 'Component/CartCoupon';
import Link from 'Component/Link';
import Image from 'Component/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag, faTag, faTags, faUserTag, faCheckCircle, faCreditCard, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import CartFloating from 'Component/CartFloating';

import icon_select from 'Route/CheckoutSauda/Images/selecao@2x.png';
import icon_local_offer from 'Route/CheckoutSauda/Images/local_offer-24px@2x.png';
import icon_local_offer_white from 'Route/CheckoutSauda/Images/local_offer-white24px@2x.png';
import arrowDown from 'Route/StorePage/Images/arrowdown.png';
import arrowUp from 'Route/StorePage/Images/arrowUp.png';
import { faTireRugged } from '@fortawesome/pro-duotone-svg-icons';
import Popup from 'Component/Popup';

import HealthCardExclusiveVouchersSlider from 'Component/HealthCardExclusiveVouchersSlider';
import HealthCardPointsExclusiveSlider from 'Component/HealthCardPointsExclusiveSlider';
import HealthCardVouchersList from 'Component/HealthCardVouchersList';

import { customerType } from 'Type/Account';
import Loader from 'Component/Loader';



class CheckoutSauda extends PureComponent {
    static propTypes = {
        requestSaudaDetails: PropTypes.func.isRequired,
        requestSaudaPoints: PropTypes.func.isRequired,
        requestSaudaMatrix: PropTypes.func.isRequired,
        saudaCardPoints: PropTypes.arrayOf(PropTypes.string).isRequired,
        matrix: PropTypes.arrayOf(PropTypes.string).isRequired,
        saudaCardMatrix: PropTypes.arrayOf(PropTypes.string).isRequired,
        requestUpdateSaudaDiscount: PropTypes.func.isRequired,
        checkSaudaDiscountStored: PropTypes.func.isRequired,
        changeAdvanceButtonState: PropTypes.func.isRequired,
        getAdvanceButtonState: PropTypes.func.isRequired,
        renderDisableEnableAdvanceButton: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        applyVoucher: PropTypes.func.isRequired
    };

    state = {
        isPoints: false,
        showValueVouchers: false,
        activeVoucher: 'no item',
        arrowUp: false,
        discountStored: false,
        clickToSave: false
    };

    componentWillMount() {
        const { requestSaudaPoints, requestSaudaMatrix } = this.props;

        //requestSaudaDetails();
        requestSaudaPoints();
        requestSaudaMatrix();

        this.cleanSaudaDiscount(0);
    }

    componentDidUpdate(prevProps) {
        const { requestSaudaDetails, requestSaudaPoints, requestSaudaMatrix } = this.props;

        if (location.pathname !== prevProps.location.pathname) {
            //requestSaudaDetails();
            requestSaudaPoints();
            requestSaudaMatrix();
        }
    }

    renderHeading() {
        return (
            <h1 block="CheckoutSauda" elem="Heading">
                { __('Points and Discounts') }
            </h1>
        );
    }

    renderDiscountCode() {
        return (
            <>
                <h4 block="CheckoutSauda" elem="SubHeading">{ __('Promotional code') }</h4>
                <div block="CheckoutSauda" elem="Discount">
                    <CartCoupon  />
                </div>
            </>
        );
    }

    renderCardSauda() {
        const { saudaCardMatrix, getAvailablePoints, customer: { favourite_pharmacy_code, sauda_card }, customer } = this.props;
        const { activeVoucher } =  this.state;

        let points = getAvailablePoints();
        if (saudaCardMatrix[activeVoucher]) {
            points -= parseInt(saudaCardMatrix[activeVoucher].points);
            points = points < 0?  0 : points;
        }

        const { isPoints, showValueVouchers } = this.state;

        let cardSaudaFlag = false;

        if( customer && sauda_card &&
            !sauda_card.is_active &&
            (sauda_card.status == 'PreCanceledAuto' ||
            sauda_card.status == 'NotDefined' ||
            sauda_card.status == 'PreCanceledBackOffice' ||
            sauda_card.status == 'Blocked')
        ){
            cardSaudaFlag = true;
        }

        return (
            <>
                <h4 block="CheckoutSauda" elem="Head">{ __('Your Health Card has ')+points+ __(' Points') }</h4>
                <div block="CheckoutSauda" elem="CardSauda">
                    <button onClick={ this.activeSave}>
                        <div block="CheckoutSauda" elem="SavePoints" mods={{pointChange:isPoints}} onClick={ () => this.cleanSaudaDiscount(0)}>
                            <div block="CheckoutSauda" elem="IconCheck">
                                <Image
                                    src={ icon_select }
                                    width="20px"
                                    height="20px"
                                />
                            </div>
                            <div block="CheckoutSauda" elem="Icon">
                                <FontAwesomeIcon className="Header-IconLeft" icon={faCreditCard} />
                            </div>
                            <h4 block="CheckoutSauda" elem="SubSavePoints">{ __('Save for another purchase') }</h4>
                        </div>
                    </button>
                    <button onClick={ this.activeVouchers } disabled={ cardSaudaFlag }>
                        <div block="CheckoutSauda" elem="Vouchers" mods={{pointChange:isPoints}}>
                            <div block="CheckoutSauda" elem="IconCheck">
                                <Image
                                    src={ icon_select }
                                    width="20px"
                                    height="20px"
                                />
                            </div>
                            <div block="CheckoutSauda" elem="Icon">
                                <FontAwesomeIcon className="Header-IconLeft" icon={faTag} />
                            </div>
                            <h4 block="CheckoutSauda" elem="SubSavePoints">{ __('Exchange for discount vouchers') }</h4>
                        </div>
                    </button>
                </div>
                { showValueVouchers && this.activeValueVouchers() }
            </>
        );
    }

    activeSave = () => {
        this.setState({
            isPoints: 'save',
            showValueVouchers:false,
            clickToSave: true
        })
    }

    activeVouchers = () => {
        const { changeAdvanceButtonState, renderDisableEnableAdvanceButton } = this.props;
        this.setState({
            isPoints: 'vouchers',
            showValueVouchers:true,
            clickToSave: false
        })
        changeAdvanceButtonState(true);
        renderDisableEnableAdvanceButton();
    }

    activeValueVouchers(){

        return (
            <div block="CheckoutSauda" elem="ValueVouchers">
                <h4 block="CheckoutSauda" elem="SubValueVouchers">{ __('Exchange for discount vouchers') }</h4>
                <div block="CheckoutSauda" elem="ValueButton">
                    { this.renderMatrixButtons() }
                </div>
            </div>
        );
    }

    renderMatrixButtons() {
        const { saudaCardMatrix } = this.props;
        if (saudaCardMatrix.length > 0) {
            return saudaCardMatrix.map((item, i) => this.renderMatrixButton(item, i));
        }
    }

    renderMatrixButton(item, i) {
        const {activeVoucher} = this.state;
        const { totals: { grand_total, sauda_discount } } = this.props;

        this.checkIfHasStoredSaudaDiscount(item, i);

        if (item.type == 'Voucher') {
            return (
                    <button  block="CheckoutSauda" elem="Button"
                        mods={{ isEnable:!(this.checkIsDisabled(i, item)) && (grand_total > 0 || !!sauda_discount), activeVoucher: activeVoucher==i }}
                        disabled={ this.checkIsDisabled(i, item) || (grand_total === 0 && !sauda_discount) }
                        onClick={ () => this.voucherActive(i, item.value, item.reference, item.points)}
                        title={ this.checkIsDisabled(i, item) ? __('Ainda não tem pontos suficientes para troca') : '' }>
                        
                        <div block="CheckoutSauda" elem="ValueButton-Number">
                            <span>{ item.value }€</span>
                        </div>
                        <span  block="CheckoutSauda" elem="ValueButton-Points">{ item.points } {__(' points')}</span>
                    </button>
                );
        }
    }

    preCheckIfHasStoredValue() {
        const { saudaCardMatrix, checkSaudaDiscountStored, changeAdvanceButtonState } = this.props;
        const { clickToSave } = this.state;

        if (clickToSave) {
            this.setState({
                showValueVouchers: false,
                isPoints: 'save',
            });
        } else {
            if (saudaCardMatrix && saudaCardMatrix.length > 0) {
                saudaCardMatrix.map((item, i) => {
                    if (checkSaudaDiscountStored(item.reference)) {
                        this.setState({
                            showValueVouchers: true,
                            isPoints: 'vouchers',
                        });
                        changeAdvanceButtonState(false);
                    }
                });
            }
        }

    }

    voucherActive = (i, value, ref, points) => {
        const { changeAdvanceButtonState } = this.props;
        this.updateDiscount(value, ref, points);
        this.setState({
            activeVoucher:i
        });
        changeAdvanceButtonState(false);
    }

    updateDiscount(value, ref, points) {
        const { requestUpdateSaudaDiscount } = this.props;
        requestUpdateSaudaDiscount(value, ref, points);
    }

    cleanSaudaDiscount(value) {
        const { requestUpdateSaudaDiscount, changeAdvanceButtonState } = this.props;
        this.setState({
            activeVoucher: 'no item'
        });
        changeAdvanceButtonState(false);
        requestUpdateSaudaDiscount(value, "null", "null");
        this.activeSave();
    }

    checkIsDisabled(i, item){
        const { saudaCardPoints, getAvailablePoints, customer, customer: { favourite_pharmacy_code, sauda_card } } = this.props;
        const points = getAvailablePoints();

        if( customer && sauda_card &&
            !sauda_card.is_active &&
            (sauda_card.status == 'PreCanceledAuto' ||
            sauda_card.status == 'NotDefined' ||
            sauda_card.status == 'PreCanceledBackOffice' ||
            sauda_card.status == 'Blocked')
        ){
            return false;
        }

        if (points >= item.points) {
            return false;
        } else {
            return true;
        }
    }

    checkIfHasStoredSaudaDiscount(item, i) {
        const { checkSaudaDiscountStored } = this.props;

        if (checkSaudaDiscountStored(item.reference)) {
            this.setState({
                activeVoucher:i
            });
        }
    }

    checkIfExclusiveCanRender(voucher, customerPharmacy) {
        if (customerPharmacy) {
            if (voucher.burnConditions.exclusivePharmacies && (voucher.burnConditions.exclusivePharmacies.length > 0)) {
                let pharmaciesArray = voucher.burnConditions.exclusivePharmacies;
                for (let i=0;i<=pharmaciesArray.length;i++) {
                    if (pharmaciesArray[0] && pharmaciesArray[0].code == customerPharmacy) {
                        return true;
                    }
                }
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    renderSaudaVouchers() {
        const {
            saudaCardPoints,
            customer,
            totals: { grand_total },
            customer: { sauda_card },
            showInfoPopupCardInfo
        } = this.props;

        let cards = [];

        let countVouchers = 0;

        if( customer && sauda_card &&
            !sauda_card.is_active &&
            (sauda_card.status == 'PreCanceledAuto' ||
            sauda_card.status == 'NotDefined' ||
            sauda_card.status == 'PreCanceledBackOffice' ||
            sauda_card.status == 'Blocked')
        ){
            return (<></>);
        }
        if (saudaCardPoints.vouchers) {
            countVouchers = saudaCardPoints.vouchers.length;

            if (countVouchers > 0) {
                saudaCardPoints.vouchers
                    .sort((a,b) => {
                        return new Date(a.endDate) - new Date(b.endDate);
                    })
                    .forEach((voucher) => {
                        if (voucher.status == 'Available' && !voucher.in_order && voucher.is_online ) {
                            if (this.checkIfExclusiveCanRender(voucher, customer.favourite_pharmacy_code)) {
                                cards.push(
                                    <div block="CheckoutSaudaCard" mods={ { Active: voucher.in_quote } }mix={ { block:'card' } }>
                                        <div class="title">
                                            <h3 block="CheckoutSauda" elem="Title" class="title-item">{ voucher.detailedDescription.title }</h3>
                                        </div>
                                        <div class="description">
                                            <p class="description-item">{ voucher.detailedDescription.description }</p>
                                            <p class="see-more" onClick={ () => showInfoPopupCardInfo('HealthCardInfo' + voucher.code.trim()) }>{ __('See more') } <FontAwesomeIcon icon={ faArrowRight } /></p>

                                            <Popup
                                                id={ 'HealthCardInfo' + voucher.code.trim() }
                                                clickOutside={ true }
                                                mix={ { block: 'CheckoutSauda', elem: 'PopupInfo' } }
                                                closedOn={ false }
                                            >
                                                <FontAwesomeIcon className="CheckoutSauda-Close" onClick={ () => showInfoPopupCardInfo('HealthCardInfo' + voucher.code.trim()) } icon={ faTimes } />
                                                <div block="CheckoutSauda" elem="HealthCardInfoPopup-Content">
                                                    <div class="title">
                                                        <h3 block="CheckoutSauda" elem="Title" class="title-item">{ voucher.detailedDescription.title }</h3>
                                                    </div>
                                                    <div class="description">
                                                        <p><strong>{ __('Conditions') }</strong></p>
                                                        <p class="description-item">{ voucher.detailedDescription.description }</p>
                                                        <div class="code">
                                                            <div class="code-block">
                                                                <p class="code-content">
                                                                    <span class="code-item-title">{ __('Code') }</span> <br></br>
                                                                    <span class="code-item-code" id={voucher.code.trim()}>{ voucher.code }</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="actions">
                                                        <button
                                                            block="CheckoutSauda"
                                                            elem="HealthCardVouchers"
                                                            mix={ { block: 'Button' } }
                                                            onClick={ () => this.copyToClipboard(voucher.code.trim()) }
                                                        >
                                                            <p
                                                                block="CheckoutSauda"
                                                                elem="HealthCardVouchers">
                                                                    { __('Copy Code') }
                                                            </p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Popup>
                                        </div>

                                        <div class="actions-continer">
                                            <div class="code">
                                                <div class="code-block">
                                                    <p class="code-content">
                                                        <span class="code-item-title">{ __('Code') }</span> <br></br>
                                                        <span class="code-item-code" id={voucher.code.trim()}>{ voucher.code }</span>
                                                    </p>

                                                </div>
                                            </div>
                                            { !voucher.is_locked && <div class="actions">
                                                <button
                                                    block="HealthCardPoints"
                                                    elem="HealthCardVouchers"
                                                    mix={ { block: 'Button' } }
                                                    onClick={ () => this.applyExclusiveVoucher(voucher) }
                                                    disabled={ !voucher.in_quote && grand_total === 0 }
                                                >
                                                    <p
                                                        block="HealthCardPoints"
                                                        elem="HealthCardVouchers">
                                                            { voucher.in_quote ? __('Do not use') : __('Use') }
                                                    </p>
                                                </button>
                                            </div> }
                                        </div>
                                    </div>
                                );
                            }
                        }
                    });
            }
        }
        let voucherMessage = <p>{ __('No vouchers available') }</p>;
        if (cards.length > 0) {
            voucherMessage = <p>{ __('These vouchers were exclusively assigned to you, enjoy!') }</p>;
        }

        return (
            <div block="CheckoutSauda" elem="ExclusiveVouchers">
                <div class="heading-exclusive">
                    <h3 block="CheckoutSauda" elem="Title">{ __('My Exclusive Sauda Vouchers') }</h3>
                    { voucherMessage }
                </div>
                {cards.length > 0 && <HealthCardVouchersList cards={ cards } key={ Date.now() }/>}
                </div>
        );

    }

    renderSaudaExclusiveVouchers() {
        const {
            saudaCardPoints,
            customer
        } = this.props;

        let cards = [];

        let countVouchers = 0;

        if (saudaCardPoints.vouchers) {
            countVouchers = saudaCardPoints.vouchers.length;

            if (countVouchers > 0) {
                saudaCardPoints.vouchers.forEach((voucher) => {
                    if (voucher.paymentType != 'Points' && voucher.type == 'Voucher' && voucher.status == 'Available') {
                        if (this.checkIfExclusiveCanRender(voucher, customer.favourite_pharmacy_code)) {
                            cards.push(
                                <div class="card">
                                    <div class="title">
                                        <h3 block="CheckoutSauda" elem="Title" class="title-item">{ voucher.detailedDescription.title }</h3>
                                    </div>
                                    <div class="description">
                                        <p class="description-item">{ voucher.detailedDescription.description }</p>
                                    </div>
                                    <div class="code">
                                        <div class="code-block">
                                            <p class="code-content">
                                                <span class="code-item-title">{ __('Code') }</span> <br></br>
                                                <span class="code-item-code" id={voucher.code.trim()}>{ voucher.code }</span>
                                            </p>

                                        </div>
                                    </div>
                                    <div class="actions">
                                        { !voucher.is_locked && <button
                                            block="HealthCardPoints"
                                            elem="HealthCardVouchers"
                                            mix={ { block: 'Button' } }
                                            onClick={ () => this.applyExclusiveVoucher(voucher) }
                                        >
                                            <p
                                                block="HealthCardPoints"
                                                elem="HealthCardVouchers">
                                                    { voucher.in_quote ? __('Remove') : __('Use') }
                                            </p>
                                        </button> }
                                    </div>
                                </div>
                            );
                        }
                    }
                });
            }
        }
        return (
            <div block="CheckoutSauda" elem="ExclusiveVouchers">
                <div class="heading-exclusive">
                    <h3 block="CheckoutSauda" elem="Title">{ __('My Exclusive Sauda Vouchers') }</h3>
                    <p>{ __('These vouchers were exclusively assigned to you, enjoy!') }</p>
                    { cards.length > 0 ? '' : <p>{__('No vouchers available')}</p> }
                </div>
                { cards.length > 0 ? <HealthCardExclusiveVouchersSlider cards={cards} bigScreenNumber="3"/> : '' }
                </div>
        );

    }

    renderSaudaExclusivePoints() {
        const {
            customer,
            saudaCardPoints
        } = this.props;

        let cardsPoints = [];

        let countVouchers = 0;

        if (saudaCardPoints.vouchers) {
            countVouchers = saudaCardPoints.vouchers.length;

            if (countVouchers > 0) {
                saudaCardPoints.vouchers.forEach((voucher) => {
                    if (voucher.paymentType == 'Points' && voucher.type == 'Voucher' && voucher.status == 'Available') {

                        if (this.checkIfExclusiveCanRender(voucher, customer.favourite_pharmacy_code)) {

                            cardsPoints.push(
                                <div class="card">
                                    <div class="title">
                                        <h3 block="CheckoutSauda" elem="Title" class="title-item">{ voucher.detailedDescription.title }</h3>
                                    </div>
                                    <div class="description">
                                        <p><strong>{ __('Conditions') }</strong></p>
                                        <p class="description-item">{ voucher.detailedDescription.description }</p>
                                    </div>
                                    {!voucher.is_locked && <div class="actions">
                                        <button
                                            block="HealthCardPoints"
                                            elem="HealthCardVouchers"
                                            mix={ { block: 'Button' } }
                                            onClick={ () => this.applyExclusiveVoucher(voucher) }
                                        >
                                            <p
                                                block="HealthCardPoints"
                                                elem="HealthCardVouchers">
                                                    { voucher.in_quote ? __('Remove') : __('Use') }
                                            </p>
                                        </button>
                                    </div> }
                                </div>
                            );
                        }
                    }
                });
            }
        }

        return (
            <div block="CheckoutSauda" elem="ExclusiveVouchers">
                <div class="heading-exclusive">
                    <h3 block="CheckoutSauda" elem="Title">{ __('My Discount Vouchers') }</h3>
                    <p>{ __('Discount vouchers available to use on your next purchases.') }</p>
                    { cardsPoints.length > 0 ? '' : <p>{__('No vouchers available')}</p> }
                </div>
                { cardsPoints.length > 0 ? <HealthCardPointsExclusiveSlider cards={cardsPoints}  bigScreenNumber="3"/> : '' }
                </div>
        );

    }

    applyExclusiveVoucher = (voucher) => {
        const { applyVoucher } = this.props;
        applyVoucher(voucher);
    }

    render() {
        const { isMobile, isLoading } = this.props;
        let MobileCart, DesktopCart = null;

        if(isMobile) {
            MobileCart = <CartFloating
                            { ...this.props }
                        />
        } else {
            DesktopCart = <CartFloating
                                { ...this.props }
                            />
        }

        return (
            <main block="CheckoutSauda" aria-label="Checkout Sauda">
                <Loader isLoading={ isLoading }/>
                <ContentWrapper
                    wrapperMix={ { block: 'CheckoutSauda', elem: 'Wrapper' } }
                    label="Checkout Sauda details"
                >
                    { this.renderHeading() }
                    <div block="CheckoutSauda" elem="Content">
                        <div block="CheckoutSauda" elem="Static">
                            { this.preCheckIfHasStoredValue() }
                            { this.renderCardSauda() }
                            { this.renderSaudaVouchers() }

                            { /* this.renderSaudaExclusivePoints() */ }
                            { /* this.renderSaudaExclusiveVouchers() */ }
                            { this.renderDiscountCode() }
                        </div>
                        <div block="CheckoutSauda" elem="Floating">
                            { DesktopCart }
                        </div>
                    </div>
                    { MobileCart }
                </ContentWrapper>
            </main>
        );
    }
}

export default CheckoutSauda;
