/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { BlockListType } from 'Type/CMS';
import NoMatch from 'Route/NoMatch';

import './CmsPage.style';
import CmsPagesQuery from 'Query/CmsPages.query';
import { fetchQuery } from 'Util/Request';

/** @namespace Route/CmsPage/Component */
export class CmsPage extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isBreadcrumbsActive: PropTypes.bool,
        page: BlockListType.isRequired,
    };

    static propTypes = {
        isNotFound: PropTypes.bool,
        props: PropTypes.object,
        type: PropTypes.string,
    };
    static defaultProps = {
        isBreadcrumbsActive: true
    };

    state = {
        header_footer: 1
    };

    componentDidUpdate() {
        this.getHeaderFooter();
    }

    renderHeading() {
        const { page: { content_heading } } = this.props;

        if (!content_heading) {
            return null;
        }

        return (
            <h1 block="CmsPage" elem="Heading">
                <TextPlaceholder content={ content_heading } />
            </h1>
        );
    }

    renderContent() {
        const {
            isLoading,
            page: { content },
        } = this.props;

        const { header_footer } = this.state;

        if (isLoading) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!isLoading && !content) {
            return null;
        }

        return <Html 
            content={ content }
            header_footer={header_footer} 
        />;
    }

    getHeaderFooter(){
        const { pageIds } = this.props;
        
        if(pageIds >= 0){
            const query = CmsPagesQuery.getHeaderFooter(pageIds);

            fetchQuery(query).then(
                ({getHeaderFooter}) => {
                    this.setState({ header_footer: getHeaderFooter.header_footer})
                }
            );
        }
        
    }
    
    render() {
        const { page, isBreadcrumbsActive, noMatch, isLoading, page:{content}} = this.props;
        const { page_width } = page;
        const { header_footer } = this.state;

        if (!isLoading && !content) {
            return <NoMatch />;
        }

        
        return (
            <main
              block="CmsPage"
              mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
              mix={ { block: header_footer ? '':'FullPage' } }
            >
                <div block="CmsPage" elem="Wrapper" mods={ { page_width } }>
                    { this.renderHeading() }
                    <div block="CmsPage" elem="Content">
                        { this.renderContent() }
                    </div>
                </div>
            </main>
        );      
    }

}

export default CmsPage;
