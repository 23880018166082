import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchPosts from './SearchPosts.component';
import { fetchQuery } from 'Util/Request';
import BlogPostsQuery from 'Query/BlogPosts.query';
import { CategoriesDispatcher } from '../../store/Categories';


export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    requestCategories: (options) => CategoriesDispatcher.handleData(dispatch, options)
});

export class SearchPostsContainer extends PureComponent {
    static propTypes = {
        requestCategories: PropTypes.func.isRequired,
    };

    state = {
        searchCriteria: '',
        posts: [],
        showContent: false,
        prevSearch: '',
        page: 1,
        pageNumber: 1,
        pageSize: 9,
        totalPages: 1,
        totalPosts: 0,
        flagChange: false
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        let segment = decodeURIComponent(window.location.pathname.split("/").pop());

        this.setState({searchCriteria: segment, prevSearch: segment});
        this.searchPosts(segment);
        this._updateBreadcrumbs(segment);
        this.requestCategories();
    }

    requestCategories() {
        const { requestCategories } = this.props;
        requestCategories();
    }


    componentDidUpdate() {
        let segment = decodeURIComponent(window.location.pathname.split("/").pop());

        if (this.state.prevSearch != segment) {
            this.setState({searchCriteria: segment, prevSearch: segment});
            this.searchPosts(segment, true);
            this._updateBreadcrumbs(segment);
        }
    }

    _updateBreadcrumbs(segment) {
        const { updateBreadcrumbs } = this.props;
        const breadcrumbs = [
            { url: '', name: segment },
            { url: '/search', name: __('Search') },
            { url: '/blog', name: __('Blog') }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    containerFunctions = {
        getPosts: this.getPosts.bind(this),
        searchPosts: this.searchPosts.bind(this),
        onPageChange: this.onPageChange.bind(this),
        sortPosts: this.sortPosts.bind(this),
        requestCategories: this.requestCategories.bind(this)
    };

    containerProps = () => {
    };

    getPosts() {
        return this.state.posts;
    }

    sortPosts(sortBy) {
        const { posts, flagChange } = this.state;

        const newPosts = posts.sort((a,b) => {
            if (sortBy === 'publish_time') {
                return new Date(b.publish_time.replace(" ", "T") + "Z") - new Date(a.publish_time.replace(" ", "T") + "Z");
            } else if (sortBy === 'title') {
                return a.title.localeCompare(b.title);
            } else if (sortBy === 'views_count') {
                return parseInt(b[sortBy]) - parseInt(a[sortBy]);
            } else {
                return a[sortBy] > b[sortBy];
            }
        });

        this.setState({ posts:newPosts, flagChange:!flagChange, pageNumber:1 });
        window.scrollTo(0,0);
    }

    searchPosts(search, flagNew = false) {
        const { pageSize, pageNumber } = this.state;

        const options = {
            filter: {
                post_id: {},
                author_id: {},
                tag_id: {},
                category_id: {},
                title: {},
                content: {},
                publish_time: {},
                search: {
                    like: search
                },
                relatedproduct_id: {}
            },
            pageSize,
            currentPage: flagNew ? 1 : pageNumber
        }
        const query = BlogPostsQuery.getQuery(options);

        fetchQuery(query).then(
            /** @namespace Store/Cart/Dispatcher/_syncCartWithBEFetchQueryThen */
            (result) => {
                let dataResult = false,
                    posts = [],
                    totalPages = 0,
                    totalPosts = 0;

                if(result.posts.items.length > 0) {
                    dataResult = true;

                    posts = result.posts.items;
                    totalPages = result.posts.total_pages;
                    totalPosts = result.posts.total_count;
                }

                this.setState({ posts, showContent: dataResult, totalPages, totalPosts });

                window.scrollTo(0,0);

                return result.posts.items;
            },
            /** @namespace Store/Cart/Dispatcher/_syncCartWithBEFetchQueryError */
            (error) => {
                console.log('error search posts', error)
                return [];
            }
        );
    }

    onPageChange(page) {
        this.setState({ pageNumber: page.selected + 1 }, () => {
            const { searchCriteria } = this.state;
            this.searchPosts(searchCriteria);
        });
    }

    render() {
        return (
            <SearchPosts
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPostsContainer);
