import ProductListQuery from 'Query/ProductList.query';
import {
    LinkedProductsDispatcher as SourceLinkedProductsDispatcher
} from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';

export class LinkedProductsDispatcher extends SourceLinkedProductsDispatcher {
    /**
     * Prepare LinkedProducts query
     * @return {Query} ProductList query
     * @memberof LinkedProductsDispatcher
     * @param product_links
     */
    prepareRequest(product_links) {
        const relatedSKUs = product_links.reduce((links, link) => {
            const { linked_product_sku } = link;
            return [...links, `${ linked_product_sku.replace(/ /g, '%20') }`];
        }, []);
        
        let currentTimestamp = Math.floor(Date.now() / 1000);

        return [
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: relatedSKUs
                    }
                },
                notRequireInfo: true
            },currentTimestamp)
        ];
    }
}

export default new LinkedProductsDispatcher();
