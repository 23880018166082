import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    MyAccountOverlay as SourceMyAccountOverlay,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.component';
import Image from 'Component/Image';

import {
    STATE_CONFIRM_EMAIL,
    STATE_CREATE_ACCOUNT,
    STATE_FORGOT_PASSWORD,
    STATE_FORGOT_PASSWORD_SUCCESS,
    STATE_LOGGED_IN,
    STATE_SIGN_IN,
    STATE_FIRST_LOGIN
} from './MyAccountOverlay.config';

import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { MY_WISHLIST,DASHBOARD,MY_ORDERS,ADDRESS_BOOK,NEWSLETTER_SUBSCRIPTION,HEALTH_CARD,CONTACTS, NOTIFICATION, PHARMACY, PRESCRIPTION } from 'Type/Account';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Overlay from 'Component/Overlay';

import {
    CUSTOMER_ACCOUNT_OVERLAY_KEY
} from './MyAccountOverlay.config';

import icon_exit from 'Component/MyAccountOverlay/Images/icon-exit-to-app.svg';
import icon_prescription from 'Component/MyAccountOverlay/Images/icon-prescription.svg';
import icon_healthcard from 'Component/MyAccountOverlay/Images/icon-sauda-card.svg';
import icon_address from 'Component/MyAccountOverlay/Images/icon-address.svg';
import icon_notifications from 'Component/MyAccountOverlay/Images/icon-notifications.svg';
import icon_help from 'Component/MyAccountOverlay/Images/icon-help.svg';
import icon_pharmacy from 'Component/MyAccountOverlay/Images/icon-red-cross.svg';
import icon_ship from 'Component/MyAccountOverlay/Images/icon-ship.svg';
import icon_mydata from 'Component/MyAccountOverlay/Images/icon-mydata.svg';
import icon_userpharmacy from 'Component/MyAccountOverlay/Images/icon-userpharmacy.png';

import MyAccountSignIn from 'Component/MyAccountSignIn';
import { signInStateType } from 'Type/Account';
import { DeviceType } from 'Type/Device';


import './MyAccountOverlay.component.style.override'
import { MyAccountAutoLogout } from 'Component/MyAccountAutoLogout/MyAccountAutoLogout.container';

export class MyAccountOverlay extends SourceMyAccountOverlay {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        isOverlayVisible: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        state: signInStateType.isRequired,
        setSignInState: PropTypes.func.isRequired,
        setLoadingState: PropTypes.func.isRequired,
        onVisible: PropTypes.func.isRequired,
        onFormError: PropTypes.func.isRequired,
        handleForgotPassword: PropTypes.func.isRequired,
        handleSignIn: PropTypes.func.isRequired,
        handleCreateAccount: PropTypes.func.isRequired,
        isCheckout: PropTypes.bool,
        device: DeviceType.isRequired,
        onSignIn: PropTypes.func.isRequired,
        handleFirstLogin: PropTypes.func.isRequired,
    };
    // TODO implement logic
    renderMap = {
        [STATE_LOGGED_IN]: {
            render: () => this.renderLoggedIn(),
            title: "Logged In"
        },
        [STATE_SIGN_IN]: {
            render: () => this.renderSignIn(),
            title: __('Access your account Portuguese Pharmacy!')
        },
        [STATE_FORGOT_PASSWORD]: {
            render: () => this.renderForgotPassword(),
            title: <>{ __('Forgotten your password?') }<br/>{ __('There`s no problem!') }</>
        },
        [STATE_FORGOT_PASSWORD_SUCCESS]: {
            render: () => this.renderForgotPasswordSuccess(),
            title: __('An email was sent with further information to reset your password.')
        },
        [STATE_CREATE_ACCOUNT]: {
            render: () => this.renderCreateAccount(),
            title: __('Create your Portuguese Pharmacies account')
        },
        [STATE_CONFIRM_EMAIL]: {
            render: () => this.renderConfirmEmail(),
            title: __('Check your email!'),
            image: icon_userpharmacy
        },
        [STATE_FIRST_LOGIN]: {
            render: () => this.renderFirstLogin(),
            title: __('Check your email!')
        }
    }

    renderFirstLogin() {
        const { hideActiveOverlay, gotToHomePage, device } = this.props;
        
        return (
            <>
                <br></br>
                {/* <p class="MyAccountOverlay-Heading">{ __('An email has been sent with the information needed to retrieve your password.') }</p> */}
                <p class="MyAccountOverlay-Notes">{ __('Thank you for continue with us!') }</p>
                <p class="MyAccountOverlay-Notes">{ __('Validate your account and get access to new features we have for you. Follow the instructions sent to your email.') }</p>
                <button
                    type="button"
                    block="MyAccountOverlay"
                    onClick={ device.isMobile ? gotToHomePage : hideActiveOverlay }
                    elem="Button"
                    mix={ { block: 'Button' } }
                >
                    { __('Ok') }
                </button>
            </>
        );
    }

    renderSignIn() {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            onSignIn,
            handleFirstLogin
        } = this.props;

        return (
            <MyAccountSignIn
              state={ state }
              onFormError={ onFormError }
              handleForgotPassword={ handleForgotPassword }
              handleCreateAccount={ handleCreateAccount }
              isCheckout={ isCheckout }
              setLoadingState={ setLoadingState }
              onSignIn={ onSignIn }
              handleFirstLogin={ handleFirstLogin }
            />
        );
    }

    onPressedOption(params) {
        if(window.location.pathname.localeCompare(params) == 0){
            return true;
        }else {
            return false;
        }
    }

    renderMyAccount() {
        const {  isLoading, state } = this.props;
        const { render, title, image } = this.renderMap[state];

        return (
            <div block="MyAccountOverlay" elem="Action" mods={ { state } }>
                <Loader isLoading={ isLoading } />
                { image ? <Image
                    src={ image }
                    width="40px"
                    height="40px"
                    mix={ { block: 'MyAccountOverlay', elem: 'Icon', } }
                /> : null }
                <p block="MyAccountOverlay" elem="Heading">{ title }</p>
                { render() }
            </div>
        );
    }


    render() {
        const {
            onVisible,
            isCheckout,
            device
        } = this.props;

        return (
            <Overlay
              id={ CUSTOMER_ACCOUNT_OVERLAY_KEY }
              mix={ { block: 'MyAccountOverlay' } }
              onVisible={ onVisible }
              isStatic={ !isCheckout && device.isMobile }
            >
                { this.renderMyAccount() }
            </Overlay>
        );
    }

    renderPrescriptionLink() {
        const { onClickPresNoStore, hideActiveOverlay, customer: { favourite_pharmacy_code } } = this.props;

        if (favourite_pharmacy_code) {
            return (
                <div block="MyAccountOverlay" elem="link-to">
                    <Image
                        src={ icon_prescription }
                        width="20px"
                        height="20px"
                        mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                    />
                    <Link to={ `${ MY_ACCOUNT_URL }/${ PRESCRIPTION }` }
                        block="MyAccountOverlay"
                        elem="option"
                        mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ PRESCRIPTION }`) } }
                        onClick={ hideActiveOverlay }
                    >
                        {__('Prescription Wallet')}
                    </Link>
                </div>
            );
        }

        return (
            <div block="MyAccountOverlay" elem="link-to">
                <Image
                    src={ icon_prescription }
                    width="20px"
                    height="20px"
                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                />
                <Link to={ '/selecionador-de-farmacias' }
                    block="MyAccountOverlay"
                    elem="option"
                    mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ PRESCRIPTION }`) } }
                    onClick={ onClickPresNoStore }
                >
                    {__('Prescription Wallet')}
                </Link>
            </div>
        );
    }

    renderLoggedIn() {

        const {
            handleLogout,
            hideActiveOverlay
        } = this.props;

        return (
                <>
                    <div
                        block="MyAccountOverlay"
                        elem="menu-list"
                        >
                        <div
                            block="MyAccountOverlay"
                            elem="link-to">
                                <Image
                                    src={ icon_healthcard }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                                />
                                <Link to={ `${ MY_ACCOUNT_URL }/${ HEALTH_CARD }` }
                                    block="MyAccountOverlay"
                                    elem="option"
                                    mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ HEALTH_CARD }`) } }
                                    onClick={ hideActiveOverlay }
                                >
                                        {__('Health Card')}
                                </Link>
                        </div>
                        { this.renderPrescriptionLink() }
                        <div
                            block="MyAccountOverlay"
                            elem="link-to">
                                <Image
                                    src={ icon_ship }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                                />
                            <Link to={ `${ MY_ACCOUNT_URL }/${ MY_ORDERS }` }
                                block="MyAccountOverlay"
                                elem="option"
                                mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ MY_ORDERS }`) } }
                                onClick={ hideActiveOverlay }
                            >
                                    {__('My orders')}
                            </Link>
                        </div>
                        <div
                            block="MyAccountOverlay"
                            elem="link-to" >
                                <Image
                                    src={ icon_mydata }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                                />
                            <Link to={ `${ MY_ACCOUNT_URL }/${ DASHBOARD }`}
                                block="MyAccountOverlay"
                                elem="option"
                                mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ DASHBOARD }`) } }
                                onClick={ hideActiveOverlay }
                            >
                                    {__('Dashboard')}
                            </Link>
                        </div>
                        <div
                            block="MyAccountOverlay"
                            elem="link-to">
                                <Image
                                    src={ icon_address }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                                />
                            <Link to={ `${ MY_ACCOUNT_URL }/${ ADDRESS_BOOK }` }
                                block="MyAccountOverlay"
                                elem="option"
                                mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ ADDRESS_BOOK }`) } }
                                onClick={ hideActiveOverlay }
                            >
                                    {__('My Addresses')}
                            </Link>
                        </div>
                        <div
                            block="MyAccountOverlay"
                            elem="link-to">
                            <Image
                                    src={ icon_pharmacy }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                                />
                                <Link to={ `${ MY_ACCOUNT_URL }/${ PHARMACY }` }
                                    block="MyAccountOverlay"
                                    elem="option"
                                    mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ PHARMACY }`) } }
                                    onClick={ hideActiveOverlay }
                                >
                                    {__('My Pharmacy')}
                                </Link>
                        </div>
                        {/* <div
                            block="MyAccountOverlay"
                            elem="link-to">
                                <Image
                                    src={ icon_notifications }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                                />
                            <Link to={ `${ MY_ACCOUNT_URL }/${ NOTIFICATION }` }
                                block="MyAccountOverlay"
                                elem="option"
                                mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ NOTIFICATION }`) } }
                            >
                                    {__('Notifications')}
                            </Link>
                        </div> */}
                        <div
                            block="MyAccountOverlay"
                            elem="link-to">
                                <Image
                                    src={ icon_help }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                                />
                            <Link to={ `${ MY_ACCOUNT_URL }/${ CONTACTS }` }
                                block="MyAccountOverlay"
                                elem="option"
                                mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ CONTACTS }`) } }
                                onClick={ hideActiveOverlay }
                            >
                                    {__('HelpAndContacts')}
                            </Link>
                        </div>
                        <div
                            block="MyAccountOverlay"
                            elem="link-to-last">
                                <Image
                                    src={ icon_exit }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                                />
                                <button
                                    onClick={ handleLogout}
                                    block="MyAccountOverlay"
                                    elem="button-option"
                                    mods={ { isVisible:this.onPressedOption(`${ MY_ACCOUNT_URL }/${ MY_WISHLIST }`) } }
                                >
                                        {__('Logout')}
                                </button>
                        </div>
                    </div>
                </>
        );
    }
};

export default withRouter(
    MyAccountOverlay
);
