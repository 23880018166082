import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CheckoutConfirm from './CheckoutConfirm.component';

import { showNotification } from 'Store/Notification/Notification.action';
import { TotalsType } from 'Type/MiniCart';
import { showPopup } from 'Store/Popup/Popup.action';

import { fetchQuery } from 'Util/Request';
import gtag, { install } from 'ga-gtag';
import ConfigQuery from 'Query/Config.query';

export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals,
    storeObj: state.StorePageReducer.storeObj,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    customer: state.MyAccountReducer.customer,
    favStoreObj: state.StorePageReducer.favStoreObj,
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

export const mapDispatchToProps = (dispatch) => ({
    requestStore: (id) => {
        StorePageDispatcher.requestStore(dispatch, id);
    },
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showInfoPopup: (payload) => dispatch(showPopup('CheckoutTermsKnowMore', payload)),
});

export class CheckoutConfirmContainer extends PureComponent {
    static propTypes = {
        setLoading: PropTypes.func.isRequired,
        totals: TotalsType.isRequired,
        confirmPlaceOrder: PropTypes.func.isRequired,
        requestStore: PropTypes.func.isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        setNotReady: PropTypes.func.isRequired
    };

    containerFunctions = {
        onConfirmSuccess: this.onConfirmSuccess.bind(this),
        onConfirmError: this.onConfirmError.bind(this)
    };

    componentDidMount(){
        const { setNotReady } = this.props;
        setNotReady(true);

        fetchQuery(ConfigQuery.getAnalyticsConfig('page_view_confirm_counter')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );
    }

    onConfirmSuccess() {
        const { confirmPlaceOrder, setLoading } = this.props;
        setLoading();
        confirmPlaceOrder();
    }

    onConfirmError(fields, invalidFields, error) {
        const { showErrorNotification } = this.props;

        if (error) {
            const { message = __('Something went wrong!') } = error;
            showErrorNotification(message);
        }
    }

    render() {
        return (
            <CheckoutConfirm
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutConfirmContainer);
