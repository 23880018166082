import {
    NewProducts as SourceNewProducts,
} from 'SourceComponent/NewProducts/NewProducts.component';

import './NewProducts.style'

export class NewProducts extends SourceNewProducts {
    // TODO implement logic
};

export default NewProducts;
