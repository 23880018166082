/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */
import { QueryDispatcher, executePost } from 'Util/Request';

import { prepareQuery } from 'Util/Query';
import { updateStoreFinder, updateStoreServices, updateStoreSelector } from '.';
import StoreFinderQuery from '../../query/StoreFinder.query';

/**
 * Store Finder Dispatcher
 * @class StoreFinderDispatcher
 * @extends QueryDispatcher
 * @namespace Store/StoreFinder/Dispatcher
 */
export class StoreFinderDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ getPharmacies }, dispatch) {
        dispatch(updateStoreFinder(getPharmacies));
    }

    onError(error, dispatch) {
        console.log(error);
    }

    requestStoreData(dispatch) {
        const query = StoreFinderQuery.getQuery();

        return executePost(prepareQuery([query])).then(
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Dispatcher/executePostThen */
            ({ getPharmacies }) => {
                dispatch(updateStoreFinder(getPharmacies));
            },
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Dispatcher/executePostThen */
            error => console.log('error', error[0].message)
        );
    }

    requestStoreOnlineData(dispatch) {
        const query = StoreFinderQuery.getStoreOnline();

        return executePost(prepareQuery([query])).then(
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Dispatcher/executePostThen */
            ({ getPharmaciesOnline }) => {
                dispatch(updateStoreSelector(getPharmaciesOnline));
            },
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Dispatcher/executePostThen */
            error => console.log('error', error[0].message)
        );
    }

    requestServicesData(dispatch) {
        const query = StoreFinderQuery.getServicesQuery();

        return executePost(prepareQuery([query])).then(
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Dispatcher/executePostThen */
            ({ getServices }) => {
                dispatch(updateStoreServices(getServices));
            },
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Dispatcher/executePostThen */
            error => console.log('error', error[0].message)
        );
    }
}

export default new (StoreFinderDispatcher)();
