/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import PropTypes from 'prop-types';
 import React, { PureComponent } from 'react';

 import DropdownByBlock from './DropdownByBlock.component';

 /** @namespace ScandiPWA/Blog/Component/BlogRecentPosts/Container */
 export class DropdownByBlockContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.array.isRequired
    };

    state = {
        fetched: [],
        blockSize: 5,
        total: 0,
        showMore: false,
        totalFetched: 0,
        showAction: false
    };

    containerFunctions = {
        fetchMore: this.fetchMore.bind(this),
        showLess: this.showLess.bind(this)
    }

    componentDidMount() {
        const { children } = this.props;
        const { blockSize } = this.state;

        let showAction = true;
        let showMore = false;
        if (children.length > blockSize) {
            showMore = true;
        } else {
            showAction = false;
        }

        let totalFetched = blockSize;
        let fetched = children.slice(0, blockSize);

        this.setState({ showMore, fetched, totalFetched, showAction });
    }

    componentDidUpdate(prevProps) {
        const { children } = this.props;
        const { children: prevChildren } = prevProps;
        const { blockSize, fetched } = this.state;

        let chidlrenLength = children.length;
        if (prevChildren.length != children.length) {
            let showAction = true;
            let showMore = false;
            if (children.length > blockSize) {
                showMore = true;
            } else {
                showAction = false;
            }
            let totalFetched = blockSize;
            let fetched = children.slice(0, blockSize);

            this.setState({ showMore, fetched, totalFetched, showAction });
            return;
        }
        for (let i = 0; i < chidlrenLength; i++) {
            if (prevChildren[i] && children[i] && children[i].props.isSelected !== prevChildren[i].props.isSelected) {
                const { totalFetched } = this.state;
                let fetched = children.slice(0, totalFetched);
                this.setState({ fetched });
                return;
            }
        }

        const subset = fetched.every(val => children.includes(val));

        if(!subset){
            let fetched = children.slice(0, blockSize);
            this.setState({ fetched });
        }
    }

    fetchMore() {
        let { totalFetched, blockSize, showMore } = this.state;
        const { children } = this.props;

        totalFetched += blockSize;
        if (totalFetched >= children.length) {
            totalFetched = children.length;
            showMore = false;
        }

        let fetched = children.slice(0, totalFetched);
        this.setState({ totalFetched, fetched, showMore });
    }

    showLess() {
        let { blockSize } = this.state;
        const { children } = this.props;

        let showMore = false;
        if (children.length > blockSize) {
            showMore = true;
        }
        let totalFetched = blockSize;
        let fetched = children.slice(0, blockSize);

        this.setState({ showMore, fetched, totalFetched });
    }

    render() {
        return (
            <DropdownByBlock
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
 }

 export default DropdownByBlockContainer;
