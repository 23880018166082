/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { StoreFinderDispatcher } from '../../store/StoreFinder';
import StoreFinder from './StoreFinder.component';
import { showNotification } from 'Store/Notification/Notification.action';
import StorePageQuery from 'Query/StorePage.query';
import { fetchMutation } from 'SourceUtil/Request';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { StorePageDispatcher } from '../../store/StorePage';
import { showPopup } from 'Store/Popup/Popup.action';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateMeta } from 'Store/Meta/Meta.action';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace Scandiweb/StoreFinderGraphQl/Route/StoreFinder/Container/mapStateToProps */
export const mapStateToProps = state => ({
    storeListCities: state.StoreFinderReducer.storeListCities,
    storeListMapped: state.StoreFinderReducer.storeListMapped,
    storeByName: state.StoreFinderReducer.storeByName,
    serviceList: state.StoreFinderReducer.serviceList,
    storeFinderIsLoading: state.StoreFinderReducer.isLoading,
    device: state.ConfigReducer.device,
    customer: state.MyAccountReducer.customer,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    cartTotals: state.CartReducer.cartTotals,
    favStoreObj: state.StorePageReducer.favStoreObj
});

/** @namespace Scandiweb/StoreFinderGraphQl/Route/StoreFinder/Container/mapDispatchToProps */
export const mapDispatchToProps = dispatch => ({
    requestStores: () => {
        StoreFinderDispatcher.requestStoreData(dispatch);
    },
    requestServices: () => {
        StoreFinderDispatcher.requestServicesData(dispatch);
    },
    requestStore: (id) => {
        StorePageDispatcher.requestStore(dispatch, id);
    },
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
    showCartPopup: (payload) => dispatch(showPopup('CartItemsAlert', payload)),
    toggleBreadcrumbs: () => dispatch(toggleBreadcrumbs()),
    syncWishList: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
});

export class StoreFinderContainer extends PureComponent {

    static propTypes = {
        requestStores: PropTypes.func.isRequired,
        requestServices: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired
    };

    containerFunctions = {
        updateContextPharmacy: this.handleUpdateCustomerContextPharmacy.bind(this)
    }

    componentDidMount() {
        const { toggleBreadcrumbs, updateMeta } = this.props;
        toggleBreadcrumbs(false);
        updateMeta({ title: __('Farmácias perto de mim - Localizar') });
    }

    handleUpdateCustomerContextPharmacy(selectedCode, selectedPharmaUrl) {
        const { customer: { preference_pharmacy_code }, favStoreObj: { pharmacy_code: favCode }, updateCart, syncCart, isSignedIn, showErrorNotification, showSuccessNotification, requestCustomerData, requestStore, syncWishList } = this.props;

        if(favCode == selectedCode) {
            history.push(appendWithStoreCode(selectedPharmaUrl ? selectedPharmaUrl : '/'));
        } else {
            if(isSignedIn){
                if(!preference_pharmacy_code) {
                    const query = StorePageQuery.getUpdateCustomerPharmacyPreference(selectedCode);
                    fetchMutation(query).then(
                        () => {
                            this.setState({ isLoading: false }, () => {
                                //requestCustomerData();
                                showSuccessNotification(__('This pharmacy is now your favourite pharmacy.'));
                                /*updateCart().then(
                                    () => {
                                        syncCart();
                                        if (BrowserDatabase.getItem('redirect_to')) {
                                            const redirect = BrowserDatabase.getItem('redirect_to');
                                            BrowserDatabase.deleteItem('redirect_to');
                                            history.push(appendWithStoreCode(redirect));
                                        }
                                    }
                                );*/
                            });
                        },
                        (error) => {
                            this.handleError([{message:__('Something went wrong.')}])
                        }
                    );
                }

                const query = StorePageQuery.getUpdateCustomerContextPharmacy(selectedCode);
                fetchMutation(query).then(
                    () => {
                        this.setState({ isLoading: false }, () => {
                            requestCustomerData().then(
                                () => {
                                    updateCart().then(
                                        () => {
                                            syncCart();
                                            if (BrowserDatabase.getItem('redirect_to')) {
                                                const redirect = BrowserDatabase.getItem('redirect_to');
                                                BrowserDatabase.deleteItem('redirect_to');
                                                history.push(appendWithStoreCode(redirect));
                                            } else {
                                                history.push(appendWithStoreCode(selectedPharmaUrl ? selectedPharmaUrl : '/'));
                                            }
                                        }
                                    );
                                    syncWishList();
                                }
                            );
                            //showSuccessNotification(__('This pharmacy is now your favourite pharmacy.'));
                        });
                    },
                    (error) => {
                        this.handleError([{message:__('Something went wrong.')}])
                    }
                );
            } else {
                const query = StorePageQuery.getUpdateGuestContextPharmacy(selectedCode);
                fetchMutation(query).then(
                    ({updateGuestContextPharmacy}) => {

                        if(updateGuestContextPharmacy != 'false'){
                            this.setState({ isLoading: false }, () => {
                                localStorage.setItem('guest_pharmacy_code', selectedCode);

                                let obj = {
                                    time:new Date().getTime(),
                                    value:3600000,
                                    expire:3600000,
                                }
                                // You can only store strings
                                let objStr = JSON.stringify(obj);
                                localStorage.setItem('guest_pharmacy_code_expire_time',objStr);

                                requestStore(selectedCode);
                                //showSuccessNotification(__('This pharmacy is now your favourite pharmacy.'));
                                updateCart().then(
                                    () => {
                                        syncCart();
                                        if (BrowserDatabase.getItem('redirect_to')) {
                                            const redirect = BrowserDatabase.getItem('redirect_to');
                                            BrowserDatabase.deleteItem('redirect_to');
                                            history.push(appendWithStoreCode(redirect));
                                        } else {
                                            history.push(appendWithStoreCode(selectedPharmaUrl ? selectedPharmaUrl : '/'));
                                        }
                                    }
                                );
                                syncWishList();
                                //window.location.reload();
                            });
                        }
                    },
                    (error) => {
                        this.handleError([{message:__('Something went wrong.')}]);
                        showErrorNotification([{message:__('You need to be logged in to make this pharmacy as favourite.')}]);
                    }
                );
            }

        }
    }

    render() {
        return (
            <StoreFinder
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreFinderContainer);
