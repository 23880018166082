/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm';
import Form from 'Component/Form';
import { customerType } from 'Type/Account';
import './MyAccountNotification.style.override'
import FieldSelect from 'SourceComponent/FieldSelect'

/** @namespace Component/MyAccountNotification/Component */
export class MyAccountNotification extends FieldForm {
    static propTypes = {
        customer: customerType.isRequired,
        onCustomerSave: PropTypes.func.isRequired
    };

    onFormSuccess = (fields) => {
        const { onCustomerSave } = this.props;
        onCustomerSave(fields);
    };

    getSelectOptions(items){
        const copy = [];
        items.forEach(function(item){
            if(item != undefined){
                copy.push({'label' : item.name, 'value' : item.value, 'id' : item.id});
            }
        });

        return copy;
    }

    get fieldMap() {
        const { customer, customer: { is_subscribed } } = this.props;

        return {
            is_subscribed: {
                type: 'checkbox',
                label: __('Mail notifications'),
                value: 'is_subscribed',
                checked: is_subscribed
            },
            allow_sms: {
                type: 'checkbox',
                label: __('SMS/telephone notifications'),
                checked: customer.allow_sms
            },
            allow_sauda: {
                type: 'checkbox',
                label: __('Sauda notifications'),
                checked: customer.allow_sauda
            }
        };
    }

    get fieldMap2() {
        const { customer } = this.props;
        const contact_array = [
            {id:0,name:customer.email,value:1},
            {id:1,name:customer.phone,value:2}
        ];
        const contact_list = this.getSelectOptions(contact_array);

        return {
            contact_preference: {
                type:'select',
                label: __('Contact Prefered'),
                validation: ['notEmpty'],
                selectOptions: contact_list,
                value:customer.contact_preference
            }
            /*email: {
                id:1,
                type:'email',
                label: __('E-mail contact'),
                validation: ['notEmpty'],
                value: (customer == undefined ? '' : customer.email)
            },
            phone: {
                id:2,
                type: 'text',
                label: __('Contact phone'),
                validation: ['notEmpty'],
                value: (customer == undefined ? '' : customer.phone)
            }*/
        };
    }

    renderFields() {
        return (
            <>
            <div
              block="FieldForm"
              elem="Fields"
            >
                { Object.entries(this.fieldMap).map(this.renderField) }
            </div>
              <div
              block="FieldForm"
              elem="Fields-Contact"
            >
            { Object.entries(this.fieldMap2).map(this.renderField) }
            </div>
            </>
        );
    }

    renderActions(){
        return(
            <button
            type="submit"
            block="Button"
            mix={ { block: 'MyAccount', elem: 'Button' } }
            >
            { __('Save Changes') }
            </button>
            );

        }

    render(){
        const { title,customer }  = this.props;
            return (
                <>
                <h1 block="MyAccount" elem="Heading">{ __('Sauda notifications') }</h1>
                <div block="MyAccountNotification">
                <Form
                    onSubmitSuccess={ this.onFormSuccess }
                    mix={ { block: 'FieldForm' } }
                    >
                    { this.renderFields() }
                    <div block="MyAccountNotification" elem="Link">
                    <a href="/condicoes-gerais">{ __('General Condition') }</a>
                    </div>
                    { this.renderActions() }
                </Form>
                    </div>
                </>
            );
    }


}

export default MyAccountNotification;
