import PropTypes from 'prop-types';
import {
    Menu as SourceMenu,
} from 'SourceComponent/Menu/Menu.component';

import { DeviceType } from 'Type/Device';
import { MenuType } from 'Type/Menu';
import { getSortedItems } from 'Util/Menu';
import MenuItem from 'Component/MenuItem';
import Link from 'Component/Link';
import Image from 'Component/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt, faPlusCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faBars, faBookOpen, faCreditCard, faCommentLines, faCreditCardBlank, faHeart } from '@fortawesome/pro-light-svg-icons';
import servicesIcon from 'Route/StoreFinder/images/servicesIcon.svg';
import CmsBlock from 'Component/CmsBlock';

import './Menu.style.override.scss';

export class Menu extends SourceMenu {

    static propTypes = {
        menu: MenuType.isRequired,
        activeMenuItemsStack: PropTypes.array.isRequired,
        handleSubcategoryClick: PropTypes.func.isRequired,
        closeMenu: PropTypes.func.isRequired,
        onCategoryHover: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        isMenuVisible: PropTypes.bool.isRequired,
        onClickMenuToggle: PropTypes.func.isRequired,
        onClickOut: PropTypes.func.isRequired,
        isLogin: PropTypes.bool,
        getAvailableServices: PropTypes.func.isRequired
    };

    static defaultProps = {
        menuVisible: false
    };

    renderSubLevel(category) {
        const {
            activeMenuItemsStack,
            pharmacyHeader,
            device,
            goBackSubMenuMobile,
            closeMenu,
            closeMenuMobile,
            handleSubcategoryClick
        } = this.props;
        const { item_id, children, title } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };

        let itemList = "ItemList";

        if(pharmacyHeader) {
            itemList = "ItemListPharmacy"
        }

        let menuHeader = '';
        let categoryHeader = '';
        if (device.isMobile) {
            menuHeader = <div block="Menu" elem="HeaderActions">
                <div block="Menu" elem="HeaderActionBack" onClick={ goBackSubMenuMobile }>{ __('Back') }</div>
                <div block="Menu" elem="HeaderActionClose" onClick={ closeMenuMobile }>
                    <FontAwesomeIcon className="Header-IconLeft" icon={faTimes} />
                </div>
            </div>

            categoryHeader = <div block="Menu" elem="SubMenuHeader">
                <MenuItem
                    activeMenuItemsStack={ [] }
                    item={ category }
                    isLink
                    onClick={ (e) => handleSubcategoryClick(e, category) }
                />
            </div>;
        }

        if (device.isMobile) {
            return (
                <div
                  block="Menu"
                  elem="SubMenu"
                  mods={ { isVisible } }
                  key={ item_id }
                >   <div
                      block="Menu"
                      elem= { itemList }
                      mods={ { ...subcategoryMods } }
                    >
                        { menuHeader }
                        { categoryHeader }
                        { childrenArray.map(this.renderSubLevelMobileItems) }
                    </div>
                </div>
            )
        }

        return (
            <div
              block="Menu"
              elem="SubMenu"
              mods={ { isVisible } }
              key={ item_id }
            >   <div
                  block="Menu"
                  elem= { itemList }
                  mods={ { ...subcategoryMods } }
                >
                    { menuHeader }
                    { categoryHeader }
                    { childrenArray.map(this.renderSubLevelItems) }
                </div>
            </div>
        );
    }

    renderSubLevelMobileItems = (item) => {
        const {
            activeMenuItemsStack,
            activeSubMenuItemsStack,
            onClickSubCategoryToggle,
            closeMenu
        } = this.props;

        const {
            item_id,
            children,
            item_class
        } = item;

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const childrenArray = Object.values(children);
        const hasChildren =  childrenArray.length > 0;
        const isVisible = activeSubMenuItemsStack.includes(parseInt(item_id));

        if (hasChildren) {
            return (
                <div
                    block="Menu"
                    elem="SubItemWrapper"
                    key={ item_id }
                    mods={ { isBanner, isVisible, hasChildren } }
                    onClick={ () => onClickSubCategoryToggle(item) }
                >
                    <MenuItem
                        activeMenuItemsStack={ activeMenuItemsStack }
                        item={ item }
                        closeMenu={ closeMenu }
                        isLink={ false }
                        onClick={ () => false }
                    />
                    <div block="Menu" elem="SubItemWrapperChildren">
                        <div block="Menu" elem="SubItemWrapper">
                            <Link to={ item.url } block="Menu" elem="Link" id={ item.item_id }>
                                { __("See All") }
                            </Link>
                        </div>
                        { childrenArray.map(this.renderSubLevelItems) }
                    </div>
              </div>
            );
        }


        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ { isBanner } }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  closeMenu={ closeMenu }
                  isLink
                />
            </div>
        );
    }

    renderSubLevelItems = (item) => {
        const {
            handleSubcategoryClick,
            activeMenuItemsStack,
            activeSubMenuItemsStack,
            onClickSubCategoryToggle,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const {
            item_id,
            children,
            item_class
        } = item;

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const childrenArray = Object.values(children);
        const subcategoryMods = { type: 'subcategory' };

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                    key={ item_id }
                    // TODO: split into smaller components
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ (e) => handleSubcategoryClick(e, item) }
                    tabIndex="0"
                    role="button"
                >
                    <MenuItem
                        activeMenuItemsStack={ activeMenuItemsStack }
                        item={ item }
                        itemMods={ subcategoryMods }
                        onCategoryHover={ onCategoryHover }
                        closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        const isVisible = activeSubMenuItemsStack.includes(parseInt(item_id));
        const hasChildren = childrenArray.length > 0;

        if (childrenArray.length && !device.isMobile) {
            return (
                <div
                  block="Menu"
                  elem="SubItemWrapper"
                  key={ item_id }
                  mods={ { isBanner, isVisible, hasChildren } }
                  onClick={ () => onClickSubCategoryToggle(item) }
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      closeMenu={ closeMenu }
                      isLink={ false }
                      onClick={ () => false }
                    />
                    { this.renderDesktopSubLevel(item) }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ { isBanner, isVisible } }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    };

    renderDesktopSubLevel(category) {
        const { device, closeMenu } = this.props;
        const { children, item_class, item_id, url } = category;
        const childrenArray = getSortedItems(Object.values(children));

        if (device.isMobile || !childrenArray.length) {
            return null;
        }

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const isLogo = item_class === 'Menu-ItemFigure_type_logo';
        const mods = {
            isBanner: !!isBanner,
            isLogo: !!isLogo
        };

        return (
            <div
              block="Menu"
              elem="SubLevelDesktop"
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...mods } }
                >
                    <Link to={ url } block="Menu" elem="Link" id={ item_id } onClick={ closeMenu }>
                        { __("See All") }
                    </Link>
                    { childrenArray.map((item) => this.renderDesktopSubLevelItems(item, mods)) }
                </div>
            </div>
        );
    }

    componentDidMount() {
        const { device } = this.props;

        if (!device.isMobile) {
            document.addEventListener('click', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        const { device } = this.props;

        if (!device.isMobile) {
            document.removeEventListener('click', this.handleClickOutside);
        }
    }

    handleClickOutside = ({ target }) => {
        const { onClickOut, isMenuVisible } = this.props;

        if (
            !target.closest('.Header-MenuToggle') &&
            !target.closest('.Menu-MenuWrapper') &&
            isMenuVisible
        ) {
            onClickOut();
        }
    }

    /**
     * Render submenus inside li's
     * @param {*} item
     */
    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            handleSubcategoryClick,
            onCategoryHover,
            closeMenu,
            device,
            onClickOut
        } = this.props;

        const { children } = item;
        const childrenArray = Object.values(children);
        const itemMods = { type: 'main' };

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                  // TODO: split into smaller components
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  block="Menu"
                  elem="SubCatLink"
                  role="button"
                >
                    <MenuItem
                        onClickOut = { onClickOut }
                        activeMenuItemsStack={ activeMenuItemsStack }
                        item={ item }
                        itemMods={ itemMods }
                        onCategoryHover={ onCategoryHover }
                        closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        if(childrenArray.length && !device.isMobile) {
            return (
                <>
                    <MenuItem
                        onClickOut = { onClickOut }
                        activeMenuItemsStack={ activeMenuItemsStack }
                        item={ item }
                        itemMods={ itemMods }
                        onCategoryHover={ onCategoryHover }
                        closeMenu={ closeMenu }
                        isLink
                    />
                    { this.renderSubLevel(item) }
                </>
            );
        }

        return (
            <MenuItem
                onClickOut = { onClickOut }
                activeMenuItemsStack={ activeMenuItemsStack }
                item={ item }
                itemMods={ itemMods }
                onCategoryHover={ onCategoryHover }
                closeMenu={ closeMenu }
                isLink
            />
        );
    }

    /**
     * Remove submenus and move them into menu item
     */
    renderTopLevel() {
        const { menu, closeMenuMobile, goBackMenuMobile, device } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        let header = '';
        if (device.isMobile) {
            header = <div block="Menu" elem="HeaderActions">
                <div block="Menu" elem="HeaderActionBack" onClick={ goBackMenuMobile }>{ __('Back') }</div>
                <div block="Menu" elem="HeaderActionClose" onClick={ closeMenuMobile }>
                    <FontAwesomeIcon className="Header-IconLeft" icon={faTimes} />
                </div>
            </div>;
        }

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    { header }
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                    { this.renderAdditionalInformation(true) }
                </div>
            </>
        );
    }

    renderFirstLevel = (item) => {
        const { item_id } = item;

        return (
            <li key={ item_id } block="Menu" elem="Item">
                { this.renderFirstLevelItems(item) }
            </li>
        );
    };

    renderPromotionCms() {
        const { closeMenu } = this.props;
        const { header_content: { header_cms } = {} } = window.contentConfiguration;

        if (header_cms) {
            return <CmsBlock identifier={ header_cms } />;
        }

        return null;
    }

    renderMenuToggle() {
        const { onClickMenuToggle } = this.props;

        return (
            <button
              block="Menu"
              elem="MenuToggle"
              tabIndex="0"
              onClick={ onClickMenuToggle }
              id="menuToggle"
              mix={ { block: 'Menu', elem: 'MobileLink' } }
            >
                <div
                  block="Menu"
                  elem="MenuToggleTitle"
                >
                    <FontAwesomeIcon className="Menu-IconLeft" icon={faBars} />
                    { __('Find by category') }
                </div>
            </button>
        );
    }

    /**
     * Render Pharmacy button
     */
    renderPharmacy() {
        return (
            <Link
              to="/"
              block="Menu"
              elem="Pharmacy"
              mix={ { block: 'Menu', elem: 'MobileLink' } }
            >
                <FontAwesomeIcon className="Menu-IconLeft" icon={faStoreAlt} />
                { __('Select my Pharmacy') }
            </Link>
        );
    }

    /**
     * Render Sauda card or Prescription link
     */
    renderCardPrescription() {
        return (
            <Link
              to="/"
              block="Menu"
              elem="Sauda"
              mix={ { block: 'Menu', elem: 'MobileLink' } }
            >
                <FontAwesomeIcon className="Menu-IconLeft" icon={faCreditCard} />
                { __('Enroll your Sauda card') }
            </Link>
        );
    }

    /**
     * Render Sauda card or Prescription link
     */
     renderBonus() {
        return (
            <Link
                to="/vantagens-exclusivas"
                block="Menu"
                elem="Sauda"
                mix={ { block: 'Menu', elem: 'MobileLink' } }
            >
                <FontAwesomeIcon className="Menu-IconLeft" icon={faCreditCardBlank} />
                { __('Exclusive Advantages') }
            </Link>
        );
    }

    /**
     * Render Health link
     */
    renderHealth() {
        return (
            <Link
              to="/blog"
              block="Menu"
              elem="Health"
              mix={ { block: 'Menu', elem: 'MobileLink' } }
            >
                <FontAwesomeIcon className="Menu-IconLeft" icon={faBookOpen} />
                { __('Health from A-Z') }
            </Link>
        );
    }

    /**
     * Render Services link
     */
    renderServices() {
        return (
            <Link
                to="/servicos"
                block="Menu"
                elem="Services"
                mix={ { block: 'Menu', elem: 'MobileLink' } }
            >
                <Image
                    mix={ { block: 'Menu', elem: 'Icon' } }
                    width="18px"
                    height="16px"
                    src={servicesIcon}
                />
                { __('Services') }
            </Link>
        );
    }

    renderHelp() {
        return (
            <Link
              to="/"
              block="Menu"
              elem="Help"
              mix={ { block: 'Menu', elem: 'MobileLink' } }
            >
                <FontAwesomeIcon className="Menu-IconLeft" icon={faCommentLines} />
                { __('Help') }
            </Link>
        );
    }

    renderAboutUs() {
        return (
            <Link
              to="/"
              block="Menu"
              elem="AboutUs"
              mix={ { block: 'Menu', elem: 'MobileLink' } }
            >
                <FontAwesomeIcon className="Menu-IconLeft" icon={faPlusCircle} />
                { __('About us') }
            </Link>
        );
    }

    controlMenuByState() {
        const { menuVisible, favStoreObj, services } = this.props;

        if(menuVisible) {
            return (
                <>
                    { this.renderTopLevel() }
                </>
            );
        }

        let isNotSauda = Object.entries(favStoreObj).length ? favStoreObj.sauda == 0 : false;

        // check if theres any service that can be shown on services page
        // if yes, then we'll render the link for the services page in the mobile menu
        let hasServices = services.find(element => element.show_in_services_page === 1);

        return (
            <>
                { /*this.renderPharmacy()*/ }
                { this.renderMenuToggle() }
                { !isNotSauda ? this.renderBonus() : null }
                { /* this.renderCardPrescription() */ }
                { this.renderHealth() }
                { hasServices ? this.renderServices() : null}
                { this.renderHelp() }
                { this.renderAboutUs() }
                { this.renderPromotionCms() }
            </>
        );
    }

    /**
     * Add menu visibility toggle
     */
    render() {
        const { closeMenuHover, isMenuVisible, device, menuVisible, isLogin } = this.props;
        if(device.isMobile) {
            return (
                <div
                    block="Menu"
                    elem="MenuWrapper"
                    mods={ { isMenuVisible, menuVisible, isLogin } }
                    onMouseLeave={ closeMenuHover }
                >
                    { this.controlMenuByState() }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              mods={ { isMenuVisible } }
              onMouseLeave={ closeMenuHover }
            >
                { this.renderTopLevel() }
            </div>
        );
    }

};

export default Menu;
