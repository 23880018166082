import {
    MyAccountCustomerTable as SourceMyAccountPasswordTable,
} from 'SourceComponent/MyAccountCustomerTable/MyAccountCustomerTable.component';
import MyAccountPageManager from 'Component/MyAccountPageManager';
import { customerType,addressType } from 'Type/Account';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit } from '@fortawesome/pro-regular-svg-icons';

import './MyAccountPasswordTable.style.override'

export class MyAccountPasswordTable extends SourceMyAccountPasswordTable {
    // TODO implement logic
    static propTypes = {
        customer: customerType.isRequired,
      //  address: addressType.isRequired,
        showEditPopup: PropTypes.func.isRequired,
        showChangePasswordPopup: PropTypes.func.isRequired,
        showEditForm: PropTypes.func.isRequired
    };

    get dataPairArray() {
        const { customer } = this.props;

        return [
            {
                key: 'email',
                label: __('E-mail'),
                source: customer
            },
            {
                key: 'password',
                label: __('Password'),
                source: customer
            }
        ];
    }

    renderActions() {
        const { showChangePasswordPopup, showEditPopup, showEditForm} = this.props;

        return (
            <>
                <button
                  block="MyAccountCustomerTable"
                  elem="EditButton"
                  title={ __('Edit') }
                  onClick={ showEditForm  }//showEditPopup }
                >
                    <FontAwesomeIcon icon={faEdit} />
                </button>
            </>
        );
    }

    renderTableRow = (data) => {
        const { key, label } = data;
        const value = this.getValueFromSource(data);
        let newValue = value;

        if(key == 'password') {
            newValue = '********';
        }

        if (!value && value != undefined) {
            return null;
        }


        return (
            <div block="MyAccountPasswordTable" elem="UserInfoBlock" key={ key }>
                <p block="MyAccountPasswordTable" elem="UserInfoTitle">{ label }</p>
                <p block="MyAccountPasswordTable" elem="UserInfoValue">{ newValue }</p>
            </div>
        );
    };

    renderPageManager(button) {

        return (
            <>
            <h1 block="MyAccount" elem="Heading" mods={{ altDisplay: true }}>{ button }{ __('Edit User Info') }</h1>
            <div block="MyAccountDashboard" elem="AddressesEdit">
                <div
                    block="MyAccountDashboard"
                    elem="AddressesHolder"
                    mix={ { block: 'MyAccountDashboard', elem: 'PasswordsHolder' } }
                >
                <div block="MyAccountPasswordTable">
                <MyAccountPageManager
                { ...this.props }
                />
               </div>
                </div>
            </div>
            </>
        );
    }

    renderTable() {
        return (
            <div block="MyAccountPasswordTable" elem="InfoWrapper">
                { this.dataPairArray.map(this.renderTableRow) }
            </div>
        );
    }

    renderContent(){
        const { hideTables }  = this.props;
        if(!hideTables){
            return (
                <div block="MyAccountDashboard" elem="AddressesWrapper">
                    <div
                        block="MyAccountDashboard"
                        elem="AddressesHolder"
                        mix={ { block: 'MyAccountDashboard', elem: 'PasswordsHolder' } }
                    >
                        <h3 block="MyAccountCustomerTable" elem="Title">{ __('User Info') }</h3>
                        <div block="MyAccountPasswordTable">
                            { this.renderTable() }
                            { this.renderActions() }
                        </div>
                    </div>
                </div>
            );
      }
    }

    render() {
        //<h3 block="MyAccountPasswordTable" elem="Title">{ title }</h3>
        const { title, showHideForm, showEditForm, hideTables}  = this.props;

        let button;
        if(hideTables && showHideForm) {
            button = <button
                    block="MyAccount"
                    elem="BackButton"
                    onClick={ showEditForm }
                >
                    <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                    { __('Back') }
                </button>
        }

        return (
            <>
                    { showHideForm ? this.renderPageManager(button) : this.renderContent() }
            </>
        );
    }
};

export default MyAccountPasswordTable;
