import {
    MyAccountCustomerTable as SourceMyAccountCustomerTable,
} from 'SourceComponent/MyAccountCustomerTable/MyAccountCustomerTable.component';

import { customerType,addressType } from 'Type/Account';
import PropTypes from 'prop-types';
import Image from 'Component/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit } from '@fortawesome/pro-regular-svg-icons';

import './MyAccountCustomerTable.style.override.scss'
import MyAccountPageManager from 'Component/MyAccountPageManager';

export class MyAccountCustomerTable extends SourceMyAccountCustomerTable {
    // TODO implement logic
    static propTypes = {
        customer: customerType.isRequired,
      //  address: addressType.isRequired,
        showEditForm: PropTypes.func.isRequired,
        showEditPopup: PropTypes.func.isRequired,
        showHideForm: PropTypes.bool.isRequired,
        showChangePasswordPopup: PropTypes.func.isRequired
    };

    get dataPairArray() {
        const { customer } = this.props;//,address } = this.props;
        customer['fullname'] = customer.firstname + ' ' + customer.lastname;
        /*if (!customer.phone || customer.phone == '+351') {
            customer['phone'] = __('No phone associated.');
        }*/

        return [
            {
                key: 'fullname',
                label: __('Name'),
                source: customer,
                full_width: true,
            },
            {
                key: 'date_of_birth',
                label: __('Date of Birth'),
                source: customer
            },
            {
                key: 'gender',
                label: __('Gender'),
                source: customer
            },
            {
                key: 'doc_type',
                label: __('Document ID'),
                source: customer
            },
            {
                key: 'doc_number',
                label: __('Identification Number'),
                source: customer
            },
            {
                key: 'phone',
                label: __('Phone number'),
                source: customer
            },
            {
                key: 'taxvat',
                label: __('Tax/VAT Number'),
                source: customer
            }
            //,
            // {
            //     key: 'photo',
            //     label: __('Profile Photo'),
            //     source: customer
            // }
        ];
    }

    renderActions() {
        const { showChangePasswordPopup, showEditPopup, toggleEditForm} = this.props;

        return (
            <>
                <button
                  block="MyAccountCustomerTable"
                  elem="EditButton"
                  title={ __('Edit') }
                  mods= {{isSpecial: true}}
                  onClick={ toggleEditForm }//showEditPopup
                >
                    <FontAwesomeIcon icon={faEdit} />
                </button>
            </>
        //   <button
        //  block="Button"
        //   mods={ { isHollow: true } }
        //   onClick={ showChangePasswordPopup }
        //  >
        //     { __('Change password') }
        // </button>
        );
    }

    trimPhone(customer) {
        customer.phone = customer.phone.replace(/\s+/g, '');
    }
    renderTableRow = (data) => {
        const { key, label, full_line, full_width } = data;
        const value = this.getValueFromSource(data);
        let newVal = value;

        if (!value) {
            return null;
        }

        // if(key == 'photo'){
        //     return (
        //         <div block="MyAccountCustomerTable" elem="UserInfoBlock" key={ key }>
        //             <p block="MyAccountCustomerTable" elem="UserInfoTitle">{ label }</p>
        //             <p block="MyAccountCustomerTable" elem="UserInfoValue">
        //                 <Image
        //                     mix={ { block: 'MyAccountCustomerTable', elem: 'UserPhoto' } }
        //                     src={ value }
        //                     ratio={ 'custom' }
        //                 />
        //             </p>
        //         </div>
        //     );
        // }

        if(key == 'gender') {
            switch(value) {
                case 1:
                    newVal = __('Male')
                    break;
                case 2:
                    newVal = __('Female')
                    break;
                case 3:
                    newVal = __('Undefined')
                    break;
            }
        }

        if(key == 'doc_type') {
            switch(value) {
                case 1:
                    newVal  = __('Citizen Card');
                    break;
                case 2:
                    newVal = __('Passport')
                    break;
            }
        }

        if(key == 'fullname' && value.includes('undefined')) {
            newVal = '';
        }
        if(key == 'phone' && value && value != '') {
            newVal = '(+351) ' + value;
        }
        if(key == 'date_of_birth' && value) {
            var date = new Date(value);
            newVal = (this.addZero(date.getDate()) + "/" + this.addZero(date.getMonth() + 1) + "/" + date.getUTCFullYear()) ;
        }
        return (
            <div block="MyAccountCustomerTable" elem="UserInfoBlock" key={ key } mods={{ full_line, full_width }}>
                <p block="MyAccountCustomerTable" elem="UserInfoTitle">{ label }</p>
                <p block="MyAccountCustomerTable" elem="UserInfoValue">{ newVal ? newVal : '' }</p>
            </div>
        );
    };
    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }
    renderTable() {
        return (
            <div block="MyAccountCustomerTable" elem="InfoWrapper">
                { this.dataPairArray.map(this.renderTableRow) }
            </div>
        );
    }

    renderPageManager(button) {
        let isEdit = true;
        return (
            <>
                {/* <div block="MyAccountProfilePicture">
                    <label class="Field-Label" for="profilePhoto">{__('Profile Photo')}</label>
                    <input type="file" name="profilePhoto" id="profilePhoto" onChange={this.onphotoChange}/>
                </div> */}
                <h1 block="MyAccount" elem="Heading" mods={ { isEdit } }>{ button }{ __('Edit My Profile') }</h1>
                <div block="MyAccountCustomerTable">
                    <MyAccountPageManager
                    { ...this.props }
                    />
                </div>
            </>
        );
    }

    renderContent(button){
        const { hideTables,title }  = this.props;
        if(!hideTables){
            return (
                <>
                <h1 block="MyAccount" elem="Heading">{ button }{ __('Dashboard') }</h1>
                 <div block="MyAccountCustomerTable">
                    <div block="MyAccountCustomerTable"
                        elem="TitleActions">
                        <h3 block="MyAccountCustomerTable" elem="Title">{ title }</h3>
                        { this.renderActions() }
                    </div>
                    { this.renderTable() }
                 </div>
                </>
            );
      }
    }

    render() {
        const { title, showHideForm, toggleEditForm, hideTables}  = this.props;

        let button;
        if(hideTables && showHideForm) {
            button = <button
                    block="MyAccount"
                    elem="BackButton"
                    onClick={ toggleEditForm }
                >
                    <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />{ __('Back') }</button>
        }

        return (
            <>
                    { showHideForm ? this.renderPageManager(button) : this.renderContent(button) }
            </>
        );
    }
};

export default MyAccountCustomerTable;
