/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import { convertDateFormat } from '../../util/Date';
import { getPostUrl } from '../../util/Posts';
import { MAX_RECENT_POSTS } from './BlogRecentPosts.config';

import './BlogRecentPosts.style';

/** @namespace ScandiPWA/Blog/Component/BlogRecentPosts/Component */
export class BlogRecentPosts extends PureComponent {
    static propTypes = {
        posts: PropTypes.array.isRequired,
        isLoaded: PropTypes.bool.isRequired
    };

    renderContents() {
        const { posts, isLoaded } = this.props;
        const post = {};

        if (!isLoaded) {
            return Array(MAX_RECENT_POSTS).fill().map((_, i) => this.renderCard(post, i));
        }

        return posts.map(this.renderCard);
    }

    renderCard(post, key) {
        const { title, first_image, publish_time } = post || {};
        const postUrl = getPostUrl(post);

        return (
            <div
              block="BlogRecentPosts"
              elem="PostCard"
              key={ key }
            >
                <Link
                  to={ postUrl }
                  title={ __('View %s', title) }
                  mix={ { block: 'BlogRecentPosts', elem: 'ImageWrapper' } }
                >
                    <Image
                      src={ first_image }
                      ratio="16x9"
                      isPlaceholder={ !first_image }
                    />
                </Link>
                <div
                  block="BlogRecentPosts"
                  elem="Wrapper"
                >
                    <Link to={ postUrl } title={ __('View %s', title) }>
                        <h3 block="BlogRecentPosts" elem="PostTitle">
                            <TextPlaceholder
                              mix={ { block: 'BlogRecentPosts', elem: 'PostTitlePlaceholder' } }
                              content={ title }
                              length="short"
                            />
                        </h3>
                    </Link>
                    <div block="BlogRecentPosts" elem="Date">
                        <TextPlaceholder
                          mix={ { block: 'BlogRecentPosts', elem: 'DatePlaceholder' } }
                          content={ publish_time ? convertDateFormat(publish_time) : null }
                          length="custom"
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="BlogRecentPosts" elem="Wrapper">
                <h3 block="BlogRecentPosts" elem="Title">
                    Recent Posts
                </h3>
                { this.renderContents() }
            </div>
        );
    }
}

export default BlogRecentPosts;
