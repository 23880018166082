/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/** @namespace ScandiPWA/Blog/Query */
export class BlogPostsQuery {
    getQuery(options) {
        const page = this.pageField();

        const {
            filter,
            sort,
            sortFiled
        } = options;

        const defaultOptions = {
            pageSize: 10,
            currentPage: 1
        };

        const modifiedOptions = { ...defaultOptions, ...options };
        const items = this.itemField(options);

        const field = new Field('blogPosts')
            .addArgument('pageSize', 'Int', modifiedOptions.pageSize)
            .addArgument('currentPage', 'Int', modifiedOptions.currentPage)
            .addField(items)
            .addFieldList(page)
            .setAlias('posts');

        if (filter) {
            field.addArgument('filter', 'BlogPostsFilterInput', filter);
        }

        if (sort) {
            field.addArgument('sort', '[String]', sort);
        }

        if (sortFiled) {
            field.addArgument('sortFiled', 'String', sortFiled);
        }

        return field;
    }

    itemField(options) {
        const {
            getBasicDetails = true,
            getDescription = true,
            getMedia
        } = options;

        const fields = [];
        const author = this.authorField();

        if (getBasicDetails) {
            fields.push('post_id', 'identifier', 'title', 'publish_time', 'views_count', 'featured_image', 'first_image', ...this.metaFields());
        }

        if (getDescription) {
            fields.push('short_content', author, 'content');
        }

        if (getMedia) {
            fields.push('og_image', 'first_image', 'media_gallery');
        }

        return new Field('items').addFieldList(fields);
    }

    pageField() {
        return ['total_count', 'total_pages'];
    }

    authorField() {
        return new Field('author').addField('name');
    }

    metaFields() {
        return ['meta_title', 'meta_description', 'meta_keywords'];
    }

    _searchPosts(search) {
        return new Field('getSearchPosts')
            .addArgument('search', 'String!', search)
            .addFieldList(this._getSearchPostsFields());
    }

    _getSearchPostsFields() {
        return [
            'post_id',
            'title',
            'featured_image'
        ];
    }
}

export default new BlogPostsQuery();
