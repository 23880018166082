import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './CheckoutConfirm.style';

import Form from 'Component/Form';
import { CONFIRM_STEP } from 'Route/Checkout/Checkout.config';
import { addressType } from 'Type/Account';
import CartItem from 'Component/CartItem';
import Image from 'Component/Image';
import Link from 'Component/Link';
import pin from 'Route/StorePage/Images/pin.png';
import clock from 'Route/StorePage/Images/clock.png';
import phone from 'Route/StorePage/Images/phone.png';
import erroImg from 'Route/CheckoutSauda/Images/error-24px-5@2x.png';
import home from 'Route/CheckoutSauda/Images/path115@2x.png';
import pharmacy from 'Route/CheckoutSauda/Images/group360@2x.png';
import iconMbWay from 'Component/MyAccountMyOrdersDetails/Images/mb-way@2x.png';
import iconMb from 'Component/MyAccountMyOrdersDetails/Images/mb@2x.png';
import iconVisaLogo from 'Component/MyAccountMyOrdersDetails/Images/visa-logo@2x.png';
import iconPosPago from 'Component/MyAccountMyOrdersDetails/Images/Group745@2x.png';
import { imageOverlay } from 'leaflet';
import PharmacySchedule from 'Component/PharmacySchedule';
import base_logo from 'Component/MyPharmacy/Images/logo_pharmacy_default.jpg';
import history from 'Util/History';
import Field from 'Component/Field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Popup from 'Component/Popup';
import CmsBlock from 'Component/CmsBlock';

class CheckoutConfirm extends PureComponent {
    static propTypes = {
        onConfirmSuccess: PropTypes.func.isRequired,
        onConfirmError: PropTypes.func.isRequired,
        setLoading: PropTypes.func.isRequired,
        confirmPlaceOrder: PropTypes.func.isRequired,
        shippingAddress: addressType.isRequired,
        billingAddress: addressType.isRequired,
        setNotReady: PropTypes.func.isRequired,
        getNotReady: PropTypes.func.isRequired,
        notReady: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isConfirm: true,
    };

    renderProducts() {
        return(
            <div>
                <h3 block="CheckoutConfirm" elem="Header" mix={ { block: 'CheckoutPage', elem: 'Heading', mods: { hasDivider: true } } }>
                    <span>{ __('Shopping cart') }</span>
                </h3>

                { this.renderItemProduct() }
            </div>
        );
    }
    renderItemProduct() {
        const { totals: { items, quote_currency_code ,is_wallet} } = this.props;

        return (
            <div block="CheckoutConfirm" elem="Shopping">
                <ul block="CartPage" elem="Items" aria-label="List of items in cart">
                    { items.map((item) => (
                        <CartItem
                            is_wallet={ ((is_wallet && is_wallet == item.sku) ? item.sku : null) }
                            key={ item.item_id }
                            currency_code={ quote_currency_code }
                            item={ item }
                            isEditing
                            isLikeTable
                            updateCrossSellsOnRemove
                            isConfirm={ true }
                            buyWithPointsFlag={ item.buy_with_points }
                        />
                    )) }
                </ul>
            </div>
        );
    }
    renderShipping() {
        const { shippingMethod } = this.props;

        let imgDeliveryMethod = '';
        let labelDeliveryMethod = '';
        let deliveryMethod = '';

        if (shippingMethod.shipping_carrier_code == "pharmacy_pickup") {
            imgDeliveryMethod =
            <Image
                src = {pharmacy}
                height="32px"
            />;
            labelDeliveryMethod = __("Collection at the pharmacy");
            deliveryMethod = this.renderPharmacyInfo();
        }
        if (shippingMethod.shipping_carrier_code == "flatrate") {
            imgDeliveryMethod =
            <Image
                src = {home}
                height="32px"
            />;
            labelDeliveryMethod = __("Deliver to Home");
            deliveryMethod = this.renderShippingAddress();
        }

        return(
            <div>
                <h3 block="CheckoutConfirm" elem="Header" mix={ { block: 'CheckoutPage', elem: 'Heading', mods: { hasDivider: true } } }>
                    <span>{ __('Delivery data') }</span>
                    <Link
                        to="/checkout/shipping"
                        block="CheckoutConfirm"
                        elem="GoBack"
                        >
                        { __("Edit") }
                    </Link>
                </h3>
                <div block="CheckoutConfirm" elem="Delivery">
                    <div block="CheckoutConfirm-Delivery" elem="Title">
                        <div block="CheckoutConfirm-Delivery" elem="Method">
                            {imgDeliveryMethod}
                            <label> { labelDeliveryMethod } </label>
                        </div>
                    </div>
                    <div block="CheckoutConfirm" elem="Separator"></div>
                    <div block="CheckoutConfirm-Delivery" elem="Content">
                        { deliveryMethod }
                    </div>
                </div>
            </div>
        );
    }

    renderPharmacyInfo() {
        const { storeObj, baseLinkUrl, favStoreObj } = this.props;

        if(!Object.entries(favStoreObj).length) {
            return null;
        }

        const {
            name,
            street_name,
            locality,
            postal_code_zone,
            postal_code_sufix,
            postal_code_locality,
            country,
            all_schedules,
            number,
            telephone,
            telephone_secondary,
            schedule_values,
            status,
            logo
        } = favStoreObj;

        let media = '/media/';
        let mediaUrl = baseLinkUrl.concat(media);
        let url = logo ? mediaUrl.concat(logo) : base_logo;

        let address = `${street_name} ${number}`;

        if (locality) {
            address += ' ' + locality;
        }

        if (postal_code_zone && postal_code_sufix) {
            address += ',\n' + postal_code_zone + '-' + postal_code_sufix;
        }

        if (postal_code_locality) {
            address += ' ' + postal_code_locality;
        }
        

        let storeStatusLabel = __('Closed');
        let storeSaturdays = __('Closed');
        let storeSundays = __('Closed');
        let storeWeekdays = __('Closed');

        let telephoneToShow = telephone_secondary ? telephone_secondary : telephone;

        if(status == 'open') {
            storeStatusLabel = <>{ __("Open today until ") } { schedule_values[schedule_values.length-1] }</>;
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        if(all_schedules.saturdays_start !== null) {
            storeSaturdays = <>{ all_schedules.saturdays_start } - { all_schedules.saturdays_end }</>;
        }

        if(all_schedules.sunday_start !== null) {
            storeSundays = <>{ all_schedules.sunday_start } - { all_schedules.sunday_end }</>;
        }

        if(all_schedules.holidays_start !== null) {
            storeWeekdays = <>{ all_schedules.holidays_start } - { all_schedules.holidays_end }</>;
        }

        let services = [];

        if (favStoreObj.services) {
            favStoreObj.services.forEach((item) => {
                services.push(<p block="StorePage" elem="Service_Info">{ item.name }</p>);
            })
        }

        return (
            <div block="CheckoutAddressBook">
                <div block="CheckoutConfirm" elem="PharmacyWrapper">
                    <div block="CheckoutConfirm" elem="Pharmacy">
                        <div block="MyPharmacy" elem="PharmacyLogo">
                            <Image
                                src = {url}
                                width="70px"
                                height="50px"
                            />
                        </div>
                        <h3 block="CheckoutAddressBook" elem="PharmacyName">
                            { name || __('No store name') }
                        </h3>
                    </div>
                    <div block="CheckoutConfirm" elem="PharmacyAddress">
                        <div block="CheckoutAddressBook" elem="IconInfo">
                            <Image
                                src={ pin }
                                width="20px"
                                height="20px"
                            />
                            <span block="MyPharmacy" elem="AddressRoute">
                                <p>{ address || __('No address') }</p>
                            </span>
                        </div>
                        <div block="CheckoutAddressBook" elem="IconInfo">
                            <Image
                                src={ phone }
                                width="20px"
                                height="20px"
                            />
                            <span block="MyPharmacy" elem="PhoneNumber">
                                <p>{ telephoneToShow || __('No telephone') }</p>
                            </span>
                        </div>
                    </div>
                </div>
                <div block="CheckoutAddressBook" elem="Separator"></div>
                <div block="CheckoutAddressBook" elem="IconInfo">
                    <Image
                        src={ clock }
                        width="20px"
                        height="20px"
                    />
                    <div>
                        <h3 block="CheckoutAddressBook" elem="PharmacyName">
                            { __('Schedule') }
                        </h3>
                        <h4 block="CheckoutAddressBook" elem="PharmacyStatus" mods={{ isOpen: (status == 'open' || status == 'open_24'), isClosing: status == 'closing' }}>
                            { storeStatusLabel }
                        </h4>
                        <div block="MyPharmacy" elem="PharmacyHours">
                            { <PharmacySchedule storeObj={ favStoreObj } /> }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderShippingAddress() {
        const { shippingAddress, billingAddress } = this.props;

        const {
            firstname,
            lastname,
            street,
            city,
            telephone,
            vat_id,
            postcode,
        } = shippingAddress;

        let phoneNumber = '';

        if (telephone && telephone != 'empty') {
            phoneNumber = telephone;
        } else if (billingAddress.telephone && billingAddress.telephone != 'empty') {
            phoneNumber = billingAddress.telephone;
        }

        let address =
            <div>
                <label>{ street[0] }</label>
                <label>{ postcode } { city }</label>
            </div>;

        let fullname= '';
        if (firstname) {
            fullname = firstname;

            if (lastname) {
                fullname += " " + lastname;
            }
        }

        return (
            <div block="CheckoutConfirm-Delivery" elem="Info">
                <h3 block="CheckoutConfirm-Delivery" elem="InfoName">
                    { fullname !== '' ? fullname : __('No store name') }
                </h3>
                <div block="CheckoutConfirm-Delivery" elem="IconInfo">
                    <Image
                        src={ pin }
                        width="20px"
                        height="20px"
                    />
                    <span block="Delivery" elem="AddressRoute">
                        <p>{ address || __('No address') }</p>
                    </span>
                </div>
                <div block="CheckoutConfirm-Delivery" elem="IconInfo">
                    <Image
                        src={ phone }
                        width="20px"
                        height="20px"
                    />
                    <span block="Delivery" elem="PhoneNumber">
                        <p>{ phoneNumber ||  __('No telephone') }</p>
                    </span>
                </div>
            </div>
        );
    }

    renderPayment() {
        const { paymentMethod, is_wallet, totals } = this.props;
        let imgDeliveryMethod = '';
        let labelDeliveryMethod = '';
        let deliveryMethod = '';
        let prescriptionFlag = is_wallet;

        switch (paymentMethod.code) {
            case "checkmo":
                imgDeliveryMethod =
                    <Image
                        src = { iconPosPago }
                        width="60px"
                        height="32px"
                    />;
                    labelDeliveryMethod = __("Check / Money order");
                    deliveryMethod = this.renderPaymentData();
                break;
            case "mb":
                imgDeliveryMethod =
                    <Image
                        src = { iconMb }
                        height="32px"
                    />;
                    labelDeliveryMethod = __("Multibanco");
                    deliveryMethod = this.renderPaymentData();
                break;
            case "creditcard":
                imgDeliveryMethod =
                    <div block="CheckoutConfirm" elem="logo">
                        <img
                            src = { iconVisaLogo }
                            height="32px"
                            width="60px"
                        />
                    </div>;
                    labelDeliveryMethod = __("Credit Card");
                    deliveryMethod = this.renderPaymentData();
                break;
            case "mbway":
                imgDeliveryMethod =
                    <Image
                        src = { iconMbWay }
                        height="32px"
                    />;
                    labelDeliveryMethod = __("MB WAY");
                    deliveryMethod = this.renderPaymentData();
                break;
            case "cashondelivery":
                imgDeliveryMethod =
                    <Image
                        src = { iconPosPago }
                        height="32px"
                    />;
                    labelDeliveryMethod = __("Postpaid");
                    deliveryMethod = this.renderPaymentData();
                break;
            case "free":
                imgDeliveryMethod =
                    <Image
                        src = { iconPosPago }
                        height="32px"
                    />;
                labelDeliveryMethod = __("Postpaid");
                deliveryMethod = this.renderPaymentData();
                break;
            default:
                break;
        }

        return(
            <div>
                <h3 block="CheckoutConfirm" elem="Header" mix={ { block: 'CheckoutPage', elem: 'Heading', mods: { hasDivider: true } } }>
                    <span>{ __('Payment data') }</span>
                    <Link
                        to="/checkout/billing"
                        block="CheckoutConfirm"
                        elem="GoBack"

                        >
                        { __("Edit")}
                    </Link>
                </h3>
                <div block="CheckoutConfirm" elem="Payment">
                    <div block="CheckoutConfirm-Delivery" elem="Title">
                        <div block="CheckoutConfirm-Delivery" elem="Method">
                            { imgDeliveryMethod }
                            <label> { labelDeliveryMethod } </label>
                        </div>
                    </div>
                    { prescriptionFlag && <>
                    <div block="CheckoutAddressBook" elem="Separator"></div>
                    <div block="CheckoutConfirm-Payment" elem="Info">
                        <div block="CheckoutConfirm" elem="InfoDescription">
                            <Image
                                src={ erroImg }
                                width="20px"
                                height="20px"
                            />
                           <CmsBlock identifier={ "checkout_confirm_wallet_notice" } />
                        </div>
                        <div block="CheckoutConfirm" elem="IconInfo">
                            <span>{ __('After finalizing the order, you will receive a contact from your pharmacist.') }</span>
                        </div>
                    </div>
                    </> }
                    <div block="CheckoutAddressBook" elem="Separator"></div>
                    <div block="CheckoutConfirm-Delivery" elem="Content">
                        { deliveryMethod }
                    </div>
                </div>
            </div>
        );
    }

    renderPaymentData() {

        const { billingAddress, customer, customBillingMbway } = this.props;
        let typePayment = 'pos-pago';
        const {
            street,
            city,
            number,
            telephone,
            vat_id,
            postcode,
            firstname,
            lastname
        } = billingAddress;

        const {
            taxvat,
            phone
        } = customer;


        let name = firstname + " " + lastname;

        let address =
            <div>
                <label>{street[0]}</label>
                <label>{postcode} {city}</label>
            </div>;
        if (typePayment == 'pos-pago') {
            let phoneToShow = '';
            if(customBillingMbway && customBillingMbway != ''){
                phoneToShow = customBillingMbway;
            } else {
                phoneToShow = telephone;
            }
            return (
                <div block="CheckoutConfirm-Payment" elem="Info">
                    <h3 block="CheckoutConfirm-Payment" elem="InfoName">
                        { __('Data billing') }
                    </h3>
                    <div block="CheckoutConfirm" elem="Billing">
                        <div>
                            <span block="CheckoutConfirm" elem="AddressBilling">
                                { address || __('No address') }
                            </span>
                        </div>
                        <div block="CheckoutConfirm" elem="CustomerName">
                            <span>
                                { name || __('No name') }
                            </span>
                        </div>
                        <div>
                            <span block="CheckoutConfirm" elem="Vat">{ __("NIF.:") } </span>
                            <span>{ vat_id || __('No nif') }</span>
                        </div>
                        <div>
                            <span block="CheckoutConfirm" elem="Telephone">{ __("Tel.:") } </span>
                            <span>{ phoneToShow || __('No telephone') }</span>
                        </div>
                    </div>
                </div>
            );
        } else {

            return (
                <div block="CheckoutConfirm-Payment" elem="Wrapper">
                    <div block="CheckoutConfirm-Payment" elem="Info">
                        <div block="CheckoutAddressBook" elem="IconInfo">
                            <Image
                                src={ erroImg }
                                width="20px"
                                height="20px"
                            />
                            <span block="CheckoutConfirm" elem="AddressRoute">
                                { __('Because your order contains ') } <b>{ __('products from a medical prescription, the amount to be paid will be communicated by your pharmacy after your order is validated.') } </b>
                            </span>
                        </div>
                        <div block="CheckoutConfirm" elem="IconInfo">
                            <span>{ __('After finalizing the order, you will receive a contact from your pharmacist.') }</span>
                        </div>
                    </div>
                    <div block="CheckoutConfirm-Payment" elem="InfoBilling">
                        <h3 block="CheckoutAddressBook" elem="PharmacyName">
                            { __('Data billing') }
                        </h3>
                        <div block="CheckoutConfirm" elem="Billing">
                            <div>
                                <span block="CheckoutConfirm" elem="AddressBilling">
                                    { address || __('No address') }
                                </span>
                            </div>
                            <div>
                                <span block="CheckoutConfirm" elem="CustomerName">
                                    { firstname || __('No name') }
                                </span>
                            </div>
                            <div>
                                <span block="CheckoutConfirm" elem="Vat">{ __("Nif.:") } </span>
                                <span>{ vat_id || __('No nif') }</span>
                            </div>
                            <div>
                                <span block="CheckoutConfirm" elem="Telephone">{ __("Tell.:") } </span>
                                <span>{ phone || __('No telephone') }</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    changeRoute = (route) => {
        const {
            location: {
                pathname
            }
        } = history;

        history.push({
            pathname: appendWithStoreCode(route)
        });
    }

    renderTermsAndConditions() {
        const { showInfoPopup, setNotReady, notReady, getNotReady } = this.props;
        return (
            <div>
                <h3 block="CheckoutConfirm" elem="Header" mix={ { block: 'CheckoutPage', elem: 'Heading', mods: { hasDivider: true } } }>
                    <span>{ __('Confirmation of Terms of Sale') }</span>
                </h3>
                <div block="CheckoutConfirm" elem="Terms">
                    <div block="CheckoutConfirm" elem="Info">
                        <span block="CheckoutConfirm-Info" elem="Text">
                            <CmsBlock identifier={ "checkout_terms_block" } />
                        </span>
                    </div>

                    <div block="CheckoutConfirm" elem="Link">
                        <a
                            onClick={() => showInfoPopup()}
                            elem="Button"
                            >
                            { __("To know more")}
                        </a>
                    </div>

                    <div block="CheckoutConfirm" elem="Accept">
                    <fieldset block="MyAccountOverlay" elem="Legend">
                        <Field
                            type="checkbox"
                            value="accept_terms"
                            label={ __('I confirm that I have read and accept the terms and conditions.') }
                            id="accept_terms"
                            mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
                            name="accept_terms"
                            validation={ ['notEmpty'] }
                            onClick={() => setNotReady(!getNotReady())}
                        />
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }


    renderPopup(){
        const { showInfoPopup } = this.props;
        return (
            <Popup
                id={ 'CheckoutTermsKnowMore' }
                clickOutside={ true }
                mix={ { block: 'CheckoutConfirm' } }
                closedOn={ false }
            >
                <FontAwesomeIcon className="CheckoutConfirm-Close" onClick={  showInfoPopup } icon={ faTimes } />
                <CmsBlock identifier={ "checkout_terms" } />
            </Popup>
        );
    }

    render() {
        const { onConfirmError, onConfirmSuccess } = this.props;
        return (
            <Form
              mix={ { block: 'CheckoutConfirm' } }
              id={ CONFIRM_STEP }
              onSubmitError={ onConfirmError }
              onSubmitSuccess={ onConfirmSuccess }
            >
                { this.renderPopup() }
                { this.renderProducts() }
                { this.renderShipping() }
                { this.renderPayment() }
                { this.renderTermsAndConditions() }
            </Form>
        );
    }
}

export default CheckoutConfirm;
