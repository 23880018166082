import React, { PureComponent } from 'react';

import ProductLinks from 'Component/ProductLinks';
import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';

import './BlogRelatedProducts.style';

/** @namespace ScandiPWA/Blog/Component/BlogRelatedProducts/Component */
export class BlogRelatedProducts extends PureComponent {
    render() {
        const { customer } = this.props;
        //if ((customer && customer.favourite_pharmacy_code) || (localStorage.getItem('guest_pharmacy_code'))) {
            return (
                <div block="BlogRelatedProducts" elem="Wrapper">
                    <ProductLinks
                      linkType={ RELATED }
                      title={ __('Related Products') }
                      isSlider={ true }
                      numberOfProductsToDisplay={ 10 }
                    />
                </div>
            );
        /*} else {
            return (<></>);
        }*/
    }
}

export default BlogRelatedProducts;
