import {
    STATUS_PASSWORD_UPDATED as SourceSTATUS_PASSWORD_UPDATED,
    STATUS_PASSWORD_MISS_MATCH as SourceSTATUS_PASSWORD_MISS_MATCH,
} from 'SourceRoute/PasswordChangePage/PasswordChangePage.config';

//TODO: implement STATUS_PASSWORD_UPDATED
export const STATUS_PASSWORD_UPDATED = SourceSTATUS_PASSWORD_UPDATED;

//TODO: implement STATUS_PASSWORD_MISS_MATCH
export const STATUS_PASSWORD_MISS_MATCH = SourceSTATUS_PASSWORD_MISS_MATCH;
