import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Menu from 'Component/Menu';

import {
    MenuPageContainer as SourceMenuPageContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps,
} from 'SourceRoute/MenuPage/MenuPage.container';

import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

export {
    mapDispatchToProps,
};

/** @namespace Route/MenuPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    storeObj: state.StorePageReducer.storeObj
});

export class MenuPageContainer extends SourceMenuPageContainer {

    render() {
        const { storeObj} = this.props;
        let pharmacyHeader = true;

        if(!Object.entries(storeObj).length){
            pharmacyHeader = false;
        }

        let isLogin = false;
        if(isSignedIn()){
            isLogin = true;
        }

        return (
            <main block="MenuPage">
                <Menu  pharmacyHeader = { pharmacyHeader } isLogin={ isLogin }/>
            </main>
        );
    }
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MenuPageContainer)
);
