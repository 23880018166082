import {
    FieldSelect as SourceFieldSelect,
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import ClickOutside from 'Component/ClickOutside';

import './FieldSelect.style.override';

export class FieldSelect extends SourceFieldSelect {

    render() {
        const {
            isSelectExpanded: isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled
        } = this.props;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  block="FieldSelect"
                  onClick={ isDisabled ? '' : handleSelectExpand }
                  onKeyPress={ isDisabled ? '' : handleSelectListKeyPress }
                  role="button"
                  tabIndex={ isDisabled ? '-1' : '0' }
                  aria-label="Select drop-down"
                  aria-expanded={ isExpanded }
                >
                    { this.renderNativeSelect() }
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }

    renderOption = (option) => {
        const {
            id,
            label,
            disabled
        } = option;

        const {
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ { isExpanded, isDisabled:disabled } }
              key={ id }
              disabled={ disabled }
              /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ disabled? '' : () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ disabled? '' : () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
            </li>
        );
    };

};

export default FieldSelect;
