import {
    CheckoutSuccess as SourceCheckoutSuccess,
} from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import CheckoutPharmacy from 'Component/CheckoutPharmacy';
import './CheckoutSuccess.style.override'
import Image from 'Component/Image';
import Link from 'Component/Link';
import pin from 'Route/StorePage/Images/pin.png';
import clock from 'Route/StorePage/Images/clock.png';
import phone from 'Route/StorePage/Images/phone.png';
import erroImg from 'Route/CheckoutSauda/Images/error-24px-5@2x.png';
import home from 'Route/CheckoutSauda/Images/path115@2x.png';
import pharmacy from 'Route/CheckoutSauda/Images/group360@2x.png';
import check from 'Route/CheckoutSauda/Images/check-24px-3@2x.png';
import iconMbWay from 'Component/MyAccountMyOrdersDetails/Images/mb-way@2x.png';
import iconMb from 'Component/MyAccountMyOrdersDetails/Images/mb@2x.png';
import iconVisaLogo from 'Component/MyAccountMyOrdersDetails/Images/visa-logo@2x.png';
import iconPosPago from 'Component/MyAccountMyOrdersDetails/Images/Group745@2x.png';
import Popup from 'Component/Popup';
import delete_icon from 'Component/MyAccountAddressTable/images/icon_delete_modal.png';
import CmsBlock from 'Component/CmsBlock';
import gtag, { install } from 'ga-gtag';
import ConfigQuery from 'Query/Config.query';
import { fetchQuery } from 'Util/Request';

export class CheckoutSuccess extends SourceCheckoutSuccess {

    static defaultProps = {
        is_wallet: false
    };

    componentDidMount() {
        const { isSignedIn, showPreferencePopup, customer: { favourite_pharmacy_code, preference_pharmacy_code } } = this.props;
        if(isSignedIn && preference_pharmacy_code && favourite_pharmacy_code != preference_pharmacy_code) {
            showPreferencePopup();
        }

        fetchQuery(ConfigQuery.getAnalyticsConfig('checkout_success')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );
    }

    // fix: update only one time
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    renderPayment() {
        const { paymentMethod, mbData } = this.props;
        let imgDeliveryMethod = '';
        let labelDeliveryMethod = '';
        let additionalData = '';

        switch (paymentMethod.code) {
            case "checkmo":
                imgDeliveryMethod =
                    <Image
                        src = { iconPosPago }
                        height="32px"
                    />;
                labelDeliveryMethod = __("Check / Money order");
                break;
            case "mb":
                imgDeliveryMethod =
                    <Image
                        src = { iconMb }
                        height="32px"
                    />;
                labelDeliveryMethod = __("Multibanco");
                if(mbData && mbData.mb_entity) {
                    let date = mbData.mb_end_date ? mbData.mb_end_date.split('T')[0] : '';
                    additionalData = <div block="CheckoutSuccess-Payment" elem="AdditionalData">
                        <p><strong>{ __('Entity') }:</strong> { mbData.mb_entity }</p>
                        <p><strong>{ __('Reference') }:</strong> { mbData.mb_reference }</p>
                        <p><strong>{ __('Amount') }:</strong> { parseFloat(mbData.mb_amount).toFixed(2) } €</p>
                        <p><strong>{ __('Limit Date') }:</strong> { date }</p>
                    </div>;
                }
                break;
            case "creditcard":
                imgDeliveryMethod =
                                <div block="CheckoutSuccess" elem="logo">
                                    <img
                                        src = { iconVisaLogo }
                                        height="32px"
                                        width="60px"
                                    />
                                </div>;
                labelDeliveryMethod = __("Credit Card");
                break;
            case "mbway":
                imgDeliveryMethod =
                    <Image
                        src = { iconMbWay }
                        height="32px"
                    />;
                labelDeliveryMethod = __("MB WAY");
                break;
            case "cashondelivery":
                imgDeliveryMethod =
                    <Image
                        src = { iconPosPago }
                        height="25px"
                        width="60px"
                    />;
                labelDeliveryMethod = __("Postpaid");
                break;
            case "free":
                imgDeliveryMethod =
                    <Image
                        src = { iconPosPago }
                        height="32px"
                    />;
                labelDeliveryMethod = __("Postpaid");
                break;
            default:
                break;
        }

        return(
            <>
                <div block="CheckoutSuccess-Payment" elem="Method">
                    { imgDeliveryMethod }
                    <label> { labelDeliveryMethod } </label>
                </div>
                { additionalData }
            </>
        );
    }

    renderDelivery() {
        const { shippingMethod } = this.props;

        if (shippingMethod.shipping_carrier_code == "pharmacy_pickup") {
            return( <CheckoutPharmacy/> );
        }
        if (shippingMethod.shipping_carrier_code == "flatrate") {
            return( <> { this.renderShippingAddress() } </>);
        }
    }

    renderShippingAddress() {
        const { shippingAddress, billingAddress } = this.props;

        const {
            firstname,
            lastname,
            street,
            city,
            telephone,
            vat_id,
            postcode,
        } = shippingAddress;

        let phoneNumber = '';

        if (telephone && telephone != 'empty') {
            phoneNumber = telephone;
        } else if (billingAddress.telephone && billingAddress.telephone != 'empty') {
            phoneNumber = billingAddress.telephone;
        }

        let fullname = firstname + ' ' + lastname;

        let address =
            <div>
                <label>{street[0]}</label>
                <label>{postcode}, {city}</label>
            </div>;

        return (
            <div block="CheckoutSuccess-Delivery" elem="Info">
                <h3 block="CheckoutSuccess-Delivery" elem="InfoName">
                    { fullname || __('No store name') }
                </h3>
                <div block="CheckoutSuccess-Delivery" elem="IconInfo">
                    <Image
                        src={ pin }
                        width="20px"
                        height="20px"
                    />
                    <span block="Delivery" elem="AddressRoute">
                       <p> { address || __('No address') } </p>
                    </span>
                </div>
                <div block="CheckoutSuccess-Delivery" elem="IconInfo">
                    <Image
                        src={ phone }
                        width="20px"
                        height="20px"
                    />
                    <span block="Delivery" elem="PhoneNumber">
                        <p> { phoneNumber || __('No telephone') } </p>
                    </span>
                </div>
            </div>
        );
    }

    renderMessageByPayment() {
        const {
            is_wallet,
            paymentMethod
        } = this.props;

        if(!is_wallet) {
            let message;
            switch (paymentMethod.code) {
                case "creditcard":
                    message = <>
                        <div className={'cc_message'}>
                            <p>{ __("Wait until you are redirected to complete the payment.") }</p>
                            <p>{ __("If the payment page has not opened automatically, click on the following button.") }</p>
                            <br></br>
                            <div className={'button-container'}>
                                <button
                                    onClick={() => {
                                        let link = localStorage.getItem("payment_link");
                                        window.open(link);
                                    }}
                                    className={'Button'}
                                >
                                    <b>{ __("Open payment page") }</b>
                                </button>
                            </div>
                            <br></br>
                        </div>
                    </>;
                    break;
                case "mbway":
                    message = <>
                            <p>{ __("Enter yout MBWAY app and accept the payment.") }</p>
                        </>;
                    break;
                default:
                    message = <>
                            <p>{ __("We remind you that you have 24 hours, after order confirmation, to make the payment at an ATM or over the internet through home banking services.") }</p>
                        </>;
                    break;
            }
            return message;
        }
    }

    checkoutSuccessTextToHtml(){
        const {checkoutSuccessText} = this.props;
        return (
            <div block="CheckoutSuccess" elem="Text" dangerouslySetInnerHTML={{__html: checkoutSuccessText }}/>
        );
    }

    render() {
        const {
            orderID,
            shippingAddress,
            billingAddress,
            paymentMethod,
            shippingMethod,
            is_wallet,
            showPreferencePopup,
            updateCustomerPharmacyPreference,
            isSignedIn,
            customer: {
                favourite_pharmacy_code,
                preference_pharmacy_code
            }
        } = this.props;

        let popUp = '';
        if(isSignedIn && favourite_pharmacy_code != preference_pharmacy_code) {
            popUp = <>
                <Popup
                    id={ 'PharmacyPreferenceChange' }
                    clickOutside={ false }
                    mix={ { block: 'PharmacyPreferenceChange' } }
                    closedOn={ false }
                >
                    <header class="Popup-Header">
                        <h3 class="Popup-Heading">
                            <Image src={ delete_icon } width="35px" height="35px" />
                            <br/>
                            { __('You finished an order with a different pharmacy.') }
                        </h3>
                    </header>
                    <p>{ __('Do you want to make it your pharmacy preference?') }</p>
                    <div block="PharmacyPreferenceChange" elem="Actions">
                        <button
                            block="PharmacyPreferenceChange"
                            elem="PrimaryButton"
                            mix={ { block: 'Button' } }
                            onClick={ () => showPreferencePopup() }
                        >{ __('No') }</button>
                    </div>
                    <div block="PharmacyPreferenceChange" elem="Actions" >
                        <button
                            block="PharmacyPreferenceChange"
                            elem="SecondaryButton"
                            mix={ { block: 'Button' } }
                            onClick={ () => updateCustomerPharmacyPreference(favourite_pharmacy_code) }
                        >{ __('Yes') }</button>
                    </div>
                </Popup>
            </>;
        }

        return (
            <div block="CheckoutSuccess">
                { popUp }
                <div block="CheckoutSuccess" elem="Content">
                    <div block="CheckoutSuccess" elem="Summary">
                        <div block="CheckoutSuccess" elem="InfoNotice">
                            <span>{ __('Thank you for your order, which was assigned the ') } <strong> { __("N.º ") } { orderID }.</strong></span>
                            { is_wallet && <>
                                <div block="CheckoutSuccess" elem="InfoDescription">
                                    <Image
                                        src={ erroImg }
                                        width="20px"
                                        height="20px"
                                    />
                                    <span block="CheckoutSuccess" elem="AddressRoute">
                                        <CmsBlock identifier={ "checkout_success_wallet_notice" } />
                                    </span>
                                </div>
                            <div block="CheckoutSuccess" elem="IconInfo">
                                <span>{ __('After finalizing the order, you will receive a contact from your pharmacist.') }</span>
                            </div>
                            </> }
                            { this.renderMessageByPayment() }
                            { this.checkoutSuccessTextToHtml() }
                        </div>
                        <div block="CheckoutSuccess" elem="Separator"></div>
                        <div block="CheckoutSuccess" elem="Payment">
                            <h3 block="CheckoutSuccess-Payment" elem="InfoName">
                                { __('Payment data') }
                            </h3>
                            <div block="CheckoutSuccess" elem="Info">
                                { this.renderPayment() }
                            </div>
                        </div>
                        <div block="CheckoutSuccess" elem="Separator"></div>
                        <div block="CheckoutSuccess" elem="Delivery">
                            <h3 block="CheckoutSuccess-Payment" elem="InfoName">
                                { __('Delivery data') }
                            </h3>
                            <div block="CheckoutSuccess" elem="Info">
                                { this.renderDelivery() }
                            </div>
                        </div>
                    </div>
                </div>
                <div block="CheckoutSuccess" elem="Button">
                    <Link
                        block="CheckoutSuccess"
                        elem="GoOrders"
                        to="/my-account/my-orders"
                        mix={ { block: 'Button' } }
                    >
                        <b>{ __('Go to my Orders') }</b>
                    </Link>
                    <Link
                        block="CheckoutSuccess"
                        elem="GoHome"
                        to="/"
                        mix={ { block: 'Button' } }
                    >
                        <b>{ __('Return to home page') }</b>
                    </Link>
                </div>
            </div>
        );
    }
};

export default CheckoutSuccess;
