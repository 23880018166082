import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchQuery } from 'Util/Request';
import PropTypes from 'prop-types';
import {
    ProductListContainer as SourceProductListContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/ProductList/ProductList.container';
import ProductListQuery from 'Query/ProductList.query';
import { appendWithStoreCode } from 'Util/Url';
import history from 'Util/History';
export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch)
});

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    favStoreObj: state.StorePageReducer.favStoreObj,
    isInfoLoading: state.ProductListInfoReducer.isLoading
});

export class ProductListContainer extends SourceProductListContainer {
    // TODO implement logic
    state = {
        countRequestProducts: 0
    }

    static propTypes = {
        magentoPageSize: PropTypes.number,
        updateBreadcrumbs: PropTypes.func.isRequired
    }

    static defaultProps = {
        pageSize: 24,
        filter: {},
        search: '',
        selectedFilters: {},
        sort: undefined,
        isPreventRequest: false,
        isPaginationEnabled: true,
        isInfiniteLoaderEnabled: false,
        isPageLoading: false,
        noAttributes: false,
        noVariants: false,
        isWidget: false,
        magentoPageSize: 12
    };

    componentDidUpdate(prevProps) {
        const { favStoreObj, sort, search, filter, customer, totalItems, isInfoLoading } = this.props;
        const { favStoreObj: prevFavStoreObj, sort: prevSort, search: prevSearch, filter: prevFilter, totalItems: prevTotalItems } = prevProps;

        const { pages } = this.props;
        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        let requestInfoFlag = true;

        if (JSON.stringify(filter) !== JSON.stringify(prevFilter)) {
            this.requestPage(this._getPageFromUrl(), false, requestInfoFlag);
            requestInfoFlag = false;
        }
        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }
        if (search !== prevSearch
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            this.requestPage(this._getPageFromUrl(), false, requestInfoFlag);
            requestInfoFlag = false;
        }
        if (favStoreObj && prevFavStoreObj) {
            const { pharmacy_code } = favStoreObj;
            const { pharmacy_code: prevPharmacy } = prevFavStoreObj;

            if (pharmacy_code != prevPharmacy) {
                this.requestPage(this._getPageFromUrl(), false, requestInfoFlag);
                requestInfoFlag = false;
            }
        }
        if (prevProps.customer != customer) {
            this.requestPage(this._getPageFromUrl(), false, requestInfoFlag);
            requestInfoFlag = false;
        }
        if (this.state.countRequestProducts == 0) {
            this.requestPage(this._getPageFromUrl(), false, requestInfoFlag);
            requestInfoFlag = false;
            this.state.countRequestProducts++;
        }

        if(localStorage.getItem('account_confirmed')){
            localStorage.removeItem('account_confirmed');
            window.location.reload();
        }
    }

    requestPage = (currentPage = 1, isNext = false, requestInfo = true) => {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            magentoPageSize,
            customer,
            isInfoLoading
        } = this.props;



        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        let pharma = null;
        if(customer && customer.favourite_pharmacy_code) {
            pharma = customer.favourite_pharmacy_code;
        } else {
            if (localStorage.getItem('guest_pharmacy_code')) {
                pharma = localStorage.getItem('guest_pharmacy_code');
            }
        }


        let pricesDate = null;

        if(pharma){
            let dateNow = new Date(Date.now());
            pricesDate = dateNow.getDay()+'-'+dateNow.getMonth()+'-'+dateNow.getFullYear()+'-'+dateNow.getHours()+'-'+dateNow.getMinutes();
        }


        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort,
                filter,
                search,
                pageSize,
                currentPage,
                pharma,
                pricesDate
            }
        };

        const infoOptions = {
            args: {
                filter,
                search,
                pharma,
                pricesDate
            }
        };
        //if (pharma != null) {
            requestProductList(options);
            requestProductListInfo(infoOptions);
        //} else {
          //  console.log('page return 3');
        //}
    };

    _getPagesBounds() {
        const { pages, totalItems, pageSize, magentoPageSize } = this.props;
        const keys = Object.keys(pages);

        return {
            maxPage: Math.max(...keys),
            minPage: Math.min(...keys),
            totalPages: Math.ceil(totalItems / pageSize),
            loadedPagesCount: keys.length
        };
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
