import {
    MyAccountPasswordForm as SourceMyAccountPasswordForm,
} from 'SourceComponent/MyAccountPasswordForm/MyAccountPasswordForm.component';

import { customerType } from 'Type/Account';

import PropTypes from 'prop-types';
import Field from 'Component/Field';
import Form from 'Component/Form';

import './MyAccountPasswordForm.style.override'

export class MyAccountPasswordForm extends SourceMyAccountPasswordForm {

    static propTypes = {
        customer: customerType.isRequired,
    };

    scrollToElement(e) {
        if (e.target) {
            window.scrollTo(0, (e.target.offsetTop + 82)); // header height: 82px;
        }
    }

    onFormSuccess = (fields) => {
        const { onPasswordChange } = this.props;
        onPasswordChange(fields);
    };

    get fieldMap() {
        const { customer } = this.props;

        return {
            // email: {
            //     type: 'email',
            //     label: __('Email'),
            //     value: customer.email,
            //     isDisabled:true,
            //     // validation: ['notEmpty']
            // },
          //  currentPassword: {
          //      type: 'password',
          //      label: __('Current Password'),
          //      validation: ['notEmpty']
          //  },
            newPassword: {
                type: 'password',
                label: __('New password'),
                validation: ['notEmpty', 'password'],
                onFocus: this.scrollToElement
            },
            newPasswordRepeat: {
                type: 'password',
                label: __('Confirm new password'),
                validation: ['notEmpty', 'password', 'new_password_match'],
                onFocus: this.scrollToElement
            }
        };
    }

    renderEmail() {
        const { customer } = this.props;

        const email = [
            "email",
            {
                type: 'email',
                label: __('Email'),
                value: customer.email,
                isDisabled:true,
                full_line: true
            }
        ];
        const password = [
            "currentPassword",
            {
                type: 'password',
                label: __('Current Password'),
                validation: ['notEmpty'],
                full_line: true
            }
        ];
        return (
            <>
            <div
              block="FieldForm"
              elem="Fields-email"
            >
                <Field { ...this.getDefaultValues(email) }/>
            </div>
              <div
              block="FieldForm"
              elem="Fields-password"
            >
                 <Field { ...this.getDefaultValues(password) }/>
            </div>
            </>
        );
    }

    renderActions() {
        const { showEditForm } = this.props;
        return (
            <>
                <button block="Button" mix={ { block: 'MyAccount', elem: 'Button' } }>
                    { __('Save Changes') }
                </button>
                <button
                    block="Button"
                    mix={ { block: 'MyAccount', elem: 'Cancel' }  }
                    onClick={ showEditForm }
                >
                    { __('Cancel') }
                </button>
            </>
        );
    }

    render() {
        return (
            <Form
              onSubmitSuccess={ this.onFormSuccess }
              mix={ { block: 'FieldForm' } }
            >
                { this.renderEmail() }
                { this.renderFieldsPassword() }
                { this.renderActions() }
            </Form>
        );
    }
};

export default MyAccountPasswordForm;
