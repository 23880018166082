/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 export const STATE_SIGN_IN = 'signIn';
 export const STATE_FORGOT_PASSWORD = 'forgotPassword';
 export const STATE_FORGOT_PASSWORD_SUCCESS = 'forgotPasswordSuccess';
 export const STATE_CREATE_ACCOUNT = 'createAccount';
 export const STATE_LOGGED_IN = 'loggedIn';
 export const STATE_CONFIRM_EMAIL = 'confirmEmail';
 export const STATE_FIRST_LOGIN = 'firstLogin';
 
 export const CUSTOMER_ACCOUNT_OVERLAY_KEY = 'customer_account';
 