import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountCustomerTableContainer as SourceMyAccountCustomerTableContainer,
    mapStateToProps as SourceMapStateToProps,
} from 'SourceComponent/MyAccountCustomerTable/MyAccountCustomerTable.container';


import {
    CHANGE_PASSWORD,
    CUSTOMER_POPUP_ID,
    EDIT_CUSTOMER
} from 'Component/MyAccountCustomerPopup/MyAccountCustomerPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { customerType } from 'Type/Account';
import { showNotification } from 'Store/Notification/Notification.action';

import MyAccountCustomerTable from './MyAccountCustomerTable.component';
import PropTypes from 'prop-types';



export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
    showPopup: (payload) => dispatch(showPopup(CUSTOMER_POPUP_ID, payload)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});


export const mapStateToProps = (state) => ({});

export class MyAccountCustomerTableContainer extends SourceMyAccountCustomerTableContainer {
    // TODO implement logic
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        FormTitle: PropTypes.string.isRequired,
        disableTables: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        FormTitle : "Customer"
    };

    state = {
        showHideForm: false
    };

    containerFunctions = {
        showEditPopup: this.showEditPopup.bind(this),
        showChangePasswordPopup: this.showChangePasswordPopup.bind(this),
        toggleEditForm: this.toggleEditForm.bind(this)
    };

    toggleEditForm(){
        const { disableTables , hideTables, customer } = this.props;
        this.setState({
           showHideForm: !this.state.showHideForm
        });
        disableTables(!hideTables);
        window.scrollTo(0,0);
    }

    phoneWithSpaces(value, pattern) {
        let i = 0;
        let phone = value.replace(/\s+/g, '');
        phone = phone.toString();
        return pattern.replace(/#/g, _ => phone[i++]);
    }

    showEditPopup() {
        const { showPopup, customer } = this.props;
        showPopup({
            action: EDIT_CUSTOMER,
            customer,
            title: __('Edit customer details')
        });
    }

    showChangePasswordPopup() {
        const { showPopup, customer} = this.props;

        showPopup({
            action: CHANGE_PASSWORD,
            customer,
            title: __('Change password')
        });
    }

    render() {
        return (
            <MyAccountCustomerTable
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCustomerTableContainer);
