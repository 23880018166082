import {
    UPDATE_META as SourceUPDATE_META,
    updateMeta as SourceUpdateMeta,
} from 'SourceStore/Meta/Meta.action';

//TODO: implement UPDATE_META
export const UPDATE_META = SourceUPDATE_META;

//TODO: implement updateMeta
export const updateMeta = SourceUpdateMeta;
