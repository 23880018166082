import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    MyAccountAddressBookContainer as SourceMyAccountAddressBookContainer,
} from 'SourceComponent/MyAccountAddressBook/MyAccountAddressBook.container';
import PropTypes from 'prop-types';
import { addressType } from 'Type/Account';
import { customerType } from 'Type/Account';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import MyAccountAddressBook from './MyAccountAddressBook.component';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateMeta } from 'Store/Meta/Meta.action';


export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    // TODO extend mapStateToProps,
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta))
    // TODO extend mapDispatchToProps
});

export class MyAccountAddressBookContainer extends SourceMyAccountAddressBookContainer {
    static propTypes = {
        customer: customerType.isRequired,
        showPopup: PropTypes.func.isRequired,
        FormTitle: PropTypes.string.isRequired,
        address: addressType.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        FormTitle : "Address"
    };

    state = {
        showHideForm: false,
        title: '',
        page: 1,
        limit: 10
    };

    containerFunctions = {
        getDefaultPostfix: this.getDefaultPostfix.bind(this),
        showCreateNewPopup: this.showCreateNewPopup.bind(this),
        showEditForm: this.showEditForm.bind(this),
        onPageChange: this.onPageChange.bind(this)
    };

    componentDidMount() {
        const { customer: { addresses = [] }, updateMeta } = this.props;
        updateMeta({ title: __('Endereços') });
    }

    onPageChange(page) {
        this.setState({ page });
    }

    showEditForm(title,address = []) {
        this.setState({
            title_page: title,
            address: address
        }, () => {
            this.setState({showHideForm:!this.state.showHideForm});
        });
        window.scrollTo(0,0);
    }

    getDefaultPostfix(address) {
        const { default_billing, default_shipping } = address;
        if (!default_billing && !default_shipping) {
            return '';
        }
        if (default_billing && default_shipping) {
            return (
                <>
                    <FontAwesomeIcon className="MyAccountAddressBook-CheckIcon" icon={faCheck} />
                    { __(' default shipping, billing address') }
                </>
            );
        }
        if (default_billing) {
            return (
                <>
                    <FontAwesomeIcon className="MyAccountAddressBook-CheckIcon" icon={faCheck} />
                    { __(' default billing address') }
                </>
            );
        }

        return (
            <>
                <FontAwesomeIcon className="MyAccountAddressBook-CheckIcon" icon={faCheck} />
                { __(' default shipping address') }
            </>
        );
    }
    render() {
        return (
            <MyAccountAddressBook
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressBookContainer);
