/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import { UPDATE_POSTS } from './PostsByTag.action';

 export const emptyPosts = {
    items: [],
    count: 0
 };
 
 export const initialState = {
    postsByTag: emptyPosts
 };
 
 /** @namespace ScandiPWA/Blog/Store/Posts/Reducer/postsReducer */
 export const PostsByTagReducer = (state = initialState, action) => {
     switch (action.type) {
     case UPDATE_POSTS:
         const {
            posts: {
                items,
                total_count: count
            },
            isLoaded
         } = action;
 
         return {
            ...state,
            postsByTag: {
                items,
                count,
                isLoaded
             }
         };
     default:
         return state;
     }
 };
 
 export default PostsByTagReducer;
 