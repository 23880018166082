/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_STORE_FINDER = 'UPDATE_STORE_FINDER';
export const UPDATE_STORE_SERVICES = 'UPDATE_STORE_SERVICES';
export const UPDATE_STORE_SELECTOR = 'UPDATE_STORE_SELECTOR';

/**
 * Update Store Finder information
 * @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Action/updateStoreFinder
 */
export const updateStoreFinder = storeList => ({
    type: UPDATE_STORE_FINDER,
    storeList
});

/**
 * Update Store Selector information
 * @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Action/updateStoreSelector
 */
export const updateStoreSelector = storeSelectorList => ({
    type: UPDATE_STORE_SELECTOR,
    storeSelectorList
});

/**
 * Update available services
 * @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Action/updateStoreServices
 */
export const updateStoreServices = serviceList => ({
    type: UPDATE_STORE_SERVICES,
    serviceList
});