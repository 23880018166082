import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MyAccountPageManager from 'Component/MyAccountPageManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import {  faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import MyAccountPrescriptionPopup from 'Component/MyAccountPrescriptionPopup';
import MyAccountPrescriptionCard from 'Component/MyAccountPrescriptionCard';
import { DeviceType } from 'Type/Device';
import CmsBlock from 'Component/CmsBlock';
import './PrescriptionWallet.style.scss';
import PaginationView from 'Component/Pagination';
import LeftIcon from 'Route/StoreFinder/images/left.svg';
import RightIcon from 'Route/StoreFinder/images/right.svg';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import WarningImage from "./Images/icon-modal@2x.png";

export class PrescriptionWallet extends PureComponent {
    static propTypes = {
        showEditForm: PropTypes.func.isRequired,
        showHideForm: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
        prescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
        onDeleteClick: PropTypes.func.isRequired,
        onPrescriptionAddSuccess: PropTypes.func.isRequired,
        resetPage: PropTypes.func.isRequired
    };

    state = {
        seeAll: false,
        limit: 10
    };

    renderPopup() {
        return <MyAccountPrescriptionPopup />;
    }

    renderPopupDelete() {
        const { onDeleteClick } = this.props
        return (
            <>
                <div block="MyAccountPrescription"></div>
                <Popup
                    id={ 'MyAccountPrescriptionDetailsDelete' }
                    clickOutside={ true }
                    mix={ { block: 'MyAccountPrescription' } }
                    closedOn={ false }
                >
                    <FontAwesomeIcon className="MyAccountPrescription-Close" onClick={ onDeleteClick } icon={ faTimes } />
                </Popup>
            </>
        );
    }

    calcPagination(viewList){
        const { pageNumber } = this.props;

        const prescriptionsInPage = viewList.filter((prescription, index) => {
			return (index < pageNumber * this.state.limit);
        });
        return prescriptionsInPage;
    }

    renderPrescriptionList() {
        const { isLoading, prescriptions, onPageChange, pageNumber } = this.props;
        const { seeAll } = this.state;

        let viewList = prescriptions;
        let completeList = prescriptions.length;

        if(!seeAll) {
            viewList = prescriptions.filter(prescription => {
                let isExpired = prescription.status == 'Dispensed/Expired';
                if (prescription.forfeit && prescription.forfeit != "") {
                    let date = new Date();
                    date.setDate(date.getDate()-1);
                    let fParts = prescription.forfeit.split('-');
                    let fDate = new Date(fParts[2], parseInt(fParts[1]) - 1, parseInt(fParts[0]));
                    isExpired = prescription.status == 'Dispensed/Expired' || fDate < date;
                }
                return !isExpired;
            });

            completeList = viewList.length;
        }

        if (isLoading) {
            return (<></>);
        }

        if (!viewList.length) {
            return this.renderNoPrescriptions();
        }

        viewList = this.calcPagination(viewList);

        const count = Math.ceil(completeList / this.state.limit);

        return (
            <>
                <div>
                    { viewList.map(this.renderPrescription) }
                </div>
             
               {
                    pageNumber < count &&
                    <div block="PrescriptionWallet" elem="ShowMore">
                        <button
                            block="PrescriptionWallet"
                            elem="SelectButton"
                            mix={ { block: 'Button' } }
                            onClick={ () => { onPageChange({selected:pageNumber}) } }
                        >{ __('Show more results') }</button>
                    </div>
                }
            </>
        );
    }

    renderPrescription = (prescription, index) => {
        const { showEditForm } = this.props;
        const prescriptionNumber = prescription.number;

        let title = prescription.medical_speciality ? prescription.medical_speciality : __('No Especiality'); //__('Prescription #%s', prescriptionNumber);


        return (
            <MyAccountPrescriptionCard
              title={ title }
              showActions
              prescription={ prescription }
              showEditForm={ showEditForm }
              key={ index }
            />
        );
    };

    renderNoPrescriptions() {
        return (
            <div>
                 <div block="PrescriptionWallet" elem="Popup-Content">
                    <CmsBlock identifier={ "PrescriptionTutorial" } />
                </div>
            </div>
        );
    }

    renderPageManager(button) {
        const { title_page } = this.props;
        let isEdit = true;
        let pageType = "PrescriptionWallet";

        if(title_page == __('Details from Prescription')) {
            pageType = 'PrescriptionDetails';
        }

        if(title_page == __('Add Prescription') || title_page == __('Details from Prescription')) {
            return (
                <>
                    { pageType == 'PrescriptionDetails' && <h1 block="MyAccount" elem="Heading" mods={{isEdit}} mix={ { block: 'PrescriptionWallet' ,elem:'WalletTab' } }>
                        <div block="PrescriptionWallet" elem="HeadingButton">
                            { button } { title_page }
                        </div>
                        { this.renderDeleteIcon() }
                    </h1> }
                    { !(pageType == 'PrescriptionDetails') && <h1 block="MyAccount" elem="Heading" mods={{ isEdit, PrescriptionWalletHeading:true }} >{ button } { title_page }</h1> }

                    <div block= { pageType }>
                        <MyAccountPageManager
                            { ...this.props }
                        />
                    </div>
                </>
            );
        }
    }

    renderDeleteIcon() {
        const { onDelete, onDeleteClick } = this.props;

        return (
            <div block="PrescriptionWallet" elem="ActionDelete">
                <button block="PrescriptionWallet" elem="DeleteButton" onClick={ onDeleteClick }>
                    <span block="PrescriptionWallet" elem="DeleteIcon">{ __("Delete prescription") }</span>
                    <FontAwesomeIcon className="fa-2x" icon={ faTrashAlt } />
                </button>
                <div block="MyAccountPrescriptionDetails">
                    <Popup
                        id={ 'MyAccountPrescriptionDetailsDelete' }
                        clickOutside={ true }
                        mix={ { block: 'MyAccountPrescriptionDetails' } }
                        closedOn={ false }
                    >
                        <div block="PrescriptionWallet" elem="CloseBtn">
                            <FontAwesomeIcon className="PrescriptionWallet-Close" onClick={ onDeleteClick } icon={ faTimes } />
                        </div>
                        <div block="PrescriptionWallet" elem="Warning">
                            <Image
                                src={ WarningImage }
                                width="60px"
                                height="60px"
                                />
                        </div>
                        <div block="PrescriptionWallet" elem="Title">
                            <p>{ __("Are you sure you want to discard this prescription?") }</p>
                        </div>
                        <div>
                            <button block="PrescriptionWallet" elem="ConfirmButtonPopup" mix={ { block: 'Button' } } onClick={ onDelete }>
                                <span>{ __('Yes, delete') }</span>
                            </button>
                        </div>
                        <div>
                        <button block="PrescriptionWallet" elem="CancelButtonPopup" mix={ { block: 'Button' } } onClick={ onDeleteClick }>
                            <span>{ __('Cancel') }</span>
                        </button>
                        </div>
                    </Popup>
                </div>
            </div>
        );
    }

    controlList = () => {
        this.setState({
            seeAll: !this.state.seeAll
        });

        const { resetPage } = this.props;
        resetPage();
    }

    renderActionsIcons() {
        const { seeAll } = this.state;
        return (
            <div block="PrescriptionWallet" elem="ActionsIcons">
                <div block="PrescriptionWallet" elem="FilterAll">{ __("See All") }</div>
                <button
                  block="CartItem"
                  elem="TogglePoints"
                  onClick={ this.controlList }
                  mods={ { isActive: seeAll } }
                >
                    <span></span>
                </button>
            </div>
        );
    }

    renderContent(button){
        const { device } = this.props; // Mobile Device
        let divActions =
                <div block="MyAccount" elem="Actions" mods={ { PrescriptionWalletActions:true } }>
                    { this.renderActions() }
                </div>
        return (
            <>
            <h1 block="MyAccount" elem="Heading" mix={ { block: 'PrescriptionWallet' ,elem:'WalletTab' } }>{ button }{ __('Prescription Wallet') }{ this.renderActionsIcons() }</h1>
                <div block="PrescriptionWallet">
                { this.renderPrescriptionList() }
                { this.renderPopup() }
                { this.renderPopupDelete() }
                { device.isMobile?null:divActions }   {/* Desktop div */}
                </div>
                { device.isMobile?divActions:null }   {/* Mobile div */}
            </>
        );
    }

    clickAddPrescription = () => {
        const { showEditForm} = this.props;

        showEditForm(__('Add Prescription'),'Prescription');
    }


    renderActions() {
        return (
            <button
              block="PrescriptionWallet"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ this.clickAddPrescription }
            >
                { __('Add new prescription') }
            </button>
        );
    }

    render() {
        const { title, showHideForm, showEditForm, isLoading }  = this.props;

        let button;
        if(showHideForm) {
            button = <button
                        block="MyAccount"
                        elem="BackButton"
                        onClick={ showEditForm }
                    >
                        <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                        <span>{ __('Back') }</span>
                    </button>
        }

        return (
            <>
                <Loader isLoading={ isLoading } />
                { showHideForm ? this.renderPageManager(button) : this.renderContent(button) }
            </>
        );
    }
}

export default PrescriptionWallet;
