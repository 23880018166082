/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Slider Query
 * @class Slider
 * @namespace Query/Slider
 */
export class SliderQuery {
    getQuery(options) {
        const { sliderId } = options;
        let currentTimestamp = Math.floor(Date.now() / 1000);

        return new Field('getScandiwebSlider')
            .addArgument('id', 'ID!', sliderId)
            .addArgument('timestamp', 'String', currentTimestamp)
            .addFieldList(this._getSliderFields())
            .setAlias('slider');
    }

    _getSliderFields() {
        return [
            this._getSlidesField(),
            this._getSlideSpeedField(),
            'slider_id',
            'title',
            'show_menu',
            'show_navigation',
            'slides_to_display',
            'slides_to_scroll',
            'lazy_load',
            'slides_to_display_tablet',
            'slides_to_scroll_tablet',
            'slides_to_display_mobile',
            'slides_to_scroll_mobile'
        ];
    }

    _getSlideFields() {
        return [
            'slide_text',
            'slide_id',
            'mobile_image',
            'desktop_image',
            'title',
            'is_active',
            'is_sauda',
            'slide_link',
            'slide_link_target',
            'slide_link_text'
        ];
    }

    _getSlidesField() {
        return new Field('slides')
            .addFieldList(this._getSlideFields());
    }

    _getSlideSpeedField() {
        return new Field('slide_speed').setAlias('slideSpeed');
    }
}

export default new SliderQuery();
