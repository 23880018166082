import {
    CheckoutDeliveryOption as SourceCheckoutDeliveryOption,
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt } from '@fortawesome/pro-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import home from './images/home.png';
import home_white from './images/home_white.png';
import pharmacy from './images/pharmacy.png';
import pharmacy_white from './images/pharmacy_white.png';

import './CheckoutDeliveryOption.style.override'

export class CheckoutDeliveryOption extends SourceCheckoutDeliveryOption {

    renderRow() {
        const {
            option: {
                carrier_title,
                carrier_code,
                method_title
            },
            onClick,
            isSelected,
            option
        } = this.props;

        let icon = home;

        if(isSelected) {
            icon = home_white;
            onClick(option);
        }
        if(carrier_code == 'pharmacy_pickup') {
            icon = pharmacy;

            if(isSelected) {
                icon = pharmacy_white;
            }
        }

        return (
            <div block="CheckoutDeliveryOption" elem="Row">
                <div>
                    <img
                        block="CheckoutDeliveryOption"
                        elem="Icon"
                        src={ icon }
                        width="40px"
                        height="40px"
                    />
                </div>
                <div>
                    <strong>{ carrier_title }</strong>
                </div>
                <div block="CheckoutDeliveryOption" elem="Check">
                    <FontAwesomeIcon icon={ faCheck } />
                </div>
            </div>
        );
    }

};

export default CheckoutDeliveryOption;
