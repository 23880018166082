/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import KeyValueTable from 'Component/KeyValueTable';
import { customerType } from 'Type/Account';
import MyAccountPageManager from 'Component/MyAccountPageManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faEdit, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { COPAYMENT_POPUP_ID } from 'Component/MyAccountPageManager/MyAccountPageManager.config';
import Popup from 'Component/Popup';
import Loader from 'Component/Loader';

import './MyAccountCoPaymentsTable.style';

/** @namespace Component/MyAccountCoPaymentsTable/Component */
export class MyAccountCoPaymentsTable extends KeyValueTable {
    static propTypes = {
        customer: customerType.isRequired,
        payload: PropTypes.shape({
            id: PropTypes.number
        }).isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    get dataPairArray() {
        const { customer } = this.props;
        const coPayment    = customer.customerCoPayment;
        return [
            {
                key: 'card_name',
                label: __('Card Name'),
                source: coPayment
            },
            {
                key: 'co_payment_system_number',
                label: __('Card Number'),
                source: coPayment
            }
        ];
    }

    renderActions() {
        const { showEditForm }  = this.props;

        return (
            <button
                block="Button"
                onClick={ showEditForm }
            >
                { __('Add Co-payment') }
            </button>
        );
    }

    renderContent() {
        const { hideTables }  = this.props;
        if(hideTables){
            return null;
        }

        return (
            <div block="MyAccountDashboard" elem="AddressesWrapper">
                <div
                    block="MyAccountDashboard"
                    elem="AddressesHolder"
                    mix={ { block: 'MyAccountDashboard', elem: 'CoPayment' } }
                >
                    <h3 block="MyAccountCustomerTable" elem="Title">{ __('Medicine Co-payments') }</h3>
                    <div block="MyAccountCoPaymentsTable">
                        { this.renderTable() }
                        { this.renderActions() }
                    </div>
                </div>
            </div>
        );
    }

    renderPageManager() {
        const { showEditForm, currentCoPayment }  = this.props;
        return (
            <>
                <h1 block="MyAccount" elem="Heading" mods={{ altDisplay: true }}>
                    <button
                        block="MyAccount"
                        elem="BackButton"
                        onClick={ showEditForm }
                    >
                        <FontAwesomeIcon className="MyAccount-BackButtonIcon" icon={faChevronLeft} />
                        { __('Back') }
                    </button>
                    { __('Add Co-payment') }
                </h1>
                <div block="MyAccountDashboard" elem="AddressesWrapper">
                    <div
                        block="MyAccountDashboard"
                        elem="AddressesHolder"
                        mix={ { block: 'MyAccountDashboard', elem: 'PasswordsHolder' } }
                    >
                        <div block="MyAccountCoPaymentsTable">
                            <MyAccountPageManager
                                currentCoPayment={currentCoPayment}
                                { ...this.props }
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderCoPaymentName(items,id){
        let coPayName = "vazio";
        items.forEach(function(item){
            if(id==item.code){
                coPayName = item.name;
                }
        });

        return (<>
            { coPayName }
            </>
        );

    }

    render() {
        const { showHideForm }  = this.props;

        return (
            <>
                { showHideForm ? this.renderPageManager() : this.renderContent() }
                { this.renderDeletePopup() }
            </>
        );
    }

    renderTable() {
        const { title, postfix } = this.props;

        return (
            <div block="MyAccountCoPaymentsTable" elem="InfoWrapper">
                { this.renderCoPaymentsInfo() }
            </div>
        );
    }

    renderCoPaymentsInfo() {
        const { customer } = this.props;
        const coPayment    = customer.customerCoPayment;

        if(coPayment) {
            return (
                    this.renderTableRow(coPayment)
            );
        }

    }

    renderTableRow = (coPayment) => {
        const { customer } = this.props;

        const items = coPayment.map((item) =>
            <div block="MyAccountCoPaymentsTable" elem="CoPaymentHolder">
                <div>
                <div block="MyAccountCoPaymentsTable" elem="UserInfoBlock">
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoTitle">
                        { __('Card Name') }
                    </p>
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoValue">
                        { item.card_name }
                    </p>
                </div>
                <div block="MyAccountCoPaymentsTable" elem="UserInfoBlock">
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoTitle">
                        { __('Co-Payment System') }
                    </p>
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoValue">
                        { this.renderCoPaymentName(customer.coPaymentList, item.co_payment_system_code) }
                    </p>
                </div>
                <div block="MyAccountCoPaymentsTable" elem="UserInfoBlock">
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoTitle">
                        { __('Number') }
                    </p>
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoValue">
                        { item.co_payment_system_number }
                    </p>
                </div>
                </div>
                <div>
                <div block="MyAccountCoPaymentsTable" elem="UserInfoBlock">
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoTitle">
                        { __('Co-Payment Card Photo') }
                    </p>
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoValue">
                        <a href={ item.photo_card_front } download={ item.photo_card_front }>
                            { __('Front Photo') }
                        </a>
                    </p>
                    <p block="MyAccountCoPaymentsTable" elem="UserInfoValue">
                        <a href={ item.photo_card_back } download={ item.photo_card_back }>
                            { __('Back Photo') }
                        </a>
                    </p>
                </div>
                </div>
                { this.renderActionsIcons(item.entity_id) }
            </div>
        );

        return (items);
    }

    handleCoPaymentsClick = (id) => {
        const { showEditForm, customer } = this.props;
        const position = customer.customerCoPayment.findIndex(item => item.entity_id === id);
        showEditForm(customer.customerCoPayment[position]);
    }

    handleCoPaymentsDeleteClick = (id) => {
        this.setState({ isLoading: true });
        const { deleteItem, customer } = this.props;
        const position = customer.customerCoPayment.findIndex(item => item.entity_id === id);
        deleteItem(customer.customerCoPayment[position]);
    }


    renderPopupContent() {
        const { hideActiveOverlay, payload: { id } } = this.props;

        if(!id) {
            return null;
        }

        return (
            <>
                <div block="MyAccountCoPaymentsTable" elem="PopupActions">
                    <button block="Button" onClick={ () => this.handleCoPaymentsDeleteClick(id) }>
                        { __('Yes, delete co-payment') }
                    </button>
                </div>
                <div block="MyAccountCoPaymentsTable" elem="PopupActions">
                    <button block="Button" mix={ { block: 'MyAccount', elem: 'Cancel' }  } onClick={ hideActiveOverlay }>
                        { __('Cancel') }
                    </button>
                </div>
            </>
        );
    }

    renderDeletePopup() {
        const { isLoading } = this.props;
        return (
            <Popup
              id={ COPAYMENT_POPUP_ID }
              clickOutside={ true }
              mix={ { block: 'MyAccountCoPaymentPopup' } }
            >
                <Loader isLoading={ isLoading } />
                { this.renderPopupContent() }
            </Popup>
        );
    }

    renderActionsIcons(id) {
        const {
            onDeleteClick
        } = this.props;

        return (
            <div block="MyAccountCoPaymentsTable" elem="ActionsIcons">
                <button
                  block="Button"
                  mix={ { block: 'MyAccountCoPaymentsTable', elem: 'ActionIcon' } }
                  title={ __('Edit') }
                  onClick={ () => this.handleCoPaymentsClick(id) }
                >
                    <FontAwesomeIcon className="fa" icon={faEdit} />
                </button>
                <button
                  block="Button"
                  mods={ { isHollow: true } }
                  mix={ { block: 'MyAccountCoPaymentsTable', elem: 'ActionIcon' } }
                  title={ __('Delete') }
                  onClick={ () => onDeleteClick(id) }
                >
                    <FontAwesomeIcon className="fa" icon={faTrashAlt} />
                </button>
            </div>
        );
    }
}

export default MyAccountCoPaymentsTable;
