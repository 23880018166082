/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */
import { QueryDispatcher, executePost } from 'Util/Request';

import { prepareQuery } from 'Util/Query';
import { updateStorePage, updateFavStore, updateLoading } from '.';
import StorePageQuery from '../../query/StorePage.query';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/**
 * Store Page Dispatcher
 * @class StorePageDispatcher
 * @extends QueryDispatcher
 * @namespace Store/StorePage/Dispatcher
 */
export class StorePageDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ getPharmacies }, dispatch) {
        dispatch(updateStorePage(getPharmacies));
    }

    onError(error, dispatch) {
        console.log(error);
    }

    requestStore(dispatch, id) {
        const query = StorePageQuery.getQuery(parseInt(id));

        dispatch(updateLoading(true));
        return executePost(prepareQuery([query])).then(
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StorePage/Dispatcher/executePostThen */
            ({ getPharmacies }) => {
                if (getPharmacies && getPharmacies[0]) {
                    dispatch(updateStorePage(getPharmacies[0]));
                } else {
                    history.push({ pathname: appendWithStoreCode('/localizador-de-farmacias') });
                }
            },
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StorePage/Dispatcher/executePostThen */
            error => {
                console.log('error', error[0].message);
                history.push({ pathname: appendWithStoreCode('/404') });
            }
        );
    }

    requestFavStore(dispatch, id) {
        const query = StorePageQuery.getQuery(id);

        dispatch(updateLoading(true));
        return executePost(prepareQuery([query])).then(
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StorePage/Dispatcher/executePostThen */
            ({ getPharmacies }) => {
                if (getPharmacies && getPharmacies[0]) {
                    dispatch(updateFavStore(getPharmacies[0]));
                }
            },
            /** @namespace Scandiweb/StoreFinderGraphQl/Store/StorePage/Dispatcher/executePostThen */
            error => console.log('error', error[0].message)
        );
    }
}

export default new (StorePageDispatcher)();
