import {
    ProductCustomizableOptions as SourceProductCustomizableOptions,
} from 'SourceComponent/ProductCustomizableOptions/ProductCustomizableOptions.component';

import './ProductCustomizableOptions.style.override'

export class ProductCustomizableOptions extends SourceProductCustomizableOptions {
    // TODO implement logic
};

export default ProductCustomizableOptions;
