import {
    NewsletterSubscription as SourceNewsletterSubscription,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import './NewsletterSubscription.style.override.scss'

import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm/FieldForm.component';
import Form from 'Component/Form';
import Field from 'Component/Field';

export class NewsletterSubscription extends SourceNewsletterSubscription {
    // TODO implement logic
    static propTypes = {
        onFormSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    get fieldMap() {
        const fields = {
            email: {
                validation: ['notEmpty', 'email'],
                placeholder: __('Enter your email address')
            }
        };

        return fields;
    }

    renderActions() {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'FieldForm', elem: 'Button' } }
              aria-label={ __('Submit') }
              style={{marginLeft: '-30px',width: '130px'}}
            >
            Subscrever
            </button>
        );
    }

    render() {
        const { isLoading, onFormSubmit } = this.props;
        return (
            <div block="NewsletterSubscription" style={{width:'360px'}}>
                <Form
                  onSubmitSuccess={ onFormSubmit }
                  mix={ { block: 'FieldForm' } }
                  style={{width:'330px'}}
                >
                    <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                        { this.renderFields() }
                        { this.renderActions() }
                    </div>
                    <Field
                        // id="sameAsShippingAddress"
                        // name="sameAsShippingAddress"
                        type="checkbox"
                        label={ __('Authorize lorem ipsum sitt dolor amet') }
                        value="sameAsShippingAddress"
                        // mix={ { block: 'CheckoutBilling', elem: 'Checkbox' } }
                        checked={ false }
                        // onChange={ onSameAsShippingChange }
                        />
                </Form>
            </div>
        );
    }
};

export default NewsletterSubscription;
