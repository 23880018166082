import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckoutSignin from './CheckoutSignin.component';
import { customerType } from 'Type/Account';
import { DeviceType } from 'Type/Device';
import {
    ADDRESS_BOOK,
    DASHBOARD,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION
} from 'Type/Account';
import { CUSTOMER_ACCOUNT, CUSTOMER_ACCOUNT_PAGE, CUSTOMER_WISHLIST } from 'Component/Header/Header.config';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { HistoryType, LocationType, MatchType } from 'Type/Common';
import { appendWithStoreCode } from 'Util/Url';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    payload: state.PopupReducer.popupPayload["MenuMobile"] || {},
    isSignedIn: state.MyAccountReducer.isSignedIn,
    favStoreObj: state.StorePageReducer.favStoreObj,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    device: state.ConfigReducer.device,
    wishlistItems: state.WishlistReducer.productsInWishlist
});

export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    toggleBreadcrumbs: (areBreadcrumbsVisible) => dispatch(toggleBreadcrumbs(areBreadcrumbsVisible))
});

export class CheckoutSigninContainer extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        customer: customerType.isRequired,
        payload: PropTypes.shape({
            id: PropTypes.number
        }).isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isMyAccount: PropTypes.bool,
        isCheckout: PropTypes.bool.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        requestCustomerData: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        match: MatchType.isRequired,
        location: LocationType.isRequired,
        history: HistoryType.isRequired,
        wishlistItems: PropTypes.object
    };

    state = {
        createAccount: false,
        guest_pharmacy_code: localStorage.getItem('guest_pharmacy_code')
    }

    containerFunctions = {
        onSignIn: this.onSignIn.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    componentDidMount() {
        const { history, customer, toggleBreadcrumbs } = this.props;

        if (customer && customer.email) {
            let redirectUrl = '/cart';

            if (sauda && customer.sauda_card) {
                const { is_active, status } = customer.sauda_card;
                const statusNotAllowed = ['PreCanceledAuto', 'PreCanceledBackOffice', 'Blocked'];
                if (is_active && !statusNotAllowed.includes(status)) {
                    redirectUrl = '/checkout-sauda';
                }
            }

            history.push(appendWithStoreCode(redirectUrl));
        }

        toggleBreadcrumbs(false);
    }

    componentDidUpdate() {
        const { history, customer, favStoreObj: { sauda }, } = this.props;

        if (customer && customer.email) {
            let redirectUrl = '/cart';

            if (sauda && customer.sauda_card) {
                const { is_active, status } = customer.sauda_card;
                const statusNotAllowed = ['PreCanceledAuto', 'PreCanceledBackOffice', 'Blocked'];
                if (is_active && !statusNotAllowed.includes(status)) {
                    redirectUrl = '/checkout-sauda';
                }
            }

            history.push(appendWithStoreCode(redirectUrl));
        }
    }

    checkCustomerLoggedIn() {
        
    }

    changeDefaultHeaderState() {
        const { changeHeaderState } = this.props;

        this.setState({createAccount: true});
    }

    changeHeaderState(activeTabParam) {
        const { activeTab: activeTabState } = this.state;
        const activeTab = activeTabParam || activeTabState;

        if (activeTab !== MY_WISHLIST) {
            this.changeDefaultHeaderState();

            return;
        }

        this.changeWishlistHeaderState();
    }

    changeWishlistHeaderState(hiddenElements = ['ok']) {
        const { changeHeaderState } = this.props;
        const { [MY_WISHLIST]: { headerTitle } } = this.tabMap;

        const handleClick = (isEdit = false) => {
            this.setState({ isEditingActive: isEdit });

            const hiddenElements = [isEdit ? 'edit' : 'ok'];

            this.changeWishlistHeaderState(hiddenElements);
        };

        changeHeaderState({
            title: headerTitle(),
            name: CUSTOMER_WISHLIST,
            onEditClick: () => handleClick(true),
            onOkClick: () => handleClick(),
            hiddenElements,
            shouldNotGoToPrevState: true
        });
    }

    onSignIn() {
        const {
            requestCustomerData,
            isSignedIn,
            history,
            customer
        } = this.props;

        if (isSignedIn) {
            requestCustomerData();
        }
    }

    render() {
        return (
            <CheckoutSignin
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSigninContainer);
