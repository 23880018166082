import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MyAccountPageManager from 'Component/MyAccountPageManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CmsBlock from 'Component/CmsBlock';
import HealthCardSms from 'Component/HealthCardSms';
import Popup from 'Component/Popup';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { customerType } from 'Type/Account';

import { DeviceType } from 'Type/Device';
import Link from 'Component/Link';

import './HealthCardWithoutLogin.style';
import '../HealthCard/HealthCard.style'


export class HealthCardWithoutLogin extends PureComponent {
    static propTypes = {
        showEditForm: PropTypes.func.isRequired,
        setLoading: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        saudaCardMatrix: PropTypes.arrayOf(PropTypes.string).isRequired,
        saudaCardPoints: PropTypes.arrayOf(PropTypes.string).isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        changeCreateCardSuccessState: PropTypes.func.isRequired,
        updateCustomer: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        associationFinish: PropTypes.bool.isRequired,
        setAssociationFinish: PropTypes.func.isRequired
    };

    state = {
        showCreateNewCard: false,
        phoneNumber: 0,
        cardDetails: {},
        positions: {},
        positionFirst: '',
        positionSecond: '',
        positionThird: '',
        showPopup: false,
        showPointsBlock: false,
        showSuccessAssociate: false,
        isBtnPointsActive: true,
        currentVoucher: false,
        currentCardNumber: false
    }

    static defaultProps = {
        FormTitle : "HealthCard"
    };

    renderPageManager() {
        const { phoneNumber } = this.state;
        const { showInfoPopup, changeCreateCardSuccessState } = this.props;

        return (
            <>
                <div block="HealthCard">
                <CmsBlock identifier={ "HealthCardAddCardDescription" } />
                <MyAccountPageManager
                    phoneNumber={ phoneNumber }
                    { ...this.props }
                    changeCreateCardSuccessState={ changeCreateCardSuccessState }
                />
                <Popup
                    id={ 'HealthCardConditions' }
                    clickOutside={ true }
                    mix={ { block: 'HealthCardConditions' } }
                    closedOn={ false }
                >
                    <FontAwesomeIcon className="HealthCard-Close" onClick={ showInfoPopup } icon={ faTimes } />
                    <div block="HealthCard" elem="Popup-Content">
                        <CmsBlock identifier={ "HealthCardConditions" } />
                    </div>
                </Popup>
                </div>
            </>
        );
    }

    renderSmsForm() {
        const { showInfoPopupPhone, customer } = this.props;
        const { phoneNumber } = this.state;

        return (
            <>
                <HealthCardSms
                    manageShowForm={ this.manageShowForm }
                    phoneNumber={ phoneNumber }
                    setPhoneNumber={ this.setPhoneNumber }
                    showPopupPhone={ showInfoPopupPhone }
                    setCardDetails={ this.setCardDetails }
                    customer={ customer }
                    step={2}
                />
                <Popup
                    id={ 'HealthCardPhone' }
                    clickOutside={ true }
                    mix={ { block: 'HealthCardPhone' } }
                    closedOn={ false }
                >
                    <FontAwesomeIcon className="HealthCard-Close" onClick={ showInfoPopupPhone } icon={ faTimes } />
                    <div block="HealthCard" elem="Popup-Content">
                        <CmsBlock identifier={ "HealthCardPhone" } />
                    </div>
                </Popup>
            </>
        );
    }

    setPhoneNumber = (phone) => {
        this.setState({ phoneNumber: phone });
    }

    setCardDetails = (details, positions) => {
        this.setState({
            cardDetails: details,
            positions: positions,
            showCreateNewCard: false,
            showAssociateCard: true
        });
    }

    manageShowForm = () => {
        const { showCreateNewCard } = this.state;
        if (!showCreateNewCard) {
            this.setState({
                showCreateNewCard: true,
                showAssociateCard: false
            });
        }
    }

    showResult() {
        const { showHideForm,favStoreObj: { store_url },healthcard } = this.props;

        const button =  <Link
                            to={ store_url ? store_url : '/' }
                            title= "Aceder ao Site"
                            block="Button"
                            mix={ { block: 'HealthCardWithoutLogin', elem: 'Button' } }>
                                Aceder ao Site
                        </Link>

        if(showHideForm === 201){
            window.history.replaceState(null, null, "aderir-sauda-s3.2");
            return (
                <>
                    <CmsBlock identifier={ "HealthCardS3.2" } />
                    {button}
                </>
            );
        }else if(showHideForm === 202){
            window.history.replaceState(null, null, "aderir-sauda-s3.1");
            return (
                <>
                    <CmsBlock identifier={ "HealthCardS3.1" } />
                    {button}
                </>
            );
        }else if(showHideForm === 203){
            window.history.replaceState(null, null, "aderir-sauda-s3.3");
            return (
                <>
                    <CmsBlock identifier={ "HealthCardS3.3" } />
                    {button}
                </>
            );
        }else{
            window.history.replaceState(null, null, "aderir-sauda-s4");
            return (
                <>
                    <CmsBlock identifier={ "HealthCardS4" } />
                    {button}
                </>
            );
        }

    }

    render() {
        const { showHideForm }  = this.props;
        const { showCreateNewCard, showAssociateCard} = this.state;

        if(showHideForm){
            return (
                <>
                    { this.showResult() }
                </>
            );
        }

        if (showAssociateCard) {
            return (
                <>
                    { this.showResult() }
                </>
            );
        }

        if (showCreateNewCard) {
            return (
                <>
                    { this.renderPageManager() }
                </>
            );
        }

        return (
            <>
                { this.renderSmsForm() }
            </>
        );
    }
}

export default HealthCardWithoutLogin;
