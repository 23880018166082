/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Store Page Query
 * @class StorePageQuery
 * @namespace Query/StorePageQuery
 */
export class StorePageQuery {
    /**
     * get Stores query
     * @return {Query} Store page query
     * @memberof StorePageQuery
     */
    getQuery(id) {
        return new Field('getPharmacies')
            .addArgument('storeId', 'Int', id)
            .addFieldList([
                'entity_id',
                'pharmacy_code',
                'sauda',
                'home_delivery',
                'name',
                'email',
                'resume',
                'longitude',
                'latitude',
                'street_name',
                'number',
                'locality',
                'postal_code_zone',
                'postal_code_sufix',
                'postal_code_locality',
                'postal_code_region',
                'telephone',
                'telephone_secondary',
                'status',
                'schedule',
                'schedule_values',
                'on_call',
                'shifts',
                'online_store_active',
                'store_url',
                'home_delivery',
                'free_shipping_value',
                this._getServicesField(),
                this._getAllSchedulesField(),
                'photo',
                'logo',
                'technical_team',
                'owner',
                'director'
            ]);
    }

    _getServicesField() {
        return new Field('services')
            .addFieldList(this._getServicesFields());
    }

    _getServicesFields() {
        return [
            'entity_id',
            'code',
            'name',
            'active'
        ]
    }

    _getAllSchedulesField() {
        return new Field('all_schedules')
            .addFieldList(this._getAllSchedulesFields());
    }

    _getAllSchedulesFields() {
        /*return [
            'weekdays_morning_start',
            'weekdays_morning_end',
            'weekdays_afternoon_start',
            'weekdays_afternoon_end',
            'saturdays_start',
            'saturdays_end',
            'sunday_start',
            'sunday_end',
            'holidays_start',
            'holidays_end'
        ]*/
        return [
            'saturday_open',
            'saturday_schedule_complete',
            'saturday_morning_start',
            'saturday_morning_end',
            'saturday_afternoon_start',
            'saturday_afternoon_end',
            'sunday_open',
            'sunday_schedule_complete',
            'sunday_morning_start',
            'sunday_morning_end',
            'sunday_afternoon_start',
            'sunday_afternoon_end',
            'monday_open',
            'monday_schedule_complete',
            'monday_morning_start',
            'monday_morning_end',
            'monday_afternoon_start',
            'monday_afternoon_end',
            'tuesday_open',
            'tuesday_schedule_complete',
            'tuesday_morning_start',
            'tuesday_morning_end',
            'tuesday_afternoon_start',
            'tuesday_afternoon_end',
            'wednesday_open',
            'wednesday_schedule_complete',
            'wednesday_morning_start',
            'wednesday_morning_end',
            'wednesday_afternoon_start',
            'wednesday_afternoon_end',
            'thursday_open',
            'thursday_schedule_complete',
            'thursday_morning_start',
            'thursday_morning_end',
            'thursday_afternoon_start',
            'thursday_afternoon_end',
            'friday_open',
            'friday_schedule_complete',
            'friday_morning_start',
            'friday_morning_end',
            'friday_afternoon_start',
            'friday_afternoon_end',
            'holidays_open',
            'holidays_schedule_complete',
            'holidays_morning_start',
            'holidays_morning_end',
            'holidays_afternoon_start',
            'holidays_afternoon_end'
        ]
    }

    getUpdateCustomerPharmacyPreference(pharmacy_code) {
        return new Field('updateCustomerPharmacyPreference')
            .addArgument('pharmacy_code', 'String!', pharmacy_code);
    }

    getUpdateCustomerContextPharmacy(pharmacy_code) {
        return new Field('updateCustomerContextPharmacy')
            .addArgument('pharmacy_code', 'String!', pharmacy_code);
    }

    getUpdateGuestContextPharmacy(pharmacy_code) {
        return new Field('updateGuestContextPharmacy')
            .addArgument('pharmacy_code', 'String!', pharmacy_code);
    }

    removeContextPharmacy(pharmacy_code) {
        return new Field('removeContextPharmacy')
            .addArgument('pharmacy_code', 'String!', pharmacy_code);
    }
}

export default new (StorePageQuery)();
