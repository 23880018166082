import {
    SHIPPING_STEP as SourceSHIPPING_STEP,
    BILLING_STEP as SourceBILLING_STEP,
    DETAILS_STEP as SourceDETAILS_STEP,
    CHECKOUT_URL as SourceCHECKOUT_URL,
    PAYMENT_TOTALS as SourcePAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY as SourceUPDATE_EMAIL_CHECK_FREQUENCY,
} from 'SourceRoute/Checkout/Checkout.config';

export const SHIPPING_STEP = SourceSHIPPING_STEP;
export const BILLING_STEP = SourceBILLING_STEP;
export const DETAILS_STEP = SourceDETAILS_STEP;
export const CONFIRM_STEP = 'CONFIRM_STEP';

export const CHECKOUT_URL = SourceCHECKOUT_URL;

export const PAYMENT_TOTALS = SourcePAYMENT_TOTALS;

export const UPDATE_EMAIL_CHECK_FREQUENCY = SourceUPDATE_EMAIL_CHECK_FREQUENCY;
