/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_STORE_FINDER,
    UPDATE_STORE_SERVICES,
    UPDATE_STORE_SELECTOR
} from './StoreFinder.action';

export const initialState = {
    storeListCities: [],
    storeListMapped: {},
    storeByName: {},
    storeList: [],
    storeSelectorList: [],
    storeSelectorCities: [],
    storeSelectorMapped: {},
    storeSelectorByName: {},
    serviceList: [],
    isLoading: true,
    isLoadingSelector: true
};

/** @namespace Scandiweb/StoreFinderGraphQl/Store/StoreFinder/Reducer/storeFinderReducer */
export const StoreFinderReducer = (state = initialState, action) => {
    const { storeList, storeSelectorList, serviceList, type } = action;

    switch (type) {
        case UPDATE_STORE_FINDER:
            const storeListObject = storeList.reduce((cities, store) => {
                const {
                    locality, longitude, latitude, name
                } = store;

                if (locality && longitude && latitude) {
                    if (!cities.storeListCities.includes(locality)) {
                        cities.storeListCities.push(locality);
                    }

                    if (!cities.storeListMapped[locality]) {
                        // eslint-disable-next-line no-param-reassign
                        cities.storeListMapped[locality] = [];
                    }

                    cities.storeListMapped[locality].push(store);

                    // eslint-disable-next-line no-param-reassign
                    cities.storeByName[name] = store;
                }

                return cities;
            }, { storeListCities: [], storeListMapped: {}, storeByName: {}, isLoading: false });

            return {
                ...state,
                ...storeListObject,
                storeList
            };

        case UPDATE_STORE_SELECTOR:
            const storeSelectorListObject = storeSelectorList.reduce((cities, store) => {
                const {
                    locality, longitude, latitude, name
                } = store;

                if (locality && longitude && latitude) {
                    if (!cities.storeSelectorCities.includes(locality)) {
                        cities.storeSelectorCities.push(locality);
                    }

                    if (!cities.storeSelectorMapped[locality]) {
                        // eslint-disable-next-line no-param-reassign
                        cities.storeSelectorMapped[locality] = [];
                    }

                    cities.storeSelectorMapped[locality].push(store);

                    // eslint-disable-next-line no-param-reassign
                    cities.storeSelectorByName[name] = store;
                }

                return cities;
            }, { storeSelectorCities: [], storeSelectorMapped: {}, storeSelectorByName: {}, isLoadingSelector: false });

            return {
                ...state,
                ...storeSelectorListObject,
                storeSelectorList
            };

        case UPDATE_STORE_SERVICES:
            return {
                ...state,
                serviceList
            };

        default:
            return state;
    }
};

export default StoreFinderReducer;
