import {
    MyAccountForgotPassword as SourceMyAccountForgotPassword,
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';
import Field from 'Component/Field';
import Form from 'Component/Form';
//import 'MyAccountForgotPassword.component.style.override'

export class MyAccountForgotPassword extends SourceMyAccountForgotPassword {
    scrollToElement(e) {
        if (e.target) {
            window.scrollTo(0, (e.target.offsetTop + 82)); // header height: 82px;
        }
    }

    renderForgotPasswordForm() {
        const { onForgotPasswordAttempt, onForgotPasswordSuccess, onFormError } = this.props;

        return (
            <>
            <section block="MyAccountOverlay" elem="LoginDesc">
            { __('Enter the email address associated with your account, and we will send you an email to reset your password.') }
            </section>
            <Form
              key="forgot-password"
              onSubmit={ onForgotPasswordAttempt }
              onSubmitSuccess={ onForgotPasswordSuccess }
              onSubmitError={ onFormError }
            >
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder={ __('E-mail address') }
                  autocomplete="email"
                  validation={ ['notEmpty', 'email'] }
                  onFocus={ this.scrollToElement }
                />
                <div block="MyAccountOverlay" elem="Buttons">
                    <button block="Button" type="submit">
                        { __('Send reset link') }
                    </button>
                </div>
            </Form>
            </>
        );
    }


    renderAdditionalField() {
        const { state, handleSignIn } = this.props;

        return (
        <></>
        );
    }
};

export default MyAccountForgotPassword;
