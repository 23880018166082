/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */
// Leaflet icons not working with webpack
// Solution from https://github.com/Leaflet/Leaflet/issues/4968
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

/* eslint-disable global-require, import/first */
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
// [END] fix

import React, { createRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup as LeafletPopup,
    useMapEvents
} from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";

import { throttle } from 'lodash';
import _ from 'lodash';
import ContentWrapper from 'Component/ContentWrapper';
import Meta from 'Component/Meta';
import Link from 'Component/Link';
import Image from 'Component/Image';

import 'leaflet/dist/leaflet.css';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import './StoreSelector.style';
import 'react-input-range/lib/css/index.css';

import { renderToString } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faDirections, faExclamationCircle, faInfoCircle, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { faGlobeAmericas } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faTimes, faHorizontalRule } from '@fortawesome/pro-light-svg-icons';
import iconPharmacyOpen from './images/Group_816@2x.png';
import iconPharmacyClosed from './images/Group_815@2x.png';
import iconPharmacyOpenNf from './images/Path_97@2x.png';
import iconPharmacyClosedNf from './images/Path_91@2x.png';
import MapFilters from 'Component/StoreSelectorMapFilters/StoreSelectorMapFilters.component'
import PaginationView from 'Component/Pagination';
import LeftIcon from './images/left.svg';
import RightIcon from './images/right.svg';
import cardSauda from './images/pecas-sauda-2-79-copy@2x.png';
import iconUser from './images/user.png';
import { DeviceType } from 'Type/Device';
import Tooltip from 'Component/Tooltip';
import Loader from 'Component/Loader';
import BrowserDatabase from 'Util/BrowserDatabase';
import Popup from 'Component/Popup';
import delete_icon from 'Component/MyAccountAddressTable/images/icon_delete_modal.png';
import arrowDown from 'Route/StorePage/Images/arrowdown.png';
import arrowUp from 'Route/StorePage/Images/arrowUp.png';

import imgShoppingCart from './images/Group_756.svg';
import imgDirections from './images/Group_754.svg';
import imgPhone from './images/Group_755.svg';

function LocationMarkerCenter(props) {
    const map = useMapEvents({
        popupopen(e) {
            let lat = e.target._popup._latlng.lat,
                lng = e.target._popup._latlng.lng,
                coords = [lat, lng];
            if(window.innerWidth <= 810) {
                let earth = 6378.137,  //radius of the earth in kilometer
                    pi = Math.PI,
                    m = (1 / ((2 * pi / 360) * earth)) / 1000,  //1 meter in degree
                    offset = 60; //offset distance in meters

                let new_latitude = lat - (offset * m);
                coords = [new_latitude, lng];
            }
            map.flyTo(coords, 18);
        },
    })

    return null;
}

/** @namespace Scandiweb/StoreFinderGraphQl/Route/StoreFinder/Component */
export class StoreSelector extends PureComponent {
    static propTypes = {
        location: PropTypes.shape().isRequired,
        requestStores: PropTypes.func.isRequired,
        requestServices: PropTypes.func.isRequired,
        storeListCities: PropTypes.arrayOf(PropTypes.string).isRequired,
        storeListMapped: PropTypes.objectOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    latitude: PropTypes.string,
                    longitude: PropTypes.string,
                    locality: PropTypes.string,
                    address: PropTypes.string
                })
            )
        ).isRequired,
        storeByName: PropTypes.objectOf(
            PropTypes.shape({
                latitude: PropTypes.string,
                longitude: PropTypes.string,
                locality: PropTypes.string,
                address: PropTypes.string
            })
        ).isRequired,
        device: DeviceType.isRequired
    };

    MapData = {
        allStores : {}
    };

    startTime = 0;
    endTime = 0;

    myRef = {};

    mapIconsRef = createRef();

    state = {
        filtersVisible: false,
        changeAction: false,
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            selectedCity: 'All cities',
            selectedStore: {},
            searchValue: '',
            filtersVisible: false,
            mapVisible: true,
            listVisible: true,
            searchingOnline: false,
            searchingDelivery: false,
            searchingSauda: (BrowserDatabase.getItem('clicked_sauda') || localStorage.getItem('saudaPercent') == "true") ? true : false,
            searchingOpen: true,
            viewAll: false,
            searchingOnCall: false,
            searchingService: '',
            defaultSchedule: {
                min: 0,
                max: 86400,
            },
            searchingSchedule: {
                min: 0,
                max: 86400,
            },
            coordinates: [],
            showMarker: false,
            map: null,
            page: 1,
            limit: 10,
            clickedPharmacy: '',
            arrowUp: false,
            mapInitiated: false,
            mapAfterInitiated: false,
            turnOnGeolocation: false
        };

        this.myRef = createRef();
        this.changeCity = this.changeCity.bind(this);
        this.changeStore = this.changeStore.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.prepareStorePosition = this.prepareStorePosition.bind(this);
        //this.searchByName = this.searchByName.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputThrottled = throttle(this.searchByName, 2000);
        this.handleInputThrottled  =  this.handleInputThrottled.bind(this);

    }

    changeArrow = () => {
        this.setState({
            arrowUp: !this.state.arrowUp
        })
    }

    componentWillMount() {
        const { requestServices, allStores } = this.props;

        // requestServices();
        //this.prepareToRender();
    }

    componentDidMount() {
        const { showNotification,device,storeFinderIsLoading } = this.props;
        navigator.geolocation.getCurrentPosition(position => {
            let coords=[position.coords.latitude,position.coords.longitude];

            this.setState({coordinates:coords});
        },()=>{
            if(!device.isMobile){
                showNotification('info', __('Please allow access to location services in your browser.'));
            }else{
                localStorage.setItem('overlayIsVisible',true);
            }
        });
        BrowserDatabase.deleteItem('clicked_sauda');
    }


    filterFunctions = {
        changeCity: this.changeCity.bind(this),
        changeStore: this.changeStore.bind(this),
        changeSearchingOnline: this.changeSearchingOnline.bind(this),
        changeSearchingDelivery: this.changeSearchingDelivery.bind(this),
        changeSearchingSauda: this.changeSearchingSauda.bind(this),
        changeSearchingOpen: this.changeSearchingOpen.bind(this),
        changeViewAll: this.changeViewAll.bind(this),
        changeSearchingOnCall: this.changeSearchingOnCall.bind(this),
        changeService: this.changeService.bind(this),
        startSearchSchedule: this.startSearchSchedule.bind(this),
        useLocation: this.useLocation.bind(this),
        prepareServicesOptions: this.prepareServicesOptions.bind(this),
        toggleMap: this.toggleMap.bind(this),
    }


    componentDidUpdate(prevProps,prevState) {
        const { showNotification,device } = this.props;
        const { coordinates } = this.state;
        const overlayIsVisible = localStorage.getItem('overlayIsVisible');
        if(device.isMobile && overlayIsVisible == 'true'){
            const locationMsg = localStorage.getItem('locationMsg');
            if(locationMsg == 'true'){
                if(navigator && navigator.permissions && navigator.permissions.query){
                    navigator.permissions.query({ name: "geolocation" }).then((result) => {
                        if(result.state == 'denied' || (result.state == 'prompt' && coordinates.length == 0)){
                            showNotification('info', __('Please allow access to location services in your browser.'));
                        }
                    });
                }
            }
            localStorage.setItem('locationMsg',false)
        }
        localStorage.setItem('overlayIsVisible',false);
    }

    changeCity(selectedCity) {
        this.setState({ searchValue: '', selectedCity, selectedStore: {}, page: 1 });
    }

    changeStore(selectedStore) {
        this.setState({ searchValue: '', selectedStore });
    }

    changeSearchingOnline() {
        this.setState({ searchingOnline: !this.state.searchingOnline, page: 1 });
    }

    changeSearchingDelivery() {
        this.setState({ searchingDelivery: !this.state.searchingDelivery, page: 1 });
    }

    changeSearchingSauda() {
        this.setState({ searchingSauda: !this.state.searchingSauda, page: 1 });
    }

    changeSearchingOpen() {
        //this.state.changeAction = false;
        this.setState({ searchingOpen: !this.state.searchingOpen, page: 1 });
    }

    changeViewAll(value) {
        this.setState({ searchingOpen: value, page: 1 });
        this.setState({ viewAll: value, page: 1 });
    }

    changeSearchingOnCall() {
        this.setState({ searchingOnCall: !this.state.searchingOnCall });
    }

    changeService(selectedService) {
        this.setState({ searchingService: selectedService, page: 1 });
    }

    startSearchSchedule(value) {
        this.setState({ searchingSchedule: value, page: 1  })
    }

    toggleFilters() {
        this.state.changeAction = true;
        this.setState({ filtersVisible: !this.state.filtersVisible });
    }

    toggleMap() {
        const { device } = this.props;
        this.setState({
            mapVisible: !this.state.mapVisible,
            listVisible: !this.state.listVisible
        });

        if (device.isMobile) {
            //this.renderMap(this.MapData);
            this.mapIconsRef.current.scrollIntoView();
            const { map } = this.state;
            map.setZoom(map.getZoom() - 1);
        }
    }

    useLocation() {
        const { showNotification } = this.props;
        const { map, coordinates } = this.state;

        if(coordinates && coordinates.length) {
            this.setState({
                showMarker:true,
            })
            map.flyTo(coordinates, 18);
        } else {
            map.locate();
            /*navigator.geolocation.getCurrentPosition(position => {
                let coords=[position.coords.latitude,position.coords.longitude];

                this.setState({
                    coordinates: coords,
                    showMarker: true,
                })
                map.flyTo(coordinates, 18);
            }, () => {
                showNotification('info', __('Please allow access to location services in your browser.'));
            });*/
        }
    }

    checkSchedule(store) {
        const { searchingSchedule } = this.state;
        const { schedule_values } = store;

        if(schedule_values && schedule_values.length) {
            if (schedule_values.length > 2) {
                return (searchingSchedule.min <= this.hmsToSeconds(schedule_values[1]) && searchingSchedule.max >= this.hmsToSeconds(schedule_values[0])) || (searchingSchedule.min <= this.hmsToSeconds(schedule_values[3]) && searchingSchedule.max >= this.hmsToSeconds(schedule_values[2]));
            } else {
                return searchingSchedule.min <= this.hmsToSeconds(schedule_values[1]) && searchingSchedule.max >= this.hmsToSeconds(schedule_values[0]);
            }
        }

        return false;
    }

    hmsToSeconds(str) {
        var hms = str + ':00';   // your input string
        var a = hms.split(':'); // split it at the colons
        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds;
    }

    onPageChange(page) {
        this.setState({ page: page.selected + 1 });
        //this.myRef.current.scrollTo(0, 0);
        //window.scrollTo(0, 0);
    }

    handleStoreChange(name) {
        const { storeByName } = this.props;

        this.changeStore(storeByName[name] || {});
    }

    searchByName(value) {
      // const value = e.target.value;

       this.setState({ searchValue: value , page: 1});
    }

    prepareMapBounds(allStores) {
        if(allStores.length) {
            const latLongArr = allStores.map(this.prepareStorePosition);

            return L.latLngBounds(latLongArr);
        }
    }

    prepareStorePosition({ latitude, longitude }) {
        if (!latitude || !longitude) {
            return null;
        }

        return [latitude, longitude];
    }

    prepareStoreData() {
        const { storeListMapped } = this.props;
        const {
            selectedCity,
            searchValue,
            searchingOnline,
            searchingDelivery,
            searchingSauda,
            searchingOpen,
            searchingOnCall,
            searchingService,
            defaultSchedule,
            searchingSchedule,
            changeAction,
            coordinates,
        } = this.state;

        const MapData = storeListMapped[selectedCity]
        || Object.values(storeListMapped).reduce((stores, MapData) => {
            // eslint-disable-next-line no-param-reassign
            stores = [...stores, ...MapData];
            return stores;
        }, []);

        let sortedMap = null;

        if(coordinates && coordinates.length) {
            sortedMap = this.sortLocation(MapData,coordinates[0],coordinates[1]);
        } else {
            sortedMap = this.sortMapData(MapData);
        }

        const cityStores = sortedMap;

        if(changeAction == true) {
            this.state.changeAction = false;
            return cityStores;
        }

        if(searchingOnline || searchingSauda ) {
            return cityStores.filter(store => {
                if (store.name != null || store.street_name != null || store.postal_code_zone != null || store.locality != null  ) {
                    let storeNameNormalized = _.deburr(store.name);
                    let storeLocalityNormalized = _.deburr(store.locality);
                    let storeStreetNameNormalized = _.deburr(store.street_name);
                    let searchValueNormalized = _.deburr(searchValue);

                    return (searchingOnline ? store.online_store_active == searchingOnline : true)
                        && (searchingSauda ? store.sauda == searchingSauda : true)
                        && (storeNameNormalized.toLowerCase().includes(searchValueNormalized.toLowerCase())
                        || storeLocalityNormalized.toLowerCase().includes(searchValueNormalized.toLowerCase())
                        || storeStreetNameNormalized.toLowerCase().includes(searchValueNormalized.toLowerCase())
                        || (store.postal_code_zone && store.postal_code_zone.toLowerCase().includes(searchValue.toLowerCase()))
                        || (store.postal_code_locality && store.postal_code_locality.toLowerCase().includes(searchValue.toLowerCase()))
                        || (store.postal_code_region && store.postal_code_region.toLowerCase().includes(searchValue.toLowerCase())));
                } else {
                    return false;
                }
            });
        } else {

            if(parseInt(searchValue.length) < 3) {
                return cityStores;
            } else {
                if(searchValue) {

                    return cityStores.filter(store => {
                        if (store.name != null || store.street_name != null || store.postal_code_zone != null || store.locality != null  ) {
                            let storeNameNormalized = _.deburr(store.name);
                            let storeLocalityNormalized = _.deburr(store.locality);
                            let storeStreetNameNormalized = _.deburr(store.street_name);
                            let searchValueNormalized = _.deburr(searchValue);

                            return ( storeNameNormalized.toLowerCase().includes(searchValueNormalized.toLowerCase())
                            || storeLocalityNormalized.toLowerCase().includes(searchValueNormalized.toLowerCase())
                            || storeStreetNameNormalized.toLowerCase().includes(searchValueNormalized.toLowerCase())
                            || store.postal_code_zone.toLowerCase().includes(searchValue.toLowerCase())
                            || (store.postal_code_locality && store.postal_code_locality.toLowerCase().includes(searchValue.toLowerCase())));
                        } else {
                            return false;
                        }
                    });
                        //store.name.includes(searchValue));
                }
                return cityStores;

            }

        }

        /*return cityStores.reduce((validStores, allStore) => {
            const {
                latitude,
                longitude,
                locality,
                name
            } = allStore;
            const { searchingOnline, searchingDelivery, searchingSauda } = this.state;

            if (latitude !== 0 && longitude !== 0 && locality) {
                const sortInsertIndex = validStores.findIndex(({
                    online_store_active: validStoreActive,
                    sauda: validSauda,
                    home_delivery: validDelivery
                }) => {
                    if (searchingOnline == validStoreActive) {
                        return validSauda > searchingSauda;
                    }

                    return validStoreActive > searchingOnline;
                });

                const insertPosition = sortInsertIndex >= 0 ? sortInsertIndex : validStores.length;

                validStores.splice(insertPosition, 0, {
                    ...allStore,
                    active: false
                });
            }

            return validStores;
        }, []);*/
    }

    sortMapData(MapData) {

        if (MapData == undefined) {
            return undefined;
        }

        const sortedMapData = MapData.reduce(
            (acc, a) => (a.name ? [...acc, a] : acc), []
        ).sort((a, b) => {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            // eslint-disable-next-line no-nested-ternary
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        return sortedMapData;
    }

    sortLocation(MapData,lat,long) {

        if (MapData == undefined) {
            return undefined;
        }

        const sortedMapData = MapData.reduce(
            (acc, a) => (a.name ? [...acc, a] : acc), []
        ).sort((a, b) => {
            const origLat = lat;
            const origLong = long;
            // eslint-disable-next-line no-nested-ternary
            return this.calcDistance(origLat, origLong, a.latitude, a.longitude) - this.calcDistance(origLat, origLong, b.latitude, b.longitude);
        });

        return sortedMapData;
    }

    calcDistance(lat1, lon1, lat2, lon2) {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;

        return dist;
    };

    prepareCityOptions() {
        const { storeListCities: cities } = this.props;

        const validCityOptions = cities.reduce((options, city) => {
            if (city) {
                const sortInsertIndex = options.findIndex(({ label }) => label > city);
                const insertPossition = sortInsertIndex >= 0 ? sortInsertIndex : options.length;

                options.splice(insertPossition, 0, {
                    id: city.replace(/\s/g, ''), value: city, label: city
                });
            }

            return options;
        }, []);

        validCityOptions.unshift({ id: __('All cities'), value: __('All cities'), label: __('All cities') });

        return validCityOptions;
    }

    prepareServicesOptions() {
        const { serviceList } = this.props;

        const validServiceOptions = serviceList.reduce((options, service) => {
            if (service) {
                const sortInsertIndex = options.findIndex(({ label }) => label > service.name);
                const insertPossition = sortInsertIndex >= 0 ? sortInsertIndex : options.length;

                options.splice(insertPossition, 0, {
                    id: service.name.replace(/\s/g, ''), value: service.entity_id, label: service.name
                });
            }

            return options;
        }, []);

        //validServiceOptions.unshift({ id: __('Select'), value: '', label: __('Select') });

        return validServiceOptions;
    }

    prepareStoreOptions() {
        const allStore = this.prepareStoreData() || [];

        return [{ name: __('All stores') }, ...allStore].map(({ name }) => (
            { id: name, value: name, label: name }
        )) || [];
    }

    prepareMapData() {
        const { showNotification } = this.props;
        const { selectedStore, selectedStore: { name }, map } = this.state;
        const allStores = this.prepareStoreData();
        const hasMultipleStores = allStores.length > 1;

        //TODO: change static latitude

        if(allStores.length == 1) {
            let store = allStores[0];
            let coordinates=[store.latitude,store.longitude];

            // this.mapChangeLocation(coordinates,store);
        }

        let markers = [];
        if (allStores.length > 1) {
            for (let i=0; i<allStores.length;i++) {
                markers.push([allStores[i].latitude, allStores[i].longitude]);
            }

            if(map) {
                //map.flyTo(coordinates, 17);
                map.fitBounds(markers);
            }
        }

        return {
            allStores,
            centerPosition: this.prepareStorePosition(hasMultipleStores ? selectedStore : allStores.length ? allStores[0] : {latitude: '38.70106485495845',longitude:'-9.147839099051913'}),
            bounds: hasMultipleStores && !name ? this.prepareMapBounds(allStores) : null
        };
    }

    prepareHours(store) {
        const { schedule } = store;

        if (!schedule) {
            return __('No info about hours');
        }

        return (
            <strong>{ schedule }</strong>
        );

        /*const workingDaysMap = working_days.split(',').map(day => day === '1');

        return (
            workingDaysMap.map((isActive, index) => (
                <span
                  block="StoreSelector"
                  elem="Circles"
                  key={ index }
                  mods={ { isActive } }
                />
            ))
        );*/
    }

    renderHeading() {
        return (
            <div block="StoreSelector" elem="Heading">
                <h2 block="StoreSelector" elem="Heading" mods={ { Title: true } }>
                    { __('Filter Results') }
                    <FontAwesomeIcon
                        className="StoreSelector-FilterToggle"
                        icon={ faTimes }
                        onClick={ () => this.toggleFilters() }
                    />
                </h2>
            </div>
        );
    }

    mapChangeLocation(coordinates,store) {
        const { map } = this.state;
        const { device } = this.props;

        if(device.isMobile){
            this.setState({
                mapVisible: true,
                listVisible: false
            }, () => {
                if(map) {
                    map.flyTo(coordinates, 17);
                    map.openPopup(
                        renderToString(<div block="StoreSelector" elem="Store">
                            { this.renderStoreCardContent(store) }
                        </div>),coordinates);
                    document.getElementById(store.entity_id).click();
                }
            });
        } else {
            if(map) {
                map.flyTo(coordinates, 17);
                map.openPopup(
                    renderToString(<div block="StoreSelector" elem="Store">
                        { this.renderStoreCardContent(store) }
                    </div>),coordinates);
                document.getElementById(store.entity_id).click();
            }
        }
    }


    renderStoreCardContent(store) {
        const { updateContextPharmacy, cartTotals: { items_qty }, device } = this.props;
        const {
            name,
            street_name,
            number,
            locality,
            postal_code_zone,
            postal_code_sufix,
            postal_code_locality,
            status,
            telephone,
            telephone_secondary,
            online_store_active,
            pharmacy_code,
            sauda,
            latitude,
            longitude,
            store_url,
            service_message
        } = store;
        const { coordinates } = this.state;

        let saudaInfo = ``;
        let storeLocation = [latitude, longitude];
        let distance = '';
        let telephoneToShow = telephone_secondary;
        if(coordinates && coordinates.length) {
            let dist = this.calcDistance(coordinates[0], coordinates[1], latitude, longitude);
            let finalDistance = Math.round(dist * 100) / 100;
            distance = `${finalDistance} km - `;
        }
        let address = `${distance}${street_name} ${number}`;
        let postLocality = postal_code_locality == null ? '' : `${postal_code_locality}`;
        let postCode = `${postal_code_zone}-${postal_code_sufix} ${postLocality}`;
        let local = locality == null ? '' : `${locality}`;

        let storeStatusLabel = __('Closed');
        let showHours = true;

        if(status == 'open') {
            storeStatusLabel = __('Open');
        }

        if(status == 'open_24') {
            storeStatusLabel = __('Open 24H');
            showHours = false;
        }

        if(status == 'closing') {
            storeStatusLabel = __('Closing soon');
        }

        if(sauda == 1){
            saudaInfo = <div class="saudaIcon"><Image
                        src={ cardSauda }
                    /></div>;
        }

        if (!telephoneToShow) {
            telephoneToShow = telephone;
        }

        const urlInfo = '/farmacia-info/' + pharmacy_code;

        return (
            <>
                {/* <div block="StoreSelector" elem="StoreInfo">
                    <span block="StoreSelector" elem="StoreInfoLabel" mods={ { type: status } }>
                        { storeStatusLabel }
                    </span>
                </div> */}
                <div block="StoreSelector" elem="StoreInfo" mods={ { type: 'name' } }>
                { device.isMobile &&
                    <h3>
                        <a>{ name || __('No store name') }</a>
                    </h3>
                }
                { !device.isMobile &&
                    <h3>
                        <a onClick={  () => this.mapChangeLocation(storeLocation,store) }>{ name || __('No store name') }</a>
                    </h3>
                }
                </div>
                <div>
                    <span block="StoreSelector" elem="StoreInfo" mods={ { type: 'address' } }>
                        { address || __('No address') }
                    </span>
                    { local != '' ?
                    <span block="StoreSelector" elem="StoreInfo" mods={ { type: 'address' } }>
                        { local }
                    </span> : ''
                    }
                    <span block="StoreSelector" elem="StoreInfo" mods={ { type: 'address' } }>
                        { postCode || __('No postcode') }
                    </span>
                    <div block="StoreSelector" elem="StoreInfo" mods={ { type: 'workingdays' } }>
                        <span block="StoreSelector" elem="StoreInfoLabel" mods={ { type: status } }>
                            { storeStatusLabel }
                        </span>
                        { showHours ? <span> - </span> : '' }
                        { showHours ? this.prepareHours(store) : '' }
                    </div>
                </div>
                { service_message ? <div block="StoreSelector" elem="StoreInfo" mods={ { type: 'service_message' } }>
                    { service_message }
                    <Tooltip message={ __('Call 1400 for urgent prescriptions') } position={ 'top-right' }>
                        <FontAwesomeIcon
                            className="StoreSelector-CircleInfo"
                            icon={ faExclamationCircle }
                        />
                    </Tooltip>
                </div> : '' }
                <div block="StoreSelector" elem="StoreInfoCardAndIcons">
                    <div>{ saudaInfo }</div>
                    <div block="StoreSelector" elem="StoreInfoIcons">
                        <button
                            block="StoreSelector"
                            elem="SelectButton"
                            mix={ { block: 'Button' } }
                            onClick={ () => !items_qty ? updateContextPharmacy(store) : this.prepareShowCartPopup(store) }
                        >{ __('Select ') }</button>
                    </div>
                </div>
            </>
        );
    }

    /*selectPharmacy(store) {
        const { updateContextPharmacy } = this.props;
        updateContextPharmacy(store);
    }*/

    getPharmacyMarker(store) {
        const { name, online_store_active, status } = store;
        let icon = iconPharmacyOpenNf;
        if(status == 'closed') {
            icon = iconPharmacyClosedNf;
        }

        if(online_store_active) {
            icon = iconPharmacyOpen;

            if(status == 'closed') {
                icon = iconPharmacyClosed;
            }
        }

        return (
            <img
                block="StoreSelector"
                elem="StoreMarkerImage"
                src={ icon || '' }
                alt={ name }
                title={ name }
                loading="lazy"
                id={ store.entity_id }
            />
        )
    }

    renderMarker(store) {
        const { latitude, longitude, name } = store;

        const divIcon = this.getPharmacyMarker(store);
        const customMarkerIcon = new L.DivIcon({
            html: renderToString(divIcon),
            iconAnchor: [10, 37],
            iconSize: new L.Point(30, 37)
        });

        let storeLocation = [latitude, longitude];


        return (
            <Marker
              position={ [latitude, longitude] }
              icon={ customMarkerIcon }
              //key={ name.replace(/\s/g, '') || 'All Stores' }
              riseOnHover
              onClick={ () => this.mapChangeLocation(storeLocation, store) }
            >
                <LeafletPopup closeButton={ true }>
                    <div block="StoreSelector" elem="Store">
                        { this.renderStoreCardContent(store) }
                    </div>
                </LeafletPopup>
            </Marker>
        );
    }

    renderAllMarkers(allStores) {
        //const { allStores } = this.prepareMapData();
        const { map } = this.state;

        if(allStores.length) {
            return allStores.map(store => this.renderMarker(store));
        } else {
            if(map) {
                map.closePopup();
            }
        }
    }

    renderMapLegend() {
        const { device } = this.props;

        if(!device.isMobile) {
            return (
                <div ref={ this.mapIconsRef } block="StoreSelector" elem="MapLegend">
                    <div block="StoreSelector" elem="MapLegendSingle">
                        <img
                            block="StoreSelector"
                            elem="MapLegendImage"
                            src={ iconPharmacyOpen || '' }
                            alt={ __('Pharmacy Open') }
                            title={ __('Pharmacy Open') }
                            loading="lazy"
                        />
                        <div block="StoreSelector" elem="MapLegendInfo">
                            <p>{ __('Pharmacy Open') }</p>
                            <strong>{ __('With online store') }</strong>
                        </div>
                    </div>
                    <div block="StoreSelector" elem="MapLegendSingle">
                        <img
                            block="StoreSelector"
                            elem="MapLegendImage"
                            src={ iconPharmacyClosed || '' }
                            alt={ __('Pharmacy Closed') }
                            title={ __('Pharmacy Closed') }
                            loading="lazy"
                        />
                        <div block="StoreSelector" elem="MapLegendInfo">
                            <p>{ __('Pharmacy Closed') }</p>
                            <strong>{ __('With online store') }</strong>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div ref={ this.mapIconsRef } block="StoreSelector" elem="TotalMobileColumns">
                    <div block="StoreSelector" elem="Arrow" onTouchEnd={ this.changeArrow }>
                        <FontAwesomeIcon
                            className="StoreSelector-FilterToggle"
                            icon={ this.state.arrowUp ? faChevronDown : faChevronUp }
                        />
                        <div block="StoreSelector" elem="TotalMobile">
                            <div block="StoreSelector" elem="TotalMobilePrice">
                                <p block="StoreSelector" elem="TotalMobilePriceLabel">{ __('Subtitles') }</p>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.arrowUp && <div block="StoreSelector" elem="TotalMobileResume">
                            <div block="StoreSelector" elem="MapLegendSingle">
                                <img
                                    block="StoreSelector"
                                    elem="MapLegendImage"
                                    src={ iconPharmacyOpen || '' }
                                    alt={ __('Pharmacy Open') }
                                    title={ __('Pharmacy Open') }
                                    loading="lazy"
                                />
                                <div block="StoreSelector" elem="MapLegendInfo">
                                    <p>{ __('Pharmacy Open') }</p>
                                    <strong>{ __('With online store') }</strong>
                                </div>
                                <img
                                    block="StoreSelector"
                                    elem="MapLegendImage"
                                    src={ iconPharmacyClosed || '' }
                                    alt={ __('Pharmacy Closed') }
                                    title={ __('Pharmacy Closed') }
                                    loading="lazy"
                                />
                                <div block="StoreSelector" elem="MapLegendInfo">
                                    <p>{ __('Pharmacy Closed') }</p>
                                    <strong>{ __('With online store') }</strong>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
        }
    }

    renderPosition() {

        const { coordinates } = this.state

        if(coordinates && coordinates.length) {
            const divIcon = <img
                block="StoreSelector"
                elem="StoreMarkerImage"
                src={ iconUser || '' }
                alt={ "user location" }
                title={ "user location" }
                loading="lazy"
            />;

            const customMarkerIcon = new L.DivIcon({
                html: renderToString(divIcon),
                iconAnchor: [10, 37],
                iconSize: new L.Point(30, 37)
            });

            return (
                <Marker
                    position={ coordinates }
                    icon={ customMarkerIcon }
                    key={ name.replace(/\s/g, '') || 'All Stores' }
                    riseOnHover
                >
                </Marker>
            );
        }
    }

    handleLocationFound = (e) => {
        const { map } = this.state;
        if (map) {
            let coords=[e.latitude,e.longitude];

            this.setState({
                coordinates:coords,
                showMarker:true
            });

            const radius = e.accuracy;
            const circle = L.circle(e.latlng, radius, {fill:false, opacity:0});
            circle.addTo(map);

            setTimeout(() => {
                map.flyTo(coords, 12);
            }, 100);
        }

        this.setState({mapAfterInitiated:true});
    }

    handleLocationError = (e) => {
        const { showNotification } = this.props;
        const { mapAfterInitiated } = this.state;

        if (mapAfterInitiated) {
            showNotification('info', __('Please allow access to location services in your browser.'));
        }

        this.setState({mapAfterInitiated:true});

        // localhost test
        /*const obj = {
            accuracy: 40.836,
            latitude: 41.1557723,
            latlng: {lat: 41.1557723, lng: -8.6297807},
            longitude: -8.6297807,
            timestamp: 1637925033846,
            type: "locationfound"
        }

        this.handleLocationFound(obj);*/
    }

    onMapCreate = (map) => {
        const { mapInitiated } = this.state;
        this.setState({ map });

        if (!mapInitiated) {
            this.setState({ mapInitiated:true });

            map.locate()
                .on('locationfound', this.handleLocationFound)
                .on('locationerror', this.handleLocationError);
        }

        /*navigator.geolocation.getCurrentPosition(position => {
            let coords=[position.coords.latitude,position.coords.longitude];

            this.setState({coordinates:coords});
            this.setState({
                showMarker:true,
            })
            map.flyTo(coords, 12);
        }, () => {
            // showNotification('info', __('Please allow access to location services in your browser.'));
        });*/

        this.setState({mapVisible: false});
    }

    renderMap(MapData) {
        const { centerPosition, bounds, allStores } = MapData;
        const { showMarker, mapVisible } = this.state;

        const { device } = this.props;
        return (
            <div ref={ this.mapIconsRef } block="StoreSelector" elem="Map">
                <MapContainer
                  center={ centerPosition }
                  zoom={ 13 }
                  bounds={ bounds }
                  boundsOptions={ { padding: [20, 20] } }
                  duration={ 0.8 }
                  minZoom={ 2 }
                  whenCreated={ (map) => this.onMapCreate(map)}
                >
                    <LocationMarkerCenter/>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      updateWhenIdle={ false }
                      reuseTiles
                    />
                    <MarkerClusterGroup showCoverageOnHover= { false }>
                    { this.renderAllMarkers(allStores) }
                    </MarkerClusterGroup>
                    { showMarker && this.renderPosition()}
                </MapContainer>
                { this.renderMapLegend() }
            </div>
        );
    }

    renderStoreCard(store) {
        const { selectedStore: { name: selectedStoreName } } = this.state;
        const { name } = store;

        return (
            <div
              block="StoreSelector"
              elem="Store"
              //key={ name.replace(/\s/g, '') }
              mods={ { isActive: name === selectedStoreName } }
            >
                { this.renderStoreCardContent(store) }
                {/* <button
                  block="Button"
                  mods={ { likeLink: true } }
                  onClick={ () => this.changeStore(store) }
                >
                    { __('Show on the map') }
                </button> */}
            </div>
        );
    }

    calcPagination(stores){
        const storesInPage = stores.filter((store, index) => {
			return (index < this.state.page * this.state.limit);
        });
        return storesInPage;
    }

    renderStoreCards(storesInPage, showMoreFlag, allStores) {
        const { listVisible, page } = this.state;

        if(storesInPage.length) {
       // if(allStores.length) {
            return (
                <div block="StoreSelector" elem="StoreCards" ref={ this.myRef } mods={ { listVisible } }>
                    { storesInPage.map(store => this.renderStoreCard(store)) }
                    { /*allStores.map(store => this.renderStoreCard(store)) */}
                    { (showMoreFlag && storesInPage.length < allStores.length) &&
                                    <div block="StoreSelector" elem="ShowMore">
                                        <button
                                            block="StoreSelector"
                                            elem="SelectButton"
                                            mix={ { block: 'Button' } }
                                            onClick={ () => this.onPageChange({ selected:page }) }
                                        >{ __('Show more results') }</button>
                                    </div> }
                </div>

            );
        }
    }

    renderMainContent(MapData) {
        const { mapVisible, listVisible } = this.state;

            return (
                <>
                    <div block="StoreSelector" elem="MapSection" mods={ { mapVisible } }>
                        { this.renderMap(MapData) }
                    </div>
                </>
            );
    }

    prepareShowCartPopup(storeObj) {
        const { customer: { preference_pharmacy_code, favourite_pharmacy_code }, showCartPopup, updateContextPharmacy } = this.props;
        if(preference_pharmacy_code != storeObj.pharmacy_code) {
            updateContextPharmacy(storeObj);
        } else {
            if(!favourite_pharmacy_code){
                updateContextPharmacy(storeObj);
            }
            this.setState({
                clickedPharmacy: storeObj
            });
            showCartPopup();
        }
    }

    onPopupChangeStore(clickedPharmacy) {
        const { updateContextPharmacy, showCartPopup } = this.props;
        updateContextPharmacy(clickedPharmacy);
        showCartPopup();
    }

    render() {
        const { device, storeFinderIsLoading, showCartPopup, showMoreFlag} = this.props;
        const { listVisible, clickedPharmacy} = this.state;
        let feedbackVisible = "";

        let storesInPage = {};

        //if (!this.state.changeAction) {
            this.MapData = this.prepareMapData();
        //}

        const { allStores } = this.MapData;

        storesInPage = this.calcPagination(allStores);

        const metaObject = {
            name: __('Our Shops'),
            title: __('Our Shops'),
            meta_title: __('Our Shops'),
            meta_description: __('Our Shops - Find the closest store'),
            meta_keyword: 'stores'
        };

        if (storeFinderIsLoading) {
            feedbackVisible = <Loader isLoading={ storeFinderIsLoading } />
        } else if(storesInPage.length == 0){
            feedbackVisible =
                <div block="StoreSelector" elem="Feedback">
                    { __('Sorry, no pharmacies were found. Please check your spelling and search again, or try another term.') }
                </div>
        }

        return (
            <main block="StoreSelector">
                <ContentWrapper
                  wrapperMix={ { block: 'StoreSelector', elem: 'Wrapper' } }
                  label={ __('Our Shops') }
                >
                    <Meta metaObject={ metaObject } />
                    <div block="StoreSelector" elem="Heading">
                        <h3 block="StoreSelector" elem="Heading" mods={ { Title: true } }>
                            { __('To continue browsing select your pharmacy') }
                        </h3>
                    </div>

                    <Popup
                        id={ 'CartItemsAlert' }
                        clickOutside={ false }
                        mix={ { block: 'CartItemsAlert' } }
                        closedOn={ false }
                    >
                        <header class="Popup-Header">
                            <h3 class="Popup-Heading">
                                <Image src={ delete_icon } width="35px" height="35px" />
                                <br/>
                                { __('By changing pharmacy your cart may be changed.') }
                            </h3>
                        </header>
                        <p>{ __('Do you wish to continue?') }</p>
                        <div block="CartItemsAlert" elem="Actions" >
                            <button
                                block="CartItemsAlert"
                                elem="PrimaryButton"
                                mix={ { block: 'Button' } }
                                onClick={ () => showCartPopup() }
                            >{ __('No') }</button>
                        </div>
                        <div block="CartItemsAlert" elem="Actions">
                            <button
                                block="CartItemsAlert"
                                elem="SecondaryButton"
                                mix={ { block: 'Button' } }
                                onClick={ () => this.onPopupChangeStore(clickedPharmacy) }
                            >{ __('Yes') }</button>
                        </div>
                    </Popup>

                    <div block="StoreSelector" elem="MainContent">
                        <div block="StoreSelector" elem="sideSection">
                            <div block="StoreSelector" elem="ListSection">
                                <MapFilters   { ...this.state } { ...this.filterFunctions } handleInputThrottled={ this.handleInputThrottled } device={device}></MapFilters>
                                { feedbackVisible }
                                <div block="StoreSelector">
                                    { this.renderStoreCards(storesInPage, showMoreFlag, allStores) }
                                </div>
                            </div>
                            <div block="StoreSelector" elem="Pagination" mods={ { listVisible } }>
                                {
                                    /*<PaginationView
                                        previousLabel={<Image
                                            src={ LeftIcon }
                                            width="20px"
                                            height="20px"
                                            mix={ { block: 'Paginate', elem: 'Icon', } }
                                            />}
                                        nextLabel={<Image
                                            src={ RightIcon }
                                            width="20px"
                                            height="20px"
                                            mix={ { block: 'Paginate', elem: 'Icon', } }
                                            />}
                                        breakLabel={<a href="">...</a>}
                                        pageCount={allStores.length / this.state.limit}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.onPageChange}
                                        containerClassName={'pagination_container'}
                                        pageClassName={'page_item'}
                                        pageLinkClassName={'page_link'}
                                        previousClassName={'page_item'}
                                        nextClassName={'page_item'}
                                        previousLinkClassName={'page_link'}
                                        nextLinkClassName={'page_link'}
                                        activeClassName={'active'}
                                    />*/
                                }
                            </div>
                        </div>
                        { this.renderMainContent(this.MapData) }
                    </div>
                </ContentWrapper>
            </main>
        );
    }

    renderMapInfo() {
        return (
            <>
                <div block="StoreSelector" elem="TotalMobileColumns" mods={ mods }>
                    {this.state.arrowUp && <div block="CartFloating" elem="TotalMobileResume">
                        { this.renderPromoContent() }
                        { this.renderTotals() }
                    </div> }
                    <div block="StoreSelector" elem="TotalMobile">
                        {!this.state.arrowUp && <button onClick={ this.changeArrow }>
                            <Image
                                src={ arrowUp }
                                width="20px"
                                height="20px"
                            />
                        </button>}
                        {this.state.arrowUp &&<button onClick={ this.changeArrow }>
                            <Image
                                src={ arrowDown }
                                width="20px"
                                height="20px"
                            />
                        </button>}
                        <div block="CartFloating" elem="TotalMobilePrice">
                            <p block="CartFloating" elem="TotalMobilePriceLabel">Total</p>
                            <p block="CartFloating" elem="TotalMobilePriceValue">
                                { is_wallet && <FontAwesomeIcon className="CartFloating-AlertIcon" icon={ faExclamationCircle } /> }
                                { formattedPrice }
                                { this.renderTotalPoints() }
                            </p>
                        </div>

                        { this.renderSecureCheckoutButton(true) }
                    </div>
                </div>
            </>
        );
    }

    showMore() {
        this.setState({ page: this.state.page++ });
    }
}

export default StoreSelector;
