/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PrescriptionWallet from './PrescriptionWallet.component';
import { ADD_ADDRESS, ADDRESS_POPUP_ID } from 'Component/MyAccountAddressPopup/MyAccountAddressPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { customerType } from 'Type/Account';
import { fetchQuery, fetchMutation } from 'SourceUtil/Request';
import MyAccountQuery from 'Query/MyAccount.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateMeta } from 'Store/Meta/Meta.action';


/** @namespace Component/PrescriptionWallet/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    device: state.ConfigReducer.device
});

/** @namespace Component/PrescriptionWallet/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup("MyAccountPrescriptionPopup", payload)),
    showInfoPopup: (payload) => dispatch(showPopup("MyAccountPrescriptionFormPopup", payload)),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    showDeletePopup: (payload) => dispatch(showPopup("MyAccountPrescriptionDetailsDelete", payload)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
});

/** @namespace Component/PrescriptionWallet/Container */
export class PrescriptionWalletContainer extends PureComponent  {
    static propTypes = {
        customer: customerType.isRequired,
        showPopup: PropTypes.func.isRequired,
        showInfoPopup: PropTypes.func.isRequired,
        showDeletePopup: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired
    };

    state = {
        showHideForm: false,
        title: '',
        page: 'Prescription',
        prescriptionSelected: {},
        prescriptions: [],
        page: 1,
        isLoading: false,
        pageNumber: 1
    };

    containerFunctions = {
        showEditForm: this.showEditForm.bind(this),
        onInfoPopup: this.onInfoPopup.bind(this),
        onPageChange: this.onPageChange.bind(this),
        onDelete: this.onDelete.bind(this),
        onDeleteClick: this.onDeleteClick.bind(this),
        onPrescriptionAddSuccess: this.onPrescriptionAddSuccess.bind(this),
        resetPage: this.resetPage.bind(this)
    };

    componentDidMount() {
        const { updateMeta } = this.props;
        this.getPrescriptions();
        updateMeta({ title: __('Receitas Médicas') });
    }

    onDeleteClick() {
        const { showDeletePopup } = this.props;
        const { prescriptionSelected : { entity_id } } = this.state;
        showDeletePopup({
            title: __(''),
            entity_id
        });
    }

    onPageChange(page) {
        this.setState({ pageNumber: page.selected + 1 });
        //window.scrollTo(0,0);
    }

    onPrescriptionAddSuccess(entityId) {
        // show list
        this.setState({showHideForm:!this.state.showHideForm});

        const callback = (list) => {
            const prescription = list.find(({ entity_id }) => entity_id == entityId);
            // show prescription detail
            if (prescription) {
                this.showEditForm(__('Details from Prescription'),"PrescriptionDetails",prescription);
            }
        }
        // get new prescription list
        this.getPrescriptions(callback);
    }

    getPrescriptions(callback){
        const { showErrorNotification } = this.props;
        const query = MyAccountQuery._getPrescriptionsList();
        this.setState({isLoading: true});
        fetchQuery(query).then(
            ({ getPrescriptionList }) => {
                this.setState({isLoading: false});
                if(getPrescriptionList.status && getPrescriptionList.status == 200) {
                    this.setState({prescriptions: getPrescriptionList.data})

                    if (typeof callback === 'function') {
                        callback(getPrescriptionList.data);
                    }
                }
                if(getPrescriptionList.message) {
                    showErrorNotification([{message: getPrescriptionList.message}]);
                }
            },
            (error) => {
                this.setState({isLoading: false});
                showErrorNotification(error)
            }
        )
    }

    onDelete() {
        const { showErrorNotification, showSuccessNotification } = this.props;
        const { prescriptionSelected } = this.state;
        this.onDeleteClick();
        if(prescriptionSelected.entity_id) {
            const query = MyAccountQuery.getDeletePrescription(prescriptionSelected.entity_id);
            this.setState({isLoading: true});
            fetchMutation(query).then(
                ({deletePrescription}) => {
                    this.setState({isLoading: false});
                    if(deletePrescription.status && deletePrescription.status == 200) {
                        this.getPrescriptions();
                        this.showEditForm();
                        showSuccessNotification(deletePrescription.message);
                    } else {
                        showErrorNotification([{message: deletePrescription.message}]);
                    }
                },
                (error) => {
                    this.setState({isLoading: false});
                    showErrorNotification(error)
                }
            )
        }
    }

    onInfoPopup() {
        const { showInfoPopup } = this.props;

        showInfoPopup({
            action: "SUCCESS_PRESCRIPTION",
            title: __('')
        });
        this.getPrescriptions();
    }

    showEditForm(title,page,prescription) {
        this.setState({
            title_page: title,
            FormTitle: page,
            prescriptionSelected: prescription
        }, () => {
            this.setState({showHideForm:!this.state.showHideForm});
        });
        window.scrollTo(0,0);
    }

    resetPage() {
        let tmpPage = {selected: 0};
        this.onPageChange(tmpPage);
    }

    render() {

        return (
                <PrescriptionWallet
                    { ...this.props }
                    { ...this.state }
                    { ...this.containerFunctions }
                />
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionWalletContainer);
