import {
    CartItemPrice as SourceCartItemPrice,
} from 'SourceComponent/CartItemPrice/CartItemPrice.component';
import { formatPrice, roundPrice } from 'Util/Price';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import './CartItemPrice.style.override'

export class CartItemPrice extends SourceCartItemPrice {
    renderPrice() {
        const {
            item: {
                campaign_price,
                campaign_is_active,
                before_campaign_price,
                qty,
                custom_total_price
            },
            price,
            singlePrice,
            currency_code,
            is_wallet,
            is_walletSub,
            isMiniCart,
            is_checkout,
            isConfirm,
            isSubtotal
        } = this.props;

        let oldPriceTag = <></>;
        let priceToUse = price;
        if(isSubtotal && campaign_price && before_campaign_price && campaign_is_active) {
            priceToUse = custom_total_price;
            let oldPriceVal = parseFloat((singlePrice * qty)).toFixed(2);
            oldPriceTag = <del
                        block="CartItemPrice"
                        elem="OldPriceTag"
                        aria-label={ __('Old product price') }
                    >
                        { formatPrice(roundPrice(oldPriceVal), currency_code) }
                    </del>;
        }

        const value = roundPrice(priceToUse);
        if(priceToUse < 0){
            priceToUse = 0;
        }
        const finalPriceValue = parseFloat(priceToUse).toFixed(2);

        if (is_wallet && (isMiniCart || is_checkout || isConfirm)) {
            return (
                <span aria-label={ __('Current product price') }>
                    { oldPriceTag }
                    <FontAwesomeIcon
                        className="CartItemPrice-AlertIcon"
                        icon={ faExclamationCircle }
                        title={ __('Prescription wallet product. The total values will be available in your pharmacy.') }
                    />
                    { <data value={ value }>{ formatPrice(finalPriceValue, currency_code) }</data> }
                </span>
            );
        }

        return (
            <span aria-label={ __('Current product price') }>
                { oldPriceTag }
                { is_wallet && <>-</> }
                { is_walletSub && <><FontAwesomeIcon className="CartFloating-AlertIcon" icon={ faExclamationCircle } /></> }
                { !is_wallet && <data value={ value }>{ formatPrice(finalPriceValue, currency_code) }</data> }
            </span>
        );
    }

    renderPriceWithPoints() {
        const {
            item: {
                campaign_price,
                campaign_is_active,
                before_campaign_price,
                qty,
                custom_total_price
            },
            price,
            singlePrice,
            currency_code,
            isPointsActive,
            points,
            buyWithPointsFlag,
            isCartPage,
            isMiniCart,
            isConfirm,
            isSubtotal
        } = this.props;

        let priceToUse = price;
        let oldPriceTag = <></>;
        if(isSubtotal && campaign_price && before_campaign_price && campaign_is_active) {
            priceToUse = custom_total_price;
            let oldPriceVal = parseFloat((singlePrice * qty)).toFixed(2);
            oldPriceTag = <del
                        block="CartItemPrice"
                        elem="OldPriceTag"
                        aria-label={ __('Old product price') }
                    >
                        { formatPrice(roundPrice(oldPriceVal), currency_code) }
                    </del>;
        }

        const value = roundPrice(priceToUse);
        const finalPriceValue = parseFloat(priceToUse).toFixed(2);

        if(isPointsActive) {
            return (
                <>
                {(points > 0) && <span aria-label={ __('Current product price') } block="ProductPrice" elem="PointsSelected">
                    <data value={ value }>{ points + __(" Points") }</data>
                </span>}
                <span block="ProductPrice" elem="PriceUnset" aria-label={ __('Current product points') }>
                    <data value={ value }>{ __("or ") + formatPrice(finalPriceValue, currency_code) }</data>
                </span>
                </>
            );
        } else {
            if(buyWithPointsFlag){
                return (
                    <>
                    <span block="ProductPrice" elem="Points" aria-label={ __('Current product points') }>
                        <data value={ value }>{ points + __(" Points") }</data>
                    </span>
                    </>
                );
            } else {
                return (
                    <>
                    <span aria-label={ __('Current product price') } block="ProductPrice" elem="PriceWithPoints">
                        { oldPriceTag }
                        <data value={ value }>{ formatPrice(finalPriceValue, currency_code) }</data>
                    </span>
                    {(points > 0) && <span block="ProductPrice" elem="Points" aria-label={ __('Current product points') }>
                       { (!isConfirm || buyWithPointsFlag) && <data value={ value }>{ __("or ") + points + __(" Points") }</data>}
                    </span>}
                    </>
                );
            }
        }
    }

    renderSubPrice() {
        const { subPrice, currency_code } = this.props;

        if (!subPrice) {
            return null;
        }
        const finalPriceValue = parseFloat(subPrice).toFixed(2);

        return (
            <span
              aria-label={ __('Current product price excl. tax') }
              block="ProductPrice"
              elem="SubPrice"
            >
                { `${ __('Excl. tax:') } ${ formatPrice(finalPriceValue, currency_code) }` }
            </span>
        );
    }

    renderPriceSubTotal() {
        const { price, currency_code } = this.props;
        const value = roundPrice(price);
        const finalPriceValue = parseFloat(price).toFixed(2);


        return (
            <span block="ProductPrice" elem="SubTotal" aria-label={ __('Current product subtotal') }>
                <data value={ value }>{ formatPrice(finalPriceValue, currency_code) }</data>
            </span>
        );
    }

    getSaudaVerify() {
        const { currentProduct } = this.props;
        const { attributes = [] } = currentProduct;

        if (!Object.entries(attributes).length) {
            return null;
        }

        let flagSauda = null;
        try {
            flagSauda = attributes.has_points.attribute_value;
        } catch(err) {
            return null;
        }

        return flagSauda;
    }

    render() {
        const { mix, priceType, points } = this.props;
        let PriceJSX = null;

        if (this.getSaudaVerify() == "1" && points) {
            PriceJSX = <>{ this.renderPriceWithPoints() }</>
        } else {
            PriceJSX = <>{ this.renderPrice() }</>
        }

        return (
            <div block="ProductPrice" aria-label={ __('Product Price') } mix={ mix }>
                { PriceJSX  }
                { this.renderSubPrice() }
            </div>
        );
    }
};

export default CartItemPrice;
