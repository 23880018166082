import { lazy } from 'react';
import { Route } from 'react-router-dom';

import {
    Router as SourceRouter,
    withStoreRegex,
    HomePage,
    SearchPage,
    CmsPage,
    CartPage,
    Checkout,
    PasswordChangePage,
    ConfirmAccountPage,
    MyAccount,
    MenuPage,
    WishlistShared,
    ContactPage
} from 'SourceComponent/Router/Router.component';

import UrlRewrites from 'Route/UrlRewrites';
import Meta from 'Component/Meta';
import IdleTimer from 'react-idle-timer'
import { Router as ReactRouter } from 'react-router';
import history from 'Util/History';
import { isSignedIn } from 'Util/Auth';
import LifeTimerQuery from '../../query/LifeTimer.query';
import Breadcrumbs from 'Component/Breadcrumbs';
import DemoNotice from 'Component/DemoNotice';
import Header from 'Component/Header';
import NavigationTabs from 'Component/NavigationTabs';
import NotificationList from 'Component/NotificationList';
import { executeGet } from 'Util/Request';
import { prepareQuery } from 'Util/Query';

import {
    SWITCH_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE
} from 'Component/Router/Router.config';

export const StoreFinder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/StoreFinder'));
export const StorePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/StorePage'));
export const CheckoutSauda = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/CheckoutSauda'));
export const CheckoutSignin = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/CheckoutSignin'));
export const PostsListing = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/PostsListing'));
export const PostsDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/PostsDetails'));
export const SearchPosts = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/SearchPosts'));
export const StorePageInfo = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/StorePageInfo'));
export const StoreSelector = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/StoreSelector'));
export const SiteMap = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/SiteMap'));
export const AvailableServices = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/AvailableServicesPage'));

export class Router extends SourceRouter {

    state = {
        hasError: false,
        errorDetails: {},
        logoutDone: false,
        timer: 60,
        showTimer: false
    };

    constructor(props) {
        super(props)
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.getTimerConfig()
    }

    getTimerConfig(){
        const query = LifeTimerQuery._getTimer();

        return executeGet(prepareQuery(query), 'Router')
            .then(
                ({ getTimer }) => {
                    this.setState({ timer: getTimer.timer, showTimer: true });
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    handleOnAction (event) {
        this.getOut();
    }

    handleOnActive (event) {
        this.getOut();
    }


    getOut() {
        const { logout, defaultTimer, init } = this.props;
        const { logoutDone, timer } = this.state;
        if(isSignedIn()){
            if(localStorage.getItem('login_time')){
                let loginTime  = new Date(JSON.parse(localStorage.getItem('login_time')));
                let expireTime = new Date(loginTime.setMinutes(loginTime.getMinutes() + parseInt(timer)));
                let nowDate = new Date(Date.now());
                if(nowDate > expireTime && !logoutDone){
                    this.setState({ logoutDone: true });
                    logout();
                    localStorage.removeItem('login_time');
                    init();
                }
            }
        }
    }

    handleOnIdle (event) {
        this.getOut();
    }

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10
        },
        {
            component: <DemoNotice />,
            position: 15
        },
        {
            component: <Header />,
            position: 20
        },
        {
            component: <NavigationTabs />,
            position: 25
        },
        {
            component: <Breadcrumbs />,
            position: 30
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25
        },
        {
            component: <Route path={ withStoreRegex('/search/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 26
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55
        },
        {
            component: <Route path={ withStoreRegex('/:account*/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60
        },
        {
            component: <Route path={ withStoreRegex('/:account*/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 70
        },
        {
            component: <Route path={ withStoreRegex('/forgot-password') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 71
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
            position: 82
        },
        {
            component: <Route path={ withStoreRegex('/localizador-de-farmacias') } render={ (props) => <StoreFinder { ...props } /> } />,
            position: 83
        },
        {
            component: <Route path={ withStoreRegex('/farmacia/:id/') } render={ (props) => <StorePage { ...props } /> } />,
            position: 84
        },
        {
            component: <Route path={ withStoreRegex('/farmacia/') } render={ (props) => <StorePage { ...props } /> } />,
            position: 85
        },
        {
            component: <Route path={ withStoreRegex('/checkout-sauda') } render={ (props) => <CheckoutSauda { ...props } /> } />,
            position: 86
        },
        {
            component: <Route path={ withStoreRegex('/farmacia-info/:id/') } render={ (props) => <StorePageInfo { ...props } /> } />,
            position: 87
        },
        {
            component: <Route path={ withStoreRegex('/farmacia-info/') } render={ (props) => <StorePageInfo { ...props } /> } />,
            position: 88
        },
        {
            component: <Route path={ withStoreRegex('/checkout-signin') } render={ (props) => <CheckoutSignin { ...props } /> } />,
            position: 89
        },
        {
            component: <Route path={ withStoreRegex('/selecionador-de-farmacias') } render={ (props) => <StoreSelector { ...props } /> } />,
            position: 90
        },
        {
            component: <Route path={ withStoreRegex('/blog/search/:query') } component={ (props) => <SearchPosts { ...props } /> } />,
            position: 95
        },
        {
            component: <Route path={ withStoreRegex('/blog/categoria/:categoria') } component={ (props) => <PostsListing { ...props } /> } />,
            position: 100
        },
        {
            component: <Route path={ withStoreRegex('/blog/:handle') } component={ (props) => <PostsDetails { ...props } /> } />,
            position: 105
        },
        {
            component: <Route path={ withStoreRegex('/blog/') } component={ (props) => <PostsListing { ...props } /> } />,
            position: 110
        },
        {
            component: <Route path={ withStoreRegex('/sitemap/:map?') } component={ (props) => <SiteMap { ...props } /> } />,
            position: 114
        },
        {
            component: <Route path={ withStoreRegex('/sitemap/') } component={ (props) => <SiteMap { ...props } /> } />,
            position: 115
        },
        {
            component: <Route path={ withStoreRegex('/servicos/') } component={ (props) => <AvailableServices { ...props } /> } />,
            position: 116
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000
        }
    ];


    render() {
        const { timer, showTimer } = this.state;
        return (
            <>
                <Meta />
                {/* <HeadScripts /> */}
                { showTimer && <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * parseInt(timer)}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                }
                <ReactRouter history={ history }>
                    { this.renderRouterContent() }
                </ReactRouter>
            </>
        );
    }

};

export default Router;
