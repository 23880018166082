import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HealthCardSms from './HealthCardSms.component';
import { showNotification } from 'Store/Notification/Notification.action';
import MyAccountQuery from 'Query/MyAccount.query';
import { fetchQuery } from 'Util/Request';

export const mapStateToProps = (state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (error) => dispatch(showNotification('error', error))
});

export class HealthCardSmsContainer extends PureComponent {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired
    };

    state = {
        isLoading: false,
        showFormNewCard: false,
        showFormAssociate: false,
        generatedCode: '',
        codeConfirmation: '',
        showConfirmationCode: false,
        cardDetails: {},
        positions: {}
    };

    containerFunctions = {
        doQuerySendPhone: this.doQuerySendPhone.bind(this),
        setCodeConfirmation: this.setCodeConfirmation.bind(this)
    };

    setCodeConfirmation(value) {
        this.setState( { codeConfirmation: value } );
    }

    doQuerySendPhone(phone) {
        const { showErrorNotification } = this.props;

        if(phone) {

            this.setState({
                isLoading: true
            });
            const query = MyAccountQuery.sendSmsSauda(phone);
            return fetchQuery(query).then(
                (response) => {
                    if (response.smsSaudaPhone.status) {
                        this.setState({
                            showFormAssociate: true,
                            showFormNewCard: false,
                            cardDetails: response.smsSaudaPhone.cardDetails,
                            positions: response.smsSaudaPhone.positionsInfo
                        });
                    } else {
                        this.setState({
                            showFormAssociate: false,
                            showFormNewCard: true
                        });
                    }

                    this.setState({
                        generatedCode: response.smsSaudaPhone.code,
                        showConfirmationCode: true,
                        isLoading: false
                    });

                    //this.renderNexStep();
                },
                (error) => {
                    console.log(error);
                    showErrorNotification(__('Error verifying your phone number, please check and try again'));
                    this.setState({
                        isLoading: false
                    });
                }
            );

        } else {
            showErrorNotification(__('Please fill all fields.'));
        }
    }

    render() {
        return (
            <HealthCardSms
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthCardSmsContainer);
