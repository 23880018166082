/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';

import { getPostUrl } from '../../util/Posts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

import './BlogPostCard.style';

/** @namespace ScandiPWA/Blog/Component/BlogPostCard/Component */
export class BlogPostCard extends PureComponent {
    static propTypes = {
        post: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.bool
        ]),
        isPlaceholder: PropTypes.bool,
        isCompactOnMobile: PropTypes.bool,
        category: PropTypes.string
    };

    static defaultProps = {
        post: false,
        isPlaceholder: false,
        isCompactOnMobile: false
    };

    renderImage() {
        const {
            post: {
                first_image,
                title
            },
            post,
            category
        } = this.props;

        if (!first_image) {
            return null;
        }

        return (
            <Link to={{
                    pathname: getPostUrl(post),
                    state: { category }
                }}
                title={ __('View %s', title) }
                mix={ { block: 'BlogPostCard', elem: 'ImageWrapper' } }
                onClick={ this.onLinkClick }>
                <div block="BlogPostCard" elem="ImageBackground" style={{ backgroundImage: `url(${first_image})` }}></div>
            </Link>
        );
    }

    renderTitle() {
        const { post: { title }, post, category } = this.props;
        if (!title) {
            return null;
        }

        return (
            <Link to={{
                        pathname: getPostUrl(post),
                        state: { category }
                    }}
                title={ __('View %s', title) }
                onClick={ this.onLinkClick }>
            <div block="BlogPostCard" elem="Title">
                { title }
            </div>
        </Link>
        );
    }

    renderDescription() {
        const { post: { short_content } } = this.props;

        if (!short_content) {
            return null;
        }

        return <Html content={ short_content } />;
    }

    onLinkClick(e) {
        window.scrollTo(0,0);
    }

    renderContents() {
        const { isPlaceholder, post, post: { title }, category } = this.props;

        if (isPlaceholder) {
            return null;
        }

        return (
            <div block="BlogPostCard" elem="ContentWrapper" mods={ { expand: true } }>
                { this.renderImage() }
                <div block="BlogPostCard" elem="Details">
                    { this.renderTitle() }
                    <div block="BlogPostCard" elem="DescriptionWrapper">
                        { this.renderDescription() }
                        <Link
                            to={ getPostUrl(post) + (category ? '/category/' + category : '') }
                            title={ __('View %s', title) }
                            mix={ { block: 'BlogPostCard', elem: 'SeeMoreLink' } }
                            onClick={ this.onLinkClick }
                        >
                            { __('Read more') }
                        </Link>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        const { isPlaceholder, isCompactOnMobile } = this.props;
        return (
            <div block="BlogPostCard" mods={ { isLoading: isPlaceholder, isCompactOnMobile } }>
                { this.renderContents() }
            </div>
        );
    }
}

export default BlogPostCard;
