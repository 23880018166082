import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { customerType } from 'Type/Account';
import { connect } from 'react-redux';
import MyPharmacy from './MyPharmacy.component';
import { StorePageDispatcher } from '../../store/StorePage';
import { showPopup } from 'Store/Popup/Popup.action';
import StorePageQuery from 'Query/StorePage.query';
import { fetchMutation } from 'SourceUtil/Request';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { updateMeta } from 'Store/Meta/Meta.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    storeObj: state.StorePageReducer.storeObj,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    device: state.ConfigReducer.device,
    cartTotals: state.CartReducer.cartTotals,
});

export const mapDispatchToProps = (dispatch) => ({
    requestStore: (id) => {
        StorePageDispatcher.requestStore(dispatch, id);
    },
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    showCartPopup: (payload) => dispatch(showPopup('CartItemsAlert', payload)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
});

export class MyPharmacyContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        requestStores: PropTypes.func.isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        payload: PropTypes.shape({
            id: PropTypes.number
        }).isRequired,
        updateMeta: PropTypes.func.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
        updateContextPharmacy: this.handleUpdateCustomerContextPharmacy.bind(this),
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    componentDidMount() {
        const { requestStore, customer: { preference_pharmacy_code }, updateMeta } = this.props;
        updateMeta({ title: __('Farmácia Online') });

        if(preference_pharmacy_code) {
            requestStore(preference_pharmacy_code);
        }
    }

    componentDidUpdate(prevProps) {
        const { requestStore, customer: { preference_pharmacy_code } } = this.props;
        const { customer: { preference_pharmacy_code: prevCode } } = prevProps;

        if(preference_pharmacy_code != prevCode) {
            requestStore(preference_pharmacy_code);
        }
    }

    handleUpdateCustomerContextPharmacy(pharmacy_code, selectedPharmaUrl) {
        const { updateCart, syncCart, requestCustomerData } = this.props;

        const query = StorePageQuery.getUpdateCustomerContextPharmacy(pharmacy_code);
        fetchMutation(query).then(
            () => {
                this.setState({ isLoading: false }, () => {
                    requestCustomerData();
                    updateCart().then(
                        () => {
                            syncCart();
                            if (BrowserDatabase.getItem('redirect_to')) {
                                const redirect = BrowserDatabase.getItem('redirect_to');
                                BrowserDatabase.deleteItem('redirect_to');
                                history.push(appendWithStoreCode(redirect));
                            } else {
                                history.push(appendWithStoreCode(selectedPharmaUrl ? selectedPharmaUrl : '/'));
                            }
                        }
                    );
                });
            },
            (error) => {
                this.handleError([{message:__('Something went wrong.')}])
            }
        );
    }

    render() {
        return (
            <MyPharmacy
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPharmacyContainer);
