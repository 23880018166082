import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer,
    mapDispatchToProps,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.container';

export {
    mapDispatchToProps,
};

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    isMobile: state.ConfigReducer.device.isMobile,
});

export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {

    collectAdditionalData = () => {
        const { showError, notReady } = this.props;
        if(!this.state) {
            if(!notReady) {
                showError(__('Please select a payment method.'));
            }
            return;
        }
        const { selectedPaymentCode } = this.state;
        const additionalDataGetter = this.dataMap[selectedPaymentCode];
        if (!additionalDataGetter) {
            return {};
        }

        return additionalDataGetter();
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
