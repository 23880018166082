/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import { CHECKOUT, MY_ACCOUNT } from 'Component/Header/Header.config';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    updateCustomerDetails,
    updateCustomerIsAuthTokenExpired,
    updateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading,
    updateCustomerPhoto,
    setCustomerRequestDone,
    updateIsLoadingLogin
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import {
    deleteAuthorizationToken,
    setAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, fetchQuery } from 'Util/Request';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import StorePageQuery from 'Query/StorePage.query';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const CheckoutSaudaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CheckoutSauda/CheckoutSauda.dispatcher'
);

export const CUSTOMER = 'customer';

export const ONE_MONTH_IN_SECONDS = 2628000;
export const ONE_HOUR_IN_SECONDS = 3600;

/**
 * My account actions
 * @class MyAccount
 * @namespace Store/MyAccount/Dispatcher
 */
export class MyAccountDispatcher {
    forceLogoutRedirectPages = [
        CHECKOUT,
        MY_ACCOUNT
    ];

    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        /*const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
            dispatch(setCustomerRequestDone(true));
        }*/
        dispatch(setCustomerRequestDone(false));
        dispatch(updateIsLoading(true));

        return executePost(prepareQuery([query])).then(
            /** @namespace Store/MyAccount/Dispatcher/requestCustomerDataExecutePostThen */
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));
                dispatch(setCustomerRequestDone(true));
                dispatch(updateIsLoading(false));
                BrowserDatabase.setItem(customer, CUSTOMER, (ONE_HOUR_IN_SECONDS*8));
            },
            /** @namespace Store/MyAccount/Dispatcher/requestCustomerDataExecutePostError */
            (error) => {
                console.log(error);
                // history.push(appendWithStoreCode('/localizador-de-farmacias'));
                if(error[0] && error[0].message && error[0].message.trim() != '') {
                    dispatch(showNotification('error', error[0].message));
                }
                dispatch(updateCustomerIsAuthTokenExpired(true));
                dispatch(setCustomerRequestDone(true));
                dispatch(updateIsLoading(false));
                this.handleForceRedirectToLoginPage();
                deleteAuthorizationToken();
            }
        );
    }

    logout(authTokenExpired = false, dispatch) {
        localStorage.removeItem('login_time');
        if (authTokenExpired) {
            dispatch(updateCustomerIsAuthTokenExpired(true));
            this.handleForceRedirectToLoginPage();
        } else {
            deleteAuthorizationToken();
        }
        dispatch(updateCustomerSignInStatus(false));
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.createGuestEmptyCart(dispatch);
                dispatcher.updateInitialCartData(dispatch);
            }
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        dispatch(updateCustomerDetails({}));
        // window.location.reload();

        if (localStorage.getItem('guest_pharmacy_code')) {
            this.restoreCatalogSession(localStorage.getItem('guest_pharmacy_code'));
        } else {
            // redirect home page
            window.location.href = "/";
        }
    }

    restoreCatalogSession(code) {
        const query = StorePageQuery.getUpdateGuestContextPharmacy(code);
            fetchMutation(query).then(
                () => {
                    window.location.href = "/";
                    window.location.reload();
                },
                (error) => {
                    console.log('error restoreCatalogSession', error);
                }
            );
    }

    /**
     * Forgot password action
     * @param {{email: String}} [options={}]
     * @returns {Promise<{status: String}>} Reset password token
     * @memberof MyAccountDispatcher
     */
    forgotPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getForgotPasswordMutation(options);
        return fetchMutation(mutation).then(
            /** @namespace Store/MyAccount/Dispatcher/forgotPasswordFetchMutationThen */
            () => dispatch(updateCustomerPasswordForgotStatus()),
            /** @namespace Store/MyAccount/Dispatcher/forgotPasswordFetchMutationError */
            (error) => dispatch(showNotification('error', error[0].message))
        );
    }

     /**
     * Upload Customer Photo action
     * @param {{email: String, photo: Array}} [options={}]
     * @returns {Promise<{status: String}>} Upload Result status
     * @memberof MyAccountDispatcher
     */
    updateCustomerPhoto(dispatch) {
        const query = MyAccountQuery.getUpdatePhotoMutation(options);

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerPhoto(customerPhoto));
        }

        return executePost(prepareQuery([query])).then(
            /** @namespace Store/MyAccount/Dispatcher/requestCustomerDataExecutePostThen */
            ({ customerPhoto }) => {
                dispatch(updateCustomerPhoto(customerPhoto));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS*8);
            },
            /** @namespace Store/MyAccount/Dispatcher/requestCustomerDataExecutePostError */
            (error) => dispatch(showNotification('error', error[0].message))
        );
    }

    /**
     * Reset password action
     * @param {{token: String, password: String, password_confirmation: String}} [options={}]
     * @returns {Promise<{status: String}>} Reset password token
     * @memberof MyAccountDispatcher
     */
    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutationNew(options);

        return fetchMutation(mutation).then(
            /** @namespace Store/MyAccount/Dispatcher/resetPasswordFetchMutationThen */
            ({ resetPasswordNew: { status } }) => dispatch(updateCustomerPasswordResetStatus(status)),
            /** @namespace Store/MyAccount/Dispatcher/resetPasswordFetchMutationError */
            () => dispatch(updateCustomerPasswordResetStatus('error'))
        );
    }

    /**
     * Create account action
     * @param {{customer: Object, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Store/MyAccount/Dispatcher/createAccountFetchMutationThen */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));
                    return 2;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace Store/MyAccount/Dispatcher/createAccountFetchMutationError */
            (error) => {
                dispatch(showNotification('error', error[0].message));
                Promise.reject();
                dispatch(updateIsLoading(false));

                return false;
            }
        );
    }

    /**
     * Confirm account action
     * @param {{key: String, email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    confirmAccount(options = {}, dispatch) {
        const mutation = MyAccountQuery.getConfirmAccountMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Store/MyAccount/Dispatcher/confirmAccountFetchMutationThen */
            () => {
                localStorage.setItem('account_confirmed', true);
                dispatch(showNotification('success', __('Your account is confirmed!')));
                this.requestCustomerData(dispatch);
            },
            /** @namespace Store/MyAccount/Dispatcher/confirmAccountFetchMutationError */
            () => dispatch(showNotification('error', __('Password is not valid.')))
        );
    }

    /**
     * Sign in action
     * @param {{email: String, password: String, rememberMe: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);
        dispatch(updateIsLoading(true));
        dispatch(updateIsLoadingLogin(true));
        let result = false;
        try {
            result = await fetchMutation(mutation);
        } catch(e) {
            dispatch(updateIsLoading(false));
            dispatch(updateIsLoadingLogin(false));
            throw e;
        }
        const { generateCustomerToken: { token, email, access } } = result;
        const { rememberMe } = options;
        if(rememberMe == true){
            window.localStorage.setItem('email',email);
            window.localStorage.setItem('access',access);
        }
        
        window.localStorage.setItem('rememberMe',rememberMe);

        setAuthorizationToken(token);
        dispatch(updateCustomerSignInStatus(true));
        const updateSaudaCard = MyAccountQuery.updateSaudaCard();
        const resultUpdateSaudaCard = await fetchQuery(updateSaudaCard);
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
            dispatch(setCustomerRequestDone(true));
        }
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        dispatch(updateIsLoading(false));
        dispatch(updateIsLoadingLogin(false));
        //localStorage.removeItem('guest_pharmacy_code');
        //localStorage.removeItem('guest_pharmacy_code_expire_time');


        let customDate = new Date(Date.now());

        localStorage.setItem('login_time', JSON.stringify(customDate));

        return true;
    }

    handleForceRedirectToLoginPage() {
        const { location: { pathname = '' } = {} } = history;
        const doRedirect = this.forceLogoutRedirectPages.reduce((result, urlPart) => {
            if (pathname.includes(urlPart)) {
                return true;
            }

            return result;
        }, false);

        if (doRedirect) {
            history.push({ pathname: '/' });
        }
    }
}

export default new MyAccountDispatcher();
