import {
    CategoryPriceRange as SourceCategoryPriceRange,
} from 'SourceComponent/CategoryPriceRange/CategoryPriceRange.component';

import ExpandableContent from 'Component/ExpandableContent';
import RangeSelector from 'Component/RangeSelector';


export class CategoryPriceRange extends SourceCategoryPriceRange {
    // TODO implement logic
    render() {
        const {
            updatePriceRange,
            priceValue,
            minPriceValue,
            maxPriceValue
        } = this.props;

        const { min: minValue, max: maxValue } = priceValue;
        const min = minValue || minPriceValue;
        const max = maxValue || maxPriceValue;

        if ((maxPriceValue - minPriceValue === 0) && (max - min === 0)) {
            return null;
        }

        return (
            <ExpandableContent
              key="price"
              heading={ __('Price') }
              subHeading={ __('From: %s to %s', min, max) }
              mix={ {
                  block: 'CategoryFilterOverlay',
                  elem: 'Filter',
                  mods: { type: 'price' }
              } }
            >
                <RangeSelector
                  value={ priceValue }
                  minValue={ minPriceValue || min }
                  maxValue={ maxPriceValue || max }
                  onChangeComplete={ updatePriceRange }
                />
            </ExpandableContent>
        );
    }
};

export default CategoryPriceRange;
