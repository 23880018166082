import {
    CategorySort as SourceCategorySort,
} from 'SourceComponent/CategorySort/CategorySort.component';

import './CategorySort.style.override'
import Field from 'Component/Field';
import TextPlaceholder from 'Component/TextPlaceholder';

export class CategorySort extends SourceCategorySort {
    renderPlaceholder() {
        return (
            <p block="CategorySort" elem="Placeholder">
                <TextPlaceholder length="short" />
            </p>
        );
    }

    renderSortField() {
        const {
            sortKey,
            sortDirection,
            selectOptions,
            isMatchingInfoFilter
        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderPlaceholder();
        }

        const newSelectOptions = selectOptions.filter(({ name }) => name !== 'points');

        return (
            <Field
              id="category-sort"
              name="category-sort"
              type="select"
              label={__("SORT")}
              mix={ { block: 'CategorySort', elem: 'Select' } }
              selectOptions={ newSelectOptions }
              value={ `${sortDirection} ${sortKey}` }
              onChange={ this.onChange }
            />
        );
    }

    render() {
        return (
            <div block="CategorySort">
                { this.renderSortField() }
            </div>
        );
    }
};

export default CategorySort;
