
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';

import './AvailableServicesPage.style';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";

export class AvailableServicesPage extends PureComponent {

    state = {
        cmsIdentifier: false,
        selectedService: false
    };

    __construct(props) {
        super.__construct(props);
    }

    /**
     * Renders the column which shows all available services
     *
     */
    renderLeftColumn() {
        const { services } = this.props;
        // sort services by name
        services.sort((a,b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
                return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
                return 1;
            }
            // names are equal
            return 0;
        })
        let servicesColumn = [];
        // create services list
        if ((services && services.length > 0)) {
            let index = 0;
            services.forEach((service) => {
                if (service.show_in_services_page && service.active && service.assoc_cms_block) {
                    // set first service as selected
                    if (index === 0 && !this.state.cmsIdentifier) {
                        this.setState({ cmsIdentifier: service.assoc_cms_block, selectedService: service.entity_id });
                    }
                    servicesColumn.push(this.renderServiceButton(service));
                    index += 1;
                }
            })
        }
        return (
            <div block="AvailableServicesPage" elem="LeftColumn">
                {servicesColumn}
            </div>
        )
    }

    renderServiceButton(service) {

        return (
            <li block="AvailableServicesPage" elem="ServiceButton" id={service.name} className={service.assoc_cms_block === this.state.cmsIdentifier ? 'active' : ''}>
                <button onClick={(e) => {
                    this.updateCmsComponent(e, service)
                } }>{service.name}</button>
                <FontAwesomeIcon className="service-arrow" icon={ faChevronRight } />
            </li>
        )
    }

    /**
     * This button redirects the user to the Pharmacies search page with a filter applied for the service selected
     *
     */
    renderSearchButton() {
        return (
            <div block="AvailableServicesPage" elem="SearchButton">
                <button>
                    <Link
                        to={"/localizador-de-farmacias?searchingService=" + this.state.selectedService}
                    >
                        { __('Pesquisar nas Farmácias') }
                    </Link>
                </button>

            </div>
        )
    }


    /**
     * Update which service is selected
     * @param event
     * @param service
     */
    updateCmsComponent(event, service) {
        this.setState({ cmsIdentifier: service.assoc_cms_block, selectedService: service.entity_id });

    }

    /**
     * Render personalized cms block depending on which service is selected by user
     *
     */
    renderCmsContent() {
        if (this.state.cmsIdentifier) {
            return (
                <div block="AvailableServicesPage" elem="ServiceContent">
                    {this.renderSearchButton()}
                    <CmsBlock key={this.state.cmsIdentifier} identifier={ this.state.cmsIdentifier } />
                </div>

            )
        }
        return (
            <div block="AvailableServicesPage" elem="ServiceContent">
                <div>
                    <h3>
                        De momento, esta página está vazia.
                        Tente novamente mais tarde.
                    </h3>
                </div>
            </div>
        )
    }


    render() {
        return (
            <main block="AvailableServicesPage" elem="Container">
                <h1>{ __('Serviços') }</h1>
                {this.renderLeftColumn()}
                {this.renderCmsContent()}
            </main>
        );
    }
}
export default AvailableServicesPage;

