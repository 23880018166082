import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyAccountMyOrdersDetails from './MyAccountMyOrdersDetails.component';

import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { orderType } from 'Type/Account';
import { getIndexedProducts } from 'Util/Product';
import { fetchQuery } from 'Util/Request';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

export const mapStateToProps = (state) => ({
    order: state.OrderReducer.order,
    currency_code: state.ConfigReducer.default_display_currency_code,
    customer: state.MyAccountReducer.customer
});

export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getOrder: (orderId) => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getOrderById(dispatch, orderId)
    )
});

export class MyAccountMyOrdersDetailsContainer extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            order: orderType,
            increment_id: PropTypes.string
        }).isRequired,
        showNotification: PropTypes.func.isRequired,
        getOrder: PropTypes.func.isRequired,
        currency_code: PropTypes.string.isRequired,
        order: orderType.isRequired,
        reBuy: PropTypes.func.isRequired
    };

    state = {
        order: {},
        prevOrderId: 0,
        isLoading: true
    };

    static getDerivedStateFromProps(props, state) {
        const { orderDetails, orderDetails: { base_order_info: { increment_id: id } } } = props;
        const { prevOrderId } = state;
        const order = orderDetails;

        if (prevOrderId === id) {
            return null;
        }

        return { order: {}, isLoading: true, prevOrderId: id };
    }

    componentDidUpdate(prevProps) {
        //const { payload: { increment_id: prevId } } = prevProps;
        const { orderDetails: { base_order_info: { increment_id: id } } } = this.props;
        const { prevOrderId } = this.state;

        if (id !== prevOrderId) {
            this.setState({prevOrderId: id});
            //this.requestOrderDetails();
        }
    }

    containerProps = () => {
        const { order: stateOrder, isLoading } = this.state;
        const { payload: { order: payloadOrder }, currency_code } = this.props;

        return {
            isLoading,
            currency_code,
            order: {
                ...payloadOrder,
                ...stateOrder
            }
        };
    };

    containerFunctions = {
        requestOrderDetails: this.requestOrderDetails.bind(this),
        clickRebuy: this.clickRebuy.bind(this)
    };

    requestOrderDetails() {
        const {
                orderDetails: {
                    base_order_info: { id } = {}
                } = {}
        } = this.props;

        this.setState({ isLoading: true });

        fetchQuery(OrderQuery.getOrderByIdQuery(id)).then(
            /** @namespace Component/MyAccountOrderPopup/Container/requestOrderDetailsFetchQueryThen */
            ({ getOrderById: rawOrder }) => {
                const { order_products = [] } = rawOrder;
                const indexedProducts = getIndexedProducts(order_products);
                const order = { ...rawOrder, order_products: indexedProducts };
                this.setState({ order, isLoading: false });
            },
            /** @namespace Component/MyAccountOrderPopup/Container/requestOrderDetailsFetchQueryCatch */
            () => {
                showNotification('error', __('Error getting Order by ID!'));
                this.setState({ isLoading: false });
            }
        );
    }

    clickRebuy(id){
        const { reBuy } = this.props;

        reBuy(id);
    }

    render() {
        return (
            <MyAccountMyOrdersDetails
              { ...this.containerFunctions }
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountMyOrdersDetailsContainer);
