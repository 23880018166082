import {
    SearchItem as SourceSearchItem,
} from 'SourceComponent/SearchItem/SearchItem.component';
import PropTypes from 'prop-types';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import TextPlaceholder from 'Component/TextPlaceholder';
import { AttributeType, ProductType } from 'Type/ProductList';

import './SearchItem.style'
import TierPrices from 'Component/TierPrices';

export class SearchItem extends SourceSearchItem {
    static propTypes = {
        linkTo: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({})
        ]),
        imgSrc: PropTypes.string,
        customAttribute: AttributeType,
        product: ProductType,
        onClick: PropTypes.func.isRequired,
        postsItems: PropTypes.oneOfType(
            PropTypes.string,
            PropTypes.shape({})
            )
    };

    static defaultProps = {
        linkTo: {},
        imgSrc: '',
        customAttribute: null,
        product: {},
        postsItems: {}
    };

    renderContentPost() {
        const { postsItems: { title } } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                <h4 block="SearchItem" elem="Title" mods={ { isLoaded: !!title } }>
                    <TextPlaceholder content={ title } length="long" />
                </h4>
            </figcaption>
        );
    }

    renderImagePost() {
        const {
            postsItems: { title, featured_image }
        } = this.props;

        if (title && !featured_image) {
            return (
                <div
                  block="SearchItem"
                  elem="Image"
                />
            );
        }

        return (
            <Image
              block="SearchItem"
              elem="Image"
              src={ featured_image }
              alt={ __('%s', title) }
              isPlaceholder={ !title }
            />
        );
    }

    renderLinkBlog() {
        const { postsItems: { identifier }, onClick } = this.props;
        return (
            <Link
              block="SearchItem"
              elem="Link"
              to={ '/blog/'+identifier }
              onClick={ onClick }
            >
                <figure
                        block="SearchItem"
                        elem="Wrapper"
                    >
                    { this.renderImagePost() }
                    { this.renderContentPost() }
                </figure>
            </Link>
        );

        /*return (
            <Link
              block="SearchItem"
              elem="Link"
              to={ linkTo }
              onClick={ onClick }
            >
                <figure
                  block="SearchItem"
                  elem="Wrapper"
                >
                    { this.renderImagePost() }
                    { this.renderContentPost() }
                </figure>
            </Link>
        );*/
    }

    renderImage() {
        const {
            product: { name },
            imgSrc,
            product
        } = this.props;

        let img = '';

        let prices = [];

        if(product && product.variants && product.variants.length > 0){
            for(let i=0; i<product.variants.length; i++){
                let price = {
                    'price': product.variants[i].price_range.maximal_price.final_price.value,
                    'key': i,
                    'img': product.variants[i].thumbnail.url

                };

                prices.push(price);
            }

            let oldPrice = 0;

            if(prices.length > 0){
                for(let j=0; j<prices.length; j++){
                    if(img == ''){
                        img      = prices[j].img;
                        oldPrice = prices[j].price;
                    } else {
                        if(prices[j].price < oldPrice){
                            img      = prices[j].img;
                            oldPrice = prices[j].price;
                        }
                    }
                }
            }
        } else {
            img = imgSrc;
        }

        if (name && !img) {
            return (
                <div
                  block="SearchItem"
                  elem="Image"
                />
            );
        }

        return (
            <Image
              block="SearchItem"
              elem="Image"
              src={ img }
              alt={ __('Product %s thumbnail.', name) }
              isPlaceholder={ !name }
            />
        );
    }

    renderContent() {
        const { product: { name } } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                { /*this.renderCustomAttribute()*/ }
                <h4 block="SearchItem" elem="Title" mods={ { isLoaded: !!name } }>
                    <TextPlaceholder content={ name } length="long" />
                </h4>
            </figcaption>
        );
    }


    render() {

        const { postsItems } = this.props;

        if (postsItems && postsItems.post_id) {
            return (
                <li block="SearchItem">
                    { this.renderLinkBlog() }
                </li>
            );
        } else {
            return (
                <li block="SearchItem">
                    { this.renderLink() }
                </li>
            );
        }
    }
};

export default SearchItem;
