import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import Field from './Field.component';
import {
    CHECKBOX_TYPE,
    ENTER_KEY_CODE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE,
    DATE_TYPE,
    FILE_TYPE,
    BASE64_TYPE,
    PHONE_TYPE
} from './Field.config';


/** @namespace Component/Field/Container */
export class FieldContainer extends PureComponent {
    static propTypes = {
        isControlled: PropTypes.bool,
        checked: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        type: PropTypes.oneOf([
            TEXT_TYPE,
            NUMBER_TYPE,
            TEXTAREA_TYPE,
            PASSWORD_TYPE,
            RADIO_TYPE,
            CHECKBOX_TYPE,
            SELECT_TYPE
        ]).isRequired,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        onKeyPress: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number,
        showNotification: PropTypes.func.isRequired,
        full_line: PropTypes.bool,
        full_width: PropTypes.bool
    };

    static defaultProps = {
        min: 1,
        max: 99,
        checked: false,
        value: null,
        onChange: () => {},
        onFocus: () => {},
        onBlur: () => {},
        onClick: () => {},
        showError: () => {},
        onKeyPress: () => {},
        isControlled: false,
        full_line: false,
        full_width: false
    };

    containerFunctions = {
        onChange: this.onChange.bind(this),
        handleChange: this.handleChange.bind(this),
        onChangeCheckbox: this.onChangeCheckbox.bind(this),
        onFocus: this.onFocus.bind(this),
        onKeyPress: this.onKeyPress.bind(this),
        onKeyEnterDown: this.onKeyEnterDown.bind(this),
        onClick: this.onClick.bind(this),
        showError: this.showError.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        const { checked } = props;
        const value = this.getInitialPropsValue();

        this.state = {
            value,
            checked
        };
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue, checked: prevChecked } = prevProps;
        const { value: currentValue, checked: currChecked, type } = this.props;

        if (prevValue !== currentValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: currentValue });
        }
        if (type === CHECKBOX_TYPE && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }
    }

    getInitialPropsValue() {
        const { type, value } = this.props;

        /*if(!value && type == PHONE_TYPE) {
            return '+351';
        }*/

        if (value) {
            return value;
        }

        switch (type) {
        case NUMBER_TYPE:
            return 0;
        case CHECKBOX_TYPE:
            return false;
        default:
            return '';
        }
    }

    containerProps = () => {
        const {
            checked: propsChecked
        } = this.props;

        const {
            type,
            checked,
            value
        } = this.state;

        return {
            checked: type === CHECKBOX_TYPE ? propsChecked : checked,
            value
        };
    };

    showError(error) {
        const { showNotification } = this.props;
        showNotification('error', error);
    }

    onChange(event) {
        if (typeof event === 'string' || typeof event === 'number') {
            return this.handleChange(false, event);
        }

        return this.handleChange(event, event.target.value);
    }

    onChangeCheckbox(event) {
        const { onChange } = this.props;
        const { target: { checked, value } } = event;

        if (onChange) {
            onChange(value, checked);
        }

        this.setState({ checked });
    }

    onFocus(event) {
        const { onFocus } = this.props;

        if (onFocus) {
            onFocus(event);
        }
    }

    onBlur(event) {
        const { onBlur } = this.props;

        if (onBlur) {
            onBlur(event);
        }
    }

    onKeyPress(event) {
        const { onKeyPress } = this.props;

        if (onKeyPress) {
            onKeyPress(event);
        }
    }

    onKeyEnterDown(event) {
        if (event.keyCode === ENTER_KEY_CODE) {
            const value = event.target.value || '';
            this.handleChange(event, value);
        }
    }

    onClick(event, selectValue = false) {
        const { onClick } = this.props;

        if (selectValue) {
            event.target.select();
        }
        if (onClick) {
            onClick(event);
        }
    }

    handleChange(e, value, shouldUpdate = true, isPhone = false, isNumeric = false, isPostcode = false) {
        const {
            isControlled,
            onChange,
            type,
            min,
            max
        } = this.props;

        switch (type) {
            case NUMBER_TYPE:
                const isValueNaN = Number.isNaN(parseInt(value, 10));
                if (min > value || value > max || isValueNaN) {
                    break;
                }
                if (onChange && shouldUpdate) {
                    onChange(value);
                }
                if (!isControlled) {
                    this.setState({ value });
                }
                break;
            default:
                /*if(isPhone) {
                    if(!value || value == '+35' || value == '+3' || value == '+') {
                        value = '+351';
                    }

                    if(value.length > 13) {
                        value = value.slice(0, -1)
                    }

                    if (value && !value.includes('+351')) {
                        value = '+351'+value;
                    }
                }*/
                if(isPostcode) {
                    //allow only numbers and '-'
                    value = value.replace(/[^0-9-]/g, '');
                    if(value.length == 4 && !value.includes('-')) {
                        if(e.keyCode == 8) {
                            value =  value.substring(0, value.length - 1);
                        } else {
                            value = value + '-';
                        }
                    } else if (value.match(/([0-9]{4})([0-9]*)-/)){
                        value = value.replace(/([0-9]{4})([0-9]*)-/, '$1-$2');
                    }
                }
                if(isNumeric) {
                    //allow only numbers
                    value = value.replace(/[^0-9]/g, '');
                }

                if (isPhone) {
                    const regExp = /[ `!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~a-zA-Z]/g;

                    if(regExp.test(value)){
                        //allow only numbers
                        value = value.replace(/[^0-9]/g, '');
                    }
                }

                if (onChange) {
                    onChange(value);
                }
                if (!isControlled) {
                    this.setState({ value });
                }
        }
    }

    render() {
        return (
            <Field
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldContainer;
