import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './HealthCardVouchersSlider.style';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faChevronCircleRight, faChevronCircleLeft } from '@fortawesome/pro-light-svg-icons';

class HealthCardVouchersSlider extends PureComponent {
    static propTypes = {
        cards: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    renderRightArrow() {
        return (
            <div>
                <FontAwesomeIcon class="vouchers-arrow-right" icon={faChevronCircleRight} />
            </div>
        );
    }

    renderLeftArrow() {
        return (
            <div>
                <FontAwesomeIcon class="vouchers-arrow-left" icon={faChevronCircleLeft} />
            </div>
        );
    }

    render() {
        const { cards } = this.props;

        if (cards.length > 0) {
            var settings = {
                dots: false,
                arrows: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                adaptiveHeight: true,
                nextArrow: this.renderRightArrow(),
                prevArrow: this.renderLeftArrow(),
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: false,
                            dots: false,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                            arrows: false,
                            className: "center",
                            centerMode: true,
                            centerPadding: "70px"
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                            arrows: false,
                            className: "center",
                            centerMode: true,
                            centerPadding: "70px"
                        }
                    }
                ]
            };
            return (
                <div class="custom-slick HealthCardVouchersSliderSlick">
                    <Slider {...settings}>
                        {cards}
                    </Slider>
                </div>
            );
        }
    }
}

export default HealthCardVouchersSlider;
