/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { WISHLIST_OVERLAY } from 'Component/Header/Header.config';
import Overlay from 'Component/Overlay';
import { OVERLAY_PLACEHOLDER } from 'Component/PopupSuspense/PopupSuspense.config';
import { DeviceType } from 'Type/Device';

import './WishlistOverlay.style';

/** @namespace Component/WishlistOverlay/Component */
export class WishlistOverlay extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
    };

    componentDidMount() {
        const { showOverlay, device, activeOverlay } = this.props;
        if (!device.isMobile && activeOverlay === OVERLAY_PLACEHOLDER) {
            showOverlay(WISHLIST_OVERLAY);
        }
    }

    render() {
        const { changeHeaderState } = this.props;

        return (
            <Overlay
              id={ WISHLIST_OVERLAY }
              onVisible={ changeHeaderState }
              mix={ { block: 'WishlistOverlay' } }
            >
                <p>{ __('Wishlist Content') }</p>
            </Overlay>
        );
    }
}

export default WishlistOverlay;
