/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm';
import { customerType } from 'Type/Account';

/** @namespace Component/MyAccountCoPaymentsForm/Component */
export class MyAccountCoPaymentsForm extends FieldForm {
    static propTypes = {
        handleCoPayments: PropTypes.func.isRequired,
        handleDeleteCoPayments: PropTypes.func.isRequired,
        customer: customerType.isRequired,
    };

    onFormSuccess = (fields) => {
        const { handleCoPayments } = this.props;
        handleCoPayments(fields);
    };

    get fieldMap() {

        const { customer } = this.props;

        const coPaymentList = this.getSelectOptions(customer.coPaymentList);

        return {
            card_name: {
                label: __('Full name'),
                validation: ['notEmpty', 'full_name'],
                maxlength: 100
            },
            co_payment_system_code: {
                type: 'select',
                label: __('Co-Payment System'),
                placeholder: __('Select the co-payment system.'),
                validation: ['notEmpty'],
                selectOptions: coPaymentList
            },
            co_payment_system_number: {
                label: __('Co-Payment System Number'),
                validation: ['notEmpty'],
                maxlength: 25
            },
            photo_card_front: {
                type: 'image-base64',
                label: __('Co-Payment card photo (front)'),
                validation: ['notEmpty']
            },
            photo_card_back: {
                type: 'image-base64',
                label: __('Co-Payment card photo (back)')
            }
        };
    }

    renderActions() {
        const { currentCoPayment,showEditForm } = this.props;
        return (<>
            <button block="Button" mix={ { block: 'MyAccount', elem: 'Button' } }>
                { __('Save') }
            </button>
            <button
            block="Button"
            mix={ { block: 'MyAccount', elem: 'Cancel' } }
            onClick={ showEditForm }
            >
                { __('Cancel') }
            </button>
            </>
        );
    }

    getSelectOptions(items){
        const copy = [];
        items.forEach(function(item){
            copy.push({'label' : item.name, 'value' : item.code, 'id' : item.entity_id});
        });

        return copy;
    }

    getDefaultValues(fieldEntry) {
        const [key, { value }] = fieldEntry;
        const { currentCoPayment } = this.props;
        const { currentCoPayment: { [key]: coPaymentsValue } } = this.props;
        return {
            ...super.getDefaultValues(fieldEntry),
            value: value !== undefined ? value : coPaymentsValue
        };
    }
}

export default MyAccountCoPaymentsForm;
