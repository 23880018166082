import { connect } from 'react-redux';

import {
    BreadcrumbsDispatcher as SourceBreadcrumbsDispatcher,
    CategoryDispatcher as SourceCategoryDispatcher,
    MetaDispatcher as SourceMetaDispatcher,
    NoMatchDispatcher as SourceNoMatchDispatcher,
    ProductListInfoDispatcher as SourceProductListInfoDispatcher,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    SearchPageContainer as SourceSearchPageContainer,
} from 'SourceRoute/SearchPage/SearchPage.container';
import PropTypes from 'prop-types';
import { customerType } from 'Type/Account';
import SearchPage from './SearchPage.component';
import { showNotification } from 'Store/Notification/Notification.action';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { debounce } from 'Util/Request';
import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import {
    getQueryParam,
} from 'Util/Url';
import { showPopup } from 'Store/Popup/Popup.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';

//TODO: implement BreadcrumbsDispatcher
export const BreadcrumbsDispatcher = SourceBreadcrumbsDispatcher;

//TODO: implement CategoryDispatcher
export const CategoryDispatcher = SourceCategoryDispatcher;

//TODO: implement MetaDispatcher
export const MetaDispatcher = SourceMetaDispatcher;

//TODO: implement NoMatchDispatcher
export const NoMatchDispatcher = SourceNoMatchDispatcher;

//TODO: implement ProductListInfoDispatcher
export const ProductListInfoDispatcher = SourceProductListInfoDispatcher;

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    totalItems: state.ProductListReducer.totalItems,
    isLoading: state.ProductListReducer.isLoading,
    favStoreObj: state.StorePageReducer.favStoreObj,
    categoryIds: 2,
    activeOverlay: state.OverlayReducer.activeOverlay
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    toggleBreadcrumbs: (state) => dispatch(toggleBreadcrumbs(state)),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
    // TODO extend mapDispatchToProps
});

export class SearchPageContainer extends SourceSearchPageContainer {
    // TODO implement logic
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        customer: customerType.isRequired
    }

    containerFunctions = {
        onSortChange: this.onSortChange.bind(this),
        checkIsFilterActive: this.checkIsFilterActive.bind(this)
    }

    componentDidMount() {
        this.updateMeta();
        this.updateBreadcrumbs();
        this.updateHeaderState();
        this.updateNavigationState();

        this.requestCategory();
    }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            match: { params: { query } },
            totalItems,
            favStoreObj: { pharmacy_code }
        } = this.props;

        const {
            match: { params: { query: prevQuery } },
            totalItems: prevTotalItems,
            favStoreObj: { pharmacy_code: prevCode }
        } = prevProps;

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * If search query has changed - update related information
         */
        if (query !== prevQuery) {
            this.updateMeta();
            this.updateBreadcrumbs();
            this.updateHeaderState();
        }

        if (prevTotalItems != totalItems) {
            this.updateBreadcrumbs();
        }

        /**
         * If the currently loaded category ID does not match the ID of
         * category from URL rewrite, request category.
         */
        if (prevCode && prevCode != pharmacy_code) {
            this.requestCategory();
        }
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs, toggleBreadcrumbs, totalItems } = this.props;

        if (!totalItems) {
            toggleBreadcrumbs(false);
        } else {
            const search = this.getSearchParam();

            updateBreadcrumbs([{
                url: '',
                name: __('Results for:') + ' ' + search
            }, {
                url: '/',
                name: __('Home')
            }]);
        }
    }

    checkIsFilterActive() {
        let currentUrl = window.location.href;

        let allFilters = currentUrl.split("?");
        let filters = allFilters[1] ? allFilters[1].split("&") : [];

        return filters.length > 0;
    }

    getSearchParam() {
        const { match: { params: { query } } } = this.props;
        return decodeURIComponent(query);
    }

    /** Rewrite getSelectedSortFromUrl to not read from magento category default_sort_by */
    getSelectedSortFromUrl() {
        const {
            location
        } = this.props;

        const {
            sortKey: globalDefaultSortKey,
            sortDirection: defaultSortDirection
        } = this.config;

        /**
         * Default SORT DIRECTION is taken from (sequentially):
         * - URL param "sortDirection"
         * - CategoryPage class property "config"
         * */
        const sortDirection = getQueryParam('sortDirection', location) || defaultSortDirection;

        /**
         * Default SORT KEY is taken from (sequentially):
         * - URL param "sortKey"
         * - CategoryPage class property "config"
         * */
        const sortKey = getQueryParam('sortKey', location) || globalDefaultSortKey;

        return {
            sortDirection,
            sortKey
        };
    }

    render() {
        return (
            <SearchPage
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
              // addded here to not override the container props
              search={ this.getSearchParam() }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageContainer);
