import {
    PDP as SourcePDP,
    POPUP as SourcePOPUP,
    CATEGORY as SourceCATEGORY,
    CUSTOMER_ACCOUNT as SourceCUSTOMER_ACCOUNT,
    CUSTOMER_SUB_ACCOUNT as SourceCUSTOMER_SUB_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE as SourceCUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_WISHLIST as SourceCUSTOMER_WISHLIST,
    HOME_PAGE as SourceHOME_PAGE,
    MENU as SourceMENU,
    MENU_SUBCATEGORY as SourceMENU_SUBCATEGORY,
    SEARCH as SourceSEARCH,
    FILTER as SourceFILTER,
    CART as SourceCART,
    CART_OVERLAY as SourceCART_OVERLAY,
    CART_EDITING as SourceCART_EDITING,
    CHECKOUT as SourceCHECKOUT,
    CHECKOUT_ACCOUNT as SourceCHECKOUT_ACCOUNT,
    CMS_PAGE as SourceCMS_PAGE,
    MY_ACCOUNT as SourceMY_ACCOUNT,
    NO_MATCH as SourceNO_MATCH
} from 'SourceComponent/Header/Header.config';

export const PDP = SourcePDP;
export const POPUP = SourcePOPUP;
export const CATEGORY = SourceCATEGORY;
export const CUSTOMER_ACCOUNT = SourceCUSTOMER_ACCOUNT;
export const CUSTOMER_SUB_ACCOUNT = SourceCUSTOMER_SUB_ACCOUNT;
export const CUSTOMER_ACCOUNT_PAGE = SourceCUSTOMER_ACCOUNT_PAGE;
export const CUSTOMER_WISHLIST = SourceCUSTOMER_WISHLIST;
export const HOME_PAGE = SourceHOME_PAGE;
export const MENU = SourceMENU;
export const MENU_SUBCATEGORY = SourceMENU_SUBCATEGORY;
export const SEARCH = SourceSEARCH;
export const FILTER = SourceFILTER;
export const CART = SourceCART;
export const CART_OVERLAY = SourceCART_OVERLAY;
export const CART_EDITING = SourceCART_EDITING;
export const CHECKOUT = SourceCHECKOUT;
export const CHECKOUT_ACCOUNT = SourceCHECKOUT_ACCOUNT;
export const CMS_PAGE = SourceCMS_PAGE;
export const MY_ACCOUNT = SourceMY_ACCOUNT;
export const NO_MATCH = SourceNO_MATCH;
export const WISHLIST_OVERLAY = 'wishlist_overlay';
export const WISHLIST = 'wishlist';
export const WISHLIST_EDITING = 'wishlist_editing';