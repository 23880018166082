import {
    WishlistItem as SourceWishlistItem,
} from 'SourceComponent/WishlistItem/WishlistItem.component';

import './WishlistItem.style.override'
import ProductCard from 'Component/ProductCard';
import PropTypes from 'prop-types';
import Image from 'Component/Image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';

export class WishlistItem extends SourceWishlistItem {
    // TODO implement logic
    getSaudaVerify() {
        const { getCurrentProduct } = this.props;
        const { attributes = [] } = getCurrentProduct() || {};

        if (!Object.entries(attributes).length) {
            return null;
        }

        let sauda = null;
        try {
            sauda = attributes.has_points.attribute_value;
        } catch(err) {
            return null;
        }

        return sauda;
    }


    renderContentMobile({
        content: { productPrice },
        pictureBlock: { picture: renderPicture },
        renderCardLinkWrapper
    }) {
        const { isEditingActive } = this.props;

        let saudaIcon =  isMobile ? <img
            src={ icon_sauda_product }
            width="30px"
            height="40px"
            block="ProductCard"
            elem="SaudaProduct"
            alt={ __('Sauda Card') }
        />: <img
            src={ icon_sauda_product }
            width="60px"
            height="70px"
            block="ProductCard"
            elem="SaudaProduct"
            alt={ __('Sauda Card') }
        />;
        return (
            <>
            { this.getSaudaVerify()=="1" ?
                <img
                            src={ icon_sauda_product }
                            width="60px"
                            height="70px"
                            block="ProductCard"
                            elem="SaudaProduct"
                    /> : null }
                <div block="ProductCard" elem="Figure-Review">
                    <figure block="ProductCard" elem="Figure">
                        { this.renderPicture() }
                    </figure>
                    { this.renderReviews() }
                </div>

                { renderRemove }
                <div block="ProductCard" elem="Content">
                    { this.renderAdditionalProductDetails() }
                    { this.renderMainDetails() }
                    { this.renderProductPrice() }
                    { this.renderVisualConfigurableOptions() }
                </div>
            </>
        );
    }

    renderContent = (renderMethods) => {
        const {
            content: { productPrice },
            pictureBlock: { picture: renderPicture },
            renderCardLinkWrapper
        } = renderMethods;

        const { isMobile } = this.props;

        if (isMobile) {
            return this.renderContentMobile(renderMethods);
        }

        return (
            <>
                <div block="WishlistItem" elem="FigureWrapper">
                { this.getSaudaVerify()=="1" ?
                    <img
                            src={ icon_sauda_product }
                            width="60px"
                            height="70px"
                            block="ProductCard"
                            elem="SaudaProduct"
                    /> : null }
                    { renderCardLinkWrapper(
                        <>
                            <figure mix={ { block: 'ProductCard', elem: 'Figure' } }>
                                { renderPicture({ block: 'WishlistItem', elem: 'Picture' }) }
                            </figure>
                            { this.renderName() }
                            { this.renderAttributes() }
                        </>
                    ) }
                    { this.renderRemove() }
                </div>
                <div block="WishlistItem" elem="Content">
                    <div block="WishlistItem" elem="RowWrapper">
                        { this.renderQuantityField() }
                        { this.renderPrice(productPrice) }
                    </div>
                    { this.renderDescription() }
                    { this.renderAddToCartButton() }
                </div>
            </>
        );
    };

    renderRemove() {
        const { removeItem } = this.props;

        return (
            <button
              block="WishlistItem"
              elem="Remove"
              onClick={ removeItem }
              aria-label={ __('Remove') }
            >
                <FontAwesomeIcon icon={ faHeart } />
            </button>
        );
    }

    render() {
        const { isLoading, isMobile } = this.props;
        let wishList = true;

        if (isMobile) {
            return (
                <ProductCard
                product={ this.getWishlistProduct() }
                mix={ { block: 'WishlistItem', elem: 'ProductCard' } }
                isLoading={ isLoading }
                renderContent={ this.renderContent }
                hideWishlistButton
                >
                    { this.renderRemove() }
                </ProductCard>
            );
        }
        return (
            <ProductCard
              product={ this.getWishlistProduct() }
              mix={ { block: 'WishlistItem', elem: 'ProductCard' } }
              isLoading={ isLoading }
              renderRemove={ this.renderRemove() }
              renderWishList={ wishList }
              hideWishlistButton
            />
        );
    }
};

export default WishlistItem;
