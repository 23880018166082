import {
    CheckoutShipping as SourceCheckoutShipping,
} from 'SourceComponent/CheckoutShipping/CheckoutShipping.component';

import Form from 'Component/Form';
import Loader from 'Component/Loader';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import CheckoutGuestForm from 'Component/CheckoutGuestForm';
import CheckoutPharmacy from 'Component/CheckoutPharmacy';
import CheckoutAddressBook from 'Component/CheckoutAddressBook';

import './CheckoutShipping.style.override'
import { propTypes } from 'react-barcode';
import PropTypes from 'prop-types';

export class CheckoutShipping extends SourceCheckoutShipping {

    static propTypes = {
        setIsEditing: PropTypes.func.isRequired,
        addressNotStored: PropTypes.arrayOf(PropTypes.string).isRequired,
        setAddressTmpCreating: PropTypes.func.isRequired,
        addressTmpCreating: PropTypes.bool.isRequired,
        setNotReady: PropTypes.func.isRequired,
        setAddressBillingNotStored: PropTypes.func.isRequired,
        onShippingMethodSelect: PropTypes.func.isRequired,
        setCheckoutEditAddress: PropTypes.func.isRequired,
        zipCodeValid: PropTypes.bool.isRequired
    }

    renderGuestForm() {
        const {
            isCreateUser,
            onEmailChange,
            onCreateUserChange,
            onPasswordChange,
            isGuestEmailSaved
        } = this.props;

        return (
            <CheckoutGuestForm
              isBilling={ false }
              isCreateUser={ isCreateUser }
              onEmailChange={ onEmailChange }
              onCreateUserChange={ onCreateUserChange }
              onPasswordChange={ onPasswordChange }
              isGuestEmailSaved={ isGuestEmailSaved }
            />
        );
    }

    renderContentByMethod() {
        const { selectedShippingMethod, customer, onAddressSelect, onShippingEstimationFieldsChange, changeFreeDelivery, onShippingMethodSelect } = this.props;

        if(selectedShippingMethod && selectedShippingMethod.carrier_code  == 'pharmacy_pickup') {
            changeFreeDelivery(true);
            onShippingMethodSelect(selectedShippingMethod);
            if (!customer.favourite_pharmacy_code && localStorage.getItem('guest_pharmacy_code')) {
                return (
                    <div block="CheckoutShipping" elem="BottomContainer">
                        { this.renderGuestForm() }
                        <CheckoutPharmacy
                        onAddressSelect={ onAddressSelect }
                        onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                        />
                    </div>
                );
            } else {
                return (
                    <div block="CheckoutShipping" elem="BottomContainer">
                        <CheckoutPharmacy
                        onAddressSelect={ onAddressSelect }
                        onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                        />
                    </div>
                );
            }
        } else {
            changeFreeDelivery(false);
            onShippingMethodSelect(selectedShippingMethod);
            return (
                <div block="CheckoutShipping" elem="BottomContainer">
                    { this.renderGuestForm() }
                    { this.renderAddressBook() }
                </div>
            );
        }
    }


    renderAddressBook() {
        const {
            onAddressSelect,
            onShippingEstimationFieldsChange,
            is_wallet,
            setIsEditing,
            setAddressNotStored,
            addressNotStored,
            setAddressTmpCreating,
            addressTmpCreating,
            setNotReady,
            setAddressBillingNotStored,
            setIsCreatingAddress,
            mainLoaderActive,
            setCheckoutEditAddress,
            zipCodeValid
        } = this.props;

        return (
            <CheckoutAddressBook
              mainLoaderActive={ mainLoaderActive }
              onAddressSelect={ onAddressSelect }
              onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
              is_wallet={ is_wallet }
              setIsEditing={ setIsEditing }
              setIsCreatingAddress={ setIsCreatingAddress }
              setAddressNotStored={ setAddressNotStored }
              addressNotStored={ addressNotStored }
              setAddressTmpCreating={ setAddressTmpCreating }
              addressTmpCreating={ addressTmpCreating }
              setNotReady={ setNotReady }
              setAddressBillingNotStored={ setAddressBillingNotStored }
              setCheckoutEditAddress={ setCheckoutEditAddress }
              zipCodeValid={ zipCodeValid }
            />
        );
    }


    render() {
        const {
            onShippingSuccess,
            onShippingError,
            isLoading,
            mainLoaderActive
        } = this.props;

        return (
            <Form
              id={ SHIPPING_STEP }
              mix={ { block: 'CheckoutShipping' } }
              onSubmitError={ onShippingError }
              onSubmitSuccess={ onShippingSuccess }
            >
                <div>
                    <Loader isLoading={ isLoading && !mainLoaderActive } />
                    { this.renderDelivery() }
                    { this.renderActions() }
                </div>
                { this.renderContentByMethod() }
            </Form>
        );
    }

};

export default CheckoutShipping;
