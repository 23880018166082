import {
    MyAccountTabListItem as SourceMyAccountTabListItem,
} from 'SourceComponent/MyAccountTabListItem/MyAccountTabListItem.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faShoppingCart, faAddressBook, faHeart, faEnvelope } from '@fortawesome/pro-light-svg-icons';

import './MyAccountTabListItem.style.override.scss'

import Image from 'Component/Image';
import icon_exit from 'Component/MyAccountOverlay/Images/icon-exit-to-app.svg';
import icon_prescription from 'Component/MyAccountOverlay/Images/icon-prescription.svg';
import icon_healthcard from 'Component/MyAccountOverlay/Images/icon-sauda-card.svg';
import icon_address from 'Component/MyAccountOverlay/Images/icon-address.svg';
import icon_notifications from 'Component/MyAccountOverlay/Images/icon-notifications.svg';
import icon_help from 'Component/MyAccountOverlay/Images/icon-help.svg';
import icon_pharmacy from 'Component/MyAccountOverlay/Images/icon-red-cross.svg';
import icon_ship from 'Component/MyAccountOverlay/Images/icon-ship.svg';
import icon_mydata from 'Component/MyAccountOverlay/Images/icon-mydata.svg';

export class MyAccountTabListItem extends SourceMyAccountTabListItem {

    renderIconByTab() {
        const { tabEntry: [ id ] } = this.props;
        let icon;

        switch (id) {
            case 'dashboard':
                 icon = icon_mydata;
                 break;
            case 'address-book':
                 icon = icon_address;
                 break;
            case 'my-orders':
                 icon = icon_ship;
                 break;
            /*case 'notifications':
                icon = icon_notifications;
                break;*/
            case 'healthcard':
                icon = icon_healthcard;
                break;
            case 'pharmacy':
                icon = icon_pharmacy;
                break;
            case 'prescription':
                icon = icon_prescription;
                break;
            // case 'my-wishlist':
            //     icon = faHeart;
            //     break;
            // case 'newsletter-subscription':
            //     icon = faEnvelope;
            //     break;
            case 'help-and-contacts':
                icon = icon_help;
                break;
            // default:
            //     icon = faAlignLeft;
            //     break;
        }

        return (
            <Image
                    src={icon}
                    width="20px"
                    height="20px"
                    mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
            />
            // <FontAwesomeIcon className="MyAccountTabListItem-IconLeft" icon={icon} />
        );
    }

    render() {
        const { tabEntry: [, { name }], isActive } = this.props;

        return (
            <li
              block="MyAccountTabListItem"
              mods={ { isActive } }
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ this.changeActiveTab }
                  role="link"
                >
                    { this.renderIconByTab() }
                    { name }
                </button>
            </li>
        );
    }

};

export default MyAccountTabListItem;
