import {
    FieldForm as SourceFieldForm,
} from 'SourceComponent/FieldForm/FieldForm.component';

import './FieldForm.style.override'

export class FieldForm extends SourceFieldForm {
    // TODO implement logic

    renderFieldsPassword() {
        return (
            <div
              block="FieldForm"
              elem="Fields"
            >
                { Object.entries(this.fieldMap).map(this.renderField) }
            </div>
        );
    }
};

export default FieldForm;
