import {
    ProductCard as SourceProductCard,
} from 'SourceComponent/ProductCard/ProductCard.component';

import './ProductCard.style.override'
import TextPlaceholder from 'Component/TextPlaceholder';
import ProductPrice from 'Component/ProductPrice';
import { BUNDLE, CONFIGURABLE } from 'Util/Product';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import icon_sauda_product from 'Component/CartItem/images/sauda.png';
import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';
import BrowserDatabase from 'Util/BrowserDatabase';
import { isSignedIn } from 'Util/Auth';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import TagManager from 'react-gtm-module';
import Image from 'Component/Image';

export class ProductCard extends SourceProductCard {
    static propTypes = {
        linkTo: PropTypes.shape({}),
        product: ProductType.isRequired,
        device: DeviceType.isRequired,
        productOrVariant: ProductType.isRequired,
        thumbnail: PropTypes.string,
        availableVisualOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            type: PropTypes.string
        })).isRequired,
        getAttribute: PropTypes.func.isRequired,
        registerSharedElement: PropTypes.func.isRequired,
        children: PropTypes.element,
        isLoading: PropTypes.bool,
        mix: PropTypes.shape({}),
        renderRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        renderWishList: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        isConfigurableProductOutOfStock: PropTypes.func.isRequired,
        isBundleProductOutOfStock: PropTypes.func.isRequired,
        hideWishlistButton: PropTypes.bool,
        siblingsHaveBrands: PropTypes.bool,
        setSiblingsHaveBrands: PropTypes.func,
        siblingsHavePriceBadge: PropTypes.bool,
        setSiblingsHavePriceBadge: PropTypes.func,
        siblingsHaveTierPrice: PropTypes.bool,
        setSiblingsHaveTierPrice: PropTypes.func,
        siblingsHaveConfigurableOptions: PropTypes.bool,
        setSiblingsHaveConfigurableOptions: PropTypes.func,
    };

    state = {
        saudaPercent: ''
    }

    static defaultProps = {
        thumbnail: '',
        isHealthCard: false,
        linkTo: {},
        children: null,
        isLoading: false,
        mix: {},
        renderContent: false,
        hideWishlistButton: false,
        siblingsHaveBrands: false,
        setSiblingsHaveBrands: () => null,
        siblingsHavePriceBadge: false,
        setSiblingsHavePriceBadge: () => null,
        siblingsHaveTierPrice: false,
        setSiblingsHaveTierPrice: () => null,
        siblingsHaveConfigurableOptions: false,
        setSiblingsHaveConfigurableOptions: () => null
    };

    renderAdditionalProductDetails() {
        return (
            <>
                <div block="ProductCard" elem="StockInfo">
                    {this.renderStockValue()}
                </div>
            </>
        );
    }

    renderStockValue() {
        const { product: { custom_attributes, stock_status } } = this.props;

        let customStock = true;
        if (custom_attributes && custom_attributes.length > 0) {
            for (let i = 0; i < custom_attributes.length; i++) {
                if (custom_attributes[i].attribute_code == 'custom_stock') {
                    if (custom_attributes[i].attribute_values[0] && custom_attributes[i].attribute_values[0] == 'Esgotado') {
                        customStock = false;
                        break;
                    }
                }
            }
        }

        if (!customStock || stock_status === 'OUT_OF_STOCK') {
            return (
                <div block="ProductCard" elem="Stock" mods={{ Soldoff: true }}>
                    <span>{__("Sold off")}</span>
                </div>
            );
        }
    }

    renderMainDetails() {
        const { product: { name } } = this.props;

        return (
            <p
                block="ProductCard"
                elem="Name"
                mods={{ isLoaded: !!name }}
            >
                <TextPlaceholder content={name} length="medium" />
            </p>
        );
    }

    getPoints() {
        const { product, productOrVariant, isHealthCard } = this.props;
        let points = null;

        try {
            /*if (isHealthCard) {
                points = product.attributes.points.attribute_value;
            } else {
                points = productOrVariant.attributes.points.attribute_value;
            }*/
            points = productOrVariant.attributes.points.attribute_value;
        } catch (err) {
            return null;
        }

        return points;

    }

    getSaudaVerify() {
        const { product, productOrVariant, favStoreObj: { sauda } } = this.props;
        let hasPoints = false;

        try {
            if (sauda) {
                hasPoints = productOrVariant.attributes.has_points.attribute_value;
            }
        } catch (err) {
            return false;
        }

        return hasPoints;
    }

    isInCampaign() {
        const { product, product: { campaigns, variants }, customer, favStoreObj: { sauda }, customer: { favourite_pharmacy_code } } = this.props;

        if(customer && favourite_pharmacy_code && !sauda ||
            (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) && !sauda){
            return false;
        }

        let maxValue      = 0;
        let maxPercentage = 0;
        let campaignToUse = {};
        let allCampaigns  = [];

        let customerVouchers = customer.vouchers;

        let flag = false;
        let discountsVariants = [];
        if ((campaigns) && (campaigns.length > 0)) {
            campaigns.map((campaign) => {
                if(campaign.burn_value_type != null || campaign.burn_value != null){
                    flag = true;
                    //if(campaign.promotional_code_type_value == 'Cash' && campaign.burn_value_type != 'Quantity'){
                    if(campaign.promotional_code_type_value == 'Cash'){
                        if(parseInt(campaign.promotional_code_value) > maxValue){
                            maxValue = parseInt(campaign.promotional_code_value);
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                        }
                        //} else if(campaign.promotional_code_type_value != 'Cash' && campaign.burn_value_type != 'Quantity'){
                    } else if(campaign.promotional_code_type_value != 'Cash'){
                        const { product : { price_range: { maximal_price: { final_price: { value } } } } } = this.props;
                        let price = value * (parseInt(campaign.promotional_code_value) / 100);
                        if(price > maxValue){
                            maxValue = price;
                            campaignToUse = campaign;
                            allCampaigns.push(campaign);
                        }
                    }
                }else if(!campaign.pharmacy_codes){
                    flag = true;
                }
                /*const { voucher_type, promotional_code_type_value, promotional_code_value, burn_products, code, burn_value_type } = campaign;
                if (burn_value_type != 'Quantity' && (promotional_code_type_value == '%' || promotional_code_type_value == 'Percentage')) {
                    this.setState({
                        saudaPercent: promotional_code_value
                    });
                }
                if (this.checkCustomerVoucher(code, customerVouchers)) {
                    flag = true;
                }*/
            });
        } else {
            if (variants && variants.length > 0) {
                variants.map((variant) => {
                    let value = variant.price_range.maximal_price.final_price.value;

                    if ((variant.campaigns) && (variant.campaigns.length > 0)) {
                        flag = true;
                        variant.campaigns.map((campaign) => {

                            if(campaign.burn_value_type != null || campaign.burn_value != null){

                                //if(campaign.promotional_code_type_value == 'Cash' && campaign.burn_value_type != 'Quantity'){
                                if(campaign.promotional_code_type_value == 'Cash'){
                                    if(parseInt(campaign.promotional_code_value) > maxValue){
                                        maxValue = parseInt(campaign.promotional_code_value);
                                        campaignToUse = campaign;
                                        allCampaigns.push(campaign);
                                    }
                                //} else if(campaign.promotional_code_type_value != 'Cash' && campaign.burn_value_type != 'Quantity'){
                                } else if(campaign.promotional_code_type_value != 'Cash'){
                                    let price = value * (parseInt(campaign.promotional_code_value) / 100);
                                    if(price > maxValue){
                                        maxValue = price;
                                        campaignToUse = campaign;
                                        allCampaigns.push(campaign);
                                    }
                                }
                            }

                            /*const { voucher_type, promotional_code_type_value, promotional_code_value, burn_products, code, burn_value_type } = campaign;
                            //if (voucher_type == 'burn' && promotional_code_type_value == '%') {
                            if (burn_value_type != 'Quantity' && (promotional_code_type_value == '%' || promotional_code_type_value == 'Percentage')) {
                                discountsVariants.push(promotional_code_value);
                                if (promotional_code_value > this.state.saudaPercent) {
                                    this.setState({
                                        saudaPercent: promotional_code_value
                                    });
                                }
                            }
                            if (this.checkCustomerVoucher(code, customerVouchers)) {
                                flag = true;
                            }*/
                        });
                    }
                });
                /*let oldValue = 0;
                if (discountsVariants && discountsVariants.length > 1) {
                    discountsVariants.forEach((discount, index) => {
                        if (index == 0) {
                            oldValue = discount;
                        } else {
                            if (discount > oldValue) {
                                this.setState({ saudaPercent: null });
                            }
                        }
                    });
                }*/
            }
        }

        if(allCampaigns.length > 0){
            flag = true;
            if(campaignToUse.promotional_code_type_value == 'Cash'){
                this.setState({
                    saudaPercent: campaignToUse.promotional_code_value+'€'
                });
            } else {
                this.setState({
                    saudaPercent: campaignToUse.promotional_code_value+'%'
                });
            }
        }
        return flag;
    }

    checkCustomerVoucher(code, customerVouchers) {

        return true;
        if(customerVouchers) {
            customerVouchers.map((voucher) => {
                if (voucher.reference == code) {
                    return true;
                }
            });
            return false;
        }

        //return true;
    }

    renderProductCardWishlistButton() {
        const { product, hideWishlistButton } = this.props;

        if (hideWishlistButton) {
            return null;
        }

        return (
            <ProductWishlistButton
                product={product}
            />
        );
    }

    _getAvailableVisualOptions() {
        const { product: { configurable_options = {} } } = this.props;

        if (Object.keys(configurable_options).length === 0) {
            return [];
        }

        const { attribute_options } = Object.values(configurable_options)[0];

        if (Object.keys(attribute_options).length === 0) {
            return [];
        }

        return Object.values(attribute_options).reduce(
            (acc, option) => {
                const {
                    swatch_data,
                    label
                } = option;

                const { type, value } = swatch_data || {};

                if (type && value) {
                    acc.push({ value, label, type });
                }

                return acc;
            },
            []
        );
    }

    renderProductPrice() {
        const {
            product,
            product: { price_range, type_id, sku, variants, url },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock,
            favStoreObj: { sauda },
            customer,
            customer: { favourite_pharmacy_code },
            isHealthCard
        } = this.props;

        let finalPrice = price_range;

        let productOrVariant = product;

        let minPrice = 0;

        let campaignIsValid = false;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
            case CONFIGURABLE:
                if (isConfigurableProductOutOfStock()) {
                    return this.renderEmptyProductPrice();
                }
                if (variants) {
                    for (let i = 0; i < variants.length; i++) {
                        if (variants[i] && variants[i].price_range && variants[i].price_range.maximal_price.final_price.value) {
                            if(variants[i].attributes.has_points){
                                if (minPrice == 0) {
                                    minPrice = variants[i].price_range.maximal_price.final_price.value;
                                    finalPrice = variants[i].price_range;
                                    productOrVariant = variants[i];
                                } else {
                                    if (variants[i].price_range.maximal_price.final_price.value < minPrice) {
                                        finalPrice = variants[i].price_range;
                                        productOrVariant = variants[i];
                                    }
                                }
                            }
                            /*if (i == 0) {
                                minPrice = variants[i].price_range.maximal_price.final_price.value;
                                finalPrice = variants[i].price_range;
                                productOrVariant = variants[i];
                            } else {
                                if (variants[i].price_range.maximal_price.final_price.value < minPrice) {
                                    finalPrice = variants[i].price_range;
                                    productOrVariant = variants[i];
                                }
                            }*/
                        }
                    }
                }
                break;
            case BUNDLE:
                if (isBundleProductOutOfStock()) {
                    return this.renderEmptyProductPrice();
                }
                break;
            default:
                break;
        }

        let points = 0;

        if (isHealthCard) {
            points = this.getPoints();
        } else {
            if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
                if (sauda && this.getSaudaVerify() == "1") {
                    points = this.getPoints();
                }
            } else {
                if (this.getSaudaVerify() == "1") {
                    points = this.getPoints();
                }
            }
        }

        if ((this.isInCampaign() || this.getSaudaVerify())) {
            campaignIsValid = true;
        }
        return (
            <div block="ProductCard" elem="PriceWrapper">
                {this.renderTierPrice()}
                { /* this.renderConfigurablePriceBadge() */}
                <ProductPrice
                    isHealthCard={isHealthCard}
                    points={points}
                    price={finalPrice}
                    sku={sku}
                    mix={{ block: 'ProductCard', elem: 'Price CategoryPage-ProductPrice' }}
                    customer={customer}
                    product={ productOrVariant }
                    canRenderCampaign={campaignIsValid}
                />
            </div>
        );
    }

    registerSharedElement = () => {
        const { registerSharedElement, product } = this.props;
        localStorage.setItem('fromWebsite',true);

        TagManager.dataLayer({
            dataLayer: {
                event: 'productClick',
                ecommerce: {
                    click: {
                        products: [{
                            name: product.name,
                            id: product.id,
                            price: 0,
                            brand: product.custom_attributes[1].attribute_values[0],
                            category: '',
                            variant: ''
                        }]
                    }
                }
            }
        });

        registerSharedElement(this.imageRef);
        this.setClickedSauda();
    };

    setClickedSauda = () => {
        if (this.isInCampaign() || this.getSaudaVerify()) {
            BrowserDatabase.setItem(true, 'clicked_sauda');
        }
    }

    renderShowPrice(){
        const { customer: { favourite_pharmacy_code } } = this.props;

        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            return null;
        }

        return (
            <button
                block="ProductCard"
                elem="ShowPrice"
                mix={ { block: 'Button' } }
            >Ver Preço</button>
        );
    }

    renderCardContent() {
        const { renderRemove, renderWishList } = this.props;

        if (renderWishList) {
            return (
                <>
                    {
                        this.renderCardLinkWrapper((
                            <>
                                <div block="ProductCard" elem="Figure-Review">
                                    <figure block="ProductCard" elem="Figure">
                                        {this.renderPicture()}
                                    </figure>
                                    {this.renderReviews()}
                                </div>

                                <div block="ProductCard" elem="Content">
                                    {this.renderAdditionalProductDetails()}
                                    {this.renderMainDetails()}
                                    {this.renderProductPrice()}

                                    {this.renderVisualConfigurableOptions()}
                                </div>
                            </>
                        ))
                    }
                    {renderRemove}
                </>
            );
        }


        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="Figure-Review">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                        {this.renderReviews()}
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderAdditionalProductDetails()}
                        {this.renderMainDetails()}
                        {this.renderProductPrice()}
                        {this.renderShowPrice()}
                        {this.renderVisualConfigurableOptions()}
                    </div>
                </>
            ))
        );
    }

    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail } = this.props;

        this.sharedComponent = (
            <Image
                imageRef={ this.imageRef }
                src={ thumbnail }
                alt={ name }
                ratio="custom"
                mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
            </>
        );
    }

    showPharacySelectorPopup = (linkTo = false) => {
        const { showPharacySelectorPopup, showNotification, product: { url }, requestStores } = this.props;
        this.setClickedSauda();
        if (url) BrowserDatabase.setItem(url, 'redirect_to');
        if (linkTo) BrowserDatabase.setItem(linkTo.pathname, 'redirect_to');
        if(linkTo){
            localStorage.setItem('goToProductPage', true);
        }
        //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
        requestStores();
        showPharacySelectorPopup();
    }

    showAlertNotSauda = () => {
        const { showAlertNotSaudaPopup, product: { url } } = this.props;
        if (url) BrowserDatabase.setItem(url, 'redirect_to');
        //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
        this.setClickedSauda();
        showAlertNotSaudaPopup();
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { showPharacySelectorPopup, customer, isHealthCard, favStoreObj: { sauda }, linkTo, product: { url } } = this.props;

        if (isHealthCard && sauda != 1 ) {
            return (
                <div
                    block="ProductCard"
                    elem="Link"
                    onClick={() => this.showAlertNotSauda()}
                >
                    {children}
                </div>
            );

        } else {
            if ((isSignedIn() && customer && !customer.favourite_pharmacy_code)) {
                return (
                    <div
                        block="ProductCard"
                        elem="Link"
                        onClick={() => this.showPharacySelectorPopup(linkTo)}
                    >
                        {children}
                    </div>
                );
            } else if (!isSignedIn() && !localStorage.getItem('guest_pharmacy_code')) {
                return (
                    <Link
                        block="ProductCard"
                        elem="Link"
                        to={linkTo}
                        onClick={this.registerSharedElement}
                        mix={mix}
                    >
                        {children}
                    </Link>
                );
            } else {
                if (!url) {
                    return (
                        <div
                            block="ProductCard"
                            elem="Link"
                        >
                            {children}
                        </div>
                    );
                }
                return (
                    <Link
                        block="ProductCard"
                        elem="Link"
                        to={linkTo}
                        onClick={this.registerSharedElement}
                        mix={mix}
                    >
                        {children}
                    </Link>
                );
            }

        }
    }

    renderNewProduct() {
        const { product: { attributes }, showNewLabel, renderWishList } = this.props;

        let isNew = <></>;

        if (showNewLabel && !renderWishList) {
            if (attributes && attributes['news_from_date']) {
                isNew = <><div block="ProductCard" elem="NewProduct">{ __('New') }</div></>;
            }
        }

        return isNew;
    }

    renderSaudaIcon() {
        const { device, favStoreObj: { sauda }, customer: { favourite_pharmacy_code } } = this.props;
        const { saudaPercent } = this.state;
        const points = this.getPoints();
        let saudaIcon = null
        let valid = false;
        if (this.isInCampaign() || this.getSaudaVerify()) {
            valid = true;
        }
        // checked sauda
        localStorage.setItem('saudaPercent', false);
        if(valid) {
            localStorage.setItem('saudaPercent', true);
            saudaIcon = <div block="ProductCard" elem="SaudaProduct">
                <img
                    src={icon_sauda_product}
                    width={device.isMobile ? "30px" : "60px"}
                    height={device.isMobile ? "40px" : "70px"}
                    block="ProductCard"
                    elem="SaudaProductImg"
                    alt={ __('Sauda Card') }
                />
            </div>;
            if(saudaPercent) {
                saudaIcon = <div block="ProductCard" elem="SaudaPercentLabel">
                    <span block="ProductCard" elem="SaudaPercentFrom">
                        { __('From') }
                    </span>
                    <span block="ProductCard" elem="SaudaPercentValue">
                        -{ saudaPercent }
                    </span>
                    <img
                        src={icon_sauda_product}
                        width={device.isMobile ? "30px" : "60px"}
                        height={device.isMobile ? "40px" : "70px"}
                        block="ProductCard"
                        elem="SaudaProductImg"
                        alt={ __('Sauda Card') }
                    />
                </div>;
            } else if (points) {
                saudaIcon = <div block="ProductCard" elem="SaudaPercentLabel">
                    <span block="ProductCard" elem="SaudaPercentFrom">
                        { __('Points') }
                    </span>
                    <span block="ProductCard" elem="SaudaPercentValue">
                        { points }
                    </span>
                    <img
                        src={icon_sauda_product}
                        width={device.isMobile ? "30px" : "60px"}
                        height={device.isMobile ? "40px" : "70px"}
                        block="ProductCard"
                        elem="SaudaProductImg"
                        alt={ __('Sauda Card') }
                    />
                </div>;
            }
        }
        return saudaIcon;
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            customer: { favourite_pharmacy_code }
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        };

        let blockClass = favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code") ? '' : 'ShowPrice';

        return (
            <li
                block="ProductCard"
                mods={ mods }
                mix={  { block: blockClass } }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                { this.renderProductCardWishlistButton() }
                { this.renderNewProduct() }
                { this.renderSaudaIcon() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
};

export default ProductCard;
