import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';
import CartFloating from './CartFloating.component';
import CartQuery from 'Query/Cart.query';
import { fetchQuery } from 'Util/Request';

import {
    CartDispatcher
} from 'SourceRoute/CartPage/CartPage.container';
export {
    CartDispatcher
};

/** @namespace Component/CartFloatingContainer/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartData: state.CartReducer.cartTotals,
    favStoreObj: state.StorePageReducer.favStoreObj,
    customer: state.MyAccountReducer.customer
});

/** @namespace Component/CartFloatingContainer/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCustomer: (customer) => dispatch(updateCustomerDetails(customer)),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    showOutStockNotification: () => dispatch(showNotification('error', __('Remove out of stock products from cart'))),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
});

/** @namespace Component/CartFloatingContainer/Container */
class CartFloatingContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
        canEstimate: PropTypes.bool.isRequired
    };

    state = {
        free_shipping: 'not_available',
        prevSubtotal: 0
    };

    componentDidMount() {
        this.requestFreeShipping();
    }

    componentDidUpdate(prevProps) {
        const { favStoreObj: { pharmacy_code: prevCode } } = prevProps;
        const { favStoreObj: { pharmacy_code }, updateCart, syncCart, cartData, canEstimate } = this.props;

        if(prevCode != pharmacy_code) {
            this.requestFreeShipping();
        }
    }

    requestFreeShipping() {
        const { favStoreObj: { pharmacy_code }, showErrorNotification } = this.props;

        fetchQuery(CartQuery.getFreeShipping(pharmacy_code)).then(
            /** @namespace Store/Cart/Dispatcher/_createEmptyCartFetchMutationThen */
            ({ getFreeShipping }) => {
                this.setState({
                    free_shipping: getFreeShipping.free_shipping_value ? getFreeShipping.free_shipping_value : 'not_available'
                });
            },
            /** @namespace Store/Cart/Dispatcher/_createEmptyCartFetchMutationCatch */
            (error) => showErrorNotification(error)
        );
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    render() {
        return (
            <CartFloating
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CartFloatingContainer);