import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { hideActiveOverlay} from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { customerType } from 'Type/Account';
import { showNotification } from 'Store/Notification/Notification.action';
import PropTypes from 'prop-types';
import { getVariantIndex } from 'Util/Product';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { setQueryParams } from 'Util/Url';
import Loader from 'Component/Loader';
import ProductPage from './ProductPage.component';
import { debounce } from 'Util/Request';
import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';
import StorePageQuery from 'Query/StorePage.query';
import { fetchMutation } from 'SourceUtil/Request';
import Popup from 'Component/Popup';
import delete_icon from 'Component/MyAccountAddressTable/images/icon_delete_modal.png';
import Image from 'Component/Image';
import { StorePageDispatcher } from '../../store/StorePage';

import {
    ProductPageContainer as SourceProductPageContainer,
    BreadcrumbsDispatcher,
    MetaDispatcher,
    ProductDispatcher,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { isSignedIn } from 'Util/Auth';

export {
    BreadcrumbsDispatcher,
    MetaDispatcher,
    ProductDispatcher,
};

export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    baseLinkUrl: state.ConfigReducer.base_link_url || '',
    customer: state.MyAccountReducer.customer,
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints,
    customerRequestDone: state.MyAccountReducer.customerRequestDone
});
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
/** @namespace Route/StorePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showInfoPopup: (payload) => dispatch(showPopup('ProductPageGeneric', payload)),
    showSharePopup: (payload) => dispatch(showPopup('ProductPageShare', payload)),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    requestStore: (id) => {
        StorePageDispatcher.requestStore(dispatch, id);
    },
    showCartPopup: (payload) => dispatch(showPopup('CartItemsAlert', payload)),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
    ),
    syncWishList: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    )
});


export class ProductPageContainer extends SourceProductPageContainer {

    state = {
        configurableVariantIndex: -1,
        parameters: {},
        productOptionsData: {},
        selectedBundlePrice: 0,
        currentProductSKU: '',
        countRequestProduct: false,
        isLoading: true,
        waitForCustomerData: true,
        popupVisible: false,
        defaultProduct: false,
        loginDone: false,
        pharmaCode: '',
        genericSort: {
            sortKey: 'name',
            sortDirection: 'ASC'
        },
        showPopup:false
    };

    containerFunctions = {
        updateConfigurableVariant: this.updateConfigurableVariant.bind(this),
        getLink: this.getLink.bind(this),
        getSelectedCustomizableOptions: this.getSelectedCustomizableOptions.bind(this),
        setBundlePrice: this.setBundlePrice.bind(this),
        hideActivePopup: this.hideActivePopup.bind(this),
        onSortChange: this.onSortChange.bind(this)
    };

    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        customer: customerType.isRequired,
    };

    componentWillUnmount() {
        localStorage.setItem('fromWebsite',false)
    }

    componentDidMount() {
        const {
            productSKU,
            product,
            product: {
                sku
            },
            showCartPopup,
            requestProduct,
            customer: { favourite_pharmacy_code }
        } = this.props;

        /**
         * Always make sure the navigation switches into the MENU tab
         * */
        this.updateNavigationState();

        /**
         * Ensure transition PDP => homepage => PDP always having proper meta
         */
        this.updateMeta();

        /**
         * Make sure to update header state, the data-source will
         * define the correct information to use for update
         * (it can be a product, history state product or an empty object).
         */
        this.updateHeaderState();
        this.updateBreadcrumbs();

        this.scrollTopIfPreviousPageWasPLP();

        /*if (!customer.favourite_pharmacy_code) {
            if (JSON.parse(localStorage.getItem('customer'))) {
                user = JSON.parse(localStorage.getItem('customer'));
                if (user.data.favourite_pharmacy_code) {
                    favourite_pharmacy_code = user.data.favourite_pharmacy_code;
                }
            }

            if (favourite_pharmacy_code == null) {
                if (localStorage.getItem('guest_pharmacy_code')) {
                    favourite_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
                }
            }
        } else {
            favourite_pharmacy_code = customer.favourite_pharmacy_code;
        }

        if( favourite_pharmacy_code == null && !!Object.values(customer).length || !Object.values(user).length){
            if (favourite_pharmacy_code == null) {
                let path = location.pathname;
                BrowserDatabase.setItem(path,'redirect_to');
                showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
                history.push(appendWithStoreCode('/selecionador-de-farmacias'));
            } else {
                BrowserDatabase.deleteItem('clicked_sauda');
            }
        }*/

        this.setState({
            isLoading: false,
            defaultProduct: false
        });
        this.checkLogin();
        let pharmacy_code_search = new URL(location.href).searchParams.get('pharmacy_code');
        let guest_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
        if(pharmacy_code_search ){
            if(isSignedIn()){
                const customer_favourite_pharmacy_code = JSON.parse(localStorage.getItem('customer')).data.favourite_pharmacy_code;
                if((pharmacy_code_search != customer_favourite_pharmacy_code) && (customer_favourite_pharmacy_code != null)){
                    localStorage.setItem('pharmacy_code_search', pharmacy_code_search)
                    showCartPopup();
                }
                else{
                    if(customer_favourite_pharmacy_code == null){
                        this.handleUpdateCustomerContextPharmacy(pharmacy_code_search);
                    }
                }
            }else{
                if((pharmacy_code_search != guest_pharmacy_code) && (guest_pharmacy_code != null)  ){

                    localStorage.setItem('pharmacy_code_search', pharmacy_code_search)
                    showCartPopup();
                }
                else{
                    if(guest_pharmacy_code == null){
                        this.handleUpdateCustomerContextPharmacy(pharmacy_code_search);
                    }
                }
            }
        }
    }

    hideActivePopup() {
        const { hideActiveOverlay } = this.props;
        hideActiveOverlay();

        document.documentElement.classList.remove('scrollDisabled');
        document.body.style.marginTop = 0;
        window.scrollTo(0,  window.pageYOffset || document.body.scrollTop);
    }

    checkLogin(){
        const { customerRequestDone } = this.props;
        if(customerRequestDone && isSignedIn()) {
            this.setState({ loginDone: true });
        } else {
            this.setState({ loginDone: false });
        }
    }


    componentDidUpdate(prevProps) {
        const {
            showPharacySelectorPopup,
            isOffline,
            productSKU,
            product: {
                sku,
                options,
                items,
                type_id
            },
            product,
            showNotification,
            customer: { favourite_pharmacy_code },
            customerRequestDone,
            requestProduct,
            requestStores
        } = this.props;

        const { waitForCustomerData, popupVisible, loginDone, pharmaCode, currentProductSKU, countRequestProduct, showPopup} = this.state;
        const {
            productSKU: prevProductSKU,
            product: {
                sku: prevSku,
                options: prevOptions,
                items: prevItems
            }
        } = prevProps;

        if(customerRequestDone || !isSignedIn()) {
            let code = favourite_pharmacy_code ? favourite_pharmacy_code : localStorage.getItem('guest_pharmacy_code');
            if(favourite_pharmacy_code && (code != pharmaCode)) {
                this.setState({ currentProductSKU: productSKU });
                this.setState({ pharmaCode: favourite_pharmacy_code ? favourite_pharmacy_code : localStorage.getItem('guest_pharmacy_code') });
                const options = {
                    isSingleProduct: true,
                    args: { filter: this.getProductRequestFilter(), pharma: code }
                };
                requestProduct(options);
            } else {
                if (!isSignedIn() && productSKU && productSKU != prevProductSKU) {
                    const options = {
                        isSingleProduct: true,
                        args: { filter: this.getProductRequestFilter(), pharma: null }
                    };
                    requestProduct(options);
                }
            }

            const fromWebsite = localStorage.getItem('fromWebsite');
            if(!loginDone){
                if(favourite_pharmacy_code || localStorage.getItem('guest_pharmacy_code')) {
                    BrowserDatabase.deleteItem('clicked_sauda');
                } else {
                    let path = location.pathname;
                    BrowserDatabase.setItem(path,'redirect_to');
                    //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
                    //history.push(appendWithStoreCode('/selecionador-de-farmacias'));
                    if(!popupVisible){
                        //console.log(prevProps);
                        //history.push(appendWithStoreCode(prevProps.location.pathname));
                        if(!showPopup && fromWebsite == 'true'){
                            requestStores();
                            showPharacySelectorPopup();
                            this.setState({ popupVisible: true });
                        }
                    }
                    //return;
                }
            }
            if(waitForCustomerData) {
                this.setState({
                    waitForCustomerData: false
                })
            }
        }

        if(localStorage.getItem('goToProductPage')){
            localStorage.removeItem('goToProductPage');
            this.requestProduct();
        }

        const { sku: stateSKU } = history?.state?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * We should also update product based data if, the URL
         * rewrite SKU has changed to matching the product history SKU
         * one. At this point there could be sufficient data for
         * some updates (i.e. header state).
         */
        if (
            productSKU !== prevProductSKU
            && stateSKU === productSKU
        ) {
            this.updateHeaderState();
        }

        /**
         * If the currently loaded category ID does not match the ID of
         * category ID from URL rewrite, request category.
         */
        if (productSKU !== prevProductSKU || !countRequestProduct) {
            this.setState({
                countRequestProduct: true
            });
            this.requestProduct();
        }

        /**
         * If product ID was changed => it is loaded => we need to
         * update product specific information, i.e. breadcrumbs.
         */
        if (sku !== prevSku) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
        }

        /*if (sku === prevSku && this.state.countRequestProduct == 0) {
            this.requestProduct();
            this.state.countRequestProduct = 1;
        }*/

        /**
         * LEGACY: needed to make sure required items are
         * selected in the bundle product.
         */
        if (JSON.stringify(options) !== JSON.stringify(prevOptions)) {
            this.getRequiredProductOptions(options);
        }

        /**
         * LEGACY needed to make sure required options are
         * selected in the customizable options product.
         */
        if (JSON.stringify(items) !== JSON.stringify(prevItems)) {
            this.getRequiredProductOptions(items);
        }

        this._addToRecentlyViewedProducts();

        const { defaultProduct } = this.state;


        if ((prevSku != sku || !defaultProduct) && type_id === 'configurable') {
            this.setState({ defaultProduct: true });
            if (location.search === '') {
                const { variants, configurable_options } = product;
                if (variants.length > 0) {
                    const lowestPrice =  variants.reduce((a,c) => {
                        if (a) {
                            if (c.price_range.minimum_price.regular_price.value < a.price_range.minimum_price.regular_price.value) {
                                return c;
                            }

                            return a;
                        } else {
                            return c;
                        }
                    }, false);

                    if (lowestPrice) {
                        //const keys = Object.keys(configurable_options);
                        let newParameters = Object.keys(configurable_options).reduce((a,c) => {
                            if (lowestPrice.attributes[c]) {
                                a[c] = lowestPrice.attributes[c].attribute_value;
                            }
                            return a;
                        }, {});
                        setQueryParams(newParameters, location, history);
                    }
                }
            }
        }
    }

    handleUpdateCustomerContextPharmacy(pharmacy_code_search) {
        const { updateCart, syncCart, storeObj, showNotification, requestCustomerData, isLoading, requestStore, syncWishList } = this.props;
        if(isSignedIn()){

            const query = StorePageQuery.getUpdateCustomerContextPharmacy(pharmacy_code_search);

            fetchMutation(query).then(
                () => {
                    this.setState({ isLoading: false }, () => {
                        requestCustomerData();
                        showNotification('success', __('This pharmacy is now your favourite pharmacy.'));
                        updateCart().then(
                            () => {
                                syncCart();
                            }
                        );
                        syncWishList();
                    });
                },
                (error) => {
                    this.handleError([{message:__('Something went wrong.')}])
                }
            );
        } else {
            const query = StorePageQuery.getUpdateGuestContextPharmacy(pharmacy_code_search);
            fetchMutation(query).then(
                () => {
                        this.setState({ isLoading: false }, () => {
                        localStorage.setItem('guest_pharmacy_code', pharmacy_code_search);

                        let obj = {
                            time:new Date().getTime(),
                            value:3600000,
                            expire:3600000,
                        }
                        // You can only store strings
                        let objStr = JSON.stringify(obj);
                        localStorage.setItem('guest_pharmacy_code_expire_time',objStr);

                        showNotification('success', __('This pharmacy is now your favourite pharmacy.'));
                        requestStore(pharmacy_code_search);

                        updateCart().then(
                            () => {
                                syncCart();
                                console.log('SyncCart HandleUpdateCustomer não está logado');

                            }
                        );
                        syncWishList();
                        window.location.reload();
                    });
                },
                (error) => {
                    this.handleError([{message:__('Something went wrong.')}]);
                }
            );
        }
    }

    onPopupChangeStore() {
        const { showCartPopup } = this.props;
        let code = localStorage.getItem('pharmacy_code_search');
        console.log('Alterando para loja '+code+' no popup');
        this.handleUpdateCustomerContextPharmacy(code);
        localStorage.removeItem('pharmacy_code_search');
        showCartPopup();
    }

    renderPopupCartAlert() {
        const { showCartPopup } = this.props;

        return (
            <Popup
                id={ 'CartItemsAlert' }
                clickOutside={ false }
                mix={ { block: 'CartItemsAlert' } }
                closedOn={ false }
            >
                <header class="Popup-Header">
                    <h3 class="Popup-Heading">
                        <Image src={ delete_icon } width="35px" height="35px" />
                        <br/>
                        { __('By changing pharmacy your cart may be changed.') }
                    </h3>
                </header>
                <p>{ __('Do you wish to continue?') }</p>
                <div block="CartItemsAlert" elem="Actions" >
                    <button
                        block="CartItemsAlert"
                        elem="PrimaryButton"
                        mix={ { block: 'Button' } }
                        onClick={ () => showCartPopup() }
                    >{ __('No') }</button>
                </div>
                <div block="CartItemsAlert" elem="Actions">
                    <button
                        block="CartItemsAlert"
                        elem="SecondaryButton"
                        mix={ { block: 'Button' } }
                        onClick={ () => this.onPopupChangeStore() }
                    >{ __('Yes') }</button>
                </div>
            </Popup>
        );
    }

    getSelectedCustomizableOptions(values, updateArray = false) {
        const { productOptionsData } = this.state;

        if (updateArray) {
            this.setState({
                productOptionsData:
                    { ...productOptionsData, productOptionsMulti: values }
            });
        } else {
            this.setState({
                productOptionsData:
                    { ...productOptionsData, productOptions: values }
            });
        }
    }

    requestProduct() {
        const { requestProduct, productSKU, customer: { favourite_pharmacy_code } } = this.props;
        const { currentProductSKU } = this.state;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        /*if (currentProductSKU === productSKU) {
            return;
        }*/

        this.setState({ currentProductSKU: productSKU });

        let code = favourite_pharmacy_code ? favourite_pharmacy_code : localStorage.getItem('guest_pharmacy_code');

        const options = {
            isSingleProduct: true,
            args: { filter: this.getProductRequestFilter(), pharma: code }
        };

        requestProduct(options);
    }

    getAreDetailsLoaded() {
        const { product } = this.props;
        const dataSource = this.getDataSource();
        //return dataSource === product;
        return true;
    }

    getConfigurableVariantIndex(variants) {
        const { configurableVariantIndex, parameters } = this.state;

        if (configurableVariantIndex >= 0) {
            return configurableVariantIndex;
        }

        if (variants && Object.keys(parameters).length > 0) {
            return getVariantIndex(variants, parameters);
        }

        return -1;
    }

    updateConfigurableVariant(key, value) {
        const parameters = this.getNewParameters(key, value);

        // remove key if value is empty
        if (value === '') {
            delete parameters[key];
        }

        this.setState({ parameters });

        this.updateUrl(key, value, parameters);
        this.updateConfigurableVariantIndex(parameters);
    }

    updateConfigurableVariantIndex(parameters) {
        const { product: { variants, configurable_options } } = this.props;
        const { configurableVariantIndex } = this.state;

        const newIndex = parameters && configurable_options && Object.keys(parameters).length === Object.keys(configurable_options).length
            ? getVariantIndex(variants, parameters)
            // Not all parameters are selected yet, therefore variantIndex must be invalid
            : -1;

        if (configurableVariantIndex !== newIndex) {
            this.setState({ configurableVariantIndex: newIndex });
        }

        return newIndex;
    }

    onSortChange(value) {
        const values = value.split('_');
        const genericSort = { sortKey: values[0], sortDirection: values[1] };

        this.setState({ genericSort });
    }

    render() {
        const { isLoading, waitForCustomerData } = this.state;
        return (
            <>
                <Loader isLoading={ isLoading || waitForCustomerData } />
                { this.renderPopupCartAlert() }
                <ProductPage
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
                { ...this.containerProps() }
                />
            </>
        );
    }
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer)
);
