import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import HealthCardProductList from './HealthCardProductList.component';


export const ProductListDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductList/ProductList.dispatcher'
);

export const mapStateToProps = (state) => ({
    pages: state.ProductListReducer.pages,
    totalItems: state.ProductListReducer.totalItems,
    totalPages: state.ProductListReducer.totalPages,
    isLoading: state.ProductListReducer.isLoading,
    currentArgs: state.ProductListReducer.currentArgs
});

export const mapDispatchToProps = (dispatch) => ({
    requestProductList: (options) => ProductListDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    showErrorNotification: (error) => dispatch(showNotification('error', error))
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

export class HealthCardProductListContainer extends PureComponent {
    static propTypes = {
        saudaCardMatrix: PropTypes.arrayOf(PropTypes.string).isRequired,
        requestProductList: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired
    };

    containerProps = () => {
        const {
            page,
            limit,
            itemsFinal
        } = this.state;

        return {
            page,
            limit,
            itemsFinal
        }
    };

    containerFunctions = {
        onPageChange: this.onPageChange.bind(this)
    };

    state = {
        page: 1,
        limit: 12,
        itemsFinal: [],
        countRequest: 0
    };

    onPageChange(page) {
        const {
            requestProductList,
            totalPages,
            pages
        } = this.props;
        const { itemsFinal } = this.state;

        const options = {
            isNext: false,
            noAttributes: false,
            noVariants: false,
            args: {
                sort: {
                    sortDirection: "ASC",
                    sortKey: "points"
                },
                filter: {
                    has_points: 1
                },
                search: "",
                pageSize: this.state.limit,
                currentPage: page.selected + 1
            }
        };

        requestProductList(options);

        this.setState({ page: page.selected + 1 });
    }

    componentDidMount() {
        const {
            requestProductList,
            totalPages,
            pages
        } = this.props;
        const { page, itemsFinal, countRequest } = this.state;


        const options = {
            isNext: false,
            noAttributes: false,
            noVariants: false,
            args: {
                sort: {
                    sortDirection: "ASC",
                    sortKey: "points"
                },
                filter: {
                    has_points: 1
                },
                search: "",
                pageSize: this.state.limit,
                currentPage: this.state.page
            }
        };

        requestProductList(options);
    }

    componentDidUpdate(prevProps, prevState) {
        const { page, itemsFinal, countRequest } = this.state;
        const { requestProductList, pages, currentArgs, isLoading, totalItems, totalPages } = this.props;
        const { currentArgs: prevCurrentArgs, pages: prevPages } = prevProps;

        if((prevState.page != page) || (itemsFinal.length == 0 && countRequest == 0) || (pages[Object.keys(this.props.pages)[0]] != prevPages[Object.keys(this.props.pages)[0]])){

            const found = itemsFinal.some(r => pages[Object.keys(this.props.pages)[0]].indexOf(r) >= 0);

            if(!found){
                let newItems = [];
                if(page == 1){
                    newItems = [...pages[Object.keys(this.props.pages)[0]]];
                } else {
                    newItems = [...itemsFinal, ...pages[Object.keys(this.props.pages)[0]]];
                }
                this.setState({itemsFinal:newItems});
                this.setState({countRequest: 1});
            } else {
                if(countRequest == 0){
                    const options = {
                        isNext: false,
                        noAttributes: false,
                        noVariants: false,
                        args: {
                            sort: {
                                sortDirection: "ASC",
                                sortKey: "points"
                            },
                            filter: {
                                has_points: 1
                            },
                            search: "",
                            pageSize: this.state.limit,
                            currentPage: this.state.page+1
                        }
                    };
                    requestProductList(options);
                    this.setState({countRequest: 1, page: this.state.page+1});
                }
            }
        }


        /*if (
            ((!prevCurrentArgs && currentArgs) ||
            (prevCurrentArgs && currentArgs && prevCurrentArgs.currentPage != currentArgs.currentPage))
        ){
            let newItems = [...itemsFinal, ...pages[Object.keys(this.props.pages)[0]]];
            this.setState({itemsFinal:newItems});
        }*/
    }

    render() {

        return (
            <>
                <HealthCardProductList
                    {...this.props}
                    {...this.state}
                    {...this.containerFunctions}
                    {...this.containerProps()}
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthCardProductListContainer);
