import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PaginationView from 'Component/Pagination';
import LeftIcon from 'Route/StoreFinder/images/left.svg';
import RightIcon from 'Route/StoreFinder/images/right.svg';
import Image from 'Component/Image';
import { PagesType } from 'Type/ProductList';
import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';


import './HealthCardProductList.style';

Array.min = function (array) {
    return Math.min.apply(Math, array);
};
class HealthCardProductList extends PureComponent {
    static propTypes = {
        saudaCardMatrix: PropTypes.arrayOf(PropTypes.string).isRequired,
        pages: PagesType.isRequired
    };

    state = {
        isLoading: true,
        items: [],
        isEmpty: true
    };

    prepareProductItems = ([key, items = []]) => {
        this.setState({ items: items });
    };

    renderItems(itemsSorted) {
        const { isLoading } = this.props;
        const {
            isEmpty
        } = this.state;


        //if (!isLoading) {
            return itemsSorted.map((product, i) => (
                <>
                    <div class="product-card-item">
                        <ProductCard
                            isHealthCard={true}
                            key={i}
                            product={product}
                        />
                    </div>
                </>
            ));
        /*} else {
            return (
                <>
                </>
            );
        }*/
    }

    render() {
        const { saudaCardMatrix, page, pages, onPageChange, totalPages, totalItems, isLoading, itemsFinal } = this.props;
        const { items } = this.state;

        let itemsSorted = {};

        /*if(isLoading){
            return (
                <Loader isLoading={isLoading} />
            );
        }*/

        if (saudaCardMatrix && pages[Object.keys(this.props.pages)[0]] && pages[Object.keys(this.props.pages)[0]].length) {
                return (
                    <>
                        <Loader isLoading={isLoading} />
                        <div block="HealthCardProductList">
                            <div block="HealthCardProductList" elem="Container">
                                {this.renderItems(itemsFinal)}
                            </div>
                        </div>

                        {
                            (!isLoading && page < totalPages) &&
                            <div block="HealthCardProductList" elem="ShowMore">
                                <button
                                    block="HealthCardProductList"
                                    elem="SelectButton"
                                    mix={ { block: 'Button' } }
                                    onClick={ () => { onPageChange({selected:page}) } }
                                >{ __('Show more results') }</button>
                            </div>
                        }
                    </>
                );
        } else {
            return (
                <>
                    <Loader isLoading={isLoading} />
                    <div class="health-card-list-empty">
                        <h4>{__('Does not exists any product with points!')}</h4>
                    </div>
                </>
            );
        }
    }
}

export default HealthCardProductList;
