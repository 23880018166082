import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import domToReact from 'html-react-parser/lib/dom-to-react';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './Tabs.style';

import { DeviceType } from 'Type/Device';

/** @namespace ScandiPWA/Blog/Component/BlogPostCard/Component */
export class Tabs extends PureComponent {

    static propTypes = {
        device: DeviceType.isRequired
    };

    state = {
        activeTab: 0,
        currentUrl: ''
    }

    componentDidMount(){
        this.traitAnchor();
    }

    traitAnchor(){
        let curUrl = window.location.href;
        const { device: { isMobile } } = this.props;
        let anchor = '';
        let anchorForId = '';

        if(curUrl.includes('#')){
            this.setState({currentUrl: curUrl});
            let urlArray = curUrl.split('#');
            anchor = urlArray[1];
        }

        if(anchor != ''){
            let elem = document.getElementById(decodeURI(anchor));
            if(isMobile){
                anchorForId = anchor+'-m';
            }

            if(isMobile && document.querySelector(`.mobile-tabs #${anchorForId}`)){
                elem = document.querySelector(`.mobile-tabs #${anchorForId}`);
            }

            let active = parseInt(elem.getAttribute('data-ref'));
            this.setState({
                active
            });
            elem.click();
        }
    }

    setActiveTab(activeTab, tabId, e) {
        e.preventDefault();
        this.setState({
            activeTab
        });
        window.history.replaceState(null, '', window.location.pathname+'#'+tabId);
    }

    renderTab(tab, i) {
        const { parserOptions, device: { isMobile } } = this.props;
        const { activeTab } = this.state;
        //children of children to ignore <a> tag
        const { children: { 0: { children } } } = tab;

        let tabId = this.replaceAccents(children[0].children[0].data.replaceAll(' ', '-'));
        let tabHref = '#'+tabId;
        if(isMobile){
            tabId = tabId;
        }

        return (
            <a block="Tabs" href={tabHref} id={tabId} data-ref={i} elem="TabButton" mods={ { isVisible: i == activeTab } } onClick={ (e) => this.setActiveTab(i, tabId, e) }>
                { domToReact(children, parserOptions) }
            </a>
        );
    }

    renderTabContent(tabContent, i) {
        const { parserOptions } = this.props;
        const { activeTab } = this.state;
        const { children } = tabContent;

        return (
            <div block="Tabs" elem="TabContent" mods={ { isVisible: i == activeTab } }>
                { domToReact(children, parserOptions) }
            </div>
        );
    }

    render() {
        const { tabs: { children }, tabsContent: { children: contentChildren } } = this.props;

        return (
            <div block="Tabs" elem="Container">
                <div block="Tabs" elem="TabButtons">
                    { children.map((tab, i) => this.renderTab(tab, i) ) }
                </div>
                <div block="Tabs" elem="TabsContent">
                    { contentChildren.map((tabContent, i) => this.renderTabContent(tabContent, i) ) }
                </div>
            </div>
        )
    }


    replaceAccents(str) {
        // Verifies if the String has accents and replace them
        if (str.search(/[\xC0-\xFF]/g) > -1) {
            str = str
                .replace(/[\xC0-\xC5]/g, "A")
                .replace(/[\xC6]/g, "AE")
                .replace(/[\xC7]/g, "C")
                .replace(/[\xC8-\xCB]/g, "E")
                .replace(/[\xCC-\xCF]/g, "I")
                .replace(/[\xD0]/g, "D")
                .replace(/[\xD1]/g, "N")
                .replace(/[\xD2-\xD6\xD8]/g, "O")
                .replace(/[\xD9-\xDC]/g, "U")
                .replace(/[\xDD]/g, "Y")
                .replace(/[\xDE]/g, "P")
                .replace(/[\xE0-\xE5]/g, "a")
                .replace(/[\xE6]/g, "ae")
                .replace(/[\xE7]/g, "c")
                .replace(/[\xE8-\xEB]/g, "e")
                .replace(/[\xEC-\xEF]/g, "i")
                .replace(/[\xF1]/g, "n")
                .replace(/[\xF2-\xF6\xF8]/g, "o")
                .replace(/[\xF9-\xFC]/g, "u")
                .replace(/[\xFE]/g, "p")
                .replace(/[\xFD\xFF]/g, "y");
        }
        return str;
    }
}

export default Tabs;
