import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideActiveOverlay,toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
import { showPopup } from 'Store/Popup/Popup.action';
import { showNotification } from 'Store/Notification/Notification.action';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    DEFAULT_NAVIGATION_TABS_STATE as SourceDEFAULT_NAVIGATION_TABS_STATE,
    NavigationTabsContainer as SourceNavigationTabsContainer,
} from 'SourceComponent/NavigationTabs/NavigationTabs.container';

import NavigationTabs from './NavigationTabs.component';
import { customerType } from 'Type/Account';
import { DeviceType } from 'Type/Device';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import {
    ACCOUNT_TAB,
    CART_TAB,
    CHECKOUT_TAB, HOME_TAB,
    MENU_TAB
} from './NavigationTabs.config';
import { debounce } from 'Util/Request';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';

import browserHistory from 'Util/History';
import BrowserDatabase from 'Util/BrowserDatabase';

export const DEFAULT_NAVIGATION_TABS_STATE = SourceDEFAULT_NAVIGATION_TABS_STATE;

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    payload: state.PopupReducer.popupPayload["MenuMobile"] || {},
    isSignedIn: state.MyAccountReducer.isSignedIn,
    favStoreObj: state.StorePageReducer.favStoreObj,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    logo_alt: state.ConfigReducer.logo_alt,
    header_logo_src: state.ConfigReducer.header_logo_src,
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showMenuOverlay: (payload) => dispatch(toggleOverlayByKey("MenuMobile", payload)),
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(null, dispatch)
    ),
    showSaudaCardPopup: (payload) => dispatch(showPopup('SaudaCardPopup', payload)),
    showPharmacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
    // TODO extend mapDispatchToProps
});


export class NavigationTabsContainer extends SourceNavigationTabsContainer {
    static propTypes = {
        setNavigationState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        navigationState: PropTypes.object.isRequired,
        device: DeviceType.isRequired,
        customer: customerType.isRequired,
        payload: PropTypes.shape({
            id: PropTypes.number
        }).isRequired,
        onSignOut: PropTypes.func,
        logout: PropTypes.func.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isMyAccount: PropTypes.bool,
        isCheckout: PropTypes.bool,
        pathname: PropTypes.string
    };

    static defaultProps = {
        onSignOut: () => {},
        activeTab: ''
    };

    routeMap = {
        '/my-account': { name: ACCOUNT_TAB },
        '/checkout': { name: CHECKOUT_TAB, isHidden: true },
        '/cart': { name: CART_TAB },
        '/': { name: HOME_TAB }
    };

    containerFunctions = {
        onMenuButtonClick: this.onMenuButtonClick.bind(this),
        onMyAccountButtonClick: this.onMyAccountButtonClick.bind(this),
        onMinicartButtonClick: this.onMinicartButtonClick.bind(this),
        onHomeButtonClick: this.onHomeButtonClick.bind(this),
        handleLogout: this.handleLogout.bind(this),
        hideOverlay: this.hideOverlay.bind(this),
        handleOverlayAndActiveTab: this.handleOverlayAndActiveTab.bind(this),
        showPharmacySelectorPopupOrLoginWarning: this.showPharmacySelectorPopupOrLoginWarning.bind(this)
    };

    componentDidMount() {
        const { location: { pathname } } = browserHistory;
        this.setState({ pathname });
        const isMyAccount = pathname.includes(MY_ACCOUNT_URL);
        this.setState({ isMyAccount });

        const isCheckout = pathname.includes(CHECKOUT_URL);
        this.setState({ isCheckout });

        window.addEventListener('popstate', this.historyBackHook);
        this.handleNavVisibility();

        const SCROLL_DEBOUNCE_DELAY = 10;
        const { name } = this.getNavigationState();
        this.lastSeenMenu = name === MENU_TAB ? 0 : -1;
        window.addEventListener('scroll', debounce(this.handleScroll, SCROLL_DEBOUNCE_DELAY));

        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState) {
        const { location: { pathname } } = browserHistory;
        const { pathname: prevPath } = prevState
        this.handleNavVisibility();
        this.handleVisibleOnScrollChange(prevProps);

        if(prevPath != pathname) {
            this.setState({ pathname })
            const isMyAccount = pathname.includes(MY_ACCOUNT_URL);
            this.setState({ isMyAccount });

            const isCheckout = pathname.includes(CHECKOUT_URL);
            this.setState({ isCheckout });
        }
    }

    componentWillUnmount() {
        // Unbind listener
        window.removeEventListener('popstate', this.historyBackHook);
    }

    historyBackHook = () => {
        // force popup close
        this.handleOverlayAndActiveTab();

        // check menu active
        const { location: { pathname } } = browserHistory;
        if (pathname.includes(MY_ACCOUNT_URL)) {
            let path = pathname.split('/');
            this.setState({ activeTab:path[path.length - 1] });
        }
    }

    handleOverlayAndActiveTab() {
        const { hideActiveOverlay } = this.props;
        this.setState({ activeTab: '' });
        hideActiveOverlay();
    }

    handleLogout() {
        const { onSignOut, logout , hideActiveOverlay, isSelected, customer } = this.props;
        const { favourite_pharmacy_code } = customer;
        if(favourite_pharmacy_code) {
            let obj = {
                time:new Date().getTime(),
                value:3600000,
                expire:3600000,
            }
            let objStr = JSON.stringify(obj);
            localStorage.setItem('guest_pharmacy_code_expire_time',objStr);
            localStorage.setItem('guest_pharmacy_code', favourite_pharmacy_code);
        }
        hideActiveOverlay();
        localStorage.removeItem('email');
        localStorage.removeItem('access');
        localStorage.setItem('rememberMe', false);
        logout();
        onSignOut();
    }

    hideOverlay(key) {
        const { hideActiveOverlay } = this.props;
        this.state.activeTab = key;
        hideActiveOverlay();
    };

    onMenuButtonClick() {
        const { navigationState: { name }, hideActiveOverlay, navigationState } = this.props;

        this.handleOverlayAndActiveTab();

        if (this.lastSeenMenu === undefined) {
            this.lastSeenMenu = 0;
        }

        // TODO: resolve issue when coming from CMS page
        if (name === MENU_TAB) { // if we already are in menu
            browserHistory.push(appendWithStoreCode('/menu'));
        } else if (this.lastSeenMenu <= 0) { // if we have not yet seen menu
            browserHistory.push(appendWithStoreCode('/menu'));
        } else { // otherwise go to last remembered category
            browserHistory.go(-this.lastSeenMenu);
        }

        this.lastSeenMenu = 0;
    }

    onMyAccountButtonClick() {
        const { device, showMenuOverlay, isSignedIn, navigationState: { name } } = this.props;

        if(device.isMobile) {
            window.scrollTo(0, 0);
        }
        // check if it is menu route
        if (isSignedIn) {
            /*if (name !== ACCOUNT_TAB) {
                history.push({ pathname: appendWithStoreCode('/my-account') });
            }*/

            showMenuOverlay({
                title: __('MenuMobile'),
                id: 1
            });
        } else {
            history.push({ pathname: appendWithStoreCode('/my-account') });
        }
    }

    showPharmacySelectorPopupOrLoginWarning() {
        const { isSignedIn, showPharmacySelectorPopup, showNotification } = this.props;

        if (isSignedIn) {
            BrowserDatabase.setItem('/my-account/prescription', 'redirect_to');
            showPharmacySelectorPopup();
        } else {
            showNotification('info', __('You must be logged in to access prescriptions'));
        }
    }

    render() {
        return (
            <NavigationTabs
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);
