import {
    MyAccountCreateAccount as SourceMyAccountCreateAccount,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';

//import 'MyAccountCreateAccount.component.style.override'
import PropTypes from 'prop-types';
import { signInStateType } from 'Type/Account';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';
import Link from 'Component/Link';
import CmsBlock from 'Component/CmsBlock';

import './MyAccountCreateAccount.style';

export class MyAccountCreateAccount extends SourceMyAccountCreateAccount {
    // TODO implement logic
    static propTypes = {
        state: signInStateType.isRequired,
        onCreateAccountAttempt: PropTypes.func.isRequired,
        onCreateAccountSuccess: PropTypes.func.isRequired,
        handleSignIn: PropTypes.func.isRequired
    };

    state = {
        seeAllTerms: false
    }

    scrollToElement(e) {
        if (e.target) {
            window.scrollTo(0, (e.target.offsetTop + 82)); // header height: 82px;   
        }
    }

    renderCreateAccountPersonalInfoFields() {
        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                 { __('Create an account and easily dispense your Medical Prescriptions. Find your favorite products at your usual pharmacy!') }
                <Field
                  type="text"
                  maxlength= "100"
                  placeholder={ __('Full name') }
                  id="fullname"
                  name="fullname"
                  autocomplete="given-name"
                  validation={ ['notEmpty', 'full_name'] }
                  onFocus={ this.scrollToElement }
                />
            </fieldset>
        );
    }


    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    toggleActive = () => {
        const { seeAllTerms } = this.state;
        this.setState({ seeAllTerms: !seeAllTerms });
    }

    renderCreateAccountSignUpInfoFields() {

        const { showErrorMessage, younger18,isSubscribedNecessary } = this.props;
        const { seeAllTerms } = this.state;

        let dateNow = new Date(Date.now());
        let dataMax = ((dateNow.getUTCFullYear()-18) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;
        let dataMin = ((dateNow.getUTCFullYear()-120) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;

        let conditionsLink = <Link to="condicoes-gerais" target="_new">
                { __('Terms and Conditions') }
            </Link>;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <Field
                  type="text"
                  placeholder={ __('E-mail address') }
                  id="email"
                  name="email"
                  autocomplete="email"
                  validation={ ['notEmpty', 'email'] }
                  onFocus={ this.scrollToElement }
                />
                <Field
                  type="date"
                  placeholder={ __('Birthday') }
                  id="dob"
                  name="dob"
                  autocomplete="dob"
                  max={dataMax}
                  min={dataMin}
                  validation={ ['notEmpty', 'date_adult', 'min_date_validation'] }
                  elemCss="showPlaceholder"
                  onFocus={ this.scrollToElement }
                />
                <Field
                  type="password"
                  placeholder={ __('Password') }
                  id="password"
                  name="password"
                  autocomplete="new-password"
                  validation={ ['notEmpty', 'password'] }
                  onFocus={ this.scrollToElement }
                />
                <Field
                  type="password"
                  placeholder={ __('Confirm password') }
                  id="confirm_password"
                  name="confirm_password"
                  autocomplete="new-password"
                  validation={ ['notEmpty', 'password_match', 'password'] }
                  onFocus={ this.scrollToElement }
                />
                <section block="MyAccountCreateAccount" elem="Info">
                    <div block="MyAccountCreateAccount" elem="Intro"><CmsBlock identifier={ "create_account_terms" } /></div>
                    <div block="MyAccountCreateAccount" elem="SeeMore" mods={ { Active: seeAllTerms } } onClick={ this.toggleActive }>{ __('Click to see more') }</div>
                    <div block="MyAccountCreateAccount" elem="ShowMore"><CmsBlock identifier={ "create_account_terms_details" } /></div>
                </section>
                <section block="MyAccountOverlay" elem="CreateDesc"
                    mods = {{ error: isSubscribedNecessary }}>
                    <Field
                        type="checkbox"
                        value="accept_terms"
                        label={ <> { __('I am aware of and consent to the ') } { conditionsLink } </> }
                        id="accept_terms"
                        mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
                        name="accept_terms"
                        validation={ ['notEmpty'] }
                    />
                </section>
            </fieldset>
        );
    }

    renderSubmitButton() {
        return (
            <div block="MyAccountOverlay" elem="Buttons">
                <button
                  block="Button"
                  type="submit"
                  formNoValidate
                >
                    { __('Sign up') }
                </button>
            </div>
        );
    }

    renderCreateAccountForm() {
        const { onCreateAccountAttempt, onCreateAccountSuccess } = this.props;
        return (
            <>
            <Form
              key="create-account"
              onSubmit={ onCreateAccountAttempt }
              onSubmitSuccess={ onCreateAccountSuccess }
              onSubmitError={ onCreateAccountAttempt }
            >
                { this.renderCreateAccountPersonalInfoFields() }
                { this.renderCreateAccountSignUpInfoFields() }
                { this.renderSubmitButton() }
            </Form>
            </>
        );
    }

    render() {
        return (
            <>
                { this.renderCreateAccountForm() }
            </>
        );
    }

};

export default MyAccountCreateAccount;
