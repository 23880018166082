import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import QRCode from 'qrcode.react';
import icon_green from './images/icon_green.png';

import './MyAccountPrescriptionCard.style.override'
import { PureComponent } from 'react';
import { MyAccountPrescriptionDetails } from 'Component/MyAccountPrescriptionDetails';
import { DeviceType } from 'Type/Device';
import PropTypes from 'prop-types';
import Tooltip from 'Component/Tooltip';

export class MyAccountPrescriptionCard extends PureComponent {

    static defaultProps = {
        showAdditionalFields: false,
        showActions: false,
        mix: {},
        isPopup : false
    };

    static propTypes = {
        device: DeviceType.isRequired
    };



    handlePrescriptionClick = () => {
        const { showEditForm, prescription } = this.props;

        showEditForm(__('Details from Prescription'),"PrescriptionDetails",prescription);
    }

    renderActionsIcons() {
        return (
            <div block="MyAccountPrescriptionCard" elem="ActionsIcons">
                <button
                  block="Button"
                  mix={ { block: 'MyAccountPrescriptionCard', elem: 'ActionIcon' } }
                  title={ __('View Details') }
                  onClick= { this.handlePrescriptionClick }
                >
                    <FontAwesomeIcon className="fa-2x" icon={ faAngleRight } />
                </button>
            </div>
        );
    }

    renderPrescriptionInfo(isExpired) {
        const { prescription } = this.props;

        let isExpiring = !isExpired;

        if(prescription.forfeit && prescription.forfeit != '') {
            let date = new Date();
            date.setDate(date.getDate()-1);
            let fParts = prescription.forfeit.split('-');
            let fDate = new Date(fParts[2], parseInt(fParts[1]) - 1, parseInt(fParts[0]));

            isExpired = prescription.status == 'Dispensed/Expired' || fDate < date;
            let date2 = new Date();
            date2.setDate(date2.getDate() + 7);
            isExpiring = !isExpired && fDate < date2;
        } else {
            isExpiring = false;
        }

        return(
            <div block="MyAccountPrescriptionCard" elem="PrescriptionInfo" onClick= { this.handlePrescriptionClick }>
                <p>
                    <strong>{ __("User: ") }</strong>{ prescription.name }<br/>
                </p>
                { !isExpiring && <div><strong>{ __("Validity: ") }</strong>{ prescription.forfeit ? prescription.forfeit.replaceAll('-','/') : __('Unavailable') } </div> }
                {
                    isExpiring && <div block="MyAccountPrescriptionCard" elem="Expired"><strong>{ __("Validity: ") }</strong>
                    { prescription.forfeit ? prescription.forfeit.replaceAll('-','/') : __('Unavailable') }
                    <Tooltip message={ __("Prescription line expiring in the next 7 days") } position={ 'bottom' }>
                        <FontAwesomeIcon className="MyAccountPrescriptionDetails-AlertIcon" icon={ faExclamationCircle }/>
                    </Tooltip>
                    </div>
                }
            </div>
        );
    }

    renderExpiredNotice() {
        return(
            <div block="MyAccountPrescriptionCard" elem="PrescriptionExpired">
                <p>
                    <strong>{ __("Prescription Expired/Dispensed") }</strong>
                </p>
            </div>
        );
    }

    renderTable() {
        const { title, prescription } = this.props;

        let isExpired = prescription.status == 'Dispensed/Expired';

        if(prescription.forfeit && prescription.forfeit != '') {
            let date = new Date();
            date.setDate(date.getDate()-1);
            let fParts = prescription.forfeit.split('-');
            let fDate = new Date(fParts[2], parseInt(fParts[1]) - 1, parseInt(fParts[0]));

            isExpired = prescription.status == 'Dispensed/Expired' || fDate < date;
        }

        return (
            <div block="MyAccountPrescriptionCard" elem="PrescriptionWrapper" mods={{isExpired}}>
                    { this.renderImage(isExpired) }
                <figcaption
                    block="MyAccountPrescriptionCard"
                    elem="Content"
                >
                    <div block="MyAccountPrescriptionCard" elem="PrescriptionTitle" onClick= { this.handlePrescriptionClick }>
                        { title }
                    </div>
                    { this.renderPrescriptionInfo(isExpired) }
                    <div block="MyAccountPrescriptionCard" elem="PrescriptionSuffix">

                    </div>
                    { isExpired && this.renderExpiredNotice() }
                </figcaption>
                { this.renderActionsIcons() }
            </div>
        );
    }

    renderImage() {
        const {
            onInfoClick
        } = this.props;

        return (
            <a onClick={ onInfoClick } block="MyAccountPrescriptionCard" elem="Link">
                { this.renderImageElement() }
                <p block="MyAccountPrescriptionCard" elem="Description">{  __("See Codes") }</p>
            </a>
        );
    }

    renderImageElement() {
        const { device, prescription: { number, access_code, option_code } } = this.props;

        //const code = `${ number }|${ access_code }|${ option_code }`;
        //29/02/2022 pedido para option code ser "0000"
        const code = `${ number }|${ access_code }|0000`;

        /*let size = 100;

        if (device.isMobile) {
            size = 70;
        }*/

        return (
            <div block="MyAccountPrescriptionCard" elem="QrCode">
                <QRCode value={ code } size={ 70 } />
                <img
                    block="MyAccountPrescriptionCard"
                    elem="QrCodeIcon"
                    src={ icon_green }
                    width="40px"
                    height="40px"
                />
            </div>
        );
    }

    render() {
        const { mix } = this.props;


            //TODO: LOADING prescriptions  <Loader isLoading={ !prescriptions.length }  />

            return (
                <div block="MyAccountPrescriptionCard" mix={ mix }>
                    { this.renderTable() }
                </div>
            );
    }

};

export default MyAccountPrescriptionCard;
