/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import { HistoryType } from 'Type/Common';

import BlogCategories from 'Component/BlogCategories';
import BlogPostCard from 'Component/BlogPostCard';
import BlogRecentPosts from 'Component/BlogRecentPosts';
import BlogSearchBar from 'Component/BlogSearchBar';
import { POSTS_LISTING } from './PostsListing.config';
import CmsBlock from 'Component/CmsBlock';
import Html from 'Component/Html';
import PaginationView from 'Component/Pagination';
import LeftIcon from 'Route/StoreFinder/images/left.svg';
import RightIcon from 'Route/StoreFinder/images/right.svg';
import Image from 'Component/Image';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import './PostsListing.style';


/** @namespace ScandiPWA/Blog/Component/PostsListing/Component */
export class PostsListing extends PureComponent {
    static propTypes = {
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        history: HistoryType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        isLoaded: PropTypes.bool,
        pageSize: PropTypes.number.isRequired,
        posts: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        categories: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        category: PropTypes.string.isRequired,
        loadMore: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoaded: false
    };

    componentDidMount() {
        this.setHeaderState();
    }

    componentDidUpdate() {
        this.updateMeta();
        this.updateBreadcrumbs();
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs, categories, toggleBreadcrumbs, device } = this.props;

        if (device.isMobile) {
            toggleBreadcrumbs(false);

            return;
        }

        let catSecondLevel = {
            url: '',
            name: ''
        };
        let categorySelected = this.getCategoryBreadcrumb();
        let catId = null;
        let breadcrumbs = [];

        if(categories.items.length > 0 && categorySelected.length > 0) {
            // Get Root Category
            for (let i=0; i<categories.items.length;i++) {
                if(categories.items[i].title === categorySelected[0].name && categories.items[i].category_level === 1){
                    catId = categories.items[i].parent_category_id;
                }
            }

             // Create Root Category Id
            for (let i=0; i<categories.items.length;i++) {
                if(categories.items[i].category_id == catId){
                    catSecondLevel = {
                                        url: '/blog/categoria/' + categories.items[i].identifier,
                                        name: __(categories.items[i].title)
                                    };
                }
            }
        }

        breadcrumbs = [
            ...this.getCategoryBreadcrumb(),
            {
                url: '/blog',
                name: __('Blog')
            },
            {
                url: '/',
                name: __('Home')
            }
        ];

        if (catSecondLevel.name != ''){
            breadcrumbs = [
                            ...this.getCategoryBreadcrumb(),
                            catSecondLevel,
                            {
                                url: '/blog',
                                name: __('Blog')
                            },
                            {
                                url: '/',
                                name: __('Home')
                            }
                        ];

        }
        updateBreadcrumbs(breadcrumbs);
    }

    updateMeta() {
        const { category, categories: { items }, updateMeta } = this.props;
        const { title, meta_description, meta_keywords, meta_title  } = items.find(({ identifier }) => identifier === category) || {};
        updateMeta({
            title: meta_title,
            canonical_url: this.getCanonicalUrl(),
            meta_description: meta_description,
            description: meta_description,
            meta_keywords: meta_keywords
        });
    }

    renderSlider() {
        this.updateBreadcrumbs();
        return (
            <CmsBlock identifier={ "blog_top_content" } />
        );
    }

    /**
     * Gets the canonical url for the specific post
     */
    getCanonicalUrl() {
        const { category } = this.props;
        if (category) {
            if (category !== 'blog') {
                return `${window.location.origin}/blog/categoria/${category}`;
            } else {
                return `${window.location.origin}/blog`;
            }
        }
        return '';
    }

    getCategoryTitle() {
        const { category, categories: { items }, categories } = this.props;
        const { title } = items.find(({ identifier }) => identifier === category) || {};

        return title;
    }

    getCategoryBreadcrumb() {
        const { categories: { count }, category } = this.props;
        const title = this.getCategoryTitle() || '';

        if (count && title) {
            return [
                {
                    name: title
                }
            ];
        }

        if (!count && category) {
            return [
                {
                    name: ''
                }
            ];
        }

        return [];
    }

    setHeaderState() {
        const { setHeaderState, history } = this.props;
        setHeaderState({
            name: POSTS_LISTING,
            title: 'Blog',
            onBackClick: () => history.goBack()
        });
    }

    onOrderChange(orderBy) {
        const { reloadPosts } = this.props;
        // request new posts
        reloadPosts(orderBy);
    }

    hasSubCategories() {
        const { categories: { items = [] }, isCategoriesLoaded, category } = this.props;
        const { category_id } = items.find(({ identifier }) => identifier === category) || {};
        let categoriesArray = [];

        if(category_id) {
            categoriesArray = items.filter(({ parent_category_id }) => parent_category_id === category_id);
        } else {
            categoriesArray = items.filter(({ category_level, is_active }) => category_level < 1 && is_active );
        }

        if (!isCategoriesLoaded || categoriesArray.length === 0) {
            return false;
        }

        return true;
    }

    renderRightArrow(){
        return (
            <div>
                <FontAwesomeIcon class="arrow-right" icon={ faChevronRight } />
            </div>
        );
    }

    renderLeftArrow(){
        return (
            <div>
                <FontAwesomeIcon class="arrow-left" icon={ faChevronLeft } />
            </div>
        );
    }

    renderBlogPostByCategory() {
        const { postsByCategory: { isLoaded, items } } = this.props;

        let settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            adaptiveHeight: true,
            nextArrow: this.renderRightArrow(),
            prevArrow: this.renderLeftArrow(),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: false,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }
              ]
          };

        if (isLoaded && items && items.length > 0) {
            return (
                <>
                <div block="PostsListing" elem="PostsCategoryContainer">
                    <div block="PostsListing" elem="PostsCategoryWrapper">
                        <div block="PostsListing" elem="Title">{ __("Espaço Animal") }</div>
                        <Slider { ...settings } >
                            { items.map(post => (
                                <BlogPostCard
                                    key={ post.post_id }
                                    post={ post }
                                    block="BlogPostCard"
                            />
                            )) }
                        </Slider>
                        <div block="PostsListing" elem="LoadMoreWrapper">
                            <a
                                block="PostsListing"
                                elem="LoadMoreButton"
                                block="Button"
                                href="/blog/categoria/saude-animal"
                            >
                                { __('See more') }
                            </a>
                        </div>
                    </div>
                </div>
                </>
            );
        }

        return (
            <></>
        );
    }

    renderBlogPostMostViewed() {
        // Get most viewed posts
        const { postsMostViewed: { isLoaded, items } } = this.props;

        if (isLoaded && items && items.length > 0) {
            return (
                <>
                    <div block="PostsListing" elem="PostsMostViewedContainer">
                        <div block="PostsListing" elem="Title">{ __("The most viewed") }</div>
                        <div block="PostsListing" elem="list">
                            {
                                items.map(post => (
                                    <BlogPostCard
                                        key={ post.post_id }
                                        post={ post }
                                        block="BlogPostCard"
                                    />
                                ))
                            }
                        </div>
                    </div>
                </>
            );
        }

        return (<></>);
    }

    renderBlogPostsByTag() {
        const { postsByTag: { isLoaded, items } } = this.props;

        if (isLoaded && items && items.length > 0) {
            return (
                <>
                    <div block="PostsListing" elem="PostsByTagContainer">
                        <div block="PostsListing" elem="PostsByTagWrapper">
                            <div block="PostsListing" elem="Title-Container">
                                <div block="PostsListing" elem="Title">{ __("Our featured posts") }</div>
                                <div block="PostsListing" elem="Description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</div>
                            </div>
                            <div block="PostsListing" elem="list">
                                {
                                    items.map(post => (
                                        <BlogPostCard
                                            key={ post.post_id }
                                            post={ post }
                                            block="BlogPostCard"
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        return (<></>);
    }

    renderProductsRelated() {
        return (
            <>
                <div block="PostsListing" elem="ProductsRelatedContainer">
                        <div block="PostsListing" elem="ProductsRelatedWrapper">
                            <div block="PostsListing" elem="Title">{ __("Products picked for you") }</div>
                            <div block="PostsListing" elem="list"></div>
                        </div>
                </div>
            </>
        );
    }

    renderFilter() {
        const {
            isLoaded,
            posts: { items }
        } = this.props;

        if (isLoaded && items.length) {
            return (
                <div block="PostsListing" elem="Filter">
                    <span>{ __("Sort by") }</span>
                    <div block="PostsListing" elem="FilterSelect">
                        <select name="order" onChange={ (e) => this.onOrderChange(e.target.value, false) }>
                            <option value="publish_time">{ __("Date") }</option>
                            <option value="title">{ __("Name") }</option>
                            <option value="views_count">{ __("Most viewed") }</option>
                        </select>
                    </div>
                </div>
            )
        }

        return '';
    }

    renderPagination() {
        const {
            posts: { count },
            pageSize,
            onPageChange,
            page,
            device
        } = this.props;

        const pageRangeDisplayed = device.isMobile ? 1 : 5;

        let nPages = Math.ceil(count / pageSize);
        let pageActive = page - 1;

        if (nPages > 1) {
            return (
                <div block="PostsListing" elem="Pagination" >
                    {
                        <PaginationView
                            previousLabel={<span
                                block="PostsListing"
                                elem="PaginationIcon"
                                mods={ { isPrevious:true } }
                            />}
                            nextLabel={ <span
                                block="PostsListing"
                                elem="PaginationIcon"
                                mods={ { isNext:true } }
                            />}
                            breakLabel={<a href="">...</a>}
                            pageCount={ nPages }
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={ pageRangeDisplayed }
                            onPageChange={onPageChange}
                            containerClassName={'pagination_container'}
                            pageClassName={'page_item'}
                            pageLinkClassName={'page_link'}
                            previousClassName={'page_item'}
                            nextClassName={'page_item'}
                            previousLinkClassName={'page_link'}
                            nextLinkClassName={'page_link'}
                            activeClassName={'active'}
                            initialPage={ pageActive }
                            forcePage={ pageActive }
                        />
                    }
                    </div>
            );
        }

        return (<></>);
    }

    renderGrid() {
        const {
            isLoaded,
            posts: { items },
            pageSize,
            category
        } = this.props;

        if (isLoaded && items.length) {
            return (
                <>
                    <div block="PostsListing" elem="GridContainer">
                    {
                    items.map((post) => (
                        <BlogPostCard
                            key={ post.post_id }
                            block="BlogPostCard"
                            post={ post }
                            category={ category }
                        />
                    ))
                    }
                    </div>
                    { this.renderPagination() }
                </>
            )
        }

        if (isLoaded) {
            return (
                <span>
                    { __('No posts in this category') }
                </span>
            );
        }

        return Array.from({ length: pageSize }, (_, i) => (
            <BlogPostCard
              key={ i }
              block="BlogPostCard"
              isPlaceholder
            />
        ));
    }

    renderLoadMore() {
        const { posts: { count }, pageSize, loadMore } = this.props;

        if (count <= pageSize) {
            return null;
        }

        return (
            <div block="PostsListing" elem="LoadMoreWrapper">
                <button
                  block="PostsListing"
                  elem="LoadMoreButton"
                  mix={ { block: 'Button' } }
                  onClick={ loadMore }
                >
                    { __('Load More') }
                </button>
            </div>
        );
    }

    renderContent() {
        const { category, categories: { items }, device } = this.props;
        const { title, category_id, content } = items.find(({ identifier }) => identifier === category) || {};
        if(title) {
            return (
                <>
                    { content ? <Html content={ content } /> : '' }
                    <ContentWrapper label="PostsListing">
                        <div block="PostsListing" elem="Header"><h2>{ title }</h2></div>
                        { this.renderFilter() }
                        <div block="PostsListing" elem="ColumnWrapper">
                            <div block="PostsListing" elem="Sidebar">
                                { this.hasSubCategories() && <h3>{ __('Categories') }</h3> }
                                <BlogCategories getChildren={ true } parent={ category_id } />
                            </div>
                            <div block="PostsListing" elem="Grid">
                                { this.renderGrid() }
                            </div>
                        </div>
                    </ContentWrapper>
                </>
            );
        } else {
            return (
                <>
                    { this.renderSlider() }
                    { device.isMobile?
                        <ContentWrapper label="PostsListing">
                            {/* <BlogSearchBar mix={ { block: 'PostsListing', elem: 'Search' } } /> */}
                            <BlogCategories />
                        </ContentWrapper>
                    :<></> }
                    <div block="PostsListing" elem="CmsBlock">
                        <CmsBlock identifier={ "blog_main_content" } />
                    </div>
                </>
            );
        }
    }

    render() {
        return (
            <main block="PostsListing">
                { this.renderContent() }
            </main>
        );
    }
}

export default PostsListing;
