import { connect } from 'react-redux';

import {
    CartDispatcher as SourceCartDispatcher,
    mapDispatchToProps as SourceMapDispatchToProps,
    ProductCardContainer as SourceProductCardContainer,
    mapStateToProps as SourceMapStateToProps,
} from 'SourceComponent/ProductCard/ProductCard.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';

//TODO: implement CartDispatcher
export const CartDispatcher = SourceCartDispatcher;

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showAlertNotSaudaPopup: (payload) => dispatch(showPopup('AlertNotSauda', payload)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
});

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    favStoreObj: state.StorePageReducer.favStoreObj,
    // TODO extend mapStateToProps
});

export class ProductCardContainer extends SourceProductCardContainer {
    // TODO implement logic
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
