import {
    CheckoutAddressBook as SourceCheckoutAddressBook,
} from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.component';

import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import './CheckoutAddressBook.style.override'
import { DeviceType } from 'Type/Device';
import CheckoutAddressForm from 'Component/CheckoutAddressForm';
import MyAccountAddressForm from 'Component/MyAccountAddressForm';
import { BILLING_STEP, SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import Loader from 'Component/Loader';

export class CheckoutAddressBook extends SourceCheckoutAddressBook {

    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        device: DeviceType.isRequired,
        activateEditMode: PropTypes.func.isRequired,
        handleAddressShipping: PropTypes.func.isRequired,
        handleAddressBilling: PropTypes.func.isRequired,
        setEditAddressId: PropTypes.func.isRequired,
        disableNewAddressMode: PropTypes.func.isRequired,
        addressNotStored: PropTypes.arrayOf(PropTypes.string).isRequired,
        setAddressTmpCreating: PropTypes.func.isRequired,
        addressTmpCreating: PropTypes.bool.isRequired,
        setAddressBillingTmpCreating: PropTypes.func.isRequired,
        addressBillingNotStored: PropTypes.arrayOf(PropTypes.string).isRequired,
        addressBillingTmpCreating: PropTypes.bool.isRequired,
        setCheckoutEditAddress: PropTypes.func.isRequired,
        zipCodeValid: PropTypes.bool.isRequired
    };

    state = {
        isCustomAddressExpanded: false,
        showFields: false,
        tempName: '',
        tempTax: '',
        billing_name_flag: true,
        billing_tax_flag: true,
        hasSetTax: false,
        tempPhone: '',
        hasSetPhone: false,
        saveOnCustomerArea: false
    };

    renderAddress = (address) => {
        const { onAddressSelect, selectedAddressId } = this.props;
        const { id } = address;

        return (
            <CheckoutAddressTable
              //onClick={ onAddressSelect }
              isSelected={ selectedAddressId === id }
              title={ __('Address #%s', id) }
              address={ address }
              key={ id }
            />
        );
    };

    renderAddressList() {
        const { onSelectChange, selectedAddressId, customer: { addresses }, editMode, createNewAddress, setCheckoutEditAddress } = this.props;
        if (!addresses) {
            //return this.renderLoading();
            return (<></>);
        }
        if (!addresses.length) {
            return this.renderNoAddresses();
        }
        if (editMode || createNewAddress) {
            return (<></>);
        }

        setCheckoutEditAddress(false);

        return (
            <>
                <div block="CheckoutAddressBook" elem="AddressSelectHolder">
                    <select
                        block="CheckoutAddressBook"
                        elem="AddressSelect"
                        value={ selectedAddressId || '' }
                        onChange={ onSelectChange }
                    >
                        <option value="" label={ __('Select an address') } />
                        { addresses.map(this.renderAddressOption) }
                    </select>
                </div>
                { addresses.map(this.renderAddress) }
            </>
        );
    }

    renderNoAddresses() {
        return (
            <div>
                <p>{ __('You have no configured addresses.') }</p>
                {/* <p>
                    <Link to={ `${ MY_ACCOUNT_URL }/${ ADDRESS_BOOK }` }>
                        { __('Go to Address Book to configure them!') }
                    </Link>
                </p> */}
            </div>
        );
    }

    renderAddressOption= (address) => {
        const { id, address_title } = address;

        return (
            <option
                key={ id }
                id={ id }
                value={ id }
            >
                { address_title }
            </option>
        );
    };

    expandCustomAddress = (value) => {
        const { onAddressSelect, disableNewAddressMode, setAddressTmpCreating, setAddressBillingTmpCreating, setNotReady, setEditAddressId, isBilling } = this.props;
        this.setState({ isCustomAddressExpanded: value });
        disableNewAddressMode(value);
        onAddressSelect({});
        if(isBilling){
            setAddressBillingTmpCreating(value);
        } else {
            setAddressTmpCreating(value);
        }
        setNotReady(value);
        setEditAddressId(null);
    };

    activateEditMode = () => {
        this.setState({ editMode: true });
    }

    renderOptionalCustomAddress() {
        const { isCustomAddressExpanded } = this.state;
        const {
            shippingFields,
            selectChanged,
            activateEditMode,
            editMode,
            isBilling,
            createNewAddress,
            addressNotStored,
            addressTmpCreating,
            setNotReady,
            addressBillingNotStored,
            addressBillingTmpCreating,
            setAddressTmpCreating,
            setAddressBillingTmpCreating,
            selectedAddressId
        } = this.props;

        if (!selectChanged && !isCustomAddressExpanded && Object.keys(shippingFields).length > 0) {
            this.expandCustomAddress(true);
        }

        /*if(addressTmpCreating || addressBillingTmpCreating){
            console.log('setting true 2');
            this.expandCustomAddress(true);
        }*/

        if(!isBilling && !addressTmpCreating){
            setAddressTmpCreating(false);
        }
        if(isBilling && !addressBillingTmpCreating){
            setAddressBillingTmpCreating(false);
        }


        return (
            <div
              block="CheckoutAddressBook"
              elem="CustomAddressWrapper"
            >
                {
                    selectedAddressId && !createNewAddress && !editMode ? <a
                    block="CheckoutAddressBook"
                    elem="ButtonEdit"
                    mods={ { editMode } }
                    onClick={ () => activateEditMode(true) }
                  >
                      { __('Edit custom Address') }
                  </a> : ''
                }
                {
                    !createNewAddress && !editMode && <a
                    block="CheckoutAddressBook"
                    elem="Button"
                    mods={ { isCustomAddressExpanded } }
                    onClick={ () => this.expandCustomAddress(true) }
                  >
                      { __('Use custom address') }
                  </a>
                }
                { ((isCustomAddressExpanded && createNewAddress) ||
                    (addressNotStored && addressNotStored.firstname != '' && addressTmpCreating) ||
                    (addressBillingNotStored && addressBillingNotStored.firstname != '' && addressBillingTmpCreating))
                    && this.renderCustomAddress()
                }
                { editMode && this.renderEditMode() }
            </div>
        );
    }

    renderCustomAddress() {
        const { isBilling, onShippingEstimationFieldsChange, addressNotStored, setIsCreatingAddress } = this.props;
        const formPortalId = isBilling ? BILLING_STEP : SHIPPING_STEP;
        const { address, handleAddressShipping, handleAddressBilling, showEditForm, activateEditMode, setIsEditing, editMode, device, addressBillingNotStored, zipCodeValid } = this.props;

        /*return (
            <CheckoutAddressForm
              onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
              address={ {} }
              id={ formPortalId }
            />
        );*/


        let tmpAddress = {};
        let flagExistAddress = false;

        if(!isBilling && addressNotStored && addressNotStored.firstname != ''){
            tmpAddress = addressNotStored;
            tmpAddress.firstname = addressNotStored.firstname.trim() + ' ';
            flagExistAddress = true;
        }

        if(isBilling && addressBillingNotStored && addressBillingNotStored.firstname != ''){
            tmpAddress = addressBillingNotStored;
            tmpAddress.firstname = addressBillingNotStored.firstname.trim() + ' ';
            flagExistAddress = true;
        }

        return (
            <MyAccountAddressForm
                address={ flagExistAddress ? tmpAddress : {} }
                onSave={ !isBilling ? handleAddressShipping : handleAddressBilling }
                showEditForm={ editMode }
                checkoutEditMode={ false }
                activateEditMode={ activateEditMode }
                setIsEditing={ setIsEditing }
                setIsCreatingAddress={ setIsCreatingAddress }
                device={ device }
                isCheckout={ true }
                expandCustomAddress={ this.expandCustomAddress }
                zipCodeValid={ zipCodeValid }
            />
        );
    }


    renderEditMode() {
        const { isBilling, onShippingEstimationFieldsChange, customer: { addresses }, selectedAddressId, editMode, setEditAddressId, device } = this.props;
        const formPortalId = isBilling ? BILLING_STEP : SHIPPING_STEP;

        const { address, handleAddressBilling, handleAddressShipping, showEditForm, activateEditMode, setIsEditing, setCheckoutEditAddress, zipCodeValid } = this.props;

        if(addresses && addresses.length>0){
            for(let i=0;i<addresses.length;i++){
                if(addresses[i].id === selectedAddressId){
                    /*return (
                        <CheckoutAddressForm
                          onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                          address={ addresses[i] }
                          id={ formPortalId }
                        />
                    );*/
                    setEditAddressId(addresses[i].id);
                    setCheckoutEditAddress(true);
                    return (
                        <MyAccountAddressForm
                            address={ addresses[i] }
                            onSave={ isBilling ? handleAddressBilling : handleAddressShipping }
                            showEditForm={ editMode }
                            checkoutEditMode={ true }
                            activateEditMode={ activateEditMode }
                            setIsEditing={ setIsEditing }
                            device={ device }
                            zipCodeValid={ zipCodeValid }
                        />
                    );
                }
            }
        }
    }

    renderOptionalChangeAddress() {

        return (
            <div
              block="CheckoutAddressBook"
              elem="CustomAddressWrapper"
            >
                <a href="/my-account/address-book"
                  block="CheckoutAddressBook"
                  elem="Button"
                >
                    { __('Change Address') }
                </a>
            </div>
        );
    }

    toggleFields = () => {
        const { setNotReady, notReady } = this.props;
        this.setState({
            showFields: !this.state.showFields
        });
        setNotReady(!this.state.showFields);
    }

    updateCustomer(){

        if(this.state.saveOnCustomerArea === true){
            const { customer } = this.props;
            const { updateCustomerData } = this.props;

            let fields = {};
            fields.taxvat = this.state.tempTax;
            fields.date_of_birth = customer.date_of_birth;
            fields.phone = this.state.tempPhone;
            fields.firstname = this.state.tempName.replace(/ .*/,"").trim();
            fields.lastname = this.state.tempName.replace(/.* /,"").trim();
            updateCustomerData(fields);
        }

    }

    saveOnCustomerAreaUpdate = () => {
        this.setState({
            saveOnCustomerArea: !this.state.saveOnCustomerArea
        });
    }

    renderOptionalChangeProfile() {
        const { showFields } = this.state;
        const { device } = this.props;
        const { saveOnCustomerArea } = this.state;

        if(device.isMobile){
            if (showFields) {
                //setNotReady(true);
                return (
                    <div
                      block="CheckoutAddressBook"
                      elem="CustomAddressWrapper"
                    >
                        <div>
                            <Field
                                checked={saveOnCustomerArea}
                                onChange={this.saveOnCustomerAreaUpdate}
                                type="checkbox"
                                    label={ <> { __('Save data on customer area') } </> }
                                id="save_on_customer_area"
                                name="save_on_customer_area"
                                name="save_on_customer_area"
                            />
                        </div>
                        <button
                            onClick={ this.onCustomInfoSuccess }
                            block="Button"
                            type="button"
                            mix={ { block: 'CheckoutAddressBook', elem: 'Confirm' } }
                        >
                            <span>{ __('Save') }</span>
                        </button>
                        <button
                            onClick={ this.toggleFields }
                            block="Button"
                            type="button"
                            mix={ { block: 'CheckoutAddressBook', elem: 'Cancel' } }
                        >
                            <span>{ __('Cancel') }</span>
                        </button>
                    </div>
                );
            }
        } else {
            if (showFields) {
                //setNotReady(true);
                return (
                    <div
                      block="CheckoutAddressBook"
                      elem="CustomAddressWrapper"
                    >
                        <div>
                            <Field
                                checked={saveOnCustomerArea}
                                onChange={this.saveOnCustomerAreaUpdate}
                                type="checkbox"
                                label={ <> { __('Save data on customer area') } </> }
                                id="save_on_customer_area"
                                name="save_on_customer_area"
                            />
                        </div>
                        <button
                            onClick={ this.toggleFields }
                            block="Button"
                            type="button"
                            mix={ { block: 'CheckoutAddressBook', elem: 'Cancel' } }
                        >
                            <span>{ __('Cancel') }</span>
                        </button>
                        <button
                            onClick={ this.onCustomInfoSuccess }
                            block="Button"
                            type="button"
                        >
                            <span>{ __('Save') }</span>
                        </button>
                    </div>
                );
            }
        }

        return (
            <div
              block="CheckoutAddressBook"
              elem="CustomAddressWrapper"
            >
                <a onClick={ this.toggleFields }
                  block="CheckoutAddressBook"
                  elem="Button"
                >
                    { __('Change Profile') }
                </a>
            </div>
        );
    }

    renderSignedInContent() {
        const { isBilling } = this.props;

        return (
            <>
                { isBilling && <h2 block="Checkout" elem="Heading">
                    <strong>{ __("Billing address") }</strong>
                </h2> }
                <div block="CheckoutAddressBook" elem="Wrapper">
                    { this.renderAddressList() }
                </div>
                { this.renderOptionalCustomAddress() }
                {/* { isBilling && this.renderOptionalChangeAddress() } */}
            </>
        );
    }

    setTempName(name) {
        this.setState({
            tempName: name.trimRight()
        });
    }

    setBillingPhone(phone) {
        this.setState({
            tempPhone: phone
        });
    }

    setTempTax(tax) {
        const { setCustomBillingNif } = this.props;
        setCustomBillingNif(tax);
        this.setState({
            tempTax: tax,
            hasSetTax: true
        });
    }

    validateTax(value) {
        if(!value || !value.length) {
            return true;
        }

        const nif = typeof value === 'string' ? value : value.toString();
        const validationSets = {
            one: ['1', '2', '3', '5', '6', '8'],
            two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99']
        };

        if (nif.length !== 9) {
            return false;
        }

        if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) {
            return false;
        }

        const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
        const modulo11 = (Number(total) % 11);
        const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

        return checkDigit === Number(nif[8]);
    }

    onCustomInfoSuccess = () => {
        const {
            setBillingName,
            setBillingTax
        } = this.props;
        const { tempName, tempTax } = this.state;
        if(!tempName || !tempName.match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+ [a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+/)) {
            this.setState({billing_name_flag: false});
            return;
        }

        this.setState({billing_name_flag: true});

        if(!this.validateTax(tempTax)) {
            this.setState({billing_tax_flag: false});
            return;
        }

        this.setState({billing_tax_flag: true});

        setBillingName(tempName);
        setBillingTax(tempTax);
        this.toggleFields();
        this.updateCustomer();
    }

    renderProfileContent() {
        const {
            billing_phone_flag,
            billing_phone,
            setPhone,
            billing_name,
            setBillingName,
            billing_tax,
            setBillingTax,
            customer: { taxvat, phone },
            customBillingPhone,
            customBillingNif,
            shippingAddress: { firstname, lastname },
            billingAddress,
            customer,
            selectedAddressId
        } = this.props;
        const { showFields, tempName, tempTax, billing_tax_flag, billing_name_flag, hasSetTax, hasSetPhone, tempPhone } = this.state;

        let name = firstname + ' ' + lastname;

        /*if(!billing_phone) {
            setPhone(phone);
        }*/
        let flagName = false;
        if(!billing_name) {
            let customerAddresses = customer.addresses;
            for(let i=0; i<customerAddresses.length; i++){
                if(customerAddresses[i].id == selectedAddressId){
                    let billingName = customerAddresses[i].firstname + ' ' + customerAddresses[i].lastname;
                    setBillingName(billingName);
                    this.setTempName(billingName);
                    flagName = true;
                } else {
                    if(!flagName){
                        let billingName = customer.firstname + ' ' + customer.lastname;
                        setBillingName(billingName);
                        this.setTempName(billingName);
                    }
                }
            }
        }
        if(!billing_tax && taxvat && !hasSetTax) {
            setBillingTax(taxvat);
            this.setTempTax(taxvat);
        } else {
            if(customBillingNif != ''){
                setBillingTax(customBillingNif);
                this.setTempTax(customBillingNif);
            }
        }

        let billingPhoneFlagContent = <><div class="message-telephone-required"><span>{ __('This field is required!') }</span></div></>;
        let billingNameFlagContent = <><div class="message-telephone-required"><span>{ __('Please enter your full name.') }</span></div></>;
        let billingTaxFlagContent = <><div class="message-telephone-required"><span>{ __('The NIF number is invalid!') }</span></div></>;

        let topFields = <>
            <p>{ __("Name") }<span><strong>{ billing_name || __('No name') }</strong></span></p>
            <p>{ __("NIF") }<span><strong>{ billing_tax || __('No nif') }</strong></span></p>
        </>;

        if (showFields) {
            topFields = <>
                <Field
                    type="text"
                    label={ __('Name') }
                    validation={['notEmpty', 'full_name']}
                    value={ tempName }
                    name={ 'billing_name' }
                    onChange={ val => { this.setTempName(val) } }
                    className={ !billing_name_flag ? ' telephone-required' : '' }
                />
                { !billing_name_flag ? billingNameFlagContent : '' }
                <Field
                    type="numeric"
                    label={ __('NIF') }
                    validation={['nif']}
                    value={ tempTax }
                    name={ 'billing_tax' }
                    onChange={ val => { this.setTempTax(val) } }
                    className={ !billing_tax_flag ? ' telephone-required' : '' }
                />
                { !billing_tax_flag ? billingTaxFlagContent : '' }
            </>;
        }

        let customPhone = '';

        if (customBillingPhone != '') {
            customPhone = customBillingPhone;
            setPhone(customPhone);
        } else if(billing_phone && billing_phone != '') {
            customPhone = billing_phone;
            this.setBillingPhone(customPhone);
        } else {
            customPhone = tempPhone;
        }

        return (
            <div>
                <h2 block="Checkout" elem="Heading">
                    <strong>{ __("My Profile") }</strong>
                </h2>
                <div block="CheckoutAddressBook" elem="MyProfile">
                    { topFields }
                    <Field
                        type={ 'phone' }
                        label={ __('Telephone') }
                        validation={ ['telephone_optional'] }
                        value={ customPhone }
                        name={ 'billing_phone' }
                        onChange={ val => { this.onChangePhone(val) } }
                        className={ !billing_phone_flag ? ' telephone-required' : '' }
                    />
                    { !billing_phone_flag && !billing_phone ? billingPhoneFlagContent : '' }
                </div>
                { this.renderOptionalChangeProfile() }
            </div>
        );
    }

    onChangePhone = (val) => {
        const { setPhone, setCustomBillingPhone } = this.props;
        setPhone(val);
        this.setBillingPhone(val);
        setCustomBillingPhone(val);
    }


    render() {
        const { isBilling, is_wallet, isLoading, mainLoaderActive } = this.props;

        if(is_wallet){
            return (
                <div block="CheckoutAddressBook">
                    { <Loader isLoading={ isLoading && !mainLoaderActive } /> }
                    <div block="CheckoutAddressBook" elem="ContentWrapper">{ this.renderContent() }</div>
                    <div block="CheckoutAddressBook" elem="Separator"></div>
                    <div block="CheckoutAddressBook" elem="Message">
                        { isBilling ? this.renderProfileContent() :
                            <strong>{ __('Home delivery is only available for the localities near the pharmacy.') }</strong>
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div block="CheckoutAddressBook">
                    { <Loader isLoading={ isLoading&& !mainLoaderActive } /> }
                    <div block="CheckoutAddressBook" elem="ContentWrapper">{ this.renderContent() }</div>
                    <div block="CheckoutAddressBook" elem="Separator"></div>
                    <div block="CheckoutAddressBook" elem="Message">
                        { isBilling ? this.renderProfileContent() : '' }
                    </div>
                </div>
            );
        }
    }

};

export default CheckoutAddressBook;
