import { connect } from 'react-redux';

import { CMS_PAGE } from 'Component/Header/Header.config';
import CmsPageQuery from 'Query/CmsPage.query';
import history from 'Util/History';
import { debounce } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';
import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';

import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    CmsPageContainer as SourceCmsPageContainer
} from 'SourceRoute/CmsPage/CmsPage.container';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state)
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    toggleBreadcrumbs: () => dispatch(toggleBreadcrumbs())
});

export class CmsPageContainer extends SourceCmsPageContainer {
    componentDidMount() {
        const {
            isOffline,
            isOnlyPlaceholder,
            toggleBreadcrumbs
        } = this.props;

        toggleBreadcrumbs(false)

        const { isLoading } = this.state;

        if (isOffline && isLoading) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (!isOnlyPlaceholder) {
            this.requestPage();
        }

        if (!localStorage.getItem('load_cms_content_dt')) {
            localStorage.setItem('load_cms_content_dt', new Date().getTime() );
        }
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
            pageIdentifiers,
            pageIds
        } = this.props;

        const {
            location: { pathname: prevPathname },
            pageIdentifiers: prevPageIdentifiers,
            pageIds: prevPageIds
        } = prevProps;

        if (
            pathname !== prevPathname
            || pageIds !== prevPageIds
            || pageIdentifiers !== prevPageIdentifiers
        ) {
            this.requestPage();
        }
        if(pathname.includes('vantagens')){
            if(document.querySelector(".catalogo .Tabs-TabButtons")){ document.querySelector(".catalogo .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-catalogo").offsetLeft, 0);}
            if(document.querySelector(".vantagens .Tabs-TabButtons")){ document.querySelector(".vantagens .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-vantagens").offsetLeft, 0);}
            if(document.querySelector(".como-funciona .Tabs-TabButtons")){ document.querySelector(".como-funciona .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-como-funciona").offsetLeft, 0);}
            if(document.querySelector(".parcerias .Tabs-TabButtons")){ document.querySelector(".parcerias .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-parcerias").offsetLeft, 0);}
            if(document.querySelector(".folheto .Tabs-TabButtons")){ document.querySelector(".folheto .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-folheto").offsetLeft, 0);}
            if(document.querySelector(".revista .Tabs-TabButtons")){ document.querySelector(".revista .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-revista").offsetLeft, 0);}
        }
    }

    onPageLoad = ({ cmsPage: page }) => {
        const {
            location: { pathname },
            updateMeta,
            setHeaderState,
        } = this.props;

        const {
            content_heading,
            meta_title,
            title,
            meta_description
        } = page;

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateMeta({
            title: meta_title || title,
            description: meta_description,
            canonical_url: window.location.href
        });

        if (
            pathname !== appendWithStoreCode('/')
            && pathname !== '/'
        ) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack()
            });
        }

        this.setState({ page, isLoading: false });
        if( window.location.pathname.includes('vantagens')){
            if(document.querySelector(".catalogo .Tabs-TabButtons")){ document.querySelector(".catalogo .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-catalogo").offsetLeft, 0);}
            if(document.querySelector(".vantagens .Tabs-TabButtons")){ document.querySelector(".vantagens .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-vantagens").offsetLeft, 0);}
            if(document.querySelector(".como-funciona .Tabs-TabButtons")){ document.querySelector(".como-funciona .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-como-funciona").offsetLeft, 0);}
            if(document.querySelector(".parcerias .Tabs-TabButtons")){ document.querySelector(".parcerias .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-parcerias").offsetLeft, 0);}
            if(document.querySelector(".folheto .Tabs-TabButtons")){ document.querySelector(".folheto .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-folheto").offsetLeft, 0);}
            if(document.querySelector(".revista .Tabs-TabButtons")){ document.querySelector(".revista .Tabs-TabButtons").scrollTo(document.querySelector(".menu-vantagens-revista").offsetLeft, 0);}
        }
    };

    requestPage() {
        const params = this.getRequestQueryParams();
        const { id, identifier } = params;

        if (!id && !identifier) {
            return;
        }

        this.setState({ isLoading: true });

        this.fetchData(
            [CmsPageQuery.getQuery(params)],
            this.onPageLoad,
            () => this.setState({isLoading: false })
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
