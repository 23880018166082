import {
    CheckoutPayments as SourceCheckoutPayments,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';
import CheckoutPayment from 'Component/CheckoutPayment/CheckoutPayment.component';
import CheckoutQuery from 'Query/Checkout.query';
import { fetchQuery } from 'Util/Request';
import './CheckoutPayments.style.override';
import Field from 'Component/Field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import CmsBlock from 'Component/CmsBlock';

export class CheckoutPayments extends SourceCheckoutPayments {

    state = {
        mbway: ""
    }

    renderPayments() {
        const { paymentMethods,totals: { is_wallet }  } = this.props;

        if(is_wallet) {
            const payMethods = paymentMethods.filter(item => {
                return item.code == 'free' || item.code == 'checkmo' || item.code == 'cashondelivery' ;
            });

            return payMethods.map(this.renderPayment);

        } else {

            return paymentMethods.map(this.renderPayment);
        }
    }

    renderHeading() {
        return (
            <h4 block="CheckoutPayments" elem="Heading">
                { __('Select payment method') }
            </h4>
        );
    }

    renderPayment = (method) => {
        const {
            selectPaymentMethod,
            selectedPaymentCode,
            isMobile,
            totals: { is_wallet },
            seletedPaymentCustom,
            setSelectedPayment
        } = this.props;

        const { code } = method;

        const isSelected = selectedPaymentCode === code;

        return (
            <CheckoutPayment
              isMobile = { isMobile }
              renderStatus = { this.renderStatus.bind(this) }
              key={ code }
              isSelected={ isSelected }
              method={ method }
              onClick={ selectPaymentMethod }
              is_wallet = { is_wallet }
              seletedPaymentCustom={ seletedPaymentCustom }
              setSelectedPayment={ setSelectedPayment }
            />
        );
    };

    renderStatus() {
        const {
            selectedPaymentCode,
            isMobile,
            mbway_phone_flag,
            customBillingMbway,
            customer: {
                phone
            }
        } = this.props;

        const { mbway } = this.state;

        let mbWayPhone = mbway;

        if(mbway == ""){
            mbWayPhone = customBillingMbway;
            this.doMbwayPhone(mbWayPhone);
        }

        if(mbWayPhone == "") {
            mbWayPhone = phone;
            this.doMbwayPhone(mbWayPhone);
        }

        const isSelected = selectedPaymentCode;

        //<input type="text" name="mbway_phone" maxlength="9" placeholder={ __("Phone Number") } onChange={ this.doMbwayPhone }/>

        if (isSelected == "mbway") {
            let billingPhoneFlagContent = <><div class="message-telephone-required"><span>{ __('This field is required!') }</span></div></>;

            return (
                <div block="CheckoutPayments" elem="Status">
                    <h3>{ __("Enter the phone number associated to the MBWAY service") }</h3>
                    <Field
                        type={ 'phone' }
                        label={ __('Phone Number') }
                        validation={ ['telephone_optional'] }
                        value={ mbWayPhone }
                        name={ 'mbway_phone' }
                        onChange={ val => { this.doMbwayPhone(val) } }
                        className={ !mbway_phone_flag ? 'telephone-required' : '' }
                    />
                    { !mbway_phone_flag && !mbway ? billingPhoneFlagContent : '' }
                </div>
            );
        } else if (isSelected == "mb") {
            return (
                <div block="CheckoutPayments" elem="Status">
                    <p>{ __("You will receive a reference to pay after order confirmation.") }</p>
                </div>
            );
        } else if (isSelected == "creditcard") {
            return (
                <div block="CheckoutPayments" elem="Status">
                    <p>{ __("For pay with Credit Card or Debit, you will be redirected to another page.") }</p>
                </div>
            );
        }
    }

    doMbwayPhone = (val) => {

        const { setMbwayPhone, getMbwayStatus, setMbwayPhoneFlag, setCustomBillingMbway } = this.props;

        let phone = val;
        if (phone && phone.match(/^9[0-9]{8}$/)) {
            setMbwayPhoneFlag(true);
            this.setState({ mbway: phone });
            setCustomBillingMbway(phone);
            setMbwayPhone(phone);

        } else {
            setMbwayPhoneFlag(false);
            setMbwayPhone(phone);
        }
        //this.doMutationphone(phone);

        /*if (setMbwayPhone(phone)) {
            this.doMutationphone(phone);
        }*/

        /*if (phone.length == 9) {
            this.doMutationphone(phone);
        }*/
    }

    doMutationphone(phone){
        const query = CheckoutQuery.updateQuoteMbwayPhone(phone);
            return fetchQuery(query).then(
                (result) => {
                    //console.log('result', result);
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    renderWalletMessage() {
        return(
            <div block="CheckoutPayments" elem="WalletMessage">
                <FontAwesomeIcon className="CartFloating-AlertIcon" icon={ faExclamationCircle } />
                <CmsBlock identifier={ "checkout_payments_wallet_notice" } />
            </div>
        );
    }

    renderContent() {
        const { hasError } = this.state;
        const { isMobile, totals, paymentMethods, selectPaymentMethod, totals: { is_wallet } } = this.props;

        if (hasError) {
            return (
                <p>{ __('The error occurred during initializing payment methods. Please try again later!') }</p>
            );
        }

        if(totals.grand_total == 0 && !is_wallet) {
            selectPaymentMethod(paymentMethods[0]);
            return (<></>);
        } else {
            return (
                <>
                    { this.renderHeading() }
                    <ul block="CheckoutPayments" elem="Methods">
                        { this.renderPayments() }
                    </ul>
                    { is_wallet && this.renderWalletMessage() }
                    { !isMobile && this.renderStatus() }
                </>
            );
        }
    }
};

export default CheckoutPayments;
