/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { throttle } from 'lodash';

/** @namespace Component/MyAccountAutoLogout/Container */
export class MyAccountAutoLogout extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
    };

    __construct(props) {
        super.__construct(props);

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.warnThrottled = throttle(this.warn, 1000);
        this.logoutThrottled = throttle(this.logout, 1000);
    }

    state = {
        warningTime: 59 * 60 * 1000, // Warning Timer
        signoutTime: 60 * 60 * 1000 // Logout Timer
    }

    componentDidMount() {
        this.events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        //console.log("timer online!");
        this.setTimeout();
    }

    refreshTimeoutFunc = () => {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
        this.warnTimeout = setTimeout(this.warnThrottled, this.state.warningTime);
        this.logoutTimeout = setTimeout(this.logoutThrottled, this.state.signoutTime);
    };

    resetTimeout = () => {
        this.refreshTimeoutFunc();
        this.setTimeout();
    };

    warn = () => {
        const { showNotification } = this.props;

        showNotification('info', __('You will be logged out automatically in 1 minute.'));
    };

    logout = () => {
        const { showNotification, handleLogout } = this.props;
        // Send a logout request to the API
        showNotification('error', __('Sending a logout request...'));
        handleLogout();
    };

    render() {
        return(
            <></>
        );
    }
}

export default MyAccountAutoLogout;
