import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import gtag, { install } from 'ga-gtag';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import ConfigQuery from 'Query/Config.query';
import { StoreFinderDispatcher } from '../../store/StoreFinder';

import {
    HeaderContainer as SourceHeaderContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/Header/Header.container';

import { StorePageDispatcher } from '../../store/StorePage';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { STORE_PAGE_BASE_URL } from 'Route/StorePage/StorePage.config';
import { isSignedIn as isSignedInWithToken, deleteAuthorizationToken } from 'Util/Auth';
import {
    MY_ACCOUNT,
    WISHLIST_OVERLAY,
    WISHLIST,
    CHECKOUT_ACCOUNT,
    CUSTOMER_ACCOUNT,
    CUSTOMER_SUB_ACCOUNT,
    CART,
    CART_OVERLAY,
    MENU,
    SEARCH
} from './Header.config';

import { customerType } from 'Type/Account';
import { DeviceType } from 'Type/Device';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { CheckoutSaudaDispatcher } from 'Store/CheckoutSauda';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import HeadScriptsQuery from 'Query/HeadScripts.query';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import Header from './Header.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    favStoreObj: state.StorePageReducer.favStoreObj,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    saudaCardPoints: state.CheckoutSaudaReducer.getSaudaCardPoints,
    activeOverlay: state.OverlayReducer.activeOverlay,
    accountIsLoading: state.MyAccountReducer.isLoading,
    loginIsLoading: state.MyAccountReducer.isLoadingLoging,
    customerRequestDone: state.MyAccountReducer.customerRequestDone,
    isAuthTokenExpired: state.MyAccountReducer.isAuthTokenExpired,
    services: state.StoreFinderReducer.serviceList
});

export const mapDispatchToProps = dispatch => ({
    getAvailableServices: () => StoreFinderDispatcher.requestServicesData(dispatch)
    ,
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
    ...SourceMapDispatchToProps(dispatch),
    requestStore: (id) => {
        StorePageDispatcher.requestFavStore(dispatch, id);
    },
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    requestSaudaPoints: () => {
        CheckoutSaudaDispatcher.getSaudaCardPoints(dispatch);
    },
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showInfoPopup: (payload) => dispatch(showPopup('InfoPopup', payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    showAlertNotSaudaPopup: (payload) => dispatch(showPopup('AlertNotSauda', payload)),
    showSaudaCardBlockedPopup: (payload) => dispatch(showPopup('SaudaCardBlocked', payload)),
    signIn: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.signIn(options, dispatch)
    ),
});

export class HeaderContainer extends SourceHeaderContainer {

    state = {
        guestPrevPharmacyCode: '',
        ignoreClick: false,
        googleAnalyticsCode: null,
        showPharmacySelectorPopup: false
    }

    static propTypes = {
        showOverlay: PropTypes.func.isRequired,
        isWishlistLoading: PropTypes.bool.isRequired,
        showPopup: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        header_logo_src: PropTypes.string,
        device: DeviceType.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        customer: customerType.isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        getAvailableServices: PropTypes.func.isRequired
    };

    containerFunctions = {
        onBackButtonClick: this.onBackButtonClick.bind(this),
        onInfoClick: this.onInfoClick.bind(this),
        onClickNoStore: this.onClickNoStore.bind(this),
        onClickPresNoStore: this.onClickPresNoStore.bind(this),
        onClickPresNoStoreNotLogged: this.onClickPresNoStoreNotLogged.bind(this),
        onCloseButtonClick: this.onCloseButtonClick.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this),
        onMyAccountButtonClick: this.onMyAccountButtonClick.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onClearButtonClick: this.onClearButtonClick.bind(this),
        onEditButtonClick: this.onEditButtonClick.bind(this),
        onMinicartButtonClick: this.onMinicartButtonClick.bind(this),
        onOkButtonClick: this.onOkButtonClick.bind(this),
        onCancelButtonClick: this.onCancelButtonClick.bind(this),
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        onMyAccountOutsideClick: this.onMyAccountOutsideClick.bind(this),
        onMinicartOutsideClick: this.onMinicartOutsideClick.bind(this),
        onSignIn: this.onSignIn.bind(this),
        shareWishlist: this.shareWishlist.bind(this),
        hideActiveOverlay: this.props.hideActiveOverlay,
        onClickMenuToggle: this.onClickMenuToggle.bind(this),
        onClickOut: this.onClickOut.bind(this),
        onWishlistOutsideClick: this.onWishlistOutsideClick.bind(this),
        onWishlistButtonClick: this.onWishlistButtonClick.bind(this),
        onClickNoStoreContext: this.onClickNoStoreContext.bind(this),
        showPharacySelectorPopup: this.showPharacySelectorPopup.bind(this),
        showAlertNotSaudaPopup: this.props.showAlertNotSaudaPopup,
        showSaudaCardBlockedPopup: this.props.showSaudaCardBlockedPopup,
        signIn: this.props.signIn.bind(this),
        setShowPharmacySelectorPopup: this.setShowPharmacySelectorPopup.bind(this)
    };

    showPharacySelectorPopup() {
        const { showPharacySelectorPopup, requestStores } = this.props;
        requestStores();
        showPharacySelectorPopup();
    }

    containerProps = () => {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            device,
            isWishlistLoading,
            customer,
            setNavigationState,
            services
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin,
            isMenuVisible
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);
        const isMyAccount = pathname.includes(MY_ACCOUNT_URL) || activeOverlay === 'MenuMobile' || activeOverlay === 'SaudaCardPopup';
        const isStorePage = pathname.includes('/farmacia/');
        const isCateOrProductPage = pathname.includes('.html') || pathname.includes('search');
        const isHomePage = pathname == '/';
        const isWishlist = pathname.includes('/my-wishlist');
        const isCartPage = pathname.includes('/cart');

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            showMyAccountLogin,
            device,
            isWishlistLoading,
            isMenuVisible,
            customer,
            setNavigationState,
            isMyAccount,
            isStorePage,
            isCartPage,
            isHomePage,
            isWishlist,
            isCateOrProductPage,
            services
        };
    };

    __construct(props) {
        super.__construct(props);

        const {
            location: {
                pathname
            }
        } = history;

        this.state = {
            prevPathname: pathname,
            searchCriteria: '',
            isClearEnabled: this.getIsClearEnabled(),
            showMyAccountLogin: false,
            isMenuVisible: false,
            selectedStore: {},
            hasShownSelector: false,
            hasRequestedCustomer: false
        };

    }

    componentWillMount() {
        const {
            requestCustomerData,
            customer,
            isSignedIn,
            activeOverlay,
            signIn
        } = this.props;

        const rememberMe = window.localStorage.getItem('rememberMe');

        if(rememberMe == "true" || rememberMe == "direct"){
            const email = window.localStorage.getItem('email');
            const access = window.localStorage.getItem('access');
            signIn({email: email, password: access, rememberMe: 'direct'});
        }

        if (isSignedIn && !Object.keys(customer).length) {
            requestCustomerData();
        }

        this.unlisten = history.listen((location, action) => {
            if (location.pathname !== this.state.prevPathname) {
                window.scrollTo(0, 0);
                this.setState({ prevPathname: location.pathname });

                if(this.state.googleAnalyticsCode) {
                    ReactGA.set({ page: location.pathname });
                    ReactGA.pageview(location.pathname + location.search);
                }
            }

            const { pathname } = location;
            const isMyAccount = pathname.includes(MY_ACCOUNT_URL) || activeOverlay === 'MenuMobile' || activeOverlay === 'SaudaCardPopup';
            const isStorePage = pathname.includes('/farmacia/');
            const isCateOrProductPage = pathname.includes('.html');
            const isWishlist = pathname.includes('/my-wishlist');
            const isHomePage = pathname == '/';
            const isCartPage = pathname.includes('/cart');

            this.setState({ isMyAccount, isStorePage, isCateOrProductPage, isHomePage, isCartPage, isWishlist });
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    getCookie(name) {
        const value = `; ${decodeURIComponent(document.cookie)}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    marketingAndStatisticsAllowed(cookie){

        if(!cookie || typeof cookie !== 'string'){
            return false;
        }

        let fields = cookie.split(",");
        let counter = 0;

        for(let i = 0;i < fields.length;i++){
            let splited = fields[i].split(":");

            if(!splited[0] || !splited[1]){
                return false;
            }

            let key = splited[0].replace(/[\{\}\'\"]/g,"").trim();
            let value = splited[1].replace(/[\{\}\'\"]/g,"").trim();

            if((key === "marketing" && value === "true") || (key === "statistics" && value === "true")){
                counter++;
            }
        }

        return counter >= 2;
    }

    componentDidMount() {
        const { favStoreObj, requestSaudaPoints, requestStore, isSignedIn, customer: { favourite_pharmacy_code } } = this.props;

        this.initGTM();
        this.initFacebookPixel();

        executeGet(prepareQuery(ConfigQuery.getAnalyticsConfig('initialize')), 'Header')
            .then(
                ({ getAnalyticsConfig }) => {
                    if(getAnalyticsConfig && getAnalyticsConfig.config_value && parseInt(getAnalyticsConfig.config_value) === 1){
                        this.initAnalytics();
                    }
                })
            .catch(
                (e) => showNotification('error', 'Error get Analytics Config', e)
            );

        if (favourite_pharmacy_code) {
            requestStore(favourite_pharmacy_code);
        } else {
            if (localStorage.getItem('guest_pharmacy_code')) {

                if (localStorage.getItem('guest_pharmacy_code_expire_time')) {

                    let expire = localStorage.getItem('guest_pharmacy_code_expire_time');
                    let expireObj = JSON.parse(expire);

                    if (new Date().getTime() - expireObj.time >= expireObj.expire) {
                        localStorage.removeItem('guest_pharmacy_code');
                        localStorage.removeItem('guest_pharmacy_code_expire_time')
                    } else {
                        requestStore(localStorage.getItem('guest_pharmacy_code'));
                        this.setState({ guestPrevPharmacyCode: localStorage.getItem('guest_pharmacy_code') })
                    }

                } else {
                    localStorage.removeItem('guest_pharmacy_code');
                }
            }
        }
        this.getAvailableServices();
        if(isSignedIn){
            requestSaudaPoints();
        }

        document.addEventListener('mousedown', this.handleMouseDown.bind(this));
    }

    initFacebookPixel() {
        executeGet(prepareQuery(ConfigQuery.getFacebookPixelConfig()), 'Header')
            .then(
                ({ getFacebookPixelConfig }) => {
                    ReactPixel.init(getFacebookPixelConfig.config_value);
                    ReactPixel.pageView();
                }
            )
            .catch(
                (e) => showNotification('error', 'Error initialize FacebookPixe!', e)
            );
    }

    initGTM() {
        executeGet(prepareQuery(HeadScriptsQuery.getQuery()), 'Header')
            .then(
                ({ getScripts }) => {
                    if(getScripts.googleAnalytics && getScripts.accountType == 'universal') {
                        ReactGA.initialize(getScripts.googleAnalytics);
                        ReactGA.set({ page: location.pathname });
                        ReactGA.pageview(window.location.pathname + window.location.search);
                        this.setState({
                            googleAnalyticsCode: getScripts.googleAnalytics
                        });
                    } else if (getScripts.googleAnalytics && getScripts.accountType == 'tag_manager') {
                        const tagManagerArgs = {
                            gtmId: getScripts.googleAnalytics,
                        }
                        TagManager.initialize(tagManagerArgs);
                    }
                }
            )
            .catch(
                (e) => showNotification('error', 'Error initialize Tag Manager', e)
            );
    }

    initAnalytics() {

        executeGet(prepareQuery(ConfigQuery.getAnalyticsConfig('gtag_id')), 'Header')
            .then(
                ({ getAnalyticsConfig }) => {
                    install(getAnalyticsConfig.config_value, { 'send_page_view': false });
                })
            .catch(
                (e) => showNotification('error', 'Error get Analytics Config', e)
            ).then(() => {
                executeGet(prepareQuery(ConfigQuery.getAnalyticsConfig('visitors')), 'Header')
                    .then(
                        ({ getAnalyticsConfig }) => {
                            gtag('event', 'conversion', {
                                'allow_custom_scripts': true,
                                'send_to': getAnalyticsConfig.config_value
                            });
                        }
                        )
                    .catch(
                        (e) => showNotification('error', 'Error generating event', e)
                    )
            });
    }

    getAvailableServices() {
        const { getAvailableServices } = this.props;

        getAvailableServices();

    }

    componentDidUpdate(prevProps) {
        const {
            showPharacySelectorPopup,
            favStoreObj,
            requestStore,
            customer,
            customer: { favourite_pharmacy_code, sauda_card, firstname },
            isSignedIn,
            requestSaudaPoints,
            activeOverlay,
            showSaudaCardBlockedPopup,
            requestCustomerData,
            customerRequestDone,
            requestStores
        } = this.props;
        const { hasShownSelector, hasRequestedCustomer } = this.state;
        const { customer: { favourite_pharmacy_code: prevCode, sauda_card: prevSauda_card }, isSignedIn: prevIsSignedIn, prevActiveOverlay } = prevProps;

        if(!isSignedIn && localStorage.getItem('login_time')) {
            localStorage.removeItem('login_time');
        }

        if (favourite_pharmacy_code != prevCode) {
            requestStore(favourite_pharmacy_code);
        }
        if (localStorage.getItem('guest_pharmacy_code') &&
            (localStorage.getItem('guest_pharmacy_code') != this.state.guestPrevPharmacyCode)
        ) {
            requestStore(localStorage.getItem('guest_pharmacy_code'));
            this.setState({ guestPrevPharmacyCode: localStorage.getItem('guest_pharmacy_code') });
        }

        if ((isSignedIn && isSignedIn !== prevIsSignedIn)) {
            requestCustomerData();
            requestSaudaPoints();
        }

        if (prevActiveOverlay !== activeOverlay) {
            if (activeOverlay === 'MenuMobile' || activeOverlay === 'SaudaCardPopup') {
                this.setState({ isMyAccount: true })
            }
        }

        if(isSignedIn && customerRequestDone){
            if(customer && !hasRequestedCustomer && !favourite_pharmacy_code && localStorage.getItem('guest_pharmacy_code')){
                requestCustomerData();
                this.setState({
                    hasRequestedCustomer: true
                });
            }
            if(firstname && !hasShownSelector && !localStorage.getItem('guest_pharmacy_code') && !favourite_pharmacy_code) {
                this.setShowPharmacySelectorPopup();
                requestStores();
                showPharacySelectorPopup();
                this.setState({
                    hasShownSelector: true
                });
            }
        }
    }

    setShowPharmacySelectorPopup(){
        this.setState({ showPharmacySelectorPopup: true });
    }

    onClickMenuToggle() {
        this.setState({ isMenuVisible: !this.state.isMenuVisible });
    }

    onClickOut() {
        this.setState({ isMenuVisible: false });
    }

    onClickNoStore() {
        const { showNotification, showPharacySelectorPopup, requestStores } = this.props;
        BrowserDatabase.setItem('/', 'redirect_to');
        //showNotification('info', __('Please select a Store!'));
        this.setShowPharmacySelectorPopup()
        requestStores();
        showPharacySelectorPopup();
    }

    onClickNoStoreContext() {
        const { showNotification, showPharacySelectorPopup,requestStores } = this.props;
        const urlInfo = '/farmacia-info/';
        BrowserDatabase.setItem(urlInfo, 'redirect_to');
        //showNotification('info', __('Please select a Store!'));
        this.setShowPharmacySelectorPopup()
        requestStores();
        showPharacySelectorPopup();
    }

    onClickPresNoStore() {
        const { showNotification, showPharacySelectorPopup, requestStores } = this.props;
        //showNotification('info', __('You must choose a pharmacy to access prescriptions'));
        BrowserDatabase.setItem('/my-account/prescription', 'redirect_to');
        //history.push(appendWithStoreCode(`/selecionador-de-farmacias`));

        this.setShowPharmacySelectorPopup()
        requestStores();
        showPharacySelectorPopup();
    }

    onClickPresNoStoreNotLogged() {
        const { showNotification, showPharacySelectorPopup, requestStores } = this.props;
        showNotification('info', __('You must be logged in to access prescriptions'));

        if (isSignedInWithToken()) {
            this.setShowPharmacySelectorPopup()
            requestStores();
            showPharacySelectorPopup();
        }
    }

    onInfoClick(store) {
        const { favStoreObj: { pharmacy_code } } = this.props;
        const urlInfo = '/farmacia-info/' + pharmacy_code;
        history.push(urlInfo);
        /* const { showInfoPopup } = this.props;

        this.setState({
            InfoMenu: !this.state.InfoMenu,
            showPopup:true,
        });

        showInfoPopup({
            action: 'InfoPopup',
            title: __(''), // Confirm delete
            store
        }); */
    }

    onWishlistButtonClick() {
        const {
            showOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (device.isMobile) {
            if (!isSignedInWithToken()) {
                const { pathname } = location;
                if (pathname !== appendWithStoreCode(`/${MY_ACCOUNT}`)) {
                    history.push(appendWithStoreCode(`/${MY_ACCOUNT}`));
                }
            }
        }

        if (name === WISHLIST_OVERLAY) {
            return;
        }

        if (!device.isMobile) {
            this.setState({ shouldRenderWishlistOverlay: true });

            showOverlay(WISHLIST_OVERLAY);
            return;
        }
    }

    onMyAccountButtonClick() {
        const {
            showOverlay,
            setNavigationState,
            isSignedIn,
            device
        } = this.props;

        if (device.isMobile) {
            const { pathname } = location;
            window.scrollTo(0, 0);
            if (pathname !== appendWithStoreCode(`/${MY_ACCOUNT}`)) {
                history.push(appendWithStoreCode(`/${MY_ACCOUNT}`));
            }
        } else {
            // if (isSignedIn && !isSignedInWithToken()) {
            //     return;
            // }
            // if (isSignedInWithToken()) {
            //     history.push({ pathname: appendWithStoreCode('/my-account/dashboard') });
            //     return;
            // }

            this.setState({ showMyAccountLogin: true }, () => {
                showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
                setNavigationState({
                    name: CHECKOUT_ACCOUNT,
                    title: '', // Sign in
                    onCloseClick: this.closeOverlay
                });
            });
        }
    }

    onWishlistOutsideClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (device.isMobile || name !== WISHLIST_OVERLAY) {
            return;
        }

        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    onMyAccountOutsideClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (device.isMobile || ![CUSTOMER_ACCOUNT, CUSTOMER_SUB_ACCOUNT, CHECKOUT_ACCOUNT].includes(name)) {
            return;
        }

        if (name === CUSTOMER_SUB_ACCOUNT) {
            goToPreviousNavigationState();
        }

        if (this.state.ignoreClick) {
            this.setState({ ignoreClick: false });
        } else {
            this.goToDefaultHeaderState();
            hideActiveOverlay();
        }

    }

    handleMouseDown(e) {
        var element = document.getElementsByClassName('Overlay')[0];

        if (element && element.contains(e.target)) {
            this.setState({ ignoreClick: true });
        } else {
            this.setState({ ignoreClick: false });
        }
    }

    onMinicartButtonClick() {
        const {
            isSignedIn,
            showNotification,
            customer,
            showPharacySelectorPopup,
            showOverlay,
            favStoreObj,
            navigationState: { name },
            device,
            requestStores
        } = this.props;

        if((isSignedIn && customer && !favStoreObj.pharmacy_code) || (!isSignedIn && !localStorage.getItem('guest_pharmacy_code'))) {
            //showNotification('info', __('You have nothing pharmacy selected, choose your pharmacy.'));
            this.setShowPharmacySelectorPopup()
            requestStores();
            showPharacySelectorPopup();
        } else {
            if (name === CART_OVERLAY) {
                return;
            }
            if (!device.isMobile) {
                this.setState({ shouldRenderCartOverlay: true });
                showOverlay(CART_OVERLAY);
                return;
            }
            history.push(appendWithStoreCode(`/${ CART }`));
        }
    }

    render() {
        const { favStoreObj, baseLinkUrl, accountIsLoading, customerRequestDone, loginIsLoading } = this.props;

        return (
            <Header
                {...this.state}
                {...this.containerProps()}
                {...this.containerFunctions}
                favStoreObj={favStoreObj}
                baseLinkUrl={baseLinkUrl}
                accountIsLoading={accountIsLoading}
                customerRequestDone={customerRequestDone}
                loginIsLoading={loginIsLoading}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
