// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Images/Loader.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--loader-scale: 1}.Loader{background-color:rgba(255,255,255,0.6);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position-x:center;background-position-y:center;background-size:70px 70px;position:absolute;top:0;left:0;right:0;bottom:0;z-index:75;margin:auto;opacity:0;-webkit-animation:appear 1ms 200ms forwards;animation:appear 1ms 200ms forwards}.Loader-Scale{position:absolute;transform:translate(-50%, -50%) scale(var(--loader-scale));left:50%;top:calc(50% - (3px * var(--loader-scale)))}\n", "",{"version":3,"sources":["webpack://src/app/component/Loader/Loader.style.scss"],"names":[],"mappings":"AAAA,MAAM,iBAAiB,CAAC,QAAQ,sCAAsC,CAAC,wDAA2C,CAAC,2BAA2B,CAAC,4BAA4B,CAAC,4BAA4B,CAAC,yBAAyB,CAAC,iBAAiB,CAAC,KAAK,CAAC,MAAM,CAAC,OAAO,CAAC,QAAQ,CAAC,UAAU,CAAC,WAAW,CAAC,SAAS,CAAC,2CAA2C,CAAC,mCAAmC,CAAC,cAAc,iBAAiB,CAAC,0DAA0D,CAAC,QAAQ,CAAC,2CAA2C","sourcesContent":[":root{--loader-scale: 1}.Loader{background-color:rgba(255,255,255,0.6);background-image:url(\"./Images/Loader.gif\");background-repeat:no-repeat;background-position-x:center;background-position-y:center;background-size:70px 70px;position:absolute;top:0;left:0;right:0;bottom:0;z-index:75;margin:auto;opacity:0;-webkit-animation:appear 1ms 200ms forwards;animation:appear 1ms 200ms forwards}.Loader-Scale{position:absolute;transform:translate(-50%, -50%) scale(var(--loader-scale));left:50%;top:calc(50% - (3px * var(--loader-scale)))}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
