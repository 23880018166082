/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { DeviceType } from 'Type/Device';
import { MenuType } from 'Type/Menu';
import { customerType } from 'Type/Account';

import Barcode from 'react-barcode';
import Link from 'Component/Link';
import Popup from 'Component/Popup';
import Image from 'Component/Image';
import cardSauda from 'Route/StoreFinder/images/pecas-sauda-2-79-copy@2x.png';
import exit from 'Component/MyAccountOverlay/Images/icon-exit-to-app@3x.png';

import browserHistory from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import MenuMobileItem from 'Component/MenuMobileItem';
import icon_exit from 'Component/MyAccountOverlay/Images/icon-exit-to-app.svg';
import base_logo from 'Component/MyPharmacy/Images/logo_pharmacy_default.jpg';

import Logo from 'Component/Logo';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

import './MenuMobile.style';

/** @namespace Component/MenuMobile/Component */
export class Menu extends PureComponent {
    static propTypes = {
        menu: MenuType.isRequired,
        activeMenuItemsStack: PropTypes.array.isRequired,
        handleSubcategoryClick: PropTypes.func.isRequired,
        closeMenu: PropTypes.func.isRequired,
        onCategoryHover: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        customer: customerType.isRequired,
        baseLinkUrl: PropTypes.string.isRequired
    };

    static defaultProps = {
        isSelected: false
    };

    renderPopupSaudaCard() {
        const {
            customer: { fullname, sauda_card },
            storeObj: { logo },
            baseLinkUrl,
            showMenuOverlay,
            logo_alt,
            header_logo_src,
            saudaCardPoints
        } = this.props;

        let mediaPath = '/media/';
        let mediaUrl = baseLinkUrl.concat(mediaPath);
        let url = logo ? mediaUrl.concat(logo) : base_logo;

        return (
            <Popup
                id={ 'SaudaCardPopup' }
                clickOutside={ false }
                mix={ { block: 'SaudaCardPopup' } }
                closedOn = { true }
                onVisible={() => {
                    // kepp menu mobile open
                    const $popup = document.querySelector(".Overlay.MenuMobile");
                    if ($popup)
                        $popup.classList.add("Overlay_isVisible");
                }}
                onHide= {() => {
                    // kepp menu mobile open
                    showMenuOverlay();
                }}
            >
                <div block="SaudaCardPopup" elem="PharmacyLogo">
                    <Logo
                        src={ media(header_logo_src, LOGO_MEDIA) }
                        alt={ logo_alt }
                        title={ logo_alt }
                    />
                </div>
                <Image src={ cardSauda } />
                <span class="name">{ saudaCardPoints && saudaCardPoints.name ? saudaCardPoints.name.toLowerCase() : fullname }</span>
                <span class="description">{ __("Present this code at your pharmacy to enjoy all the advantages.") }</span>
                <Barcode value={ sauda_card.card_number } />
            </Popup>
        );
    }

    renderPopupContent() {
        const {
            hideOverlay, handleLogout , activeTab, storeObj, customer: { sauda_card }, showSaudaCardPopup
        } = this.props;
        let elemCss = "menu-listPharmacy";

        if(!Object.entries(storeObj).length){
            elemCss = "menu-list";
        }

        let buttonLabel = __('Enroll Saúda Card');
        let hasCard = sauda_card && sauda_card.card_number != null;
        if (hasCard) {
            buttonLabel = __('Show Saúda Card');
        }

        return (
                    <div
                        block="MenuMobile"
                        elem= { elemCss }
                        >
                        <div block="MenuMobile" elem="Header">
                            <span><Image src = { cardSauda } /></span>
                            {
                                (hasCard) ?
                                    <button
                                        block="MenuMobile"
                                        elem="PharmacyButton"
                                        className="Button"
                                        onClick= { showSaudaCardPopup }
                                    >{ buttonLabel }</button>
                                    :
                                    <Link
                                        to='/my-account/healthcard'
                                        block="MenuMobile"
                                        elem="PharmacyButton"
                                        className="Button"
                                        onClick={ hideOverlay }
                                    >{ buttonLabel }</Link>
                            }
                            { (hasCard)? this.renderPopupSaudaCard() : null }
                        </div>
                        <div
                            block="MenuMobile"
                            elem="link-to">
                            <MenuMobileItem hideOverlay={ hideOverlay } idName={ "health-card" } isSelected={ activeTab === "health-card" }></MenuMobileItem>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div>
                        <div
                            block="MenuMobile"
                            elem="link-to">
                            <MenuMobileItem hideOverlay={ hideOverlay } idName={ "prescription" } isSelected={ activeTab === "prescription" }></MenuMobileItem>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div>
                        <div
                            block="MenuMobile"
                            elem="link-to">
                            <MenuMobileItem hideOverlay={ hideOverlay } idName={ "my-orders" } isSelected={ activeTab === "my-orders" }></MenuMobileItem>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div>
                        <div
                            block="MenuMobile"
                            elem="link-to">
                            <MenuMobileItem hideOverlay={ hideOverlay } idName={ "dashboard" } isSelected={ activeTab === "dashboard" }></MenuMobileItem>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div>
                        <div
                            block="MenuMobile"
                            elem="link-to">
                            <MenuMobileItem hideOverlay={ hideOverlay } idName={ "address-book" } isSelected={ activeTab === "address-book" }></MenuMobileItem>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div>
                        <div
                            block="MenuMobile"
                            elem="link-to">
                            <MenuMobileItem hideOverlay={ hideOverlay } idName={ "pharmacy" } isSelected={ activeTab === "pharmacy" }></MenuMobileItem>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div>
                        {/* <div
                            block="MenuMobile"
                            elem="link-to">
                            <MenuMobileItem hideOverlay={ hideOverlay } idName={ "notification" } isSelected={ activeTab === "notification" }></MenuMobileItem>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div> */}
                        <div
                            block="MenuMobile"
                            elem="link-to" >
                            <MenuMobileItem hideOverlay={ hideOverlay } idName={ "help-and-contacts" } isSelected={ activeTab === "help-and-contacts" }></MenuMobileItem>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div>
                        <div
                            block="MenuMobile"
                            elem="link-to">
                                <Image
                                    src={ icon_exit }
                                    width="20px"
                                    height="20px"
                                    mix={ { block: 'MenuMobile', elem: 'Icon', } }
                                />
                            <span
                                block="MenuMobile"
                                elem="option"
                                onClick={ handleLogout }
                            >
                                    {__('Logout')}
                            </span>
                            <FontAwesomeIcon className="MenuMobile-BackButtonIcon" icon={faChevronRight} />
                        </div>
                    </div>
                );
    }


    render() {

        return (<>
        { this.renderPopupContent()}
        </>
        );
    }
}

export default Menu;
