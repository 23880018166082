import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    CategoryFilterOverlayContainer as SourceCategoryFilterOverlayContainer,
} from 'SourceComponent/CategoryFilterOverlay/CategoryFilterOverlay.container';

import CategoryFilterOverlay from './CategoryFilterOverlay.component';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    favStoreObj: state.StorePageReducer.favStoreObj,
    customer: state.MyAccountReducer.customer,
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class CategoryFilterOverlayContainer extends SourceCategoryFilterOverlayContainer {
    // TODO implement logic
    containerFunctions = {
        onSeeResultsClick: this.onSeeResultsClick.bind(this),
        toggleCustomFilter: this.toggleCustomFilter.bind(this),
        getFilterUrl: this.getCustomFilterUrl.bind(this),
        onVisible: this.onVisible.bind(this),
        onHide: this.onHide.bind(this)
    };

    /**
     * Returns filter array with new parameters
     *
     * @param {String} filterKey key of option
     * @param {String} value
     * @returns {Object[]}
     * @memberof CategoryShoppingOptions
     */
     _getNewFilterArray(filterKey, value) {
        const { customFiltersValues } = this.props;
        const newFilterArray = customFiltersValues[filterKey] !== undefined
            ? Array.from(customFiltersValues[filterKey])
            : [];

        if (filterKey === 'price') { // for price filter, choose one
            return [value];
        }

        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                let filterValueIndex = newFilterArray.indexOf(value[i]);

                if (filterValueIndex === -1) {
                    newFilterArray.push(value[i]);
                } else {
                    newFilterArray.splice(filterValueIndex, 1);
                }
            }
        } else {
            const filterValueIndex = newFilterArray.indexOf(value);

            if (filterValueIndex === -1) {
                newFilterArray.push(value);
            } else {
                newFilterArray.splice(filterValueIndex, 1);
            }
        }

        return newFilterArray;
    }

    _getNewSelectedFiltersString(filterName, filterArray) {
        const prevCustomFilters = this._getSelectedFiltersFromUrl();
        const customFilers = {
            ...prevCustomFilters,
            [filterName]: filterArray
        };

        return Object.entries(customFilers)
            .reduce((accumulator, [filterKey, filterValue]) => {
                if (filterValue.length) {
                    const filterValues = filterValue.join(',');

                    accumulator.push(`${filterKey}:${filterValues}`);
                }

                return accumulator;
            }, [])
            .sort()
            .join(';');
    }

    render() {
        return (
            <CategoryFilterOverlay
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CategoryFilterOverlayContainer)
);
