/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import CheckoutQuery from 'Query/Checkout.query';
import MyAccountQuery from 'Query/MyAccount.query';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateShippingPrice, updateGrandTotal } from 'Store/Cart/Cart.action';
import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';
import { updateEmail, updateShippingFields } from 'Store/Checkout/Checkout.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { customerType } from 'Type/Account';
import { HistoryType } from 'Type/Common';
import { TotalsType } from 'Type/MiniCart';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { debounce, fetchMutation, fetchQuery } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { appendWithStoreCode } from 'Util/Url';
import { showPopup } from 'Store/Popup/Popup.action';
import StorePageQuery from 'Query/StorePage.query';
import { StorePageDispatcher } from '../../store/StorePage';

import Checkout from './Checkout.component';
import {
    BILLING_STEP, DETAILS_STEP, CONFIRM_STEP, PAYMENT_TOTALS, SHIPPING_STEP, UPDATE_EMAIL_CHECK_FREQUENCY
} from './Checkout.config';
import { store } from 'Store/';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
export const CheckoutDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Checkout/Checkout.dispatcher'
);

/** @namespace Route/Checkout/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals,
    customer: state.MyAccountReducer.customer,
    guest_checkout: state.ConfigReducer.guest_checkout,
    countries: state.ConfigReducer.countries,
    isEmailAvailable: state.CheckoutReducer.isEmailAvailable,
    isMobile: state.ConfigReducer.device.isMobile,
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Route/Checkout/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
    ),
    toggleBreadcrumbs: (state) => dispatch(toggleBreadcrumbs(state)),
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showInfoNotification: (message) => dispatch(showNotification('info', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    setNavigationState: (stateName) => dispatch(changeNavigationState(BOTTOM_NAVIGATION_TYPE, stateName)),
    createAccount: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createAccount(options, dispatch)
    ),
    updateShippingFields: (fields) => dispatch(updateShippingFields(fields)),
    updateEmail: (email) => dispatch(updateEmail(email)),
    checkEmailAvailability: (email) => CheckoutDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, email)
    ),
    updateShippingPrice: (data) => dispatch(updateShippingPrice(data)),
    updateGrandTotal: (data) => dispatch(updateGrandTotal(data)),
    showPreferencePopup: (payload) => dispatch(showPopup('PharmacyPreferenceChange', payload)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    showPostalCodePopup: (payload) => dispatch(showPopup('PostalCodeBlock', payload)),
    requestFavStore: (id) => {
        StorePageDispatcher.requestFavStore(dispatch, id);
    }
});

/** @namespace Route/Checkout/Container */
export class CheckoutContainer extends PureComponent {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired,
        showInfoNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        toggleBreadcrumbs: PropTypes.func.isRequired,
        setNavigationState: PropTypes.func.isRequired,
        createAccount: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        resetCart: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired,
        guest_checkout: PropTypes.bool.isRequired,
        totals: TotalsType.isRequired,
        history: HistoryType.isRequired,
        customer: customerType.isRequired,
        countries: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                id: PropTypes.string,
                available_regions: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string,
                        name: PropTypes.string,
                        id: PropTypes.number
                    })
                )
            })
        ).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                step: PropTypes.string
            })
        }).isRequired,
        updateShippingFields: PropTypes.func.isRequired,
        updateEmail: PropTypes.func.isRequired,
        checkEmailAvailability: PropTypes.func.isRequired,
        isEmailAvailable: PropTypes.bool.isRequired,
        updateShippingPrice: PropTypes.func.isRequired
    };

    containerFunctions = {
        setLoading: this.setLoading.bind(this),
        setDetailsStep: this.setDetailsStep.bind(this),
        setConfirmStep: this.setConfirmStep.bind(this),
        savePaymentInformation: this.savePaymentInformation.bind(this),
        saveAddressInformation: this.saveAddressInformation.bind(this),
        onShippingEstimationFieldsChange: this.onShippingEstimationFieldsChange.bind(this),
        onEmailChange: this.onEmailChange.bind(this),
        onCreateUserChange: this.onCreateUserChange.bind(this),
        onPasswordChange: this.onPasswordChange.bind(this),
        goBack: this.goBack.bind(this),
        getAdvanceButtonState: this.getAdvanceButtonState.bind(this),
        confirmPlaceOrder: this.confirmPlaceOrder.bind(this),
        updateCustomerPharmacyPreference: this.updateCustomerPharmacyPreference.bind(this),
        getNotReady: this.getNotReady.bind(this),
        setNotReady: this.setNotReady.bind(this),
        setSelectedPayment: this.setSelectedPayment.bind(this),
        setCustomBillingPhone: this.setCustomBillingPhone.bind(this),
        setCustomBillingNif: this.setCustomBillingNif.bind(this),
        setCustomBillingMbway: this.setCustomBillingMbway.bind(this),
        changeFreeDelivery: this.changeFreeDelivery.bind(this),
        setSelectedShipping: this.setSelectedShipping.bind(this),
        setAddressTmp: this.setAddressTmp.bind(this),
        setAddressTmpCreating: this.setAddressTmpCreating.bind(this),
        getAddressTmp: this.getAddressTmp.bind(this),
        setAddressBillingTmpCreating: this.setAddressBillingTmpCreating.bind(this),
        setAddressBillingNotStored: this.setAddressBillingNotStored.bind(this),
        setCheckoutEditAddress: this.setCheckoutEditAddress.bind(this)
    };

    setCustomBillingMbway(value) {
        this.setState({ customBillingMbway: value });
    }

    setSelectedPayment(value) {
        this.setState({ seletedPaymentCustom: value });
    }

    setSelectedShipping(method) {
        const { notReady, selectedPaymentMethod, isLoading } = this.state;
        if(method != selectedPaymentMethod && notReady && !isLoading) {
            this.setState({ notReady: false });
        }
        this.setState({ selectedPaymentMethod: method });
    }

    setCustomBillingPhone(value) {
        this.setState({ customBillingPhone: value });
    }

    setCustomBillingNif(value) {
        this.setState({ customBillingNif: value });
    }

    getNotReady() {
        return this.state.notReady;
    }

    setNotReady(value) {
        this.setState({ notReady: value });
    }

    checkEmailAvailability = debounce((email) => {
        const { checkEmailAvailability } = this.props;
        checkEmailAvailability(email);
    }, UPDATE_EMAIL_CHECK_FREQUENCY);

    changeFreeDelivery(value) {
        this.setState({freeDelivery: value});
    }

    setAddressTmp(address){
        this.setState({addressTmp: address});
    }

    getAddressTmp(){
        return this.state.addressTmp;
    }

    setAddressTmpCreating(value){
        this.setState({ addressTmpCreating: value});
    }

    setAddressBillingTmpCreating(value){
        this.setState({ addressBillingTmpCreating: value});
    }

    setAddressBillingNotStored(value){
        this.setState({ addressBillingNotStored: value});
    }

    __construct(props) {
        super.__construct(props);

        const {
            toggleBreadcrumbs,
            totals: {
                is_virtual
            }
        } = props;

        toggleBreadcrumbs(false);

        this.state = {
            isLoading: is_virtual,
            isDeliveryOptionsLoading: false,
            requestsSent: 0,
            paymentMethods: [],
            shippingMethods: [],
            shippingAddress: {},
            billingAddress: {},
            paymentMethod: {},
            shippingMethod: {},
            checkoutStep: is_virtual ? BILLING_STEP : SHIPPING_STEP,
            orderID: '',
            mbData: {},
            paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
            email: '',
            isGuestEmailSaved: false,
            isCreateUser: false,
            notReady : false,
            seletedPaymentCustom: '',
            customBillingPhone: '',
            customBillingNif: '',
            customBillingMbway: '',
            isWallet: false,
            freeDelivery: false,
            selectedPaymentMethod: {},
            addressTmp: {
                firstname: ''
            },
            addressTmpCreating: false,
            addressBillingTmpCreating: false,
            addressBillingNotStored: {
                firstname: ''
            },
            editAddress: false,
            zipCodeValid: true
        };

        if (is_virtual) {
            this._getPaymentMethods();
        }
    }

    componentDidMount() {
        const {
            history,
            showInfoNotification,
            guest_checkout,
            updateMeta,
            totals: {
                items = []
            },
            totals,
            customer: { favourite_pharmacy_code },
            requestFavStore
        } = this.props;

        if(favourite_pharmacy_code !== undefined){
            requestFavStore(favourite_pharmacy_code);
        }


        if (!items.length) {
            showInfoNotification(__('Please add at least one product to cart!'));
            history.push(appendWithStoreCode('/cart'));
        }

        // if guest checkout is disabled and user is not logged in => throw him to homepage
        if (!localStorage.getItem('guest_pharmacy_code')) {
            if (!guest_checkout && !isSignedIn()) {
                history.push(appendWithStoreCode('/'));
            }
        }


        if(totals && totals.is_wallet){
            this.setState({ isWallet: true });
        }

        updateMeta({ title: __('Checkout') });
    }

    componentDidUpdate(prevProps, prevState) {
        const { match: { params: { step: urlStep } }, isEmailAvailable, updateEmail } = this.props;
        const { match: { params: { step: prevUrlStep } } } = prevProps;
        const { email } = this.state;
        const { email: prevEmail } = prevState;

        // Handle going back from billing to shipping
        if (/shipping/.test(urlStep) && (/billing/.test(prevUrlStep) || /confirm/.test(prevUrlStep))) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                checkoutStep: SHIPPING_STEP,
                isGuestEmailSaved: false
            });
        }


        if (/billing/.test(urlStep) && /confirm/.test(prevUrlStep)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                checkoutStep: BILLING_STEP,
                isGuestEmailSaved: false
            });
        }

        if (email !== prevEmail) {
            this.checkEmailAvailability(email);
        }

        if (!isEmailAvailable) {
            updateEmail(email);
        }

        return null;
    }

    componentWillUnmount() {
        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(true);
    }

    updateCustomerPharmacyPreference(pharmacy_code) {
        const { requestCustomerData, showSuccessNotification, showErrorNotification, showPreferencePopup } = this.props;
        const query = StorePageQuery.getUpdateCustomerPharmacyPreference(pharmacy_code);
        fetchMutation(query).then(
            () => {
                showPreferencePopup();
                requestCustomerData();
                showSuccessNotification(__('This pharmacy is now your pharmacy preference.'));
            },
            (error) => {
                showPreferencePopup();
                showErrorNotification(__('Something went wrong.'));
            }
        );
    }

    getAdvanceButtonState() {
        return false;
    }

    onEmailChange(email) {
        this.setState({ email });
    }

    onCreateUserChange() {
        const { isCreateUser } = this.state;
        this.setState({ isCreateUser: !isCreateUser });
    }

    onPasswordChange(password) {
        this.setState({ password });
    }

    setCheckoutEditAddress(value){
        this.setState({editAddress: value});
    }

    onShippingEstimationFieldsChange(address) {
        const { requestsSent } = this.state;

        this.setState({
            isDeliveryOptionsLoading: true,
            requestsSent: requestsSent + 1
        });

        fetchMutation(CheckoutQuery.getEstimateShippingCosts(
            address,
            this._getGuestCartId()
        )).then(
            /** @namespace Route/Checkout/Container/onShippingEstimationFieldsChangeFetchMutationThen */
            ({ estimateShippingCosts: shippingMethods }) => {
                const { requestsSent } = this.state;

                this.setState({
                    shippingMethods,
                    isDeliveryOptionsLoading: requestsSent > 1,
                    requestsSent: requestsSent - 1
                });
            },
            this._handleError
        );
    }

    goBack() {
        const { checkoutStep } = this.state;

        if (checkoutStep === BILLING_STEP) {
            this.setState({
                isLoading: false,
                checkoutStep: SHIPPING_STEP
            });

            BrowserDatabase.deleteItem(PAYMENT_TOTALS);
        }

        history.goBack();
    }

    setDetailsStep(orderID, mbData) {
        const { resetCart, resetGuestCart, setNavigationState } = this.props;

        // For some reason not logged in user cart preserves qty in it
        if (!isSignedIn()) {
            BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
        }

        BrowserDatabase.deleteItem(PAYMENT_TOTALS);

        // For guest we can just update cart without creating new quote id
        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }

        this.setState({
            isLoading: false,
            paymentTotals: {},
            checkoutStep: DETAILS_STEP,
            orderID,
            mbData
        });

        setNavigationState({
            name: CART_TAB
        });
    }

    setConfirmStep(orderID) {
        const { resetCart, resetGuestCart, setNavigationState } = this.props;


        this.setState({
            isLoading: false,
            checkoutStep: CONFIRM_STEP,
            orderID
        });

        /*setNavigationState({
            name: CART_TAB
        });*/
    }

    setLoading(isLoading = true) {
        this.setState({ isLoading });
    }

    setShippingAddress = async () => {
        const { shippingAddress } = this.state;
        const { region, region_id, ...address } = shippingAddress;

        const mutation = MyAccountQuery.getCreateAddressMutation({
            ...address, region: { region, region_id }
        });

        await fetchMutation(mutation);

        return true;
    };

    containerProps = () => {
        const { paymentTotals } = this.state;

        return {
            checkoutTotals: this._getCheckoutTotals(),
            paymentTotals
        };
    };

    _handleError = (error) => {
        const { showErrorNotification } = this.props;
        const [{ message, debugMessage }] = error;

        this.setState({
            isDeliveryOptionsLoading: false,
            isLoading: false
        }, () => {
            showErrorNotification(debugMessage || message);
        });

        return false;
    };

    /*_getGuestCartId = () => {

        let stored = BrowserDatabase.getItem(GUEST_QUOTE_ID);

        if (stored) {
            fetchQuery(CheckoutQuery.checkQuoteExists(
                stored
            )).then(
                /** @namespace Route/Checkout/Container/fetchQueryThen
                (response) => {
                    if(!response.checkGuestQuoteMaskExists){
                        BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
                    }
                },
                this._handleError
            );
        }

        return BrowserDatabase.getItem(GUEST_QUOTE_ID);
    }*/


    _getGuestCartId = () => BrowserDatabase.getItem(GUEST_QUOTE_ID);


    _getPaymentMethods() {
        fetchQuery(CheckoutQuery.getPaymentMethodsQuery(
            this._getGuestCartId()
        )).then(
            /** @namespace Route/Checkout/Container/fetchQueryThen */
            ({ getPaymentMethods: paymentMethods }) => {
                this.setState({ isLoading: false, paymentMethods });
            },
            this._handleError
        );
    }

    _getCheckoutTotals() {
        const { totals: cartTotals } = this.props;
        const { paymentTotals: { shipping_amount } } = this.state;

        return shipping_amount
            ? { ...cartTotals, shipping_amount }
            : cartTotals;
    }

    saveGuestEmail() {
        const { email } = this.state;
        const { updateEmail } = this.props;
        const guestCartId = BrowserDatabase.getItem(GUEST_QUOTE_ID);
        const mutation = CheckoutQuery.getSaveGuestEmailMutation(email, guestCartId);

        updateEmail(email);
        return fetchMutation(mutation).then(
            /** @namespace Route/Checkout/Container/saveGuestEmailFetchMutationThen */
            ({ setGuestEmailOnCart: data }) => {
                if (data) {
                    this.setState({ isGuestEmailSaved: true });
                }

                return data;
            },
            this._handleError
        );
    }

    async createUserOrSaveGuest() {
        const {
            createAccount,
            totals: { is_virtual },
            showSuccessNotification,
            isEmailAvailable
        } = this.props;

        const {
            email,
            password,
            isCreateUser,
            shippingAddress: {
                firstname,
                lastname
            }
        } = this.state;

        if (!isCreateUser || !isEmailAvailable) {
            return this.saveGuestEmail();
        }

        const options = {
            customer: {
                email,
                firstname,
                lastname
            },
            password
        };

        const creation = await createAccount(options);

        if (!creation) {
            return creation;
        }

        showSuccessNotification(__('Your account has been created successfully!'));

        if (!is_virtual) {
            return this.setShippingAddress();
        }

        return true;
    }

    prepareAddressInformation(addressInformation) {
        const {
            shipping_address: {
                save_in_address_book,
                ...shippingAddress
            } = {},
            billing_address: {
                save_in_address_book: x,
                ...billingAddress
            } = {},
            ...data
        } = addressInformation;

        return {
            ...data,
            shipping_address: shippingAddress,
            billing_address: billingAddress
        };
    }

    async saveAddressInformation(addressInformation) {
        const { updateShippingPrice, updateGrandTotal } = this.props;
        const { shipping_address, shipping_carrier_code, shipping_method_code, billing_address } = addressInformation;
        const { editAddress } = this.state;

        this.setState({
            isLoading: true,
            shippingAddress: shipping_address,
            shippingMethod: {
                shipping_carrier_code: shipping_carrier_code,
                shipping_method_code: shipping_method_code
            }
        });

        if(editAddress){
            this.setState({
                isLoading: false,
                editAddress: false
            });
            return;
        }

        if (!isSignedIn()) {
            if (!await this.createUserOrSaveGuest()) {
                this.setState({ isLoading: false });
                return;
            }
        }

        fetchMutation(CheckoutQuery.getSaveAddressInformation(
            this.prepareAddressInformation(addressInformation),
            this._getGuestCartId()
        )).then(
            /** @namespace Route/Checkout/Container/saveAddressInformationFetchMutationThen */
            ({ saveAddressInformation: data }) => {
                const { payment_methods, totals } = data;

                updateShippingPrice(totals);
                updateGrandTotal(totals);

                BrowserDatabase.setItem(
                    totals,
                    PAYMENT_TOTALS,
                    ONE_MONTH_IN_SECONDS
                );

                this.setState({
                    isLoading: false,
                    paymentMethods: payment_methods,
                    checkoutStep: BILLING_STEP,
                    paymentTotals: totals,
                    zipCodeValid: true
                });
            },
            (error) => {
                if(error[0].message == 'zip_code') {
                    const { showErrorNotification, showPostalCodePopup } = this.props;
                    this.setState({
                        isDeliveryOptionsLoading: false,
                        isLoading: false
                    }, () => {
                        //showErrorNotification(__('Your zip code is outside the delivery zone of the selected pharmacy.'));
                        showPostalCodePopup();
                        this.setState({zipCodeValid: false});
                        return false;
                    });
                } else {
                    this._handleError(error)
                }
            }
        );
    }

    async savePaymentInformation(paymentInformation) {
        const { totals: { is_virtual } } = this.props;
        const {
            billing_address,
            paymentMethod,
            mbway_phone
        } = paymentInformation;
        const {
            firstname: billingFirstName,
            lastname: billingLastName
        } = billing_address;

        this.setState({
            billingAddress: billing_address,
            paymentMethod: paymentMethod
        });

        /**
         * If cart contains only virtual products then set firstname & lastname
         * from billing step into shippingAddress for user creating.
         */
        if (is_virtual) {
            this.setState({
                shippingAddress: {
                    firstname: billingFirstName,
                    lastname: billingLastName
                }
            });
        }

        this.setState({ isLoading: true });

        if (!isSignedIn()) {
            if (!await this.createUserOrSaveGuest()) {
                this.setState({ isLoading: false });
                return;
            }
        }

        await this.saveBillingAddress(paymentInformation).then(
            /** @namespace Route/Checkout/Container/saveBillingAddressThen */
            () => {
                this.savePaymentMethodAndPlaceOrder(paymentInformation);
            },
            this._handleError
        );
    }

    async savePaymentBillingInformation(paymentInformation) {
        const { totals: { is_virtual } } = this.props;
        const {
            billing_address: {
                firstname: billingFirstName,
                lastname: billingLastName
            }
        } = paymentInformation;

        /**
         * If cart contains only virtual products then set firstname & lastname
         * from billing step into shippingAddress for user creating.
         */
        if (is_virtual) {
            this.setState({
                shippingAddress: {
                    firstname: billingFirstName,
                    lastname: billingLastName
                }
            });
        }

        this.setState({ isLoading: true });

        if (!isSignedIn()) {
            if (!await this.createUserOrSaveGuest()) {
                this.setState({ isLoading: false });
                return;
            }
        }

        await this.saveBillingAddress(paymentInformation).then(
            /** @namespace Route/Checkout/Container/saveBillingAddressThen */
            () => this.savePaymentMethodAndPlaceOrder(paymentInformation),
            this._handleError
        );
    }

    trimAddressMagentoStyle(address) {
        const { countries } = this.props;

        const {
            country_id,
            region_code, // drop this
            region_id,
            region,
            ...restOfBillingAddress
        } = address;

        const newAddress = {
            ...restOfBillingAddress,
            country_code: country_id,
            region
        };

        /**
         * If there is no region specified, but there is region ID
         * get the region code by the country ID
         */
        if (region_id) {
            // find a country by country ID
            const { available_regions } = countries.find(
                ({ id }) => id === country_id
            ) || {};

            if (!available_regions) {
                return newAddress;
            }

            // find region by region ID
            const { code } = available_regions.find(
                ({ id }) => +id === +region_id
            ) || {};

            if (!code) {
                return newAddress;
            }

            newAddress.region = code;
        }

        return newAddress;
    }

    async saveBillingAddress(paymentInformation) {
        const guest_cart_id = !isSignedIn() ? this._getGuestCartId() : '';
        const { billing_address } = paymentInformation;

        await fetchMutation(CheckoutQuery.getSetBillingAddressOnCart({
            guest_cart_id,
            billing_address: {
                address: this.trimAddressMagentoStyle(billing_address)
            }
        }));
    }

    async savePaymentMethodAndPlaceOrder(paymentInformation) {
        const { paymentMethod: { code, additional_data }, mbway_phone } = paymentInformation;
        const guest_cart_id = !isSignedIn() ? this._getGuestCartId() : '';

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    [code]: additional_data
                }
            }));

            if(code == 'mbway') {
                const query = CheckoutQuery.updateQuoteMbwayPhone(mbway_phone);
                fetchQuery(query).then(
                    (result) => {
                        //console.log('result', result);
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            }

            /** Move Place order to confirm step */
            //const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            //const { placeOrder: { order: { order_id } } } = orderData;
            //this.setDetailsStep(order_id);

            this.setConfirmStep();
        } catch (e) {
            this._handleError(e);
        }
    }

    async confirmPlaceOrder() {
        const guest_cart_id = !isSignedIn() ? this._getGuestCartId() : '';
        const { history } = this.props;

        try {
            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            const { placeOrder: { order: { order_id, cc_redirect, mb_entity, mb_reference, mb_amount, mb_begin_date, mb_end_date } } } = orderData;
            const mbData = {
                mb_entity: mb_entity,
                mb_reference: mb_reference,
                mb_amount: mb_amount,
                mb_begin_date: mb_begin_date,
                mb_end_date: mb_end_date
            };
            this.setDetailsStep(order_id, mbData);
            if (cc_redirect) {
                const a = document.createElement('a');
                a.href = cc_redirect;
                a.target = '_blank';
                localStorage.setItem("payment_link",cc_redirect);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        } catch (e) {
            this._handleError(e);
        }
    }

    render() {
        return (
            <Checkout
            { ...this.props }
            { ...this.state }
            { ...this.containerFunctions }
            { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer);
