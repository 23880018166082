import {
    FieldSelectContainer as SourceFieldSelectContainer,
} from 'SourceComponent/FieldSelect/FieldSelect.container';

export class FieldSelectContainer extends SourceFieldSelectContainer {
    removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }

    sortSelectOptions() {
        const { selectOptions, isStoreFinder, showPlaceholder } = this.props;
        let isNumber = false;

        for (let i = 0; i < selectOptions.length; i++) {
            if(Number.isInteger(selectOptions[i].label)) {
                isNumber = true;
            }else{
                isNumber = false;
                break;
            }
        }

        /**
         * Trim all null label values, sort alphabetically
         */

        if(!isNumber) {
            const sortedOptions = selectOptions.reduce(
                (acc, a) => (a.label ? [...acc, a] : acc), []
            ).sort((a, b) => {
                const textA = this.removeAccents(a.label.toString().toUpperCase());
                const textB = this.removeAccents(b.label.toString().toUpperCase());
                // eslint-disable-next-line no-nested-ternary
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            if (isStoreFinder) {
                sortedOptions.unshift({ id: __('Select'), value: '', label: __('Select') });
            }

            if (showPlaceholder) {
                sortedOptions.unshift({ id: __('Choose Option'), value: '', label: __('Choose Option') });
            }

            return sortedOptions;
        } else {
            return selectOptions;
        }
    }
};

export default FieldSelectContainer;
