import {
    ProductTab as SourceProductTab,
} from 'SourceComponent/ProductTab/ProductTab.component';
import arrowdown from 'Route/StorePage/Images/arrowdown.png';
import arrowUp from 'Route/StorePage/Images/arrowUp.png';
import Image from 'Component/Image';

import './ProductTab.style.override'

export class ProductTab extends SourceProductTab {

    state = {
        isActive: false
    }

    onTabClick = (activeTab) => {
        this.setState({
            isActive: !this.state.isActive
        });
    };

    render() {
        const { tabName, product, renderActive, children } = this.props;
        const { isActive } = this.state;

        return (
            <>
            <li
              block="ProductTab"
              elem="Item"
              mods={ { isActive } }
            >
                <button
                  mix={ { block: 'ProductTab', elem: 'Button' } }
                  onClick={ this.onTabClick }
                >
                    <h3>{ tabName }</h3>
                </button>
                    { !isActive &&
                        <span
                            block="ProductTab"
                            elem="Arrow"
                            onClick={ this.onTabClick }
                        >
                            <Image
                                src={ arrowdown }
                                width="20px"
                                height="20px"
                            />
                        </span>
                    }
                    { isActive &&
                        <span
                            block="ProductTab"
                            elem="Arrow"
                            onClick={ this.onTabClick }
                        >
                            <Image
                                src={ arrowUp }
                                width="20px"
                                height="20px"
                            />
                        </span>
                    }
            </li>
            { isActive ? renderActive(tabName, children) : null }
            </>
        );
    }
};

export default ProductTab;