import {
    ProductList as SourceProductList,
} from 'SourceComponent/ProductList/ProductList.component';


import CategoryPagination from 'Component/CategoryPagination';
import CmsBlock from 'Component/CmsBlock';

import './ProductList.style.override'

export class ProductList extends SourceProductList {

    componentDidUpdate(prevProps) {
        const { isWidget, currentPage, device } = this.props;
        const { currentPage: prevCurrentPage } = prevProps;

        let mobileContainer = null;

        // this is so it doesn't break on the search results pages
        // since apparently it used a different class for the filters
        if (device.isMobile) {
           mobileContainer =
               document.getElementsByClassName('CategoryPage-MiscellaneousMobileSortFilter')[0] ?
                   document.getElementsByClassName('CategoryPage-MiscellaneousMobileSortFilter')[0] :
                   document.getElementsByClassName('CategoryPage-MiscellaneousMobile')[0];
        }
        const container = device.isMobile ? mobileContainer : document.getElementsByClassName('Breadcrumbs-Blog')[0];
        // Scroll up on page change, ignore widgets
        if (prevCurrentPage !== currentPage && !isWidget) {
            container.scrollIntoView(0);

        }

        const { isInfiniteLoaderEnabled } = this.props;

        if (isInfiniteLoaderEnabled) {
            this.observePageChange();
        }
    }

    renderNoProducts() {
        return (
            /*<div block="ProductList">
                <div
                  block="ProductList"
                  elem="ProductsMissing"
                >
                    <h2>{ __('Sorry!') }</h2>
                    <h3>{ __('There were no products found matching your request.') }</h3>
                    <p>{ __('Please, check your spelling and search again, or try another term.') }</p>
                </div>
            </div>*/
            <div block="ProductList">
                <div
                  block="ProductList"
                  elem="ProductsMissing"
                >
                    <CmsBlock identifier={ "search_no_results" } />
                </div>
            </div>
        );
    }

    renderPagination() {
        const {
            isLoading,
            totalPages,
            requestPage,
            isPaginationEnabled
        } = this.props;

        if (!isPaginationEnabled) {
            return null;
        }

        return (
            <CategoryPagination
              key={ Date.now() }
              isLoading={ isLoading }
              totalPages={ totalPages }
              onPageSelect={ requestPage }
            />
        );
    }

    // TODO implement logic
    render() {
        const {
            totalPages,
            isLoading,
            mix
        } = this.props;

        if (!isLoading && totalPages === 0) {
            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
            >
                { this.renderTitle() }
                { this.renderLoadButton() }
                { this.renderPages() }
                { this.renderPagination() }
            </div>
        );
    }
};

export default ProductList;
