import { connect } from 'react-redux';

import {
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
    SearchItemContainer as SourceSearchItemContainer,
} from 'SourceComponent/SearchItem/SearchItem.container';

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch)
});

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state)
});

export class SearchItemContainer extends SourceSearchItemContainer {

    handleItemClick() {
        const { hideActiveOverlay, closeSearch, clearSearch } = this.props;

        hideActiveOverlay();
        closeSearch();
        clearSearch();
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemContainer);
