//import 'MyAccountPageManager.component.style.override'

import PropTypes from 'prop-types';
import { Component, PureComponent } from 'react';

import Loader from 'SourceComponent/Loader';
import MyAccountCustomerForm from 'Component/MyAccountCustomerForm';
import MyAccountPasswordForm from 'Component/MyAccountPasswordForm';
import MyAccountPrescriptionForm from 'Component/MyAccountPrescriptionForm';
import MyAccountAddressForm from 'Component/MyAccountAddressForm';
import MyAccountCoPaymentsForm from 'Component/MyAccountCoPaymentsForm';
import { addressType } from 'Type/Account';

import {
    ADD_ADDRESS, DELETE_ADDRESS, EDIT_ADDRESS
} from './MyAccountPageManager.config';
import HealthCardForm from 'Component/HealthCardForm';
import HealthCardWithoutLoginForm from 'Component/HealthCardWithoutLoginForm';
import MyAccountPrescriptionDetails from 'Component/MyAccountPrescriptionDetails';

export class MyAccountPageManager extends PureComponent {
    // TODO implement logic
    static propTypes = {
        showHideForm: PropTypes.bool.isRequired,
        showEditForm: PropTypes.func.isRequired,
        onCustomerSave: PropTypes.func.isRequired,
        onPasswordChange: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        handleClick: PropTypes.func.isRequired,
        handleAddress: PropTypes.func.isRequired,
        handleDeleteAddress: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            action: PropTypes.oneOf([
                EDIT_ADDRESS,
                DELETE_ADDRESS,
                ADD_ADDRESS
            ]),
            address: addressType
        }).isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        onPrescriptionAddSuccess: PropTypes.func.isRequired
    };

    renderHealthCard() {
        const { customer, showEditForm, handleHealthCard, baseLinkUrl, showInfoPopup, phoneNumber, isSignedIn } = this.props;
        if(isSignedIn){
            const str = '/aderir-sauda*/g';
            if(window.location.href.search(str) > 0){
                window.history.replaceState(null, null, "aderir-sauda-s3");
            }
            return (
                <HealthCardForm
                    customer={ customer }
                    handleHealthCard={ handleHealthCard }
                    showEditForm={ showEditForm }
                    baseLinkUrl={ baseLinkUrl }
                    showPopup={ showInfoPopup }
                    phoneNumber={ phoneNumber }
                />
            );
        }else{
            return (
                <HealthCardWithoutLoginForm
                    customer={ customer }
                    handleHealthCard={ handleHealthCard }
                    showEditForm={ showEditForm }
                    baseLinkUrl={ baseLinkUrl }
                    showPopup={ showInfoPopup }
                    phoneNumber={ phoneNumber }
                />
            );
        }
    }

    renderChangePasswordForm() {
        const { customer, onPasswordChange, showEditForm } = this.props;

        return (
            <MyAccountPasswordForm
                customer={ customer }
                onPasswordChange={ onPasswordChange }
                showEditForm={ showEditForm }
            />
        );
    }

    renderPrescriptionDetails() {
        const { customer, onInputChange , showEditForm, prescriptionSelected } = this.props;

        return (
            <MyAccountPrescriptionDetails
                prescription={ prescriptionSelected }
                onInputChange={ onInputChange }
                showEditForm={ showEditForm }
            />
        );
    }

    renderPrescriptionForm() {
        const { onInfoPopup, onAccessPopup, onOptionPopup, showEditForm, onInputChange,device, showErrorNotification, showSuccessNotification, onPrescriptionAddSuccess } = this.props;

        return (
            <MyAccountPrescriptionForm
                device={ device }
                onInfoPopup={ onInfoPopup }
                onAccessPopup={ onAccessPopup }
                onOptionPopup={ onOptionPopup }
                showEditForm={ showEditForm }
                showErrorNotification={ showErrorNotification }
                showSuccessNotification={ showSuccessNotification }
                onPrescriptionAddSuccess={ onPrescriptionAddSuccess }
            />
        );
    }

    renderAddressForm() {
        const { address, handleAddress, showEditForm } = this.props;

        return (
            <MyAccountAddressForm
                address={ address }
                onSave={ handleAddress }
                showEditForm={ showEditForm }
                isMyAccount={ true }
            />
        );
    }

    renderCustomerForm() {
        const {  customer, onCustomerSave, toggleEditForm} = this.props;
        return (
            <>
                <MyAccountCustomerForm
                    customer={ customer }
                    onSave={ onCustomerSave }
                    toggleEditForm={ toggleEditForm }
                />
            </>
        );
    }

    renderCoPaymentsForm() {
        const { customer, showEditForm, handleCoPayments, currentCoPayment } = this.props;
        return (
            <MyAccountCoPaymentsForm
                customer={ customer }
                handleCoPayments={ handleCoPayments }
                showEditForm={ showEditForm }
                currentCoPayment={ currentCoPayment }
            />
      );
    }

    renderContent() {
        const { FormTitle }  = this.props;

        switch (FormTitle) {
        case "Password":
            return this.renderChangePasswordForm();
        case "Customer":
            return this.renderCustomerForm();
        case "Address":
            return this.renderAddressForm();
        case "CoPayments":
            return this.renderCoPaymentsForm();
        case "HealthCard":
            return this.renderHealthCard();
        case "Prescription":
            return this.renderPrescriptionForm();
        case "PrescriptionDetails":
            return this.renderPrescriptionDetails();
        default:
            return null;
        }
    }

    render() {
        const { isLoading } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                {  this.renderContent() }
            </>
        );
    }
};

export default MyAccountPageManager;
