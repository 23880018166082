import {
    AddToCart as SourceAddToCart,
} from 'SourceComponent/AddToCart/AddToCart.component';
import iconCheckout from '../ProductActions/images/icon-car-market@3x.png';
import './AddToCart.style.override'

export class AddToCart extends SourceAddToCart {
    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick,
            hasStock,
            isGeneric,
            product
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        let stockFlag = hasStock;

        if(isGeneric){
            for(let i=0; i<product.custom_attributes.length; i++){
                if(product.custom_attributes[i].attribute_code == 'custom_stock'){
                    if(product.custom_attributes[i].attribute_values[0] == 'Com Stock'){
                        stockFlag = true;
                    }
                }
            }
        }

        return (
            <button
              onClick={ buttonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { isLoading } }
              disabled={ isLoading ? isLoading : !stockFlag }
            >
                <span>{ __('Add') }</span>
                <img
                        block="ProductCheckoutButton"
                        src={ iconCheckout || '' }
                    />
                <span>{ __('Adding...') }</span>
            </button>
        );
    }
};

export default AddToCart;
