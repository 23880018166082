import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';
import CartQuery from 'Query/Cart.query';
import { CheckoutSaudaDispatcher } from '../../store/CheckoutSauda';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);

import {
    CartCouponContainer as SourceCartCouponContainer,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
} from 'SourceComponent/CartCoupon/CartCoupon.container';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    totals: state.CartReducer.cartTotals
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    showErrorNotification: (error) => dispatch(showNotification('error', error[0].message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    _syncCartWithBE: () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher._syncCartWithBE(dispatch)
        )
    },
    requestSaudaPoints: () => {
        CheckoutSaudaDispatcher.getSaudaCardPoints(dispatch);
    }
});

export class CartCouponContainer extends SourceCartCouponContainer {

    containerFunctions = {
        handleApplyCouponToCart: this.handleApplyCouponToCart.bind(this),
        handleRemoveCouponFromCart: this.handleRemoveCouponFromCart.bind(this),
        deleteCampaign: this.deleteCampaign.bind(this)
    };

    handleApplyCouponToCart(couponCode) {
        const { applyCouponToCart, requestSaudaPoints } = this.props;

        this.setState({ isLoading: true });

        applyCouponToCart(couponCode).then(
            /** @namespace Component/CartCoupon/Container/applyCouponToCartThen */
            () => {
                requestSaudaPoints();
                this.setState({ isLoading: false });
            }
        );
    }

    deleteCampaign(campaign) {
        const { showErrorNotification, showSuccessNotification, _syncCartWithBE, requestSaudaPoints } = this.props;
        this.setState({
            isLoading: true
        });
        const mutation = CartQuery.getQuoteCampaignsDeleteMutation(campaign);
        fetchMutation(mutation).then(
            ({ quoteCampaignsDelete }) => {
                this.setState({ isLoading: false }, () => {
                    if(quoteCampaignsDelete.status && quoteCampaignsDelete.status == '200') {
                        showSuccessNotification(quoteCampaignsDelete.message);
                        requestSaudaPoints();
                    } else {
                        showErrorNotification('error', [{message: quoteCampaignsDelete.message}]);
                    }
                    _syncCartWithBE();
                });
            },
            (error) => {
                this.setState({
                    isLoading: false
                });
                showErrorNotification('error', [{message: __('Something went wrong.')}]);
            }
        );
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(CartCouponContainer);