import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './MyAccountMyOrdersDetails.style';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import { orderType } from 'Type/Account';
import { roundPrice, formatPrice } from 'Util/Price';
import CheckoutOrderSummary from 'Component/CheckoutOrderSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTruckMoving, faTruckCouch, faReceipt } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import iconMbWay from 'Component/MyAccountMyOrdersDetails/Images/mb-way@2x.png';
import iconMb from 'Component/MyAccountMyOrdersDetails/Images/mb@2x.png';
import iconPagamento from 'Component/MyAccountMyOrdersDetails/Images/pagamento@2x.png';
import iconTruck from 'Component/MyAccountMyOrdersDetails/Images/truck@2x.png';
import iconVisaLogo from 'Component/MyAccountMyOrdersDetails/Images/visa-logo@2x.png';
import iconPosPago from 'Component/MyAccountMyOrdersDetails/Images/Group745@2x.png';
import  icon_sauda_product  from 'Component/CartItem/images/sauda.png';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import Tooltip from 'Component/Tooltip';
import CmsBlock from 'Component/CmsBlock';

class MyAccountMyOrdersDetails extends PureComponent {

    static propTypes = {
        order: orderType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        currency_code: PropTypes.string.isRequired,
        requestOrderDetails: PropTypes.func.isRequired,
        clickRebuy: PropTypes.func.isRequired
    };

    state = {
        prevOrderId: 0,
        isProductBuyWithPoints: false,
        isProductCapacity: false,
    }

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    renderBaseInfo() {
        const { clickRebuy, order: { base_order_info, is_wallet }, currency_code } = this.props;
        const { status_label, created_at, grand_total, increment_id, pharmacy_name } = base_order_info || {};

        let date = '';
        let dateStr = '';
        if (created_at) {
            date = new Date(created_at.replace(' ', 'T'));
            dateStr = date.toLocaleString('pt-PT', { timeZone: 'Europe/Lisbon' }).replaceAll(':', 'h').replaceAll(',', '').slice(0, -3);
            /*dateStr = this.addZero(date.getDate()) + '-' + this.addZero(date.getMonth() + 1) + '-' + date.getFullYear()
            + ' ' + date.getHours() + 'h' + date.getMinutes();*/
        }

        let walletInfo = '';
        if (is_wallet) {
            walletInfo = <Tooltip message={ <CmsBlock identifier={ "order_detail_wallet_notice" } /> } position={ 'bottom' }>
                <FontAwesomeIcon className="MyAccountOrderTableRow-AlertIcon" icon={ faExclamationCircle } />
            </Tooltip>
        }

        return (
            <div block="MyAccountMyOrdersDetails" elem="BaseInfo">
                <div block="MyAccountMyOrdersDetails" elem="Pharmacy">
                    <label>{ __("Bought in ") }<b>{ pharmacy_name }</b></label>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="Content">
                    <div block="MyAccountMyOrdersDetails" elem="ContLeft">
                        <div>
                            <label>{ __('Purchase date') }</label>
                            <label block="MyAccountMyOrdersDetails" elem="DataBase">{ dateStr }</label>
                        </div>
                        <div>
                            <label>{ __('Value') }</label>
                            <label block="MyAccountMyOrdersDetails" elem="DataBase">
                                { walletInfo }
                                { grand_total ? formatPrice( roundPrice(grand_total), currency_code ) : 0 }
                            </label>
                        </div>
                        <div>
                            <label>{ __('State') }</label>
                            <label block="MyAccountMyOrdersDetails" elem="DataBase">{ status_label }</label>
                        </div>
                    </div>
                    <button block="MyAccountMyOrdersDetails" elem="Button" onClick={ () => clickRebuy(increment_id) } mix={ { block: 'Button' } }>
                        { __('Rebuy') }
                    </button>
                </div>
            </div>
        );
    }

    renderPayment() {
        const { order: { payment_info, shipping_info } } = this.props;
        const { method, additional_information: { method_title } = {}  } = payment_info || {};
        const { mbway_phone, mb_end_date, mb_entity, mb_reference } = shipping_info || {};
        let imgDeliveryMethod = '';
        let labelDeliveryMethod = '';

        switch (method) {
            case "checkmo":
                imgDeliveryMethod =
                    <Image
                        src = {iconPosPago}
                        height="25px"
                        width="60px"
                    />;
                labelDeliveryMethod = __("Check / Money order");
                break;
            case "mb":
                imgDeliveryMethod =
                    <Image
                        src = {iconMb}
                        height="25px"
                        width="60px"
                    />;
                labelDeliveryMethod =
                <div block="MyAccountMyOrdersDetails" elem="Multibanco">
                    <div>
                        <label>{__("Entity")}</label>
                        <label block="MyAccountMyOrdersDetails" elem="DataBase">{ mb_entity ? mb_entity : '' }</label>
                    </div>
                    <div>
                        <label>{__("Reference")}</label>
                        <label block="MyAccountMyOrdersDetails" elem="DataBase">{ mb_reference ? mb_reference : '' }</label>
                    </div>
                    <div>
                        <label>{__("Limit Date")}</label>
                        <label block="MyAccountMyOrdersDetails" elem="DataBase">{ mb_end_date ? mb_end_date.substr(0, 10).replaceAll('-','/') : '' }</label>
                    </div>
                </div>
                ;
                break;
            case "creditcard":
                imgDeliveryMethod =
                    <Image
                        src = {iconVisaLogo}
                        height="25px"
                        width="60px"
                    />;
                //labelDeliveryMethod = __("Credit Card");
                break;
            case "mbway":
                imgDeliveryMethod =
                    <Image
                        src = {iconMbWay}
                        height="25px"
                        width="60px"
                    />;
                labelDeliveryMethod = mbway_phone;
                break;
            case "cashondelivery":
                imgDeliveryMethod =
                    <Image
                        src = { iconPosPago }
                        height="25px"
                        width="60px"
                    />;
                labelDeliveryMethod = __("Postpaid");
                break;
            case "free":
                imgDeliveryMethod =
                    <Image
                        src = { iconPosPago }
                        height="25px"
                        width="60px"
                    />;
                labelDeliveryMethod = __("Postpaid");
                break;
            default:
                break;
        }

        return (
            <div block="MyAccountMyOrdersDetails" elem="PaymentWrapper">
                <div block="MyAccountMyOrdersDetails" elem="Title">
                    <Image src = {iconPagamento} height="20px" width="32px"/>
                    <h4>{ __('Payment method') }</h4>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="Payment">
                    <div block="MyAccountMyOrdersDetails" elem="MethodImg">
                        { imgDeliveryMethod}
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="MethodLabel">
                        <label>{ labelDeliveryMethod }</label>
                    </div>
                </div>
            </div>
        );
    }

    renderShippingAddressTable() {
        const { order: { shipping_info: { shipping_address } } } = this.props;

        return (
            <MyAccountAddressTable
              title={ __('Shipping address') }
              address={ shipping_address }
              mix={ { block: 'MyAccountMyOrdersDetails', elem: 'Address' } }
            />
        );
    }

    renderShipping() {
        const { order: { shipping_info }, customer, currency_code, order } = this.props;
        const { shipping_address, shipping_method, tracking_numbers, tracking, postal_code_locality } = shipping_info || {};
        const {
            firstname,
            lastname,
            street,
            city,
            postcode
        } = shipping_address || {};
        let pickup;

        let name = firstname;

        if (!shipping_address) {
            return null;
        }

        let address;

        if(shipping_method == "flatrate_flatrate" ) {
            pickup = <label>{ __("Home delivery" ) }</label>;
            name = firstname+' '+lastname;
            address = `${ street ? street : ''  }, ${ postcode ? postcode : '' } ${ city ? city : '' }`;
        } else {
            name = firstname+' '+lastname;
            pickup = <label>{ __("Pick up at the %s", name ? name : '' ) }</label>;
            address = `${ street ? street : ''  } ${ city ? city : '' }, ${ postcode ? postcode : '' } ${postal_code_locality ? postal_code_locality : ''}`;
        }

        let trackingInfo;
        if (tracking_numbers && tracking_numbers.length > 0) {
            trackingInfo = <div block="MyAccountMyOrdersDetails" elem="ShippingTracking">
                <label>{ __('Follow your order') }</label>
                <label block="MyAccountMyOrdersDetails" elem="ShippingTrackingInfo">{ __('Tracking number') }: { tracking_numbers[0] }</label>
                {tracking && tracking.length > 0 && tracking[0] ? <label block="MyAccountMyOrdersDetails" elem="ShippingTrackingInfo">{ __('Shipping company') }: { tracking[0] }</label> : ''}
            </div>
        }

        return (
            <div block="MyAccountMyOrdersDetails" elem="ShippingWrapper">
                <div block="MyAccountMyOrdersDetails" elem="Title">
                    <Image src = {iconTruck} height="20px" width="32px"/>
                    <h4>{ __('Delivery method') }</h4>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="PickUp">
                    { pickup }
                </div>
                { trackingInfo }
                <div block="MyAccountMyOrdersDetails" elem="ShippingInfo">
                    <label>{ __('Name') }</label>
                    <label block="MyAccountMyOrdersDetails" elem="DataBase">{ name ? name : ''  }</label>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="Street">
                    <label>{ __('Address') }</label>
                    <label block="MyAccountMyOrdersDetails" elem="DataBase">{ address }</label>
                </div>
            </div>
        );
    }

    renderBilling() {
        const { order: { shipping_info }, currency_code, order, customer: { taxvat } } = this.props;
        const { billing_address, customer_taxvat } = shipping_info || {};
        const {
            street,
            city,
            postcode,
            firstname,
            lastname ,
            vat_id
        } = billing_address || {};

        if (!billing_address) {
            return null;
        }

        return (
            <div block="MyAccountMyOrdersDetails" elem="BillingWrapper">
                <div block="MyAccountMyOrdersDetails" elem="Title">
                    <Image src = {iconPagamento} height="20px" width="32px"/>
                    <h4>{ __('Billing address') }</h4>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="BillingInfo">
                    <div>
                        <label>{ __('Name') }</label>
                        <label block="MyAccountMyOrdersDetails" elem="DataBase">{ firstname ? firstname : ''  } { lastname ? lastname : '' }</label>
                    </div>
                    <div>
                        <label>{ __('Tax/VAT Number') }</label>
                        <label block="MyAccountMyOrdersDetails" elem="DataBase">{ vat_id ? vat_id : (taxvat ? taxvat : '')  }</label>
                    </div>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="Address">
                    <label>{ __('Address') }</label>
                    <label block="MyAccountMyOrdersDetails" elem="DataBase">{ street ? street : ''  }, { postcode ? postcode : '' } { city ? city : '' }</label>
                </div>
            </div>
        );
    }

    renderItems() {
        const { order: { order_products = [] }, currency_code } = this.props;
        const { isProductCapacity } = this.state;
        let unit_qty = 0;

        return order_products.map((product, i) => {
            const {
                name,
                thumbnail,
                id,
                qty,
                row_total,
                original_price,
                base_price,
                attributes,
                prescription,
                sku,
                img
            } = product;

            const { url } = thumbnail || {};

            let showcase_name = product.attributes.showcase_name ? product.attributes.showcase_name.attribute_value : '';
            let prescriptionLine = '';
            let infoIcon = '';

            let urlKey = product.attributes.url_key ? product.attributes.url_key.attribute_value : false;

            if (showcase_name != "") {
                this.setState({isProductCapacity: true});
            }

            if( qty > 1 ){
               unit_qty = __('units');
            } else {
               unit_qty = __('unit');
            }

            let onClickFunction = () => this.goToProductPage(urlKey);

            let isWallet = false;
            if(prescription && prescription != '') {
                prescriptionLine = <p><strong>{ __('Prescription') }: { prescription }</strong></p>;
                infoIcon = <Tooltip message={ __("Prescription wallet product. The total values will be available in your pharmacy.") } position={ 'bottom' }>
                        <FontAwesomeIcon
                    className="MyAccountMyOrdersDetails-AlertIcon"
                    icon={ faExclamationCircle }
                />
                    </Tooltip>;
                onClickFunction = () => {};
                isWallet = true;
            }

            let priceToUse = base_price;
            /*if(product.campaign_price && product.campaign_is_active) {
                priceToUse = product.campaign_price;
            }*/

            let priceLine =
                            <div block="MyAccountMyOrdersDetails" elem="Price">
                                { infoIcon }
                                { formatPrice( roundPrice(priceToUse), currency_code ) } /un
                            </div>;

            if(product.buy_with_points == "1"){
                priceLine =
                            <div block="MyAccountMyOrdersDetails" elem="Price">
                                { (product.product_points / product.qty) + __(" Points") } /un
                            </div>;
            }

            return (
                <div key={ id || i.toString() } block="MyAccountMyOrdersDetails" elem="Product-Row">
                    <div block="MyAccountMyOrdersDetails" elem="Product-Row-Image" mods={ { isWallet } } onClick={ onClickFunction }>
                        <Image src={ thumbnail.url } mix={ { block: 'MyAccountMyOrdersDetails', elem: 'Picture' } } ratio="custom" alt={ `Product ${name} thumbnail.` } />
                        <img style={ { display: 'none' } } alt={ name } src={ thumbnail.url }/>
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="Product-Row-Info" mods={ { isWallet } } onClick={ onClickFunction }>
                        <div block="MyAccountMyOrdersDetails" elem="Name" mods={ { isWallet } }>
                            <label>{ name }</label>
                            <div block="MyAccountMyOrdersDetails" elem="Details" mods={ { isWallet } }>
                                <span block="MyAccountMyOrdersDetails" elem="Capacity" mods={ { isProductCapacity:isProductCapacity } }>{ showcase_name }</span>
                            </div>
                            { prescriptionLine }
                        </div>
                        <div block="MyAccountMyOrdersDetails" elem="Info-Right">
                            <div block="MyAccountMyOrdersDetails" elem="Qty">
                                <label>{qty} {unit_qty}</label>
                            </div>
                            { priceLine }
                        </div>
                    </div>
                </div>
            );
        });
    }

    goToProductPage = (urlKey) => {
        if (urlKey) {
            history.push(appendWithStoreCode('/'+urlKey+'.html'));
        }
    }

    renderProducts() {
        return (
            <div block="MyAccountMyOrdersDetails" elem="ProductsWrapper">
                <div block="MyAccountMyOrdersDetails" elem="Title">
                    <FontAwesomeIcon icon={faShoppingCart} />
                    <h4>{ __('Shopping cart') }</h4>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="Products">
                    { this.renderItems() }
                    { this.renderTotals() }
                </div>

            </div>
        );
    }

    renderTotals() {
        const { order: { base_order_info, shipping_info, order_products }, order, currency_code } = this.props;
        const { grand_total, sub_total } = base_order_info || {};
        const { Sauda_discount_points, base_shipping_amount, base_discount_amount, coupon_code, sauda_discount, mbway_phone } = shipping_info || {};
        let totalProductPoints = 0;
        let totalPointsInValue = 0;
        if(order_products && order_products.length) {
            order_products.forEach(item => {
                if(item.buy_with_points && item.buy_with_points != '0'){
                    totalProductPoints = totalProductPoints + (item.product_points == null ? 0 : parseInt( item.product_points) );
                    totalPointsInValue += item.row_total;
                }
            });
        }

        let subTotalDiscount = 0;
        if (base_discount_amount) {
            subTotalDiscount = base_discount_amount;
        }

        let infoIcon = '';
        let walletFlag = order_products && order_products.some(({ prescription }) => prescription && prescription !== "");
        if(walletFlag) {
            infoIcon = <Tooltip message={ <CmsBlock identifier={ "order_detail_wallet_notice" } /> } position={ 'bottom' }>
                <FontAwesomeIcon
                className="MyAccountMyOrdersDetails-AlertIcon"
                icon={ faExclamationCircle }
            />
            </Tooltip>;
        }

        return (
            <>
            <div block="MyAccountMyOrdersDetails" elem="ProductTotals">
                <div block="MyAccountMyOrdersDetails" elem="OrderWrapper">
                    <div block="MyAccountMyOrdersDetails" elem="OrderTitle">
                        <label>{__("subtotal")}</label>
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="OrderValue">
                        <label>{ sub_total ? formatPrice( roundPrice(sub_total - totalPointsInValue), currency_code ) : 0 }</label>
                    </div>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="OrderWrapper">
                    <div block="MyAccountMyOrdersDetails" elem="OrderTitle">
                        <label>{__("Points")}</label>
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="OrderValue">
                        <label>{ totalProductPoints }</label>
                    </div>
                </div>
                {/*<div block="MyAccountMyOrdersDetails" elem="OrderWrapper">
                    <div block="MyAccountMyOrdersDetails" elem="OrderTitle">
                        <label>{__("Coupon ")}</label>
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="OrderValue">
                        <label>{ coupon_code == null ? 0 : coupon_code }</label>
                    </div>
                </div> */}
                <div block="MyAccountMyOrdersDetails" elem="OrderWrapper">
                    <div block="MyAccountMyOrdersDetails" elem="OrderTitle">
                        <label>{__("Voucher/Discount")}</label>
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="OrderValue">
                        <label>{ subTotalDiscount ? formatPrice( roundPrice(subTotalDiscount), currency_code ) : formatPrice(0, currency_code) }</label>
                    </div>
                </div>
                <div block="MyAccountMyOrdersDetails" elem="OrderWrapper">
                    <div block="MyAccountMyOrdersDetails" elem="OrderTitle">
                        <label>{__("Delivery ")}</label>
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="DeliveryFree">
                        <label>{ base_shipping_amount && base_shipping_amount > 0 ? formatPrice( roundPrice(base_shipping_amount), currency_code ) : __("Free") }</label>
                    </div>
                </div>
            </div>
            <div block="MyAccountMyOrdersDetails" elem="Totals">
                <div block="MyAccountMyOrdersDetails" elem="OrderWrapper">
                    <div block="MyAccountMyOrdersDetails" elem="OrderTitle">
                        <label>{__("Total ")}</label>
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="OrderValue">
                        <label>{ infoIcon } { grand_total ? formatPrice( roundPrice(grand_total), currency_code ) : 0 } { totalProductPoints ? ' + ' + totalProductPoints + ' ' + __("pts") : '' }</label>
                    </div>
                </div>
            </div>
            </>
        );
    }

    renderCampaigns() {
        const { order: { campaigns } } = this.props;

        if(campaigns && campaigns.length > 0) {
            return (
                <div block="MyAccountMyOrdersDetails" elem="ShippingWrapper">
                    <div block="MyAccountMyOrdersDetails" elem="Title">
                        <img class="MyAccountMyOrdersDetails-FlagSauda" src={ icon_sauda_product } alt={ __('Sauda Card') } />
                        <h4>{ __('Used Saúda Benefits') }</h4>
                    </div>
                    { this.renderCampaignLines(campaigns) }
                </div>
            );
        }
    }

    renderCampaignLines(campaigns) {
        return campaigns.map(({ description, value }) => {
            return (
                <div block="MyAccountMyOrdersDetails" elem="CampaignLine">
                    <div block="MyAccountMyOrdersDetails" elem="CampaignLineImage">
                        <img class="MyAccountMyOrdersDetails-FlagSauda" src={ icon_sauda_product } alt={ __('Sauda Card') } />
                        </div>
                    <div block="MyAccountMyOrdersDetails" elem="CampaignLineDesc">
                        { description }
                    </div>
                    <div block="MyAccountMyOrdersDetails" elem="CampaignLineValue">
                        <strong>{ value }</strong>
                    </div>
                </div>
            );
        });
    }

    renderContent() {
        const { order: { order_products }, orderDetails: {
            base_order_info: { id }
        } } = this.props;
        const { requestOrderDetails } = this.props;
        const { prevOrderId } = this.state;

        if (prevOrderId != id) {
            this.setState({prevOrderId: id});
            requestOrderDetails();
        }

        return (
            <>
                { this.renderBaseInfo() }
                { this.renderProducts() }
                { this.renderCampaigns() }
                { this.renderShipping() }
                { this.renderBilling() }
                { this.renderPayment() }
            </>
        );
    }

    render() {
        const { orderList, key, isLoading } = this.props;
        return (
            <div block="MyAccountMyOrdersDetails">
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </div>
        );
    }
}

export default MyAccountMyOrdersDetails;
