import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyAccountProfilePicture from './MyAccountProfilePicture.component';
import { customerType } from 'Type/Account';


export const mapStateToProps = (state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

export const mapDispatchToProps = (dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

export class MyAccountProfilePictureContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
        customer: customerType.isRequired,
    };

    state = {
        fileUploaded : ''
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
        onphotoChange: this.onphotoChange.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    onphotoChange(e) {
        const { customer } = this.props
        customer.photo = e.target.files[0];
    }

    render() {
        return (
            <MyAccountProfilePicture
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountProfilePictureContainer);
