/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductList from 'Component/ProductList';
import ProductCarousel from 'Component/ProductCarousel';

import {
    ProductListWidgetContainer as SourceProductListWidgetContainer,
    mapDispatchToProps as SourceMapDispatchToProps,
    mapStateToProps as SourceMapStateToProps,
} from 'SourceComponent/ProductListWidget/ProductListWidget.container';

/** @namespace Component/SliderWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state)
});

/** @namespace Component/SliderWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...SourceMapDispatchToProps(dispatch)
});

/** @namespace Component/SliderWidget/Container */
export class ProductListWidgetContainer extends SourceProductListWidgetContainer {

    adaptProps() {
        const {
            showPager,
            productsCount,
            productsPerPage,
            conditionsEncoded: conditions,
            sortOrder
        } = this.props;

        let sortAArray = sortOrder.split('_');
        let count = sortAArray.length;
        let sortDirection = 'ASC';
        if(sortAArray[count-1] && sortAArray[count-1] == 'descending'){
            sortDirection = 'DESC';
        }

        let config = {
            sortKey: '',
            sortDirection: ''
        };

        if(sortOrder == 'position_by_sku'){
            sortAArray[0] = 'relevance';
            sortDirection = 'DESC';
        }
        if(sortOrder == 'low_stock_first'){
            sortAArray[0] = 'stock';
            sortDirection = 'ASC';
        }
        if(sortOrder == 'high_stock_first'){
            sortAArray[0] = 'stock';
            sortDirection = 'DESC';
        }
        if(sortOrder == 'price_high_to_low'){
            sortAArray[0] = 'price';
            sortDirection = 'DESC';
        }
        if(sortOrder == 'price_low_to_high'){
            sortAArray[0] = 'price';
            sortDirection = 'ASC';
        }

        config = {
            sortKey: sortAArray[0],
            sortDirection: sortDirection
        };

        return {
            filter: { conditions },
            pageSize: showPager ? productsPerPage : productsCount,
            isPaginationEnabled: !!showPager,
            sort: config
        };
    }

    render() {
        const adaptedProps = this.adaptProps();
        const { typeName } = this.props;

        if (typeName && typeName.includes('Carousel')) {
            return (
                <ProductCarousel
                    { ...this.props }
                    { ...this.state }
                    { ...adaptedProps }
                    { ...this.containerFunctions }
                    isInfiniteLoaderEnabled={ false }
                    numberOfPlaceholders={ 4 }
                    mix={ { block: 'ProductListWidget' } }
                    isWidget
                />
            );
        }

        return (
            <ProductList
                { ...this.props }
                { ...this.state }
                { ...adaptedProps }
                { ...this.containerFunctions }
                isInfiniteLoaderEnabled={ false }
                numberOfPlaceholders={ 6 }
                mix={ { block: 'ProductListWidget' } }
                isWidget
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListWidgetContainer);