import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import QRCode from 'qrcode.react';

import './MyAccountPrescriptionPopup.style.override'

export class MyAccountPrescriptionPopup extends PureComponent {

    renderImageElement() {
        const { payload: { prescription } } = this.props;
        //const code = `${ prescription.number }|${ prescription.access_code }|${ prescription.option_code }`;
        //29/02/2022 pedido para option code ser "0000"
        const code = `${ prescription.number }|${ prescription.access_code }|0000`;
        const encodedString = Buffer.from(code).toString('base64');



        return (
            <QRCode value={ encodedString } size={ 150 } />
        );
    }

    renderContent() {
        const { payload: { prescription } } = this.props;

        if(!prescription) {
            return;
        }

        return (
                <p>
                    <p>{ __("Present this QRCode at your pharmacy for simplified reading of the prescription.") }</p>
                    <div block="MyAccountPrescriptionPopup" elem="Prescription">
                        { this.renderImageElement() }
                        <div block="MyAccountPrescriptionPopup" elem="Information">
                            <p block="MyAccountPrescriptionPopup" elem="Description">{  __("Prescription Nº") }
                            <strong>{ prescription.number }</strong></p>
                            <p block="MyAccountPrescriptionPopup" elem="Description">{  __("Access Code") }
                            <strong>{ prescription.access_code }</strong></p>
                            <p block="MyAccountPrescriptionPopup" elem="Description">{  __("Option Code") }
                            <strong>{ prescription.option_code }</strong></p>
                        </div>
                    </div>
                </p>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <Popup
              id={ "MyAccountPrescriptionPopup" }
              clickOutside={ true }
              closedOn={ true }
              mix={ { block: 'MyAccountPrescriptionPopup' } }
            >
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </Popup>
        );
    }

};

export default MyAccountPrescriptionPopup;
