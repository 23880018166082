import { connect } from 'react-redux';

import {
    CartDispatcher as SourceCartDispatcher,
    WishlistDispatcher as SourceWishlistDispatcher,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    WishlistItemContainer as SourceWishlistItemContainer,
} from 'SourceComponent/WishlistItem/WishlistItem.container';

//TODO: implement CartDispatcher
export const CartDispatcher = SourceCartDispatcher;

//TODO: implement WishlistDispatcher
export const WishlistDispatcher = SourceWishlistDispatcher;

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class WishlistItemContainer extends SourceWishlistItemContainer {
    // TODO implement logic
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistItemContainer);
