import {
    Popup as SourcePopup,
} from 'SourceComponent/Popup/Popup.component';

import './Popup.style.override.scss'
import Overlay from 'Component/Overlay/Overlay.component';

export class Popup extends SourcePopup {
    // TODO implement logic
    static defaultProps = {
       closedOn: false,
       ...Overlay.defaultProps,
       onClose: () => {},
       clickOutside: true,
       title: ''
    };

    onVisible() {
        const { onVisible } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        onVisible();
    }

    onClosing = () => {
        const {
            onClose,
            hideActiveOverlay
        } = this.props;


        hideActiveOverlay();

        //onClose();
    };

    renderCloseButton() {
        const { closedOn, hideActiveOverlay  } = this.props;

        if(!closedOn) {
            return (
                <>
                </>
            );
        } else {
            return (
                <button
                    block="Popup"
                    elem="CloseBtn"
                    aria-label={ __('Close') }
                    onClick={ this.onClosing }
                />
            );
    }
}

};

export default Popup;
