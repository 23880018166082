import {
    CheckoutBilling as SourceCheckoutBilling,
} from 'SourceComponent/CheckoutBilling/CheckoutBilling.component';

import CheckoutAddressBook from 'Component/CheckoutAddressBook';
import CheckoutPayments from 'Component/CheckoutPayments';
import Form from 'Component/Form';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import PropTypes from 'prop-types';

import './CheckoutBilling.style.override'

export class CheckoutBilling extends SourceCheckoutBilling {

    static propTypes = {
        setMbwayPhone: PropTypes.func.isRequired,
        getMbwayStatus: PropTypes.func.isRequired,
        getBillingPhoneFlag: PropTypes.func.isRequired,
        billing_phone_flag: PropTypes.bool.isRequired,
        mbway_phone_flag: PropTypes.bool.isRequired,
        setIsEditing: PropTypes.func.isRequired,
        isEditing: PropTypes.bool.isRequired,
        setAddressBillingTmpCreating: PropTypes.func.isRequired,
        setAddressBillingNotStored: PropTypes.func.isRequired,
        addressBillingTmpCreating: PropTypes.bool.isRequired,
        addressBillingNotStored: PropTypes.objectOf(PropTypes.string).isRequired,
        setCheckoutEditAddress: PropTypes.func.isRequired,
        zipCodeValid: PropTypes.bool.isRequired
    }

    renderAddressBook() {
        const {
            onAddressSelect,
            setPhone,
            billing_phone,
            billing_phone_flag,
            setBillingName,
            billing_name,
            setBillingTax,
            billing_tax,
            setNotReady,
            customBillingPhone,
            setCustomBillingPhone,
            customBillingNif,
            setCustomBillingNif,
            is_wallet,
            setCustomBillingMbway,
            customBillingMbway,
            setIsEditing,
            isEditing,
            setAddressBillingTmpCreating,
            setAddressBillingNotStored,
            addressBillingTmpCreating,
            addressBillingNotStored,
            shippingAddress,
            setIsCreatingAddress,
            mainLoaderActive,
            setCheckoutEditAddress,
            zipCodeValid
        } = this.props;

        return (
            <div block="CheckoutBilling" elem="AddressBook">
                <CheckoutAddressBook
                    mainLoaderActive={ mainLoaderActive }
                    is_wallet={ is_wallet }
                    setNotReady={ setNotReady }
                    customBillingPhone={ customBillingPhone }
                    setCustomBillingPhone={ setCustomBillingPhone }
                    customBillingNif={ customBillingNif }
                    setCustomBillingNif={ setCustomBillingNif }
                    onAddressSelect={ onAddressSelect }
                    billing_phone_flag={ billing_phone_flag }
                    setPhone={ setPhone }
                    billing_phone={ billing_phone }
                    setBillingName={ setBillingName }
                    billing_name={ billing_name }
                    setBillingTax={ setBillingTax }
                    billing_tax={ billing_tax }
                    setCustomBillingMbway={ setCustomBillingMbway }
                    customBillingMbway={ customBillingMbway }
                    isBilling
                    setIsEditing={ setIsEditing }
                    isEditing={ isEditing }
                    setAddressBillingTmpCreating={ setAddressBillingTmpCreating }
                    setAddressBillingNotStored={ setAddressBillingNotStored }
                    addressBillingTmpCreating={ addressBillingTmpCreating }
                    addressBillingNotStored={ addressBillingNotStored }
                    shippingAddress={ shippingAddress }
                    setIsCreatingAddress={ setIsCreatingAddress }
                    setCheckoutEditAddress={ setCheckoutEditAddress }
                    zipCodeValid={ zipCodeValid }
                />
            </div>
        );
    }

    renderAddresses() {
        return (
            <>
                { this.renderAddressBook() }
            </>
        );
    }

    render() {
        const { onBillingSuccess, onBillingError } = this.props;

        return (
            <Form
              mix={ { block: 'CheckoutBilling' } }
              id={ BILLING_STEP }
              onSubmitError={ onBillingError }
              onSubmitSuccess={ onBillingSuccess }
            >
                { this.renderPayments() }
                { this.renderTermsAndConditions() }
                { this.renderAddresses() }
                { this.renderActions() }
                { this.renderPopup() }
            </Form>
        );
    }

    renderPayments() {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            setLoading,
            setDetailsStep,
            shippingAddress,
            setMbwayPhone,
            getMbwayStatus,
            mbway_phone_flag,
            setSelectedPayment,
            seletedPaymentCustom,
            setCustomBillingMbway,
            setMbwayPhoneFlag,
            customBillingMbway,
            notReady,
            mainLoaderActive
        } = this.props;

        if (!paymentMethods.length) {
            return null;
        }

        return (
            <CheckoutPayments
              mainLoaderActive={ mainLoaderActive }
              setLoading={ setLoading }
              setDetailsStep={ setDetailsStep }
              paymentMethods={ paymentMethods }
              onPaymentMethodSelect={ onPaymentMethodSelect }
              setOrderButtonVisibility={ this.setOrderButtonVisibility }
              billingAddress={ shippingAddress }
              setOrderButtonEnableStatus={ this.setOrderButtonEnableStatus }
              setMbwayPhone={ setMbwayPhone }
              getMbwayStatus={ getMbwayStatus }
              mbway_phone_flag={ mbway_phone_flag}
              setMbwayPhoneFlag={ setMbwayPhoneFlag}
              setSelectedPayment={ setSelectedPayment }
              seletedPaymentCustom={ seletedPaymentCustom }
              setCustomBillingMbway={ setCustomBillingMbway }
              customBillingMbway={ customBillingMbway }
              notReady={ notReady }
            />
        );
    }

};

export default CheckoutBilling;
