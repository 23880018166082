import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import gtag, { install } from 'ga-gtag';
import ReactPixel from 'react-facebook-pixel';
import { fetchQuery } from 'Util/Request';
import ConfigQuery from 'Query/Config.query';
import TagManager from 'react-gtm-module';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    CheckoutShippingContainer as SourceCheckoutShippingContainer,
} from 'SourceComponent/CheckoutShipping/CheckoutShipping.container';

import { customerType } from 'Type/Account';
import { shippingMethodsType } from 'Type/Checkout';
import { StorePageDispatcher } from '../../store/StorePage';
import { getFormFields, trimAddressFields, trimCustomerAddress } from 'Util/Address';
import { showNotification } from 'Store/Notification/Notification.action';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    favStoreObj: state.StorePageReducer.favStoreObj,
    baseLinkUrl: state.ConfigReducer.base_link_url
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    /*requestStore: (id) => {
        StorePageDispatcher.requestStore(dispatch, id);
    },*/
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

export class CheckoutShippingContainer extends SourceCheckoutShippingContainer {

    state = {
        isEditing: false,
        isCreatingAddress: false,
        addressNotStored: {
            firstname:''
        }
    }

    static propTypes = {
        saveAddressInformation: PropTypes.func.isRequired,
        shippingMethods: shippingMethodsType.isRequired,
        customer: customerType.isRequired,
        addressLinesQty: PropTypes.number.isRequired,
        updateShippingFields: PropTypes.func.isRequired,
        requestStore: PropTypes.func.isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        setAddressTmp: PropTypes.func.isRequired,
        addressTmp: PropTypes.objectOf(PropTypes.string).isRequired,
        getAddressTmp: PropTypes.func.isRequired,
        addressTmpCreating: PropTypes.bool.isRequired,
        setNotReady: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { totals } = this.props;

        ReactPixel.track('InitiateCheckout');

        fetchQuery(ConfigQuery.getAnalyticsConfig('page_view_shipping')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );

        fetchQuery(ConfigQuery.getAnalyticsConfig('page_view_shipping_counter')).then(
            ({ getAnalyticsConfig }) => {
                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': getAnalyticsConfig.config_value
                });
            }
        );
        
        let itemsGTM = Object.values(totals.items).map( (item,index) => {
            return {
                id: item.sku,
                name: item.product.name,
                price: item.price,
                quantity: item.qty
                
            }
        }) 
        
        TagManager.dataLayer({
            dataLayer: {
              event: 'checkout',
              ecommerce: {
                checkout: {
                    products: itemsGTM
                }
            }
            },
        });
    }

    /*componentDidUpdate(prevProps) {
        const { requestStore, customer: { favourite_pharmacy_code } } = this.props;
        const { customer: { favourite_pharmacy_code: prevCode } } = prevProps;

        if(favourite_pharmacy_code && favourite_pharmacy_code != prevCode) {
            requestStore(favourite_pharmacy_code);
        }
    }*/

    componentDidUpdate(){
        const { addressTmp, getAddressTmp } = this.props;

        if(addressTmp && addressTmp.firstname != ''){
            this.setState({ addressNotStored: addressTmp });
        }
    }

    containerFunctions = {
        onShippingSuccess: this.onShippingSuccess.bind(this),
        onShippingError: this.onShippingError.bind(this),
        onAddressSelect: this.onAddressSelect.bind(this),
        onShippingMethodSelect: this.onShippingMethodSelect.bind(this),
        setIsEditing: this.setIsEditing.bind(this),
        setIsCreatingAddress: this.setIsCreatingAddress.bind(this),
        setAddressNotStored: this.setAddressNotStored.bind(this)
    };

    setAddressNotStored(address) {
        const { setAddressTmp } = this.props;
        this.setState({ addressNotStored: address });
        setAddressTmp(address);
    }

    setIsEditing(value) {
        const { setNotReady } = this.props;
        this.setState({ isEditing: value });
        setNotReady(value);
    }

    setIsCreatingAddress(value) {
        this.setState({ isCreatingAddress: value });
    }

    onShippingMethodSelect(method) {
        const { setSelectedShipping } = this.props;
        this.setState({ selectedShippingMethod: method });
        setSelectedShipping(method);
    }

    onShippingSuccess(fields) {
        const {
            saveAddressInformation,
            updateShippingFields,
            addressLinesQty,
            favStoreObj,
            showErrorNotification,
            setAddressTmp,
            addressTmpCreating
        } = this.props;

        const {
            selectedCustomerAddressId,
            selectedShippingMethod,
            isEditing,
            addressNotStored,
            isCreatingAddress
        } = this.state;

        if(!selectedShippingMethod) {
            showErrorNotification(__('Please select a delivery method!'));
            return;
        }

        const formFields = getFormFields(fields, addressLinesQty);

        let addr = {};

        if(addressTmpCreating && addressNotStored.firstname){
            addr = addressNotStored;
            addr.region = '';
        } else {
            addr = selectedCustomerAddressId
            ? this._getAddressById(selectedCustomerAddressId)
            : trimAddressFields(formFields);
        }

        const clientAddress = addr;


        let shippingAddress = clientAddress;

        if(!isCreatingAddress && selectedShippingMethod.method_code != "pharmacy_pickup" && !clientAddress.firstname){
            if(formFields.firstname == '' || !formFields.firstname){
                showErrorNotification(__('Please create a shipping address!'));
                return;
            }
        }

        if(clientAddress.firstname && !clientAddress.lastname) {
            /*let nameArray    = clientAddress.firstname.split(' ');
            clientAddress.firstname = nameArray[0];
            clientAddress.lastname  = nameArray.pop();*/
            let nameArray    = clientAddress.firstname.split(' ');
            let count = nameArray.length;
            if(nameArray[(count - 1)] != ' ' && nameArray[(count - 1)] != '') {
                clientAddress.lastname  = nameArray[(count - 1)];
            } else {
                clientAddress.lastname  = nameArray[(count - 2)];
            }
            clientAddress.firstname = clientAddress.firstname.replace(clientAddress.lastname, '');
        } /*else {
            if (!localStorage.getItem('guest_pharmacy_code')) {
                if(selectedShippingMethod.method_code != "pharmacy_pickup"){
                    clientAddress.lastname = clientAddress.lastname.split(' ')[0];
                    //clientAddress.firstname = clientAddress.firstname.replace(clientAddress.lastname, '');
                    let lastNameLength = clientAddress.lastname.length + 1;
                    clientAddress.firstname = clientAddress.firstname.slice(0, -lastNameLength);
                }
            }
        }*/


        //let firstNameCount = shippingAddress.firstname.split(' ').length;

        /*console.log(shippingAddress.firstname);
        if(formFields.firstname != '') {
            shippingAddress.firstname = shippingAddress.firstname + clientAddress.lastname;
        }
        console.log(shippingAddress.firstname);*/

        const {
            carrier_code: shipping_carrier_code,
            method_code: shipping_method_code
        } = selectedShippingMethod;

        if(shipping_carrier_code && shipping_carrier_code == 'pharmacy_pickup' && Object.entries(favStoreObj).length) {
            const {
                name,
                street_name,
                number,
                telephone,
                telephone_secondary,
                locality,
                postal_code_zone,
                postal_code_sufix
            } = favStoreObj;
            let address = `${street_name} ${number}`;
            let postCode = `${postal_code_zone}-${postal_code_sufix}`;

            let nameExplode = name.split(' ');
            let countPharma = nameExplode.length;
            let pharmacyName2 = nameExplode[(countPharma - 1)];
            let pharmacyName1 = __('Pharmacy');

            if (countPharma > 1) {
                pharmacyName1 = name.replace(pharmacyName2, '');
            }

            shippingAddress = {
                city: locality,
                company: undefined,
                country_id: "PT",
                email: undefined,
                firstname: pharmacyName1,
                lastname: pharmacyName2,
                method: undefined,
                postcode: postCode,
                region: null,
                region_code: null,
                region_id: 0,
                save_in_address_book: false,
                street: [address],
                telephone: telephone_secondary ? telephone_secondary : telephone,
                vat_id: null
            };
        }

        let billingAddress = shippingAddress;


        if(!clientAddress.firstname && shippingAddress.firstname) {
            billingAddress = shippingAddress;
            /*if(formFields.firstname != '') {
                billingAddress.firstname = shippingAddress.firstname;
            }*/
        }

        const data = {
            billing_address: billingAddress,
            shipping_address: shippingAddress,
            shipping_carrier_code,
            shipping_method_code
        };

        if(!isEditing && !isCreatingAddress){
            saveAddressInformation(data);
            updateShippingFields(fields);
            let cleanTmp = {
                firstname: ''
            };
            if(!addressTmpCreating){
                setAddressTmp(cleanTmp);
            }
        }
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutShippingContainer);
