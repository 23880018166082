/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 import PropTypes from 'prop-types';
 import { PureComponent } from 'react';
 import { connect } from 'react-redux';
 import { withRouter } from 'react-router-dom';
 
 import ProductListInfoDispatcher from 'Store/ProductListInfo/ProductListInfo.dispatcher';
 
 import ProductCarousel from './ProductCarousel.component';
 import { ProductListContainer } from 'Component/ProductList/ProductList.container';
 
 /** @namespace Component/ProductCarousel/Container/mapStateToProps */
 export const mapStateToProps = (state) => ({
     device: state.ConfigReducer.device,
     customer: state.MyAccountReducer.customer,
 });
 
 /** @namespace Component/ProductCarousel/Container/mapDispatchToProps */
 export const mapDispatchToProps = (dispatch) => ({
     requestProductListInfo: (options) => ProductListInfoDispatcher.handleData(dispatch, options)
 });
 
 /** @namespace Component/ProductCarousel/Container */
 export class ProductCarouselContainer extends ProductListContainer { 
     // TODO implement logic
    state = {
        countRequestProducts: 0
    }

    static propTypes = {
        magentoPageSize: PropTypes.number,
        updateBreadcrumbs: PropTypes.func.isRequired
    }

    static defaultProps = {
        pageSize: 24,
        filter: {},
        search: '',
        selectedFilters: {},
        sort: undefined,
        isPreventRequest: false,
        isPaginationEnabled: true,
        isInfiniteLoaderEnabled: true,
        isPageLoading: false,
        noAttributes: false,
        noVariants: false,
        isWidget: false,
        magentoPageSize: 12
    };

    componentDidUpdate(prevProps) {
        const { favStoreObj, sort, search, filter, customer, totalItems } = this.props;
        const { favStoreObj: prevFavStoreObj, sort: prevSort, search: prevSearch, filter: prevFilter, totalItems: prevTotalItems } = prevProps;


        if (JSON.stringify(filter) !== JSON.stringify(prevFilter)) {
            this.requestPage(this._getPageFromUrl());
        }
        if (favStoreObj && prevFavStoreObj) {
            const { pharmacy_code } = favStoreObj;
            const { pharmacy_code: prevPharmacy } = prevFavStoreObj;

            if (pharmacy_code != prevPharmacy) {
                this.requestPage(this._getPageFromUrl());
            }
        }
        if(customer && !prevProps.customer){
            this.requestPage(this._getPageFromUrl());
        }
        if (prevProps.customer != customer) {
            this.requestPage(this._getPageFromUrl());
        }
        if (this.state.countRequestProducts == 0) {
            this.requestPage(this._getPageFromUrl());
            this.state.countRequestProducts++;
        }
    }

    requestPage = (currentPage = 1, isNext = false) => {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            magentoPageSize,
            customer
        } = this.props;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        let pharma = null;
        if(customer && customer.favourite_pharmacy_code) {
            pharma = customer.favourite_pharmacy_code;
        } else {
            if (localStorage.getItem('guest_pharmacy_code')) {
                pharma = localStorage.getItem('guest_pharmacy_code');
            }
        }

        let pricesDate = null;

        if(pharma){
            let dateNow = new Date(Date.now());
            pricesDate = dateNow.getDay()+'-'+dateNow.getMonth()+'-'+dateNow.getFullYear()+'-'+dateNow.getHours()+'-'+dateNow.getMinutes();
        }

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort,
                filter,
                search,
                pageSize,
                currentPage,
                pharma,
                pricesDate
            }
        };

        const infoOptions = {
            args: {
                filter,
                search,
                pharma,
                sort,
                pricesDate
            }
        };

        requestProductList(options);
        requestProductListInfo(infoOptions);
    };

    _getPagesBounds() {
        const { pages, totalItems, pageSize, magentoPageSize } = this.props;
        const keys = Object.keys(pages);

        return {
            maxPage: Math.max(...keys),
            minPage: Math.min(...keys),
            totalPages: Math.ceil(totalItems / pageSize),
            loadedPagesCount: keys.length
        };
    }

    render() {
        return (
            <ProductCarousel
                { ...this.props }
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }
 }
 
 export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductCarouselContainer));
 