import { connect } from 'react-redux';

import {
    WishlistDispatcher as SourceWishlistDispatcher,
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

import ProductWishlistButton from './ProductWishlistButton.component';

import { ERROR_CONFIGURABLE_NOT_PROVIDED } from './ProductWishlistButton.config';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

//TODO: implement WishlistDispatcher
export const WishlistDispatcher = SourceWishlistDispatcher;

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    // TODO implement logic

    toggleProductInWishlist = (add = true) => {
        const {
            product: { sku, type_id, url },
            product: productState,
            quantity,
            isAddingWishlistItem,
            showNotification,
            productsInWishlist,
            addProductToWishlist,
            onProductValidationError,
            removeProductFromWishlist
        } = this.props;

        if (!isSignedIn()) {
            return showNotification('info', __('You must login or register to add items to your wishlist.'));
        }

        if (isAddingWishlistItem) {
            return null;
        }

        const product = this._getProductVariant();
        if (product === ERROR_CONFIGURABLE_NOT_PROVIDED) {
            onProductValidationError(type_id);
            showNotification('info', __('Please, select desirable option first!'));

            history.push({
                pathname: url,
                state: { product:productState }
            });
            return;
        } else {
            // this.setWishlistButtonLoading(true);

            const { sku: variantSku, product_option } = product;
            if (add) {
                return addProductToWishlist({ sku, product_option, quantity });
            }
    
            const { wishlist: { id: item_id } } = Object.values(productsInWishlist).find(
                ({ wishlist: { sku } }) => sku === variantSku
            );
    
            return removeProductFromWishlist({ item_id, sku: variantSku });
        }
    };

    render() {
        const { isWishlistButtonLoading } = this.state;

        // Check if redirect is needed
        const product = this._getProductVariant();
        const showLinkRedirect = product === ERROR_CONFIGURABLE_NOT_PROVIDED;

        return (
            <ProductWishlistButton
              isLoading={ isWishlistButtonLoading }
              showLinkRedirect={ showLinkRedirect }
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions() }
            />
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
