/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// eslint-disable-next-line import/prefer-default-export
export function getPostUrl(post) {
    const { identifier } = post;

    return `/blog/${ identifier }`;
}

/** @namespace Util/Posts/getIndexedPost */
export const getIndexedPost = (post) => {

    return {
        ...post,
    };
};

/** @namespace Util/Posts/getIndexedPosts */
export const getIndexedPosts = (posts) => posts.map(getIndexedPost);
