/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_STORE_PAGE,
    UPDATE_FAVORITE_PHARMACY,
    UPDATE_STORE_PAGE_LOADING
} from './StorePage.action';

export const initialState = {
    storeObj: {},
    favStoreObj: {},
    isLoading: true
};

/** @namespace Scandiweb/StoreFinderGraphQl/Store/StorePage/Reducer/StorePageReducer */
export const StorePageReducer = (state = initialState, action) => {
    const { storeObj, favStoreObj, type, isLoading } = action;

    switch (type) {
        case UPDATE_STORE_PAGE:
            return {
                ...state,
                storeObj,
                isLoading: false
            };
        case UPDATE_FAVORITE_PHARMACY:
            return {
                ...state,
                favStoreObj,
                isLoading: false
            };
        case UPDATE_STORE_PAGE_LOADING:
            return {
                ...state,
                isLoading
            }
        default:
            return state;
    }
};

export default StorePageReducer;
