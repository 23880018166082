import {
    Overlay as SourceOverlay,
} from 'SourceComponent/Overlay/Overlay.component';

 /** @namespace Component/Overlay/Component */
 export class Overlay extends SourceOverlay {
     onVisible() {
         const { onVisible, isStatic, device,id } = this.props;
         if (isStatic) {
             return;
         }
         if (device.isMobile) {
             this.freezeScroll();
         }
         this.overlayRef.current.focus();
         onVisible();
         if(id == 'PharmacySelector'){
            localStorage.setItem('overlayIsVisible',true);
         }
     }
 }
 export default Overlay;