import PropTypes from 'prop-types';
import './HealthCardSmsNumberForm.style';
import FieldForm from 'Component/FieldForm';
import { customerType } from 'Type/Account';

class HealthCardSmsNumberForm extends FieldForm {
    static propTypes = {
        //phoneNumber: PropTypes.string.isRequired,
        customer: customerType.isRequired
    };

    onFormSuccess = (fields) => {
        window.scrollTo(0, 0);
        const { doQuerySendPhone } = this.props;
        const { phone } = fields;
        doQuerySendPhone(phone);
    };

    get fieldMap() {
        const { customer } = this.props;
        let customer_phone = '';
        if(customer && customer.phone && customer.phone != __('No phone associated.')) {
            customer_phone = customer.phone;
            this.onPhoneChange(customer_phone);
        }

        return {
            phone: {
                type: 'phone',
                label: __('Telephone'),
                validation: ['telephone'],
                full_line: true,
                value: customer_phone,
                onChange: this.onPhoneChange
            }
        };
    }

    onPhoneChange = (fields) => {
        const { onPhoneChange } = this.props;
        onPhoneChange(fields);
    }

    renderActions() {
        return (
            <button
                block="HealthCard"
                elem="Button"
                mix={ { block: 'Button' } }
            >
                <p
                    block="HealthCard"
                    elem="ButtonJoinNow">
                        { __('Next') }
                </p>
            </button>
        );
    }
}

export default HealthCardSmsNumberForm;
