/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import Form from 'Component/Form';
import FieldForm from 'Component/FieldForm';
import FieldTextarea from 'Component/FieldTextarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  './MyAccountPrescriptionForm.style';
import { fetchQuery } from 'SourceUtil/Request';
import MyAccountQuery from 'Query/MyAccount.query';
import Loader from 'SourceComponent/Loader';
import Popup from 'Component/Popup'
import Image from 'Component/Image'
import icon_insert_code from 'Component/MyAccountPrescriptionForm/images/icon_inserir_cod@2x.png';
import icon_sms from 'Component/MyAccountPrescriptionForm/images/icon_sms@2x.png';
import icon_insert_code_active from 'Component/MyAccountPrescriptionForm/images/icon_inserir_cod@2x_green.png';
import icon_sms_active from 'Component/MyAccountPrescriptionForm/images/icon_sms@2x_green.png';
import icon_insert_code_mobile from 'Component/MyAccountPrescriptionForm/images/icon_inserir_cod@2x_white.png';
import icon_sms_mobile from 'Component/MyAccountPrescriptionForm/images/icon_sms@2x_white.png';
import icon_loading from 'Component/MyAccountPrescriptionForm/images/saving@2x.png';
import codigo_acesso from './images/codigo_acesso.jpg';
import codigo_opcao from './images/codigo_opcao.jpg';
import numero_receita from './images/numero_receita.jpg';

/** @namespace Component/MyAccountPrescriptionForm/Component */
export class MyAccountPrescriptionForm extends FieldForm {
    static propTypes = {
        onInputChange: PropTypes.func.isRequired,
        onInfoPopup: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            changeCode: false,
            changeSms: true,
            smsContent: '',
            isLoading: false,
            isSelected: true,
            isEdit: false,
            isValid: null
        };

        this.copyContent = this.copyContent.bind(this);
        this.setEdit = this.setEdit.bind(this);
    }

    changeSms() {
        this.setState({ changeSms: !this.state.changeSms,changeCode: this.state.changeSms,isSelected: false });
    }

    changeCode() {
        this.setState({ changeCode: !this.state.changeCode,changeSms: this.state.changeCode,isEdit: false });
    }

    onFormSuccess = (fields) => {
        const { isValid } = this.state;
        let copySms            = this.state.changeSms;
        let manualInsert       = this.state.changeCode;
        let smsContent         = '';
        let prescriptionNumber = '';
        let accessCode         = '';
        let optionCode         = '';
        if (copySms) {
            if (!isValid || (this.state.smsContent == '')) {
                this.setState({ isValid: false });
                return;
            }
            smsContent = this.state.smsContent;
        } else {
            prescriptionNumber = fields.prescriptionNumber;
            accessCode         = fields.accessCode;
            optionCode         = fields.optionCode;
        }
        this.setState({isLoading: true});
        const { showErrorNotification, showSuccessNotification, onPrescriptionAddSuccess } = this.props;
        const query = MyAccountQuery.storePrescription(copySms, manualInsert, smsContent, prescriptionNumber, accessCode, optionCode);
        fetchQuery(query).then(
            ({ addPrescription }) => {
                this.setState({isLoading: false});
                if(addPrescription.status && addPrescription.status == 200) {
                    showSuccessNotification(addPrescription.message);
                    onPrescriptionAddSuccess(addPrescription.entity_id);
                } else {
                    showErrorNotification([{message: addPrescription.message}]);
                }
            },
            () => {
                this.setState({isLoading: false});
                showErrorNotification([{message: addPrescription.message}])
            }
        )
    };

    setEdit() {
        this.setState( { isEdit: !this.state.isEdit } );
    }

    renderTextBlock() {
        const { isEdit, isValid } = this.state;

        if(isEdit){
            return(
                <>
                    <textarea name="message" cols="40" rows="5" validation={ ['notEmpty'] }  className={ (isValid == false) ? 'error-sms' : '' } onChange={ this.setSmsContent }></textarea>
                </>
            );

        } else {
            return(
                    <textarea name="message" placeholder={ __("Copy and paste the SMS text of your prescription here") } className={ (isValid == false) ? 'error-sms' : '' } cols="40" rows="5" onClick={ this.setEdit }></textarea>
                    // <FieldTextarea name={ "message" } placeholder={ __("Copy and paste the SMS text of your prescription here") } cols={ "40" } rows={ "5" } onClick={ this.setEdit } validation={ ['notEmpty'] } />
            );
        }
    }

    copyContent() {
        this.setState( { isSelected: !this.state.isSelected });
    }

    setSmsContent = (e) => {
        this.setState({ smsContent: e.target.value, isValid: true });
        /*if (e.target.value != "") {
            this.setState( { isSelected: true });
        } else {
            this.setState( { isSelected: false });
        }*/
    }

    get fieldMap() {
        const { onInfoPopup, onAccessPopup, onOptionPopup } = this.props;

        return {
            prescriptionNumber: {
                label: __('Prescription N.º'),
                infoTitle: __('Click here to get help to find your Recipe Number.'),
                validation: ['notEmpty','prescription'],
                isInfo: true,
                maxlength: 19,
                placeholder: __('Insert %1 numbers').replace('%1', '19'),
                handleInfoClick: () => onInfoPopup()
            },
            accessCode: {
                type: 'numeric',
                label: __('Access Code'),
                infoTitle: __('Click here to get help to find your Access Code and Dispensing of your Recipe.'),
                validation: ['notEmpty','access_code'],
                isInfo: true,
                maxlength: 6,
                placeholder: __('Insert %1 numbers').replace('%1', '6'),
                handleInfoClick: () => onAccessPopup()
            },
            optionCode: {
                type: 'numeric',
                label: __('Option Code'),
                infoTitle: __('Click here to get help to find your Recipe Option Code.'),
                validation: ['notEmpty','option_code'],
                isInfo: true,
                maxlength: 4,
                placeholder: __('Insert %1 numbers').replace('%1', '4'),
                handleInfoClick: () => onOptionPopup()
            }
        };
    }

    renderActions() {
        const { isSelected } = this.state;

        return (
            <button block="Button" mix={ { block: 'MyAccountPrescriptionForm', elem: 'Button' } }>
                { __('Accept Prescription') }
            </button>
        );

        /*return (
            <>
            { isSelected &&
                                <button block="Button" mix={ { block: 'MyAccountPrescriptionForm', elem: 'Button' } }>
                                    { __('Accept Prescription') }
                                </button>
            }
            { !isSelected &&
                                <button block="Button" disabled mix={ { block: 'MyAccountPrescriptionForm', elem: 'Button' } }>
                                    { __('Accept Prescription') }
                                </button>
            }
            </>

        );*/
    }

    renderActionsSms() {
        const { isSelected, isValid } = this.state;

        return (
            <>
                <button block="Button" mix={ { block: 'MyAccountPrescriptionForm', elem: 'Button' } } onClick={ () => this.onFormSuccess('') }>
                    { __('Accept Prescription') }
                </button>
            </>

        );
        /*return (
            <button block="Button" mix={ { block: 'MyAccountPrescriptionForm', elem: 'Button' } } onClick={ () => this.onFormSuccess('') }>
                { __('Accept Prescription') }
            </button>
        );*/
    }

    renderPopup() {
        const { showEditForm } = this.props;
        return(
            <Popup
              id={ "MyAccountPrescriptionFormPopup" }
              clickOutside={ false }
              closedOn={ true }
              onClose= { showEditForm }
              mix={ { block: 'MyAccountPrescriptionFormPopup' } }
            >
               { this.renderImageElement() }
                <p>{ __("Our pharmacists are analyzing your prescription.") }</p>
            </Popup>
        );
    }

    renderImageElement() {
        return (
            <img
              src={ icon_loading }
              mix={ {
                  block: 'MyAccountPrescriptionFormPopup',
                  elem: 'Picture'
              } }
              ratio="custom"
            />
        );
    }

    renderFieldPopups() {
        return(
            <>
                <Popup
                    id={ "FieldInfoPopup" }
                    clickOutside={ true }
                    mix={ { block: 'FieldInfoPopup' } }
                >
                    <Image
                        src={ numero_receita }
                    />
                </Popup>
                <Popup
                    id={ "FieldAccessPopup" }
                    clickOutside={ true }
                    mix={ { block: 'FieldAccessPopup' } }
                >
                    <Image
                        src={ codigo_acesso }
                    />
                </Popup>
                <Popup
                    id={ "FieldOptionPopup" }
                    clickOutside={ true }
                    mix={ { block: 'FieldOptionPopup' } }
                >
                    <Image
                        src={ codigo_opcao }
                    />
                </Popup>
            </>
        );
    }

    render() {
        const { changeCode, changeSms, isLoading, isValid } = this.state;
        const { device } = this.props;

        if(changeCode){

            return (
                <>
                    <Loader isLoading={ isLoading } />
                    <p>{ __("Enter the details for accessing your Prescription via:") }</p>
                    <div block="MyAccountPrescriptionForm" elem="TabList">
                        <div block="MyAccountPrescriptionForm" elem="Select"  onClick={ () =>  this.changeSms() } mods= { { isVisible: changeSms } }>
                            { !device.isMobile && <input
                                id="has-sms"
                                name="has-sms"
                                block="MyAccountPrescriptionForm"
                                elem="Input"
                                type="checkbox"
                                value={ changeSms }
                                checked={ changeSms }
                            /> }
                            <div block="MyAccountPrescriptionForm" elem="IconImg">
                            <img
                                block="MyAccountPrescriptionForm"
                                src={ !device.isMobile || changeSms == false ? changeSms == true ? icon_sms_active:icon_sms  || '':icon_sms_mobile || '' }
                                width="30px"
                                height="30px"
                            />
                            </div>
                            <label><strong>{ __('Copy Sms') }</strong></label>
                        </div>
                        <div block="MyAccountPrescriptionForm" elem="Select"  onClick={ () =>  this.changeCode() } mods= { { isVisible: changeCode } }>
                        { !device.isMobile && <input
                                id="has-codes"
                                name="has-codes"
                                block="MyAccountPrescriptionForm"
                                elem="Input"
                                type="checkbox"
                                value={ changeCode }
                                checked={ changeCode }
                            /> }
                            <div block="MyAccountPrescriptionForm" elem="IconImg">
                            <img
                                block="MyAccountPrescriptionForm"
                                src={ !device.isMobile || changeCode == false ? changeCode == true ? icon_insert_code_active:icon_insert_code || '':icon_insert_code_mobile || '' }
                                width="30px"
                                height="30px"
                            />
                            </div>
                           <label><strong>{ __('Insert Codes') }</strong></label>
                        </div>
                    </div>
                    <Form
                        onSubmitSuccess={ this.onFormSuccess }
                        mix={ { block: 'FieldForm' } }
                    >
                        { this.renderFields() }
                        { this.renderActions() }
                    </Form>
                    { this.renderPopup() }
                    { this.renderFieldPopups() }
                </>
            );
        } else {

            return(
                <>
                    <Loader isLoading={ isLoading } />
                    <p>{ __("Enter the details for accessing your Prescription via:") }</p>
                    <div block="MyAccountPrescriptionForm" elem="TabList">
                        <div block="MyAccountPrescriptionForm" elem="Select"  onClick={ () =>  this.changeSms() } mods= { { isVisible: changeSms } }>
                        { !device.isMobile && <input
                                id="has-sms"
                                name="has-sms"
                                block="MyAccountPrescriptionForm"
                                elem="Input"
                                type="checkbox"
                                value={ changeSms }
                                checked={ changeSms }
                            /> }
                            <div block="MyAccountPrescriptionForm" elem="IconImg">
                            <img
                                block="MyAccountPrescriptionForm"
                                src={ !device.isMobile || changeSms == false ? changeSms == true ? icon_sms_active:icon_sms  || '':icon_sms_mobile || '' }
                                width="30px"
                                height="30px"
                            />
                            </div>
                            <label><strong>{ __('Copy Sms') }</strong></label>
                        </div>
                        <div block="MyAccountPrescriptionForm" elem="Select"  onClick={ () =>  this.changeCode() } mods= { { isVisible: changeCode } }>
                            { !device.isMobile && <input
                                id="has-codes"
                                name="has-codes"
                                block="MyAccountPrescriptionForm"
                                elem="Input"
                                type="checkbox"
                                value={ changeCode }
                                checked={ changeCode }
                            /> }
                            <div block="MyAccountPrescriptionForm" elem="IconImg">
                            <img
                                block="MyAccountPrescriptionForm"
                                src={ !device.isMobile || changeCode == false ? changeCode == true ? icon_insert_code_active:icon_insert_code || '':icon_insert_code_mobile || '' }
                                width="30px"
                                height="30px"
                            />
                            </div>
                            <label><strong>{ __('Insert Codes') }</strong></label>
                        </div>
                    </div>
                    <div block="MyAccountPrescriptionForm" elem="TextArea">
                        { this.renderTextBlock() }
                        <div block="MyAccountPrescriptionForm" >
                            <span hidden={ (isValid == false) ? false : true } class="validation-error-sms">{ __("This field is required!") }</span>
                        </div>
                    </div>
                    { this.renderActionsSms() }
                    { this.renderPopup() }
                    { this.renderFieldPopups() }
                </>
            );
        }
    }
}

export default MyAccountPrescriptionForm;
