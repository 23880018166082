import { PureComponent } from 'react';
import { customerType } from 'Type/Account';

import PropTypes from 'prop-types';
import FieldForm from 'Component/FieldForm';
import Form from 'Component/Form';
import Link from 'Component/Link';
import Field from 'Component/Field';
import CmsBlock from 'Component/CmsBlock';

import './HealthCardWithoutLoginForm.style';
import '../MyAccountCreateAccount/MyAccountCreateAccount.style'
import '../HealthCard/HealthCard.style'

export class HealthCardWithoutLoginForm extends FieldForm {
    static propTypes = {
        customer: customerType.isRequired,
        handleHealthCard: PropTypes.func.isRequired,
    };

    state = {
        doc_type_maxlength: 10,
        doc_type_validation: 'passport',
        doc_type_minlength: 4,
        changed: false,
        isCheckedConditions: false,
        isCheckedTerms: false,
        isCheckedCreateAccount: true,
        seeAllTerms: false
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.history.replaceState(null, null, "aderir-sauda-s3");
        }
    }

    scrollToElement(e) {
        if (e.target) {
            window.scrollTo(0, (e.target.offsetTop + 82)); // header height: 82px;
        }
    }

    toggleActive = () => {
        const { seeAllTerms } = this.state;
        this.setState({ seeAllTerms: !seeAllTerms });
    }

    onFormSuccess = (fields) => {
        const { handleHealthCard,phoneNumber } = this.props;
        fields['phone'] = phoneNumber;
        handleHealthCard(fields);
    };

    fieldMap() {
        const { customer, baseLinkUrl, showPopup, phoneNumber } = this.props;
        const { doc_type_maxlength, doc_type_validation, doc_type_minlength, changed } = this.state;

        let shippingAddress = '';
        let city = '';
        let postCode = '';
        let conditions = '';

        if(customer && customer.doc_type == 1 && !changed) {
            this.setState({
                doc_type_maxlength: 8,
                doc_type_validation: 'cc',
                doc_type_minlength: 7
            });
        }

        if (customer && customer.addresses && customer.addresses.length) {
            const address = customer.addresses.filter(address => { return address.default_shipping });

            if(address.length) {
                shippingAddress = address[0].street[0];
                city = address[0].city;
                postCode = address[0].postcode;
            }
        }
        conditions =
        <>
            <a block="HealthCard" elem="InfoPhone" onClick= { showPopup }>{__('general conditions.')}</a>
        </>;

        let dateNow = new Date(Date.now());
        let dataMax = ((dateNow.getUTCFullYear()-18) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;
        let dataMin = ((dateNow.getUTCFullYear()-120) + "-" + this.addZero(dateNow.getMonth() + 1) + "-" + this.addZero(dateNow.getDate() )) ;

        return [
            [
                "fullname",
                {
                    type: 'text',
                    label: __('Full name'),
                    value: customer.name ? customer.name : '',
                    maxlength: 100,
                    validation: ['notEmpty'],
                    full_line: true
                }
            ],
            [
                "email",
                {
                    type: 'email',
                    label: __('E-mail address'),
                    value: customer.email ? customer.email : '',
                    validation: ['notEmpty'],
                    full_line: true
                }
            ],
            [
                "date_of_birth",
                {
                    type: 'date',
                    label: __('Date of Birth'),
                    max: dataMax,
                    min: dataMin,
                    validation: ['notEmpty', 'date_adult', 'min_date_validation'],
                    value: customer ? customer.date_of_birth : '',
                    full_line: true
                }
            ],
            [
                "doc_type",
                {
                    type: 'select',
                    label: __('Document ID'),
                    validation: ['notEmpty'],
                    value: customer ? customer.doc_type : '',
                    placeholder: __('Select a option'),
                    selectOptions: [
                        {
                            id: 1,
                            value: 1,
                            label: __('Citizen Card')
                        },
                        {
                            id: 2,
                            value: 2,
                            label: __('Passport')
                        }
                    ],
                    onChange: this.onDocTypeChangeisCreateAccount
                }
            ],
            [
                "doc_number",
                {
                    label: __('Identification Number'),
                    validation: ['notEmpty', doc_type_validation],
                    maxlength: doc_type_maxlength,
                    value: customer ? customer.doc_number : '',
                }
            ],
            [
                "gender",
                {
                    type: 'select',
                    label: __('Gender'),
                    validation: ['notEmpty'],
                    value: customer ? customer.gender : '',
                    placeholder: __('Select a option'),
                    selectOptions: [
                        {
                            id: 1,
                            value: 1,
                            label: __('Male')
                        },
                        {
                            id: 2,
                            value: 2,
                            label: __('Female')
                        },
                        {
                            id: 3,
                            value: 3,
                            label: __('Undefined')
                        }
                    ]
                }
            ],
            [
                "address",
                {
                    label: __('Address'),
                    validation: ['notEmpty'],
                    full_width: true,
                    value: shippingAddress,
                    maxlength: 250
                }
            ],
            [
                "postcode",
                {
                    type: 'postcode',
                    label: __('Zip/Postal code'),
                    validation: ['notEmpty', 'postcode'],
                    placeholder: '9999-999',
                    value: postCode,
                    maxlength: 8
                }
            ],
            [
                "locality",
                {
                    label: __('City'),
                    validation: ['notEmpty'],
                    value: city,
                    maxlength: 50
                }
            ]
        ];
    }

    addZero(numero){
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    onDocTypeChange = (doc_type) => {
        this.setState({
            doc_type_maxlength: 10,
            doc_type_validation: 'passport',
            doc_type_minlength: 4,
            changed: true
        });

        if(doc_type == 1){
            this.setState({
                doc_type_maxlength: 8,
                doc_type_validation: 'cc',
                doc_type_minlength: 7
            });
        }
    };

    onCheckboxConditonsChange = () => {
        this.setState({
            isCheckedConditions: !this.state.isCheckedConditions
        });
    };

    onCheckboxTermsChange = () => {
        this.setState({
            isCheckedTerms: !this.state.isCheckedTerms
        });
    };

    onCheckboxCreateAccountChange = () => {
        this.setState({
            isCheckedCreateAccount: !this.state.isCheckedCreateAccount
        });
    };

    renderSubmitButton() {
        return (
            <>
                <button
                    type="submit"
                    block="Button"
                    mix={ { block: 'HealthCardWithoutLoginForm', elem: 'Button' } }
                    formNoValidate
                >
                    Criar
                </button>
            </>
        );
    }

    renderField = (item) => {
        return (
            <Field { ...this.getDefaultValues(item) }/>
        );
    }

    renderCreateHealthCardFields() {
        const fields = this.fieldMap();
        return (
            fields.map(this.renderField)
        );
    }

    renderCreateAccountFields() {
        const {showPopup} = this.props;
        const { seeAllTerms, isCheckedCreateAccount,isCheckedTerms,isCheckedConditions } = this.state;

        let conditionsLink = <Link to="condicoes-gerais" target="_new">
                { __('Terms and Conditions') }
            </Link>;
        let conditions =
            <>
                <a block="HealthCard" elem="InfoPhone" onClick= { showPopup }>{__('general conditions.')}</a>
            </>;

        return (
           <>
                <Field
                    type="checkbox"
                    value={isCheckedCreateAccount}
                    checked={isCheckedCreateAccount}
                    label="Criar conta no site Farmácias Portuguesas"
                    id="create_account"
                    name="create_account"
                    full_line= {true}
                    onChange= {this.onCheckboxCreateAccountChange}
                />
                <Field
                    type="password"
                    label={ __('Password') }
                    id="password"
                    name="password"
                    autocomplete="new-password"
                    validation={ isCheckedCreateAccount ? ['notEmpty', 'password'] : '' }
                    onFocus={ this.scrollToElement }
                    isDisabled= {!isCheckedCreateAccount}
                />
                <Field
                    type="password"
                    label={ __('Confirm password') }
                    id="confirm_password"
                    name="confirm_password"
                    autocomplete="new-password"
                    validation={ isCheckedCreateAccount ? ['notEmpty', 'password'] : '' }
                    onFocus={ this.scrollToElement }
                    isDisabled= {!isCheckedCreateAccount}
                />
                <section block="MyAccountCreateAccount" elem="Info">
                    <div block="MyAccountCreateAccount" elem="Intro">
                        <CmsBlock identifier={ "create_account_terms" } />
                    </div>
                    <div block="MyAccountCreateAccount" elem="SeeMore" mods={ { Active: seeAllTerms } } onClick={ this.toggleActive }>
                        { __('Click to see more') }
                    </div>
                    <div block="MyAccountCreateAccount" elem="ShowMore">
                        <CmsBlock identifier={ "create_account_terms_details" } />
                    </div>
                </section>
                <Field
                    type="checkbox"
                    value={isCheckedTerms}
                    label={ <> { __('I am aware of and consent to the ') } { conditionsLink } </> }
                    id="accept_terms"
                    name="accept_terms"
                    validation={ isCheckedCreateAccount ? ['notEmpty', 'checkbox_required'] : []}
                    full_line= {true}
                    onChange= {this.onCheckboxTermsChange}
                />
                <Field
                    type="checkbox"
                    value={isCheckedConditions}
                    label={ __('I agree with with the ') }
                    link={conditions}
                    id="conditions"
                    name="conditions"
                    validation={ ['notEmpty', 'checkbox_required'] }
                    full_line= {true}
                    onChange= {this.onCheckboxConditonsChange}
                />
            </>
        );
    }

    renderForm() {
        return (
            <Form
              key="create-sauda-without-login"
              mix={ { block: 'FieldForm' } }
              onSubmitSuccess={ this.onFormSuccess }
             /*onSubmitError={ onCreateAccountAttempt }*/
            >
                <div block="FieldForm" elem="Fields">
                    { this.renderCreateHealthCardFields() }
                    { this.renderCreateAccountFields() }
                </div>
                { this.renderSubmitButton() }
            </Form>
        );
    }

    render() {
        return (
            <>
                { this.renderForm() }
            </>
        );
    }

}

export default HealthCardWithoutLoginForm;
