import {
    MyAccount as SourceMyAccount,
} from 'SourceRoute/MyAccount/MyAccount.component';

import ContentWrapper from 'Component/ContentWrapper';
import MyAccountTabList from 'Component/MyAccountTabList';

import './MyAccount.style.override.scss'
import PropTypes from 'prop-types';
import MyAccountAddressBook from 'Component/MyAccountAddressBook';
import MyAccountDashboard from 'Component/MyAccountDashboard';
import MyAccountMyOrders from 'Component/MyAccountMyOrders';
import MyAccountMyWishlist from 'Component/MyAccountMyWishlist';
import MyAccountNewsletterSubscription from 'Component/MyAccountNewsletterSubscription';
import MyAccountNotification from 'Component/MyAccountNotification';
import MyAccountHelpAndContacts from 'Component/MyAccountHelpAndContacts';
import HealthCard from 'Component/HealthCard';
import MyPharmacy from 'Component/MyPharmacy';
import PrescriptionWallet from 'Component/PrescriptionWallet';

import {
    activeTabType,
    ADDRESS_BOOK,
    DASHBOARD,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION,
    NOTIFICATION,
    PHARMACY,
    HEALTH_CARD,
    PRESCRIPTION,
    CONTACTS,
    tabMapType
} from 'Type/Account';


export class MyAccount extends SourceMyAccount {


    static propTypes = {
        activeTab: activeTabType.isRequired,
        tabMap: tabMapType.isRequired,
        changeActiveTab: PropTypes.func.isRequired,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isEditingActive: PropTypes.bool.isRequired
    };

    renderMap = {
        [DASHBOARD]: MyAccountDashboard,
        [MY_ORDERS]: MyAccountMyOrders,
        [MY_WISHLIST]: MyAccountMyWishlist,
        [ADDRESS_BOOK]: MyAccountAddressBook,
        [NEWSLETTER_SUBSCRIPTION]: MyAccountNewsletterSubscription,
        [NOTIFICATION]: MyAccountNotification,
        [HEALTH_CARD]: HealthCard,
        [CONTACTS]: MyAccountHelpAndContacts,
        [PHARMACY]: MyPharmacy,
        [PRESCRIPTION]: PrescriptionWallet
    };

    renderContent() {
        const {
            activeTab,
            tabMap,
            changeActiveTab,
            isSignedIn,
            onSignOut,
            isEditingActive
        } = this.props;


        if (!isSignedIn) {
            return this.renderLoginOverlay();
        }

        const TabContent = this.renderMap[activeTab];
        const { name } = tabMap[activeTab];

        return (
            <ContentWrapper
              label={ __('My Account page') }
              wrapperMix={ { block: 'MyAccount', elem: 'Wrapper' } }
            >
                <MyAccountTabList
                  tabMap={ tabMap }
                  activeTab={ activeTab }
                  changeActiveTab={ changeActiveTab }
                  onSignOut={ onSignOut }
                />
                <div block="MyAccount" elem="TabContent">
                    {/* <h1 block="MyAccount" elem="Heading">{ name }</h1> */}
                    <TabContent isEditingActive={ isEditingActive } />
                </div>
            </ContentWrapper>
        );
    }

};

export default MyAccount;
