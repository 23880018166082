/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';
import Image from 'Component/Image';
import { TotalsType } from 'Type/MiniCart';
import { formatPrice } from 'Util/Price';
import arrowDown from 'Route/StorePage/Images/arrowdown.png';
import arrowUp from 'Route/StorePage/Images/arrowUp.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

import './CartFloating.style';

/** @namespace Route/CartPage/Component */
export class CartFloating extends PureComponent {
    static propTypes = {
        totals: TotalsType.isRequired,
        onCheckoutButtonClick: PropTypes.func.isRequired,
        hasOutOfStockProductsInCart: PropTypes.bool,
        cartSubTotal: PropTypes.number.isRequired,
        cartSubTotalExlTax: PropTypes.number.isRequired,
        cartOrderTotalExlTax: PropTypes.number.isRequired,
        getAdvanceButtonState: PropTypes.func.isRequired
    };

    static defaultProps = {
        hasOutOfStockProductsInCart: false
    };

    state = {
        arrowUp: false,
        saudaCardDiscount: false,
        saudaCardUsedPoints: 0,
        shippingCost: 0,
        totalPoints: 0,
        newSubTotal: 0
    };

    changeArrow = () => {
        this.setState({
            arrowUp: !this.state.arrowUp
        })
    }

    getPoints() {
        const { cartData } = this.props;
        if (Object.hasOwnProperty.call(cartData, 'items')) {
            const normalizedItemsProduct = cartData.items.map((item) => {
                const normalizedItem = item;
                let points = null;

                try {
                    points = normalizedItem.product.attributes.points.attribute_value;
                } catch(err) {
                    return null;
                }

                return points;
            });

            return normalizedItemsProduct;
        }
    }

    renderPriceLine(price) {
        const { totals: { quote_currency_code } } = this.props;

        price = price ? price.toFixed(2) : 0;

        return formatPrice(price, quote_currency_code);
    }

    renderSubTotal() {
        //const { cartSubTotal = 0 } = this.props;
        const { totals: { subtotal } } = this.props;
        const { newSubTotal } = this.state;

        let newSTotal = subtotal - parseFloat(newSubTotal);

        return (
            <>
                <dt block="CheckoutSauda" elem="TotalDetails-Subtotal">
                    <strong>{ __('Subtotal:') }</strong>
                </dt>
                <dd>
                    <strong>{ this.renderPriceLine(newSTotal) }</strong>
                    <strong>{ this.renderSubTotalExlTax() }</strong>
                </dd>
            </>
        );
    }

    renderSubTotalExlTax() {
        const { cartSubTotalExlTax } = this.props;

        if (!cartSubTotalExlTax) {
            return null;
        }

        return (
            <span>
                { `${ __('Excl. tax:') } ${ this.renderPriceLine(cartSubTotalExlTax) }` }
            </span>
        );
    }

    renderTaxFullSummary() {
        const {
            totals: {
                cart_display_config: {
                    display_full_tax_summary
                } = {},
                applied_taxes
            }
        } = this.props;

        if (!display_full_tax_summary || !applied_taxes.length) {
            return null;
        }

        return applied_taxes
            .flatMap(({ rates }) => rates)
            .map(({ percent, title }) => (
                <div block="CartPage" elem="TaxRate">
                    { `${title} (${percent}%)` }
                </div>
            ));
    }

    renderTax() {
        const {
            totals: {
                tax_amount = 0,
                cart_display_config: {
                    display_zero_tax_subtotal
                } = {}
            }
        } = this.props;

        if (!tax_amount && !display_zero_tax_subtotal) {
            return null;
        }

        return (
            <>
                <dt>
                    { __('Tax:') }
                    { this.renderTaxFullSummary() }
                </dt>
                <dd>{ this.renderPriceLine(tax_amount) }</dd>
            </>
        );
    }

     renderTotalDetails(isMobile = false) {
        return (
            <dl
              block="CartFloating"
              elem="TotalDetails"
              aria-label={ __('Order total details') }
              mods={ { isMobile } }
            >
                { this.renderSubTotal() }
                { this.renderRecipe() }
                { this.renderDiscount() }
                { this.renderSaudaDiscount() }
                { this.renderPoints() }
                { this.renderDelivery() }
            </dl>
        );
    }

    renderOrderTotalExlTax() {
        const { cartOrderTotalExlTax } = this.props;

        if (!cartOrderTotalExlTax) {
            return null;
        }

        return (
            <span>
                { `${ __('Excl. tax:') } ${ this.renderPriceLine(cartOrderTotalExlTax) }` }
            </span>
        );
    }

    renderTotal() {
        const {
            totals: {
                subtotal_with_discount = 0,
                tax_amount = 0,
                is_wallet,
                grand_total,
                subtotal,
                base_grand_total,
                discount_amount
            },
            totals,
            cartData,
            isPointsActive,
            isOnShipping,
            selectedPaymentMethod
        } = this.props;
        let formattedPrice = null;
        const { shippingCost, newSubTotal } = this.state;
        if(window.location.pathname.includes('cart') || window.location.pathname.includes('checkout-sauda') || window.location.pathname.includes('shipping')) {
            //let total = subtotal_with_discount - parseFloat(newSubTotal);
            formattedPrice = <> { this.renderPriceLine(subtotal_with_discount) } </>
        } else {
            //let total = grand_total - parseFloat(newSubTotal);
            formattedPrice = <> { this.renderPriceLine(grand_total) } </>
        }

        if(isOnShipping && selectedPaymentMethod && selectedPaymentMethod.amount) {
            /** Calculate price here to avoid overprice when selecting shipping */
            let priceCalc = (subtotal + selectedPaymentMethod.amount) + discount_amount - newSubTotal;
            if(priceCalc < 0) {
                priceCalc = 0;
            }
            formattedPrice = <> { this.renderPriceLine(priceCalc) } </>
        }

        //if ( cartData.shipping_amount != null && (shippingCost != 0)) {
            // formattedPrice = <> { this.renderPriceLine(grand_total) } </>
        /*} else {
            formattedPrice = <> { this.renderPriceLine(subtotal_with_discount + tax_amount - shippingCost) } </>
        }*/

        /*if(isPointsActive){
            formattedPrice = <> { this.renderPriceLine(subtotal_with_discount + tax_amount) + __("+") + this.getPoints() + "pts" } </>
        } else {
            formattedPrice = <> { this.renderPriceLine(subtotal_with_discount + tax_amount) } </>
        }*/

        //formattedPrice = <> { this.renderPriceLine(subtotal_with_discount + tax_amount) } </>

        return (
            <>
                <dl block="CartFloating" elem="Total" aria-label="Complete order total">
                    <dt>{ __('Total') }</dt>
                    <dd>
                        { is_wallet && <FontAwesomeIcon className="CartFloating-AlertIcon" icon={ faExclamationCircle } /> }
                        { formattedPrice }
                        { this.renderTotalPoints() }
                        { this.renderOrderTotalExlTax() }
                    </dd>
                </dl>
                { this.renderWalletMessage() }
             </>
        );
    }

    renderWalletMessage() {
        const {
            totals: {
                is_wallet
            }
        } = this.props;

        if(is_wallet) {
            return (
                <p block="CartFloating" elem="Alert">
                    <FontAwesomeIcon className="CartFloating-AlertIcon" icon={ faExclamationCircle } />
                    <CmsBlock identifier={ "cart_wallet_notice" } />
                </p>
            );
        }
    }

    renderTotalPoints() {
        const { saudaCardDiscount, saudaCardUsedPoints } = this.state;
        const { totals: { items }, favStoreObj: { sauda } } = this.props;

        if(!sauda) {
            return (<></>);
        }

        let points = 0;

        if (items && items.length > 0) {
            items.forEach(item => {
                if (item.buy_with_points) {
                    points = points + parseInt(item.product_points);
                }
            });
        }

        /*if (saudaCardDiscount) {
            points = points + parseInt(saudaCardUsedPoints);
            return (
                <span> + { points } pts</span>
            );
        } else {
            if (points != 0) {
                return (
                    <span> + { points } pts</span>
                );
            }
        }*/

        if (points != 0) {
            return (
                <span> + { points } pts</span>
            );
        }
    }

    renderDelivery() {
        const { cartData, submit, checkoutTotals, freeDelivery, isOnShipping, selectedPaymentMethod } = this.props;

        let shippingCost = 0;

        /*if((cartData && !cartData.shipping_amount) && (checkoutTotals && checkoutTotals.shipping_amount)){
            shippingCost = checkoutTotals.shipping_amount;
        } else {
            shippingCost = cartData.shipping_amount;
        }*/

        shippingCost = cartData.shipping_amount;

        if(isOnShipping && selectedPaymentMethod && selectedPaymentMethod.amount) {
            shippingCost = selectedPaymentMethod.amount;
        }

        if (submit) {
            this.setState({ shippingCost: cartData.shipping_amount});

            /*if(window.location.pathname.includes('shipping')){
                return (
                    <>
                        <dt>
                            { __('Delivery ') }
                        </dt>
                        <p>{ __('Calculated in the next steps') }</p>
                    </>
                );
            } else {*/
                if (freeDelivery) {
                    return (
                        <>
                            <dt>
                                { __('Delivery ') }
                            </dt>
                            <dd>{ __('Free') }</dd>
                        </>
                    );
                } else {
                    if(shippingCost>0){
                        return (
                            <>
                                <dt>
                                    { __('Delivery ') }
                                </dt>
                                <dd>{ `${this.renderPriceLine(Math.abs(shippingCost))}` }</dd>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <dt>
                                    { __('Delivery ') }
                                </dt>
                                <dd>{ __('Free') }</dd>
                            </>
                        );
                    }
                }
            //}
        } else {
            this.setState({ shippingCost: 0});
            this.renderTotal;
            return (
                <>
                    <dt>
                        { __('Delivery ') }
                    </dt>
                    <p>{ __('Calculated in the next steps') }</p>
                </>
            );
        }
    }

    renderRecipe() {
        const { totals: { quote_currency_code } } = this.props;

        return (
            <>
                <dt>{ __('Medical Perscription:') }</dt>
                <dd>
                    { formatPrice(0, quote_currency_code) }
                </dd>
            </>
        );
    }

    renderButtons() {
        if(window.location.pathname.includes('checkout')){
            return (
                <div block="CartFloating" elem="CheckoutButtons">
                    { this.renderSecureCheckoutButton() }
                </div>
            );
        } else {
            return (
                <div block="CartFloating" elem="CheckoutButtons">
                    { this.renderSecureCheckoutButton() }
                    <Link
                    block="CartFloating"
                    elem="ContinueShopping"
                    to="/"
                    >
                        { __('Continue shopping') }
                    </Link>
                </div>
            );
        }
    }

    renderTotals() {
        const { getAdvanceButtonState, customer: { favourite_pharmacy_code } } = this.props;
        getAdvanceButtonState();

        let totalsRender = <></>;
        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            totalsRender = <>
                { this.renderTotalDetails() }
                { this.renderTotal() }
            </>;
        }

        return (
            <>
                <article
                block="CartFloating"
                elem="Summary"
                mix={ { block: 'FixedElement', elem: 'Bottom' } }
                >
                    { totalsRender }
                </article>
                { this.renderButtons() }
            </>
        );
    }

    renderDiscount() {
        const {
            totals: {
                applied_rule_ids,
                coupon_code,
                discount_amount
            }
        } = this.props;

        if (discount_amount != 0 && discount_amount != null) {
            return (
                <>
                    <dt>
                        { __('Coupon/Discount') }
                    </dt>
                    <dd>{ `-${this.renderPriceLine(Math.abs(discount_amount))}` }</dd>
                </>
            );
        }

        if (!applied_rule_ids) {
            return null;
        }

        if (!coupon_code) {
            return (
                <>
                    <dt>
                        { __('Discount: ') }
                    </dt>
                    <dd>{ `-${this.renderPriceLine(Math.abs(discount_amount))}` }</dd>
                </>
            );
        }

        return (
            <>
                <dt>
                    { __('Discount/Coupon ') }
                    <strong block="CartPage" elem="DiscountCoupon">{ coupon_code.toUpperCase() }</strong>
                </dt>
                <dd>{ `-${this.renderPriceLine(Math.abs(discount_amount))}` }</dd>
            </>
        );
    }

    renderSaudaDiscount() {
        const {
            totals: {
                sauda_discount,
                sauda_discount_points
            }
        } = this.props;
        if (sauda_discount) {
            this.setState({ saudaCardDiscount: true });
            this.setState({ saudaCardUsedPoints: sauda_discount_points });
            /*return (
                <>
                    <dt>
                        { __('Coupon/Discount ') }
                    </dt>
                    <dd>{ `-${this.renderPriceLine(Math.abs(sauda_discount))}` }</dd>
                </>
            );*/
        } else {
            this.setState({ saudaCardDiscount: false });
            this.setState({ saudaCardUsedPoints: 0 });
        }
    }


    renderPoints() {
        const {
            totals: {
                items
            }
        } = this.props;

        let total = 0;
        let newsubTotal = 0;

        items.forEach(item => {
            if (item.buy_with_points) {
                total = total + parseInt(item.product_points);
                newsubTotal = newsubTotal + (parseFloat(item.price)*item.qty);
            }
        });

        this.setState({ newSubTotal: newsubTotal });

        return (
            <>
                <dt>
                    { __('Points:') }
                </dt>
                <dd>{ total }</dd>
            </>
        );
    }

    renderPromoContent() {
        const { cart_content: { cart_cms } = {} } = window.contentConfiguration;
        const { free_shipping } = this.props;

        if (cart_cms) {
            return <CmsBlock identifier={ cart_cms } />;
        }

        if (!free_shipping || free_shipping === 'not_available' || free_shipping == 0) {
            return <></>;
        }

        return (
            <figure
                block="CartFloating"
                elem="PromoBlock"
            >
                <figcaption block="CartFloating" elem="PromoText">
                    <span><b>{ __('Free shipping') }</b></span>
                    <span>{ __('on order over %s', free_shipping) }€</span>
                </figcaption>
            </figure>
        );
    }

    renderSecureCheckoutButton(mobile = false) {
        const {
            notReady,
            onCheckoutButtonClick,
            hasOutOfStockProductsInCart,
            showOutStockNotification,
            customer,
            customer: { favourite_pharmacy_code, sauda_card }
        } = this.props;

        let action = onCheckoutButtonClick;
        if (hasOutOfStockProductsInCart) {
            action = showOutStockNotification;
        }

        const { submit, label, checkoutLoading } = this.props;
        let elem = 'CheckoutButton';
        if(mobile) {
            elem = 'TotalMobileNextButton';
        }

        let disabled = true;
        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            disabled = false;
        }

        /*if( customer && sauda_card &&
            !sauda_card.is_active &&
            (sauda_card.status == 'PreCanceledAuto' ||
            sauda_card.status == 'NotDefined' ||
            sauda_card.status == 'PreCanceledBackOffice' ||
            sauda_card.status == 'Blocked')
        ){
            disabled = true;
        }*/

        if(submit) {
            return (
                <button
                    block="CartFloating"
                    elem={ elem }
                    mix={ { block: 'Button' } }
                    form={ submit }
                    type="submit"
                    disabled={ checkoutLoading || notReady }
                >
                    { label }
                </button>
            );
        }

        if(window.location.pathname.includes('checkout') && !window.location.pathname.includes('checkout-sauda')){
            return (
                <button
                  block="CartFloating"
                  elem={ elem }
                  mix={ { block: 'Button' } }
                  onClick={ action }
                  disabled={ this.checkIsDisabled() || notReady }
                >
                    { __('Buy') }
                </button>
            );
        } else {
            return (
                <button
                block="CartFloating"
                elem={ elem }
                mix={ { block: 'Button' } }
                onClick={ onCheckoutButtonClick }
                disabled={ disabled ? disabled : notReady }
                >
                    { __('Next') }
                </button>
            );
        }
    }

    checkIsDisabled() {
        const { getAdvanceButtonState, cartData } = this.props;
        if (!cartData.items || cartData.items.length == 0) {
            return true;
        }
        return getAdvanceButtonState();
    }

    renderTotalMobile() {
        const {
            totals: {
                subtotal_with_discount = 0,
                tax_amount = 0,
                grand_total,
                base_grand_total,
                is_wallet
            },
            isPointsActive,
            onCheckoutButtonClick,
            cartData,
            submit,
            customer: { favourite_pharmacy_code }
        } = this.props;

        let mods = {};
        if (favourite_pharmacy_code || localStorage.getItem("guest_pharmacy_code")) {
            let formattedPrice = 0;

            if(window.location.pathname.includes('cart') || window.location.pathname.includes('checkout-sauda') || window.location.pathname.includes('shipping')) {
                formattedPrice = this.renderPriceLine(subtotal_with_discount);
            } else {
                formattedPrice = this.renderPriceLine(grand_total);
            }

            //if ( cartData.shipping_amount != null ) {
                // formattedPrice = this.renderPriceLine(grand_total);
            /*} else {
                formattedPrice = this.renderPriceLine(subtotal_with_discount + tax_amount);
            }*/

            if(submit) {
                mods = {
                    isInCheckout: true
                };
            }

            return(
                <div block="CartFloating" elem="TotalMobileColumns" mods={ mods }>
                    {this.state.arrowUp && <div block="CartFloating" elem="TotalMobileResume">
                        { this.renderPromoContent() }
                        { this.renderTotals() }
                    </div> }
                    <div block="CartFloating" elem="TotalMobile">
                        {!this.state.arrowUp && <button onClick={ this.changeArrow }>
                            <Image
                                src={ arrowUp }
                                width="20px"
                                height="20px"
                            />
                        </button>}
                        {this.state.arrowUp &&<button onClick={ this.changeArrow }>
                            <Image
                                src={ arrowDown }
                                width="20px"
                                height="20px"
                            />
                        </button>}
                        <div block="CartFloating" elem="TotalMobilePrice">
                            <p block="CartFloating" elem="TotalMobilePriceLabel">Total</p>
                            <p block="CartFloating" elem="TotalMobilePriceValue">
                                { is_wallet && <FontAwesomeIcon className="CartFloating-AlertIcon" icon={ faExclamationCircle } /> }
                                { formattedPrice }
                                { this.renderTotalPoints() }
                            </p>
                        </div>

                        { this.renderSecureCheckoutButton(true) }
                    </div>
                </div>
            );
        }

        return(
            <div block="CartFloating" elem="TotalMobileColumns" mods={ mods }>
                <div block="CartFloating" elem="TotalMobile">
                    { this.renderSecureCheckoutButton(true) }
                </div>
            </div>
        );
    }

    renderPromo() {
        const { free_shipping } = this.props;

        if (!free_shipping || free_shipping == 'not_available' || free_shipping == 0) {
            return <></>;
        }

        return (
            <div
              block="CartFloating"
              elem="Promo"
            >
                { this.renderPromoContent() }
            </div>
        );
    }

    render() {
        const { isMobile } = this.props;
        let CartFloat = null;

        if (!isMobile) {
            CartFloat = <>
                            <h4 block="CartFloating" elem="SummaryHeading">{ __('Order Summary') }</h4>
                            { this.renderPromo() }
                            { this.renderTotals() }
                        </>
        } else {
            CartFloat = <>
                           { this.renderTotalMobile() }
                        </>
        }

        return (
                    <>
                        { CartFloat }
                    </>
        );
    }
}

export default CartFloating;
