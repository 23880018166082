import { PureComponent } from 'react';
import { connect } from 'react-redux';
import Html from './Html.component';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { StoreFinderDispatcher } from '../../store/StoreFinder';


export const mapStateToProps = (state) => ({
    favStoreObj: state.StorePageReducer.favStoreObj,
    customer: state.MyAccountReducer.customer
});

export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showPharacySelectorPopup: (payload) => dispatch(showPopup('PharmacySelector', payload)),
    requestStores: () => {
        StoreFinderDispatcher.requestStoreOnlineData(dispatch);
    },
});


export class HtmlContainer extends PureComponent {

    componentDidMount() {
        const { favStoreObj, header_footer } = this.props;
        const hide = favStoreObj.sauda === 0;

        this.showOrHideSaudaBlocks(hide);

        if(header_footer !== undefined){
            this.showOrHideHeaderFooter(header_footer);
        }
    }

    componentDidUpdate(prevProps) {
        const { favStoreObj: prevFavStoreObj,header_footer: prev_header_footer } = prevProps;
        const { favStoreObj, header_footer } = this.props;

        if (favStoreObj.sauda !== prevFavStoreObj) {
            const hide = favStoreObj.sauda === 0;
            this.showOrHideSaudaBlocks(hide);
        }

        if(header_footer !== prev_header_footer){
            this.showOrHideHeaderFooter(header_footer);
        }
    }

    showOrHideHeaderFooter(header_footer){
        const header = document.querySelectorAll('.Header-Wrapper')[0];
        const footer = document.querySelectorAll('footer')[0];
        if(header_footer == 0){
            if(header){
                header.classList.add('hidden');
            }
            if(footer){
                footer.classList.add('hidden');
            }
        }else{
            if(header){
                header.classList.remove('hidden');
            }
            if(footer){
                footer.classList.remove('hidden');
            }
        }
        
    }

    showOrHideSaudaBlocks(hide) {
        const { favStoreObj } = this.props;
        const hideBlocks = document.querySelectorAll('.sauda-only');
        for (let i = 0; i < hideBlocks.length; i++) {
            let node = hideBlocks[i];

            if (hide) {
                node.classList.add('hidden');
            } else {
                node.classList.remove('hidden');
                if(!favStoreObj || !favStoreObj.sauda){
                    let items = node.getElementsByClassName('slick-slide');

                    for(let j=0;j<items.length;j++) {
                        let attrLink = items[j].getElementsByTagName('a');

                        for(let k=0; k<attrLink.length; k++) {
                            if(attrLink[k].getAttribute('href')){
                                attrLink[k].removeAttribute('href');
                                attrLink[k].addEventListener('click', () => this.doSomething(), false);
                            }
                        }
                    }
                }
            }
        }
    }

    doSomething() {
        const { showPharacySelectorPopup, requestStores } = this.props;
        requestStores();
        setTimeout( () => showPharacySelectorPopup(), 300);
    }

    render() {
        return (
            <Html
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HtmlContainer);
