import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { getIndexedProducts } from 'Util/Product';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import PropTypes from 'prop-types';
import history from 'Util/History';

import {
    mapStateToProps as SourceMapStateToProps,
    mapDispatchToProps as SourceMapDispatchToProps,
    NewProductsContainer as SourceNewProductsContainer,
} from 'SourceComponent/NewProducts/NewProducts.container';

export const mapStateToProps = state => ({
    ...SourceMapStateToProps(state),
    customer: state.MyAccountReducer.customer,
    favStoreObj: state.StorePageReducer.favStoreObj
});

export const mapDispatchToProps = dispatch => ({
    ...SourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class NewProductsContainer extends SourceNewProductsContainer {

    componentDidUpdate(prevProps) {
        const { favStoreObj, sort, search, filter, customer } = this.props;
        const { favStoreObj: prevFavStoreObj, sort: prevSort, search: prevSearch, filter: prevFilter } = prevProps;

        const {
            category,
            productsCount,
            cacheLifetime,
            timezone
        } = this.props;
        const {
            category: pCategory,
            productsCount: pProductsCount,
            cacheLifetime: pCacheLifetime,
            timezone: pTimezone
        } = prevProps;

        if (category !== pCategory
            || timezone !== pTimezone
            || productsCount !== pProductsCount
            || cacheLifetime !== pCacheLifetime) {
            this.requestProducts();
        } else {
            if (favStoreObj && prevFavStoreObj) {
                const { pharmacy_code } = favStoreObj;
                const { pharmacy_code: prevPharmacy } = prevFavStoreObj;
                if (pharmacy_code != prevPharmacy) {
                    this.requestProducts();
                }
            }
        }
        if (prevProps.customer != customer) {
            this.requestProducts();
        }

        if(localStorage.getItem('account_confirmed')){
            localStorage.removeItem('account_confirmed');
            window.location.reload();
        }
    }


    requestProducts() {
        const {
            timezone,
            category: categoryUrlPath,
            productsCount: pageSize,
            cacheLifetime,
            showNotification,
            customer
        } = this.props;

        if (!timezone) {
            return;
        }

        const newToDate = this.getRequestDate();

        let pharma = null;
        if(customer && customer.favourite_pharmacy_code) {
            pharma = customer.favourite_pharmacy_code;
        } else {
            if (localStorage.getItem('guest_pharmacy_code')) {
                pharma = localStorage.getItem('guest_pharmacy_code');
            }
        }


        let pricesDate = null;

        if(pharma){
            let dateNow = new Date(Date.now());
            pricesDate = dateNow.getDay()+'-'+dateNow.getMonth()+'-'+dateNow.getFullYear()+'-'+dateNow.getHours()+'-'+dateNow.getMinutes();
        }

        const options = {
            args: {
                filter: {
                    categoryUrlPath,
                    newToDate
                },
                currentPage: 1,
                pageSize,
                pharma,
                pricesDate
            }
        };

        const query = [ProductListQuery.getQuery(options)];
        executeGet(prepareQuery(query), 'NewProducts', cacheLifetime)
            .then(
                /** @namespace Component/NewProducts/Container/executeGetThen */
                ({ products: { items } }) => this.setState({ products: getIndexedProducts(items) })
            )
            .catch(
                /** @namespace Component/NewProducts/Container/executeGetThenCatch */
                (e) => showNotification('error', 'Error fetching NewProducts!', e)
            );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProductsContainer);
