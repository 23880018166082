import {
    EDIT_CUSTOMER as SourceEDIT_CUSTOMER,
    CUSTOMER_POPUP_ID,
    CHANGE_PASSWORD,
} from 'SourceComponent/MyAccountCustomerPopup/MyAccountCustomerPopup.config';

export {
    CUSTOMER_POPUP_ID,
    CHANGE_PASSWORD,
};

//TODO: implement EDIT_CUSTOMER
export const EDIT_CUSTOMER = SourceEDIT_CUSTOMER;
