import {
    CmsBlock as SourceCmsBlock,
} from 'SourceComponent/CmsBlock/CmsBlock.component';

import './CmsBlock.style.override.scss'

export class CmsBlock extends SourceCmsBlock {
    // TODO implement logic
};

export default CmsBlock;
