import {
    MyAccountTabList as SourceMyAccountTabList,
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.component';
import {
    ADDRESS_BOOK,
    DASHBOARD,
    MY_ORDERS,
    NOTIFICATION,
    CONTACTS,
    HEALTH_CARD,
    PHARMACY,
    PRESCRIPTION
} from 'Type/Account';
import MyAccountTabListItem from 'Component/MyAccountTabListItem';
import ExpandableContent from 'Component/ExpandableContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt } from '@fortawesome/pro-solid-svg-icons';
import { faSignOut, faPaste, faCreditCard } from '@fortawesome/pro-light-svg-icons';
let tabMapOrder = {};

import Image from 'Component/Image';
import icon_exit from 'Component/MyAccountOverlay/Images/icon-exit-to-app.svg';
import icon_prescription from 'Component/MyAccountOverlay/Images/icon-prescription.svg';
import icon_healthcard from 'Component/MyAccountOverlay/Images/icon-sauda-card.svg';
import icon_pharmacy from 'Component/MyAccountOverlay/Images/icon-red-cross.svg';


export class MyAccountTabList extends SourceMyAccountTabList {

    renderLogoutTab() {
        const { handleLogout } = this.props;

        return (
            <li
              key="logout"
              block="MyAccountTabListItem"
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ handleLogout }
                  role="link"
                >
                    {/* <FontAwesomeIcon className="MyAccountTabListItem-IconLeft" icon={faSignOut} /> */}
                    <Image
                        src={ icon_exit }
                        width="25px"
                        height="25px"
                        mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                    />
                    <p
                        block="MyAccountTabListItem"
                        elem="title">{ __('Logout') }</p>
                </button>
            </li>
        );
    }

    OrderTabList() {
        tabMapOrder = {
            [HEALTH_CARD]: {
                url: '/healthcard',
                name: __('Health Card')
            },
            [PRESCRIPTION]: {
                url: '/prescription',
                name: __('Prescription Wallet')
            },
            [MY_ORDERS]: {
                url: '/my-orders',
                name: __('My orders')
            },
            [DASHBOARD]: {
                url: '/dashboard',
                name: __('Dashboard')
            },
            [ADDRESS_BOOK]: {
                url: '/address-book',
                name: __('Address book')
            },
            [PHARMACY]: {
                url: '/pharmacy',
                name: __('My Pharmacy')
            },
            /*[NOTIFICATION]: {
                url: '/notifications',
                name: __('Notifications')
            },*/
            [CONTACTS]: {
                url: '/help-and-contacts',
                name: __('HelpAndContacts')
            }
        }
    }

    renderPharmacyTab() {
        return (
            <li
              key="pharmacy"
              block="MyAccountTabListItem"
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick='my-account/pharmacy'
                  role="link"
                >
                    <Image
                        src={ icon_pharmacy }
                        width="20px"
                        height="20px"
                        mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                    />
                    { __('My Pharmacy') }
                </button>
            </li>
        );
    }

    renderHealthCardTab() {
        return (
            <li
              key="healthCard"
              block="MyAccountTabListItem"
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick='my-account/healthcard'
                  role="link"
                >
                    <Image
                        src={ icon_healthcard }
                        width="20px"
                        height="20px"
                        mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                    />
                    { __('Health Card') }
                </button>
            </li>
        );
    }

    renderPrescriptionTab() {
        const { onClickPresNoStore, customer: { favourite_pharmacy_code } } = this.props;

        if (favourite_pharmacy_code) {
            return (
                <li
                  key="prescription"
                  block="MyAccountTabListItem"
                >
                    <button
                      block="MyAccountTabListItem"
                      elem="Button"
                      onClick='my-account/prescription'
                      role="link"
                    >
                        {/* <FontAwesomeIcon className="MyAccountTabListItem-IconLeft" icon={faPaste} /> */}
                        <Image
                                src={ icon_prescription }
                                width="20px"
                                height="20px"
                                mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                        />
                        { __('Prescription Wallet') }
                    </button>
                </li>
            );
        }

        return (
            <li
              key="prescription"
              block="MyAccountTabListItem"
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ onClickPresNoStore }
                  role="link"
                >
                    {/* <FontAwesomeIcon className="MyAccountTabListItem-IconLeft" icon={faPaste} /> */}
                    <Image
                            src={ icon_prescription }
                            width="20px"
                            height="20px"
                            mix={ { block: 'MyAccountOverlay', elem: 'Logout', } }
                    />
                    { __('Prescription Wallet') }
                </button>
            </li>
        );
    }

    renderTabListItem = (tabEntry) => {
        const { activeTab, onClickPresNoStore, customer: { favourite_pharmacy_code } } = this.props;
        const [key] = tabEntry;

        let clickAction = this.onTabClick;
        if (!favourite_pharmacy_code && key == PRESCRIPTION) {
            clickAction = onClickPresNoStore;
        }

        return (
            <MyAccountTabListItem
              key={ key }
              isActive={ activeTab === key }
              changeActiveTab={ clickAction }
              tabEntry={ tabEntry }
            />
        );
    };

    render() {
        const { tabMap, activeTab } = this.props;
        const { isContentExpanded } = this.state;
        const { name } = tabMap[activeTab];

        const tabs = [
            this.OrderTabList(),
            // this.renderHealthCardTab(),
            // this.renderPrescriptionTab(),
            ...Object.entries(tabMapOrder).map(this.renderTabListItem),
            // this.renderPharmacyTab(),
            this.renderLogoutTab(),
        ];

        return (
            <ExpandableContent
              heading={ name }
              isContentExpanded={ isContentExpanded }
              onClick={ this.toggleExpandableContent }
              mix={ { block: 'MyAccountTabList' } }
              device={ this.props.device }
            >
                <ul>
                    { tabs }
                </ul>
            </ExpandableContent>
        );
    }
};

export default MyAccountTabList;
