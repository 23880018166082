import { Suspense } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

import {
    SearchField as SourceSearchField,
    SearchOverlay,
} from 'SourceComponent/SearchField/SearchField.component';

import ClickOutside from 'Component/ClickOutside';
import SearchPostsOverlay from 'Component/SearchPostsOverlay/SearchPostsOverlay.container';
import { fetchQuery } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';
import history from 'Util/History';

import BlogPostsQuery from 'Query/BlogPosts.query';
import BrowserDatabase from 'Util/BrowserDatabase';

import './SearchField.style.override.scss';
import { StoreFinderDispatcher } from '../../store/StoreFinder';

export {
    SearchOverlay,
};

import { isSignedIn } from 'Util/Auth';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

export class SearchField extends SourceSearchField {

    state = {
        postsResults: {},
        showPharmacySelectorPopup:false
    }

    componentDidMount() {
        this.setState({ showSearch: false });
    }

    componentDidUpdate(prevProps, prevStates) {
        const { showSearch } = this.state;
        const { showSearch: prevShowSearch } = prevStates;

        if (showSearch && showSearch !== prevShowSearch) {
            this.onIconClick();
        }
    }

    checkPharmacy() {
        const { customer, device, hideActiveOverlay } = this.props;
        let favourite_pharmacy_code = null;

        if (!window.location.pathname.includes('blog')){
            if(isSignedIn()) {
                if (customer && customer.favourite_pharmacy_code) {
                    favourite_pharmacy_code = customer.favourite_pharmacy_code;
                } else {
                    if (localStorage.getItem('guest_pharmacy_code')) {
                        favourite_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
                    } else {
                        if(device.isMobile){
                            hideActiveOverlay();
                            //this.closeSearch();
                        }
                        this.closeSearch();
                        this.showPopup();
                    }
                }
            } else {
                if (localStorage.getItem('guest_pharmacy_code')) {
                    favourite_pharmacy_code = localStorage.getItem('guest_pharmacy_code');
                } else {
                    if(device.isMobile){
                        hideActiveOverlay();
                        //this.closeSearch();
                    }
                    this.closeSearch();
                    this.showPopup();
                }
            }
        }
    }

    showPopup() {
        const { showPharacySelectorPopup,setShowPharmacySelectorPopup} = this.props;
        let path = location.pathname;
        BrowserDatabase.setItem(path,'redirect_to');
        setShowPharmacySelectorPopup();
        StoreFinderDispatcher.requestStoreOnlineData();
        showPharacySelectorPopup();
    }

    /**
     * Always show search in desktop
     */
    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device
        } = this.props;

        const { postsResults } = this.state;

        const isBlog = window.location.pathname.includes('blog');


        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <form onSubmit={ isBlog ? this.onSubmitBlog : this.onSubmit }>
                    <input
                        id="search-field"
                        ref={ this.searchBarRef }
                        block="SearchField"
                        elem="Input"
                        onFocus={ onSearchBarFocus }
                        onChange={ this.handleChange }
                        onKeyDown={ isBlog ? this.onSearchBlogEnterPress : this.onSearchEnterPress }
                        onClick={ () => this.checkPharmacy() }
                        value={ searchCriteria }
                        mods={ { isActive } }
                        autoComplete="off"
                        placeholder={ __('Search entire store here...') }
                    />
                    <input type='submit'/>
                </form>
                <div
                  block="SearchField"
                  elem="SearchIcon"
                  role="button"
                  tabIndex="0"
                  onClick={ isBlog ? this.onIconBlogClick : this.onIconClick }
                  aria-label={ __('Search') }
                />
                <Suspense fallback={ this.renderOverlayFallback() }>
                { isBlog ?
                    <SearchPostsOverlay
                        isHideOverlay={ device.isMobile }
                        clearSearch={ this.clearSearch }
                        searchCriteria={ searchCriteria }
                        postsResults={ postsResults }
                        getPostsResults={ () => this.getPostsResults() }
                        onSearchEnterPress={ this.onSearchBlogEnterPress }
                        closeSearch={ this.closeSearch }
                    />
                    :
                    <SearchOverlay
                        isHideOverlay={ device.isMobile }
                        clearSearch={ this.clearSearch }
                        searchCriteria={ searchCriteria }
                        postsResults={ postsResults }
                        getPostsResults={ () => this.getPostsResults() }
                        onSearchEnterPress={ this.onSearchEnterPress }
                        closeSearch={ this.closeSearch }
                    />
                }
                </Suspense>
            </div>
        );
    }

    onIconClick = () => {
        this.checkPharmacy();
        this.searchBarRef.current.focus();
    };

    onIconBlogClick = () => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = encodeURIComponent(searchCriteria.trim());
        const trimmedSearch = searchCriteria.trim();
        const desired = trimmedSearch.replace(/[\/|\!“@#£$§%€&\[\]\(\){}=\?‘«»ºª\+\\*<>]/gi, '');

        if( desired != "") {
            history.push(appendWithStoreCode(`/blog/search/${ desired }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };


    onSearchEnterPress = (e) => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = encodeURIComponent(searchCriteria.trim());
        const trimmedSearch = searchCriteria.trim();

        if ((e.key === 'Enter' || e.key == '13' || e.keyCode == 13 || e.keyCode == 9 || e.which == 13|| e.code === "NumpadEnter") && trimmedSearch !== '') {
            const desired = trimmedSearch.replace(/[\/|\!“@#£$§%€&\[\]\(\){}=\?‘«»ºª\+\\*<>]/gi, '');
            history.push(appendWithStoreCode(`/search/${ desired }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };

    onSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = encodeURIComponent(searchCriteria.trim());
        const trimmedSearch = searchCriteria.trim();

        if (trimmedSearch !== '') {
            const desired = trimmedSearch.replace(/[\/|\!“@#£$§%€&\[\]\(\){}=\?‘«»ºª\+\\*<>]/gi, '');
            history.push(appendWithStoreCode(`/search/${ desired }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    }

    onSearchBlogEnterPress = (e) => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = encodeURIComponent(searchCriteria.trim());
        const trimmedSearch = searchCriteria.trim();

        if ((e.key === 'Enter' || e.key == '13' || e.keyCode == 13 || e.keyCode == 9 || e.which == 13|| e.code === "NumpadEnter") && trimmedSearch !== '') {
            const desired = trimmedSearch.replace(/[\/|\!“@#£$§%€&\[\]\(\){}=\?‘«»ºª\+\\*<>]/gi, '');
            history.push(appendWithStoreCode(`/blog/search/${ desired }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };

    onSubmitBlog = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = encodeURIComponent(searchCriteria.trim());
        const trimmedSearch = searchCriteria.trim();

        if (trimmedSearch !== '') {
            const desired = trimmedSearch.replace(/[\/|\!“@#£$§%€&\[\]\(\){}=\?‘«»ºª\+\\*<>]/gi, '');
            history.push(appendWithStoreCode(`/blog/search/${ desired }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    }


    handleChange = (e) => {
        const { target: { value } } = e;
        const { onSearchBarChange } = this.props;
        onSearchBarChange(e);

        if (value != '') {
            const desired = value.replace(/[\/|\!“@#£$§%€&\[\]\(\){}=\?‘«»ºª\+\\*<>]/gi, '');
            this.searchPosts(desired);
        }

        this.setState({ isPlaceholderVisible: value === '' });
    };

    getPostsResults = () => {
        return this.state.postsResults;
    }

    searchPosts(search) {
        /*const query = BlogPostsQuery.getQuery({
            filter: `{ search: { eq: ${ encodeURIComponent(search) } } }`,
            getMedia: true
        });*/

        let options = {
                filter: {
                    post_id: {},
                    author_id: {},
                    tag_id: {},
                    category_id: {},
                    title: {},
                    content: {},
                    publish_time: {},
                    search: {
                        like: search
                    },
                    relatedproduct_id: {}
                },
                pageSize: 24,
                currentPage: 1
        }

        const query = BlogPostsQuery.getQuery(options);

        fetchQuery(query).then(
            /** @namespace Store/Cart/Dispatcher/_syncCartWithBEFetchQueryThen */
            (result) => this.setState({ postsResults: result }),
            /** @namespace Store/Cart/Dispatcher/_syncCartWithBEFetchQueryError */
            (error) => console.log('error search posts', error)
        );

        /*fetchQuery(query).then(

            ({ blogPosts: posts }) => {
                console.log('blogPosts',posts);
                this.setState({ postsResults: posts })
            },

            (error) => {
                console.log('error search posts', error);
            }
        );*/
    }

    renderSearchIcon() {
        const { showSearch } = this.state;

        if (showSearch) {
            return (
                <button
                    block="SearchField"
                    tabIndex="0"
                    title={ __('Search entire store here...') }
                    onClick={ this.closeSearch }
                >
                    <FontAwesomeIcon block="SearchField" icon={ faTimes } />
                </button>
            );
        }

        return (
            <button
                 block="SearchField"
                 tabIndex="0"
                 title={ __('Search entire store here...') }
                 onClick={ this.openSearch }
            >
                <FontAwesomeIcon block="SearchField" icon={ faSearch } />
            </button>
        );
    }

    /**
     * Change mobile content
     */
    renderMobileContent() {
        const {
            device
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        const { showSearch } = this.state;

        return (
            <>
                { this.renderSearchIcon() }
                <div
                  block="SearchField"
                  elem="SearchWrapper"
                  mods={ { isVisible: showSearch } }
                >
                    { this.renderSearch() }
                </div>
            </>
        );
    }
    /**
     * Change desktop search
     */
    renderDesktopContent() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <>
                <div
                  block="SearchField"
                  elem="SearchWrapper"
                  mods={ { isVisible: true } }
                >
                    { this.renderSearch() }
                </div>
            </>
        );
    }

    /**
     * On desktop always show search
     */
    render() {
        const {
            isVisible,
            isActive
        } = this.props;

        return (
            <div block="SearchField" mods={ { isVisible, isActive } }>
                <ClickOutside onClick={ this.closeSearch }>
                    <div block="SearchField" elem="Wrapper">
                        { this.renderMobileContent() }
                        { this.renderDesktopContent() }
                    </div>
                </ClickOutside>
            </div>
        );
    }

};

export default SearchField;
