import {
    MyAccountOrderTableRow as SourceMyAccountOrderTableRow,
} from 'SourceComponent/MyAccountOrderTableRow/MyAccountOrderTableRow.component';
import { formatPrice } from 'Util/Price';
import { format } from "date-fns";
import PropTypes from 'prop-types';
import Loader from 'Component/Loader';
import Tooltip from 'Component/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

import './MyAccountOrderTableRow.style.override'

export class MyAccountOrderTableRow extends SourceMyAccountOrderTableRow {
    static propTypes = {
        setOrderStatusComplete: PropTypes.func.isRequired,
        setOrderStatusCancelByUser: PropTypes.func.isRequired,
        clickRebuy: PropTypes.func.isRequired
    };

    render() {
        const {
            isLoading,
            clickRebuy,
            setOrderStatusComplete,
            setOrderStatusCancelByUser,
            base_order_info: {
                created_at,
                status_label,
                status_code,
                increment_id,
                grand_total,
                pharmacy_name,
                id
            },
            is_wallet,
            onViewClick,
            currency_code
        } = this.props;
        let orderReceipt = '';
        let created_atFormat = created_at;

        if(status_label == "Em Distribuição" || status_label == "Em distribuição" ){
                orderReceipt = <tr block="MyAccountOrderTableRow" elem="OrderReceipt">
                        <td block="MyAccountOrderTableRow" elem="OrderReceipt-Info">
                            <label>{ __("Did you receive your order?") }</label>
                            <label>{ __("A sua confirmação ajuda-nos a melhorar os nossos serviços.") }</label>
                        </td>
                        <td>
                            <button onClick={ () => setOrderStatusComplete(id) } block="MyAccountOrderTableRow" elem="Button" mix={ { block: 'Button' } }>
                                { __('Confirm Receipt') }
                            </button>
                        </td>

                    </tr>
                ;
        }

        /*if(status_label == "Aguarda Pagamento" ){
            orderReceipt = <tr block="MyAccountOrderTableRow" elem="OrderReceipt">
                    <td block="MyAccountOrderTableRow" elem="OrderReceipt-Info">
                    </td>
                    <td>
                        <button onClick={ () => setOrderStatusCancelByUser(id) } block="MyAccountOrderTableRow" elem="Button" mix={ { block: 'Button' } }>
                            { __('Cancel') }
                        </button>
                    </td>

                </tr>
            ;
        }*/

        if(created_at != undefined){
            created_atFormat = created_at.replace(".", "/");
        }

        let walletInfo = '';
        if (is_wallet) {
            walletInfo = <Tooltip message={ __('The total presented will change according to the value of the products in the recipe.') } position={ 'bottom' }>
                <FontAwesomeIcon className="MyAccountOrderTableRow-AlertIcon" icon={ faExclamationCircle } />
            </Tooltip>
        }

        return (
            <>
                <Loader isLoading={ isLoading } />
                <tr block="MyAccountOrderTableRow">
                    <td>{ increment_id ? `#${increment_id}` : '' }</td>
                    <td>{ created_at ? created_atFormat.replace(".", "/") : created_atFormat }</td>
                    <td>
                        { is_wallet ? walletInfo : null } { grand_total ? formatPrice(grand_total, currency_code) : formatPrice(0, currency_code) }
                    </td>
                    <td>{ status_label }</td>
                    <td block="MyAccountOrderTableRow" elem="LinkRight"><a onClick={ () => clickRebuy(increment_id) }>{ __('Rebuy') }</a></td>
                </tr>
                <tr block="MyAccountOrderTableRow" elem="Pharmacy">
                    <label>{ __("Bought in ") }<b>{ pharmacy_name }</b></label>
                </tr>
                { orderReceipt }
            </>
        );
    }
};

export default MyAccountOrderTableRow;
